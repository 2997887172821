import * as React from 'react';
import {useEffect, useState} from 'react';
import './DateTime.scss';
import {useTranslation} from "react-i18next";
import {AutoComplete, DatePicker, Input, Select} from "antd";
import moment, {Moment} from "moment";
import {StartTimeDto} from "../../gen/client";
import MeetingApi from "../../api/MeetingApi";
import {handleServerError} from "../../util/ErrorHandler";
import {timestamp} from "../../util/utils";
import Storage from "../../util/Storage";
import {ReactComponent as clockIcon}  from '../../img/icons/clock-icon.svg';
import {ReactComponent as calendarIcon}  from '../../img/icons/calendar-icon.svg';
import Icon from '@ant-design/icons';
import { MEETING_DATE_FORMAT } from '../../util/constants';
import locale from 'antd/es/date-picker/locale/de_DE';
interface DateTimeProps {
  date: Moment;
  duration: number;
  onDateChange: (date: Moment) => void;
  onTimeChange: (time: string) => void;
  dateErr: boolean;
  timeErr: boolean;
  startTime: string;
}

const {Option} = Select;

export default function DateTime({date, duration, onDateChange, onTimeChange, dateErr, timeErr, startTime}: DateTimeProps) {
  const {t} = useTranslation();

  const [times, setTimes] = useState([] as StartTimeDto[]);

  useEffect(() => {
    if (!date || !duration) {
      setTimes([]);
      return;
    }
    MeetingApi.getAvailableStartTimes(Storage.getMemberId(), timestamp(date), duration).then(resp => {
      setTimes(resp.data);
    }).catch(handleServerError);
  }, [duration, date]);

  const options = times.map(it => <Option key={it.time} value={it.time} disabled={!it.available}>{it.time}</Option>)

  function onStartTimeChange(time: string) {
    if (time.length > 5) {
      time = time.slice(0, 5);
    } else {
      if (time.length === 4 && time.indexOf(':') === -1) {
        time = time.slice(0,2) + ":" + time.slice(2,4);
      }
    }
    onTimeChange(time);
  }

  function disableDate(current: Moment): boolean {
    return current <= moment().subtract(1, "days");
  }

  return (
    <div className={'date-time-sel'}>
      <div className={`cel ${dateErr ? 'ant-form-item-has-error' : ''}`}>
        <label className={'req'}>{t('Datum')}</label>
        <div className='date-time-wrapper'>
          <div className='input-wrapper date-picker'>
            <DatePicker locale={locale} id='date' size={'large'} format={MEETING_DATE_FORMAT} value={date} onChange={onDateChange} disabledDate={disableDate} suffixIcon={<Icon component={calendarIcon}/>}/>
          </div>
          {dateErr && <div className='err-msg'>{t('Feld ist erforderlich')}</div>}
        </div>
      </div>

      <div className={`ant-form-item cel time ${timeErr ? 'ant-form-item-has-error' : ''}`}>
        <label className={'req'}>{t('Zeit')}</label>
        <div className='date-time-wrapper'>
          <div className='input-wrapper'>
            <AutoComplete dropdownClassName={'hour-dropdown'} value={startTime} onChange={onStartTimeChange} dataSource={options}>
              <Input suffix={<Icon component={clockIcon} alt={''} className='date-time-icon'/>} name="new-hour" id="new-hour" autoComplete="off"/>
            </AutoComplete>
          </div>
          {(timeErr && !startTime) && <div className='err-msg'>{t('Feld ist erforderlich')}</div>}
          {(timeErr && startTime) && <div className='err-msg'>{t('Ungültige Zeit')}</div>}
        </div>
      </div>
    </div>
  )
}
