import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import './Meeting.scss';
import MeetingSettings from './MeetingSettings';
import {MeetingDto, MeetingDtoTypeEnum, PublicMeetingDto} from '../../gen/client';
import {useHistory, useParams} from 'react-router-dom';
import MeetingInfoDrawer from './MeetingInfoDrawer';
import {ActiveTab, Dimensions} from '../../util/enums';
import MeetingFooter from "./MeetingFooter";
import {
  closeConnection,
  connections, declareNumberOfParticipants,
  joinRtc, markAsProvider, markAsSingleMeeting,
  sendChatMessage,
  sendConnectionConfirm,
  sendConnectionInfirm,
  sendFileMessage,
  sendInternalMessage,
  startMeeting,
  stopMeeting,
  syncMediaStreamTracks
} from "../../service/RtcService";
import {Message} from './Chat';
import {isMeetingRoute, isPreviewRoute, Routes} from '../../util/routes';
import {stopTracks} from '../../service/DeviceService';
import MeetingPreviewHeader from './MeetingPreviewHeader';
import {doNothing, getApplicationSizeClass, isOwner} from '../../util/utils';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {
  applicationSize,
  audioMutedState,
  audioTracksState,
  cameraBrokenState,
  cameraOffState,
  cameraPositionState,
  confirmStartWithoutCameraState,
  detailsViewState,
  loggedMember,
  meetingActiveTabState,
  meetingInfoState,
  meetingOwnerState, meetingParticipantsState,
  meetingTypeState,
  presentationModeState,
  remoteStreamsState,
  settingsViewState,
  shareScreenState,
  userSelectedPresentationModeState,
  videoSettingsState,
  videoTracksState
} from '../../state/atoms';
import Storage from "../../util/Storage";
import StartPageFooter from '../start/StartPageFooter';
import {useTranslation} from 'react-i18next';
import {CameraPosition, InternalMessage, InternalMessageType, MeetingInfo, MeetingSession, MeetingStatus, RemoteStream} from '../../util/types';
import {calcLayout, createMemberJoinedLog, createParticipantJoinedLog, fitVideosToFullScreen} from '../../service/MeetingService';
import {usePrevious} from '../../util/hooks';
import RouteLeaveGuard from '../general/RouteLeaveGuard';
import LeaveMeetingModal from '../general/LeaveMeetingModal';
import moment from 'moment';
import '../general/Avatar.scss';
import NetworkSpeed from "./NetworkSpeed";
import MeetingActives from './MeetingActives';
import MeetingStreams from "./MeetingStreams";
import {handleServerError} from "../../util/ErrorHandler";
import {AxiosPromise} from "axios";
import MeetingApi from "../../api/MeetingApi";
import MeetingListener from "./MeetingListener";
import {EMPTY_MEETING_INFO, LARGE_MEETING_THRESHOLD, VideoSettings} from "../../util/constants";

export interface MeetingProps {
  preview?: boolean;
}

export default function Meeting({preview}: MeetingProps) {
  const {id} = useParams();
  const history = useHistory();
  const {t} = useTranslation();
  const appSize = useRecoilValue<Dimensions>(applicationSize);
  const [logged] = useRecoilState(loggedMember);

  // local stream
  const [localAudioTracks, setLocalAudioTracks] = useRecoilState(audioTracksState);
  const [localVideoTracks, setLocalVideoTracks] = useRecoilState(videoTracksState);
  const previousAudioTracks = usePrevious(localAudioTracks);
  const previousVideoTracks = usePrevious(localVideoTracks);

  // remote stream
  const [remoteStreams, setRemoteStreams] = useRecoilState(remoteStreamsState);

  // settings - drawers
  const [isSettingsView, setSettingsView] = useRecoilState(settingsViewState);
  const [isDetailsView, setDetailsView] = useRecoilState(detailsViewState);

  // settings - local video
  const [shareScreen, setShareScreen] = useRecoilState(shareScreenState);
  const [isPresentationMode, setPresentationMode] = useRecoilState(presentationModeState);
  const [userSelectedPresentationMode, setUserSelectedPresentationMode] = useRecoilState(userSelectedPresentationModeState);
  const [cameraPosition, setCameraPosition] = useRecoilState(cameraPositionState);

  // settings - meeting
  const [meeting, setMeeting] = useState(null as MeetingDto | PublicMeetingDto);
  const [meetingOwner, setMeetingOwner] = useRecoilState(meetingOwnerState);
  const [meetingType, setMeetingType] = useRecoilState(meetingTypeState);
  const [meetingParticipants, setMeetingParticipants] = useRecoilState(meetingParticipantsState);
  const [meetingInfo, setMeetingInfo] = useRecoilState(meetingInfoState);
  const [participantPreview, setParticipantPreview] = useState(false);
  const isAudioMuted = useRecoilValue(audioMutedState);
  const setVideoSettings = useSetRecoilState(videoSettingsState);
  const isCameraOff = useRecoilValue(cameraOffState);
  const cameraBroken = useRecoilValue(cameraBrokenState);
  const confirmStartWithoutCamera = useRecoilValue(confirmStartWithoutCameraState);
  const [joined, setJoined] = useState(false);
  const [participantJoined, setParticipantJoined] = useState(false);

  // chat
  const [messages, setMessages] = useState([] as Message[]);
  const [newMessagesCount, setNewMessagesCount] = useState(0);

  const [leaveMeetingModal, setLeaveMeetingModal] = useState(false);
  const activeTab = useRecoilValue(meetingActiveTabState);

  const isRouteGuardEnabled = useMemo(() => {
    return meetingOwner && !leaveMeetingModal && !isPreviewRoute(history.location.pathname) && meetingInfo.status === MeetingStatus.Progress && !!remoteStreams.length;
  }, [meetingOwner, leaveMeetingModal, history.location.pathname, remoteStreams.length, meetingInfo.status]);

  useEffect(() => {
    setMeetingOwner(isOwner());
  }, [setMeetingOwner]);

  useEffect(() => {
    setParticipantPreview(!remoteStreams.length && !meetingOwner);
  }, [remoteStreams, meetingOwner]);

  useEffect(calcLayout, [remoteStreams.length]);

  useEffect(() => {
    if (activeTab === ActiveTab.SECOND) {
      onOpenChat()
    }
  }, [activeTab]);

  useEffect(() => {
    if (isDetailsView && activeTab === ActiveTab.SECOND) {
      onOpenChat();
    }
  }, [isDetailsView, activeTab]);

  useEffect(() => {
    window.removeEventListener('resize', calcLayout);
    window.addEventListener('resize', calcLayout);

    return () => {
      window.removeEventListener('resize', calcLayout);
    }
  }, [history.location]);

  useEffect(() => {
    window.removeEventListener('beforeunload', closeConnection);
    if (!isRouteGuardEnabled) {
      window.addEventListener('beforeunload', closeConnection);
    }
    return () => {
      window.removeEventListener('beforeunload', closeConnection);
    }
  }, [isRouteGuardEnabled]);

  useEffect(() => {
    const unregister = history.listen((location: any) => {
      try {
        if (!isMeetingRoute(location.pathname)) {
          window.removeEventListener('resize', calcLayout);
        }
      } catch (e) {
      }
    });

    return () => {
      unregister();
    }
  }, [history]);

  const fetchMeeting = useCallback(() => {
    if (!id) return;

    const action: AxiosPromise = isOwner() ? MeetingApi.getFullMeetingByPublicIdForProvider(id) : MeetingApi.getFullMeetingByPublicIdForPatient(id);
    action.then((resp: any) => {
      setMeeting(resp.data);
    }).catch(err => handleServerError(err));
  }, [id]);

  useEffect(fetchMeeting, [fetchMeeting]);

  useEffect(() => {
    if (!meeting) return;

    setPresentationMode(meeting.type === MeetingDtoTypeEnum.Multicast);
    setUserSelectedPresentationMode(meeting.type === MeetingDtoTypeEnum.Multicast);
    setMeetingType(meeting.type);
    setMeetingParticipants(meeting.participants.length);
  }, [meeting, setMeetingType, setPresentationMode, setMeetingParticipants, setUserSelectedPresentationMode]);

  useEffect(calcLayout, [isPresentationMode]);

  const triggerStartMeeting = useCallback(() => {
    if (!meeting || !joined) return;

    startMeeting(meeting);
  }, [meeting, joined]);

  useEffect(() => {
    if (!meetingParticipants) return;

    if (meetingType === MeetingDtoTypeEnum.Single || meetingOwner) {
      setVideoSettings(VideoSettings.MEDIUM_SINGLE);
      markAsSingleMeeting();
    } else if (!meetingOwner && meetingParticipants > LARGE_MEETING_THRESHOLD) {
      setVideoSettings(VideoSettings.LOW);
    }
    if (meetingOwner) {
      markAsProvider();
    }
    declareNumberOfParticipants(meetingParticipants);
  }, [meetingType, meetingParticipants, meetingOwner, setVideoSettings]);

  useEffect(() => {
    if (!id) return;

    if (logged.logged && logged.skipPreview && isPreviewRoute(history.location.pathname)) {
      history.push(`${Routes.MEETING}/${id}`);
    }
  }, [logged, history, id, triggerStartMeeting]);

  const stopLocalTracks = useCallback(() => {
    stopTracks(localAudioTracks);
    stopTracks(localVideoTracks);
  }, [localAudioTracks, localVideoTracks]);

  const endMeeting = useCallback(() => {
    stopLocalTracks();

    if (meetingOwner) {
      stopMeeting(id);
    }

    window.removeEventListener('resize', calcLayout);

    closeConnection();

    setMeetingInfo(EMPTY_MEETING_INFO);
    setLocalAudioTracks([]);
    setLocalVideoTracks([]);
    setRemoteStreams([]);
    setSettingsView(false);
    setDetailsView(false);
    setShareScreen(false);

    history.push(meetingOwner ? `${Routes.APPOINTMENTS}/overview/${id}` : `${Routes.MEETING}/feedback/${id}`);
  }, [history, id, meetingOwner, stopLocalTracks, setMeetingInfo, setDetailsView, setLocalAudioTracks, setLocalVideoTracks, setRemoteStreams, setSettingsView, setShareScreen]);

  const onParticipantJoined = useCallback((meetingSession: MeetingSession) => {
    if (Storage.getMemberId() === meetingSession.memberOrParticipantId) {
      createMemberJoinedLog(meetingSession.memberOrParticipantId, id);
    } else {
      createParticipantJoinedLog(meetingSession.memberOrParticipantId, id);
      setParticipantJoined(true);
    }
  }, [id]);

  const sendLocalSettingsToPeer = useCallback((uuid: string) => {
    if (isAudioMuted) {
      sendInternalMessage({type: InternalMessageType.MUTE}, uuid);
    } else {
      sendInternalMessage({type: InternalMessageType.UNMUTE}, uuid);
    }
    if (shareScreen) {
      sendInternalMessage({type: InternalMessageType.SWITCH_TO_SHARE_SCREEN}, uuid);
    } else {
      sendInternalMessage({type: InternalMessageType.SWITCH_TO_DEVICE}, uuid);
    }
    if (isCameraOff) {
      sendInternalMessage({type: InternalMessageType.CAMERA_HIDE}, uuid);
    } else {
      sendInternalMessage({type: InternalMessageType.CAMERA_SHOW}, uuid);
    }

    sendInternalMessage({type: InternalMessageType.CAMERA_POSITION, payload: JSON.stringify(cameraPosition)}, uuid);
  }, [isAudioMuted, shareScreen, isCameraOff, cameraPosition]);

  const onDataChannelOpen = useCallback((uuid: string) => {
    if (!uuid) return;

    sendLocalSettingsToPeer(uuid);
  }, [sendLocalSettingsToPeer]);

  const updateSentStream = useCallback((stream: MediaStream) => {
    if (!id) return;
    joinRtc(
      id,
      stream,
      (uuid, remote) => {
        setRemoteStreams(it => {
          const prevStream = it.find(e => e.id === uuid);

          const newStream = {id: uuid, stream: remote} as RemoteStream;
          if (prevStream) {
            newStream.cameraPosition = prevStream.cameraPosition;
            newStream.screenSharing = prevStream.screenSharing;
            newStream.audioMuted = prevStream.audioMuted;
            newStream.cameraOff = prevStream.cameraOff;
          }

          return it.filter(e => e.id !== uuid).concat(newStream).filter(e => e.stream.active);
        });
        sendConnectionConfirm(id, uuid);
        sendLocalSettingsToPeer(uuid);
      },
      (uuid) => {
        if (!uuid) {
          setRemoteStreams([]);
        } else {
          setRemoteStreams(it => it.filter(e => e.id !== uuid).filter(e => e.stream.active));
          sendConnectionInfirm(id, uuid);
        }
      },
      (uuid) => {
        onDataChannelOpen(uuid);
      },
      onMessageReceived,
      (meetingSession: MeetingSession) => onParticipantJoined(meetingSession),
      (msg: InternalMessage) => {
        let recalcLayout = false;

        setRemoteStreams(streams => {
          const newRM = [...streams.filter(e => e.stream.active)];
          const idx = newRM.findIndex(it => it.id === msg.from);
          const obj = newRM.find(it => it.id === msg.from);

          if (msg.type === InternalMessageType.SWITCH_TO_SHARE_SCREEN || msg.type === InternalMessageType.SWITCH_TO_DEVICE) {
            const screenSharing = msg.type === InternalMessageType.SWITCH_TO_SHARE_SCREEN;
            if (screenSharing !== newRM[idx].screenSharing) recalcLayout = true;

            newRM[idx] = {...obj, screenSharing};
          } else if (msg.type === InternalMessageType.MUTE || msg.type === InternalMessageType.UNMUTE) {
            newRM[idx] = {...obj, audioMuted: msg.type === InternalMessageType.MUTE};
          } else if (msg.type === InternalMessageType.CAMERA_POSITION) {
            newRM[idx] = {...obj, cameraPosition: JSON.parse(msg.payload) as CameraPosition};
          } else if (msg.type === InternalMessageType.CAMERA_HIDE || msg.type === InternalMessageType.CAMERA_SHOW) {
            newRM[idx] = {...obj, cameraOff: msg.type === InternalMessageType.CAMERA_HIDE};
          }
          return newRM;
        });

        if (recalcLayout) {
          calcLayout();
        }
      },
      (info: MeetingInfo) => setMeetingInfo(info),
      (() => {
        setVideoSettings(VideoSettings.LOW);
      })
    );
    setJoined(true);
  }, [id, onDataChannelOpen, onParticipantJoined, setRemoteStreams, setMeetingInfo, sendLocalSettingsToPeer, setVideoSettings]);


  useEffect(() => {
    if (meetingOwner) {
      if (meetingInfo.status === MeetingStatus.WarmUp && !preview) {
        triggerStartMeeting();
      }
    }
    if (meetingInfo.status === MeetingStatus.Progress && isPreviewRoute(history.location.pathname)) {
      setTimeout(() => {
        history.push(`${Routes.MEETING}/${id}`);
      }, 500);
    }

    const parsedMsgs = JSON.parse(Storage.getMessages());
    if (meetingInfo.status === MeetingStatus.Progress && parsedMsgs) {
      const msgs: { [key: string]: Message[] } = parsedMsgs;
      if (msgs && Object.keys(msgs)[0] === id) {
        setMessages(msgs[id]);
      }
    }

    if (meetingInfo.status === MeetingStatus.Ended) {
      endMeeting();
    }
  }, [triggerStartMeeting, endMeeting, meetingOwner, preview, meetingInfo.status, history, id]);

  useEffect(() => {
    if (remoteStreams.length && meetingType !== MeetingDtoTypeEnum.Single) {
      const screenSharing = remoteStreams.find(it => it.screenSharing);

      if (!screenSharing && !shareScreen) {
        setPresentationMode(userSelectedPresentationMode);
      } else {
        setPresentationMode(shareScreen || isPresentationMode || !!screenSharing);
      }
    }
  }, [remoteStreams, meeting, isPresentationMode, userSelectedPresentationMode, shareScreen, setPresentationMode, meetingType]);

  useEffect(() => {
    if ((localVideoTracks.length || (cameraBroken && confirmStartWithoutCamera)) && localAudioTracks.length) {
      const mediaStream = new MediaStream();

      localVideoTracks.forEach(track => {
        const previousVideoTracksArr = (previousVideoTracks as MediaStreamTrack[]);
        if (previousVideoTracksArr && previousVideoTracksArr.length && (previousVideoTracksArr[0].id !== track.id)) {
          stopTracks(previousVideoTracksArr);
        }

        mediaStream.addTrack(track);
        setCameraPosition((track.getSettings() && track.getSettings().facingMode !== 'user') ? CameraPosition.ENVIRONMENT : CameraPosition.USER);
      });

      localAudioTracks.forEach(track => {
        const previousAudioTracksArr = (previousAudioTracks as MediaStreamTrack[]);
        if (previousAudioTracksArr && previousAudioTracksArr.length && (previousAudioTracksArr[0].id !== track.id)) {
          stopTracks(previousAudioTracksArr);
        }

        mediaStream.addTrack(track);
      });

      updateSentStream(mediaStream);
      syncMediaStreamTracks(mediaStream);
    }
  }, [localAudioTracks, localVideoTracks, updateSentStream, previousAudioTracks, previousVideoTracks, setCameraPosition, cameraBroken, confirmStartWithoutCamera]);

  useEffect(() => {
    if (meetingInfo.status !== MeetingStatus.Progress) return;

    const interval = setInterval(() => {
      Object.keys(connections).forEach(peerId => {
        const connection = connections[peerId];
        if (connection) {
          sendLocalSettingsToPeer(peerId);
          connection.refreshBandwidth();
        }
      });
    }, 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [meetingInfo.status, sendLocalSettingsToPeer]);

  function onShowSettingsViewChanged(open: boolean) {
    setSettingsView(open);

    fitVideosToFullScreen(appSize, {drawerVisible: open});
  }

  function toggleDetailsView() {
    if (isSettingsView && !isDetailsView) {
      setSettingsView(!isSettingsView);
    }
    setDetailsView(!isDetailsView);

    fitVideosToFullScreen(appSize, {drawerVisible: !isDetailsView});
  }

  function toggleSettingsView() {
    if (isDetailsView && !isSettingsView) {
      setDetailsView(!isDetailsView);
    }
    setSettingsView(!isSettingsView);

    fitVideosToFullScreen(appSize, {drawerVisible: !isSettingsView});
  }

  function onSendMessage(message: Message) {
    setMessages([...messages, message]);
    sendChatMessage(message);
  }

  const saveMessages = useCallback((messages: Message[]) => {
    if (!id) return;

    const msgs: { [key: string]: Message[] } = {};
    msgs[id] = [...messages];
    Storage.setMessages(JSON.stringify(msgs));
  }, [id]);

  useEffect(() => {
    if (messages.length > 0) {
      saveMessages(messages)
    }
  }, [messages, saveMessages]);


  function onSendFile(file: File) {
    sendFileMessage(file, buffer => {
      onMessageReceived({attachment: new Blob(buffer), attachmentName: file.name, attachmentSize: file.size, yours: true, time: moment().unix()});
    });
  }

  function onMessageReceived(message: Message) {
    setMessages(it => it.concat(message));
    setNewMessagesCount(prevCount => prevCount + 1);
  }

  function goToEdit() {
    history.push((meeting.groups.length === 0 && meeting.participants.length === 1) ? `${Routes.APPOINTMENTS_EDIT_SINGLE}/${meeting.publicId}` : `${Routes.APPOINTMENTS_EDIT_GROUP}/${meeting.publicId}`);
  }

  function cancel() {
    stopLocalTracks();

    closeConnection();

    preview ? history.push(Routes.APPOINTMENTS) : history.push(Routes.JOIN);
  }

  function onOpenChat() {
    setNewMessagesCount(0);
  }

  const videoWrapperElemClass = useMemo(() => {
    return `video-wrapper ${meetingType !== MeetingDtoTypeEnum.Single ? 'conference' : ''} ${!isPresentationMode ? '' : 'presentation'}`;
  }, [meetingType, isPresentationMode]);

  return (
    <div className={`meeting-view`}>
      {appSize !== Dimensions.MOBILE && <MeetingPreviewHeader/>}
      <div className={`meeting ${getApplicationSizeClass(appSize)} ${(isSettingsView || isDetailsView) ? 'w-det' : ''}`}>
        <div className={videoWrapperElemClass}>
          {meeting && <>
              <MeetingStreams preview={preview} meetingSessions={meetingInfo.sessions} participantJoined={participantJoined}
                              participantPreview={participantPreview} meeting={meeting} onVideoMetadataLoaded={calcLayout} onCancel={cancel}/>

              <MeetingListener/>

              <MeetingFooter id={id} meeting={meeting} toggleSettingsView={toggleSettingsView} toggleDetailsView={toggleDetailsView}
                             isProvider={meetingOwner} onEndMeeting={() => setLeaveMeetingModal(true)} onStartMeeting={triggerStartMeeting}
                             newMessagesCount={newMessagesCount} preview={preview}
                             participantPreview={participantPreview} onParticipantCancel={cancel}/>
          </>}
        </div>

        <MeetingSettings open={isSettingsView} showSettingsView={onShowSettingsViewChanged} meetingId={id}/>

        {meeting &&
            <MeetingInfoDrawer active={meeting} multiSelect={false} open={isDetailsView} onToggleDetailsView={toggleDetailsView}
                               onOpenChat={onOpenChat}
                               onMeetingStart={doNothing} messages={messages} onSendMessage={onSendMessage}
                               onSendFile={onSendFile} onEditMeeting={goToEdit} onDeleteMeeting={doNothing} meeting={meeting}/>}

        {!preview && !meetingOwner && appSize !== Dimensions.MOBILE && <StartPageFooter/>}
      </div>

      <NetworkSpeed/>
      <MeetingActives meetingId={id}/>
      <RouteLeaveGuard enabled={isRouteGuardEnabled} onConfirm={endMeeting} message={t('leaveMeeting')} okText={t('Beenden')}
                       cancelText={t('Abbrechen')}/>
      <LeaveMeetingModal visible={leaveMeetingModal} onOk={endMeeting} onCancel={() => setLeaveMeetingModal(false)} message={t('leaveMeeting')}
                         okText={t('Beenden')} cancelText={t('Abbrechen')}/>
    </div>
  );
}
