import { AxiosPromise } from "axios";
import { apiConfig, ops } from "./ApiConfig";
import { CmsDto, CmsResourceApi } from "../gen/client";

const api = new CmsResourceApi(apiConfig);

const CmsApi = {
  getAvailableCmsTypes(memberId: string): AxiosPromise<CmsDto[]> {
    return api.getCmsList(memberId, ops())
  }
};

export default CmsApi;
