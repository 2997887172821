import {replaceAll} from "../util/utils";
import {NotificationDto} from "../gen/client";

const KeyWords: { [key: string]: string[] } = {
  "SMS_MEETING_CREATED": ['name', 'praxis', 'datum', 'uhrzeit', 'dauer', 'link', 'mitarbeiter'],
  "SMS_MEETING_REMOVED": ['name', 'praxis', 'datum', 'uhrzeit', 'dauer', 'link', 'mitarbeiter'],
  "SMS_MEETING_REMINDER": ['name', 'praxis', 'datum', 'uhrzeit', 'dauer', 'link', 'mitarbeiter'],
  "SMS_AUTH_CODE": ['code'],
  "SMS_MFA_RESET": ['link'],
  "SMS_GROUP_MEETING_CREATED": ['name', 'gruppenname', 'praxis', 'datum', 'uhrzeit', 'dauer', 'link', 'mitarbeiter'],

  "EMAIL_MEETING_CREATED": ['name', 'praxis', 'datum', 'uhrzeit', 'dauer', 'link', 'mitarbeiter', 'verbindenbutton'/*, 'kalendereintrag'*/],
  "EMAIL_GROUP_MEETING_CREATED": ['name', 'praxis', 'datum', 'uhrzeit', 'dauer', 'link', 'mitarbeiter', 'verbindenbutton', 'gruppenname'],
  "EMAIL_MEETING_REMOVED": ['name', 'praxis', 'datum', 'uhrzeit', 'dauer', 'link', 'mitarbeiter'/*, 'kalendereintrag'*/],
  "EMAIL_MEETING_REMINDER": ['name', 'praxis', 'datum', 'uhrzeit', 'dauer', 'link', 'mitarbeiter', 'verbindenbutton'],
  "EMAIL_AUTH_CODE": ['code'],
  "EMAIL_ACTIVATION": ['mitarbeiter', 'aktivierungslink', 'aktivierungsbutton'],
  "EMAIL_NOTIFICATION_ACCOUNT_DELETED_BY_MEMBER": ['mitarbeiter', 'email', 'env'],
  "EMAIL_PASSWORD_FORGOT": ['passwortlink', 'passwortbutton'],
  "EMAIL_REGISTER": ['mitarbeiter'],
  "EMAIL_NOTIFICATION_REGISTER": ['email', 'env'],
  "EMAIL_NOTIFICATION_ACCOUNT_EXPIRES_SOON": ['env', 'mitarbeiter', 'email'],
  "EMAIL_ACCOUNTS_REMOVED": ['env']
};

export class SmsTag {
  key: string;
  value: string;

  constructor(key: string, value: string) {
    this.key = key;
    this.value = value;
  }
}

export const SmsTags: SmsTag[] = [
  new SmsTag('code', '000000'),
  new SmsTag('dauer', '30'),
  new SmsTag('datum', '12.04'),
  new SmsTag('uhrzeit', '16:45'),
  new SmsTag('name', 'Frank Mustermann'),
  new SmsTag('gruppenname', 'Noventi Group'),
  new SmsTag('link', 'https://www.noventi-video.de/join/zuaAA1nSQzwi?publicId=S1qMVpkgTaAA')
];

export function getKeyWords(type: string, name: string): string[] {
  return KeyWords[`${type}_${name}`] || [];
}

export function getSmsPreview(content: string, tags: SmsTag[]): string {
  let preview = content || '';
  tags.forEach(it => {
    preview = replaceAll(preview, '[(${' + it.key + '})]', it.value);
  });
  return preview;
}

export function getSmsLengthDiff(content: string, tags: SmsTag[]): number {
  let diff = 0;
  tags.forEach(it => {
    const key = '[(${' + it.key + '})]';
    if (content.indexOf(key) > -1) {
      diff += (key.length - it.value.length);
    }
  });
  return diff;
}

export function sortNotificationByType(a: NotificationDto, b: NotificationDto) {
  if (a.name === b.name) return -1;
  return 0
}

const notificationTypesOrdered = ["REGISTER", "NOTIFICATION_REGISTER", "ACTIVATION", "ACCOUNT_DELETED_DUE_TO_INACTIVITY",
  "ACCOUNT_DELETED_DUE_TO_INACTIVITY", "ACCOUNTS_REMOVED", "NOTIFICATION_ACCOUNT_DELETED_BY_MEMBER", "NOTIFICATION_ACCOUNT_EXPIRES_SOON",
  "PASSWORD_FORGOT", "MFA_RESET", "AUTH_CODE", "MEETING_CREATED", "MEETING_CREATED", "GROUP_MEETING_CREATED", "GROUP_MEETING_CREATED", "MEETING_REMINDER", "MEETING_REMINDER", "MEETING_REMOVED", "MEETING_REMOVED", "PARTICIPANTS_REMOVED"];

export function notificationSorter(a: NotificationDto, b: NotificationDto) {
  return notificationTypesOrdered.indexOf(a.name) - notificationTypesOrdered.indexOf(b.name);
}
