import * as React from 'react';
import { useState } from 'react';
import './StatisticsHeader.scss';
import IntervalPicker, { DatePickerInterval } from '../general/IntervalPicker';
import { Row } from 'antd';
import { useRecoilValue } from 'recoil';
import { applicationSize } from '../../state/atoms';
import { Dimensions } from '../../util/enums';
import IntervalPickerMobile from '../general/IntervalPickerMobile';
import {MEETING_DATE_FORMAT} from "../../util/constants";

export interface StatisticsHeaderProps {
  title: string;
  interval: DatePickerInterval;
  setInterval: (val: DatePickerInterval) => void;
}

export default function StatisticsHeader({interval, setInterval, title}: StatisticsHeaderProps) {

  const [calendarOpen, setCalendarOpen] = useState(false);
  const appSize = useRecoilValue<Dimensions>(applicationSize);

  return (
    <>
      {appSize !== Dimensions.MOBILE ? 
        <IntervalPicker value={interval} onChange={setInterval}/> 
      :
        <Row className='interval-mob'>
          <IntervalPickerMobile open={calendarOpen} openChange={setCalendarOpen} value={interval} valueChange={setInterval}/> 
          <div>{interval[0].format(MEETING_DATE_FORMAT)} - {interval[1].format(MEETING_DATE_FORMAT)}</div>
        </Row>
      }
    </>

  )
}
