import * as React from 'react';
import './StatisticsAppointmentTable.scss';
import { Card } from 'antd';
import { CmsAnswerDto, MeetingDto } from '../../gen/client';
import { MeetingListSummary } from './Statistics';
import StatisticsAppointmentTableRow from './StatisticsAppointmentTableRow';
import { useTranslation } from 'react-i18next';
import { formatDuration, getApplicationSizeClass } from '../../util/utils';
import { useRecoilValue } from 'recoil';
import { applicationSize } from '../../state/atoms';
import { Dimensions } from '../../util/enums';
import HourglassIcon from '../general/icons/HourglassIcon';
import ChatIcon from '../general/icons/ChatIcon';
import { useEffect, useState } from 'react';
import { participantFeedbacksList } from './state/statistics.state';

export interface MeetingTableProps {
  meetings: MeetingDto[],
  summary: MeetingListSummary
}

export default function StatisticsAppointmentTable({meetings, summary}: MeetingTableProps) {

  const {t} = useTranslation();
  const appSize = useRecoilValue<Dimensions>(applicationSize);
  const [feedbacksCount, setFeedbacksCount] = useState(0);
  const participantFeedbacks = useRecoilValue<CmsAnswerDto[]>(participantFeedbacksList);

  useEffect(() => {
    var meetingIds = meetings.map(it => it.publicId);
    setFeedbacksCount(participantFeedbacks.filter(it => meetingIds.indexOf(it.meetingPublicId) !== -1).length);
  }, [participantFeedbacks, meetings]);

  return (
    <Card className={`statistics-appointment-table ${getApplicationSizeClass(appSize)}`}>
      {appSize !== Dimensions.MOBILE ? <h3>{t('Video Termine')}</h3> : <h2 className={'title'}>{t('Video Termine')}</h2>}
      <div className={'table-body'}>
        {meetings.length > 0 ? meetings.map((meeting, id) => (
          <StatisticsAppointmentTableRow key={id} appointment={meeting}/>
        )) : <h1>{t('Keine Übereinstimmung gefunden.')}</h1>}
      </div>
      {appSize !== Dimensions.MOBILE ? <div className={'table-footer'}>
        <h3>{t('Total')}</h3>
        <h3>{`${summary.total} ${summary.total > 1 ? t('Termine') : t('Termin')}`}</h3>
        <h3>{`${formatDuration(summary.totalEstimatedDuration)}`}</h3>
        <h3><HourglassIcon className={'icon-hourglass'}/> {formatDuration(summary.totalDuration)}
        </h3>
        <h3><ChatIcon className={'icon-hourglass'}/> {feedbacksCount}</h3>
      </div> : null}
    </Card>
  )
}
