import * as React from 'react';
import MfaInput from './MfaInput';
import './MfaInputBox.scss';
import {Input} from 'antd';
import {MfaInputBoxState} from '../../util/types';

interface MfaInputBoxProps {
    onChange: (value: string) => void;
    state?: MfaInputBoxState;
    disabled?: boolean;
    onInvalidValue: () => void;
}

export default function MfaInputBox({onChange, state, disabled, onInvalidValue}: MfaInputBoxProps) {
    const inputNumber = 6;
    const refArr: Array<Input> = [];

    function setRef(index: number, ref: Input) {
        refArr.splice(index, 0, ref);
    }

    function onDelete(index: number) {
        const prevInput = refArr[index - 1];
        if (prevInput) {
            prevInput.focus();
        }
        onInvalidValue();
    }

    function onInputValueChange(index: number, value: string) {
        const nextInput = refArr[index + 1];
        if (nextInput) {
            nextInput.focus();
        }

        const currentValue = refArr.map(r => r.input.value).join('');
        if (currentValue.length === inputNumber) {
            onChange(currentValue);
        } else {
            onInvalidValue();
        }
    }

    return (
        <Input.Group className={'mfa-input'} size={'large'} key={state}>
            {[...Array(inputNumber)].map((x, i) =>
                <MfaInput
                    key={i}
                    onDelete={() => onDelete(i)}
                    onChange={value => onInputValueChange(i, value)}
                    autoFocus={i === 0}
                    setRef={(ref) => setRef(i, ref)} 
                    disabled={disabled} />
                    
            )}
        </Input.Group>
    );
}
