import * as React from 'react';
import {useEffect, useState} from 'react';
import './CMSPage.scss';
import Container from '../general/Container';
import {useTranslation} from 'react-i18next';
import {NotificationDtoTypeEnum} from '../../gen/client';
import {useRecoilState} from 'recoil';
import {loggedMember} from '../../state/atoms';
import {isAdmin, isCustomerCare, isPracticeManager} from '../../util/utils';
import {Tabs} from "antd";
import ContentList from "./ContentList";
import QuestionList from "./QuestionList";
import NotificationList from "./NotificationList";
import Storage from "../../util/Storage";

const {TabPane} = Tabs;

export default function CMSPage() {
  const {t} = useTranslation();

  const [logged] = useRecoilState(loggedMember);
  const [hasQuestionsPermission, setQuestionsPermission] = useState(false);
  const [hasContentPermission, setContentPermission] = useState(false);
  const [isAdminOrCustomerCare, setAdminOrCustomerCare] = useState(false);
  const [tab, setTab] = useState(Storage.getActiveContentTab() || '1');

  useEffect(() => {
    setQuestionsPermission(isAdmin(logged) || isPracticeManager(logged));
    setContentPermission(isAdmin(logged));
    setAdminOrCustomerCare(isAdmin(logged) || isCustomerCare(logged));

    setTab(Storage.getActiveContentTab() || '1');
  }, [logged]);

  function onTabChange(key: string) {
    setTab(key);
    Storage.setActiveContentTab(key);
  }

  return (
    <Container className={'cms full vd-list'}>
      <header>
        <h2>{t('CMS')}</h2>
      </header>
      <div className='content'>
        <Tabs activeKey={tab} onChange={onTabChange}>
          {isAdminOrCustomerCare && <TabPane tab={t('Emails')} key="1">
            <NotificationList type={NotificationDtoTypeEnum.Email}/>
          </TabPane>}

          {isAdminOrCustomerCare && <TabPane tab={t('Text messages')} key="2">
            <NotificationList type={NotificationDtoTypeEnum.Sms}/>
          </TabPane>}

          {hasContentPermission &&
          <TabPane tab={t('Pages')} key="3">
            <ContentList/>
          </TabPane>}

          {(hasQuestionsPermission || isAdminOrCustomerCare) &&
          <TabPane tab={t('Questions')} key="4">
            <QuestionList/>
          </TabPane>}
        </Tabs>
      </div>
    </Container>
  )
}
