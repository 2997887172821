export const env = {
  debug: false,
  apiPath: 'https://www.noventi-video.de',
  basePath: 'https://www.noventi-video.de',
  pingInterval: 15000, // ms
  logoutInterval: 10 * 60 * 1000,
  logoutWarningInterval: 9 * 60 * 1000,
  resendMfaCodeTimeout: 30000,
  generateDummyData: false,
  refreshTokenInterval: 240000 // 4 minutes
};
