import {CmsAnswerDto, CmsQuestionDto, ContentDto, LightCmsAnswerDto, MemberAnswerGroupDto} from '../gen/client/api';
import {AxiosPromise} from "axios";
import {apiConfig, ops} from "./ApiConfig";
import {TenantResourceApi} from "../gen/client";

const api = new TenantResourceApi(apiConfig);

const TenantApi = {
  createContent(tenantId: string, dto: ContentDto): AxiosPromise<ContentDto> {
    return api.createContent(tenantId, dto, ops());
  },

  updateContent(tenantId: string, dto: ContentDto): AxiosPromise<ContentDto> {
    return api.updateContent(tenantId, dto, ops());
  },

  deleteContent(tenantId: string, contentId: string): AxiosPromise<void> {
    return api.deleteContent(tenantId, contentId, ops())
  },

  getContentByType(tenantId: string, type: "HOMEPAGE" | "REGISTER" | "IMPRINT" | "DATA_PRIVACY" | "TERMS" | "FAQ"): AxiosPromise<ContentDto> {
    return api.getTenantContentByType(tenantId, type, ops());
  },

  createAdminQuestion(cmsId: number, tenantId: string, dto: CmsQuestionDto): AxiosPromise<void> {
    return api.createQuestion(tenantId, cmsId, dto, ops());
  },

  getQuestions(cmsId: number, tenantId: string): AxiosPromise<CmsQuestionDto[]> {
    return api.getQuestions(tenantId, cmsId, ops())
  },

  getAnswers(cmsId: number, tenantId: string, questionId?: string): AxiosPromise<{ [key: string]: Array<MemberAnswerGroupDto>; }> {
    return api.getAnswers(tenantId, cmsId, questionId, ops())
  },

  updateQuestion(cmsId: number, questionId: string, tenantId: string, dto: CmsQuestionDto) {
    return api.updateQuestion(tenantId, cmsId, questionId, dto, ops());
  },

  deleteQuestion(cmsId: number, tenantId: string, questionId: string) {
    return api.deleteQuestion(tenantId, cmsId, questionId, ops());
  },

  addAnswers(tenantId: string, cmsAnswers: CmsAnswerDto[]): AxiosPromise<CmsAnswerDto[]> {
    return api.addAnswers(tenantId, cmsAnswers, ops());
  },

  getQuestionsByType(tenantId: string, type: 'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER'): AxiosPromise<CmsAnswerDto[]> {
    return api.getQuestionsByType(tenantId, type, ops());
  },

  generateQuestionsAndAnswersOverview(tenantId: string, cmsId: string): AxiosPromise<void> {
    return api.getQuestionsAndAnswersOverview(tenantId, cmsId, {...ops(), responseType: 'blob'});
  },
  exportCmsToCSV(tenantId: string, cmsId: number): AxiosPromise<void> {
    return api.exportCmsToCSV(tenantId, cmsId, ops());
  },
  exportQuestionToCSV(tenantId: string, cmsId: number, questionId: string): AxiosPromise<void> {
    return api.exportQuestionToCSV(tenantId, cmsId, questionId, ops());
  },
  getLightAnswersForQuestion(cmsId: number, tenantId: string, questionId: string): AxiosPromise<LightCmsAnswerDto[]> {
    return api.getLightAnswersForQuestion(tenantId, cmsId, questionId, ops())
  },
  getAdminOrCustomerCareFeedbacks(tenantId: string): AxiosPromise<{ [key: string]: Array<MemberAnswerGroupDto>; }> {
    return api.getAdminOrCustomerCareFeedbacks(tenantId, ops());
  }
};

export default TenantApi;
