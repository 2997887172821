import * as React from 'react';
import { useTranslation } from "react-i18next";
import './SearchBar.scss';
import searchIcon from '../../img/icons/search-icon.svg';
import { useState } from 'react';
import { Divider, Dropdown } from 'antd';
import MemberApi from '../../api/MemberApi';
import { handleServerError } from '../../util/ErrorHandler';
import {AccountDto, AccountGroupDto, MeetingDto, MemberDto, PracticeDto} from '../../gen/client';
import { formatDate, formatTime, fromTimestamp, hasCustomerCareRole, isPracticeManager, isProvider } from '../../util/utils';
import { isPracticesRoute, Routes } from '../../util/routes';
import { useRecoilState } from 'recoil';
import { loggedMember } from '../../state/atoms';
import { useHistory } from 'react-router-dom';
import AdminApi from '../../api/AdminApi';

export default function SearchBar() {
  const { t } = useTranslation();
  const history = useHistory();

  const [logged] = useRecoilState(loggedMember);
  const [searchValue, setSearchValue] = useState("");

  const [accounts, setAccounts] = useState([] as AccountDto[]);
  const [accountGroups, setAccountGroups] = useState([] as AccountGroupDto[]);
  const [meetings, setMeetings] = useState([] as MeetingDto[]);
  const [members, setMembers] = useState([] as MemberDto[]);
  const [practices, setPractices] = useState([] as PracticeDto[]);

  const providerSearchResultMenu = (
    <div>
      {(meetings.length > 0 || accounts.length > 0 || accountGroups.length > 0) &&
        <div className='menu'>
          <div className='col'>
            <div className='row'>
              <h3>{t('Termine')}</h3>
            </div>
              {
                meetings.length > 0 ?
                  meetings?.slice(0, 5)?.map(it =>
                  (
                    <div key={it.id} onClick={() => goToMeeting(it.publicId)}>
                      <div className='row' key={it.id}>
                        <div className={'dtm'}>
                          <span>{formatDate(fromTimestamp(it.startDate))}</span>
                          <span>{formatTime(fromTimestamp(it.startDate))}</span>
                        </div>
                        <div className='col-2'>{it.duration}&nbsp;{t('Min')}</div>
                      </div>
                      <div>{it.participants[0]?.name}</div>
                      <Divider />
                    </div>
                  )) : 
                  <h4>{t('KEINE ÜBEREINSTIMMUNG GEFUNDEN.')}</h4>
              }
            {meetings.length > 0 && <div className='row'><a href={getMeetingsLink()}>{t('alle termine ansehen')}</a></div>}
          </div>
          <Divider type="vertical" />
          <div className='col'>
            <div className='row'>
              <h3>{t('Kontakte')}</h3>
            </div>
            {
              accounts.length > 0 ?
                accounts?.slice(0, 7)?.map(it =>
                (
                  <div key={it.id} onClick={() => goToContact(it.id)}>
                    <div className='row'>
                      <div className={'contact-name'}>{it.name}</div>
                    </div>
                    <Divider />
                  </div>
                )) :
                <h4>{t('KEINE ÜBEREINSTIMMUNG GEFUNDEN.')}</h4>
            }
            {accounts.length > 0 && <div className='row'><a href={getContactsLink()}>{t('alle kontakte ansehen')}</a></div>}
          </div>
          <Divider type="vertical" />
          <div className='col'>
            <div className='row'>
              <h3>{t('Gruppen')}</h3>
            </div>
            {
              accountGroups.length > 0 ?
              accountGroups?.slice(0, 7)?.map(it =>
                (
                  <div key={it.id} onClick={() => goToGroup(it.id)}>
                    <div className='row'>
                      <div className={'contact-name'}>{it.name}</div>
                    </div>
                    <Divider />
                  </div>
                )) :
                <h4>{t('KEINE ÜBEREINSTIMMUNG GEFUNDEN.')}</h4>
            }
            {accountGroups.length > 0 && <div className='row'><a href={getGroupsLink()}>{t('alle gruppen ansehen')}</a></div>}
          </div>
        </div>
      }
      {(meetings.length === 0 && accounts.length === 0 && accountGroups.length === 0 && !!searchValue) &&
        <h4 className='menu'>{t('KEINE ÜBEREINSTIMMUNG GEFUNDEN.')}</h4>
      }
    </div>
  );

  const memberSearchResultMenu = (
    <div>
    {members.length > 0 &&
      <div className='menu short'>
        <div className='col'>
          <div className='row'>
            <h3>{t('Kontakte')}</h3>
          </div>
          {
            members.slice(0, 7)?.map(it =>
            (
              <div key={it.id}>
                <div className='row' onClick={() => goToMember(it.id)}>
                  <h4>{it.lastName}{it.lastName && it.firstName ? ',' : ''} {it.firstName}</h4>
                </div>
                <Divider />
              </div>
            ))
          }
          {members.length > 0 && <div className='row'><a href={getMembersLink()}>{t('alle kontakte ansehen')}</a></div>}
        </div>
      </div>
    }
    {(members.length === 0 && !!searchValue) &&
        <h4 className='menu'>{t('KEINE ÜBEREINSTIMMUNG GEFUNDEN.')}</h4>
    }
    </div>
  )

  const practicesSearchResultMenu = (
    <div>
    {practices.length > 0 &&
      <div className='menu short'>
        <div className='col'>
          <div className='row'>
            <h3>{t('Praxis')}</h3>
          </div>
          {
            practices.slice(0, 7)?.map(it =>
            (
              <div key={it.id}>
                <div className='row' onClick={() => goToPractice(it.id)}>
                  <h4>{it.name}</h4>
                </div>
                <Divider />
              </div>
            ))
          }
          {practices.length > 0 && <div className='row'><a href={getPracticesLink()}>{t('alle praxis ansehen')}</a></div>}
        </div>
      </div>
    }
    {(practices.length === 0 && !!searchValue) &&
        <h4 className='menu'>{t('KEINE ÜBEREINSTIMMUNG GEFUNDEN.')}</h4>
    }
    </div>
  )

  function search(value: string) {
    if (value === "") {
      setMeetings([]);
      setAccounts([]);
      setMembers([]);
      setPractices([]);
      setAccountGroups([]);
    } else {
      if ((logged.isAdmin || hasCustomerCareRole(logged.roles)) && isPracticesRoute(history.location.pathname)) {
        practicesSearch(value);
      } else {
        if (logged.isAdmin || !isProvider(logged)) {
          memberSearch(value);
        } else {
          providerSearch(value);
        }
      }
    }
    setSearchValue(value);
  }

  function providerSearch(value: string) {
    MemberApi.fullSearchForProvider(logged.id, value)
      .then(resp => {
        setMeetings(resp.data.meetings);
        setAccounts(resp.data.participants);
        setAccountGroups(resp.data.groups);
      })
      .catch(err => handleServerError(err));
  }

  function memberSearch(value: string) {
    MemberApi.searchForManager(logged.id, value)
      .then(resp => setMembers(resp.data))
      .catch(err => handleServerError(err));
  }

  function practicesSearch(value: string) {
    AdminApi.searchPractices(value)
      .then(resp => setPractices(resp.data))
      .catch(err => handleServerError(err));
  }

  function getMeetingsLink(): string {
    return `${Routes.APPOINTMENTS}/search?key=${searchValue}`
  }

  function getContactsLink(): string {
    return `${Routes.CONTACTS}/search?key=${searchValue}`
  }

  function getGroupsLink(): string {
    return `${Routes.GROUPS}/search?key=${searchValue}`
  }

  function getMembersLink(): string {
    return `${Routes.MEMBERS}/search?key=${searchValue}`
  }

  function goToContact(id: string){
    history.push(`${Routes.CONTACTS}/${id}`);
  }

  function goToGroup(id: string){
    history.push(`${Routes.GROUPS}/${id}`);
  }

  function goToMember(id: string){
    history.push(`${Routes.MEMBERS}/${id}`);
  }

  function goToMeeting(id: string){
    history.push(`${Routes.APPOINTMENTS}/${id}`);
  }

  function goToPractice(id: string){
    history.push(`${Routes.PRACTICES}/${id}`);
  }

  function getPlaceholder() {
    return isPracticeManager(logged) ? t('Nutzer suche') : t('Suche');
  }

  function getPracticesLink(): string {
    return `${Routes.PRACTICES}/search?key=${searchValue}`
  }

  function showOverlay(visible: boolean) {
    if (visible) {
      document.getElementById('root').classList.add('blur-content');
    } else {
      document.getElementById('root').classList.remove('blur-content');
    }
  }

  return (
    <div className={'header-search-bar'}>
      <Dropdown overlay={logged.isAdmin || !isProvider(logged) ? (isPracticesRoute(history.location.pathname) ? practicesSearchResultMenu : memberSearchResultMenu) : providerSearchResultMenu} 
                trigger={['click']} onVisibleChange={(visible) => showOverlay(visible)}>
        <div className={'search-bar'} id='search-bar'>
          <div className={'bar'}>
            <input placeholder={getPlaceholder()} onChange={ev => search(ev.target.value)} onBlur={() => showOverlay(false)}/>
            <img src={searchIcon} alt="" />
          </div>
        </div>
      </Dropdown>
    </div>
  );
}
