import { ActivityType } from './../util/constants';
import SockJS from "sockjs-client"

export type Message = ActivityMessage | MeetingMessage;

export interface ActivityMessage {
  id: string,
  type: ActivityType
}

export interface MeetingMessage {
  type: string,
  sender?: string,
  receiver: string,
  data: any
}

export abstract class AbstractWebSocket {
  private socket: WebSocket;
  private _interval: number = null;
  private readonly _url: string = null;

  protected constructor(url: string, keepAlive: boolean = false) {
    this._url = url;
    this.initSocket();
    this.initHandlers();
    if (keepAlive) {
      this.initKeepAlive();
    }
  }

  private initSocket() {
    // @ts-ignore
    this.socket = new SockJS(this._url, null, { transports: ['websocket'] });
  }

  private initKeepAlive() {
    this._interval = window.setInterval(() => {
      if (!this.isOpened()) {
        console.log('[SOCKET_KEEP_ALIVE] => restarting socket because it was not opened');
        this.close();
        this.initSocket();
      }
    }, 5000);
  }

  private initHandlers(): void {
    this.socket.onmessage = (msg: MessageEvent) => { this.onMessage(JSON.parse(msg.data)) };
    this.socket.onclose = (closeEv: CloseEvent) => { this.onClose(closeEv) };
    this.socket.onerror = (ev: Event) => { this.onError(ev) };
    this.socket.onopen = (ev: Event) => { this.onOpen(ev) };
  }

  send(message: Message): void {
    if (message) {
      if (this.isOpened()) {
        this.socket.send(JSON.stringify(message));
      } else {
        console.error("Cannot send message because socket is not opened yet.", message);
      }
    }
  }

  onMessage(message: Message): void { }
  onClose(closeEv: CloseEvent): void { }
  onError(ev: Event): void { }
  onOpen(ev: Event): void { }
  close(): void {
    if (this._interval) {
      clearInterval(this._interval);
    }
    this.socket.close();
  }
  isOpened(): boolean {
    return this.socket.readyState === SockJS.OPEN;
  }
}
