import React, {useCallback, useEffect, useRef, useState} from 'react';
import {LOGOUT_WINDOW_EVENTS} from "../../util/constants";
import {useTranslation} from "react-i18next";
import {loggedMember, logoutEvents} from "../../state/atoms";
import {useRecoilState, useSetRecoilState} from "recoil";
import {env} from "../../env/env";
import {shouldLogout, logout, warnMessage, isOwner} from "../../util/utils";
import {isAppRoute} from "../../util/routes";
import AuthApi from "../../api/AuthApi";
import {getUnloggedAtom} from "../../state/logged";
import {useHistory} from "react-router-dom";
import MemberApi from "../../api/MemberApi";
import _ from "lodash";

export default function AutoLogout() {
  const [lastAction, setLastAction] = useState(0);
  const [warned, setWarned] = useState(false);
  const [preLogoutEvents, setPrelogoutEvents] = useRecoilState(logoutEvents);
  const setLogged = useSetRecoilState(loggedMember);
  const {t} = useTranslation();
  const history = useHistory();
  const throttledVisibilityChangeHandler = useRef(_.debounce(checkStillLoggedIn, 1000));

  const executeLogout = useCallback(() => {
    Promise.all(preLogoutEvents.map(it => it())).then(() => {
      console.log(`[AUTOLOGOUT] Finished running all prelogout events`);

      AuthApi.logout();
      logout();
      setLogged(getUnloggedAtom());

      history.push('/');

      setPrelogoutEvents([]);
      warnMessage(t('You have been automatically logged out.'));
    });
  }, [t, history, preLogoutEvents, setLogged, setPrelogoutEvents]);

  useEffect(() => {
    const visibilityChangedHandler = () => {
      if (!document.hidden) {
        throttledVisibilityChangeHandler.current();
      }
    };
    if (isOwner()) {
      document.addEventListener('visibilitychange', visibilityChangedHandler, false);
    }
    return () => {
      document.removeEventListener('visibilitychange', visibilityChangedHandler, false);
    }
  }, []);

  function checkStillLoggedIn() {
    if (shouldLogout()) {
      MemberApi.getStatus().then(resp => {
        if (!resp.data.authenticated && window.location.pathname !== '/') {
          executeLogout();
          window.location.href = '/';
        }
      });
    }
  }

  function registerLastAction() {
    setLastAction(new Date().getTime());
    setWarned(false);
  }

  useEffect(() => {
    registerLastAction();

    LOGOUT_WINDOW_EVENTS.forEach(event => {
      window.addEventListener(event, registerLastAction);
    });

    return () => {
      LOGOUT_WINDOW_EVENTS.forEach(event => {
        window.removeEventListener(event, registerLastAction);
      });
    }
  }, []);

  useEffect(() => {
    const timeout = setInterval(() => {
      if (isAppRoute(window.location.pathname) && isOwner()) {
        const diffSinceLastAction = new Date().getTime() - lastAction;

        if (diffSinceLastAction >= env.logoutInterval) {
          executeLogout();

          return;
        }

        if (diffSinceLastAction >= env.logoutWarningInterval && !warned) {
          warnMessage(t('You will be logged out automatically in 1 minute.'));
          setWarned(true);
        }
      }
    }, 1000);

    return () => {
      clearInterval(timeout);
    }
  }, [lastAction, warned, t, executeLogout]);

  return <></>;
}
