import * as React from 'react';

export function DashboardIcon() {
  return (
    <svg viewBox="0 0 26.625 26">
      <defs>
        <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#24317b" />
          <stop offset="1" stopColor="#111b54" />
        </linearGradient>
      </defs>
      <g transform="translate(-62.7 -67.1)">
        <g transform="translate(62.7 67.1)">
          <rect width="10.932" height="10.853" transform="translate(0.711 0.711)" />
          <g transform="translate(0 0)">
            <path d="M74.342,77.953H63.411l.711.711V67.811l-.711.711H74.342l-.711-.711V78.664a.711.711,0,0,0,1.422,0V67.811a.722.722,0,0,0-.711-.711H63.411a.722.722,0,0,0-.711.711V78.664a.722.722,0,0,0,.711.711H74.342A.711.711,0,0,0,74.342,77.953Z" transform="translate(-62.7 -67.1)" />
          </g>
        </g>
        <g transform="translate(76.844 67.1)">
          <rect width="10.932" height="10.853" transform="translate(0.718 0.711)" />
          <g transform="translate(0 0)">
            <path d="M273.342,77.953H262.411l.711.711V67.811l-.711.711h10.932l-.711-.711V78.664a.711.711,0,0,0,1.422,0V67.811a.722.722,0,0,0-.711-.711H262.411a.722.722,0,0,0-.711.711V78.664a.722.722,0,0,0,.711.711h10.932A.711.711,0,0,0,273.342,77.953Z" transform="translate(-261.7 -67.1)" />
          </g>
        </g>
        <g transform="translate(62.7 80.825)">
          <rect width="10.932" height="10.853" transform="translate(0.711 0.711)" />
          <g transform="translate(0 0)">
            <path d="M74.342,271.053H63.411l.711.711V260.911l-.711.711H74.342l-.711-.711v10.853a.711.711,0,0,0,1.422,0V260.911a.722.722,0,0,0-.711-.711H63.411a.722.722,0,0,0-.711.711v10.853a.722.722,0,0,0,.711.711H74.342A.711.711,0,0,0,74.342,271.053Z" transform="translate(-62.7 -260.2)" />
          </g>
        </g>
        <g transform="translate(76.972 80.825)">
          <rect width="10.932" height="10.853" transform="translate(0.711 0.711)" />
          <g transform="translate(0 0)">
            <path d="M275.142,271.053H264.211l.711.711V260.911l-.711.711h10.932l-.711-.711v10.853a.711.711,0,0,0,1.422,0V260.911a.722.722,0,0,0-.711-.711H264.211a.722.722,0,0,0-.711.711v10.853a.722.722,0,0,0,.711.711h10.932A.711.711,0,0,0,275.142,271.053Z" transform="translate(-263.5 -260.2)" />
          </g>
        </g>
      </g>
    </svg>
  )
}
