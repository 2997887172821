import {AuthResourceApi, AuthResponseDto, LoginDto, MfaFieldsDto, RefreshTokenDto, RegisterDto, UpdatePasswordDto} from "../gen/client";
import {AxiosPromise} from "axios";
import {apiConfig, ops} from "./ApiConfig";

const api = new AuthResourceApi(apiConfig);

const AuthApi = {
  login(dto: LoginDto): AxiosPromise<AuthResponseDto> {
    try { dto.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone } catch (e) {}
    
    return api.login(dto, ops());
  },

  logout(): AxiosPromise<void> {
    return api.logout(ops());
  },

  register(dto: RegisterDto): AxiosPromise<AuthResponseDto> {
    return api.register(dto, ops());
  },

  refreshToken(): AxiosPromise<RefreshTokenDto> {
    return api.refreshToken(ops())
  },

  sendLoginCode(dto: LoginDto): AxiosPromise<void> {
    return api.sendLoginCode(dto, ops());
  },

  globalLogout(memberId: string): AxiosPromise<boolean> {
    return api.globalLogout(memberId, ops());
  },

  sendPasswordForgotMail(email: string): AxiosPromise<void> {
    return api.sendPasswordForgotMail(email, ops());
  },

  resetPassword(code: string, dto: UpdatePasswordDto): AxiosPromise<void> {
    return api.resetPassword(code, dto, ops());
  },

	getMfaFields(id: string) {
		return api.getMfaFieldsNewMember(id, ops());
	},

	updateMfaFields(id: string, dto: MfaFieldsDto) {
		return api.updateMfaFieldsNewMember(id, dto, ops());
	},
};

export default AuthApi;
