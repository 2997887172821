import {CreateMeetingDto, MeetingPageDto, PublicMeetingDto, SearchOptions} from '../gen/client';
import {AxiosPromise} from "axios";
import {apiConfig, ops} from "./ApiConfig";
import {
  MeetingCountDto,
  MeetingDto,
  MeetingResourceApi,
  StartTimeDto,
  MeetingLogDto,
  LightMeetingDto
} from "../gen/client";

const api = new MeetingResourceApi(apiConfig);

const MeetingApi = {
  getMemberMeetingsPages(memberId: string, options: SearchOptions): AxiosPromise<MeetingPageDto> {
    return api.searchMeetings(memberId, options, ops());
  },

  getAvailableStartTimes(memberId: string, date: number, duration: number): AxiosPromise<StartTimeDto[]> {
    return api.getAvailableTimes(memberId, date, duration, ops());
  },

  create(meeting: CreateMeetingDto): AxiosPromise<string> {
    return api.create1(meeting, ops());
  },

  resendNotifications(meetingId: string): AxiosPromise<void> {
    return api.resendNotifications(meetingId, ops());
  },

  countMeetings(memberId: string): AxiosPromise<MeetingCountDto[]> {
    return api.countMeetings(memberId, ops());
  },

  deleteMeetings(notify: boolean, dtos: MeetingDto[]): AxiosPromise<string[]> {
    return api.removeList(notify, dtos, ops());
  },

  createMeetingLog(dto: MeetingLogDto, meetingId: string): AxiosPromise<MeetingLogDto> {
    return api.createMeetingLog(meetingId, dto, ops());
  },

  getMeetingLogs(meetingId: string): AxiosPromise<MeetingLogDto[]> {
    return api.getMeetingLogs(meetingId, ops());
  },

  getLightMeetingByPublicId(meetingId: string): AxiosPromise<LightMeetingDto> {
    return api.getLightMeetingByPublicId(meetingId, ops());
  },

  update(meeting: MeetingDto): AxiosPromise<string> {
    return api.updateMeeting(meeting, ops());
  },

  getMeetingActives(meetingId: string): AxiosPromise<string[]> {
    return api.getMeetingActives(meetingId, ops());
  },

  checkIfIsInThePast(startDate: number, startTime: number): AxiosPromise<boolean> {
    return api.checkIfIsInThePast(startDate, startTime, ops());
  },

  getFullMeetingByPublicIdForProvider(meetingId: string): AxiosPromise<MeetingDto> {
    return api.getFullMeetingByPublicIdForProvider(meetingId, ops());
  },

  updateCallDuration(meetingId: string, duration: number): AxiosPromise<void> {
    return api.updateCallDuration(meetingId, duration, ops());
  },

  getMemberMeetings(memberId: string): AxiosPromise<MeetingDto[]> {
    return api.getMemberMeetings(memberId,  ops());
  },

  getFullMeetingByPublicIdForPatient(meetingId: string): AxiosPromise<PublicMeetingDto> {
    return api.getFullMeetingByPublicIdForPatient(meetingId, ops());
  },
  
  printMeetingInfo(meetingId: string): AxiosPromise<void> {
    return api.getMeetingInfoPDF(meetingId, {...ops(), responseType: 'blob'})
  }
};

export default MeetingApi;
