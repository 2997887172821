import * as React from 'react';
import './ContactFilters.scss';
import {useTranslation} from "react-i18next";
import {MemberCountDtoKeyEnum} from "../../gen/client";
import {useEffect, useState} from "react";
import PracticeApi from '../../api/PracticeApi';
import {useRecoilState, useRecoilValue} from 'recoil';
import {activePractice, applicationSize, loggedMember} from '../../state/atoms';
import {Button, Dropdown, Menu} from "antd";
import {isAdmin, isCustomerCare} from "../../util/utils";
import { Dimensions } from '../../util/enums';
import AdminApi from '../../api/AdminApi';
import CustomerCareApi from '../../api/CustomerCareApi';

interface ContactFiltersProps {
  onChange: (filter: MemberCountDtoKeyEnum) => void;
  active: MemberCountDtoKeyEnum;
  membersCount: number;
  updated: boolean;
}

export default function ContactFilters({active, membersCount, updated, onChange}: ContactFiltersProps) {
  const {t} = useTranslation();

  const [practice] = useRecoilState(activePractice);
  const [logged] = useRecoilState(loggedMember);

  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [allCount, setAllCount] = useState(0);
  const appSize = useRecoilValue<Dimensions>(applicationSize);

  const statuses = {
    [MemberCountDtoKeyEnum.Active]: activeCount,
    [MemberCountDtoKeyEnum.Inactive]: inactiveCount,
    [MemberCountDtoKeyEnum.All]: allCount
  };

  useEffect(() => {
    let action;
    if (isAdmin(logged)) {
      action = AdminApi.countMembers();
    } else if (isCustomerCare(logged)) {
      action = CustomerCareApi.countMembers();
    } else {
      action = PracticeApi.countMembers(practice.id);
    }

    action.then(resp => {
      if (!resp || !resp.data) return;
      setActiveCount(resp.data.find(it => it.key === MemberCountDtoKeyEnum.Active).count);
      setInactiveCount(resp.data.find(it => it.key === MemberCountDtoKeyEnum.Inactive).count);
      setAllCount(resp.data.find(it => it.key === MemberCountDtoKeyEnum.All).count);
    });
  }, [logged, practice.id, membersCount, updated]);

  const list = [MemberCountDtoKeyEnum.All, MemberCountDtoKeyEnum.Active, MemberCountDtoKeyEnum.Inactive].map(it => {
    return (
      <li key={it}>
        <Button type={'link'} className={`${active === it ? 'act' : ''}`} onClick={() => onChange(it)}>
          {t(it)}
          {statuses[it] > 0 && <span>&nbsp;({statuses[it]})</span>}
        </Button>
      </li>
    )
  });

  const menu = (
    <Menu className={'apt-mr-men filter'}> 
      <Menu.Item>
        <Button type={'link'} className={`mobile-filter-link ${active === MemberCountDtoKeyEnum.All ? 'act' : ''}`} onClick={() => onChange(MemberCountDtoKeyEnum.All)}>
          {t(MemberCountDtoKeyEnum.All)} ({statuses[MemberCountDtoKeyEnum.All]})
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button type={'link'} className={`mobile-filter-link ${active === MemberCountDtoKeyEnum.Active ? 'act' : ''}`} onClick={() => onChange(MemberCountDtoKeyEnum.Active)}>
          {t(MemberCountDtoKeyEnum.Active)} ({statuses[MemberCountDtoKeyEnum.Active]})
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button type={'link'} className={`mobile-filter-link ${active === MemberCountDtoKeyEnum.Inactive ? 'act' : ''}`} onClick={() => onChange(MemberCountDtoKeyEnum.Inactive)}>
          {t(MemberCountDtoKeyEnum.Inactive)} ({statuses[MemberCountDtoKeyEnum.Inactive]})
        </Button>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
    {appSize === Dimensions.DESKTOP ? 
      <>
        <ul className={'filter'}>{list}</ul>
      </> : <>
        <div className='filter-dropdown'>
          <Dropdown overlay={menu}>
            <Button type={'link'} className='act sort mobile-filter-btn'>
              <span>{t(active)} ({statuses[active]})</span>
              <div className='sort-arrows'>
                <div className='arrow up-arrow'/>
                <div className='arrow down-arrow'/>
              </div>
            </Button>
          </Dropdown>
        </div>
      </>
      }
    </>
  )
}
