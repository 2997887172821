import React, {useCallback, useEffect, useState} from "react";
import AuthApi from "../../api/AuthApi";
import Storage from "../../util/Storage";
import {useRecoilValue} from "recoil";
import {loggedMember} from "../../state/atoms";
import {RefreshTokenDto} from "../../gen/client";
import {handleServerError} from "../../util/ErrorHandler";

const REFRESH_TOKEN_DIFF = 5000;

export default function TokenRefresher() {
  const logged = useRecoilValue(loggedMember);
  const [refreshResponse, setRefreshResponse] = useState({} as RefreshTokenDto);

  const refreshToken = useCallback(() => {
    if (!logged.id || !Storage.getToken()) {
      Storage.removeIsRefreshing();
      return;
    }

    const timeout = setTimeout(() => {
      Storage.setIsRefreshing(true);
      AuthApi.refreshToken().then((resp) => {
        setRefreshResponse(resp.data);
      }).catch(e => {
        handleServerError(e);
        Storage.removeIsRefreshing();
      });
    }, REFRESH_TOKEN_DIFF);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        Storage.removeIsRefreshing();
      }
    }
  }, [logged]);

  useEffect(() => {
    if (!logged.id || !Storage.getToken()) {
      Storage.removeIsRefreshing();
      setRefreshResponse({});
      return;
    }

    refreshToken();
  }, [logged, refreshToken]);

  useEffect(() => {
    if (!logged.id) {
      Storage.removeIsRefreshing();
      return;
    }

    let timeout: NodeJS.Timeout = null;

    if (refreshResponse.token) {
      Storage.setToken(refreshResponse.token);
    }
    if (refreshResponse.expiresIn) {
      timeout = setTimeout(() => {
        refreshToken();
      }, refreshResponse.expiresIn - (REFRESH_TOKEN_DIFF * 2));
    }

    Storage.removeIsRefreshing();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        Storage.removeIsRefreshing();
      }
    }
  }, [refreshResponse, refreshToken, logged.id]);

  return <></>
}
