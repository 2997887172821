import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {Switch, Select, Input, Button, Popconfirm} from "antd";
import {useEffect, useState} from 'react';
import {handleServerError} from '../../util/ErrorHandler';
import {AppSettingsDto, AppSettingsDtoVideoQualityEnum} from '../../gen/client';
import {Routes} from '../../util/routes';
import AdminApi from "../../api/AdminApi";
import "./AdminSettings.scss";
import {downloadFile, successMessage} from "../../util/utils";

export default function AdminSettings() {
  const {t} = useTranslation();
  const history = useHistory();

  const [settings, setSettings] = useState({} as AppSettingsDto);
  const [durationErr, setDurationErr] = useState(null as string);

  const [licenseCount, setLicenseCount] = useState(0);
  const [licenseInUseCount, setLicenseInUseCount] = useState(0);

  const [generating, setGenerating] = useState(false);
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    AdminApi.getPractice().then(resp => {
      setSettings(resp.data.appSettings);
    }).catch(handleServerError);
    fetchLicenseCount();
  }, []);

  function fetchLicenseCount() {
    AdminApi.countLicenses().then(resp => {
      setLicenseCount(resp.data.all);
      setLicenseInUseCount(resp.data.inUse);
    })
  }

  function onSettingsChange(dto: AppSettingsDto) {
    setSettings({...settings, ...dto})
    validate(dto);
  }

  function validate(dto: AppSettingsDto) {
    if (dto.defaultDuration !== undefined) {
      if (isNaN(dto.defaultDuration) || dto.defaultDuration < 5) {
        setDurationErr(t('DEFAULT_DURATION_MIN_ERROR'));
      } else if (dto.defaultDuration > 120) {
        setDurationErr(t('DEFAULT_DURATION_MAX_ERROR'));
      } else {
        setDurationErr(null);
      }
    }
    return durationErr == null;
  }

  function onSave() {
    if (!validate(settings)) return;
    AdminApi.savePracticeSettings(settings).then(() => {
      successMessage(t('Saved'))
    }).catch(handleServerError)
  }

  function goToDashboard() {
    history.push(Routes.STATISTICS);
  }

  function generate() {
    setGenerating(true);
    AdminApi.generateLicenses().then(() => {
      fetchLicenseCount();
      setGenerating(false);
    }).catch(err => {
      setGenerating(false);
      handleServerError(err);
    });
  }

  function exportCsv() {
    setExporting(true);
    AdminApi.exportLicensesToCsv().then(response => {
      setExporting(false);
      downloadFile(response.request.responseText, 'exported.csv')
    }).catch(err => {
      setExporting(false);
      handleServerError(err);
    });
  }

  return (
    <div className={'admin-settings'}>
      <div className={'admin block'}>
        <div className={`ant-form-item ${durationErr ? 'ant-form-item-has-error' : ''}`}>
          <label className={'req'}>{t('Meeting duration')}</label>
          <Input size={'large'} name={'duration'} type={'number'} min={5} max={120} step={5} value={settings.defaultDuration}
                 onChange={ev => onSettingsChange({defaultDuration: parseInt(ev.target.value)})}/>
          {durationErr && <span className='err-msg'>{durationErr}</span>}
        </div>

        {/* <div className={'ant-form-item'}>
          <label className={'req'}>{t('Days until inactivation')}</label>
          <Input size={'large'} name={'activeDays'} type={'number'} value={settings.maxActiveDays} onChange={ev => onSettingsChange({maxActiveDays: parseInt(ev.target.value)})}/>
        </div> */}

        <div className={'ant-form-item'}>
          <label>{t('Auflösung / Bitrate')}</label>
          <Select value={settings.videoQuality} size={'large'} onChange={ev => onSettingsChange({videoQuality: ev})} className={'full-width'}>
            <Select.Option value={AppSettingsDtoVideoQualityEnum.High}>{t('High')}</Select.Option>
            <Select.Option value={AppSettingsDtoVideoQualityEnum.Low}>{t('Low')}</Select.Option>
          </Select>
        </div>

        <div className={'ant-form-item'}>
          <div className='label switch-wrapper'>
            <Switch checked={settings.emailNotification} onChange={ev => onSettingsChange({emailNotification: ev})}/>
            <span>{t('Email notification')}</span>
          </div>
        </div>
        <div className={'ant-form-item'}>
          <div className='label switch-wrapper'>
            <Switch checked={settings.smsNotification} onChange={ev => onSettingsChange({smsNotification: ev})}/>
            <span>{t('Sms notification')}</span>
          </div>
        </div>

        <footer className={'actions'}>
          <Button type={'primary'} size="large" onClick={onSave}>{t('Speichern')}</Button>
          <Button className={'default'} size="large" onClick={goToDashboard}>{t('Abbrechen')}</Button>
        </footer>
      </div>

      <div className={'license'}>
        <div className={'block'}>
          {licenseCount === 0 && <p>{t('No generated licenses')}</p>}

          {licenseCount > 0 &&
          <p>
            <strong>{licenseCount}</strong> {t('Generated licenses')}.<br />
            <strong>{licenseInUseCount}</strong> {t('In use licenses')}.
          </p>}

          <footer>
            <Popconfirm disabled={exporting} title={t('Are you sure')} onConfirm={generate} okText={t('Yes')} cancelText={t('No')}>
              <Button loading={generating} disabled={generating} type={'primary'}>{t('Generate Licenses')}</Button>
            </Popconfirm>

            <Button loading={exporting} disabled={exporting} onClick={exportCsv}>{t('Export')}</Button>
          </footer>
        </div>
      </div>
    </div>
  )
}
