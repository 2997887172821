import * as React from 'react';
import './MeetingOverview.scss';
import Container from "../general/Container";
import {useParams, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ReactComponent as checkIcon} from '../../img/icons/check-icon.svg';
import {Rate, Button, Input, Timeline, Card} from 'antd';
import {useState, useEffect} from 'react';
import {ReactComponent as participantIcon} from '../../img/icons/participant-icon-thin.svg';
import MeetingApi from '../../api/MeetingApi';
import Storage from "../../util/Storage";
import {MeetingDto, MeetingLogDto, MeetingLogDtoTypeEnum, CmsQuestionDto, CmsQuestionDtoTypeEnum, CmsAnswerDto} from '../../gen/client';
import {handleServerError} from '../../util/ErrorHandler';
import moment from 'moment';
import {formatTime, formatDate, downloadFileUsingBlob, isProvider, timestamp, fromTimestamp, successMessage} from '../../util/utils';
import {Routes} from '../../util/routes';
import {getMeetingTime, getMeetingLogContent, getParticipantsWhoJoined, getMeetingInfoFileName} from '../../service/MeetingService';
import PracticeApi from '../../api/PracticeApi';
import { useRecoilState } from 'recoil';
import { activePractice, loggedMember } from '../../state/atoms';
import { TENANT_ID } from '../../util/constants';
import TenantApi from '../../api/TenantApi';
import { AxiosPromise } from 'axios';
import Icon from '@ant-design/icons';

const {TextArea} = Input;

export default function MeetingOverview() {
  const {id} = useParams();
  const {t} = useTranslation();
  const history = useHistory();

  const [logged] = useRecoilState(loggedMember);
  const [practice] = useRecoilState(activePractice);

  const [meeting, setMeeting] = useState(null as MeetingDto);
  const [meetingLogs, setMeetingLogs] = useState([] as MeetingLogDto[]);

  const [questions, setQuestions] = useState([] as CmsQuestionDto[]);
  const [answers] = useState([] as string[]);

  const [answersSent, setAnswersSent] = useState(false);

  useEffect(() => {
    MeetingApi.getFullMeetingByPublicIdForProvider(id).then(resp => {
      setMeeting(resp.data);
    }).catch(err => {
      handleServerError(err);
    });
    PracticeApi.getQuestionsByType(practice.id, isProvider(logged) ? "QUESTION_AFTER_CALL_PROVIDER" : "QUESTION_AFTER_CALL_PATIENT").then(resp => {
      setQuestions(prev => [...prev, ...resp.data])
    }).catch(handleServerError);

    TenantApi.getQuestionsByType(TENANT_ID, "QUESTION_AFTER_CALL_ADMIN")
      .then(resp => setQuestions(prev => [...prev, ...resp.data]))
      .catch(handleServerError);
  }, [id, practice.id, logged.roles, logged]);

  useEffect(() => {
    if (!!meeting) {
      MeetingApi.getMeetingLogs(meeting.publicId).then(resp => {
        setMeetingLogs(resp.data);
      }).catch(err => {
        handleServerError(err);
      });
    }
  }, [meeting])

  const participants = meeting?.participants.map(it => (
    <div key={it.id} className='participant'>
      <div><Icon component={participantIcon} alt={''} style={{fontSize: '48px'}}/></div>
      <div>
        <div className="name"><span>{it.name}</span></div>
        {it.email &&
        <div><Button type={'link'} className='link'>{it.email}</Button></div>}
        {it.phone &&
        <div className="phone"><span>{it.phone}</span></div>}
      </div>
    </div>
  ));

  const logs = meetingLogs?.map(it => (
    <Timeline.Item key={it.id} dot={formatTime(fromTimestamp(it?.createdDate))}>
      {<div dangerouslySetInnerHTML={{__html: t(getMeetingLogContent(it))}}/>}
    </Timeline.Item>
  ));

  function goToAppointments() {
    history.push(Routes.APPOINTMENTS);
  }

  function sendAnswers() {
    let list: CmsAnswerDto[] = [];
    questions.forEach((question, idx) => {
      if (!!answers[idx]) {
        list.push({answer: answers[idx], createdDate: timestamp(moment()), memberId: Storage.getMemberId(), questionId: question.id })
      }
    });
    if (list.length > 0) {
      const addAnswersAction: AxiosPromise = logged.isAdmin ? TenantApi.addAnswers(TENANT_ID, list) : PracticeApi.addPracticeAnswers(practice.id, list);
      addAnswersAction
        .then(() => {
          successMessage(t('Ihre Antwort wurde erfolgreich gesendet!'));
          setAnswersSent(true);
        })
        .catch(err => handleServerError(err))
    } 
  }

  function print() {
    MeetingApi.printMeetingInfo(meeting.publicId).then(response => {
      const participants = getParticipantsWhoJoined(meeting.logs);
      downloadFileUsingBlob(response.request.response, getMeetingInfoFileName(participants));
    }).catch(handleServerError);
  }

  return (
    <Container className='meeting-overview'>
      <div className={'split content'}>

      {!answersSent ?
        <div className='questions'>
          <div className='title'>
            <div><Icon component={checkIcon} alt={''} style={{fontSize: '120px', color: '#00D2A5'}}/></div>
            <h1>{t('Der Termin ist beendet')}</h1>
          </div>
          <div>
            {
              questions?.map((question, idx) => (
                <Card key={question.id} className='card' hoverable={true}>
                  <div>{question.label}</div>
                  {question.type === CmsQuestionDtoTypeEnum.Paragraph &&
                    <div>
                      <TextArea maxLength={255} className='txt' onChange={ev => answers[idx] = ev.target.value}/>
                    </div>
                  }
                  {question.type === CmsQuestionDtoTypeEnum.Rating &&
                    <Rate className='rate' onChange={value => answers[idx] = value.toString()}/>}
                </Card>
              ))
            }
          </div>
          <div className={'center'}><Button className='save-btn' onClick={sendAnswers}>{t('Senden')}</Button></div>
        </div>
        :
        <div className='rating'>
          <div><Icon component={checkIcon} alt={''} style={{fontSize: '120px', color: '#00D2A5'}}/></div>
          <h1>{t('Vielen Dank für dein Feedback!')}</h1>
        </div>
        }

        <div className='overview'>

          <div className="info-wrapper">
            <h4 className='title'>{t('Meeting - Verlauf')}</h4>
            <div className="info">
              <div>
                <label>{t('Erstellit')}</label>
              </div>
              <span>{formatDate(fromTimestamp(meeting?.createdDate))}</span>
              <span className="right">{formatTime(fromTimestamp(meeting?.createdDate))}</span>

              <div>
                <label>{t('Einladung gesendet')}</label>
              </div>
              <span>{formatDate(fromTimestamp(meeting?.startDate))}</span>
              <span className="right">{formatTime(fromTimestamp(meeting?.startDate))}</span>

              <div>
                <label>{t('Start time')}</label>
              </div>
              <span>{meetingLogs.length > 0 ? formatDate(getMeetingTime(meetingLogs, MeetingLogDtoTypeEnum.MeetingStarted)) : ""}</span>
              <span className="right">{meetingLogs.length > 0 ? formatTime(getMeetingTime(meetingLogs, MeetingLogDtoTypeEnum.MeetingStarted)) : ""}</span>
              <div>
                <label>{t('End time')}</label>
              </div>
              <span>{meetingLogs.length > 0 ? formatDate(getMeetingTime(meetingLogs, MeetingLogDtoTypeEnum.MeetingEnded)) : ""}</span>
              <span className="right">{meetingLogs.length > 0 ? formatTime(getMeetingTime(meetingLogs, MeetingLogDtoTypeEnum.MeetingEnded)) : ""}</span>
            </div>
          </div>

          <div className="participants">
            <h4 className='title'>{t('Teilnehmer')}</h4>
            <div>{participants}</div>
          </div>

          <div className="logs">
            <h4 className='title'>{t('Überblick')}</h4>
            <div className='logs'>
              <Timeline>{logs}</Timeline>
            </div>
          </div>

          <div className='overview-buttons'>
            <Button type={'primary'} size="large" className='d-btn' onClick={print}>{t('Drücken')}</Button>
            <Button type={'primary'} size="large" className='b-btn' onClick={goToAppointments}>{t('Beenden')}</Button>
          </div>

        </div>
      </div>
    </Container>
  )
}
