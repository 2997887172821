import {AbstractWebSocket} from "./AbstractWebSocket";
import {env} from "../env/env";
import Storage from "../util/Storage";
import {ActivityType} from "../util/constants";

export class ActivityWebSocket extends AbstractWebSocket {
  private interval: number = null;

  constructor() {
    super(`${env.apiPath}/activity`, true);
  }

  onOpen(ev: Event) {
    this.interval = this.interval || window.setInterval(() => {
      let id: string = null;
      let type: ActivityType = null;

      if (Storage.getMemberId()) {
        id = Storage.getMemberId();
        type = ActivityType.MEMBER;
      } else if (Storage.getParticipantId()) {
        id = Storage.getParticipantId();
        type = ActivityType.PARTICIPANT;
      }

      if (id && type) {
        this.send({id, type})
      }
    }, env.pingInterval);
  }

  onClose(closeEv: CloseEvent) {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
