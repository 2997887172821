import * as React from 'react';
import './AddContact.scss';
import { useTranslation } from 'react-i18next';
import {AccountDto} from '../../gen/client';
import { Button, Card } from 'antd';
import Container from '../general/Container';
import { useEffect, useState } from 'react';
import { isValidEmail, isValidNumber } from "../../util/validators";
import PracticeApi from '../../api/PracticeApi';
import { useRecoilState } from 'recoil';
import { activePractice } from '../../state/atoms';
import { handleServerError } from '../../util/ErrorHandler';
import { Routes } from '../../util/routes';
import { useHistory } from 'react-router-dom';
import Account from '../appointment/Account';
import {DEFAULT_PHONE_PREFIX} from '../../util/constants';
import {notificationChannelProvided, successMessage} from '../../util/utils';

export default function AddContact() {
  const { t } = useTranslation();
  const history = useHistory();
  const [practice] = useRecoilState(activePractice);

  const [nameErr, setNameErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const [account, setAccount] = useState({} as AccountDto);

  useEffect(() => {
    PracticeApi.getSettings(practice.id).then(resp => {
      setAccount({...account, ...{emailNotification: resp.data.emailNotification, smsNotification: resp.data.smsNotification}})
    }).catch(err => {
      handleServerError(err);
    });
  }, [practice.id]);

  function validate(): boolean {
    if (!account.name) {
      setNameErr(true)
      return false
    }
    return notificationChannelProvided(account)
  }

  function save() {
    if (!validate()) {
      return
    }
    PracticeApi.createAccount(practice.id, account)
      .then(() => {
        successMessage(t('Erfolgreich gespeichert!'));
        history.push(Routes.CONTACTS);
      })
      .catch(handleServerError)
  }
  function validateAccountName(value: string) {
    setNameErr(!value);
  }

  function validateAccountPhone(value: string, required: boolean) {
   setPhoneErr(value && value !== DEFAULT_PHONE_PREFIX ? !isValidNumber(value) : required);
  }

  function validateAccountEmail(value: string, required: boolean) {
    setEmailErr(value ? !isValidEmail(value) : required);
  }

  function cancel() {
    history.push(Routes.CONTACTS);
  }

  function onAccountChange(updated: AccountDto) {
    const newA = {...account, ...updated};
    setAccount({...account, ...updated});
    setCanSave(notificationChannelProvided(newA) && !!newA.name);
  }

  return (
    <Container className={'add-contact'}>
      <header>
        <h2>{t('Neuer Kontakt')}</h2>
      </header>
      <div className='content'>
      <Card>
        <Account
            it={account}
            idx={0}
            nameErr={nameErr}
            phoneErr={phoneErr}
            emailErr={emailErr}
            onNameChange={(_idx, value) => validateAccountName(value)}
            onPhoneChange={(_idx, value, req) => validateAccountPhone(value, req)}
            onEmailChange={(_idx, value, req) => validateAccountEmail(value, req)}
            onParticipantChange={(p) => onAccountChange(p)}
            saveErr={false} modal={false}/>          
      </Card>
      <footer className={'btns'}>
        <Button type={'primary'} size="large" onClick={save} disabled={!canSave}>{t('Speichern')}</Button>
        <Button className={'default'} size="large" onClick={cancel}>{t('Abbrechen')}</Button>
      </footer>
      </div>
  </Container>
  )
}
