import * as React from 'react';
import './SearchContactModal.scss';
import {useTranslation} from "react-i18next";
import {useState, useEffect, useCallback, createRef} from "react";
import {generateCharArray, sortAscendingByName} from "../../util/utils";
import {Button, Input, Checkbox, Tabs, Card} from "antd";
import {handleServerError} from "../../util/ErrorHandler";
import {
  AccountDto, AccountDtoGenderEnum,
  AccountGroupDto,
  MeetingDto,
} from "../../gen/client";
import PracticeApi from '../../api/PracticeApi';
import {useRecoilState} from "recoil";
import {activePractice, loggedMember} from "../../state/atoms";
import throttle from 'lodash.throttle';
import MemberApi from '../../api/MemberApi';
import searchImg from '../../img/icons/search-icon.svg';
import Icon from '@ant-design/icons';
import {ReactComponent as femaleIcon} from '../../img/icons/gender-female-icon.svg';
import {ReactComponent as maleIcon} from '../../img/icons/gender-male-icon.svg';
import {ReactComponent as diversIcon} from '../../img/icons/gender-divers-icon.svg';
import { ActiveTab, ViewMode } from '../../util/enums';
import {ReactComponent as gridIcon} from "../../img/icons/grid-icon.svg";
import {ReactComponent as listIcon} from "../../img/icons/list-icon.svg";
import {ReactComponent as videoIcon} from '../../img/icons/video-outline-icon.svg';
import {ReactComponent as searchIcon} from '../../img/icons/search-icon.svg';
import {ReactComponent as userIcon} from '../../img/icons/user-icon.svg';
import {ReactComponent as cameraIcon} from '../../img/icons/video-camera-outline-icon.svg';
import MeetingApi from '../../api/MeetingApi';

interface SearchContactModalProps {
  openSearch: boolean;
  showGroups: boolean;
  onCloseSearch: () => void;
  onContactChosen: (account: AccountDto) => void;
  onContactsChosen: (accounts: AccountDto[]) => void;
  onGroupChosen: (group: AccountGroupDto) => void;
}

interface ReferenceProp {
  [key: string]: any;
}

const {TabPane} = Tabs;

export default function SearchContactModal({openSearch, showGroups, onCloseSearch, onContactChosen, onContactsChosen, onGroupChosen}: SearchContactModalProps) {
  const {t} = useTranslation();
  const letters = generateCharArray('A', 'Z');

  const [practice] = useRecoilState(activePractice);
  const [logged] = useRecoilState(loggedMember);

  const [accounts, setAccounts] = useState([] as AccountDto[]);
  const [selectedAccounts, setSelectedAccounts] = useState([] as string[]);
  const [accountReferences, setAccountReferences] = useState([] as ReferenceProp[]);

  const [groups, setGroups] = useState([] as AccountGroupDto[]);
  const [selectedGroups, setSelectedGroups] = useState([] as string[]);
  const [groupReferences, setGroupReferences] = useState([] as ReferenceProp[]);

  const [meetings, setMeetings] = useState([] as MeetingDto[]);

  const [tab, setTab] = useState(ActiveTab.FIRST);
  const [viewMode, setViewMode] = useState(ViewMode.LIST);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    if (logged.id) {
      MeetingApi.getMemberMeetings(logged.id)
      .then(resp => setMeetings(resp.data))
      .catch(err => handleServerError(err))
    }
  }, [logged.id]);

  useEffect(() => {
    if (tab === ActiveTab.FIRST) {
      setSelectedGroups([]);
      participantSearch(searchKey);
    } else {
      setSelectedAccounts([]);
      groupSearch(searchKey);
    }
    setViewMode(ViewMode.LIST);
  }, [tab]);

  useEffect(() => {
    setAccountReferences(accounts.reduce((acc: any, value: AccountDto) => {
      acc[value.id] = createRef();
      return acc;
    }, {}));
  }, [accounts]);

  useEffect(() => {
    setGroupReferences(groups.reduce((acc: any, value: AccountGroupDto) => {
      acc[value.id] = createRef();
      return acc;
    }, {}));
  }, [groups]);

  useEffect(() => {
    if (openSearch) {
      if (tab === ActiveTab.FIRST) {
        getAllContacts();
      } else {
        getAllGroups();
      }
    }
  }, [openSearch]);

  const participantSearch = throttle(key => {
    setSearchKey(key);
    if (!key || key === "") {
      getAllContacts();
    } else {
      if (key) {
        MemberApi.searchForProvider(logged.id, key)
          .then((resp) => setAccounts(sortAscendingByName(resp.data)))
          .catch(err => handleServerError(err))
      }
    }
  }, 1000);

  const groupSearch = throttle(key => {
    setSearchKey(key);
    if (!key || key === "") {
      getAllGroups();
    } else {
      if (key) {
        MemberApi.searchGroupsForProvider(logged.id, key)
          .then((resp) => setGroups(sortAscendingByName(resp.data)))
          .catch(err => handleServerError(err))
      }
    }
  }, 1000);

  const scrollToParticipantItem = useCallback((id: string) => {
    if (accountReferences && (accountReferences as ReferenceProp)[id]) {
      (accountReferences as ReferenceProp)[id].current?.scrollIntoView({ // NOSONAR
        behavior: 'smooth',
        block: "center",
        inline: "nearest"
      });
    }
  }, [accountReferences]);

  const scrollToGroupItem = useCallback((id: string) => {
    if (groupReferences && (groupReferences as ReferenceProp)[id]) {
      (groupReferences as ReferenceProp)[id].current?.scrollIntoView({ // NOSONAR
        behavior: 'smooth',
        block: "center",
        inline: "nearest"
      });
    }
  }, [groupReferences]);

  function getAllContacts() {
    PracticeApi.getAccounts(practice.id, logged.id)
    .then(resp => setAccounts(sortAscendingByName(resp.data)))
    .catch(err => handleServerError(err));
  }

  function getAllGroups() {
    PracticeApi.getAccountGroups(practice.id, logged.id)
      .then(resp => setGroups(resp.data))
      .catch(err => handleServerError(err));
  }

  function onSelectParticipantToggle(id: string) {
    let contactIds: string[];
    if (selectedAccounts.indexOf(id) > -1) {
      contactIds = selectedAccounts.filter(it => it !== id);
    } else {
      contactIds = [...selectedAccounts, id];
    }
    setSelectedAccounts(contactIds);
  }

   function onSelectGroupToggle(id: string) {
    let groupIds: string[];
    if (selectedGroups.indexOf(id) > -1) {
      groupIds = selectedGroups.filter(it => it !== id);
    } else {
      groupIds = [...selectedGroups, id];
    }
    setSelectedGroups(groupIds);
  }

  function closeSearchContactModal() {
    setAccounts([]);
    setSelectedAccounts([]);
    setGroups([]);
    setSelectedGroups([]);
    onCloseSearch();
  }

  function useContact() {
    const found = accounts.find(it => it.id === selectedAccounts[0]);
    if (!found) return;
    if (!found.emailNotification && !found.smsNotification) {
      found.emailNotification = true;
    }
    onContactChosen(found);
    closeSearchContactModal();
  }

  function useContacts() {
    onContactsChosen(accounts.filter(it => selectedAccounts.indexOf(it.id) !== -1));
    closeSearchContactModal();
  }

  function useGroup() {
    const found = groups.find(it => it.id === selectedGroups[0]);
    if (!found) return;
    onGroupChosen(found);
    closeSearchContactModal();
  }

  function handleParticipantLetterOption(letter: string) {
    const list = accounts.filter(participant => participant.name.startsWith(letter.toUpperCase()) || participant.name.startsWith(letter.toLowerCase()));
    if (list && list.length > 0) {
      scrollToParticipantItem(list[0].id);
    }
  }

  function handleGroupLetterOption(letter: string) {
    const list = groups.filter(group => group.name.startsWith(letter.toUpperCase()) || group.name.startsWith(letter.toLowerCase()));
    if (list && list.length > 0) {
      scrollToGroupItem(list[0].id);
    }
  }

  function search(value: string) {
    if (tab === ActiveTab.FIRST) {
      participantSearch(value);
    } else {
      groupSearch(value);
    }
  }

  function getMeetingsForGroup(groupId: string): number {
    return meetings.filter(meeting => !!meeting?.groups?.find(group => group.id === groupId)).length;
  }

  function getPlaceholder(): string {
    return tab === ActiveTab.FIRST ? t('Name oder Telefonnummer') : t('Name');
  }

  const participantListItems = accounts.map(contact => (
    <div className={`vd-line ${selectedAccounts.indexOf(contact.id) > -1 ? 'active' : ''}`} ref={(accountReferences as ReferenceProp)[contact.id]} key={contact.id}>
      <div className={'col-3 name-wrapper'}>
        <Checkbox checked={selectedAccounts.indexOf(contact.id) > -1} className='checkbox' onChange={() => onSelectParticipantToggle(contact.id)} disabled={!showGroups && selectedAccounts.length > 0 && selectedAccounts.indexOf(contact.id) === -1}/>
        <div className='name'>
          <span>{contact.name}</span>
          <Icon className={`icon-only gender ${contact.gender === AccountDtoGenderEnum.Female ? 'female-icon' : contact.gender === AccountDtoGenderEnum.Male ? 'male-icon' : 'divers-icon'}`}
              component={contact.gender === AccountDtoGenderEnum.Female ? femaleIcon : contact.gender === AccountDtoGenderEnum.Male ? maleIcon : diversIcon} alt={''}/>
        </div>
      </div>
      <div className='col-2'>{contact.phone}</div>
      <div className='col-3 email'> 
        <a href={`mailto:${contact.email}`}>{contact.email}</a>
      </div>
    </div>));

  const participantGridItems = accounts.map((contact, idx) => (
    <Card.Grid className={`${idx % 2 === 1 ? 'odd-card' : 'even-card'} gridStyle ${selectedAccounts.indexOf(contact.id) > -1 ? 'active' : ''}`} hoverable={false} key={contact.id}>
      <div className='contact-card' ref={(accountReferences as ReferenceProp)[contact.id]}>
        <div>
          <Checkbox checked={selectedAccounts.indexOf(contact.id) > -1} className='checkbox' onChange={() => onSelectParticipantToggle(contact.id)} disabled={selectedAccounts.length > 0 && selectedAccounts.indexOf(contact.id) === -1}/>
        </div>
        <div className='col'>
          <div className='row name-wrapper'>
            <h3><span className='name'>{contact.name}</span>
              <Icon
                className={`icon-only gender ${contact.gender === AccountDtoGenderEnum.Female ? 'female-icon' : contact.gender === AccountDtoGenderEnum.Male ? 'male-icon' : 'divers-icon'}`}
                component={contact.gender === AccountDtoGenderEnum.Female ? femaleIcon : contact.gender === AccountDtoGenderEnum.Male ? maleIcon : diversIcon} alt={''}/>
            </h3>
          </div>
          {contact.phone && <div className='row'>
            <span className='col-2'>{contact.phone}</span>
            <Button className='col-1 icon-only' size='large' type={'link'} onClick={() => {
            }}><Icon component={videoIcon} alt={'video'}/></Button>
          </div>}
          <div className='row'>
            <a href={`mailto:${contact.email}`}>{contact.email}</a>
          </div>
        </div>
      </div>
    </Card.Grid>
  ))

  const groupListItems = groups.map(group => (
    <div className={`vd-line ${selectedGroups.indexOf(group.id) > -1 ? 'active' : ''}`} ref={(groupReferences as ReferenceProp)[group.id]} key={group.id}>
      <div className={'col-4'}>
        <Checkbox checked={selectedGroups.indexOf(group.id) > -1} className='checkbox' onChange={() => onSelectGroupToggle(group.id)} disabled={selectedGroups.length > 0 && selectedGroups.indexOf(group.id) === -1}/>
        <span>{group.name}</span>
      </div>
      <div className='col-1 group'><Icon className="icon-only" component={userIcon} alt={''}/>&nbsp;<span>{group.accounts.length}</span></div>
      <div className='col-1 group'><Icon className="icon-only" component={cameraIcon} alt={''}/>&nbsp;<span>{getMeetingsForGroup(group.id)}</span></div>
    </div>));

  const groupGridItems = groups.map((group, idx) => (
    <Card.Grid className={`${idx % 2 === 1 ? 'odd-card' : 'even-card'} gridStyle ${selectedGroups.indexOf(group.id) > -1 ? 'active' : ''}`} hoverable={false} key={group.id}>
      <div className={`group-name row`} ref={(groupReferences as ReferenceProp)[group.id]}>
        <Checkbox className='checkbox' checked={selectedGroups.indexOf(group.id) > -1} onChange={() => onSelectGroupToggle(group.id)} disabled={selectedGroups.length > 0 && selectedGroups.indexOf(group.id) === -1}/>
        <h3 className='contact-name'>{group.name}</h3>
      </div>
      <div className='group-info row'>
        <div className='col-1 phone'><Icon className="icon-only" component={userIcon} alt={''}/><span>{group.accounts.length}</span></div>
        <div className='col-1 camera'><Icon className="icon-only" component={cameraIcon} alt={''}/><span>&nbsp;{getMeetingsForGroup(group.id)}</span></div>
      </div>
    </Card.Grid>
  ))

  return (
    <div className='search-modal'>
      <div className='row'>
        <h3>{t('Addressbuch Suche')}</h3>
        <div className={'search-bar'}>
          <div className={'bar'}>
            <Input placeholder={getPlaceholder()} onChange={ev => search(ev.target.value)}/>
            <img src={searchImg} alt="search"/>
          </div>
        </div>
      </div>
      <div className={'content'}>
        <Tabs activeKey={tab} onChange={key => setTab(key as ActiveTab)} >
          <TabPane tab={t('Kontakte')} key={ActiveTab.FIRST}>
            {accounts.length > 0 ?
              <div className='tab-content'>
                <div className={`participants ${viewMode === ViewMode.GRID ? 'grid-view' : ''}`}>{viewMode === ViewMode.LIST ? participantListItems : participantGridItems}</div>
                <div className='letters-bar'>
                  {letters.map(it => <div key={it}>
                    <Button className='letter-btn' onClick={() => handleParticipantLetterOption(it)}>{it}</Button>
                  </div>)}
                </div>
              </div>
            :
            <div className={'tab-content image'}>
              <Icon component={searchIcon} alt=""/>
              <h3>
                <span>{t('Suchen den Kontakt nach Name')}</span>
                <span>{t('oder Telefonnummer')}</span>
              </h3>
            </div>
            }
          </TabPane>

          {showGroups &&
            <TabPane tab={t('Gruppen')} key={ActiveTab.SECOND}>
              {groups.length > 0 ?
                <div className='tab-content'>
                  <div className='participants'>{viewMode === ViewMode.LIST ? groupListItems : groupGridItems}</div>
                  <div className='letters-bar'>
                    {letters.map(it => <div key={it}>
                      <Button className='letter-btn' onClick={() => handleGroupLetterOption(it)}>{it}</Button>
                    </div>)}
                  </div>
                </div>
            :
              <div className={'tab-content image'}>
                <Icon component={searchIcon} alt=""/>
                <h3>{t('Suchen Gruppe nach Namen')}</h3>
              </div>
            }
            </TabPane>
          }

          {((accounts.length > 0 && tab === ActiveTab.FIRST) || (groups.length > 0 && tab === ActiveTab.SECOND)) &&
            <TabPane tab={<div className='view-mode-wrapper'>
                            <Button size={'large'} className={`icon-only ${viewMode === ViewMode.LIST ? 'active-btn' : ''}`} onClick={() => setViewMode(ViewMode.LIST)}>
                              <Icon component={listIcon} alt={''}/>
                            </Button>
                            <Button size={'large'} className={`icon-only ${viewMode === ViewMode.GRID ? 'active-btn' : ''}`} onClick={() => setViewMode(ViewMode.GRID)}>
                              <Icon component={gridIcon} alt={''}/>
                            </Button>
                        </div>}
              key={'3'} className='test' disabled={true}/>}
        </Tabs>
      </div>
      <div className='btns'>
        <Button type={'primary'} size="large" className='cancel-btn' onClick={closeSearchContactModal}>{t('Abbrechen')}</Button>
        <Button type={'primary'} size="large" className='search-btn' onClick={tab === ActiveTab.FIRST ? (selectedAccounts.length > 1 ? useContacts : useContact) : useGroup} disabled={selectedAccounts.length === 0 && selectedGroups.length === 0}>{t('Kontakt verwenden')}</Button>
      </div>
    </div>
  )
}
