import { AxiosPromise } from "axios";
import { apiConfig, ops } from "./ApiConfig";
import { ContentDto, ContentResourceApi, LightContentDto } from "../gen/client";

const api = new ContentResourceApi(apiConfig);

const ContentApi = {
  getAvailableContentTypes(memberId: string): AxiosPromise<ContentDto[]> {
    return api.getContentList(memberId, ops())
  },

  getContentByType(type: 'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER'): AxiosPromise<LightContentDto> {
    return api.getContentByType(type, ops())
  }
};

export default ContentApi;
