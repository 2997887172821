import * as React from 'react';
import {createRef, useCallback, useEffect, useState} from 'react';
import '../contact/Contacts.scss';
import {useTranslation} from "react-i18next";
import Container from '../general/Container';
import {SortDirection, ViewMode} from '../../util/enums';
import {Button, Card, Modal} from 'antd';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {
  generateCharArray,
  sortGroupsAscendingByName,
  sortGroupsDescendingByName,
  successMessage
} from '../../util/utils';
import {isGroupRoute, isNewGroupRoute, isSearchRoute, Routes} from '../../util/routes';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilState} from "recoil";
import {activePractice, loggedMember} from "../../state/atoms";
import PracticeApi from "../../api/PracticeApi";
import {handleServerError} from "../../util/ErrorHandler";
import {AccountGroupDto, MemberCountDtoKeyEnum} from "../../gen/client";
import Icon from '@ant-design/icons';
import {ReactComponent as iconMultiSelectionContacts} from "../../img/icons/icon-multi-selection-contacts.svg";
import GroupLine from './GroupLine';
import GroupInfo from './GroupInfo';
import ContactsHeader from '../contact/ContactsHeader';
import GroupSelectionBar from './GroupSelectionBar';
import GroupCard from './GroupCard';
import MemberApi from '../../api/MemberApi';

interface ReferenceProp {
  [key: string]: any;
}

export default function Groups() {
  const {t} = useTranslation();
  const {id} = useParams();
  const history = useHistory();

  const {confirm} = Modal;
  const letters = generateCharArray('A', 'Z');

  const [logged] = useRecoilState(loggedMember);
  const [practice] = useRecoilState(activePractice);

  const [list, setList] = useState([] as AccountGroupDto[]);

  const [viewMode, setViewMode] = useState(ViewMode.LIST);
  const [selected, setSelected] = useState([] as string[]);
  const [references, setReferences] = useState([] as ReferenceProp[]);
  const [filter, setFilter] = useState(MemberCountDtoKeyEnum.All);

  const getContactGroups = useCallback(() => {
    if (!practice.id || !logged.id) return;
    if (!isSearchRoute(history.location.pathname)) {
      PracticeApi.getAccountGroups(practice.id, logged.id)
      .then(resp => setList(resp.data))
      .catch(err => handleServerError(err));
    } else {
      const query = new URLSearchParams(history.location.search);
      const key = query.get('key');
      MemberApi.fullSearchForProvider(logged.id, key)
        .then(resp => setList(resp.data.groups))
        .catch(err => handleServerError(err))
    }
  }, [practice.id, logged.id, history.location.pathname, history.location.search]);

  const scrollToItem = useCallback((itemId: string) => {
    if (references && (references as ReferenceProp)[itemId]) {
      (references as ReferenceProp)[itemId].current?.scrollIntoView({ // NOSONAR
        behavior: 'smooth',
        block: "center",
        inline: "nearest"
      });
    }
  }, [references]);

  const onLineClick = useCallback((groupId: string) => {
    if (selected.length === 1 && selected.indexOf(groupId) > -1) {
      history.push(Routes.GROUPS);
      setSelected([]);
    } else if (selected.length <= 1) {
      history.push(`${Routes.GROUPS}/${groupId}`);
    }
  }, [history, selected]);

  useEffect(getContactGroups, [practice, getContactGroups]);

  useEffect(() => {
    setReferences(list.reduce((acc: any, value: AccountGroupDto) => {
      acc[value.id] = createRef();
      return acc;
    }, {}));
  }, [list]);

  useEffect(() => {
    if (isNewGroupRoute(history.location.pathname)) {
      setSelected(['new']);
      return;
    }

    if (selected.length === 1 && selected[0] === id) {
      return;
    }

    if (selected.length > 0 && list.filter(group => selected.indexOf(group.id) > -1).length === 0) {
      setSelected([]);
      return;
    }

    if (!history.location.search.includes('edit')) {
      if (id && selected.length < 2) {
        setSelected([id]);
      }
    } else if (selected.length === 0 && id) {
      setSelected([id]);
    }

    if (selected.length > 1 && isGroupRoute(history.location.pathname)) {
      history.push(Routes.GROUPS);
    }
  }, [history.location.pathname, id, selected, history, list]);

  function handleLetterOption(letter: string) {
    const groups = list.filter(group => group.name.startsWith(letter.toUpperCase()) || group.name.startsWith(letter.toLowerCase()));
    if (groups && groups.length > 0) {
      scrollToItem(groups[0].id);
    }
  }

  function onSelectToggle(groupId: string) {
    let groupIds: string[] = [];
    if (selected.indexOf(groupId) > -1) {
      groupIds = selected.filter(it => it !== groupId);
    } else {
      groupIds = [...selected, groupId];
    }
    setSelected(groupIds);
    if (groupIds.length === 1) {
      onLineClick(groupIds[0]);
    }
  }

  function onToggleAll(event: CheckboxChangeEvent) {
    if (event.target.checked) {
      setSelected([...list.map(it => it.id)]);
    } else {
      setSelected([]);
    }
  }

  function deleteParticipantGroup(group: AccountGroupDto) {
    if (selected.length === 1 && selected.indexOf(group.id) > -1) {
      setSelected([]);
    }
    PracticeApi.deleteAccountGroups(practice.id, [group.id])
      .then(() => {
        setList([...list.filter(it => it.id !== group.id)]);
        successMessage(t('Succesfully deleted'));
      })
      .catch(handleServerError);
  }

  function deleteParticipantGroups(ids: string[]) {
    setList([...list.filter(it => !ids.includes(it.id))]);
  }

  function sortGroups(direction: SortDirection) {
    const sortedList = (direction === SortDirection.Asc) ? sortGroupsAscendingByName(list) : sortGroupsDescendingByName(list);
    setList([...sortedList]);
  }

  function editGroup(group: AccountGroupDto) {
    PracticeApi.updateAccountGroup(practice.id, group.id, group).then(() => {
      successMessage(t('Erfolgreich gespeichert!'));
      getContactGroups();
    }).catch(handleServerError)
  }

  function onBackPush() {
    setSelected([]);
    history.push(Routes.GROUPS);
  }

  function onVideoIconClick(event: React.MouseEvent<HTMLElement, MouseEvent>, group: AccountGroupDto) {
    event.stopPropagation();
    history.push(`${Routes.APPOINTMENTS_NEW_GROUP}?id=${group.id}`);
  }

  function onEditIconClick(event: React.MouseEvent<HTMLElement, MouseEvent>, participant: AccountGroupDto) {
    event.stopPropagation();
    history.push(`${Routes.GROUPS}/${participant.id}?edit`);
  }

  function onDeleteIconClick(event: React.MouseEvent<HTMLElement, MouseEvent>, group: AccountGroupDto) {
    event.stopPropagation();

    if (selected.length < 2) {
      confirm({
        title: t('Are you sure you want to delete {{name}}?', {name: group.name}),
        content: t('{{name}} will no longer be in your list.', {name: group.name}),
        okText: t('Ja'),
        okType: 'danger',
        cancelText: t('Nein'),
        onOk() {
          deleteParticipantGroup(group);
        }
      });
    }
  }

  const gridItems = list.map(group => (
    <div key={group.id} onClick={() => setSelected([group.id])}>
      <GroupCard group={group} selected={selected} references={references} onSelectToggle={onSelectToggle}
                 onVideoIconClick={onVideoIconClick} onEditIconClick={onEditIconClick}
                 onDeleteIconClick={onDeleteIconClick}/></div>
  ));

  return (
    <Container className={`contacts full vd-list ${selected && selected.length === 1 ? 'item-selected' : ''}`}>
      <ContactsHeader onToggleAll={onToggleAll} onChangeViewMode={setViewMode} shortView={selected.length >= 1}
                      onSortChange={sortGroups} filter={filter} onFilterChange={setFilter}
                      isAdmin={logged.isAdmin} emptyContactList={list.length === 0} memberView={false}
                      membersCount={list.length} updated={false} onClearSearch={() => {
      }}/>

      <div className={`content ${(selected && selected.length === 1) ? 'edit' : ''}`}>
        {(viewMode === ViewMode.LIST || selected.length >= 1) &&
        <div className={'contact-list'}>
          {list.map(it =>
            <GroupLine key={it.id}
                       group={it}
                       selected={selected.indexOf(it.id) > -1}
                       onSelectToggle={onSelectToggle}
                       reference={(references as ReferenceProp)[it.id]}
                       shortView={selected.length >= 1}
                       onClick={onLineClick}
                       multipleSelection={selected.length > 0}
                       onVideoIconClick={(e) => onVideoIconClick(e, it)}
                       onEditIconClick={(e) => onEditIconClick(e, it)}
                       onDeleteIconClick={(e) => onDeleteIconClick(e, it)}/>)}
        </div>}

        {(viewMode === ViewMode.GRID && !selected.length) &&
        <div className='contact-list'>
            <Card className='grid-contacts'>
                <div className="grid-items-wrapper">{gridItems}</div>
            </Card>
        </div>}

        <div className='letters-bar'>
          {letters.map(it => <div key={it}>
            <Button className='letter-btn' onClick={() => handleLetterOption(it)}>{it}</Button>
          </div>)}
        </div>

        {(selected && selected.length === 1) &&
        <div className='info'>
            <GroupInfo onEdit={editGroup} onCancelEdit={getContactGroups} onBackPush={onBackPush}
                       group={list.find(it => it?.id === selected[0])}/>
        </div>}

        {(selected && selected.length > 1) &&
        <div className="multi-selection-app-icon">
            <Icon component={iconMultiSelectionContacts} alt={''}/>
        </div>
        }

      </div>
      {selected.length > 1 && <GroupSelectionBar groups={list} selected={selected} onSelectedChange={setSelected}
                                                 onDeleteGroups={deleteParticipantGroups}/>}
    </Container>
  )
}
