import {Rule} from "antd/lib/form";
import {DEFAULT_EMPTY_OK, LOWER_CASE_REGEX, MIN_PASS_LENGTH, SPECIAL_CHARACTERS_PASSWORD_REGEX, UPPER_CASE_REGEX} from "./constants";
import {EMAIL_REGEX, SPECIAL_CHARACTERS_PHONE_REGEX} from './constants';
import {PhoneNumberUtil} from "google-libphonenumber";

export function isValidNumber(value: string) {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const number = phoneUtil.parse(value, "DE");
    return phoneUtil.isValidNumberForRegion(number, "DE") && !SPECIAL_CHARACTERS_PHONE_REGEX.test(value)
  } catch (ex) {
    return false;
  }
}

export function isValidEmail(value: string) {
  try {
    return EMAIL_REGEX.test(value)
  } catch (e) {
    return false;
  }
}

export function isEmpty(value: string) {
  if (typeof value !== 'string') {
    return DEFAULT_EMPTY_OK;
  }

  return value.trim().length === 0;
}


export function hasMinLength(value: string, length: number = MIN_PASS_LENGTH): boolean {
  return value.trim().length >= length;
}

export function hasOneNumber(value: string): boolean {
  return /\d/.test(value);
}

export function hasOneLetter(value: string): boolean {
  return hasLowerCaseChar(value) || hasUpperCaseChar(value);
}

export function hasLowerCaseChar(value: string): boolean {
  return LOWER_CASE_REGEX.test(value);
}

export function hasUpperCaseChar(value: string): boolean {
  return UPPER_CASE_REGEX.test(value);
}

export function hasUpperAndLowerChar(value: string): boolean {
  return hasLowerCaseChar(value) && hasUpperCaseChar(value);
}

export function hasSpecialChar(value: string): boolean {
  return SPECIAL_CHARACTERS_PASSWORD_REGEX.test(value);
}

export const passwordValidator = (rule: Rule, value: string) => {
  if (!value || (hasMinLength(value) && hasOneNumber(value) && hasOneLetter(value) && hasUpperAndLowerChar(value) && hasSpecialChar(value))) {
    return Promise.resolve();
  }
  return Promise.reject('Das eingegebene Passwort ist nicht ausreichend komplex.');
};
