import * as React from 'react';
import './GroupLine.scss';
import {Checkbox, Button, Tooltip} from 'antd';
import {ReactComponent as videoIcon} from '../../img/icons/video-outline-icon.svg';
import {ReactComponent as editIcon} from '../../img/icons/edit-icon.svg';
import {ReactComponent as userIcon} from '../../img/icons/user-icon.svg';
import {AccountGroupDto} from "../../gen/client";
import {ReactComponent as deleteIcon} from '../../img/icons/trash-icon.svg';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface GroupLineProps {
  group: AccountGroupDto;
  selected: boolean;
  reference: any;
  shortView: boolean;
  onSelectToggle: (id: string) => void;
  onClick: (id: string) => void;
  onVideoIconClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onEditIconClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onDeleteIconClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  multipleSelection: boolean
}

export default function GroupLine({group, selected, reference, shortView, multipleSelection, 
                                    onSelectToggle, onClick, onVideoIconClick, onEditIconClick, onDeleteIconClick}: GroupLineProps) {
	const {t} = useTranslation();
  return shortView ?
    (
      <Tooltip title={selected ? t("If you deselect the checkbox you get back to the Group list") : undefined}>
        <div className={`vd-line short ${selected ? 'active' : ''}`} ref={reference} onClick={() => onClick(group.id)}>
            <Checkbox checked={selected} onChange={() => onSelectToggle(group.id)}/>
            <div className='phone'>{group.name}</div>
        </div>
      </Tooltip>) :
    
    (<div className={`vd-line group-line ${selected ? 'active' : ''}`} ref={reference} onClick={() => onClick(group.id)}>
      <div className='col name'>
        <Checkbox checked={selected} onChange={() => onSelectToggle(group.id)}/>
        <div className='group-name'><span>{group.name}</span></div>
      </div>
      <span className='col participants'><Icon className="icon-only" component={userIcon} alt={''}/><span>{group.accounts.length}</span></span>
      <div className="actions">
        <Button type={'link'} className="icon-only" onClick={onVideoIconClick} disabled={multipleSelection}><Icon component={videoIcon} alt={'video'}/></Button>
        <Button type={'link'} className="icon-only" onClick={onEditIconClick} disabled={multipleSelection}><Icon component={editIcon} alt={'edit'}/></Button>
        <Button type={'link'} className="icon-only" onClick={onDeleteIconClick}><Icon component={deleteIcon} alt={'delete'}/></Button>
      </div>
    </div>)
}
