import * as React from 'react';
import './MeetingInfoDrawer.scss';
import participantIcon from '../../img/icons/participant-icon-thin.svg';
import CopyUrl from '../general/CopyUrl';
import {getJoinLink} from '../../service/MeetingService';
import {CopyUrlEntry} from '../../util/types';

interface MeetingParticipantInfoProps {
  active: boolean;
  name : string;
  email : string;
  phone : string;
  meetingPublicId?: string;
  participantPublicId?: string;
  logged: boolean;
} 

export default function MeetingParticipantInfo({active, name, email, phone, meetingPublicId, participantPublicId, logged}: MeetingParticipantInfoProps) {
  function getCopyUrlEntry(): CopyUrlEntry[] {
    return [{
      url: getJoinLink(meetingPublicId, participantPublicId), 
      name: name, 
      publicId: participantPublicId
    }];
  }

  return (
    <li>
      <div className={'avatar'}><img src={participantIcon} alt={''}/><span className={`status ${active ? 'online' : 'offline'}`}></span></div>
      <div className={'info'}>
        <div className={'participant-name'}>{name} {meetingPublicId && participantPublicId && logged && <CopyUrl entries={getCopyUrlEntry()} />}</div>
        <div className={'participant-email'}><a href={`mailto:${email}`}>{email}</a></div>
        <div><a href={`tel:${phone}`}>{phone}</a></div>
      </div>
    </li>
  )
}
