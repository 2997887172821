import * as React from 'react';
import './AppointmentFilters.scss';
import {useTranslation} from "react-i18next";
import {MeetingCountDtoKeyEnum, MeetingDto, SearchOptionsFilterEnum} from "../../gen/client";
import {useCallback, useState} from "react";
import {useEffect} from "react";
import MeetingApi from "../../api/MeetingApi";
import {handleServerError} from "../../util/ErrorHandler";
import {Button, Dropdown, Menu} from "antd";
import { useRecoilValue } from 'recoil';
import { Dimensions } from '../../util/enums';
import {applicationSize, loggedMember} from '../../state/atoms';
import {useRecoilState} from "recoil";
import Storage from '../../util/Storage';

interface AppointmentFiltersProps {
  onChange: (filter: SearchOptionsFilterEnum) => void;
  active: SearchOptionsFilterEnum;
  meetings: MeetingDto[];
  pastMeetings: MeetingDto[];
}

export default function AppointmentFilters({active, meetings, pastMeetings, onChange}: AppointmentFiltersProps) {
  const {t} = useTranslation();

  const [allCount, setAllCount] = useState(0);
  const [pastCount, setPastCount] = useState(0);
  const [futureCount, setFutureCount] = useState(0);
  const [cancelledCount, setCancelledCount] = useState(0);
  const appSize = useRecoilValue<Dimensions>(applicationSize);
  const [logged] = useRecoilState(loggedMember);

  const statuses = {
    [MeetingCountDtoKeyEnum.CustomDate]: 0,
    [MeetingCountDtoKeyEnum.All]: allCount,
    [MeetingCountDtoKeyEnum.Past]: pastCount,
    [MeetingCountDtoKeyEnum.Future]: futureCount,
    [MeetingCountDtoKeyEnum.Cancelled]: cancelledCount
  };

  const updateMeetingCountCallback = useCallback(() => {
    if (!logged.id || !Storage.getMemberId()) return;

    MeetingApi.countMeetings(logged.id).then(resp => {
      setAllCount(resp.data.find(it => it.key === MeetingCountDtoKeyEnum.All).count);
      setPastCount(resp.data.find(it => it.key === MeetingCountDtoKeyEnum.Past).count);
      setFutureCount(resp.data.find(it => it.key === MeetingCountDtoKeyEnum.Future).count);
      setCancelledCount(resp.data.find(it => it.key === MeetingCountDtoKeyEnum.Cancelled).count);

    }).catch(handleServerError);
  }, [logged]);

  const menu = (
    <Menu className={'apt-mr-men filter'}> 
      <Menu.Item>
        <Button type={'link'} className={`mobile-filter-link ${active === SearchOptionsFilterEnum.All ? 'act' : ''}`} onClick={() => onChange(SearchOptionsFilterEnum.All)}>
          {t(SearchOptionsFilterEnum.All)} ({statuses[MeetingCountDtoKeyEnum.All]})
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button type={'link'} className={`mobile-filter-link ${active === SearchOptionsFilterEnum.Future ? 'act' : ''}`} onClick={() => onChange(SearchOptionsFilterEnum.Future)}>
          {t(SearchOptionsFilterEnum.Future)} ({statuses[MeetingCountDtoKeyEnum.Future]})
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button type={'link'} className={`mobile-filter-link ${active === SearchOptionsFilterEnum.Past ? 'act' : ''}`} onClick={() => onChange(SearchOptionsFilterEnum.Past)}>
          {t(SearchOptionsFilterEnum.Past)} ({statuses[MeetingCountDtoKeyEnum.Past]})
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button type={'link'} className={`mobile-filter-link ${active === SearchOptionsFilterEnum.Cancelled ? 'act' : ''}`} onClick={() => onChange(SearchOptionsFilterEnum.Cancelled)}>
          {t(SearchOptionsFilterEnum.Cancelled)} ({statuses[MeetingCountDtoKeyEnum.Cancelled]})
        </Button>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => updateMeetingCountCallback, [updateMeetingCountCallback, logged, meetings]);

  return (
    <>
    {appSize === Dimensions.DESKTOP ? 
      <>
      <ul className={'filter'}>
        <li>
          <Button type={'link'} className={`${active === SearchOptionsFilterEnum.All ? 'act' : ''}`} onClick={() => onChange(SearchOptionsFilterEnum.All)}>
            {t(SearchOptionsFilterEnum.All)} ({statuses[MeetingCountDtoKeyEnum.All]})
          </Button>
        </li>
        <li>
          <Button type={'link'} className={`${active === SearchOptionsFilterEnum.Future ? 'act' : ''}`} onClick={() => onChange(SearchOptionsFilterEnum.Future)}>
            {t(SearchOptionsFilterEnum.Future)} ({statuses[MeetingCountDtoKeyEnum.Future]})
          </Button>
        </li>
        <li>
          <Button type={'link'} className={`${active === SearchOptionsFilterEnum.Past ? 'act' : ''}`} onClick={() => onChange(SearchOptionsFilterEnum.Past)}>
            {t(SearchOptionsFilterEnum.Past)} ({statuses[MeetingCountDtoKeyEnum.Past]})
          </Button>
        </li>
        <li>
          <Button type={'link'} className={`${active === SearchOptionsFilterEnum.Cancelled ? 'act' : ''}`} onClick={() => onChange(SearchOptionsFilterEnum.Cancelled)}>
            {t(SearchOptionsFilterEnum.Cancelled)} ({statuses[MeetingCountDtoKeyEnum.Cancelled]})
          </Button>
        </li>
      </ul>
      </> : <>
      <div className='filter-dropdown'>
        <Dropdown overlay={menu}>
          <Button type={'link'} className='act sort mobile-filter-btn'>
            <span>{t(active)} ({statuses[active]})</span>
            <div className='sort-arrows'>
              <div className='arrow up-arrow'/>
              <div className='arrow down-arrow'/>
            </div>
          </Button>
        </Dropdown>
      </div>
      </>
      }
    </>
  )
}
