import * as React from 'react';
import {useEffect, useState} from 'react';
import './MeetingInfoDrawer.scss';
import {useTranslation} from "react-i18next";
import {MeetingDto, PublicMeetingDto} from "../../gen/client";
import {Button, Dropdown, Menu, Tabs} from "antd";
import {
  downloadFileUsingBlob,
  fetchMeetingParticipants,
  formatDate,
  formatTime,
  fromTimestamp,
  getMeetingLink,
  getMeetingLinkLabel,
  getParticipantActiveStatus,
  isInThePast,
  successMessage
} from '../../util/utils';
import MenuItem from 'antd/lib/menu/MenuItem';
import moreIcon from "../../img/icons/more-icon.svg";
import iconMultiSelectionAppointments from "../../img/icons/icon-multi-selection-appointments.svg";
import {ActiveTab} from '../../util/enums';
import Chat, {Message} from './Chat';
import {isAppointmentsRoute, isMeetingRoute} from '../../util/routes';
import {useHistory} from 'react-router-dom';
import MeetingParticipantInfo from './MeetingParticipantInfo';
import {MeetingStatus} from '../../util/types';
import MeetingApi from "../../api/MeetingApi";
import {handleServerError} from "../../util/ErrorHandler";
import {useRecoilState, useRecoilValue} from "recoil";
import {activeParticipantIdsState, loggedMember, meetingActiveTabState, meetingInfoState} from "../../state/atoms";
import {ReactComponent as infoIcon} from '../../img/icons/info-icon.svg';
import Icon from '@ant-design/icons';
import moment from 'moment';
import MeetingProviderInfo from './MeetingProviderInfo';
import { getMeetingInfoFileName, getParticipantsWhoJoined } from '../../service/MeetingService';

interface MeetingInfoDrawerProps {
  active: MeetingDto | PublicMeetingDto;
  multiSelect: boolean;
  open: boolean;
  messages: Message[];
  meeting: MeetingDto | PublicMeetingDto;
  onMeetingStart: (start: boolean) => void;
  onSendMessage: (message: Message) => void;
  onSendFile: (file: File) => void;
  onEditMeeting: () => void;
  onToggleDetailsView: () => void;
  onDeleteMeeting: () => void;
  onOpenChat: () => void;
}

export default function MeetingInfoDrawer({active, open, messages, meeting, onMeetingStart,
                                            onSendMessage, onSendFile, onEditMeeting, onToggleDetailsView, onDeleteMeeting, onOpenChat}: MeetingInfoDrawerProps) {
  const {t} = useTranslation();
  const history = useHistory();
  const {TabPane} = Tabs;
  const [logged] = useRecoilState(loggedMember);
  const [activeParticipantIds] = useRecoilState(activeParticipantIdsState);

  const [chatActive, setChatActive] = useState(false);
  const [activeTab, setActiveTab] = useRecoilState(meetingActiveTabState);

  const meetingInfo = useRecoilValue(meetingInfoState);

  useEffect(() => {
    let isChatActive = meetingInfo.status === MeetingStatus.Progress && meetingInfo.sessions.length > 1;
    setChatActive(isChatActive);
    setActiveTab(isChatActive ? ActiveTab.SECOND : ActiveTab.FIRST);
  }, [meetingInfo, setActiveTab]);

  function print() {
    MeetingApi.printMeetingInfo(active.publicId).then(response => {
      const participants = getParticipantsWhoJoined(active.logs);
      downloadFileUsingBlob(response.request.response, getMeetingInfoFileName(participants));
    }).catch(handleServerError);
  }

  function resendInvitation(ev: any) {
    MeetingApi.resendNotifications(active.id).then(() => {
      successMessage(t('Invitation resent'));
    }).catch(handleServerError);
  }

  function onTabChange(tab: string) {
    setActiveTab(tab as ActiveTab);
    onOpenChat();
  }

  function getActiveStatus(publicId: string): boolean {
    return isMeetingRoute(history.location.pathname) ? getParticipantActiveStatus(meetingInfo, publicId) : activeParticipantIds?.indexOf(publicId) > -1;
  }

  const menu = (
    <Menu className={'apt-mr-men'}>
      <MenuItem><Button type={'link'} onClick={onDeleteMeeting}>{t('Löschen')}</Button></MenuItem>
      <MenuItem><Button type={'link'} onClick={print} disabled={active?.anonymized}>{t('Export')}</Button></MenuItem>
      <MenuItem><Button type={'link'} onClick={resendInvitation}>{t('Einladung erneut senden')}</Button></MenuItem>
    </Menu>
  );

  return (
    <div className={`meeting-info-drawer ${open ? 'open' : ''} ${activeTab === ActiveTab.SECOND ? 'chat-active' : ''}`}>
      <div className={'close-bg'} onClick={onToggleDetailsView} />
      <Tabs type="card" activeKey={activeTab} moreIcon={false} onChange={onTabChange}>
        <TabPane
          tab={
            <>
              <Icon component={infoIcon} />
              Info
            </>
          } key={ActiveTab.FIRST}>

          {active ?
          <div>
            <div className={'scroller'}>
              <h4>{t('Termin Info')}</h4>
              <div className="split">
                <label>{t('Datum / Uhrzeit')}</label>
                <strong>{formatDate(fromTimestamp(active.startDate))} {formatTime(fromTimestamp(active.startDate))}</strong>
              </div>
              <div className="split">
                <label>{t('Dauer')}</label>
                <strong>{active.duration} {t('Min.')}</strong>
              </div>

              {logged.logged &&
                 <div className="split">
                  <label>{t('Link')}</label>
                  <a href={getMeetingLink(active)}>{getMeetingLinkLabel(active)}</a>
                </div>
              }
              <MeetingProviderInfo active={logged.logged || activeParticipantIds?.indexOf(active.provider.id) > -1} name={`${active.provider.firstName} ${active.provider?.lastName}`} />

              <h4>{t('Teilnehmer')}</h4>
              <ul className={'participants'}>
                {
                  fetchMeetingParticipants(active)?.map(it => (
                    <MeetingParticipantInfo active={getActiveStatus(it.publicId)} name={it.name} email={it.email} phone={it.phone} key={it.id} meetingPublicId={active.publicId} participantPublicId={it.publicId} logged={logged.logged}/>
                  ))
                }
              </ul>

              <h4>{t('Meeting Verlauf')}</h4>
              <div className="split">
                <label>{t('Erstellit')}</label>
                <strong>{formatDate(fromTimestamp(active.createdDate))} {formatTime(fromTimestamp(active.createdDate))}</strong>
              </div>

              <div className="split">
                <label>{t('Einladung gesendet')}</label>
                <strong>{formatDate(fromTimestamp(active.startDate))} {formatTime(fromTimestamp(active.startDate))}</strong>
              </div>

              {active.startDate < moment().unix() &&
                <div className="split">
                  <label>{t('Start time')}</label>
                  <strong>{formatDate(fromTimestamp(active.startDate))} {formatTime(fromTimestamp(active.startDate))}</strong>
                </div>
              }
              {active.startDate < moment().unix() &&
                <div className="split">
                  <label>{t('End time')}</label>
                  <strong>{formatDate(fromTimestamp(active.startDate).add(active.duration, 'minutes'))} {formatTime(fromTimestamp(active.startDate).add(active.duration, 'minutes'))}</strong>
                </div>
              }

              <h4>{t('Bemerkungen')}</h4>
              <div className={'info'}>
                {active.information}
              </div>
            </div>

            {logged.logged &&
              <div>
                {(active.startDate < moment().unix() && fromTimestamp(active.startDate).add(active.duration, "minutes") < moment())  || isMeetingRoute(history.location.pathname) || active.removed ?
                  <footer className='past-btns'>
                    <Button type={'primary'} size="large" className='del' onClick={onDeleteMeeting} hidden={active.removed || isInThePast(active)}>{t('Löschen')}</Button>
                    <Button type={'primary'} size="large" className='exp' onClick={print} disabled={active?.anonymized}>{t('Export')}</Button>
                  </footer>
                :
                  <footer>
                    <Button type={'primary'} size="large" className='start' onClick={() => onMeetingStart(true)}>{t('Starten')}</Button>
                    <Button type={'primary'} size="large" className='edit' onClick={onEditMeeting}>{t('Bearbeiten')}</Button>
                    <Dropdown overlay={menu} trigger={['click']} placement={'topLeft'}>
                      <Button type={'link'} size={'large'} className='more'>
                        <img src={moreIcon} alt={''}/>
                      </Button>
                    </Dropdown>
                  </footer>
                }
              </div>
            }
          </div> :
          <div className="multi-selection-app-icon">
						<img src={iconMultiSelectionAppointments} alt={''}/>
					</div>}
        </TabPane>

        {!isAppointmentsRoute(history.location.pathname) &&
          <TabPane tab={
            <>
              <svg viewBox="0 0 24 24">
                <path d="M15,2.5H9c-3.9,0-7,3.1-7,7c0,2.8,1.6,5.3,4.1,6.4v5c0,0.3,0.2,0.5,0.4,0.6c0.1,0,0.2,0,0.3,0c0.2,0,0.4-0.1,0.5-0.2 l4.4-4.8H15c3.9,0,7-3.1,7-7S18.9,2.5,15,2.5L15,2.5z M15,15.4h-3.7l-4,4.4v-4.6L6.9,15c-3-1.2-4.6-4.6-3.4-7.6 C4.4,5.1,6.6,3.6,9,3.6h6c3.2,0,5.9,2.6,5.9,5.9S18.3,15.4,15,15.4L15,15.4z M16.3,8.5c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1 c0.6,0,1-0.4,1-1c0,0,0,0,0,0C17.3,8.9,16.9,8.5,16.3,8.5z M12,8.5c-0.6,0-1,0.5-1,1s0.5,1,1,1c0.6,0,1-0.5,1-1S12.6,8.5,12,8.5 L12,8.5z M7.7,8.5c-0.6,0-1,0.5-1,1s0.5,1,1,1c0.6,0,1-0.5,1-1S8.2,8.5,7.7,8.5C7.7,8.5,7.7,8.5,7.7,8.5z"/>
              </svg>
              Chat
            </>
        } key={ActiveTab.SECOND} disabled={!chatActive}><Chat messages={messages} onSendMessage={onSendMessage} onSendFile={onSendFile} toggleDetailsView={onToggleDetailsView} meetingInfo={meetingInfo} meeting={meeting} onReadMessages={onOpenChat}/>
          </TabPane>
        }
      </Tabs>
    </div>
  )
}
