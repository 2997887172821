import * as React from 'react';
import './CheckConnectionTemplate.scss';
import { useTranslation } from "react-i18next";
import { Button, Steps } from "antd";
import { DeviceType, Dimensions, NetworkConnectionStatus } from '../../util/enums';
import MicrophoneSettingsSVG from './svg/MicrophoneSettingsSVG';
import VideoSettingsSVG from './svg/VideoSettingsSVG';
import ConnectionSVG from './svg/ConnectionSVG';
import { getApplicationSizeClass } from '../../util/utils';
import { useRecoilValue } from 'recoil';
import { applicationSize } from '../../state/atoms';
import mainLogo from '../../img/noventi-video-logo.png';
import chairIcon from '../../img/icons/chair.svg';
import DeviceErrorModal from '../appointment/DeviceErrorModal';
import StartPageFooter from '../start/StartPageFooter';

interface CheckConnectionTemplateProps {
  currentStep: number;
  errorDeviceType: DeviceType;
  finished: boolean;
  audioStatus: string;
  cameraStatus: string;
  networkStatus: string;
  onStart: () => void;
  onAbort: () => void;
  onRefresh: () => void;
}

export default function CheckConnectionTemplate({ currentStep, audioStatus, cameraStatus, networkStatus, errorDeviceType, finished, onStart, onAbort, onRefresh }: CheckConnectionTemplateProps) {
  const { t } = useTranslation();

  const { Step } = Steps;
  const appSize = useRecoilValue<Dimensions>(applicationSize);

  return (
    <div className={`prepare ${getApplicationSizeClass(appSize)}`} >
      <div className={'logo-wrapper'}>
        <img className={'logo'} src={mainLogo} alt={'logo'} />
      </div>
      <div className="content-wrapper">
        <div className="bullets">
          <div className={currentStep === 0 ? 'active' : ''}></div>
          <div className={currentStep === 1 ? 'active' : ''}></div>
          <div className={currentStep === 2 ? 'active' : ''}></div>
        </div>
        <div><h1>{t('Bitte warten')}</h1></div>
        <div><h3>{t('Wir überprüfen Ihre Einstellungen')}</h3></div>
        <div>
          <Steps direction="vertical" current={currentStep}>
            <Step className={networkStatus === NetworkConnectionStatus.PASSED ? 'goodNetworkConnection' : 'poorNetworkConnection'}
              title={t('Internet-Geschwindigkeitstest')} description={networkStatus}
              icon={<ConnectionSVG />} />
            <Step className={`mic ${audioStatus === 'Erlaubt' ? 'allowed' : 'not-allowed'}`}
              title={t('Browser Zugriff auf Ihr Mikrofon')} description={audioStatus}
              icon={<MicrophoneSettingsSVG />} />
            <Step className={cameraStatus === 'Erlaubt' ? 'allowed' : 'not-allowed'}
              title={t('Browser Zugriff auf Ihr Kamera')} description={cameraStatus}
              icon={<VideoSettingsSVG />} />
          </Steps>
        </div>
        {finished &&
          <div><Button className={'search-contact-btn'} size={'middle'} onClick={onAbort}>{t('Done')}</Button></div>}
        <div className='chairs'>
          <img src={chairIcon} className='about-img' alt='star' />
          <img src={chairIcon} className='about-img' alt='star' />
          <img src={chairIcon} className='about-img' alt='star' />
        </div>
      </div>
      <footer>
        <StartPageFooter />
      </footer>

      {errorDeviceType && <DeviceErrorModal deviceType={errorDeviceType} onStart={onStart} onAbort={onAbort} onRefresh={onRefresh} />}
    </div>
  )
}
