import {atom} from "recoil";
import {MeetingDtoTypeEnum, MemberDtoPermissionsEnum, MemberDtoRolesEnum, PublicMeetingDtoTypeEnum, SettingsDto} from "../gen/client";
import {ActiveTab, DeviceType} from '../util/enums';
import {CameraPosition, RemoteStream} from "../util/types";
import {EMPTY_MEETING_INFO, VideoSettings} from "../util/constants";
import {getAppSize} from "../util/utils";

export const UNLOGGED_MEMBER = {
  logged: false,
  avatar: null as string,
  id: null as string,
  name: 'Frank Mustermann',
  email: 'frank.musterman@noventi.de',
  roles: [] as MemberDtoRolesEnum[],
  activeRole: null as MemberDtoRolesEnum,
  isAdmin: false,
  permissions: [] as MemberDtoPermissionsEnum[],
  skipPreview: false,
  showLoginHint: false,
  millisTillTokenExpire: 0
};

export const loggedMember = atom({
  key: 'loggedMember',
  default: UNLOGGED_MEMBER
});

export const activePractice = atom({
  key: 'activePractice',
  default: {
    id: '',
    name: ''
  }
});

export const loginData = atom({
  key: 'loginData',
  default: {
    email: '',
    password: ''
  }
});

export const applicationSize = atom({
  key: 'applicationSize',
  default: getAppSize()
});

export const logoutEvents = atom({
  key: 'logoutEvents',
  default: [] as (() => Promise<string>)[]
});


export const camerasState = atom({  key: 'camerasState',  default: null as MediaDeviceInfo[]});
export const microphonesState = atom({  key: 'microphonesState',  default: null as MediaDeviceInfo[]});
export const speakersState = atom({  key: 'speakersState',  default: null as MediaDeviceInfo[]});
export const audioTracksState = atom({  key: 'audioTracksState',  default: [] as MediaStreamTrack[]});
export const videoTracksState = atom({  key: 'videoTracksState',  default: [] as MediaStreamTrack[]});
export const activeParticipantIdsState = atom({key: 'activeParticipantIdsState', default: [] as string[]});
export const cameraOffState = atom({key: 'cameraOffState', default: false});
export const userSelectedCameraOffState = atom({key: 'userSelectedCameraOffState', default: false});
export const cameraBrokenState = atom({key: 'cameraBrokenState', default: false});
export const confirmStartWithoutCameraState = atom({key: 'confirmStartWithoutCameraState', default: false});
export const meetingOwnerState = atom({key: 'meetingOwnerState', default: false});
export const shareScreenState = atom({key: 'shareScreenState', default: false});
export const audioMutedState = atom({key: 'audioMutedState', default: false});
export const presentationModeState = atom({key: 'presentationModeState', default: false});
export const userSelectedPresentationModeState = atom({key: 'userSelectedPresentationModeState', default: false});
export const cameraPositionState = atom({key: 'cameraPositionState', default: CameraPosition.USER});
export const meetingInfoState = atom({key: 'meetingInfoState', default: EMPTY_MEETING_INFO});
export const remoteStreamsState = atom({key: 'remoteStreamsState', default: [] as RemoteStream[]});
export const errorDeviceTypeState = atom({key: 'errorDeviceTypeState', default: null as DeviceType});
export const settingsState = atom({key: 'settingsState', default: {} as SettingsDto});
export const meetingTypeState = atom({key: 'meetingTypeState', default: null as MeetingDtoTypeEnum | PublicMeetingDtoTypeEnum});
export const meetingParticipantsState = atom({key: 'meetingParticipantsState', default: 0});
export const detailsViewState = atom({key: 'detailsViewState', default: false});
export const settingsViewState = atom({key: 'settingsViewState', default: false});
export const meetingActiveTabState = atom({key: 'meetingActiveTabState', default: ActiveTab.FIRST});
export const videoSettingsState = atom({key: 'videoSettingsState', default: VideoSettings.MEDIUM_GROUP});
