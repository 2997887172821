import * as React from 'react';
import './ContactEdit.scss';
import {useTranslation} from 'react-i18next';
import {Button} from 'antd';
import {AccountDto} from '../../gen/client';
import { useEffect, useState } from 'react';
import { isValidEmail, isValidNumber } from "../../util/validators";
import Account from '../appointment/Account';
import { DEFAULT_PHONE_PREFIX } from '../../util/constants';
import {errorMessage, notificationChannelProvided} from '../../util/utils';

interface ContactEditProps {
  contact: AccountDto;
  onSave: (account: AccountDto) => void;
  onCancel: () => void;
}

export default function ContactEdit({contact, onSave, onCancel}: ContactEditProps) {
  const { t } = useTranslation();

  const [nameErr, setNameErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const [account, setAccount] = useState({} as AccountDto);

  useEffect(() => { if (contact && !!contact.id) {setAccount(contact)}}, [contact]);

  function validate(): boolean {
    if (!account.name) {
      setNameErr(true)
      return false
    }
    return notificationChannelProvided(account)
  }

  function save() {
    if (!validate()) {
      errorMessage(t('Invalid fields'));
      return;
    }
    onSave(account);
  }

  function validateAccountName(value: string) {
    setNameErr(!value);
  }

  function validateAccountPhone(value: string, required: boolean) {
   setPhoneErr(value && value !== DEFAULT_PHONE_PREFIX ? !isValidNumber(value) : required);
  }

  function validateAccountEmail(value: string, required: boolean) {
    setEmailErr(value ? !isValidEmail(value) : required);
  }

  function onParticipantChange(updated: AccountDto) {
    const newA = {...account, ...updated};
    setAccount({...account, ...updated});
    setCanSave(notificationChannelProvided(newA) && !!newA.name);
  }

  return (
    <div className='content'>
      <div className='edit contact'>
        <h3>{t('Kontakt bearbeiten')}</h3>
        <Account
            it={account}
            idx={0}
            nameErr={nameErr}
            phoneErr={phoneErr}
            emailErr={emailErr}
            onNameChange={(_idx, value) => validateAccountName(value)}
            onPhoneChange={(_idx, value, req) => validateAccountPhone(value, req)}
            onEmailChange={(_idx, value, req) => validateAccountEmail(value, req)}
            onParticipantChange={(p) => onParticipantChange(p)} 
            saveErr={false} modal={false}/>  

        <div className='buttons'>
          <Button type={'primary'} size="large" className='speichern-button' onClick={save} disabled={!canSave}>{t('Speichern')}</Button>
          <Button type={'primary'} size="large" className='abbrechen-button' onClick={onCancel}>{t('Abbrechen')}</Button>
        </div>
      </div>
    </div>
  )
}
