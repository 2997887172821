import {Modal} from 'antd';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

interface RouteLeaveGuardProps {
  visible: boolean;
  message: string;
  okText?: string;
  cancelText?: string;
  onOk: () => void;
  onCancel: () => void;
}

export default function LeaveMeetingModal({visible, message, okText, cancelText, onOk, onCancel}: RouteLeaveGuardProps) {
  const {t} = useTranslation();

  return (
    <Modal className={'leave-modal'} okText={t(okText || 'yes')} cancelText={t(cancelText || 'no')} visible={visible} onOk={onOk} onCancel={onCancel} title={message}>
    </Modal>);
}


