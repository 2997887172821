import * as React from 'react';
import './CMSLine.scss';
import {Button} from 'antd';
import {ReactComponent as editIcon} from '../../img/icons/edit-icon.svg';
import {NotificationDto, NotificationDtoTypeEnum} from '../../gen/client';
import Icon from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {useRecoilState} from "recoil";
import {loggedMember} from "../../state/atoms";
import { isAdminOrCustomerCare } from '../../util/utils';

interface NotificationLineProps {
  notification: NotificationDto;
  onClick: (id: NotificationDto) => void;
}

export default function NotificationLine({notification, onClick}: NotificationLineProps) {
  const {t} = useTranslation();
  const [logged] = useRecoilState(loggedMember);

  return (
    <div className='vd-line'>
      <div className='col-1 notification-name'>
        {t(notification.name)}
      </div>
      <div className='col-3 flx'>
        {isAdminOrCustomerCare(logged) && <div className={`label ${notification.adminOnly ? 'private' : 'public'}`}>
          {t(notification.adminOnly ? 'private' : 'public')}
        </div>}

        <div className={`label ${notification.type === NotificationDtoTypeEnum.Sms ? 'sms' : 'email'}`}>
          {t(notification.type)}
        </div>
      </div>
      <div className={'actions'}>
        <Button type={'link'} className="icon-only" onClick={() => onClick(notification)}>
          <Icon component={editIcon} alt={'edit'}/>
        </Button>
      </div>
    </div>
  )
}
