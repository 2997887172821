import * as React from 'react';
import './CMSLine.scss';
import {Button} from 'antd';
import {ReactComponent as editIcon} from '../../img/icons/edit-icon.svg';
import {ReactComponent as printIcon} from '../../img/icons/print-icon.svg';
import {CmsDtoAccessEnum, CmsDto, MemberDtoRolesEnum} from '../../gen/client';
import Icon from '@ant-design/icons';
import {useRecoilState} from 'recoil';
import {loggedMember} from '../../state/atoms';
import { useTranslation } from 'react-i18next';
import { isAdminOrCustomerCare } from '../../util/utils';
import {ReactComponent as exportIcon} from '../../img/icons/export-icon.svg';

interface CMSLineProps {
  cms: CmsDto;
  onClick: (id: number) => void;
  onPrint: () => void;
  onExport: () => void;
  access?: CmsDtoAccessEnum;
}

const AFTER_REG_QUESTIONS = "After register questions";

export default function CMSLine({cms, onClick, access, onPrint, onExport}: CMSLineProps) {
	const { t } = useTranslation();
  const [logged] = useRecoilState(loggedMember);

  return (
    <div className='vd-line'>
      <div className='col-3'>
        {t(cms.name)}
      </div>
      <div className='col-1'>
        {isAdminOrCustomerCare(logged) && <div className={`label ${(access || cms.access) === CmsDtoAccessEnum.Public ? 'public' : 'private'}`}>
          {t(access || cms.access)}
        </div>}
      </div>
      <div className={'actions'}>
        {
          (cms.name !== AFTER_REG_QUESTIONS || (cms.name === AFTER_REG_QUESTIONS && !!logged.roles.find(it => it === MemberDtoRolesEnum.Admin))) &&
          <div>
            <Button type={'link'} className="icon-only"><Icon component={editIcon} onClick={() => onClick(cms.id)}/></Button>
          </div>
        }
        {
          cms.name === AFTER_REG_QUESTIONS &&
          <div>
            <Button type={'link'} className="icon-only"><Icon component={printIcon} onClick={onPrint}/></Button>
          </div>
        }
        <div>
          <Button type={'link'} className="icon-only"><Icon component={exportIcon} onClick={onExport}/></Button>
        </div>
      </div>
    </div>
  )
}
