import * as React from 'react';
import './DurationSlider.scss';
import {useTranslation} from "react-i18next";
import {Slider} from "antd";

interface DurationSliderProps {
  value: number;
  onChange: (val: number) => void;
}

export default function DurationSlider({value, onChange}: DurationSliderProps) {
  const {t} = useTranslation();

  return (
    <div className={'duration-slider ant-form-item'}>
      <label className={'req'}>{t('Dauer')}</label>
      <div className={'spin'}>
        <Slider step={5} max={120} min={5} value={value} onChange={(val: number) => onChange(val)} />
        <span className={'val'}>{value} {t('Min')}</span>
      </div>
    </div>
  )
}
