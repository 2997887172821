import * as React from 'react';

export default function HelpIcon() {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M12,3.8c4.5,0,8.2,3.7,8.2,8.2s-3.7,8.2-8.2,8.2S3.8,16.5,3.8,12C3.8,7.5,7.5,3.8,12,3.8L12,3.8 M12,2C6.5,2,2,6.5,2,12 s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z"/>
      <path
        d="M11.9,14.1h-0.7c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0,0,0c0-0.6,0-1.3,0.1-1.9c0.1-0.3,0.2-0.6,0.5-0.8c0.2-0.2,0.4-0.4,0.6-0.6 c0.2-0.2,0.5-0.4,0.7-0.6c0.4-0.3,0.4-0.8,0.1-1.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.7-0.4-1.5-0.1-1.9,0.6c0,0,0,0.1-0.1,0.1 c0,0.1-0.1,0.2-0.1,0.4c0,0.2-0.1,0.2-0.3,0.2L8.9,9.7c-0.1,0-0.2-0.1-0.2-0.3c0,0,0,0,0,0C8.8,8.1,9.8,7,11.1,6.8 C12,6.6,12.8,6.7,13.6,7c0.7,0.3,1.3,0.9,1.5,1.6c0.3,0.7,0.2,1.5-0.3,2.1c-0.3,0.4-0.7,0.8-1.2,1.1c-0.1,0.1-0.3,0.2-0.4,0.4 c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0,0.6,0,0.9c0,0.1-0.1,0.3-0.2,0.3c0,0,0,0-0.1,0L11.9,14.1L11.9,14.1z"/>
      <path d="M13,16.3c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1S13,15.7,13,16.3L13,16.3z"/>
    </svg>
  )
}
