/**
* This file is generated
*/
import * as React from 'react';
import { CSSProperties } from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24" xmlSpace="preserve"><path d="M19.2,20.2h-1.4v-2.5c0-1.4-0.6-2.7-1.7-3.6L13.4,12L16,9.9c1.1-0.9,1.7-2.2,1.7-3.6V3.8h1.4c0,0,0,0,0,0
	c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9H4.8C4.3,2,3.9,2.4,3.9,2.9c0,0.5,0.4,0.9,0.9,0.9h1.5v2.5C6.2,7.7,6.8,9,8,9.9
	l2.6,2.1L8,14.1c-1.1,0.9-1.7,2.2-1.7,3.6v2.5H4.8c-0.5,0-0.9,0.4-0.9,0.9c0,0.2,0.1,0.5,0.2,0.6C4.3,21.9,4.5,22,4.8,22h0h14.4
	c0.2,0,0.5-0.1,0.6-0.2c0.2-0.2,0.3-0.4,0.3-0.6C20.1,20.7,19.7,20.2,19.2,20.2z M9.1,8.5C8.4,8,8,7.1,8,6.3V3.8h8v2.5
	c0,0.9-0.4,1.7-1.1,2.3L12,10.9L9.1,8.5z M16,20.2H8v-2.5c0-0.9,0.4-1.7,1.1-2.2l2.9-2.4l2.9,2.3c0.7,0.5,1.1,1.4,1.1,2.3V20.2z"/></svg>
);

const HourglassIcon = (props: { style?: CSSProperties, className?: string }) => <Icon component={svg} {...props}/>;

export default HourglassIcon;