import * as React from 'react';
import './Contacts.scss';
import {useState, useEffect, createRef, useCallback} from 'react';
import {useTranslation} from "react-i18next";
import Container from '../general/Container';
import ContactsHeader from './ContactsHeader';
import {ViewMode, SortDirection} from '../../util/enums';
import ContactLine from './ContactLine';
import {Card, Checkbox, Button, Modal} from 'antd';
import {ReactComponent as femaleIcon} from '../../img/icons/gender-female-icon.svg';
import {ReactComponent as maleIcon} from '../../img/icons/gender-male-icon.svg';
import {ReactComponent as diversIcon} from '../../img/icons/gender-divers-icon.svg';
import {ReactComponent as videoIcon} from '../../img/icons/video-outline-icon.svg';
import {ReactComponent as editIcon} from '../../img/icons/edit-icon.svg';
import {ReactComponent as deleteIcon} from '../../img/icons/trash-icon.svg';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {generateCharArray, sortDescendingByName, sortAscendingByName, downloadFileUsingBlob, successMessage} from '../../util/utils';
import ContactInfo from './ContactInfo';
import { Routes, isNewContactRoute, isSearchRoute, isContactRoute } from '../../util/routes';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilState} from "recoil";
import {activePractice, loggedMember} from "../../state/atoms";
import PracticeApi from "../../api/PracticeApi";
import {handleServerError} from "../../util/ErrorHandler";
import {
  AccountDto, AccountDtoGenderEnum,
  MemberCountDtoKeyEnum
} from "../../gen/client";
import Icon from '@ant-design/icons';
import MemberApi from '../../api/MemberApi';
import {ReactComponent as iconMultiSelectionContacts} from "../../img/icons/icon-multi-selection-contacts.svg";
import ContactSelectionBar from './ContactSelectionBar';
interface ReferenceProp {
  [key: string]: any;
}

export default function Contacts() {
  const {t} = useTranslation();
  const history = useHistory();
  const {confirm} = Modal;
  const {id} = useParams();

  const [logged] = useRecoilState(loggedMember);
  const [practice] = useRecoilState(activePractice);

  const [viewMode, setViewMode] = useState(ViewMode.LIST);
  const [selected, setSelected] = useState([] as string[]);
  const [references, setReferences] = useState([] as ReferenceProp[]);
  const letters = generateCharArray('A', 'Z');
  const [list, setList] = useState([] as AccountDto[]);
  const [filter, setFilter] = useState(MemberCountDtoKeyEnum.All);

  const getContacts = useCallback(() => {
    if (!practice.id || !logged.id) return;
    if (!isSearchRoute(history.location.pathname)) {
      PracticeApi.getAccounts(practice.id, logged.id)
        .then(resp => setList(resp.data))
        .catch(err => handleServerError(err));
    } else {
      const query = new URLSearchParams(history.location.search);
      const key = query.get('key');
      MemberApi.fullSearchForProvider(logged.id, key)
        .then(resp => setList(resp.data.participants))
        .catch(err => handleServerError(err))
    }
  }, [practice.id, history.location.pathname, history.location.search, logged.id]);

  const scrollToItem = useCallback((itemId: string) => {
    if (references && (references as ReferenceProp)[itemId]) {
      (references as ReferenceProp)[itemId].current?.scrollIntoView({ // NOSONAR
        behavior: 'smooth',
        block: "center",
        inline: "nearest"
      });
    }
  }, [references]);

  const onLineClick = useCallback((contactId: string) => {
    if (selected.length === 1 && selected.indexOf(contactId) > -1) {
      history.push(Routes.CONTACTS);
      setSelected([]);
    } else if (selected.length <= 1) {
      history.push(`${Routes.CONTACTS}/${contactId}`);
    }
  }, [history, selected]);

  useEffect(getContacts, [practice, getContacts]);

  useEffect(() => {
    setReferences(list.reduce((acc: any, value: AccountDto) => {
      acc[value.id] = createRef();
      return acc;
    }, {}));
  }, [list]);

  useEffect(() => {
    if (isSearchRoute(history.location.pathname)) {
      return;
    }

    if (isNewContactRoute(history.location.pathname)) {
      setSelected(['new']);
      return;
    }

    if (selected.length === 1 && selected[0] === id) {
      return;
    }

    if(selected.length > 0 && list.filter(participant => selected.indexOf(participant.id) > -1).length === 0) {
    	setSelected([]);
    	return;
		}

    if (!history.location.search.includes('edit')) {
      if (id && selected.length < 2) {
        setSelected([id]);
      }
    } else if (selected.length === 0 && id) {
      setSelected([id]);
    }

    if (selected.length > 1 && isContactRoute(history.location.pathname)) {
      history.push(Routes.CONTACTS);
    }
  }, [history.location.pathname, id, selected, history, list]);

  useEffect(() => {
    if (!id) return;
    setTimeout(() => {
      scrollToItem(id);
    }, 100);
    let contact = list.find(it => it.publicId === id);
    if (contact) {
      onLineClick(contact.id);
    }
  }, [id, list, scrollToItem, onLineClick]);

  function handleLetterOption(letter: string) {
    const contacts = list.filter(contact => contact.name.startsWith(letter.toUpperCase()) || contact.name.startsWith(letter.toLowerCase()));
    if (contacts && contacts.length > 0) {
      scrollToItem(contacts[0].id);
    }
  }

  function onSelectToggle(contactId: string) {
    let contactIds: string[];
    if (selected.indexOf(contactId) > -1) {
      contactIds = selected.filter(it => it !== contactId);
    } else {
      contactIds = [...selected, contactId];
    }
    setSelected(contactIds);
    if (contactIds.length === 1) {
      onLineClick(contactIds[0]);
    }
  }

  function onToggleAll(event: CheckboxChangeEvent) {
    if (event.target.checked) {
      setSelected([...list.map(it => it.id)]);
    } else {
      setSelected([]);
    }
  }

  function confirmPrintPatientData(participant: AccountDto, blob: Blob) {
    Modal.info({
      title: t('Der Löschvorgang ist in der folgenden Datei dokumentiert'),
      okText: t('Drücken'),
      okType: 'primary',
      onOk() {
        printPatientData(participant, blob);
      }
    });
  }

  function deleteParticipant(participant: AccountDto) {
    if (selected.length === 1 && selected.indexOf(participant.id) > -1) {
      setSelected([]);
    }
    PracticeApi.deleteContacts(practice.id, [participant.publicId])
      .then((response) => {
        setList([...list.filter(it => it.id !== participant.id)]);
        successMessage(t('Succesfully deleted'));
        confirmPrintPatientData(participant, response.request.response);
      })
      .catch(handleServerError);
  }

  function deleteParticipants(publicIds: string[], document: Blob) {
    setList([...list.filter(it => !publicIds.includes(it.publicId))]);
    confirmPrintPatientData({}, document);
  }

  function printPatientData(participant: AccountDto, blob: Blob) {
    downloadFileUsingBlob(blob, participant.publicId ? `${participant.publicId}.pdf` : 'all.zip');
  }

  function sortContacts(direction: SortDirection) {
    const sortedList = (direction === SortDirection.Asc) ? sortAscendingByName(list) : sortDescendingByName(list);
    setList([...sortedList]);
  }

  function onContactSave(account: AccountDto) {
    PracticeApi.createAccount(practice.id, account).then(() => {
      successMessage(t('Erfolgreich gespeichert!'));
      getContacts();
    }).catch(handleServerError)
  }

  function onBackPush() {
    setSelected([]);
    history.push(Routes.CONTACTS);
  }

  function onVideoIconClick(event: React.MouseEvent<HTMLElement, MouseEvent>, participant: AccountDto) {
    event.stopPropagation();
    history.push(`${Routes.APPOINTMENTS_NEW_SINGLE}?publicId=${participant.publicId}`);
  }

  function onEditIconClick(event: React.MouseEvent<HTMLElement, MouseEvent>, participant: AccountDto) {
    event.stopPropagation();
    history.push(`${Routes.CONTACTS}/${participant.id}?edit`);
  }

  function onDeleteIconClick(event: React.MouseEvent<HTMLElement, MouseEvent>, participant: AccountDto) {
    event.stopPropagation();

    if (selected.length < 2) {
      confirm({
        title: t('Are you sure you want to delete {{name}}?', {name: participant.name}),
        content: t('{{name}} will no longer be in your list.', {name: participant.name}),
        okText: t('Ja'),
        okType: 'danger',
        cancelText: t('Nein'),
        onOk() {
          deleteParticipant(participant);
        }
      });
    }
  }

  const gridItems = list.map(contact => (
    <div key={contact.id} onClick={() => setSelected([contact.id])}>
      <Card.Grid className={`gridStyle ${selected.indexOf(contact.id) > -1 ? 'active' : ''}`} hoverable={false}>
        <div className={`row ${contact.phone ? 'has-phone' : 'no-phone'}`} ref={(references as ReferenceProp)[contact.id]}>
          <Checkbox className='checkbox' checked={selected.indexOf(contact.id) > -1}
                    onChange={() => onSelectToggle(contact.id)}/>
          <span className='contact-name'><span>{contact.name}</span>
              <Icon
                className={`icon-only gender ${contact.gender === AccountDtoGenderEnum.Female ? 'female-icon' : contact.gender === AccountDtoGenderEnum.Male ? 'male-icon' : 'divers-icon'}`}
                component={contact.gender === AccountDtoGenderEnum.Female ? femaleIcon : contact.gender === AccountDtoGenderEnum.Male ? maleIcon : diversIcon} alt={''}/>
          </span>
          <div className='phone-camera-wrapper'>
            <span className='col-1 phone'>{contact.phone}</span>
          </div>
          <div className="email">
            <Button type={'link'} className='link'>{contact.email}</Button>
          </div>
          <div className="actions">
            <Button type={'link'} className="icon-only" onClick={(e) => onVideoIconClick(e, contact)} disabled={selected.length > 1}><Icon component={videoIcon} alt={'video'}/></Button>
            <Button type={'link'} className="icon-only" onClick={(e) => onEditIconClick(e, contact)} disabled={selected.length > 1}><Icon component={editIcon} alt={'edit'}/></Button>
            <Button type={'link'} className="icon-only" onClick={(e) => onDeleteIconClick(e, contact)}><Icon component={deleteIcon} alt={'delete'}/></Button>
          </div>
        </div>
      </Card.Grid>
    </div>
  ));

  return (
    <Container className={`contacts full vd-list ${selected && selected.length === 1 ? 'item-selected' : ''}`}>
      <ContactsHeader onToggleAll={onToggleAll} onChangeViewMode={setViewMode} shortView={selected.length >= 1} onSortChange={sortContacts} filter={filter} onFilterChange={setFilter}
                      isAdmin={logged.isAdmin} emptyContactList={list.length === 0} memberView={false} membersCount={list.length} updated={false} onClearSearch={() => {
      }}/>

      <div className={`content ${(selected && selected.length === 1) ? 'edit' : ''}`}>
        {(viewMode === ViewMode.LIST || selected.length >= 1) &&
        <div className={'contact-list'}>
          {list.map(it =>
            <ContactLine key={it.id}
                         contact={it}
                         selected={selected.indexOf(it.id) > -1}
                         onSelectToggle={onSelectToggle}
                         reference={(references as ReferenceProp)[it.id]}
                         shortView={selected.length >= 1}
                         onClick={onLineClick}
                         multipleSelection={selected.length > 0}
                         onVideoIconClick={(e) => onVideoIconClick(e, it)}
                         onEditIconClick={(e) => onEditIconClick(e, it)}
                         onDeleteIconClick={(e) => onDeleteIconClick(e, it)}/>)}
        </div>}

        {(viewMode === ViewMode.GRID && !selected.length) &&
        <div className='contact-list'>
          <Card className='grid-contacts'>
            <div className="grid-items-wrapper">{gridItems}</div>
          </Card>
        </div>}

        <div className='letters-bar'>
          {letters.map(it => <div key={it}>
            <Button className='letter-btn' onClick={() => handleLetterOption(it)}>{it}</Button>
          </div>)}
        </div>

        {(selected && selected.length === 1) &&
        <div className='info'>
          <ContactInfo onBackPush={onBackPush} onContactSave={onContactSave} contact={list.filter(it => it.id === selected[0])[0]}/>
        </div>}

        {(selected && selected.length > 1) &&
          <div className="multi-selection-app-icon">
            <Icon component={iconMultiSelectionContacts} alt={''}/>
          </div>
          }
          
      </div>
      {selected.length > 1 && <ContactSelectionBar contacts={list} selected={selected} onSelectedChange={setSelected} onDeleteContacts={deleteParticipants} />}
    </Container>
  )
}
