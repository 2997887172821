import {MeetingDto, MeetingDtoPermissionsEnum, MeetingDtoTypeEnum, PublicMeetingDto} from '../gen/client';
import {
  AttachmentMetadata,
  Bandwidth,
  ConfirmParams,
  Connection,
  ConnectionFeedback,
  ConnectParams,
  EndParams,
  InternalMessage,
  InternalMessageType,
  JoinParams,
  MeetingInfo,
  MeetingSession,
  OnConnectedCallback,
  OnConnectionFeedbackCallback,
  OnInternalMessageCallback,
  OnMeetingInfoCallback,
  OnMessageCallback,
  OnParticipantJoinedCallback,
  OnStreamCallback,
  StartParams,
  StreamEvent,
  TimelineEvent
} from '../util/types';
import {Message} from "../components/appointment/Chat";
import {MeetingMessage} from "../websocket/AbstractWebSocket";
import {MeetingWebSocket} from "../websocket/MeetingWebSocket";
import Storage from "../util/Storage";
import adapter from 'webrtc-adapter';
import {stopTracks} from './DeviceService';
import moment from 'moment';
// @ts-ignore
import {WebRTCStats} from '@peermetrics/webrtc-stats';
import {errorMessage, groupBy, isOwner, warnMessage} from "../util/utils";
import {NetworkConnectionStatus} from "../util/enums";
import {CONNECT_USING_LINK, LARGE_MEETING_THRESHOLD} from "../util/constants";
import {env} from "../env/env";

let forceRelay = true;

const isStage = () => {
  return window.location.href.indexOf('staging-') > -1;
}

const getStunUrl = () => {
  return isStage() ? 'stun:staging-stun.noventi-video.de' : 'stun:stun.noventi-video.de';
}

const getTurnUrl = () => {
  return isStage() ? 'turn:staging-turn.noventi-video.de:3478?transport=udp' : 'turn:turn.noventi-video.de:3478?transport=udp';
}

const peerConnectionConfig = () => ({
  iceServers: [
    {
      'urls': [
        getStunUrl(),
        getTurnUrl(),
      ],
      "credential": "Zxjd3#kddp99PJs84PKdaEZZS",
      "username": "turn-test",
    }
  ],
  iceCandidatePoolSize: 4,
  iceTransportPolicy: forceRelay ? 'relay' : 'all'
} as RTCConfiguration);

const useSenderParams = (adapter.browserDetails.browser === 'chrome' ||
    adapter.browserDetails.browser === 'safari' ||
    (adapter.browserDetails.browser === 'firefox' &&
      adapter.browserDetails.version >= 64)) &&
  'RTCRtpSender' in window &&
  'setParameters' in window.RTCRtpSender.prototype

// const supportsSetCodecPreferences = window.RTCRtpTransceiver && 'setCodecPreferences' in window.RTCRtpTransceiver.prototype;
// const videoCodecPreferences = [{
//   clockRate: 90000,
//   mimeType: 'video/VP8'
// }] as RTCRtpCodecCapability[];
//
// const audioCodecPreferences = [{
//   clockRate: 48000,
//   mimeType: 'audio/opus',
//   channels: 1
// }] as RTCRtpCodecCapability[];
let meetingInfo: MeetingInfo = null;

let webrtcStats = null as WebRTCStats;
let socket: MeetingWebSocket;
const chunkSize = 16384;
const initPeerConfig = Promise.resolve();

export let connections: { [id: string]: Connection; } = {};
let receiveBuffer: any[] = [];
let fileDetails: any;

let online = true;
let isGroupMeeting = true;
let isProvider = false;
let numberOfParticipants = 1;

export function markAsSingleMeeting() {
  isGroupMeeting = false;
  forceRelay = false;
}

export function markAsProvider() {
  isProvider = true;
}

export function declareNumberOfParticipants(participants: number) {
  numberOfParticipants = participants;
}

let connect = () => {
};
let emitLogout = () => {
};
let emitConnectionFeedback = (f: ConnectionFeedback) => {
};

export const reconnect = (showErrorMsg: boolean = true, now: boolean = false, cannotContactServer: boolean = false) => {
  setTimeout(() => {
    if (showErrorMsg) {
      errorMessage(NetworkConnectionStatus.NONE, 2);
    }
    emitLogout();
    closeConnection();
    forceRelay = true;
    connect();
  }, now ? 0 : cannotContactServer ? 5000 : 250);
}

// TODO: remove this
// @ts-ignore
window["reconnect"] = reconnect;

export function joinRtc(
  meetingId: string,
  localStream: MediaStream,
  onStream: OnStreamCallback,
  onStreamLogout: OnStreamCallback,
  onDataChannelOpen: OnConnectedCallback,
  onMessage: OnMessageCallback,
  onParticipantJoined: OnParticipantJoinedCallback,
  onInternalMessage: OnInternalMessageCallback,
  onMeetingInfo: OnMeetingInfoCallback,
  onConnectionFeedback: OnConnectionFeedbackCallback) {

  connect = () => {
    initSocket(meetingId, localStream, onStream, onStreamLogout, onDataChannelOpen, onMessage, onParticipantJoined, onInternalMessage, onMeetingInfo);
  };
  emitLogout = () => {
    onStreamLogout(null, null);
  };
  emitConnectionFeedback = onConnectionFeedback;

  initPeerConfig.then(connect);
}

function initSocket(meetingId: string,
                    localStream: MediaStream,
                    onStream: OnStreamCallback,
                    onStreamLogout: OnStreamCallback,
                    onDataChannelOpen: OnConnectedCallback,
                    onMessage: OnMessageCallback,
                    onParticipantJoined: OnParticipantJoinedCallback,
                    onInternalMessage: OnInternalMessageCallback,
                    onMeetingInfo: OnMeetingInfoCallback) {

  if (!socket) {
    log('initing socket');
    socket = new MeetingWebSocket();
  }

  socket.onMessage = message => {
    processWsMessage(message as MeetingMessage, localStream, onStream, onStreamLogout, onDataChannelOpen, onMessage, onParticipantJoined, onInternalMessage, onMeetingInfo);
  };

  socket.onOpen = function () {
    log('socket open')
    socket.send({
      type: StreamEvent.Join, receiver: '', data: {
        meetingId,
        machineId: Storage.getMachineId(),
        memberOrParticipantId: getMemberOrParticipantId(),
        owner: isOwner(),
        browser: adapter.browserDetails.browser,
        browserVersion: adapter.browserDetails.version
      } as JoinParams
    });
    log('rtc open');
  };

  socket.onClose = function (e) {
    log('rtc close');
    log(JSON.stringify(e));

    if (e && ((e.code !== 1000 && e.code !== 3000) || !e.wasClean)) {
      log(`restarting socket because code is ${e.code}`);

      emitConnectionFeedback(null);

      reconnect(true, false, true);
    } else {
      log('socket closed correctly');
    }
  };

  socket.onError = ev => {
    log(`socket error`);
    log(`${JSON.stringify(ev)}`);
  };
}

export function closeConnection() {
  log('Closing all connections...');
  if (socket) {
    if (connections) {
      Object.values(connections).forEach(connection => {
        try {
          if (connection.dc) {
            connection.dc.close();
          }
        } catch (e) {
          log(e);
        }

        try {
          if (connection.ms) {
            stopTracks(connection.ms.getTracks());
          }
        } catch (e) {
          log(e);
        }

        if (connection.pc) {
          connection.pc.close();
        }
      });
    }

    connections = {};

    socket.close();
    socket = null;
  }
}

export function stopMeeting(meetingId: string) {
  if (socket) {
    socket.send({type: StreamEvent.End, receiver: '', data: {meetingId} as EndParams});
    log('meeting end');
  }
}

function getMemberOrParticipantId() {
  const id = Storage.getParticipantId() || Storage.getMemberId();
  if (!id) {
    errorMessage(CONNECT_USING_LINK);

    window.location.href = '/';
  }
  return id;
}

export function startMeeting(meeting: MeetingDto | PublicMeetingDto) {
  socket.send({
    type: StreamEvent.Start, receiver: '', data: {
      meetingId: meeting.publicId,
      type: meeting.type,
      permissions: meeting.type === MeetingDtoTypeEnum.Multicast ? [MeetingDtoPermissionsEnum.Audio, MeetingDtoPermissionsEnum.Video, MeetingDtoPermissionsEnum.FileShare] : meeting.permissions
    } as StartParams
  });
  log('meeting started');
}

export function sendConnectionConfirm(meetingId: string, peerId: string) {
  socket.send({
    type: StreamEvent.Confirm, receiver: '', data: {
      meetingId,
      peerId
    } as ConfirmParams
  });
  log('send confirmation');
}

export function sendConnectionInfirm(meetingId: string, peerId: string) {
  if (socket) {
    socket.send({
      type: StreamEvent.Infirm, receiver: '', data: {
        meetingId,
        peerId
      } as ConfirmParams
    });
    log('send infirmation');
  } else {
    log('cannot send infirmation. socket is closed.');
  }
}

export function syncMediaStreamTracks(newLocalStream: MediaStream, uuid: string = null) {
  Object.keys(connections).forEach(peerId => {
    const connection = connections[peerId];
    if (!uuid || uuid === peerId) {
      const senders = connection.pc.getSenders();
      let videoReplaced = false;
      let audioReplaced = false;
      const newStreamHasVideo = !!newLocalStream.getVideoTracks().length;
      const newStreamHasAudio = !!newLocalStream.getVideoTracks().length;

      senders.forEach(sender => {
        if (!!newLocalStream && sender.track) {
          if (sender.track.kind === 'video') {
            const videoTracks = newLocalStream.getVideoTracks();

            if (videoTracks.length) {
              videoTracks.forEach(track => {
                videoReplaced = true;
                sender.replaceTrack(track).catch(log);
              });
            }
          } else if (sender.track.kind === 'audio') {
            newLocalStream.getAudioTracks().forEach(track => {
              audioReplaced = true;
              sender.replaceTrack(track).catch(log);
            });
          }
        }
      });

      if (!videoReplaced && newStreamHasVideo) {
        newLocalStream.getVideoTracks().forEach(track => {
          connection.pc.addTrack(track, newLocalStream);
        });
      }
      if (!audioReplaced && newStreamHasAudio) {
        newLocalStream.getAudioTracks().forEach(track => {
          connection.pc.addTrack(track, newLocalStream);
        });
      }
    }
  });
}

function processWsMessage(
  signal: MeetingMessage,
  localStream: MediaStream,
  onStream: OnStreamCallback,
  onStreamLogout: OnStreamCallback,
  onDataChannelOpen: OnConnectedCallback,
  onMessage: OnMessageCallback,
  onParticipantJoined: OnParticipantJoinedCallback,
  onInternalMessage: OnInternalMessageCallback,
  onMeetingInfo?: OnMeetingInfoCallback) {

  const signalString = JSON.stringify(signal);
  if (signalString.indexOf("\"type\":\"ping\"") < 0) {
    log(signalString);
  }

  switch (signal.type) {
    case StreamEvent.Connect:
      handleConnect(signal, localStream, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage);
      break;
    case StreamEvent.Logout:
      handleLogout(signal, localStream, onStreamLogout);
      break;
    case StreamEvent.Status:
      handleStatus(signal, onMeetingInfo);
      break;
    case StreamEvent.Joined:
      handleJoinedEvent(signal, onParticipantJoined);
      break;
    case StreamEvent.RequestRestartIce:
      handleRestartIce(signal, localStream, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage);
      break;
    case StreamEvent.RequestRestart:
      handleRequestRestart(signal, localStream, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage);
      break;
    case StreamEvent.Restart:
      handleRestart(signal, localStream, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage);
      break;
    case StreamEvent.Ping:
      handlePing(signal);
      break;
    case StreamEvent.Offer:
      handleOffer(signal, localStream, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage);
      break;
    case StreamEvent.Answer:
      handleAnswer(signal, localStream, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage);
      break;
    case StreamEvent.Ice:
      handleIce(signal, localStream, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage);
      break;
  }
}

function handlePing(signal: MeetingMessage) {
  if (socket) {
    socket.send({type: StreamEvent.Pong, receiver: '', data: signal.data});
  }
}

function handleRestart(signal: MeetingMessage, localStream: MediaStream, onStream: OnStreamCallback, onStreamLogout: OnStreamCallback, onMessage: OnMessageCallback, onDataChannelOpen: OnConnectedCallback, onInternalMessage: OnInternalMessageCallback) {
  const peerId = signal.sender;
  log(`[${peerId}] => handle restart`);

  handleLogout({data: {id: peerId}, type: null, receiver: null}, null, () => {
  });
}

function restartConnection(uuid: string, params: ConnectParams, localStream: MediaStream, onStream: OnStreamCallback, onStreamLogout: OnStreamCallback, onMessage: OnMessageCallback, onDataChannelOpen: OnConnectedCallback, onInternalMessage: OnInternalMessageCallback) {
  if (new Date().getTime() - connections[uuid].lastConnectionRestart < 1500) {
    log(`[${uuid}] Duplicate call to restart connection.`);
    return;
  }

  forceRelay = true;
  log(`[${uuid}] => handle restart connection. forceRelay=${forceRelay}`);

  if (connections[uuid] && connections[uuid].offerer) {
    socket.send({type: StreamEvent.Restart, receiver: uuid, data: {params}});

    handleLogout({data: {id: uuid}, type: null, receiver: null}, null, () => {
    }, false);

    getRTCPeerConnectionObject(uuid, localStream, params, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage, true);
  } else {
    socket.send({type: StreamEvent.RequestRestart, receiver: uuid, data: {params}});
  }
}

function handleRequestRestart(signal: MeetingMessage, localStream: MediaStream, onStream: OnStreamCallback, onStreamLogout: OnStreamCallback, onMessage: OnMessageCallback, onDataChannelOpen: OnConnectedCallback, onInternalMessage: OnInternalMessageCallback) {
  const uuid = signal.sender;
  const params = signal.data.params as ConnectParams;

  log(`[${uuid}] => handle request restart`);
  restartConnection(uuid, params, localStream, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage);
}

function handleRestartIce(signal: MeetingMessage, localStream: MediaStream, onStream: OnStreamCallback, onStreamLogout: OnStreamCallback, onMessage: OnMessageCallback, onDataChannelOpen: OnConnectedCallback, onInternalMessage: OnInternalMessageCallback) {
  const uuid = signal.sender;
  const params = signal.data.params as ConnectParams;

  log(`[${uuid}] => handle restart ice`);

  if (connections[uuid].expectingAnswer) {
    log(`[${uuid}] => not handling restart ice because we're already expecting an answer`);
  } else {
    log(`[${uuid}] => not expecting an answer. Handling restart ice`);

    getRTCPeerConnectionObject(uuid, localStream, params, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage, true);

    createOffer(uuid, params, true);
  }
}

function handleStatus(signal: MeetingMessage, onMeetingInfo?: OnMeetingInfoCallback) {
  if (!!onMeetingInfo) {
    onMeetingInfo(signal.data as MeetingInfo);
    meetingInfo = signal.data;
  }
}

function handleJoinedEvent(signal: MeetingMessage, onParticipantJoined: OnParticipantJoinedCallback) {
  if (!!onParticipantJoined) {
    onParticipantJoined(signal.data as MeetingSession);
  }
}

function handleConnect(signal: MeetingMessage, localStream: MediaStream, onStream: OnStreamCallback, onStreamLogout: OnStreamCallback, onMessage: OnMessageCallback, onDataChannelOpen: OnConnectedCallback, onInternalMessage: OnInternalMessageCallback) {
  const params = signal.data as ConnectParams;

  const timer = (ms: number) => new Promise(res => setTimeout(res, ms));

  async function connect() {
    let i = 0, length = params.peers.length;
    for (i; i < length; i++) {
      const uuid = params.peers[i];
      log(`[${uuid}] Create connection`);
      getRTCPeerConnectionObject(uuid, localStream, params, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage, true);
      await timer(1250);
    }
  }

  connect();
}

function handleLogout(signal: MeetingMessage, localStream: MediaStream, onStreamLogout: OnStreamCallback, closeSocket: boolean = true) {
  const meetingSession = signal.data as MeetingSession;
  const peerId = meetingSession.id;

  log(`[${peerId}] Handle logout`);

  if (connections[peerId] && connections[peerId].pc) {
    try {
      log(`[${peerId}] Removing this peer from the stats.`);
      webrtcStats.removeConnection({
        pc: connections[peerId].pc,
        peerId
      });
      log(`[${peerId}] Removed this peer from the stats.`);
    } catch (e) {
      try {
        webrtcStats.removeConnection({
          peerId
        });
        log(`[${peerId}] Removed this peer from the stats by peerId.`);
      } catch (e) {
      }
    }
    connections[peerId].pc.close();
  }
  delete connections[peerId];

  onStreamLogout(peerId, null);
}

function handleOffer(signal: MeetingMessage, localStream: MediaStream, onStream: OnStreamCallback, onStreamLogout: OnStreamCallback, onMessage: OnMessageCallback, onDataChannelOpen: OnConnectedCallback, onInternalMessage: OnInternalMessageCallback) {
  const peerId = signal.sender;
  const params = signal.data.params as ConnectParams;

  log(`[${peerId}] Handle offer`);

  const connection = getRTCPeerConnectionObject(peerId, localStream, params, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage);

  connection.pc.setRemoteDescription(new RTCSessionDescription(signal.data.offer)).then(() => {
    log(`[${peerId}] Setting remote description by offer`);

    connection.pc.createAnswer().then(answer => {
      connection.pc.setLocalDescription(answer).then(() => {
        socket.send({
          type: StreamEvent.Answer,
          receiver: peerId,
          data: {
            params,
            answer
          }
        });
      }).catch(log);
    }).catch(log);
  }).catch(log);
}

function handleAnswer(signal: MeetingMessage, localStream: MediaStream, onStream: OnStreamCallback, onStreamLogout: OnStreamCallback, onMessage: OnMessageCallback, onDataChannelOpen: OnConnectedCallback, onInternalMessage: OnInternalMessageCallback) {
  log(`[${signal.sender}] Handle answer`);

  const params = signal.data.params as ConnectParams;
  const connection = getRTCPeerConnectionObject(signal.sender, localStream, params, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage, true);
  connection.pc.setRemoteDescription(new RTCSessionDescription(signal.data.answer)).then(function () {
    log(`[${signal.sender}] Setting remote description by answer`);
    connection.expectingAnswer = false;
    log(`[${signal.sender}] No longer expecting answer from peer`);
  }).catch(log);
}

function handleIce(signal: MeetingMessage, localStream: MediaStream, onStream: OnStreamCallback, onStreamLogout: OnStreamCallback, onMessage: OnMessageCallback, onDataChannelOpen: OnConnectedCallback, onInternalMessage: OnInternalMessageCallback) {
  if (signal.data) {
    log(`[${signal.sender}] Adding ice candidate`);
    const params = signal.data.params as ConnectParams;
    const connection = getRTCPeerConnectionObject(signal.sender, localStream, params, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage);
    connection.pc.addIceCandidate(new RTCIceCandidate(signal.data.ice)).then().catch(log);
  }
}

export function hasActiveConnection(uuid: string) {
  return !!connections[uuid];
}

function canAddTrack(track: MediaStreamTrack, params: ConnectParams, uuid: string) {
  return isOwner() || params.ownerPeers.includes(uuid) || (track.kind === 'video' && params.permissions.includes(MeetingDtoPermissionsEnum.Video)) || (track.kind === 'audio' && params.permissions.includes(MeetingDtoPermissionsEnum.Audio));
}

function handleFailOrDisconnect(uuid: string, params: ConnectParams, localStream: MediaStream, onStream: OnStreamCallback, onStreamLogout: OnStreamCallback, onMessage: OnMessageCallback, onDataChannelOpen: OnConnectedCallback, onInternalMessage: OnInternalMessageCallback) {
  if (new Date().getTime() - connections[uuid].lastIceRestart < 4000) {
    log(`[${uuid}] Duplicate call to handle fail or disconnect.`);
    return;
  }

  connections[uuid].lastIceRestart = new Date().getTime();

  if (connections[uuid].offerer) {
    log(`[${uuid}] restarting ice`);
    createOffer(uuid, params, true);
  } else {
    log(`[${uuid}] requesting ice restart`);
    requestRestartIce(uuid, params);
  }
}

function getRTCPeerConnectionObject(uuid: string, localStream: MediaStream, params: ConnectParams, onStream: OnStreamCallback, onStreamLogout: OnStreamCallback, onMessage: OnMessageCallback, onDataChannelOpen: OnConnectedCallback, onInternalMessage: OnInternalMessageCallback, offerer: boolean = false) {
  if (connections[uuid]) {
    return connections[uuid];
  }

  log(`[${uuid}] Creating connection ${offerer ? '& offer to' : ' without offer'} ${uuid}`, [peerConnectionConfig()]);

  const connection = new RTCPeerConnection(peerConnectionConfig());

  connection.onicecandidate = event => {
    if (!event.candidate || !event.candidate.candidate) {
      log(`[${uuid}] received the null ice candidate. gathering completed.`);
      connections[uuid].hasReceivedNullIceCandidate = true;

      return;
    }

    if (forceRelay && event.candidate.candidate.indexOf('typ relay') < 0) {
      log(`[${uuid}] OMITTING candidate: `, [event.candidate]);
    } else {
      log(`[${uuid}] candidate is: `, [event.candidate]);
      socket.send({
        type: StreamEvent.Ice,
        receiver: uuid,
        data: {
          ice: event.candidate,
          params
        }
      });
    }
    connections[uuid].hasReceivedNullIceCandidate = false;
  };

  connection.ontrack = ({track, streams}) => {
    log(`[${uuid}] received new track`, [track, streams]);

    if (!!track && streams.length) {
      connections[uuid].ms.addTrack(track);
    }

    if (track.kind === 'video') {
      track.onunmute = () => {
        if (connections[uuid]) {
          onStream(uuid, connections[uuid].ms);
        }
      }
    }

    if (connections[uuid].ms.getTracks().length >= 1) {
      onStream(uuid, connections[uuid].ms);
    }
  };

  initDataChannel(uuid, params, connection, onMessage, onInternalMessage, onDataChannelOpen);

  connection.onnegotiationneeded = () => {
    log(`[${uuid}] Negotiation needed`);
    if (offerer) {
      connections[uuid].offerer = offerer;
      log(`[${uuid}] Offerer viewpoint. Making offer`);

      createOffer(uuid, params, false);
    } else {
      log(`[${uuid}] Answerer viewpoint. Checking if ice restart is required`);

      if (connections[uuid].initDone) {
        log(`[${uuid}] This connection was active before. Requesting ice restart`);
        requestRestartIce(uuid, params);
      } else {
        connections[uuid].initDone = true;
        log(`[${uuid}] This connection was not active before. Expecting an offer from ${uuid}`);
      }
    }
  };

  connection.oniceconnectionstatechange = () => {
    log(`[${uuid}] ice connection state changed to: ${connection.iceConnectionState}`);

    if (connection.iceConnectionState === 'closed') {
      handleLogout({data: {id: uuid}, type: null, receiver: null}, null, onStreamLogout);
    }

    if (connection.iceConnectionState === 'failed' || connection.iceConnectionState === 'disconnected') {
      handleFailOrDisconnect(uuid, params, localStream, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage);
    }
  };

  connection.onicegatheringstatechange = () => {
    log(`[${uuid}] ice gathering state: ${connection.iceGatheringState}`);
  };

  connection.onconnectionstatechange = () => {
    log(`[${uuid}] connection state: ${connection.connectionState}`);

    if ('closed' === connection.connectionState) {
      handleLogout({data: {id: uuid}, type: null, receiver: null}, null, onStreamLogout);
    }

    if ('connected' === connection.connectionState) {
      log(`[${uuid}] connection completed.`);
    }

    if (connection.connectionState === 'failed' || connection.connectionState === 'disconnected') {
      handleFailOrDisconnect(uuid, params, localStream, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage);
    }
  };

  connection.onsignalingstatechange = () => {
    log(`[${uuid}] signaling state: ${connection.signalingState}`);

    if ('closed' === connection.signalingState) {
      handleLogout({data: {id: uuid}, type: null, receiver: null}, null, onStreamLogout);
    }
  };

  localStream.getTracks().forEach(track => {
    if (canAddTrack(track, params, uuid)) {
      connection.addTrack(track, localStream);
    } else {
      log(`[${uuid}] Not adding ${track.kind} track because it doesn't have permission.`);
    }
  });

  if (localStream.getVideoTracks().length === 0) {
    connection.addTransceiver('video');
  }
  // if (supportsSetCodecPreferences) {
  // try {
  //   log(`[${uuid}] Trying to set the default video codec.`)
  //   const transceiver = connection.getTransceivers().find(t => t.sender && t.sender.track === localStream.getVideoTracks()[0]);
  //   transceiver.setCodecPreferences(videoCodecPreferences);
  //   log(`[${uuid}] Successfully set video codec to ${JSON.stringify(videoCodecPreferences)}`)
  // } catch (e) {
  //   console.error(e);
  // }

  // try {
  //   log(`[${uuid}] Trying to set the default audio codec.`)
  //   const transceiver = connection.getTransceivers().find(t => t.sender && t.sender.track === localStream.getAudioTracks()[0]);
  //   console.log('======+> GOT AUDIO TRACK!!!!', transceiver);
  //   transceiver.setCodecPreferences(audioCodecPreferences);
  //   log(`[${uuid}] Successfully set audio codec to ${JSON.stringify(audioCodecPreferences)}`)
  // } catch (e) {
  //   console.error(e);
  // }
  // }

  webrtcStats.addConnection({
    pc: connection,
    peerId: uuid
  });

  connections[uuid] = {
    pc: connection,
    dc: null,
    ms: new MediaStream(),
    offerer,
    makingOffer: false,
    initDone: false,
    expectingAnswer: false,
    createdDate: new Date().getTime(),
    lastIceRestart: new Date().getTime(),
    lastConnectionRestart: new Date().getTime(),
    hasReceivedNullIceCandidate: false,
    bandwidth: Bandwidth._500,
    restartCount: 0,
    restartIce: () => {
      handleFailOrDisconnect(uuid, params, localStream, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage);
    },
    restartConnection: () => {
      connections[uuid].restartCount++;
      restartConnection(uuid, params, localStream, onStream, onStreamLogout, onMessage, onDataChannelOpen, onInternalMessage);
    },
    increaseBandwidth: () => {
      changeBandwidth(uuid, getHigherBandwidth(uuid));
    },
    decreaseBandwidth: () => {
      changeBandwidth(uuid, getLowerBandwidth(uuid));
    },
    refreshBandwidth: () => {
      changeBandwidth(uuid, connections[uuid].bandwidth, true);
    }
  };
  return connections[uuid];
}

function createOffer(uuid: string, params: ConnectParams, iceRestart: boolean) {
  const connection = connections[uuid].pc;

  if (connections[uuid].makingOffer) {
    log(`[${uuid}] => tried to create an offer, but was already in process of making one`);
    return;
  }

  log(`[${uuid}] => in create offer. iceRestart: ${iceRestart}`);
  connections[uuid].makingOffer = true;

  connection.createOffer({iceRestart}).then(offer => {
    connection.setLocalDescription(offer).then(() => {
      socket.send({
        type: StreamEvent.Offer,
        receiver: uuid,
        data: {
          params,
          offer
        }
      });
      connections[uuid].makingOffer = false;
      connections[uuid].expectingAnswer = true;
      log(`[${uuid}] Expecting answer`);
    }).catch((e) => {
      connections[uuid].makingOffer = false;
      log(`[${uuid}] exception while setting local description`, [e]);
    });
  }).catch((e) => {
    connections[uuid].makingOffer = false;
    log(`[${uuid}] exception while creating offer`, [e]);
  });
}

function requestRestartIce(uuid: string, params: ConnectParams) {
  if (socket) {
    socket.send({type: StreamEvent.RequestRestartIce, receiver: uuid, data: {params}});
    log(`[${uuid}] send request restart ice`);
  } else {
    log('cannot send request restart ice. socket is closed.');
  }
}

function initDataChannel(uuid: string, params: ConnectParams, connection: RTCPeerConnection, onMessage: OnMessageCallback, onInternalMessage: OnInternalMessageCallback, onDataChannelOpen: OnConnectedCallback) {
  if (params.permissions.includes(MeetingDtoPermissionsEnum.Chat) || params.ownerPeers.includes(uuid) || isOwner()) {
    log(`[${uuid}] Creating Data Channel`);

    const dataChannel = connection.createDataChannel("chat");
    dataChannel.binaryType = 'arraybuffer';

    dataChannel.onmessage = (ev) => {
      if (typeof ev.data === 'string') {
        try {
          const msg = JSON.parse(ev.data) as InternalMessage;
          const type = msg.type;

          if (type && InternalMessageType[type]) {
            onInternalMessage({...msg, from: uuid});
            return;
          }
        } catch (e) {
        }

        try {
          const currentFileDetails = JSON.parse(ev.data) as AttachmentMetadata;

          if (currentFileDetails.finished) {
            const received = new Blob(receiveBuffer);

            fileDetails = null;
            receiveBuffer = [];

            onMessage({
              attachment: received,
              attachmentName: currentFileDetails.name,
              attachmentSize: received.size,
              yours: false,
              from: uuid,
              time: moment().unix()
            });
          } else {
            fileDetails = currentFileDetails;
            log(`[${uuid}] Will receive a file with this details: `, [fileDetails]);
          }
        } catch (ex) {
          log(`[${uuid}] Received plain message: ${ev.data}`);
          onMessage({value: ev.data, yours: false, from: uuid, time: moment().unix()});
        }
      } else {
        log(`[${uuid}] Received array buffer message.`);

        receiveBuffer.push(ev.data);
      }
    };

    connection.ondatachannel = e => {
      connections[uuid].dc = e.channel;

      connections[uuid].dc.onopen = () => {
        log(`[${uuid}] data channel opened`);
        onDataChannelOpen(uuid);
      };
      connections[uuid].lastConnectionRestart = new Date().getTime();
    };
  } else {
    log(`[${uuid}] No permission for chat.`);
  }
}


function updateBandwidthRestriction(sdp: string, bandwidth: number = 250) {
  let modifier = 'AS';
  if (adapter.browserDetails.browser === 'firefox') {
    bandwidth = (bandwidth >>> 0) * 1000;
    modifier = 'TIAS';
  }
  if (sdp.indexOf('b=' + modifier + ':') === -1) {
    // insert b= after c= line.
    sdp = sdp.replace(/c=IN (.*)\r\n/, 'c=IN $1\r\nb=' + modifier + ':' + bandwidth + '\r\n');
  } else {
    sdp = sdp.replace(new RegExp('b=' + modifier + ':.*\r\n'), 'b=' + modifier + ':' + bandwidth + '\r\n');
  }
  return sdp;
}

function removeBandwidthRestriction(sdp: string) {
  return sdp.replace(/b=AS:.*\r\n/, '').replace(/b=TIAS:.*\r\n/, '');
}

export function changeBandwidth(id: string, bandwidth: Bandwidth, force: boolean = false) {
  Object.keys(connections).forEach(peerId => {
    const c = connections[peerId];

    if ((!id || id === peerId) && (force || c.bandwidth !== bandwidth)) {
      if (c.bandwidth !== bandwidth) {
        log(`[${peerId}] Changing bandwidth to ${bandwidth} with senders: ${useSenderParams}`);
      }
      c.bandwidth = bandwidth;

      if (useSenderParams) {
        c.pc.getSenders().forEach(sender => {
          const parameters = sender.getParameters();
          const isVideo = sender.track.kind === 'video';

          if (!parameters.encodings || parameters.encodings.length === 0) {
            parameters.encodings = [{}];
          }
          if (parameters.encodings && parameters.encodings[0]) {
            if (bandwidth === Bandwidth.UNLIMITED) {
              delete parameters.encodings[0].maxBitrate;
              delete parameters.encodings[0].scaleResolutionDownBy;
            } else {
              const bandwidthNr = parseInt(bandwidth);
              parameters.encodings[0].maxBitrate = bandwidthNr * (isVideo ? 1000 : 100)

              // TODO: check if audio can be force to 1 channel

              if (bandwidth === Bandwidth._100) {
                parameters.encodings[0].scaleResolutionDownBy = 4;
              } else if (bandwidth === Bandwidth._200) {
                parameters.encodings[0].scaleResolutionDownBy = 3;
              } else if (bandwidth === Bandwidth._500) {
                parameters.encodings[0].scaleResolutionDownBy = 2;
              } else {
                delete parameters.encodings[0].scaleResolutionDownBy;
              }
            }
          }
          sender.setParameters(parameters)
            .then()
            .catch(log);
        });
      } else {
        c.pc.createOffer()
          .then(offer => c.pc.setLocalDescription(offer))
          .then(() => {
            const bandwidthNr = parseInt(bandwidth);
            const desc = {
              type: c.pc.remoteDescription.type,
              sdp: bandwidth === Bandwidth.UNLIMITED ?
                removeBandwidthRestriction(c.pc.remoteDescription.sdp) :
                updateBandwidthRestriction(c.pc.remoteDescription.sdp, bandwidthNr)
            };
            return c.pc.setRemoteDescription(desc);
          })
          .then()
          .catch(log);
      }
    }
  });
}

function getLowerBandwidth(id: string) {
  if (connections[id]) {
    if (isGroupMeeting) {
      switch (connections[id].bandwidth) {
        case Bandwidth.UNLIMITED:
          return Bandwidth._750;
        case Bandwidth._2000:
          return Bandwidth._750;
        case Bandwidth._1000:
          return Bandwidth._750;
        case Bandwidth._750:
          return Bandwidth._500;
        case Bandwidth._500:
          return Bandwidth._200;
        default:
          return Bandwidth._100;
      }
    } else {
      switch (connections[id].bandwidth) {
        case Bandwidth.UNLIMITED:
          return Bandwidth._2000;
        case Bandwidth._2000:
          return Bandwidth._1000;
        case Bandwidth._1000:
          return Bandwidth._750;
        case Bandwidth._750:
          return Bandwidth._500;
        case Bandwidth._500:
          return Bandwidth._200;
        default:
          return Bandwidth._100;
      }
    }
  }
  return Bandwidth._200;
}

function getHigherBandwidth(id: string) {
  if (connections[id]) {
    if (isGroupMeeting) {
      if (!isProvider && numberOfParticipants > LARGE_MEETING_THRESHOLD) {
        switch (connections[id].bandwidth) {
          case Bandwidth._100:
            return Bandwidth._200;
          default:
            return Bandwidth._500;
        }
      } else {
        switch (connections[id].bandwidth) {
          case Bandwidth._100:
            return Bandwidth._200;
          case Bandwidth._200:
            return Bandwidth._500;
          default:
            return Bandwidth._750;
        }
      }
    } else {
      switch (connections[id].bandwidth) {
        case Bandwidth._100:
          return Bandwidth._200;
        case Bandwidth._200:
          return Bandwidth._500;
        case Bandwidth._500:
          return Bandwidth._750;
        case Bandwidth._750:
          return Bandwidth._1000
        default:
          return Bandwidth._2000;
      }
    }
  }
  return Bandwidth._500;
}

function log(message: string, params?: any[]) {
  if (env.debug) {
    const msg = '=> WEB RTC | ' + moment().format('HH:mm:ss') + ' | ' + message;
    if (params) {
      console.log(msg, params);
    } else {
      console.log(msg);
    }
  }
}

window.addEventListener("offline", () => {
  if (online) {
    online = false;
  }
});
window.addEventListener("online", () => {
  if (!online) {
    online = true;

    log('=> reconnecting due to lost internet connection');

    warnMessage(NetworkConnectionStatus.RECONNECTING);

    reconnect();
  }
});

// =============================================================================================== Messages
export function sendFileMessage(file: File, callback: (buffer: any[]) => void) {
  sendStringMessage(JSON.stringify({
    chunkSize,
    name: file.name,
    size: file.size,
    type: file.type,
    finished: false
  }));

  let sendBuffer: any[] = [];

  const fileReader = new FileReader();
  let offset = 0;
  fileReader.addEventListener('load', e => {
    const slice = e.target.result as ArrayBuffer;
    sendBuffer.push(slice);

    Object.values(connections).map(it => it.dc).filter(it => !!it).forEach(dataChannel => {
      if (dataChannel.readyState === "open") {
        dataChannel.send(slice);
      }
    });

    offset += (e.target.result as ArrayBuffer).byteLength;
    if (offset < file.size) {
      readSlice(offset);
    } else {
      sendStringMessage(JSON.stringify({
        chunkSize,
        name: file.name,
        size: file.size,
        type: file.type,
        finished: true
      } as AttachmentMetadata));

      callback(sendBuffer);
    }
  });
  const readSlice = (o: any) => {
    const slice = file.slice(offset, o + chunkSize);
    fileReader.readAsArrayBuffer(slice);
  };
  readSlice(0);
}

export function sendStringMessage(message: string, toUuid?: string) {
  try {
    (toUuid ? [connections[toUuid]] : Object.values(connections)).map(it => it.dc).filter(it => !!it).forEach(dataChannel => {
      if (dataChannel.readyState === "open") {
        dataChannel.send(message);
      }
    });
  } catch (e) {
    log(`[${toUuid}] failed to send message`, e);
  }
}

export function sendChatMessage(message: Message) {
  sendStringMessage(message.value);
}

export function sendInternalMessage(message: InternalMessage, toUuid?: string) {
  sendStringMessage(JSON.stringify(message), toUuid);
}

// =============================================================================================== Stats


webrtcStats = new WebRTCStats({getStatsInterval: 2000});
// @ts-ignore
webrtcStats.on('stats', (ev) => {
  const s = webrtcStats.getTimeline('stats') as TimelineEvent[];
  if (s.length > 1) {
    const statsPerPeer = groupBy(s, 'peerId');

    const MIN_STATS = 5;
    const TIME_SINCE_LAST_RESTART = 7500;
    const CONNECTION_HANGED_MS = TIME_SINCE_LAST_RESTART * 2;

    const brokenPeers = [] as string[];
    Object.keys(statsPerPeer)
      .filter(it => it === ev.peerId)
      .forEach(peerId => {
        try {
          const connection = connections[peerId];
          if (connection) {
            const stats = statsPerPeer[peerId]
              .filter(s => s.timestamp.getTime() > connection.lastIceRestart && s.timestamp.getTime() > connection.lastConnectionRestart)
              .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

            if (stats.length > MIN_STATS &&
              (new Date().getTime() - connection.lastIceRestart >= TIME_SINCE_LAST_RESTART) &&
              (new Date().getTime() - connection.lastConnectionRestart >= TIME_SINCE_LAST_RESTART)) {
              const latest = stats[0];
              const previous = stats[1];

              try {
                let id = '';
                try {
                  id = meetingInfo.sessions.find(it => it.id === peerId).memberOrParticipantId;
                } catch (e) {
                }
                let localIce = null;
                let remoteIce = null;
                try {
                  localIce = latest.data.connection.local;
                } catch (e) {
                }
                try {
                  remoteIce = latest.data.connection.remote;
                } catch (e) {
                }

                log(`[stats] [${peerId}] [${id}]`, [connections[peerId], latest, localIce, remoteIce]);
              } catch (e) {
              }

              let hasLag = false;
              let nackCount = -1;
              try {
                hasLag = latest.data.connection.currentRoundTripTime > 1;
                if (latest.data && latest.data.video && latest.data.video.outbound && latest.data.video.outbound[0] &&
                  previous.data && previous.data.video && previous.data.video.outbound && previous.data.video.outbound[0]
                ) {
                  nackCount = latest.data.video.outbound[0].nackCount - previous.data.video.outbound[0].nackCount;
                }
              } catch (e) {
              }

              if (hasLag) {
                if (connection) connection.decreaseBandwidth();
              } else {
                if (nackCount < 2) {
                  if (connection) connection.increaseBandwidth();
                } else {
                  if (connection) connection.decreaseBandwidth();
                }
              }

              if (connection) {
                const hasActiveMediaStream = connection.ms.active;
                const isConnected = connection.pc.connectionState === 'connected';
                const isIceConnected = connection.pc.iceConnectionState === 'connected';
                const isConnecting = connection.pc.connectionState === 'connecting' || connection.pc.connectionState === 'new';
                const shouldBeConnected = (new Date().getTime() - connection.createdDate) > CONNECTION_HANGED_MS;
                if (!isConnecting) {
                  if (!isConnected || !hasActiveMediaStream) {
                    if (!isConnected) {
                      log(`[${peerId}] [BROKEN_PEER] Peer is not connected: ${connection.pc.connectionState}.`);
                    } else {
                      log(`[${peerId}] [BROKEN_PEER] Peer doesn't have any active media streams.`);
                    }
                    if (!brokenPeers.includes(peerId)) {
                      brokenPeers.push(peerId);
                    }
                  }
                }

                if (isConnected && !isConnecting && !connection.hasReceivedNullIceCandidate) {
                  if (isIceConnected) {
                    log(`[${peerId}] [BROKEN_PEER] Null ice not received, but not restarting connection because ice is connected.`);
                  } else {
                    log(`[${peerId}] [BROKEN_PEER] Null ice not received.`);
                    if (!brokenPeers.includes(peerId)) {
                      brokenPeers.push(peerId);
                    }
                  }
                }

                try {
                  if ((isIceConnected || shouldBeConnected) && (latest.data.audio.inbound[0].bytesReceived - previous.data.audio.inbound[0].bytesReceived === 0)) {
                    log(`[${peerId}] [BROKEN_PEER] No audio received.`);
                    if (!brokenPeers.includes(peerId)) {
                      brokenPeers.push(peerId);
                    }
                  }
                } catch (e) {
                }

                try {
                  if ((isIceConnected || shouldBeConnected) && (latest.data.video.inbound[0].bytesReceived - previous.data.video.inbound[0].bytesReceived === 0)) {
                    log(`[${peerId}] [BROKEN_PEER] No video received.`);
                    if (!brokenPeers.includes(peerId)) {
                      brokenPeers.push(peerId);
                    }
                  }
                } catch (e) {
                }

                try {
                  if (isIceConnected && latest.data.connection.dataChannelsOpened === 0) {
                    log(`[${peerId}] [BROKEN_PEER] No data channels opened.`);
                    if (!brokenPeers.includes(peerId)) {
                      brokenPeers.push(peerId);
                    }
                  }
                } catch (e) {
                }

                if (connection.pc.connectionState !== 'connected' && connection.pc.iceConnectionState !== 'connected' && shouldBeConnected) {
                  log(`[${peerId}] [BROKEN_PEER] This connection is hanged. Not connected after ${CONNECTION_HANGED_MS}`);
                  if (!brokenPeers.includes(peerId)) {
                    brokenPeers.push(peerId);
                  }
                }
              }
            }
          }
        } catch (e) {
        }
      });

    if (brokenPeers.length) {
      log('[BROKEN_PEERS]: ' + brokenPeers);
    }

    let peersWithTooManyRestarts = 0;
    Object.keys(connections).forEach(peerId => {
      if (connections[peerId] && connections[peerId].restartCount >= 3) {
        log(`[BROKEN_PEERS]: ${peerId} => has too many restarts: ${connections[peerId].restartCount}`);
        peersWithTooManyRestarts++;
        connections[peerId].restartCount = 0;
      }
    });

    if (peersWithTooManyRestarts > 2) {
      log(`[BROKEN_PEERS]: ${peersWithTooManyRestarts} full reconnect`);
      reconnect(false, true);
      return;
    }

    if (brokenPeers.length === Object.keys(connections).length) {
      log('[BROKEN_PEERS]: ' + brokenPeers + " => reconnecting all");
      reconnect(false, true);
    } else {
      brokenPeers.forEach(bp => {
        log('[BROKEN_PEERS]: ' + brokenPeers + " => restarting only " + bp);
        connections[bp].restartConnection();
      });
    }
  }
});
