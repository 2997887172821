import Icon, {CheckOutlined} from '@ant-design/icons';
import {Popover, Tooltip} from 'antd';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {clipboardCopy} from '../../util/utils';
import CopyIcon from './icons/CopyIcon';
import './CopyUrl.scss';
import {CopyUrlEntry} from '../../util/types';
import {ReactComponent as CopyFilled}  from '../../img/icons/copy-icon.svg';

interface CopyUrlProps {
  entries: CopyUrlEntry[];
}

export default function CopyUrl({entries}: CopyUrlProps) {
  const {t} = useTranslation();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState('');

  function onClick(ev: React.MouseEvent, url: string) {
    ev.stopPropagation();

    clipboardCopy(url);
    setTooltipVisible(url);

    setTimeout(() => {
      setTooltipVisible('');
      setPopoverVisible(false);
    }, 3000);
  }

  function triggerPopoverVisible(ev: React.MouseEvent) {
    ev.stopPropagation();
    setPopoverVisible(!popoverVisible);
  }

  function handlePopoverVisibleChange(visible: boolean) {
    setPopoverVisible(visible);
    if (!visible) {
      setTooltipVisible('');
    }
  }

  return <>
    {entries.length === 1 &&
      <Popover overlayClassName={'copy-url-container'} trigger="click" placement="topRight" visible={popoverVisible} onVisibleChange={handlePopoverVisibleChange} content={
        <div onClick={(ev) => onClick(ev, entries[0].url)} className={'copy-url'}>
          <Tooltip placement="bottom" title={<><CheckOutlined /> {t('linkCopied')}</>} visible={tooltipVisible === entries[0].url} overlayClassName={'confirm-copy-url-container'}>
            {entries[0].url}
            <span className={'copy-icon'}>
              <Icon component={CopyFilled} alt={''} />
            </span>
          </Tooltip>
        </div>}>
        <CopyIcon onClick={triggerPopoverVisible} className={popoverVisible ? 'active' : ''} />
      </Popover>
    }
    {entries.length > 1 &&
      <Popover overlayClassName={'copy-url-container'} trigger="click" placement="bottomRight" visible={popoverVisible} onVisibleChange={handlePopoverVisibleChange} content={
        entries.map(e => (
          <div onClick={(ev) => onClick(ev, e.url)} className={'copy-url'} key={e.url}>
            <Tooltip placement="bottom" title={<><CheckOutlined /> {t('linkCopied')}</>} visible={tooltipVisible === e.url} overlayClassName={'confirm-copy-url-container'}>
              <div className='entry'>
                <div className={'participant-entry'}>
                  <span className={'title'}>{e.name}</span>
                  <span className={'id'}>{e.publicId}</span>
                </div>
                <span className={'copy-icon'}>
                  <Icon component={CopyFilled} alt={''} />
                </span>
              </div>
            </Tooltip>
          </div>
        ))
      }>
        <CopyIcon onClick={triggerPopoverVisible} className={popoverVisible ? 'active' : ''} />
      </Popover>
    }
  </>;
}
