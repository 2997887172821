import React, {useState, useEffect, useCallback} from 'react';
import './Settings.scss';
import {Form, Button, Switch, Skeleton, Modal, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import PhoneInput from '../general/PhoneInput';
import './UserMfa.scss';
import {MfaFieldsDto} from '../../gen/client';
import MemberApi from '../../api/MemberApi';
import {handleServerError} from '../../util/ErrorHandler';
import {errorMessage, removePhonePrefix, successMessage} from '../../util/utils';
import {isEmpty, isValidNumber} from '../../util/validators';
import {CloseCircleOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {Routes} from '../../util/routes';
import {useHistory} from 'react-router-dom';
import PasswordConfirm from '../general/IdentityConfirm';
import Storage from '../../util/Storage';
import {MfaInputBoxState} from '../../util/types';
import {useRecoilValue} from "recoil";
import {loggedMember} from "../../state/atoms";

interface UserMfaProps {
  memberId?: string,
  onCancel?: () => void,
  code?: string;
}

const {confirm} = Modal;

export default function UserMfa({memberId, onCancel, code}: UserMfaProps) {
  const {t} = useTranslation();
  const {Item} = Form;
  const history = useHistory();
  const logged = useRecoilValue(loggedMember);

  const [mfaFields, setMfaFields] = useState({} as MfaFieldsDto);
  const [resetMFAView] = useState(!!code);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [passwordConfirmModalVisible, setPasswordConfirmModalVisible] = useState(false);
  const [mfaPhoneChanged, setMfaPhoneChanged] = useState(false);
  const [mfaState, setMfaState] = useState(MfaInputBoxState.INITIAL);
  const [onlyPassword, setOnlyPassword] = useState(false);

  const [disableMfaInput, setDisableMfaInput] = useState(false);

  const fetchMfaFields = useCallback(() => {
    if (!!memberId) {
      setLoading(true);
      MemberApi.getMfaFields(memberId).then(resp => {
        setMfaFields(resp.data);
        setLoading(false);
      }).catch(err => {
        handleServerError(err);
        setLoading(false);
      });
    }
  }, [memberId]);

  useEffect(() => {
    fetchMfaFields();
  }, [fetchMfaFields]);

  useEffect(() => {
    setLoading(false);
  }, [code]);

  function forgetDevices() {
    confirm({
      title: t(`Are you sure you want to clear trusted devices?`),
      icon: <ExclamationCircleOutlined />,
      content: t(`Two factor authentication will be required to sign in.`),
      okText: t('Yes'),
      okType: 'danger',
      cancelText: t('No'),
      onOk() {
        setSaving(true);
        MemberApi.forgetDevices(memberId).then(() => {
          setSaving(false);
          successMessage(t('Erfolgreich gespeichert!'));
          Storage.removeDeviceId(logged?.email ?? '');
        }).catch(ex => {
          setSaving(false);
          handleServerError(ex);
        });
      }
    });
  }

  function toggleSaveDevices(checked: boolean) {
    setMfaFields({...mfaFields, saveTrustedDevices: checked});
  }

  function toggleTwoFactorAuthentication(checked: boolean) {
    setMfaFields({...mfaFields, twoFactorAuthentication: checked});
    setMfaPhoneChanged(true);
  }

  function onMfaPhoneChange(mfaPhone: string) {
    setMfaFields({...mfaFields, mfaPhone});
    setMfaPhoneChanged(true);
  }

  function resetMfaCode() {
    setSaving(true);
    MemberApi.updateMfaFields(memberId, mfaFields).then(resp => {
      setSaving(false);
      successMessage(t('Erfolgreich gespeichert!'));
      history.push(Routes.LOGIN);
    }).catch(err => {
      setSaving(false);
      handleServerError(err);
    });
  }

  function closePasswordConfirmModal() {
    setPasswordConfirmModalVisible(false);
    fetchMfaFields();
  }

  function onIdentityConfirmed(currentCode: string) {
    setMfaState(MfaInputBoxState.INITIAL);
    onSave(currentCode);
  }

  function onSave(currentCode?: string) {
    const isMfaPhoneValid = isValidNumber(mfaFields.mfaPhone);

    if (!isMfaPhoneValid && (resetMFAView || mfaFields.twoFactorAuthentication)) {
      errorMessage(t('Mobilnummer ist falsch'));
      return;
    }

    if (resetMFAView) {
      resetMfaCode();
    } else {
      setOnlyPassword(!mfaFields.twoFactorAuthentication);
      if (!currentCode && mfaPhoneChanged) {
        setPasswordConfirmModalVisible(true);
      } else {
        setSaving(true);
        MemberApi.updateMfaFields(memberId, {...mfaFields, mfaCode: currentCode}).then(() => {
          setSaving(false);
          setPasswordConfirmModalVisible(false);
          successMessage(t('Erfolgreich gespeichert!'));
        }).catch(ex => {
          setSaving(false);
          handleServerError(ex);
          setMfaState(MfaInputBoxState.INVALID);
          if (ex.response.data.message && (ex.response.data.message === t("member.auth.expired") || ex.response.data.message === t("member.auth.wrong.code"))) {
            setDisableMfaInput(true);
          }
        });
      }
    }
  }

  function onCancelButtonClick() {
    if (typeof onCancel === 'function') {
      onCancel();
    }
  }

  return (
    <>
      {loading && <Skeleton loading={true} />}

      {!loading &&
        <div className='user-mfa'>
          <Form layout="vertical" className='form'>
            {!resetMFAView &&
              <Item>
                <div className='label switch-wrapper'>
                  <Switch defaultChecked={mfaFields.twoFactorAuthentication} checked={mfaFields.twoFactorAuthentication} onChange={toggleTwoFactorAuthentication} />
                  <span>{t('Zwei-Faktor-Authentifizierung')} &nbsp;
                  
                  {(!mfaFields.twoFactorAuthentication || !mfaFields.mfaPhone) && <Tooltip placement="top" title={t('MFA_NOT_ACTIVATED')}>
                    <CloseCircleOutlined className={'error-icon'} />
                  </Tooltip>
                  }
                  </span>
                </div>
              </Item>
            }
            <div className='phone'>
              <PhoneInput asFormItem={true}
                isMfa={true}
                disabled={!mfaFields.twoFactorAuthentication}
                required={mfaFields.twoFactorAuthentication || resetMFAView}
                value={removePhonePrefix(!!mfaFields.mfaPhone || isEmpty(removePhonePrefix(mfaFields.mfaPhone)) ? mfaFields.mfaPhone : mfaFields.phone)}
                onChange={onMfaPhoneChange} customLabel='Mobilnummer für MFA' />
            </div>
            {!resetMFAView &&
              <Item className='save-devices'>
                <div className='label switch-wrapper'>
                  <Switch defaultChecked={mfaFields.twoFactorAuthentication && mfaFields.saveTrustedDevices} checked={mfaFields.twoFactorAuthentication && mfaFields.saveTrustedDevices} onChange={toggleSaveDevices} disabled={!mfaFields.twoFactorAuthentication} />
                  <span>{t('Vertrauenswürdige Geräte speichern')}</span>
                </div>
              </Item>
            }
            {!resetMFAView &&
              <Button className={'forget-devices'} onClick={forgetDevices} disabled={!mfaFields.twoFactorAuthentication}>{t('Gespeicherte Geräte vergessen')}</Button>
            }
            <div className="buttons">
              <Button className={'save'} type={'primary'} size="large" onClick={() => onSave()} loading={saving}>{t('Speichern')}</Button>
              {!resetMFAView && <Button className={'default'} size="large" onClick={onCancelButtonClick}>{t('Abbrechen')}</Button>}
            </div>
          </Form>

          <PasswordConfirm visible={passwordConfirmModalVisible} mfaFields={mfaFields} state={mfaState} onlyPassword={onlyPassword} onCancel={closePasswordConfirmModal} onConfirm={onIdentityConfirmed} onStateChange={(state) => setMfaState(state)} disableMfaInput={disableMfaInput} onResendMFACode={() => setDisableMfaInput(false)}/>
        </div>
      }
    </>
  );
}
