import * as React from 'react';
import './StatisticsAppointmentTableRow.scss'
import {
	CmsAnswerDto,
	MeetingDto,
	ParticipantDto,
	ParticipantGroupDto
} from '../../gen/client';
import { Card, Col, Row } from 'antd';
import { formatDate, formatDuration, formatTime, fromTimestamp, getApplicationSizeClass } from '../../util/utils';
import { useRecoilValue } from 'recoil';
import { applicationSize } from '../../state/atoms';
import { Dimensions } from '../../util/enums';
import ChatIcon from '../general/icons/ChatIcon';
import HourglassIcon from '../general/icons/HourglassIcon';
import { useEffect, useState } from 'react';
import { participantFeedbacksList } from './state/statistics.state';
import { ReactComponent as iconMultiSelectionContacts } from '../../img/icons/icon-multi-selection-contacts.svg';
import Icon from '@ant-design/icons';

export interface MeetingTableLineProps {
  appointment: MeetingDto
}

export default function StatisticsAppointmentTableRow({appointment}: MeetingTableLineProps) {

  const appSize = useRecoilValue<Dimensions>(applicationSize);
  const [feedbacks, setFeedbacks] = useState([] as CmsAnswerDto[]);
  const participantFeedbacks = useRecoilValue<CmsAnswerDto[]>(participantFeedbacksList);

  const participants = appointment.participants.length !== 0 ?
		getParticipantsLine(appointment.participants):
		getGroupsLine(appointment.groups);

  function getParticipantsLine(participantList: ParticipantDto[]) {
  	return participantList.map((it, idx, list) => (
			<li key={it.id}>
				<span>{it.name}{idx !== list.length - 1 ? ';' : ''}&nbsp;</span>
			</li>
		));
	}

	function getGroupsLine(groups: ParticipantGroupDto[]) {
		return groups.map((it, idx, list) => (
			<li key={it.id}>
				<span><Icon component={iconMultiSelectionContacts} alt={''}/>{it.name}{idx !== list.length - 1 ? ';' : ''}&nbsp;</span>
			</li>
		));
	}

  useEffect(() => {setFeedbacks(participantFeedbacks.filter(it => it.meetingPublicId === appointment.publicId))}, [participantFeedbacks, appointment]);

  return (
    <div className={`statistics-appointment-table-row ${getApplicationSizeClass(appSize)}`}>
      {appSize !== Dimensions.MOBILE ?
        <>
          <div className={'dtm'}>
            <span>{formatDate(fromTimestamp(appointment.startDate))}</span>
            <span>{formatTime(fromTimestamp(appointment.startDate))}</span>
          </div>
          <div className={'participants'}><ul>{participants}</ul></div>
          <div>{formatDuration(appointment.duration)}</div>
          <div><HourglassIcon
            className={'icon-hourglass'}/> {formatDuration(appointment.callDuration)}
          </div>
          <div><ChatIcon className={'icon-hourglass'}/> {feedbacks.length}</div>
        </> : <Card>
          <Row align={'middle'}>
            <Col span={12}>
              <Row className={'meeting-data'}>
                <p>{formatDate(fromTimestamp(appointment.startDate))}</p>
              </Row>
              <Row className={'meeting-data'}>
                <p>{formatTime(fromTimestamp(appointment.startDate))}</p>
              </Row>
              <Row className={'meeting-data'}>
                <p className={'name'}>{participants}</p>
              </Row>
            </Col>
            <Col span={12}>
              <Row className={'statistic-data'}>
                <p>{feedbacks.length} </p>
                <ChatIcon className={'icon-chat'}/>
              </Row>
              <Row className={'statistic-data'}>
                <p>{formatDuration(appointment.duration)} </p>
                <HourglassIcon className={'icon-hourglass'}/>
              </Row>
            </Col>
          </Row>
        </Card>}
    </div>
  )
}

