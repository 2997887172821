import * as React from 'react';

export default function ConnectionSVG() {
  return (
    <svg viewBox="0 0 24 24">
      <path id="Path_311" d="M12,3.5c-5.5,0-10,4.4-10,9.9c0,2.6,1,5,2.7,6.9c0.2,0.3,0.6,0.3,0.9,0.1c0.3-0.2,0.3-0.6,0.1-0.9
        c0,0,0,0,0,0c-1.5-1.6-2.4-3.7-2.4-5.9c0-4.8,3.9-8.7,8.7-8.7s8.7,3.9,8.7,8.7c0,2.2-0.9,4.3-2.4,5.9c-0.3,0.2-0.3,0.7,0,0.9
        c0.2,0.3,0.7,0.3,0.9,0c0,0,0,0,0,0c3.8-4,3.6-10.3-0.4-14.1C17,4.4,14.6,3.5,12,3.5z M12,6c-4.1,0-7.4,3.3-7.4,7.4
        c0,1.8,0.7,3.6,1.9,4.9c0.2,0.3,0.6,0.3,0.9,0.1c0.3-0.2,0.3-0.6,0.1-0.9c0,0,0,0,0,0c-2.3-2.6-2-6.5,0.5-8.7s6.5-2,8.7,0.5
        c2.1,2.3,2.1,5.8,0,8.2c-0.2,0.3-0.2,0.7,0,0.9c0.3,0.2,0.7,0.2,0.9,0c0,0,0,0,0,0c2.7-3.1,2.4-7.8-0.6-10.5C15.6,6.7,13.8,6,12,6z
        M12,8.6c-2.7,0-4.9,2.2-4.9,4.9c0,1.2,0.4,2.3,1.2,3.2c0.2,0.3,0.6,0.3,0.9,0.1c0.3-0.2,0.3-0.6,0.1-0.9c0,0,0,0,0,0
        C8,14.3,8.1,12,9.6,10.7c1.5-1.3,3.8-1.1,5.1,0.4c1.2,1.4,1.2,3.4,0,4.7c-0.2,0.3-0.2,0.7,0,0.9c0.3,0.2,0.7,0.2,0.9,0c0,0,0,0,0,0
        c1.8-2.1,1.5-5.1-0.5-6.9C14.3,9,13.2,8.6,12,8.6L12,8.6z M12,11.4c-1.2,0-2.1,1-2.1,2.1s1,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1
        C14.1,12.3,13.2,11.4,12,11.4L12,11.4z M12,12.6c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9
        C11.1,13,11.5,12.6,12,12.6C12,12.6,12,12.6,12,12.6z"/>
    </svg>
  )
}
