import * as React from "react";

export default function PracticeIcon() {
  return <svg viewBox="0 0 24 24">
    <path d="M21.7,12.7c0.2,0,0.3-0.1,0.3-0.3V11c0-0.2-0.1-0.3-0.3-0.3h-4v-6H18c0.2,0,0.3-0.1,0.3-0.3c0,0,0,0,0,0v-2
	C18.4,2.1,18.2,2,18,2H6C5.8,2,5.6,2.1,5.6,2.3v2c0,0.2,0.1,0.3,0.3,0.3h0.3v6h-4C2.1,10.7,2,10.8,2,11v1.3c0,0.2,0.1,0.3,0.3,0.3
	c0,0,0,0,0,0V20C2.1,20,2,20.2,2,20.4v1.3C2,21.9,2.1,22,2.3,22h19.4c0.2,0,0.3-0.1,0.3-0.3v-1.3c0-0.2-0.1-0.3-0.3-0.3V12.7z
	 M6.7,2.8h10.7v1.1H6.7L6.7,2.8z M21,16.7V20h-3.3v-3.3H21z M17.7,16v-1.3H19V16H17.7z M19.7,16v-1.3H21V16H19.7z M17.7,14v-1.3H21
	V14L17.7,14z M9.2,16v0.7h0.3V20H7.3V4.6h9.5V20h-2.2v-3.3h0.3V16H9.2z M13.8,16.7V20h-1.5v-3.3H13.8z M11.7,16.7V20h-1.5v-3.3H11.7
	z M2.6,11.3h3.7V12H2.6L2.6,11.3z M6.3,16.7V20H3v-3.3H6.3z M3,16v-1.3h1.3V16H3z M5,16v-1.3h1.3V16H5z M3,14v-1.3h3.3V14H3z
	 M21.4,21.4H2.6v-0.5h18.7V21.4z M21.4,12h-3.7v-0.7h3.7L21.4,12z M18.4,17.4H19V18h-0.7V17.4z M19.7,17.4h0.7V18h-0.7V17.4z
	 M18.4,18.7H19v0.7h-0.7V18.7z M19.7,18.7h0.7v0.7h-0.7V18.7z M5,17.4h0.7V18H5V17.4z M3.6,17.4h0.7V18H3.6V17.4z M5,18.7h0.7v0.7H5
	V18.7z M3.6,18.7h0.7v0.7H3.6V18.7z M12.3,10.6h1v1h-1V10.6z M10.3,10.6h1v1h-1V10.6z M14.3,10.6h1v1h-1V10.6z M8.3,10.6h1v1h-1
	V10.6z M12.3,12.6h1v1h-1V12.6z M10.3,12.6h1v1h-1V12.6z M14.3,12.6h1v1h-1V12.6z M8.3,12.6h1v1h-1V12.6z M12.3,14.4h1v1h-1V14.4z
	 M10.3,14.4h1v1h-1V14.4z M14.3,14.4h1v1h-1V14.4z M8.3,14.4h1v1h-1V14.4z M11.7,8.7h0.7V8H13V7.3h-0.7V6.6h-0.7v0.7H11V8h0.7V8.7z
	 M12,10.1c1.4,0,2.5-1.1,2.5-2.5S13.4,5.2,12,5.2c-1.4,0-2.5,1.1-2.5,2.5c0,0,0,0,0,0C9.5,9,10.6,10.1,12,10.1z M12,6
	c0.9,0,1.7,0.7,1.7,1.7S12.9,9.3,12,9.3c-0.9,0-1.7-0.7-1.7-1.7S11.1,6,12,6C12,6,12,6,12,6z"/>
  </svg>
}
