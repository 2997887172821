import * as React from 'react';
import './Appointments.scss';
import {Modal, Switch, Button, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import MeetingApi from "../../api/MeetingApi";
import {handleServerError} from "../../util/ErrorHandler";
import {downloadFileUsingBlob, formatDate, formatTime, fromTimestamp, isInThePast} from '../../util/utils';
import {ReactComponent as trashIcon} from '../../img/icons/trash-icon.svg';
import {ReactComponent as exportIcon} from '../../img/icons/export-icon.svg';
import { LoadingOutlined } from '@ant-design/icons';
import {MeetingDto} from "../../gen/client";
import Icon from '@ant-design/icons';
import {ReactComponent as userIcon}  from '../../img/icons/user-icon.svg';
import {ReactComponent as iconMultiSelectionContacts} from '../../img/icons/icon-multi-selection-contacts.svg';
import { getMeetingInfoFileName, getParticipantsWhoJoined } from '../../service/MeetingService';
import OkModal from '../general/OkModal';

interface AppointmentSelectionBarProps {
  list: MeetingDto[],
  pastList: MeetingDto[],
  selected: string[],
  onSelectedChange: (selected: string[]) => void;
  onDeleteMeetings: (meetingIds: string[]) => void;
}

export default function AppointmentSelectionBar({list, pastList, selected, onSelectedChange, onDeleteMeetings}: AppointmentSelectionBarProps) {
  const {t} = useTranslation();

  const [notify, setNotify] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteWarningModal, setOpenDeleteWarningModal] = useState(false);
  const [meetingsToDelete, setMeetingsToDelete] = useState([] as MeetingDto[]);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  function showDeleteConfirm() {
    const meetings = getMeetingsToDelete();
    setMeetingsToDelete(meetings);
    if (meetings.some(meeting => meeting.fromOra)) {
      setOpenDeleteWarningModal(true);
    } else {
      setOpenModal(true);
    }
  }

  function print() {
    const selectedList = list.concat(pastList).filter(meeting => selected.indexOf(meeting.id) !== -1);
    selectedList
      .filter(meeting => !meeting.anonymized)
      .forEach(meeting => {
        MeetingApi.printMeetingInfo(meeting.publicId)
        .then(response => {
          const participants = getParticipantsWhoJoined(meeting.logs);
          downloadFileUsingBlob(response.request.response, getMeetingInfoFileName(participants));
        })
        .catch(handleServerError);
      })
  }

  function handleMultipleDelete() {
    setOpenModal(false);
    setDeleteInProgress(true);
    MeetingApi.deleteMeetings(notify, meetingsToDelete).then((resp) => {
      onSelectedChange([]);
      setOpenModal(false);
      onDeleteMeetings(resp.data)
      setDeleteInProgress(false);
    }).catch(err => {
      setDeleteInProgress(false);
      handleServerError(err);
    });
  }

  function handleCancelMultipleDelete() {
    setOpenModal(false);
  }

  function onNotifyChange(checked: boolean) {
    setNotify(checked);
  }

  function getMeetingsToDelete() {
    const current = list.filter(it => selected.indexOf(it.id) > -1);
    const past = pastList.filter(it => selected.indexOf(it.id) > -1);
    return [...current, ...past];
  }

  function isDeleteButtonHidden(): boolean {
    const meetings = getMeetingsToDelete();
    if (meetings.some(it => it.removed === true)) return true;
    if (meetings.some(it => isInThePast(it))) return true;
    return false;
  }

  return (
    <>
      <div className={`count-bar ${deleteInProgress ? 'del' : ''}`}>
        {deleteInProgress ?
          <div className='count-bar-content'>
            <span className='count-message'>{selected.length + t(' Termine wurde gelöscht!')}&nbsp;</span>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24}} spin />}/>
          </div>
          :
          <div className='count-bar-content'>
            <span className='count-message'>{selected.length + t(' Termine ausgewählt')}&nbsp;</span>
            <Button type={'primary'} size="large" onClick={showDeleteConfirm} hidden={isDeleteButtonHidden()}><Icon component={trashIcon} alt={''} />&nbsp;{t('Löschen')}</Button>
            <Button type={'primary'} size="large" onClick={print}><Icon component={exportIcon} alt={''} />&nbsp;{t('Export')}</Button>
          </div>
        }
      </div>

      <Modal
        className='delete-card'
        visible={openModal}
        closable={false}
        onOk={handleMultipleDelete}
        onCancel={handleCancelMultipleDelete}
        okText={t('Ja')}
        okButtonProps={{className: 'modal-button', size: 'large'}}
        cancelText={t('Nein')}
        cancelButtonProps={{className: 'modal-button', size: 'large'}}>
        <div className='all-ntf'>
          <h3>{t('Möchten Sie diese {{sum}} Termine wirklich löschen?', {sum: selected.length})}</h3>
          <div className='cards'>
              {meetingsToDelete.map(meeting => (
                <div className='gridStyle' key={meeting.id}>
                  <div className={'dtm'}>
                    {formatDate(fromTimestamp(meeting.startDate))}
                    <div className='bullet'></div>
                    {formatTime(fromTimestamp(meeting.startDate))}
                  </div>
                  <div>{
                    meeting.participants.map(it => (
                      <div key={it.id}>
                        <Icon component={userIcon} alt={''} />&nbsp;<span>{it.name}&nbsp;</span>
                      </div>
                    ))}
                  </div>
                  <div>{
                    meeting.groups.map(it => (
                      <div key={it.id}>
                        <Icon component={iconMultiSelectionContacts} alt={''} />&nbsp;<span>{it.name}&nbsp;</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
          <div className='label switch-wrapper'>
            <Switch defaultChecked={notify} onChange={onNotifyChange}/>
            <span>{t('Absage-Meldung an die Teilnehmer senden')}</span>
          </div>
        </div>
      </Modal>

      <OkModal visible={openDeleteWarningModal} onClose={() => setOpenDeleteWarningModal(false)}
        content={t('Achtung: Mindestens einer der ausgewählten Termine kann nicht gelöscht werden, da er von Noventi ORA verwaltet wird. Bitte prüfen und überarbeiten Sie Ihre Auswahl.')}/>
    </>
  )
}
