import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { AllowStatus, DeviceType, NetworkConnectionStatus } from '../../util/enums';
import { getConnectedDevices } from '../../service/DeviceService';
import { testNetworkSpeed } from '../../service/NetworkService';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from '../../util/routes';
import { errorMessage, timestamp } from '../../util/utils';
import { useRecoilState } from 'recoil';
import { loggedMember } from '../../state/atoms';
import MeetingApi from '../../api/MeetingApi';
import { MeetingLogDtoTypeEnum } from '../../gen/client';
import { handleServerError } from '../../util/ErrorHandler';
import moment from 'moment';
import Storage from "../../util/Storage";
import { CONNECT_USING_LINK } from "../../util/constants";
import CheckConnectionTemplate from '../general/CheckConnectionTemplate';

export default function CheckConnection() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [logged] = useRecoilState(loggedMember);

  const [currentStep, setCurrentStep] = useState(-1);
  const [audioStatus, setAudioStatus] = useState('');
  const [cameraStatus, setCameraStatus] = useState('');
  const [networkStatus, setNetworkStatus] = useState('' as NetworkConnectionStatus);
  const [errorDeviceType, setErrorDeviceType] = useState(null as DeviceType);

  const init = useCallback(() => {
    if (!Storage.getMemberId() && !Storage.getParticipantId()) {
      errorMessage(CONNECT_USING_LINK, 15);
      return;
    }

    setTimeout(() => {
      setCurrentStep(0);
      setNetworkStatus(testNetworkSpeed);
    }, 1500);
    getConnectedDevices(DeviceType.AUDIO_INPUT, audio => {
      setTimeout(() => {
        setCurrentStep(1);
        let status = (audio.length > 0) ? AllowStatus.ALLOWED : AllowStatus.NOT_ALLOWED;
        setAudioStatus(status);
      }, 3000);
    });
    getConnectedDevices(DeviceType.VIDEO_INPUT, cameras => {
      setTimeout(() => {
        setCurrentStep(2);
        let status = (cameras.length > 0) ? AllowStatus.ALLOWED : AllowStatus.NOT_ALLOWED;
        setCameraStatus(status);
      }, 4500);
    });
  }, []);

  useEffect(() => {
    init()
  }, [init]);

  const startMeeting = useCallback(() => {
    if (logged.skipPreview) {
      history.push(`${Routes.MEETING}/${id}`);
      MeetingApi.createMeetingLog({ createdDate: timestamp(moment()), type: MeetingLogDtoTypeEnum.MeetingStarted }, id)
        .then(() => { })
        .catch(err => handleServerError(err));
    } else {
      history.push(`${Routes.MEETING}/${id}/preview`);
    }
  }, [history, id, logged.skipPreview]);

  useEffect(() => {
    if (networkStatus === NetworkConnectionStatus.PASSED) {
      if (cameraStatus === AllowStatus.ALLOWED && audioStatus === AllowStatus.ALLOWED) {
        setTimeout(() => {
          startMeeting();
        }, 1500);
      } else if (audioStatus === AllowStatus.NOT_ALLOWED) {
        setTimeout(() => {
          setErrorDeviceType(DeviceType.AUDIO_INPUT);
        }, 500);
      } else if (cameraStatus === AllowStatus.NOT_ALLOWED) {
        setTimeout(() => {
          setErrorDeviceType(DeviceType.VIDEO_INPUT);
        }, 500);
      }
    }
  }, [networkStatus, cameraStatus, audioStatus, startMeeting, t]);

  function refreshDevices() {
    setCurrentStep(-1);
    setAudioStatus('');
    setCameraStatus('');
    setNetworkStatus('' as NetworkConnectionStatus);
    setErrorDeviceType(null as DeviceType);

    init();
  }

  function abortMeeting() {
    history.push(Routes.APPOINTMENTS);
  }

  return (
    <CheckConnectionTemplate currentStep={currentStep} errorDeviceType={errorDeviceType} finished={false}
      audioStatus={audioStatus} cameraStatus={cameraStatus} networkStatus={networkStatus}
      onStart={startMeeting} onAbort={abortMeeting} onRefresh={refreshDevices} />
  )
}
