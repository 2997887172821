import * as React from 'react';
import './MeetingInfoDrawer.scss';
import participantIcon from '../../img/icons/participant-icon-thin.svg';

interface MeetingProviderInfoProps {
  active: boolean;
  name : string;
} 

export default function MeetingProviderInfo({active, name}: MeetingProviderInfoProps) {

  return (
    <div className='provider'>
      <div className={'avatar'}><img src={participantIcon} alt={''}/><span className={`status ${active ? 'online' : 'offline'}`}></span></div>
      <div className={'info'}>
        <div className={'participant-name'}>{name}</div>
      </div>
    </div>
  )
}
