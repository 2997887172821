import * as React from 'react';
import './Members.scss';
import {useState, useEffect, createRef, useCallback} from 'react';
import {useTranslation} from "react-i18next";
import Container from '../general/Container';
import ContactsHeader from '../contact/ContactsHeader';
import {ViewMode, SortDirection} from '../../util/enums';
import {Card, Checkbox, Button, Modal, Skeleton} from 'antd';
import femaleIcon from '../../img/icons/gender-female-icon.svg';
import maleIcon from '../../img/icons/gender-male-icon.svg';
import diversIcon from '../../img/icons/gender-divers-icon.svg';
import cameraIcon from '../../img/icons/video-camera-outline-icon.svg';
import videoIcon from '../../img/icons/video-outline-icon.svg';
import editIcon from '../../img/icons/edit-icon.svg';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {downloadFileUsingBlob, generateCharArray, hasPracticeManagerRole, isAdmin, isCustomerCare, sortAscendingMemberByLastName, sortDescendingMemberByLastName, successMessage} from '../../util/utils';
import {Routes, isNewMemberRoute, isSearchRoute} from '../../util/routes';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useRecoilState} from "recoil";
import {activePractice, loggedMember} from "../../state/atoms";
import PracticeApi from "../../api/PracticeApi";
import {handleServerError} from "../../util/ErrorHandler";
import {
  MemberDto,
  MemberDtoGenderEnum, MemberCountDtoKeyEnum
} from "../../gen/client";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import MemberLine from './MemberLine';
import MemberApi from '../../api/MemberApi';
import UserGeneralSettings from "../settings/UserGeneralSettings";
import AdminApi from "../../api/AdminApi";
import CustomerCareApi from '../../api/CustomerCareApi';
import ShowLoginHintModal from '../general/ShowLoginHintModal';
import Storage from "../../util/Storage";

const {confirm} = Modal;

interface ReferenceProp {
  [key: string]: any;
}

export default function Members() {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const {id} = useParams();

  const [logged] = useRecoilState(loggedMember);
  const [practice] = useRecoilState(activePractice);

  const [isLoading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState(ViewMode.LIST);
  const [selected, setSelected] = useState([] as string[]);
  const [references, setReferences] = useState([] as ReferenceProp[]);
  const [list, setList] = useState([] as MemberDto[]);
  const [filter, setFilter] = useState(MemberCountDtoKeyEnum.All);
  const [memberStatusChange, setMemberStatusChange] = useState(false);
  const [showHintModal, setShowHintModal] = useState(false);

  const letters = generateCharArray('A', 'Z');

  const scrollToItem = useCallback((itemId: string) => {
    if (references && (references as ReferenceProp)[itemId]) {
      (references as ReferenceProp)[itemId].current?.scrollIntoView({ // NOSONAR
        behavior: 'smooth',
        block: "center",
        inline: "nearest"
      });
    }
  }, [references]);

  useEffect(() => {
    if (hasPracticeManagerRole(logged.roles) && logged.showLoginHint && (JSON.parse(Storage.getShowLoginHint()) === true)) {
      setShowHintModal(true);
    }
  }, [logged]);

  useEffect(fetchMembers, [logged]);

  useEffect(() => {
    if (isNewMemberRoute(location.pathname)) {
      setSelected(['new'])
    }
  }, [location]);

  useEffect(() => {
    if (!id) return;
    setSelected([id]);
    scrollToItem(id);
  }, [id, scrollToItem]);

  useEffect(() => {
    setReferences(list.reduce((acc: any, value: MemberDto) => {
      acc[value.id] = createRef();
      return acc;
    }, {}));
  }, [list]);

  function fetchMembers(currentFilter?: MemberCountDtoKeyEnum) {
    if (!practice.id) return;

    setLoading(true);
    let action;
    if (isSearchRoute(history.location.pathname)) {
      action = MemberApi.searchForManager(logged.id, getSearchKey())
    } else if (isAdmin(logged)) {
      action = AdminApi.getMembers(currentFilter || filter);
    } else if (isCustomerCare(logged)) {
      action = CustomerCareApi.getMembers(currentFilter || filter);
    } else {
      action = PracticeApi.filterMembers(currentFilter || filter, practice.id);
    }

    action.then(resp => {
      setList(sortAscendingMemberByLastName(resp.data));
      setLoading(false);
    }).catch((err) => {
      handleServerError(err);
      setLoading(false);
    })
  }

  function getSearchKey(): string {
    const query = new URLSearchParams(history.location.search);
    return query.get('key');
  }

  function onSelectToggle(memberId: string) {
    let memberIds = [];
    if (selected.indexOf(memberId) > -1) {
      memberIds = selected?.filter(it => it !== memberId);
    } else {
      memberIds = [...selected, memberId];
    }

    if (memberIds.length !== 1) {
      history.push(Routes.MEMBERS);
    } else {
      history.push(`${Routes.MEMBERS}/${memberIds[0]}`);
    }

    setSelected(memberIds);
  }

  function onToggleAll(event: CheckboxChangeEvent) {
    if (event.target.checked) {
      setSelected([...list.map(it => it.id)]);
    } else {
      setSelected([]);
    }
  }

  function onClick(memberId: string) {
    if (selected.length === 1 && selected.indexOf(memberId) > -1) {
      setSelected([]);
      history.push(Routes.MEMBERS);
    } else {
      setSelected([memberId]);
      history.push(`${Routes.MEMBERS}/${memberId}`);
    }
  }

  function onCancel() {
    setSelected([]);
  }

  function onDelete(event: React.MouseEvent<HTMLElement, MouseEvent>, member: MemberDto) {
    event.stopPropagation();
    confirm({
      title: t('Are you sure you want to delete {{firstName}} {{lastName}}?', {firstName: member.firstName, lastName: member.lastName}),
      icon: <ExclamationCircleOutlined/>,
      content: t('{{firstName}} {{lastName}} will no longer be in your list.', {firstName: member.firstName, lastName: member.lastName}),
      okText: t('Yes'),
      okType: 'danger',
      cancelText: t('No'),
      onOk() {
        deleteMember(member);
      }
    });
  }

  function deleteMember(member: MemberDto) {
    if (selected.length === 1 && selected.indexOf(member.id) > -1) {
      setSelected([]);
    }

    const promise = isAdmin(logged) || isCustomerCare(logged) ?
      AdminApi.deleteMember(member.id) :
      PracticeApi.deleteProvider(practice.id, member.id);
    promise.then(() => {
      successMessage(t('Succesfully deleted'));
      showPrintDeletion(member);
      fetchMembers();
    }).catch(handleServerError);
  }

  function showPrintDeletion(member: MemberDto) {
    Modal.info({
      title: t(`Der Löschvorgang ist in der folgenden Datei dokumentiert`),
      okText: t('Drücken'),
      okType: 'primary',
      onOk() {
        MemberApi.printMemberDeletion(logged.id, member.id)
          .then(resp => downloadFileUsingBlob(resp.request.response, `${member.id}.pdf`))
          .catch(handleServerError);
      }
    });
  }

  function sortContacts(direction: SortDirection) {
    const sortedList = (direction === SortDirection.Asc) ? sortAscendingMemberByLastName(list) : sortDescendingMemberByLastName(list);
    setList([...sortedList]);
  }

  function onSaveSuccess(member: MemberDto) {
    successMessage(t('Erfolgreich gespeichert!'));
    history.push(Routes.MEMBERS);
    fetchMembers();
    setSelected([]);
  }

  function onFilterChange(newFilter: MemberCountDtoKeyEnum) {
    setFilter(newFilter);
    setSelected([]);
    fetchMembers(newFilter);
    history.push(Routes.MEMBERS);
  }

  function handleLetterOption(letter: string) {
    const members = list.filter(member => member.firstName.startsWith(letter.toUpperCase()) || member.firstName.startsWith(letter.toLowerCase()));
    if (members && members.length > 0) {
      scrollToItem(members[0].id);
    }
  }

  function onHintModalClose(value: boolean) {
    setShowHintModal(false);
    Storage.setShowLoginHint(false);
    if (value) {
      MemberApi.hideLoginHint(logged.id)
        .then(() => {})
        .catch(handleServerError);
    }
  }

  const gridItems = list.map(contact => (
    <Card.Grid className={`gridStyle ${selected.indexOf(contact.id) > -1 ? 'active' : ''}`} hoverable={false} key={contact.id}>
      <div className='row' ref={(references as ReferenceProp)[contact.id]}>
        <Checkbox className='checkbox' checked={selected.indexOf(contact.id) > -1} onChange={() => onSelectToggle(contact.id)}/>
        <span className='contact-name'>{contact.firstName}&nbsp;{contact.lastName}&nbsp;
          <img src={contact.gender === MemberDtoGenderEnum.Female ? femaleIcon : contact.gender === MemberDtoGenderEnum.Male ? maleIcon : diversIcon} alt={''}/>
        </span>
        <Button type={'link'}><img src={videoIcon} alt={'video'}/></Button>
      </div>
      <div className='row left-align'>
        <span className='col-1'>{contact.phone}</span>
        <div className='col-1'>
          <img src={cameraIcon} alt={''}/>3
        </div>
        <Button type={'link'}><img src={editIcon} alt={'edit'}/></Button>
      </div>

      <div className='row left-align'>
        <Button type={'link'} className='link'>{contact.email}</Button>
      </div>
    </Card.Grid>
  ));

  return (
    <Container className={`contacts members full vd-list ${selected && selected.length === 1 ? 'item-selected' : ''} ${selected && selected.length === 1 && selected[0] === 'new' ? 'add-new' : ''}`}>
      {selected && selected.length === 1 && selected[0] === 'new' ?
        <header>
          <h2>{t('Neuer Nutzer')}</h2>
        </header>
        :
      <ContactsHeader onToggleAll={onToggleAll} onChangeViewMode={setViewMode} shortView={selected.length === 1} onSortChange={sortContacts} filter={filter} onFilterChange={onFilterChange}
                isAdmin={true} emptyContactList={list.length === 0} memberView={true} membersCount={list.length} updated={memberStatusChange} onClearSearch={fetchMembers}/>
      }

      <div className={`content`}>
        {(viewMode === ViewMode.LIST || selected.length === 1) && selected[0] !== 'new' &&
        <div className={'contact-list'}>
          {isLoading && <Skeleton active/>}
          {!isLoading && list.map(it =>
            <div ref={(references as ReferenceProp)[it.id]} key={it.id}><MemberLine key={it.id} member={it} selected={selected.indexOf(it.id) > -1} onSelectToggle={onSelectToggle}
                                                                                    shortView={selected.length === 1} onClick={onClick} onDelete={onDelete}/></div>)
          }
        </div>}

        {viewMode === ViewMode.GRID && selected.length !== 1 &&
        <div className='contact-list'>
          <Card className='grid-contacts'>{gridItems}</Card>
        </div>}

        {selected && selected.length === 1 &&
        <div className={'info'}>
          {selected[0] === 'new' &&
          <UserGeneralSettings isSelf={false} isAdmin={isAdmin(logged)} memberId={null} onSaveSuccess={onSaveSuccess} onCancel={onCancel}
                               onMemberStateChange={() => {
                               }}/>}

          {selected[0] !== 'new' &&
          <UserGeneralSettings isSelf={false} isAdmin={isAdmin(logged)} memberId={selected[0]} onSaveSuccess={onSaveSuccess} onCancel={onCancel}
                               onMemberStateChange={() => setMemberStatusChange(!memberStatusChange)}/>}
        </div>}

        <div className='letters-bar'>
          {letters.map(it => <div key={it}>
            <Button className='letter-btn' onClick={() => handleLetterOption(it)}>{it}</Button>
          </div>)}
        </div>
        
      </div>

      <ShowLoginHintModal visible={showHintModal} onClose={onHintModalClose}/>
    </Container>
  )
}
