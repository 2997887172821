import * as React from 'react';
import {useEffect, useState} from 'react';
import './GroupInfo.scss';
import {Button, Divider, Tabs} from 'antd';
import {ReactComponent as backIcon} from '../../img/icons/back-icon.svg';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../util/routes';
import {AccountGroupDto} from '../../gen/client';
import Icon from '@ant-design/icons';
import GroupContactLine from './GroupContactLine';
import GroupFields from './GroupFields';
import {sortAscendingByName} from '../../util/utils';

interface GroupInfoProps {
  group: AccountGroupDto;
  onEdit: (group: AccountGroupDto) => void;
  onCancelEdit: () => void;
  onBackPush: () => void;
}

export default function GroupInfo({group, onEdit, onCancelEdit, onBackPush}: GroupInfoProps) {
  const {t} = useTranslation();
  const history = useHistory();
  const {TabPane} = Tabs;

  const [editView, setEditView] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);

    setEditView(queryParams.has('edit'));
  }, [history.location.search]);

  function onCancel() {
    setEditView(false);

    const queryParams = new URLSearchParams(history.location.search)

    if (queryParams.has('edit')) {
      queryParams.delete('edit');
      history.replace({
        search: queryParams.toString(),
      });
    }
    onCancelEdit();
  }

  function goToCreateAppointment() {
    history.push(`${Routes.APPOINTMENTS_NEW_GROUP}?id=${group.id}`);
  }

  function editGroup(dto: AccountGroupDto) {
    setEditView(false);
    onEdit(dto);
  }

  return (
    <div>
      <div className='main'>
        <div className='hdr'>
          <Button type="link" className='back-btn' onClick={() => onBackPush()}><Icon component={backIcon}/></Button>
          <div className='info'>
            <h1>{group?.name}&nbsp;</h1>
            <h3>{group?.accounts.length}&nbsp;{t('Teilnehmer')}</h3>
          </div>
        </div>
        <div className='hdr-btns'>
          <Button type={'primary'} size="large" className='termin-btn' onClick={() => goToCreateAppointment()}
                  disabled={editView}>{t('Termin vereinbaren')}</Button>
          <Button type={'primary'} size="large" className='edit-btn' onClick={() => setEditView(true)}
                  disabled={editView}>{t('Bearbeiten')}</Button>
        </div>
      </div>
      {!editView &&
      <Tabs defaultActiveKey="1">
          <TabPane tab={t('Teilnehmer')} key="1">
              <div className='appointments'>
                  <div className='termine'>
                      <h2>{t('Teilnehmer')}</h2>
                  </div>
                  <div className={'list'}>
                    {group?.accounts.length > 0 &&
                    sortAscendingByName(group.accounts).map(it => <GroupContactLine key={it.id} contact={it}/>)}
                  </div>
              </div>
          </TabPane>
      </Tabs>
      }

      {editView && group &&
      <div key={group.id} className='edit-group'><Divider/> <GroupFields active={group} onSave={editGroup}
                                                                         onCancel={onCancel}/></div>}
    </div>
  )
}
