import {Button, Modal} from 'antd';
import './OkModal.scss';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

interface OkModalProps {
  visible: boolean;
  content: string;
  onClose: () => void;
}

export default function OkModal({visible, content, onClose}: OkModalProps) {
  const {t} = useTranslation();

  return (
    <Modal className={'ok-modal'} visible={visible}>
      <h3>{content}</h3>
      <Button type={'primary'} size={'middle'} className={'ok-btn'} onClick={() => onClose()}>{t('Ok')}</Button>
    </Modal>
  );
}