import * as React from 'react';
import './DeviceErrorModal.scss';
import {useTranslation} from "react-i18next";
import {Button, Modal} from "antd";
import {DeviceType} from '../../util/enums';
import {ExclamationCircleOutlined} from "@ant-design/icons";

interface DeviceErrorModalProps {
  deviceType: DeviceType;
  onAbort: () => void;
  onStart: () => void;
  onRefresh: () => void;
}

export default function DeviceErrorModal({deviceType, onAbort, onStart, onRefresh}: DeviceErrorModalProps) {
  const {t} = useTranslation();

  function getDeviceNotAvailableModalTitle(type: DeviceType) {
    if (type === DeviceType.VIDEO_INPUT) {
      return t('Ihre Kamera steht nicht zur Verfügung.');
    } else {
      return t('Ihr Mikrofon steht nicht zur Verfügung.');
    }
  }

  return (
    <Modal
      title={<span><ExclamationCircleOutlined/> {getDeviceNotAvailableModalTitle(deviceType)}</span>}
      visible={true}
      closeIcon={null}
      closable={false}
      footer={[
        <Button danger size="large" onClick={onAbort} key={'1'}>{deviceType === DeviceType.VIDEO_INPUT ? t('Nein') : t('Abbrechen')}</Button>,
        <Button type={'default'} size="large" onClick={onRefresh} key={'2'}>{t(`${(deviceType === DeviceType.VIDEO_INPUT ? 'Kamera' : 'Mikrofon')} erneut suchen`)}</Button>,
        deviceType === DeviceType.VIDEO_INPUT &&
        <Button type={'primary'} size="large" onClick={onStart} key={'3'}>{t('Ja')}</Button>
      ]}>
      <div className={'device-not-available'}>
        {deviceType === DeviceType.VIDEO_INPUT && <>
            <span>{t(`Sie wird möglicherweise von einem anderen Browser-Fenster oder einer anderen Applikation blockiert.`)}</span>
            <span>{t('Möchten Sie die Video-Sprechstunde trotzdem starten?')}</span></>}
        {deviceType === DeviceType.AUDIO_INPUT && <>
            <span>{t('Es wird möglicherweise von einem anderen Browser-Fenster oder einer anderen Applikation blockiert.')}</span>
            <span>{t('Ohne Mikrofon könen Sie nicht an einer Video-Sprechstunde teilnehmen.')}</span></>}
      </div>
    </Modal>
  )
}
