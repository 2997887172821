import * as React from 'react';
import '../appointment/Appointment.scss';
import {ParticipantGroupDto} from "../../gen/client";
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import {ReactComponent as deleteIcon} from '../../img/icons/trash-icon.svg';
import {ReactComponent as userIcon} from '../../img/icons/user-icon.svg';

interface GroupShortViewProps {
  group: ParticipantGroupDto;
  onDelete: () => void;
}

export default function ParticipantGroupShortView({ group, onDelete }: GroupShortViewProps) {
  return (
    <div>
      <div className='row'>
        <h3>{group.name}</h3>
        <div>
          <Button type={'link'} className="icon-only del-btn"><Icon component={deleteIcon} alt={'delete'} onClick={onDelete} /></Button>
        </div>
      </div>
      <div className='contact-row'>
        <Icon className="icon-only" component={userIcon} alt={''}/><span>{group.participants.length}</span>
      </div>
    </div>
  )
}
