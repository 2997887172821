/**
* This file is generated
*/
import * as React from 'react';
import { CSSProperties } from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24" xmlSpace="preserve"><path id="noun_comment_847907" d="M12,2C6.5,2,2,5.4,2,9.6c0,3.2,2.6,6,6.4,7.1C8.3,17.8,8,19,7.4,20c-0.5,0.8-0.6,1.3-0.4,1.7
	C7.2,21.9,7.4,22,7.7,22h0c0.4,0,1.3,0,7.6-5.2c4-1.1,6.7-3.9,6.7-7.2C22,5.4,17.5,2,12,2z"/></svg>
);

const FeedbackIcon = (props: { style?: CSSProperties, className?: string }) => <Icon component={svg} {...props}/>;

export default FeedbackIcon;