import React, {useCallback, useEffect} from 'react';
import {Input, Form} from "antd";
import {isValidEmail, isEmpty} from "../../util/validators";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import './EmailInput.scss'
import {Rule} from 'antd/lib/form';

const {Item} = Form;

interface EmailInputProps {
  value?: string;
  onChange?: (value: string) => void;
  asFormItem: boolean;
  required: boolean;
  disabled?: boolean;
  hasError?: boolean;
  defaultValue?: string;
}

export default function EmailInput({value, onChange, asFormItem, required, disabled, hasError, defaultValue}: EmailInputProps) {
  const isValid = useCallback((val: string) => {
    if (required) {
      return !isEmpty(val) && isValidEmail(val);
    } else {
      return isEmpty(val) || isValidEmail(val);
    }
  }, [required]);

  const {t} = useTranslation();
  const [valid, setValid] = useState(!value ? true : isValid(value));
  const [empty, setEmpty] = useState(!value ? false : isEmpty(value));

  useEffect(() => {
    isValid(value);
  }, [isValid, value]);

  const emailValidator = (rule: Rule, val: string) => {
    if (isValid(val)) return Promise.resolve();
    return Promise.reject('E-Mail ist falsch');
  };

  function onEmailChange(newValue: string) {
    setValid(isValid(newValue));
    setEmpty(isEmpty(newValue));
    if (onChange) onChange(newValue);
  }

  return (
    <>
      {asFormItem ?
        <Item label={t('E-Mail')} name={'email'} initialValue={value} rules={[{required, message: t('Feld ist erforderlich')}, {validator: emailValidator}]}>
          <Input size={'large'} inputMode={'email'} type={'email'} value={value} defaultValue={defaultValue} onChange={ev => onEmailChange(ev.target.value)} disabled={disabled}/>
        </Item>
        :
        <div className={`email-input ${!valid || (hasError || (required && empty)) ? 'ant-form-item-has-error' : ''}`}>
          <label className={required ? 'req' : ''}>{t('E-Mail')}</label>
          <Input size={'large'} className={!valid || (hasError || (required && empty)) ? 'error' : ''} inputMode={'email'} type={'email'} value={value} defaultValue={defaultValue}
                 onChange={ev => onEmailChange(ev.target.value)} disabled={disabled}/>
          {(hasError || !valid) && <div className='err-msg'>{t('E-Mail ist falsch')}</div>}
          {(hasError || (required && empty)) && <div className='err-msg'>{t('Feld ist erforderlich')}</div>}
        </div>
      }
    </>
  );
}
