import React, {useCallback, useEffect, useState} from 'react';
import {errorMessage, getApplicationSizeClass, initialStorageSetup} from '../../util/utils';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Dimensions } from '../../util/enums';
import {activePractice, applicationSize, loggedMember, loginData, logoutEvents} from '../../state/atoms';
import regImage from '../../img/register-1.svg';
import mainLogo from '../../img/noventi-video-logo.png';
import { useTranslation } from 'react-i18next';
import ActivateMfaForm from './ActivateMfaForm';
import { useHistory } from 'react-router-dom';
import Storage from '../../util/Storage';
import AuthApi from '../../api/AuthApi';
import { getLoggedAtom } from '../../state/logged';
import {getInitialRoute, isActiveMfaRoute, Routes} from '../../util/routes';
import { handleServerError } from '../../util/ErrorHandler';
import StartPageFooter from './StartPageFooter';
import { MfaFieldsDto } from '../../gen/client';

export default function ActivateMfaPage() {
	const { t } = useTranslation();
	const appSize = useRecoilValue<Dimensions>(applicationSize);
	const [loginInfo, setLoginInfo] = useRecoilState(loginData);
	const [logged, setLogged] = useRecoilState(loggedMember);
	const setPractice = useSetRecoilState(activePractice);
	const setPreLogoutEvents = useSetRecoilState(logoutEvents);
	const history = useHistory();
	const [isLoading, setLoading] = useState(false);
	const [mfaFields, setMfaFields] = useState({} as MfaFieldsDto);

	function login() {
		setPreLogoutEvents(preLogoutEvents => preLogoutEvents.filter(it => it !== preLogoutFunc));
		
		setLoading(true);
		AuthApi.login(Object.assign({}, loginInfo, {})).then(resp => {
			setLoading(false);
			const member = resp.data.member;
			if (member.id) {
				const loggedAtom = getLoggedAtom(resp.data);
				setLogged(loggedAtom);
				initialStorageSetup(resp.data, loggedAtom.activeRole);

				setLoginInfo({email: '', password: ''});
				setPractice({
					id: member.practice.id,
					name: member.practice.name
				});

				history.push(getInitialRoute(resp.data.needsSetup, loggedAtom.activeRole, member.settings.twoFactorAuthentication != null));
			} else {
				if (resp.data.codeSent) {
					history.push(Routes.LOGIN_CONFIRM);
				} else {
					errorMessage(t('Failed to send multi faktor authentication token. Please contact support.'))
				}
			}
		}).catch(err => {
			setLoading(false);
			handleServerError(err);
		});
	}

	useEffect(() => {
		if ((!loginInfo.email || !loginInfo.password) && !Storage.getMemberId()) {
			history.push(Routes.LOGIN);
		}
	}, [history, loginInfo]);

	function onDeactivateButtonClick() {
		setMfaFields({...mfaFields, twoFactorAuthentication: false});
	}

	function onMfaPhoneChange(mfaPhone: string) {
		setMfaFields({...mfaFields, mfaPhone});
	}

	function onActivateButtonClick() {
		setMfaFields({...mfaFields, twoFactorAuthentication: true});
	}
	
	const preLogoutFunc = useCallback(() => {
		return new Promise<string>((resolve) => {
			if (isActiveMfaRoute(history.location.pathname)) {
				AuthApi.updateMfaFields(Storage.getMemberId(), {twoFactorAuthentication: false})
					.then(() => resolve(''))
					.catch(() => resolve(''));
			}
		})
	}, [history.location.pathname]);

	useEffect(() => {
		if (!!Storage.getMemberId()) {
			setLoading(true);
			AuthApi.getMfaFields(Storage.getMemberId()).then(resp => {
				setMfaFields(resp.data);
				setLoading(false);
			}).catch(err => {
				handleServerError(err);
				setLoading(false);
			});
		}
	}, [logged.logged]);

	useEffect(() => {
		setPreLogoutEvents(preLogoutEvents => [...preLogoutEvents, preLogoutFunc]);

		return () => {
			setPreLogoutEvents(preLogoutEvents => preLogoutEvents.filter(it => it !== preLogoutFunc));
		}
	}, [preLogoutFunc, setPreLogoutEvents]);
	
	function onSaveMfa() {
		setLoading(true);

		updateMfaFieldsAndLogin();
	}

	function updateMfaFieldsAndLogin() {
		AuthApi.updateMfaFields(Storage.getMemberId(), mfaFields).then(() => {
			setLoading(false);

			login();
		}).catch(ex => {
			setLoading(false);
			handleServerError(ex);
		});
	}

	return (
		<div className={`login-page activate-mfa-page ${getApplicationSizeClass(appSize)}`}>
			<div className={'hero'}>
				<div className={'ct'}>
					<img src={regImage} className={'main'} alt={''}/>
				</div>
			</div>
			<div className={'content'}>
				<div className={'data'}>
					<img className={'logo'} src={mainLogo} alt={'logo'} />
					<h3>{t('Aktivieren Sie die Zwei-Faktor-Authentifizierung, um Ihre Daten noch besser zu schützen.')}</h3>

					<ActivateMfaForm onSave={onSaveMfa}
													 loading={isLoading}
													 phoneValue={mfaFields.mfaPhone}
													 onPhoneChange={onMfaPhoneChange}
													 onActivateButtonClick={onActivateButtonClick}
													 onDeactivateButtonClick={onDeactivateButtonClick}
					/>
				</div>
				{appSize === Dimensions.DESKTOP && <StartPageFooter />}
			</div>
			{appSize !== Dimensions.DESKTOP && <StartPageFooter />}
		</div>
	);
}
