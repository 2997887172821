import React, {useState} from 'react';
import './Settings.scss';
import {Form, Button} from "antd";
import {useTranslation} from "react-i18next";
import PasswordStrength from '../general/PasswordStrength';
import Password from 'antd/lib/input/Password';
import './UserPassword.scss';
import {hasMinLength, hasOneLetter, hasOneNumber, hasSpecialChar, hasUpperAndLowerChar} from '../../util/validators';
import {handleServerError} from '../../util/ErrorHandler';
import MemberApi from '../../api/MemberApi';
import AuthApi from '../../api/AuthApi';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../util/routes';
import {UpdatePasswordDto} from '../../gen/client';
import {errorMessage, successMessage, warnMessage} from "../../util/utils";

interface UserPasswordProps {
  memberId?: string;
  code?: string;
  onCancel?: () => void;
}

export default function UserPassword({memberId, code, onCancel}: UserPasswordProps) {
  const {t} = useTranslation();
  const history = useHistory();

  const {Item} = Form;
  const [oldPassword, setOldPassword] = useState('' as string);
  const [newPassword, setNewPassword] = useState('' as string);
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('' as string);
  const [validMinLength, setValidMinLength] = useState(false);
  const [validOneNumber, setValidOneNumber] = useState(false);
  const [validOneLetter, setValidOneLetter] = useState(false);
  const [validUpperLowerCase, setValidUpperLowerCase] = useState(false);
  const [validSpecialChar, setValidSpecialChar] = useState(false);

  const [resetPasswordView] = useState(!!code);
  const [saving, setSaving] = useState(false);

  function onOldPasswordChange(value: string) {
    setOldPassword(value);
  }

  function onNewPasswordChange(value: string) {
    setNewPassword(value);

    setValidMinLength(hasMinLength(value));
    setValidOneNumber(hasOneNumber(value));
    setValidOneLetter(hasOneLetter(value));
    setValidUpperLowerCase(hasUpperAndLowerChar(value));
    setValidSpecialChar(hasSpecialChar(value));
  }

  function onNewPasswordConfirmChange(value: string) {
    setNewPasswordConfirm(value);
  }

  function dto(): UpdatePasswordDto {
    return {
      oldPassword,
      newPassword,
      newPasswordConfirm
    };
  }

  function resetPassword() {
    setSaving(true);
    AuthApi.resetPassword(code, dto()).then(resp => {
      setSaving(false);
      successMessage(t('Erfolgreich gespeichert!'));
      history.push(Routes.LOGIN);
    }).catch(err => {
      setSaving(false);
      handleServerError(err);
    });
  }

  function onCancelButtonClick() {
    if (typeof onCancel === 'function') {
      onCancel();
    }
  }

  function onSubmit() {
    if (
      (resetPasswordView || oldPassword !== '') &&
      newPassword !== '') {
       if(validMinLength &&
				validOneNumber &&
				validOneLetter &&
				validUpperLowerCase &&
				validSpecialChar) {
				 if (newPassword !== newPasswordConfirm) {
					 warnMessage(t('Password not matching!'));
				 } else if (resetPasswordView) {
					 resetPassword();
				 } else {
					 setSaving(true);
					 MemberApi.updatePassword(memberId, dto()).then(() => {
						 setSaving(false);
             successMessage(t('Erfolgreich gespeichert!'));
					 }).catch(err => {
						 setSaving(false);
						 handleServerError(err);
					 });
				 }
			 } else {
				 errorMessage(t('Bitte beachten Sie die Passwortanforderungen'));
			 }
    } else {
      errorMessage(t('Feld ist erforderlich'));
    }
  }

  return (
    <div className='user-password'>
      <Form layout="vertical" className='form'>
        {!resetPasswordView &&
          <Item className='old-password' label={t('Aktuelle Passwort')} name={'old-password'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
            <Password size="large" onChange={(e) => onOldPasswordChange(e.target.value)} name="new-password" id="new-password" autoComplete="off"/>
            <PasswordStrength password={oldPassword} />
          </Item>
        }
        <Item className={`new-password ${resetPasswordView ? 'reset-password' : ''}`} label={t('Neue Passwort')} name={'new-password'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
          <Password size="large" onChange={(e) => onNewPasswordChange(e.target.value)} name="new-password" id="new-password" autoComplete="off"/>
          <PasswordStrength password={newPassword} />
        </Item>
        <Item className={`confirm-password ${resetPasswordView ? 'reset-password' : ''}`} label={t('Passwort wiederholen')} name={'confirm-password'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
          <Password size="large" onChange={(e) => onNewPasswordConfirmChange(e.target.value)} name="new-password" id="new-password" autoComplete="off"/>
          <PasswordStrength password={newPasswordConfirm} />
        </Item>
        <div className="requirements-wrapper">
          <h3>{t('Passwortanforderungen')}</h3>
          <ul className='password-requirements'>
            <li className={validMinLength ? 'checked' : ''}>{t('acht Zeichen lang ')}</li>
            <li className={validOneNumber ? 'checked' : ''}>{t('eine Zahl')}</li>
            <li className={validOneLetter ? 'checked' : ''}>{t('einen Buchstaben ')}</li>
            <li className={validUpperLowerCase ? 'checked' : ''}>{t('Groß- und Kleinschreibung')}</li>
            <li className={validSpecialChar ? 'checked' : ''}>{t('ein Sonderzeichen (!@#$%^&-_()*)')}</li>
          </ul>
        </div>
        <div className='buttons'>
          <Button type={'primary'} size="large" onClick={onSubmit} loading={saving}>{t('Speichern')}</Button>
          {!resetPasswordView &&
            <Button className={'default'} size="large" onClick={onCancelButtonClick}>{t('Abbrechen')}</Button>
          }
        </div>
      </Form>
    </div>
  );
}
