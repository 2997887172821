export enum NetworkConnectionStatus {
    NONE = 'Keine Internetverbindung',
    WEAK = 'Schlechte Internet-Verbindung',
    PASSED = 'ausreichend',
    RECONNECTING = 'Erneuter Verbindungsaufbau'
}

export enum AllowStatus {
    ALLOWED = 'Erlaubt',
    NOT_ALLOWED = 'Nicht Erlaubt'
}

export enum ActiveTab {
    FIRST = '1',
    SECOND = '2'
}

export enum MeetingQuality{
    TERRIBLE = 'sehr schlechte Qualität',
    BAD = 'schlechte Qualität',
    NORMAL = 'normale Qualität',
    GOOD = 'gute Qualität',
    WONDERFUL = 'sehr gute Qualität'
}

export enum ViewMode{
    LIST = 'LIST',
    GRID = 'GRID'
}

export enum SortDirection {
    Asc = 'asc',
    Desc = 'desc'
}

export enum Dimensions {
  DESKTOP,
  TABLET,
  MOBILE
}

export enum DeviceType {
    VIDEO_INPUT = 'videoinput',
    AUDIO_INPUT = 'audioinput',
    AUDIO_OUTPUT = 'audiooutput'
}

export enum ResetMemberInfoType {
    PASSWORD = 'password',
    MFA_PHONE = 'mfa_phone'
}
