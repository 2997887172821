import {Button, Tooltip} from 'antd';
import Password from 'antd/lib/input/Password';
import Modal from 'antd/lib/modal/Modal';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import MemberApi from '../../api/MemberApi';
import './IdentityConfirm.scss';
import {loggedMember} from '../../state/atoms';
import {useRecoilState} from 'recoil';
import {handleServerError} from '../../util/ErrorHandler';
import MfaInputBox from './MfaInputBox';
import {env} from '../../env/env';
import {MemberPasswordDto, MfaFieldsDto} from '../../gen/client';
import {MfaInputBoxState} from '../../util/types';
import {PASSWORD_CONFIRMED_CODE} from '../../util/constants';
import {errorMessage, successMessage} from "../../util/utils";

interface IdentityConfirmProps {
  visible: boolean;
  mfaFields: MfaFieldsDto;
  state: MfaInputBoxState;
  onlyPassword: boolean;
  disableMfaInput: boolean;
  onConfirm: (code: string) => void;
  onCancel: () => void;
  onStateChange: (state: MfaInputBoxState) => void;
  onResendMFACode: () => void;
}

export default function IdentityConfirm({visible, mfaFields, state, onlyPassword, disableMfaInput, onConfirm, onCancel, onStateChange, onResendMFACode}: IdentityConfirmProps) {
  const {t} = useTranslation();

  const [logged] = useRecoilState(loggedMember);
  const [password, setPassword] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [mfaStep, setMfaStep] = useState(false);
  const [checking, setChecking] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible]);

  function checkPassword() {
    if (password) {
      setChecking(true);
      const memberPasswordDto = { password } as MemberPasswordDto;
      MemberApi.confirmPassword(logged.id, memberPasswordDto).then(resp => {
        setChecking(false);
        if (resp.data) {
          onPasswordConfirmValid();
        } else {
          onPasswordConfirmFailed();
        }
      }).catch(handleServerError);
    }
  }

  function resendMfa() {
    setShowResendButton(false);
    setChecking(true);
    onStateChange(MfaInputBoxState.INITIAL);
    onResendMFACode();
    MemberApi.sendResetMfaPhoneCode(logged.id, mfaFields).then(() => {
      setChecking(false);
      successMessage(t('New MFA code sent'));
    }).catch(err => {
      setChecking(false);
			err.response.data.message = (
				<span dangerouslySetInnerHTML={{__html: t(err.response.data.message, {interpolation:{escapeValue: false}})}}>
				</span>
			)
			handleServerError(err);
      onCancel();
		});

    setTimeout(() => {
      setShowResendButton(true);
    }, env.resendMfaCodeTimeout);
  }

  function checkMfaCode() {
    if (mfaCode) {
      onConfirm(mfaCode);
    }
  }

  function onPasswordConfirmValid() {
    if (onlyPassword) {
      onConfirm(PASSWORD_CONFIRMED_CODE);
    } else {
      setMfaStep(true);
      resendMfa();
    }
  }

  function onPasswordConfirmFailed() {
    setPassword('');
    errorMessage(t('PASSWORD_INCORRECT'));
  }

  function cancel() {
    reset();
    onCancel();
  }

  function reset() {
    setPassword('');
    setMfaStep(false);
  }

  function updateMfaCode(value: string) {
    setDisableSaveButton(false);
    setMfaCode(value);
  }

  return (
    <Modal
      className={'password-confirm'}
      closable={false}
      visible={visible}
      centered
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      footer={null}
      destroyOnClose={true}>
      <div className='modal-body-wrapper'>
        {!mfaStep &&
          <>
            <header>
              <h3>{t('Passwort confirm')}</h3>
            </header>

            <Password size="large" onChange={(e) => setPassword(e.target.value)} onPressEnter={checkPassword} 
                      name="new-password" id="new-password" autoComplete="off"/>

            <div className='btns'>
              <Button type={'default'} size="large" className='cancel-btn' onClick={cancel}>{t('Abbrechen')}</Button>
              <Button type={'primary'} size="large" className='check-btn' onClick={checkPassword} disabled={checking} loading={checking}>{t('Ok')}</Button>
            </div>
          </>
        }
        {mfaStep &&
          <>
            <header>
              <h3>{t('Zweit-Faktor-Authentifizierung aktivieren')}</h3>
            </header>

            <MfaInputBox onChange={updateMfaCode} state={state} disabled={disableMfaInput} onInvalidValue={() => setDisableSaveButton(true)}/>

            <div className='btns'>
              <Button type={'default'} size="large" className='cancel-btn' onClick={cancel}>{t('Abbrechen')}</Button>
              <Tooltip title={!showResendButton ? t('Sie müssen 30 Sekunden warten, bevor Sie einen neuen Code anfordern können.') : undefined}>
                <Button type={'default'} size="large" className='resend-btn' onClick={resendMfa} disabled={!showResendButton}>{t('Senden Sie den Code erneut')}</Button>
              </Tooltip>
              <Button type={'primary'} size="large" className='check-btn' onClick={checkMfaCode} disabled={checking || disableSaveButton || disableMfaInput} loading={checking} >{t('Ok')}</Button>
            </div>
          </>
        }
      </div>
    </Modal >
  );
}
