import * as React from 'react';
import {ReactNode, useEffect, useState} from 'react';
import { Button, Card, DatePicker } from 'antd'
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import './IntervalPicker.scss';
import { DEFAULT_INTERVAL, STATISTICS_DEFAULT_INTERVAL } from '../statistic/state/statistics.state';
import { getApplicationSizeClass } from '../../util/utils';
import { useRecoilValue } from 'recoil';
import { applicationSize } from '../../state/atoms';
import { Dimensions } from '../../util/enums';
import {ReactComponent as calendarIcon}  from '../../img/icons/calendar-icon.svg';
import Icon from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/de_DE';
import { useHistory } from 'react-router';
import { isStatisticRoute } from '../../util/routes';
import {MEETING_DATE_FORMAT} from "../../util/constants";

export type DatePickerInterval = [Moment, Moment]

interface IntervalPickerProps {
  value: DatePickerInterval;
  onChange: (val: DatePickerInterval) => void;
  dateFormat?: string;
}

enum DateType {
  NONE,
  TODAY,
  ACTUAL_WEEK,
  NEXT_WEEK,
  ACTUAL_MONTH,
  NEXT_MONTH,
  CUSTOM
}

const { RangePicker } = DatePicker;

export default function IntervalPicker({ value, onChange, dateFormat = MEETING_DATE_FORMAT }: IntervalPickerProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [activeInterval, setActiveInterval] = useState(intervalToEnum(value));
  const [candidateInterval, setCandidateInterval] = useState(intervalToEnum(value));
  const [candidateValue, setCandidateValue] = useState(value);
  const [customDateRangeElements, setCustomDateRangeElements] = useState<HTMLElement[]>([]);
  const [predefinedDateRangeElements, setPredefinedDateRangeElements] = useState<HTMLElement[]>([]);
  const appSize = useRecoilValue<Dimensions>(applicationSize);
	const [lastValue, setLastValue] = useState(value);

  const [isStatisticsRoute, setIsStatisticsRoute] = useState(false);

  useEffect(() => {
    const el = document.getElementsByClassName('datepicker-input')[0] as HTMLElement;
    const customRangeElements = [
      el.getElementsByClassName('ant-picker-input')[0] as HTMLElement,
      el.getElementsByClassName('ant-picker-range-separator')[0] as HTMLElement,
      el.getElementsByClassName('ant-picker-input')[1] as HTMLElement,
    ];

    setCustomDateRangeElements(customRangeElements);

    customRangeElements.forEach(it => it.remove());

    const label = document.createElement('div');
    label.className = 'datepicker-custom-label';
    setPredefinedDateRangeElements([label]);
  }, []);

  useEffect(() => {
    renderPicker(value);
  }, [predefinedDateRangeElements, customDateRangeElements, value]);

  useEffect(() => {
    setActiveInterval(intervalToEnum(value));
    setCandidateInterval(intervalToEnum(value));
    setCandidateValue(value);
  }, [value]);

  useEffect(() => {
    setIsStatisticsRoute(isStatisticRoute(history.location.pathname))
  }, [history.location.pathname])

  function enumToInterval(type: DateType): DatePickerInterval {
    switch (type) {
      case DateType.TODAY:
        return [moment().startOf('day'), moment().endOf('day')]
      case DateType.ACTUAL_WEEK:
        return [moment().startOf('week'), moment().endOf('week')]
      case DateType.NEXT_WEEK:
        return [moment().startOf('week').add(1, 'week'), moment().endOf('week').add(1, 'week')]
      case DateType.ACTUAL_MONTH:
        return [moment().startOf('month'), moment().endOf('month')]
      case DateType.NEXT_MONTH:
        return [moment().endOf('month').add(1, 'day').startOf('month'), moment().endOf('month').add(1, 'day').endOf('month')]
      case DateType.CUSTOM:
        return isStatisticRoute(history.location.pathname) ?
          [moment().subtract(1, 'month').startOf('day'), moment()] : [moment().startOf('day'), moment().add(1, 'day').endOf('day')]
      default:
        return [moment().startOf('week'), moment().endOf('week')]
    }
  }

  function intervalToEnum(interval: DatePickerInterval): DateType {
    if (!interval[0] || !interval[1]) return DateType.CUSTOM;

    if (interval[0].isSame(moment().startOf('day')) && interval[1].isSame(moment().endOf('day'))) {
      return DateType.TODAY;
    } else if (interval[0].isSame(moment().startOf('week')) && interval[1].isSame(moment().endOf('week'))) {
      return DateType.ACTUAL_WEEK;
    } else if (interval[0].isSame(moment().startOf('week').add(1, 'week')) && interval[1].isSame(moment().endOf('week').add(1, 'week'))) {
      return DateType.NEXT_WEEK;
    } else if (interval[0].isSame(moment().startOf('month')) && interval[1].isSame(moment().endOf('month'))) {
      return DateType.ACTUAL_MONTH;
    } else if (interval[0].isSame(moment().endOf('month').add(1, 'day').startOf('month')) && interval[1].isSame(moment().endOf('month').add(1, 'day').endOf('month'))) {
      return DateType.NEXT_MONTH;
    } else {
      return DateType.CUSTOM
    }
  }

  function enumToLabel(type: DateType): string {
    switch (type) {
      case DateType.TODAY:
        return t('Heute');
      case DateType.ACTUAL_WEEK:
        return t('Aktuelle Woche');
      case DateType.NEXT_WEEK:
        return t('Nächste Woche');
      case DateType.ACTUAL_MONTH:
        return t('Aktueller Monat');
      case DateType.NEXT_MONTH:
        return t('Nächster Monat');
      default:
        return t('Benutzerdefiniert');
    }
  }

  // Render customized picker
  function renderPicker(interval: DatePickerInterval) {
    const el = document.getElementsByClassName('datepicker-input')[0] as HTMLElement;
    const calendarIconElement = el.getElementsByClassName('ant-picker-suffix')[0] as HTMLElement;
    const activeIntervalType = intervalToEnum(interval);
    if (activeIntervalType === DateType.CUSTOM) {
      customDateRangeElements.forEach(it => {
        el.insertBefore(it, calendarIconElement);
      });
      predefinedDateRangeElements.forEach(it => it.remove());
    } else {
      customDateRangeElements.forEach(it => it.remove());
      predefinedDateRangeElements.forEach(it => {
        it.innerText = enumToLabel(activeIntervalType);
        el.insertBefore(it, calendarIconElement);
      });
    }
  }

  function clear() {
    var defaultInterval = isStatisticRoute(history.location.pathname) ? STATISTICS_DEFAULT_INTERVAL: DEFAULT_INTERVAL;
    if (activeInterval !== DateType.NONE) {
      onChange(defaultInterval);
    }
    setOpen(false);
    setActiveInterval(DateType.NONE);

    setCandidateInterval(intervalToEnum(defaultInterval));
    setCandidateValue(defaultInterval);
    setLastValue(defaultInterval);
  }

  function cancel() {
    setOpen(false);
    onIntervalChange(lastValue);
  }

  function submit() {
    setOpen(false);
    onChange(candidateValue);
		setLastValue(candidateValue);
  }

  function onIntervalChange(val: DatePickerInterval) {
  	try {
  		val[0] = val[0].startOf('day');
			val[1] = val[1].endOf('day');
		} catch(error) {}
    setCandidateInterval(intervalToEnum(val));
    setCandidateValue(val);
  }

  function onOpenChange() {
    if (!open) {
      setOpen(true);
    }
  }

  function getPanelRender(panel: ReactNode) {
    return <div className={`${getApplicationSizeClass(appSize)} interval-picker-content-wrapper`}>
      <div className={`interval-picker-content`}>
        <div className={'interval-list'}>
          <Button
            className={candidateInterval === DateType.TODAY ? 'interval-button active' : 'interval-button'}
            type={'link'} onClick={() => {
            onIntervalChange(enumToInterval(DateType.TODAY))
          }}>{enumToLabel(DateType.TODAY)}</Button>
          <Button
            className={candidateInterval === DateType.ACTUAL_WEEK ? 'interval-button active' : 'interval-button'}
            type={'link'} onClick={() => {
            onIntervalChange(enumToInterval(DateType.ACTUAL_WEEK))
          }}>{enumToLabel(DateType.ACTUAL_WEEK)}</Button>
          {!isStatisticsRoute && <Button
            className={candidateInterval === DateType.NEXT_WEEK ? 'interval-button active' : 'interval-button'}
            type={'link'} onClick={() => {
            onIntervalChange(enumToInterval(DateType.NEXT_WEEK))
          }}>{enumToLabel(DateType.NEXT_WEEK)}</Button>}
          <Button
            className={candidateInterval === DateType.ACTUAL_MONTH ? 'interval-button active' : 'interval-button'}
            type={'link'} onClick={() => {
            onIntervalChange(enumToInterval(DateType.ACTUAL_MONTH))
          }}>{enumToLabel(DateType.ACTUAL_MONTH)}</Button>
          {!isStatisticsRoute && <Button
            className={candidateInterval === DateType.NEXT_MONTH ? 'interval-button active' : 'interval-button'}
            type={'link'} onClick={() => {
            onIntervalChange(enumToInterval(DateType.NEXT_MONTH));
          }}>{enumToLabel(DateType.NEXT_MONTH)}</Button>}
          <Button
            className={candidateInterval === DateType.CUSTOM ? 'interval-button active' : 'interval-button'}
            type={'link'} onClick={() => {
            onIntervalChange(enumToInterval(DateType.CUSTOM));
          }}>{enumToLabel(DateType.CUSTOM)}</Button>
        </div>
        <div className={'calendar'}>{panel}</div>
      </div>
      <div className={'buttons'}>
        <Button danger size="large" onClick={clear}>{t('Reset period')}</Button>
        <Button type={'default'} size="large" onClick={cancel}>{t('Abbrechen')}</Button>
        <Button type={'primary'} size="large" onClick={submit}>{t('Anwenden')}</Button>
      </div>
    </div>;
  }

  return (
    <Card className={`interval-picker ${activeInterval !== DateType.NONE ? 'active' : ''}`}>
      <RangePicker locale={locale} className={'datepicker-input'} suffixIcon={<Icon component={calendarIcon} alt={''} />}
        panelRender={getPanelRender}
        dropdownClassName={'interval-picker-panel'}
        value={candidateValue}
        bordered={false}
        inputReadOnly={true}
        allowClear={false}
        format={dateFormat}
        onOpenChange={onOpenChange}
        onChange={onIntervalChange}
        open={open}
        disabledDate={date => isStatisticRoute(history.location.pathname) && date.isAfter(moment())}
      />
    </Card>
  )
}
