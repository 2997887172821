import * as React from 'react';
import './PasswordForgotPage.scss';
import { useTranslation } from "react-i18next";
import regImage from '../../img/register-1.svg';
import mainLogo from '../../img/noventi-video-logo.png';
import StartPageFooter from "./StartPageFooter";
import { applicationSize } from "../../state/atoms";
import { NavLink } from "react-router-dom";
import { Button, Form } from 'antd';
import { useState } from 'react';
import { useRecoilValue } from "recoil";
import { handleServerError } from "../../util/ErrorHandler";
import { getApplicationSizeClass } from '../../util/utils';
import { Dimensions } from '../../util/enums';
import { Routes } from '../../util/routes';
import { Store } from 'antd/lib/form/interface';
import AuthApi from '../../api/AuthApi';
import EmailInput from '../general/EmailInput';

export default function PasswordForgotPage() {
    const { t } = useTranslation();

    const { Item } = Form;

    const [isLoading, setLoading] = useState(false);
    const [passwordSent, setPasswordSent] = useState(false);
    const appSize = useRecoilValue<Dimensions>(applicationSize);

    function sendPasswordForgotMail(store: Store) {
        setLoading(true);
        AuthApi.sendPasswordForgotMail(store.email).then(resp => {
            setLoading(false);
            setPasswordSent(true);
        }).catch(err => {
            setLoading(false);
            handleServerError(err);
        });
    }

    return (
        <div className={`login-page password-forgot-page ${getApplicationSizeClass(appSize)}`}>
            <div className={'hero'}>
                <div className={'ct'}>
                    <img src={regImage} className={'main'} alt={''} />
                </div>
            </div>
            <div className={'content'}>
                <div className={'data'}>
                    <img className={'logo'} src={mainLogo} alt={'logo'} />
                    <h3>{t('Zurücksetzen Ihres Passworts')}</h3>

                    {!passwordSent ?
                        <div>
                            <h6>{t('Geben Sie bitte Ihre Email-Adresse ein')}</h6>

                            <Form className={'log'} onFinish={sendPasswordForgotMail} layout="vertical">
                                <EmailInput asFormItem={true} required={true} />

                                <Item className={'flx ft'}>
                                    <Button size="large" type="primary" htmlType="submit" loading={isLoading}>{t('Passwort zurücksetzen')}</Button>
                                </Item>

                                <Item>
                                    {t('Zurück zur ')} <NavLink to={Routes.LOGIN}>{t('Anmeldung')}</NavLink>
                                    <NavLink className={'login-forgot'} to={Routes.REGISTER}>{t('Registrieren')}</NavLink>
                                </Item>
                            </Form>
                        </div>
                        : <div>
                            <h6>{t('Sie erhalten in Kürze eine E-Mail zum Zurücksetzen Ihres Passworts. Bitte schauen Sie ggf. auch in Ihrem SPAM-Ordner.')}</h6>
                            {t('Zurück zur ')}
                            <NavLink className={'to-login-page'} to={Routes.LOGIN}>{t('Anmeldung')}</NavLink>
                            <NavLink className={'to-register-page'} to={Routes.REGISTER}>{t('Registrieren')}</NavLink>
                        </div>
                    }
                </div>
                {appSize === Dimensions.DESKTOP && <StartPageFooter />}
            </div>
            {appSize !== Dimensions.DESKTOP && <StartPageFooter />}
        </div>
    );
}
