import React from 'react';
import './Settings.scss';
import {useTranslation} from "react-i18next";
import Container from "../general/Container";
import {Tabs} from "antd";
import {loggedMember} from "../../state/atoms";
import {useRecoilState} from "recoil";
import {useHistory} from "react-router";
import {getDefaultRoute} from "../../util/routes";
import UserGeneralSettings from "./UserGeneralSettings";
import UserPassword from "./UserPassword";
import UserMfa from "./UserMfa";
import {MemberDto} from "../../gen/client";
import {successMessage} from "../../util/utils";

const {TabPane} = Tabs;

export default function UserSettings() {
  const {t} = useTranslation();
  const history = useHistory();
  const [logged, setLogged] = useRecoilState(loggedMember);

  function toDefaultRoute() {
    history.push(getDefaultRoute(logged.activeRole));
  }

  function onSaveSuccess(member: MemberDto) {
    setLogged({
      ...logged,
      name: `${member.firstName} ${member.lastName}`,
      avatar: member.avatar,
      roles: member.roles
    });
    successMessage(t('Erfolgreich gespeichert!'));
  }

  return (
    <Container className={'settings full'}>
      <div className={'content'}>
        <header>
          <h2>{t('Benutzer-Einstellungen')}</h2>
        </header>
        <Tabs defaultActiveKey="1">
          <TabPane tab={t('Stammdaten')} key="1" className='tab-content'>
            <UserGeneralSettings memberId={logged.id} isAdmin={false} isSelf={true} onSaveSuccess={onSaveSuccess} onCancel={toDefaultRoute} onMemberStateChange={() => {}}/>
          </TabPane>
          <TabPane tab={t('Passwort')} key="2" className='tab-content'>
            <UserPassword memberId={logged.id} onCancel={toDefaultRoute}/>
          </TabPane>
          <TabPane tab={t('Zwei-Faktor-Authentifizierung')} key="3" className='tab-content'>
            <UserMfa memberId={logged.id} onCancel={toDefaultRoute}/>
          </TabPane>
        </Tabs>
      </div>
    </Container>
  );
}
