import * as React from 'react';
import './ContentView.scss';
import Container from '../general/Container';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {handleServerError} from '../../util/ErrorHandler';
import {ContentDtoTypeEnum, LightContentDto} from '../../gen/client';
import ContentApi from '../../api/ContentApi';
import { Button } from 'antd';
import {ReactComponent as backIcon} from '../../img/icons/back-icon.svg';
import Icon from '@ant-design/icons';
import { getInitialRoute, isHelpRoute } from '../../util/routes';
import { useRecoilState } from 'recoil';
import { loggedMember } from '../../state/atoms';

export default function ContentView() {
  const {t} = useTranslation();
  const history = useHistory();
  const {type} = useParams();
  const parse = require('html-react-parser');

  const [logged] = useRecoilState(loggedMember);
  const [content, setContent] = useState(null as LightContentDto);

  useEffect(() => {
    function getContentType() {
      switch (type) {
        case 'datenschutz':
          return ContentDtoTypeEnum.DataPrivacy;
        case 'impressum':
          return ContentDtoTypeEnum.Imprint;
        case 'faq':
          return ContentDtoTypeEnum.Faq;
        case 'nutzungsbedingungen':
          return ContentDtoTypeEnum.Terms;
        case 'kontakt':
          return ContentDtoTypeEnum.Contact;
        case 'hilfe':
          return ContentDtoTypeEnum.Help;
        case 'datenschutzLE':
          return ContentDtoTypeEnum.DataPrivacyProvider;
      }
    }

    ContentApi.getContentByType(getContentType()).then(resp => {
      setContent(resp.data);
    }).catch(handleServerError);
  }, [type]);

  function goBack() {
    history.push(getInitialRoute(false, logged.activeRole));
  }

  return (
    <Container className={'content-view full'}>
      <div className='hdr'>
        {isHelpRoute(history.location.pathname) && <Button type="link" className='back-btn' onClick={goBack}><Icon component={backIcon}/></Button>}
        <h2>{t(content?.name)}</h2>
      </div>
      <div className='content-wrapper'>
        <div className='content'>
        {content && parse(content.content)}
        </div>
      </div>
    </Container>
  )
}
