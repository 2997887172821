import React from "react";
import {useSetRecoilState} from "recoil";
import {applicationSize} from "../../state/atoms";
import {useCallback, useEffect} from "react";
import {getAppSize} from "../../util/utils";

export default function LayoutListener() {
  const setAppSize = useSetRecoilState(applicationSize);

  const resizeCallback = useCallback(() => {
    setAppSize(getAppSize());
  }, [setAppSize]);

  useEffect(() => {
    window.addEventListener('resize', resizeCallback);
    window.addEventListener('DOMContentLoaded', resizeCallback);

    return () => {
      window.removeEventListener('resize', resizeCallback);
      window.removeEventListener('DOMContentLoaded', resizeCallback);
    }
  }, [resizeCallback]);

  return <></>
}
