import * as React from 'react';
import {useEffect, useState} from 'react';
import './ContactInfo.scss';
import {Button, Divider} from 'antd';
import femaleIcon from '../../img/icons/gender-female-icon.svg';
import maleIcon from '../../img/icons/gender-male-icon.svg';
import diversIcon from '../../img/icons/gender-divers-icon.svg';
import {ReactComponent as backIcon} from '../../img/icons/back-icon.svg';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../util/routes';
import {AccountDto, AccountDtoGenderEnum,} from '../../gen/client';
import ContactEdit from './ContactEdit';
import Avatar from "../general/Avatar";
import Icon from '@ant-design/icons';

interface ContactInfoProps {
  contact: AccountDto;
  onContactSave: (contact: AccountDto) => void;
  onBackPush: () => void;
}

export default function ContactInfo({contact, onContactSave, onBackPush}: ContactInfoProps) {
  const {t} = useTranslation();
  const history = useHistory();

  const [editView, setEditView] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);

    setEditView(queryParams.has('edit'));
  }, [history.location.search]);

  function onCancel() {
    setEditView(false);

    const queryParams = new URLSearchParams(history.location.search)

    if (queryParams.has('edit')) {
      queryParams.delete('edit');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }

  function goToCreateAppointment(meetingId: string) {
    history.push(`${Routes.APPOINTMENTS_NEW_SINGLE}?publicId=${meetingId}`);
  }

  function saveContact(dto: AccountDto) {
    setEditView(false);
    onContactSave(dto);
  }

  return (
    <div>
      <div className='main'>
        <div className='hdr'>
          <Button type="link" className='back-btn' onClick={() => onBackPush()}><Icon component={backIcon}/></Button>
          <Avatar name={contact?.name}/>
          <div className='info'>
            <div className='row'>
              <h2>{contact?.name}&nbsp;
                <img
                  src={contact?.gender === AccountDtoGenderEnum.Female ? femaleIcon : contact?.gender === AccountDtoGenderEnum.Male ? maleIcon : diversIcon}
                  alt={''}/>
              </h2>
            </div>
            <div>{contact?.phone}</div>
            <div className='row'>
              <div><a href={`mailto:${contact?.email}`}>{contact?.email}</a></div>
            </div>
          </div>
        </div>
        <div className='hdr-btns'>
          <Button type={'primary'} size="large" className='termin-btn'
                  onClick={() => goToCreateAppointment(contact.publicId)}
                  disabled={editView}>{t('Termin vereinbaren')}</Button>
          <Button type={'primary'} size="large" className='edit-btn' onClick={() => setEditView(true)}
                  disabled={editView}>{t('Bearbeiten')}</Button>
        </div>
      </div>
      <div className='divider'><Divider/></div>

      {editView && contact &&
      <div key={contact.id}><ContactEdit contact={contact} onSave={saveContact} onCancel={onCancel}/></div>}
    </div>
  )
}
