import React, {ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import './DashboardAside.scss';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ContentRoutes, isContactOrGroupRoute, Routes} from '../../util/routes';
import {applicationSize, loggedMember} from "../../state/atoms";
import {useRecoilState, useRecoilValue} from "recoil";
import {MemberDtoRolesEnum} from "../../gen/client";
import {DashboardIcon} from "../general/icons/DashboardIcon";
import {AppointmentIcon} from "../general/icons/AppointmentIcon";
import ContactsIcon from "../general/icons/ContactsIcon";
import CmsIcon from "../general/icons/CmsIcon";
import HelpIcon from "../general/icons/HelpIcon";
import SettingsIcon from "../general/icons/SettingsIcon";
import PracticeIcon from "../general/icons/PracticeIcon";
import {StatsIcon} from "../general/icons/StatsIcon";
import UsersIcon from '../general/icons/UsersIcon';
import {Dimensions} from '../../util/enums';

interface MenuItem {
  key: string;
  label: string;
  mobileLabel?: string;
  path: string;
  icon: ReactElement;
}

interface DashboardAsideProps {
  onRouteChanged: (route: string) => void;
}

function getProviderMenu(): MenuItem[] {
  return [
    {key: 'p-apt', label: 'Termine', path: Routes.APPOINTMENTS, icon: <AppointmentIcon/>},
    {key: 'p-kon', label: 'Adressbuch', path: Routes.CONTACTS, icon: <ContactsIcon/>},
    {key: 'p-gro', label: 'Adressbuch', path: Routes.GROUPS, icon: <ContactsIcon/>},
    {key: 'p-stt', label: 'Statistik', path: Routes.STATISTICS, icon: <StatsIcon/>},
    {key: 'p-set', label: 'Einstellungen', path: Routes.SETTINGS, icon: <SettingsIcon/>}
  ]
}

function getPracticeManagerMenu(): MenuItem[] {
  return [
    {key: 'm-mem', label: 'Nutzer Einstellungen', path: Routes.MEMBERS, icon: <UsersIcon/>},
    {key: 'p-set', label: 'Praxis Einstellungen', path: Routes.PRACTICE, icon: <PracticeIcon/>},
    {key: 'p-cms', label: 'CMS', path: Routes.CMS, icon: <CmsIcon/>}
  ]
}

function getCustomerCareMenu(): MenuItem[] {
  return [
    {key: 'a-stt', label: 'Dashboard', path: Routes.STATISTICS, icon: <DashboardIcon/>},
    {key: 'c-mem', label: 'Nutzer Einstellungen', path: Routes.MEMBERS, icon: <UsersIcon/>},
    {key: 'p-set', label: 'Praxis Einstellungen', path: Routes.PRACTICES, icon: <PracticeIcon/>},
    {key: 'p-cms', label: 'CMS', path: Routes.CMS, icon: <CmsIcon/>}
  ]
}

function getAdminMenu(): MenuItem[] {
  return [
    {key: 'a-stt', label: 'Dashboard', path: Routes.STATISTICS, icon: <DashboardIcon/>},
    {key: 'p-mem', label: 'Nutzer Einstellungen', mobileLabel: 'Nutzer', path: Routes.MEMBERS, icon: <UsersIcon/>},
    {key: 'p-set', label: 'Praxis Einstellungen', mobileLabel: 'Praxis', path: Routes.PRACTICES, icon: <PracticeIcon/>},
    {key: 'g-set', label: 'Einstellungen', path: Routes.SETTINGS, icon: <SettingsIcon/>},
    {key: 'p-cms', label: 'CMS', path: Routes.CMS, icon: <CmsIcon/>}
  ]
}

export default function DashboardAside({onRouteChanged}: DashboardAsideProps) {
  const {t} = useTranslation();

  const [logged] = useRecoilState(loggedMember);
  const [buttons, setButtons] = useState([] as MenuItem[]);
  const appSize = useRecoilValue(applicationSize);

  const routeChanged = useCallback((route: string) => {
    onRouteChanged(route);
  }, [onRouteChanged]);

  useEffect(() => {
    if (!logged.logged) return;

    if (logged.activeRole === MemberDtoRolesEnum.Provider) {
      setButtons(getProviderMenu());
    } else if (logged.activeRole === MemberDtoRolesEnum.PracticeManager) {
      setButtons(getPracticeManagerMenu());
    } else if (logged.activeRole === MemberDtoRolesEnum.CustomerCare) {
      setButtons(getCustomerCareMenu());
    } else if (logged.activeRole === MemberDtoRolesEnum.Admin) {
      setButtons(getAdminMenu());
    }
  }, [logged]);

  function isRouteActive(match: any, location: any, entry: MenuItem): boolean {
    if (!match) {
      return isContactOrGroupRoute(entry.path) && isContactOrGroupRoute(location.pathname);
    }

    return location.pathname.indexOf(match.url) > -1;
  }

  const buttonsElement = useMemo(() => {
    return (
      buttons.map(it => <li key={it.key} className={it.key}>
        <NavLink to={it.path} onClick={() => routeChanged(it.key)} isActive={(m, a) => isRouteActive(m, a, it)}>
          {it.icon}
          {appSize === Dimensions.MOBILE && it.mobileLabel ? t(it.mobileLabel) : t(it.label)}
        </NavLink>
      </li>))
  }, [appSize, buttons, routeChanged, t]);

  return <aside>
    <ul>
      <li className={'logo'}>
        <div className={'l'}/>
      </li>

      {buttonsElement}

      <li className={'help-nav-link'}>
        <NavLink to={ContentRoutes.HELP}>
          <HelpIcon/>
          {t('Hilfe')}
        </NavLink>
      </li>
    </ul>
  </aside>;
}
