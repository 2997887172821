import * as React from 'react';

export function AppointmentIcon() {
  return (
    <svg viewBox="0 0 32 28">
      <path
        d="M5836.653,4316h-23.307a4.349,4.349,0,0,1-3.063-1.261,4.2,4.2,0,0,1-1.283-3.01V4294.77a4.2,4.2,0,0,1,1.283-3.01,4.349,4.349,0,0,1,3.063-1.261h3.111v-1.447a1.07,1.07,0,0,1,2.14,0v1.447h12.772v-1.447a1.07,1.07,0,0,1,2.14,0v1.447h3.109A4.349,4.349,0,0,1,5841,4294.8v16.925a4.2,4.2,0,0,1-1.284,3.01A4.35,4.35,0,0,1,5836.653,4316Zm-17.046-14.058a1.608,1.608,0,0,0-1.606,1.606v4.788a1.61,1.61,0,0,0,1.609,1.606h5.937a1.606,1.606,0,0,0,1.6-1.609v-4.785a1.608,1.608,0,0,0-1.605-1.606Zm11.474.8a.529.529,0,0,0-.29.085l-2.7,1.706a.268.268,0,0,0-.125.225v2.375a.268.268,0,0,0,.125.226l2.7,1.706a.565.565,0,0,0,.29.083h.651a.267.267,0,0,0,.269-.265v-5.875a.267.267,0,0,0-.269-.265Zm-17.767-10.109a2.191,2.191,0,0,0-2.208,2.169v2.694h27.72V4294.8a2.191,2.191,0,0,0-2.208-2.169Z"
        transform="translate(-5809 -4288)"/>
    </svg>
  )
}
