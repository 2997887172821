import {MemberDtoRolesEnum} from "../gen/client";

export enum Routes {
  REGISTER = '/register',
  LOGIN = '/login',
  LOGIN_CONFIRM = '/login/confirm',
	ACTIVATE_MFA = '/login/mfaconfig',
  PASSWORD_FORGOT = '/passwordforgot',
  RESET_PASSWORD = '/resetpassword',
  RESET_MFA = '/resetmfa',
  ACTIVATE = '/activate',
  MEETING = '/meeting',
  SETUP = '/setup',
  CONNECTION_TEST = '/connection/test',
  APP = '/app',

  APPOINTMENTS = '/app/termine',
  APPOINTMENTS_NEW_SINGLE = '/app/termine/new/single',
  APPOINTMENTS_NEW_GROUP = '/app/termine/new/group',
  APPOINTMENTS_EDIT_SINGLE = '/app/termine/edit/single',
  APPOINTMENTS_EDIT_GROUP = '/app/termine/edit/group',
  USER_SETTINGS = '/app/user-settings',
  SETTINGS = '/app/settings',
  MEMBERS = '/app/nutzer',
  PRACTICE = '/app/praxis',
  PRACTICES = '/app/practices',
  MEMBERS_NEW = '/app/nutzer/new',
  CONTACTS = '/app/contacts/single',
  GROUPS = '/app/contacts/groups',
  CONTACTS_NEW = '/app/contacts/single/new',
  GROUPS_NEW = '/app/contacts/groups/new',
  STATISTICS = '/app/statistics',
  STATISTICS_FEEDBACK = '/app/statistics/feedback',
  CMS = '/app/cms',
  NOTIFICATION = '/app/notification',
  JOIN = '/join',

  CONTENT = '/content'
}

export function isContactRoute(pathname: string): boolean {
  return pathname.indexOf(Routes.CONTACTS) > -1;
}

export function isMemberRoute(pathname: string): boolean {
  return pathname.indexOf(Routes.MEMBERS) > -1;
}

export function isNewContactRoute(pathname: string): boolean {
  return pathname.indexOf(Routes.CONTACTS_NEW) > -1;
}

export function isNewGroupRoute(pathname: string): boolean {
  return pathname.indexOf(Routes.GROUPS_NEW) > -1;
}

export function isNewMemberRoute(pathname: string): boolean {
  return pathname.indexOf(Routes.MEMBERS_NEW) > -1;
}

export function isStatisticRoute(pathname: string): boolean {
  return pathname.startsWith(Routes.STATISTICS);
}

export function isActiveMfaRoute(pathname: string): boolean {
  return pathname.indexOf(Routes.ACTIVATE_MFA) > -1;
}

export function isCMSRoute(pathname: string): boolean {
  return pathname.startsWith(Routes.CMS);
}

export function isNewAppointmentRoute(pathname: string): boolean {
  return pathname.indexOf(Routes.APPOINTMENTS_NEW_SINGLE) > -1;
}

export function isPreviewRoute(pathname: string): boolean {
  return pathname.indexOf('preview') > -1;
}

export function isConnectionRoute(pathname: string): boolean {
  return pathname.indexOf('connection') > -1;
}

export function isMeetingRoute(pathname: string): boolean {
  return pathname.indexOf(Routes.MEETING) > -1;
}

export function isAppointmentsRoute(pathname: string): boolean {
  return pathname.indexOf(Routes.APPOINTMENTS) > -1;
}

export function isSearchRoute(pathname: string): boolean {
  return pathname.indexOf('search') > -1;
}

export function isSingleAppointmentRoute(pathname: string): boolean {
  return pathname.indexOf('single') > -1;
}

export function isHelpRoute(pathname: string): boolean {
  return pathname.indexOf('hilfe') > -1;
}

export function isPracticesRoute(pathname: string): boolean {
  return pathname.indexOf('practices') > -1;
}

export function isGroupRoute(pathname: string): boolean {
  return pathname.indexOf(Routes.GROUPS) > -1;
}

export function isContactOrGroupRoute(pathname: string): boolean {
  return isContactRoute(pathname) || isGroupRoute(pathname);
}

export function isJoinRoute(pathname: string): boolean {
  return pathname.indexOf(Routes.JOIN) > -1;
}

export function isRegisterRoute(pathname: string): boolean {
  return pathname.indexOf(Routes.REGISTER) > -1;
}

export function isLoginRoute(pathname: string): boolean {
  return pathname.indexOf(Routes.LOGIN) > -1;
}

export function isAppRoute(pathname: string): boolean {
  return pathname.indexOf(Routes.APP) > -1;
}

export function getDefaultRoute(role: MemberDtoRolesEnum): string {
  if (role === MemberDtoRolesEnum.Admin) return Routes.STATISTICS;
  if (role === MemberDtoRolesEnum.CustomerCare) return Routes.MEMBERS;
  if (role === MemberDtoRolesEnum.PracticeManager) return Routes.MEMBERS;
  return Routes.APPOINTMENTS;
}

export function getInitialRoute(needsSetup: boolean, role: MemberDtoRolesEnum, isMfaSet: boolean = true) {
	if (role === MemberDtoRolesEnum.Admin) return Routes.STATISTICS;
	if (role === MemberDtoRolesEnum.CustomerCare) return Routes.STATISTICS;

	if (!isMfaSet) return Routes.ACTIVATE_MFA;
	if (needsSetup) return Routes.SETUP;

	if (role === MemberDtoRolesEnum.Provider) return Routes.APPOINTMENTS;

	else return Routes.MEMBERS;
}

export enum ContentRoutes {
  PRIVACY = '/content/datenschutz',
  COPY = '/content/impressum',
  FAQ = '/content/faq',
  TERMS = '/content/nutzungsbedingungen',
  CONTACT = '/content/kontakt',
  HELP = '/content/hilfe',
  DATA_PRIVACY_PROVIDER = '/content/datenschutzLE'
}
