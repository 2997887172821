import * as React from "react";

export default function CmsIcon() {
  return <svg viewBox="0 0 35.839 28" className="cms-svg">
    <g transform="translate(-22 -107)">
      <g transform="translate(22 91)">
        <path
          d="m 2.24,39.519 h 19.039 v -3.36 H 5.023 A 0.56,0.56 0 0 1 4.48,35.616 V 17.663 A 0.56,0.56 0 0 1 5.023,17.12 h 25.79 a 0.56,0.56 0 0 1 0.546,0.543 V 29.44 h 1.12 V 17.663 A 1.68,1.68 0 0 0 30.816,16 H 5.023 A 1.68,1.68 0 0 0 3.36,17.663 v 17.953 a 1.68,1.68 0 0 0 1.663,1.663 H 0 a 2.409,2.409 0 0 0 2.24,2.24 z m 32.509,-7.24 -1.814,0.4 a 5.6,5.6 0 0 0 -2.167,-1.254 l -0.56,-1.77 a 7.28,7.28 0 0 0 -2.178,0 l -0.56,1.77 a 5.6,5.6 0 0 0 -2.171,1.254 l -1.814,-0.4 a 7.2,7.2 0 0 0 -1.086,1.884 l 1.254,1.369 a 5.6,5.6 0 0 0 0,2.5 l -1.254,1.375 a 7.2,7.2 0 0 0 1.089,1.889 l 1.814,-0.4 a 5.6,5.6 0 0 0 2.167,1.254 l 0.56,1.77 a 7.28,7.28 0 0 0 2.178,0 l 0.56,-1.77 a 5.6,5.6 0 0 0 2.168,-1.254 l 1.814,0.4 a 7.2,7.2 0 0 0 1.089,-1.887 L 34.584,38.04 a 5.6,5.6 0 0 0 0,-2.5 l 1.254,-1.372 a 7.2,7.2 0 0 0 -1.089,-1.889 z m -5.63,6.96 a 2.464,2.464 0 1 1 2.464,-2.464 2.464,2.464 0 0 1 -2.464,2.464 z M 20.159,22.72 h 8.96 v 1.12 h -8.96 z M 6.72,32.8 h 11.2 v 1.12 H 6.72 Z m 0,-3.36 h 19.319 v 1.12 H 6.72 Z m 0,-10.08 v 7.84 h 11.2 V 19.36 Z M 15.509,20.48 12.32,22.608 9.131,20.48 Z M 7.84,20.967 11.309,23.28 7.84,25.593 Z m 1.291,5.113 3.189,-2.128 3.189,2.128 z M 16.8,25.593 13.331,23.28 16.8,20.967 Z m 3.359,-6.233 h 8.96 v 1.12 h -8.96 z m 0,6.72 h 8.96 v 1.12 h -8.96 z"/>
      </g>
    </g>
  </svg>
}
