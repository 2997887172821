import * as React from 'react';
import './CMSLine.scss';
import { Button } from 'antd';
import {ReactComponent as editIcon} from '../../img/icons/edit-icon.svg';
import { CmsDtoAccessEnum, ContentDto, ContentDtoTypeEnum } from '../../gen/client';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface ContentLineProps {
  content: ContentDto;
  onEdit: (type: ContentDtoTypeEnum) => void;
}

export default function ContentLine({ content, onEdit }: ContentLineProps) {
	const { t } = useTranslation();

  return (
    <div className='vd-line'>
      <div className='col-1'>
        {t(content.name)}
      </div>
      <div className='col-3'>
        <div className='label private'>
          {t(CmsDtoAccessEnum.Private)}
        </div>
      </div>
      <div className={'actions'}>
        <Button className='icon-only' type={'link'} onClick={() => onEdit(content.type)}><Icon component={editIcon} alt={'edit'} /></Button>
      </div>
    </div>
  )
}
