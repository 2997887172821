import * as React from 'react';
import './Contacts.scss';
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {handleServerError} from "../../util/ErrorHandler";
import {AccountDto} from "../../gen/client";
import PracticeApi from '../../api/PracticeApi';
import { activePractice } from '../../state/atoms';
import { useRecoilState } from 'recoil';
import Storage from "../../util/Storage";
import { useHistory } from 'react-router-dom';
import { Routes } from '../../util/routes';
import {successMessage} from "../../util/utils";
import SelectionBar from '../general/SelectionBar';

interface ContactSelectionBarProps {
  contacts: AccountDto[],
  selected: string[],
  onSelectedChange: (selected: string[]) => void;
  onDeleteContacts: (meetingIds: string[], document: Blob) => void;
}

export default function ContactSelectionBar({contacts, selected, onSelectedChange, onDeleteContacts}: ContactSelectionBarProps) {
  const {t} = useTranslation();
  const history = useHistory();
  const {confirm} = Modal;

  const [practice] = useRecoilState(activePractice);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  function showDeleteConfirm() {
    confirm({
      content: t('Wollen Sie wirklich alle ausgewählten Kontakte löschen?'),
      okText: t('Ja'),
      okType: 'danger',
      cancelText: t('Nein'),
      onOk() { handleMultipleDelete() }
    });
  }

  function handleMultipleDelete() {
    setDeleteInProgress(true);
    const contactsToDeleteIds = contacts.filter(it => selected.indexOf(it.id) > -1).map(it => it.id)
    PracticeApi.deleteContacts(practice.id, contactsToDeleteIds)
      .then((response) => {
        onSelectedChange([]);
        successMessage(t('Succesfully deleted'));
        onDeleteContacts(contacts.filter(it => selected.indexOf(it.id) > -1).map(it => it.publicId),  response.request.response);
      })
      .catch(err => {
        setDeleteInProgress(false);
        handleServerError(err);
      });
  }

  function createNewAppointment() {
    Storage.setParticipantsIds(contacts.filter(it => selected.indexOf(it.id) > -1).map(it => it.publicId));
    history.push(Routes.APPOINTMENTS_NEW_GROUP);
  }

  return (
    <>
     <SelectionBar selected={selected} groups={false} deleteInProgress={deleteInProgress} 
        onDelete={showDeleteConfirm} onCreateNewAppointment={createNewAppointment}/>
    </>
  )
}
