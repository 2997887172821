import * as React from 'react';
import './QuestionFields.scss';
import { CmsQuestionDto } from '../../gen/client';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { activePractice, loggedMember } from '../../state/atoms';
import { handleServerError } from '../../util/ErrorHandler';
import PracticeApi from '../../api/PracticeApi';
import QuestionFields from './QuestionFields';
import { AxiosPromise } from 'axios';
import TenantApi from '../../api/TenantApi';
import { TENANT_ID } from '../../util/constants';
import { Button } from 'antd';
import Icon from '@ant-design/icons/';
import {ReactComponent as backIcon} from '../../img/icons/back-icon.svg';
import { isAdminOrCustomerCare } from '../../util/utils';

interface EditQuestionProps {
  question: CmsQuestionDto;
  onEdit: () => void;
  onCancel: () => void;
}

export default function EditQuestion({question, onEdit, onCancel}: EditQuestionProps) {
  const {id} = useParams();
  const [practice] = useRecoilState(activePractice);
  const [logged] = useRecoilState(loggedMember);

  function edit(dto: CmsQuestionDto){
    const action: AxiosPromise = isAdminOrCustomerCare(logged) ? TenantApi.updateQuestion(id, dto.id, TENANT_ID, dto) :  PracticeApi.updateQuestion(id, practice.id, dto.id, dto);
    action
    .then(() => onEdit())
    .catch(handleServerError);
  }

  return (
    <div className='question'>
      <div className={'info-header'}>
        <Button type="link" className='back-btn' onClick={onCancel}><Icon component={backIcon}/></Button>
        <h3>{question?.label}</h3>
      </div>
      <QuestionFields it={question} onSave={edit} onCancel={onCancel}/>
    </div>
  )
}
