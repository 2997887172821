import * as React from 'react';
import './InfoReview.scss';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import moment from "moment";

interface InfoReviewProps {
  date: string;
  startTime: string;
  duration: number;
  isInThePast: boolean;
  isFromOra: boolean;
}

export default function InfoReview({date, startTime, duration, isInThePast, isFromOra}: InfoReviewProps) {
  const {t} = useTranslation();

  const [valid, setValid] = useState(false);
  const [endTime, setEndTime] = useState(startTime);

  useEffect(() => {
    setValid(!!date && !!startTime);
		setEndTime(
			moment(startTime, "HH:mm")
				.add(duration, 'minutes')
				.format('HH:mm')
		);
  }, [date, startTime, duration]);

  return (
    <div className={`apt-info-rev ${valid && !isInThePast ? '' : 'er'}`}>
      {isFromOra ? 
        <p>{t('Dieser Termin wurde in Noventi ORA erstellt und kann nur dort geändert werden.')}</p> 
        :
        <>
          <svg viewBox="0 0 24 24">
            {valid && !isInThePast ? (
              <path d="M12,22C6.5,22,2,17.5,2,12C2,6.5,6.5,2,12,2c5.5,0,10,4.5,10,10C22,17.5,17.5,22,12,22z M12,3.1c-4.9,0-8.9,4-8.9,8.9 s4,8.9,8.9,8.9s8.9-4,8.9-8.9S16.9,3.1,12,3.1z M16,9.4l-0.6-0.6c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-4.3,4.4l-1.6-1.6 c-0.1-0.1-0.1-0.1-0.2,0c0,0,0,0,0,0l-0.6,0.6c-0.1,0.1-0.1,0.1,0,0.2c0,0,0,0,0,0l2,2c0.1,0.1,0.3,0.2,0.4,0.2 c0.2,0,0.3-0.1,0.4-0.2h0L16,9.6C16.1,9.5,16.1,9.4,16,9.4z"/>
            ) : (
              <path d="M12,22C6.5,22,2,17.5,2,12C2,6.5,6.5,2,12,2c5.5,0,10,4.5,10,10C22,17.5,17.5,22,12,22z M12,3.1c-4.9,0-8.9,4-8.9,8.9 s4,8.9,8.9,8.9s8.9-4,8.9-8.9S16.9,3.1,12,3.1z M12.8,11.7L15.5,9c0.2-0.2,0.2-0.6,0-0.8c-0.2-0.2-0.6-0.2-0.8,0l-2.6,2.6L9.4,8.3 c-0.2-0.2-0.6-0.2-0.8,0C8.5,8.5,8.5,8.8,8.7,9l2.6,2.6l-2.6,2.6c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2 l2.6-2.6l2.6,2.6c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.6,0-0.8L12.8,11.7z"/>
            )}
          </svg>
          { valid ? (
            isInThePast ? 
              <p>{t('Das Termindatum darf nicht in der Vergangenheit liegen')}</p>
              : 
              <p>{t('Dieser Termin findet am')}&nbsp;<strong>{date}</strong>&nbsp;{t('von')}&nbsp;<strong>{startTime}</strong>&nbsp;{t('bis')}&nbsp;<strong>{endTime}</strong>&nbsp;{t('Uhr statt')}!</p>
            ) : (
            <p>{t('Bitte wählen Sie Datum und Uhrzeit des Termins aus')}</p>
          )}
        </>
      }
    </div>
  )
}
