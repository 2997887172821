import {LightParticipantDto, MeetingDto, MeetingLogDto, MeetingLogDtoTypeEnum, SearchOptionsSortEnum} from "../gen/client";
import {fromTimestamp, getAppSize, getHeightFromAspectRatio, getWidthFromAspectRatio, isDesktop, timestamp} from "../util/utils";
import MemberApi from "../api/MemberApi";
import {handleServerError} from "../util/ErrorHandler";
import ParticipantApi from "../api/ParticipantApi";
import MeetingApi from "../api/MeetingApi";
import moment, {Moment} from "moment";
import {Dimensions} from "../util/enums";
import {env} from "../env/env";
import {AspectRatio, CalcLayoutParams, CalcLayoutProps, CameraPosition} from "../util/types";
import {MAX_LAYOUT_RECALCULATION_ITERATIONS} from "../util/constants";
import _ from "lodash";

export function splitPastFuture(list: MeetingDto[], sort: SearchOptionsSortEnum = SearchOptionsSortEnum.Default) {
  const past: MeetingDto[] = [];
  const future: MeetingDto[] = [];
  const now = moment();

  list.forEach(it => {
    const start = fromTimestamp(it.startDate).add(it.duration, "minutes");
    if (start >= now) {
      future.push(it);
    } else {
      past.push(it);
    }
  });

  return {
    past: sortList(past, sort),
    future: sortList(future, sort)
  };
}

function sortList(list: MeetingDto[], sort: SearchOptionsSortEnum): MeetingDto[] {
  return [...list].sort((a, b) => {
    if (sort === SearchOptionsSortEnum.Default || sort === SearchOptionsSortEnum.Desc) return fromTimestamp(b.startDate).add(b.duration, "minutes") < fromTimestamp(a.startDate).add(a.duration, "minutes") ? -1 : 1;
    return fromTimestamp(b.startDate).add(b.duration, "minutes") >=  fromTimestamp(a.startDate).add(a.duration, "minutes") ? -1 : 1;
  });
}

export function getMeetingTime(meetingLogs: MeetingLogDto[], type: MeetingLogDtoTypeEnum): Moment {
  return fromTimestamp(meetingLogs?.find(log => log.type === type)?.createdDate);
}

export function getMeetingLogContent(log: MeetingLogDto): string {
  switch (log.type) {
    case MeetingLogDtoTypeEnum.MeetingStarted:
      return 'Meeting started';
    case MeetingLogDtoTypeEnum.MeetingEnded:
      return 'Meeting ended';
    case MeetingLogDtoTypeEnum.MemberJoined:
      return `<span class='medium'>${log.member?.firstName} ${log.member?.lastName}</span> ist beigetreten`;
    case MeetingLogDtoTypeEnum.ParticipantJoined:
      return `<span class='medium'>${log.participant?.name}</span> ist beigetreten`;
    case MeetingLogDtoTypeEnum.FileShared:
      return `<span class='medium'>${log.member?.firstName} ${log.member?.lastName}</span> shared ${log.details}`;
  }
}

export function createMemberJoinedLog(memberId: string, meetingId: string) {
  MemberApi.getMember(memberId)
    .then(resp => {
      MeetingApi.createMeetingLog({createdDate: timestamp(moment()), type: MeetingLogDtoTypeEnum.MemberJoined, member: resp.data}, meetingId)
        .then(() => {
        })
        .catch(err => handleServerError(err));
    })
    .catch(err => {
      handleServerError(err);
    });
}


export function createParticipantJoinedLog(participantId: string, meetingId: string) {
  ParticipantApi.getByPublicId(participantId)
    .then((resp) => {
      MeetingApi.createMeetingLog({createdDate: timestamp(moment()), type: MeetingLogDtoTypeEnum.ParticipantJoined, participant: resp.data}, meetingId)
        .then(() => {
        })
        .catch(err => handleServerError(err));
    })
    .catch(err => {
      handleServerError(err);
    });
}

export function getDuration(appointment: MeetingDto): number {
  let duration = appointment.duration;

  try {
    const startLog = appointment.logs.find(l => l.type === MeetingLogDtoTypeEnum.MeetingStarted);
    const endLog = appointment.logs.find(l => l.type === MeetingLogDtoTypeEnum.MeetingEnded);

    if (startLog && endLog) {
      duration = (endLog.createdDate - startLog.createdDate) / 1000 / 60;
    }
  } catch (e) {
    console.error(e);
  }

  return duration > 0 ? duration : appointment.duration;
}

let lastCalcLayoutParams = null as CalcLayoutParams;

export function fitVideosToFullScreen(appSize: Dimensions = null, ops: CalcLayoutProps = null, force: boolean = false) {
  if (document.visibilityState === 'hidden') return;

  appSize = appSize || getAppSize();
  const gallery = document.getElementById("videos");
  const wrapper = document.getElementById("video-wrapper");

  if (!wrapper) return;
  if ((appSize === Dimensions.MOBILE || appSize === Dimensions.TABLET) && ops) return;

  const videos = gallery.getElementsByTagName("video");
  const videoCount = videos.length;
  const localWrapper = document.getElementById('localVideo-wrapper');

  if (localWrapper) {
    const localAspectRatioString = '3/4';
    localWrapper.style.setProperty('--aspect-ratio', '' + localAspectRatioString);
  }

  let isPresentation = false;
  try {
    isPresentation = document.getElementsByClassName('video-wrapper')[0].classList.contains("presentation");
  } catch (e) {}

  for (let col = 1; col <= videoCount; col++) {
    const currentVideo = videos.item(col - 1);

    if (isPresentation) {
      currentVideo.parentElement.style.width = '100%';
      currentVideo.parentElement.style.height = '100%';
      if (col === 1) {
        const isShareScreening = currentVideo.classList.contains('screen-sharing');
        const ratio = isShareScreening ? 16/9 : 4/3;
        if (currentVideo.parentElement.clientWidth / currentVideo.parentElement.clientHeight < ratio) {
          currentVideo.parentElement.style.width = '100%';
          currentVideo.parentElement.style.height = (currentVideo.parentElement.clientWidth / (ratio)) + 'px';
        } else {
          currentVideo.parentElement.style.height = '100%';
          currentVideo.parentElement.style.width = (currentVideo.parentElement.clientHeight * (ratio)) + 'px';
        }
      }
    } else {
      currentVideo.parentElement.style.width = '';
      currentVideo.parentElement.style.height = '';
    }
  }
  
  if (isPresentation) {
    return;
  }

  let screenWidth = wrapper.getBoundingClientRect().width;
  const screenHeight = wrapper.getBoundingClientRect().height;

  if (ops && appSize === Dimensions.DESKTOP) {
    const drawer = document.querySelector(".meeting-info-drawer").getBoundingClientRect();
    const drawerWidth = drawer.width;

    if (ops.drawerVisible) {
      screenWidth -= drawerWidth;
    } else {
      screenWidth += drawerWidth;
    }
  }

  function calculateLayout(
    containerWidth: number,
    containerHeight: number,
    currentVideoCount: number
  ): {width: number; height: number; cols: number; rows: number; customAspectRatio: boolean} {
    let bestLayout = {
      area: 0,
      cols: 0,
      rows: 0,
      width: 0,
      height: 0,
      customAspectRatio: false
    };

    // brute-force search layout where video occupy the largest area of the container
    for (let col = 1; col <= currentVideoCount; col++) {
      const currentVideo = videos.item(col - 1);
      const currentWidth = currentVideo.videoWidth || 640;
      const currentHeight = currentVideo.videoHeight || 480;

      const rows = Math.ceil(currentVideoCount / col);
      const spacing = 1;

      const packedWidth = containerWidth - (spacing * (col - 1));
      const packedHeight = containerHeight - (spacing * (rows - 1));

      if (currentWidth === 0 || currentHeight === 0) continue;

      const aspectRatioString = AspectRatio.RATIO_4_3;//getAspectRatio(currentWidth, currentHeight);

      // const aspectRatioString = Object.values(AspectRatio)[parseInt(currentVideo.id.replace('remoteVideo-', '')) || 2];
      const widthNr = parseInt(getWidthFromAspectRatio(aspectRatioString));
      const heightNr = parseInt(getHeightFromAspectRatio(aspectRatioString));

      let overwriteAspectRatio = null;
      if (!isDesktop() && videoCount === 1 && currentVideo.classList.contains('screen-sharing')) {
        overwriteAspectRatio = (currentWidth / currentHeight) + '';
      }

      const aspectRatio = overwriteAspectRatio ? parseFloat(overwriteAspectRatio) : widthNr / heightNr;

      const el = document.getElementById(currentVideo.id + '-wrapper');
      if (el) {
        el.style.setProperty('--aspect-ratio', overwriteAspectRatio || aspectRatioString);
      }

      const hScale = packedWidth / (col * aspectRatio);
      const vScale = packedHeight / rows;

      let bestWidth;
      let bestHeight;
      if (hScale <= vScale) {
        bestWidth = Math.floor(packedWidth / col / widthNr) * widthNr;
        bestHeight = Math.floor(bestWidth / aspectRatio / heightNr) * heightNr;
      } else {
        bestHeight = Math.floor(packedHeight / rows / heightNr) * heightNr;
        bestWidth = Math.floor(bestHeight * aspectRatio / widthNr) * widthNr;
      }

      const area = bestWidth * bestHeight;

      if (area > bestLayout.area) {
        bestLayout = {
          area,
          width: bestWidth,
          height: bestHeight,
          rows,
          cols: col,
          customAspectRatio: !!overwriteAspectRatio
        };
      }
    }

    return bestLayout;
  }

  let {width, height, cols, customAspectRatio} = calculateLayout(screenWidth, screenHeight, videoCount);

  if (height === 0) return;

  gallery.style.setProperty("--width", width + "px");
  gallery.style.setProperty("--height", height + "px");
  gallery.style.setProperty("--cols", cols + "");

  if (!force && lastCalcLayoutParams && lastCalcLayoutParams.videoCount === videoCount && lastCalcLayoutParams.screenHeight === screenHeight && lastCalcLayoutParams.screenWidth === screenWidth) {
    return;
  } else {
    lastCalcLayoutParams = {
      screenWidth,
      screenHeight,
      videoCount
    }
  }

  if (!customAspectRatio) {
    setTimeout(() => {
      height = expandIfRequired(gallery, wrapper, videos, videoCount, screenWidth, screenHeight, height);
      height = shrinkIfRequired(gallery, wrapper, videos, videoCount, screenWidth, screenHeight, height);
    }, force ? 0 : 250);
  }
}

function expandIfRequired(gallery: HTMLElement, wrapper: HTMLElement, videos: HTMLCollection, videoCount: number, screenWidth: number, screenHeight: number, height: number): number {
  if (gallery.getBoundingClientRect().height === 0) return;

  let saneCheckCounter = 0;
  while (gallery.getBoundingClientRect().height < wrapper.getBoundingClientRect().height) {
    if (saneCheckCounter++ >= MAX_LAYOUT_RECALCULATION_ITERATIONS) {
      break;
    }
    let leftX = screenWidth;
    let rightX = 0;
    let topY = screenHeight;
    let bottomY = 0;

    for (let cols = 1; cols <= videoCount; cols++) {
      const currentVideo = videos.item(cols - 1);

      if (currentVideo) {
        const dim = currentVideo.getBoundingClientRect();

        if (dim.left < leftX) {
          leftX = dim.left;
        }

        if (dim.right > rightX) {
          rightX = dim.right;
        }

        if (dim.top < topY) {
          topY = dim.top;
        }

        if (dim.bottom > bottomY) {
          bottomY = dim.bottom;
        }
      }
    }

    const rect = wrapper.getBoundingClientRect();
    const lowestX = rect.left;
    const rightestX = rect.right;
    const lowestY = rect.top;
    const highestY = rect.bottom;

    const leftDiff = leftX - lowestX;
    const rightDiff = rightestX - rightX;
    const topDiff = topY - lowestY;
    const bottomDiff = highestY - bottomY;

    if (leftDiff > 0 && rightDiff > 0 && topDiff > 0 && bottomDiff > 0) {
      let heightToAdd = Math.floor(topY - lowestY);
      let widthToAdd = Math.floor(leftX - lowestX);

      heightToAdd = Math.floor(heightToAdd / 4);

      if (heightToAdd === 0) break;

      if (heightToAdd < widthToAdd) {
        height += heightToAdd + 1;
      } else {
        height += Math.floor((widthToAdd / 4)) + 1;
      }

      gallery.style.setProperty("--height", height + "px");
    } else {
      break;
    }
  }

  gallery.style.setProperty("--height", (height - 1) + "px");

  return height;
}

function shrinkIfRequired(gallery: HTMLElement, wrapper: HTMLElement, videos: HTMLCollection, videoCount: number, screenWidth: number, screenHeight: number, height: number): number {
  if (gallery.getBoundingClientRect().height > wrapper.getBoundingClientRect().height) {
    height -= 3;
    let saneCheckCounter = 0;
    while (gallery.getBoundingClientRect().height > wrapper.getBoundingClientRect().height) {
      if (saneCheckCounter++ >= MAX_LAYOUT_RECALCULATION_ITERATIONS) {
        break;
      }
      height -= 3;
      gallery.style.setProperty("--height", height + "px");
    }

    height -= 3;
    gallery.style.setProperty("--height", height + "px");
  }

  return height;
}

export const calcLayout = _.debounce(() => fitVideosToFullScreen(), 50);

export function getJoinLink(meetingId: string, participantId: string): string {
  return `${env.basePath}${getJoinLinkPath(meetingId, participantId)}`;
}

export function getJoinLinkPath(meetingId: string, participantId: string): string {
  return `/join/${meetingId}?publicId=${participantId}`;
}

export function getCameraPosition(track: MediaStreamTrack) {
  return track.getSettings() && track.getSettings().facingMode !== 'user' ? CameraPosition.ENVIRONMENT : CameraPosition.USER;
}

export function getParticipantsWhoJoined(logs: MeetingLogDto[]): LightParticipantDto[] {
  const participants = logs.filter(log => log.type === MeetingLogDtoTypeEnum.ParticipantJoined && log.participant).map(it =>it.participant);
  const ids = participants.map(it => it.publicId);
  return participants.filter((participant, index) => ids.indexOf(participant.publicId) === index);
}

 export function getMeetingInfoFileName(participants: LightParticipantDto[]): string {
  if (participants.length === 0 ) {
    return `Leistungsnachweis_${moment().format("DDMMYY")}.pdf`;
  } 
  if (participants.length === 1 ) {
    const name = `Leistungsnachweis_${moment().format("DDMMYY")}_${participants[0].name}`;
    return `${name}.pdf`;
  }
  return `Leistungsnachweis_${moment().format("DDMMYY")}.zip`;
}
