import {
  AppSettingsDto,
  MemberDto,
  CmsQuestionDto,
  CmsAnswerDto,
  MemberCountDto,
  NotificationDtoTypeEnum,
  NotificationDto,
  ParticipantDeletionStatusDto,
  SetupDto,
  MeetingDto,
  MemberAnswerGroupDto,
  ParticipantAnswerGroupDto,
  LightCmsAnswerDto, AccountDto, AccountGroupDto
} from '../gen/client';
import {AxiosPromise} from "axios";
import {apiConfig, ops} from "./ApiConfig";
import {PracticeResourceApi, PracticeDto} from "../gen/client";

const api = new PracticeResourceApi(apiConfig);

const PracticeApi = {
  getPractice(practiceId: string): AxiosPromise<PracticeDto> {
    return api.getPractice(practiceId, ops());
  },

  resetNotification(practiceId: string, notificationId: string): AxiosPromise<NotificationDto> {
    return api.resetNotification(practiceId, notificationId, ops());
  },

  deleteProvider(practiceId: string, memberId: string): AxiosPromise<void> {
    return api.deleteProvider(practiceId, memberId, ops());
  },

  getPracticeSettings(practiceId: string): AxiosPromise<AppSettingsDto> {
    return api.getSettings(practiceId, ops());
  },

  getAccounts(practiceId: string, memberId: string): AxiosPromise<AccountDto[]> {
    return api.getAccounts(practiceId, memberId, ops());
  },

  getAccount(practiceId: string, publicAccountId: string): AxiosPromise<AccountDto> {
    return api.getAccount(practiceId, publicAccountId, ops());
  },

  getMeetingsForProvider(practiceId: string, memberId: string, from: number, thru: number, onlyFinished: boolean): AxiosPromise<MeetingDto[]> {
    return api.getMeetingsForProviderBetween(memberId, practiceId, from, thru, onlyFinished, ops())
  },

  createProvider(practiceId: string, dto: MemberDto): AxiosPromise<MemberDto> {
    return api.createProvider(practiceId, dto, ops())
  },

  updateMember(practiceId: string, memberId: string, dto: MemberDto): AxiosPromise<void> {
    return api.updateMember(memberId, practiceId, dto, ops())
  },

  setParticipantDeletionPeriod(practiceId: string, years: number): AxiosPromise<void> {
    return api.setParticipantDeletionPeriod(practiceId, years, ops());
  },

  sendParticipantDeletionStatusEmail(practiceId: string): AxiosPromise<void> {
    return api.sendParticipantDeletionStatusEmail(practiceId, ops());
  },

  setup(practiceId: string, dto: SetupDto) {
    return api.setupPractice(practiceId, dto, ops())
  },

  update(practiceId: string, dto: PracticeDto) {
    return api.updatePractice(practiceId, dto, ops())
  },

  getMembers(practiceId: string): AxiosPromise<MemberDto[]> {
    return api.getMembers(practiceId, ops());
  },

  createAccount(practiceId: string, dto: AccountDto): AxiosPromise<boolean> {
    return api.createAccount(practiceId, dto, ops())
  },

  saveSettings(practiceId: string, dto: AppSettingsDto): AxiosPromise<PracticeDto> {
    return api.saveSettings(practiceId, dto, ops());
  },

  getSettings(practiceId: string): AxiosPromise<AppSettingsDto> {
    return api.getSettings(practiceId, ops());
  },

  getQuestions(cmsId: number, practiceId: string): AxiosPromise<CmsQuestionDto[]> {
    return api.getPracticeQuestions(practiceId, cmsId, ops())
  },

  getAnswers(cmsId: number, practiceId: string, questionId?: string): AxiosPromise<{ [key: string]: Array<MemberAnswerGroupDto>; }> {
    return api.getPracticeAnswers(practiceId, cmsId, questionId, ops())
  },

  createProviderQuestion(cmsId: number, practiceId: string, dto: CmsQuestionDto) {
    return api.createProviderQuestion(practiceId, cmsId, dto, ops());
  },

  updateQuestion(cmsId: number, practiceId: string, questionId: string, dto: CmsQuestionDto) {
    return api.updateQuestion1(practiceId, cmsId, questionId, dto, ops());
  },

  deleteQuestion(cmsId: number, practiceId: string, questionId: string) {
    return api.deleteQuestion1(practiceId, cmsId, questionId, ops());
  },

  addParticipantAnswer(practiceId: string, questionId: string, cmsAnswer: CmsAnswerDto): AxiosPromise<CmsAnswerDto> {
    return api.addParticipantAnswer(practiceId, questionId, cmsAnswer, ops());
  },

  getQuestionsByType(practiceId: string, type: 'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER'): AxiosPromise<CmsAnswerDto[]> {
    return api.getQuestionsByType1(practiceId, type, ops());
  },

  countMembers(practiceId: string): AxiosPromise<MemberCountDto[]> {
    return api.countMembers(practiceId, ops());
  },

  filterMembers(filter: "ALL" | "ACTIVE" | "INACTIVE", practiceId: string): AxiosPromise<MemberDto[]> {
    return api.filterMembers(practiceId, filter, ops())
  },

  getNotifications(practiceId: string) {
    return api.getNotifications(practiceId, ops());
  },

  getNotificationByPracticeIdAndTypeAndName(practiceId: string, type: NotificationDtoTypeEnum, name: string) {
    return api.getNotificationByPracticeIdAndTypeAndName(practiceId, type, name, ops());
  },

  updateNotification(practiceId: string, notification: NotificationDto) {
    return api.updateNotification(practiceId, notification, ops());
  },

  deleteContacts(practiceId: string, participantIds: string[]): AxiosPromise<void> {
    return api.deleteAccounts(practiceId, participantIds, {...ops(), responseType: 'blob'});
  },

  getParticipantDeletionStatus(practiceId: string): AxiosPromise<ParticipantDeletionStatusDto> {
    return api.getParticipantDeletionStatus(practiceId, ops());
  },

  getParticipantDeletionStatusPDF(practiceId: string): AxiosPromise<void> {
    return api.getParticipantDeletionStatusPDF(practiceId, {...ops(), responseType: 'blob'});
  },

  getParticipantQuestions(practiceId: string): AxiosPromise<CmsQuestionDto[]> {
    return api.getParticipantQuestions(practiceId, ops());
  },

  getParticipantAnswers(cmsId: number, practiceId: string, questionId?: string): AxiosPromise<{ [key: string]: Array<ParticipantAnswerGroupDto>; }> {
    return api.getParticipantAnswers(practiceId, cmsId, questionId, ops())
  },
  getParticipantFeedback(practiceId: string, from: number, thru: number): AxiosPromise<{ [key: string]: Array<ParticipantAnswerGroupDto>; }> {
    return api.getParticipantFeedback(practiceId, from, thru, ops());
  },
  addPracticeAnswers(practiceId: string, cmsAnswers: CmsAnswerDto[]): AxiosPromise<CmsAnswerDto[]> {
    return api.addPracticeAnswers(practiceId, cmsAnswers, ops());
  },
  exportCmsToCSV(practiceId: string, cmsId: number): AxiosPromise<void> {
    return api.exportCmsToCSV1(practiceId, cmsId, ops());
  },
  exportQuestionToCSV(practiceId: string, cmsId: number, questionId: string): AxiosPromise<void> {
    return api.exportQuestionToCSV1(practiceId, cmsId, questionId, ops());
  },
  createAccountGroup(practiceId: string, dto: AccountGroupDto): AxiosPromise<boolean> {
    return api.createAccountGroup(practiceId, dto, ops())
  },
  getAccountGroups(practiceId: string, memberId: string): AxiosPromise<AccountGroupDto[]> {
    return api.getAccountGroups(practiceId, memberId, ops());
  },
  deleteAccountGroups(practiceId: string, groupIds: string[]): AxiosPromise<void> {
    return api.deleteAccountGroups(practiceId, groupIds, ops());
  },
  getAccountGroup(practiceId: string, groupId: string): AxiosPromise<AccountGroupDto> {
    return api.getAccountGroup(practiceId, groupId, ops());
  },
  getLightPracticeAnswersForQuestion(cmsId: number, practiceId: string, questionId: string): AxiosPromise<LightCmsAnswerDto[]> {
    return api.getLightPracticeAnswersForQuestion(practiceId, cmsId, questionId, ops())
  },
  updateAccountGroup(practiceId: string, groupId: string, dto: AccountGroupDto): AxiosPromise<void> {
    return api.updateAccountGroup(practiceId, groupId, dto, ops())
  },
  checkNameAvailability(practiceId: string, memberId: string, name: string) {
    return api.checkGroupNameAvailability(practiceId, memberId, name, ops());
  },
  getAccountsByPublicIds(practiceId: string, publicIds: string[]): AxiosPromise<AccountDto[]> {
    return api.getAccountsByPublicIds(practiceId, publicIds, ops());
  },
};

export default PracticeApi;
