import * as React from 'react';
import {Input} from 'antd';

interface MfaInputProps {
    autoFocus: boolean;
    onChange: (value: string) => void;
    onDelete: () => void;
    setRef: (value: any) => void;
    disabled?: boolean;
}
export default function MfaInput({autoFocus, disabled, onChange, onDelete, setRef}: MfaInputProps) {
    let previousValue: string = '';

    function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.currentTarget.value === '' && isNumber(event.which)) {
            previousValue = event.currentTarget.value;
        } else if (!isDeleteKey(event.which)) {
            event.preventDefault();
        }
    }

    function onKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
        const newValue: string = event.currentTarget.value;

        if (previousValue === '' && newValue === '') {
            onDelete();
        } else if (newValue !== '') {
            onChange(newValue);
        }
    }

    function isNumber(keyCode: number) {
        return (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
    }

    function isDeleteKey(keyCode: number) {
        return keyCode === 8 || keyCode === 46;
    }

    return (
        <Input maxLength={1} type="tel" autoFocus={autoFocus} onKeyUp={onKeyUp} onKeyDown={onKeyDown} ref={setRef} disabled={disabled}/>
    );
}
