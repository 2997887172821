import Storage from "../util/Storage";
import {env} from "../env/env";
import {Configuration} from "../gen/client";
import globalAxios, {AxiosError} from "axios";
import {errorMessage, logout} from "../util/utils";
import {NetworkConnectionStatus} from "../util/enums";
import {isAppRoute} from "../util/routes";

export const ops = () => ({
  headers: {
    Authorization: 'Bearer ' + Storage.getToken(),
    'X-Machine-Id': Storage.getMachineId()
  },
  withCredentials: true
});

class ApiConfig extends Configuration {
  constructor() {
    super();
    this.basePath = env.apiPath
  }
}

export const apiConfig = new ApiConfig();

globalAxios.interceptors.response.use(resp => resp, (err: AxiosError) => {
  try {
    if (!err.response && err.message === 'Network Error') {
      errorMessage(NetworkConnectionStatus.NONE);
      return Promise.reject(err);
    }
  } catch (e) {}

  if ((!err.response || (err.response.status === 401 && isAppRoute(window.location.pathname))) && window.location.pathname !== '/') {
    logout();
    errorMessage('Sie wurden automatisch abgemeldet');
    window.location.href = '/';
  }
  return Promise.reject(err);
});

globalAxios.interceptors.request.use((config) => {
  return new Promise((resolve, reject) => {
    if (Storage.isRefreshing() && !config.url.includes('public/refreshToken')) {
      const interval = setInterval(() => {
        if (!Storage.isRefreshing()) {
          clearInterval(interval);
          config.headers['Authorization'] = 'Bearer ' + Storage.getToken();
          resolve(config);
        }
      }, 5);
    } else {
      resolve(config);
    }
  });
});
