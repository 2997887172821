import * as React from 'react';
import './Content.scss';
import Container from '../general/Container';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TenantApi from '../../api/TenantApi';
import { handleServerError } from '../../util/ErrorHandler';
import { ContentDto } from '../../gen/client';
import TextEditor from '../general/TextEditor';
import { Button } from 'antd';
import {TENANT_ID} from '../../util/constants';
import Icon from '@ant-design/icons';
import {ReactComponent as backIcon} from '../../img/icons/back-icon.svg';
import { Routes } from '../../util/routes';
import {successMessage} from "../../util/utils";

export default function Content() {
  const { t } = useTranslation();
  const {type} = useParams();

  const [content, setContent] = useState(null as ContentDto);
  const [contentValue, setContentValue] = useState(null);
  const history = useHistory();

  useEffect(() => {
    TenantApi.getContentByType(TENANT_ID, type.toUpperCase()).then(resp => {
      setContent(resp.data);
    }).catch(handleServerError);
  }, [type]);

  function save(){
    TenantApi.updateContent(TENANT_ID, {...content, content: contentValue}).then(resp => {
      setContent(resp.data);
      successMessage(t('Succesfully updated'));
    }).catch(handleServerError);
  }

  function goBack() {
    history.push(Routes.CMS);
  }

  return (
    <Container className={'cms-content full'}>
      <header>
        <Button type="link" className='back-btn' onClick={goBack}><Icon component={backIcon}/></Button>
          <h2>{t(content?.name)}</h2>
      </header>
      <div className={'content'}>
        <TextEditor onChange={setContentValue} content={content?.content}/>
        <div className="actions">
          <Button size="large" className='reset-btn'>{t('Reset')}</Button>
          <Button type={'primary'} size="large" className='save-btn' disabled={!contentValue} onClick={save}>{t('Speichern')}</Button>
        </div>
      </div>
    </Container>
  )
}
