import React from 'react';
import PhoneInput from '../general/PhoneInput';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import './ActivateMfaForm.scss';

export interface ActivateMfaFormProps {
	onSave: () => void;
	loading: boolean;
	phoneValue?: string;
	onPhoneChange: (mfaPhone?: string) => void;
	onActivateButtonClick: () => void;
	onDeactivateButtonClick: () => void;
}

export default function ActivateMfaForm({onSave, loading, phoneValue, onPhoneChange, onActivateButtonClick, onDeactivateButtonClick}: ActivateMfaFormProps) {
	const { t } = useTranslation();

	return (
		<Form onFinish={onSave} className={'activate-mfa-form'}>
			<PhoneInput onChange={onPhoneChange} value={phoneValue} asFormItem={false} required={false} isMfa={true} hint={t('Mobilnummer, unter der Sie den Code empfangen möchten')}/>
			<div className={'buttons'}>
				<Button size={'large'} className={'deactivate-button'} onClick={onDeactivateButtonClick} htmlType="submit" loading={loading} >{t('Überspringen')}</Button>
				<Button size={'large'} className={'activate-button'} onClick={onActivateButtonClick} type={'primary'} htmlType="submit" loading={loading}>{t('Aktivieren')}</Button>
			</div>
		</Form>
	);
}
