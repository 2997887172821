import * as React from 'react';
import '../contact/Contacts.scss';
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {handleServerError} from "../../util/ErrorHandler";
import {AccountGroupDto} from "../../gen/client";
import PracticeApi from '../../api/PracticeApi';
import { activePractice } from '../../state/atoms';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../util/routes';
import {successMessage} from "../../util/utils";
import Storage from "../../util/Storage";
import SelectionBar from '../general/SelectionBar';

interface GroupSelectionBarProps {
  groups: AccountGroupDto[],
  selected: string[],
  onSelectedChange: (selected: string[]) => void;
  onDeleteGroups: (ids: string[]) => void;
}

export default function GroupSelectionBar({groups, selected, onSelectedChange, onDeleteGroups}: GroupSelectionBarProps) {
  const {t} = useTranslation();
  const history = useHistory();
  const {confirm} = Modal;

  const [practice] = useRecoilState(activePractice);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  function showDeleteConfirm() {
    confirm({
      content: t('Wollen Sie wirklich alle ausgewählten Gruppen löschen?'),
      okText: t('Ja'),
      okType: 'danger',
      cancelText: t('Nein'),
      onOk() { handleMultipleDelete() }
    });
  }

  function handleMultipleDelete() {
    setDeleteInProgress(true);
    let groupIds = groups.filter(it => selected.indexOf(it.id) > -1).map(it => it.id)
    PracticeApi.deleteAccountGroups(practice.id, groupIds)
      .then(() => {
        onSelectedChange([]);
        successMessage(t('Succesfully deleted'));
        onDeleteGroups(groups.filter(it => selected.indexOf(it.id) > -1).map(it => it.id));
      })
      .catch(err => {
        setDeleteInProgress(false);
        handleServerError(err);
      });
  }

  function createNewAppointment() {
    Storage.setGroupsIds(groups.filter(it => selected.indexOf(it.id) > -1).map(it => it.id));
    history.push(Routes.APPOINTMENTS_NEW_GROUP);
  }

  return (
    <>
     <SelectionBar selected={selected} groups={true} deleteInProgress={deleteInProgress} 
        onDelete={showDeleteConfirm} onCreateNewAppointment={createNewAppointment}/>
    </>
  )
}
