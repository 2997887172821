import * as React from 'react';
import './RoleLabel.scss';
import { MemberDtoRolesEnum } from '../../gen/client';
import { useTranslation } from 'react-i18next';

interface RoleLabelProps {
  role: MemberDtoRolesEnum;
}

export default function RoleLabel({role}: RoleLabelProps) {
  const {t} = useTranslation();

  function getLabelClass(): string {
    switch (role){
      case MemberDtoRolesEnum.Admin: return 'admin';
      case MemberDtoRolesEnum.PracticeManager: return 'institution-manager';
      case MemberDtoRolesEnum.Provider: return 'provider';
      case MemberDtoRolesEnum.CustomerCare: return 'customercare'
    }
  }

  return (
    <div className='role-label'>
      <div className={`label ${getLabelClass()}`}>
        {t(role)}
      </div>
    </div>
  );
}
