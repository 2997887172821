import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import {RecoilRoot} from "recoil";
import {BrowserRouter} from "react-router-dom";
import {ErrorBoundary} from "./components/general/ErrorBoundary";
import {getUserConfirmation} from "./util/utils";

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <BrowserRouter getUserConfirmation={getUserConfirmation}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
