import * as React from 'react';
import './Loader.scss';

export default function Loader() {
  return (
    <div className="app-loader">
      <p>Loading...</p>
    </div>
  )
}
