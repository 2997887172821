import React from 'react';
import './Settings.scss';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import Container from "../general/Container";
import {Skeleton} from "antd";
import VideoSettings from './VideoSettings';
import AdminSettings from './AdminSettings';
import Storage from "../../util/Storage";
import MemberApi from '../../api/MemberApi';
import {MemberDto, SettingsDto, AddressDto, MemberDtoRolesEnum} from '../../gen/client';
import {useState, useEffect} from "react";
import {handleServerError} from "../../util/ErrorHandler";
import {getDefaultRoute} from '../../util/routes';
import {useRecoilState} from 'recoil';
import {loggedMember} from "../../state/atoms";
import {getConnectedDevices, stopMeetingStream} from '../../service/DeviceService';
import {DeviceType} from "../../util/enums";
import {isAdmin, isProvider, successMessage} from "../../util/utils";

export default function Settings() {
  const {t} = useTranslation();
  const history = useHistory();

  const [logged] = useRecoilState(loggedMember);

  const [loading, setLoading] = useState(true);
  const [member, setMember] = useState(null as MemberDto);
  const [cameras, setCameras] = useState([] as MediaDeviceInfo[]);
  const [microphones, setMicrophones] = useState([] as MediaDeviceInfo[]);
  const [speakers, setSpeakers] = useState([] as MediaDeviceInfo[]);

  useEffect(() => {
    setLoading(true);
    MemberApi.getMember(Storage.getMemberId()).then(resp => {
      const current = {
        ...resp.data,
        addresses: resp.data.addresses.length > 0 ? resp.data.addresses : [{} as AddressDto],
        settings: resp.data.settings || {} as SettingsDto
      };
      setMember(current);
      setLoading(false);
    }).catch(err => {
      handleServerError(err);
      setLoading(false);
    });
  }, [setMember, setLoading]);

  useEffect(() => {
    let doNotCheckMediaDevices = logged.isAdmin || logged.roles.indexOf(MemberDtoRolesEnum.CustomerCare) !== -1;
    let cam: MediaStream = null;
    let mic: MediaStream = null;
    let spk: MediaStream = null; 
    
    if (!doNotCheckMediaDevices) {
      getConnectedDevices(DeviceType.VIDEO_INPUT, (devices, cameraStream) => {
        setCameras(devices);
        cam = cameraStream;
      });

      getConnectedDevices(DeviceType.AUDIO_INPUT, (devices, microphoneStream) => {
        setMicrophones(devices);
        mic = microphoneStream;
      });

      getConnectedDevices(DeviceType.AUDIO_OUTPUT, (devices, speakerStream) => {
        setSpeakers(devices);
        spk = speakerStream;
      });
    }

    return () => {
      if (cam) stopMeetingStream(cam);
      if (mic) stopMeetingStream(mic);
      if (spk) stopMeetingStream(spk);
    }

  }, [logged.isAdmin, logged.roles]);

  function save() {
    checkInitialDevicesSetup();
    MemberApi.saveMember(logged.id, member).then(() => {
      successMessage(t('Erfolgreich gespeichert!'))
    }).catch(handleServerError);
  }

  function checkInitialDevicesSetup() {
    if (!member.settings.cameraId) {
      member.settings.cameraId = cameras[0]?.deviceId;
    }
    if (!member.settings.microphoneId) {
      member.settings.microphoneId = microphones[0]?.deviceId;
    }
    if (!member.settings.speakerId) {
      member.settings.speakerId = speakers[0]?.deviceId;
    }
  }

  function cancel() {
    setTimeout(() => {
      history.push(getDefaultRoute(logged.activeRole));
    }, 100);
  }

  function onMemberSettingsChange(updated: SettingsDto) {
    setMember({
      ...member,
      settings: {...member.settings, ...updated}
    })
  }

  return <Container className={'settings full'}>
    <header>
      <h2>{t('Einstellungen')}</h2>
    </header>
    {isAdmin(logged) && <AdminSettings/>}

    {!isAdmin(logged) &&
    <div className={'tab-content content'}>
      {loading && <Skeleton loading={true}/>}
      {isProvider(logged) && !loading && member &&
      <VideoSettings onSave={save} onCancel={cancel} settings={member.settings} cameras={cameras} microphones={microphones} speakers={speakers} onMemberSettingsChange={onMemberSettingsChange}/>}
    </div>}
  </Container>;
}
