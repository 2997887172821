import React from 'react';
import './PasswordStrength.scss';
import zxcvbn from "zxcvbn";
import { Rate } from "antd";
import { MinusOutlined } from '@ant-design/icons';

interface PasswordStrengthProps {
    password: string;
}

export default function PasswordStrength({ password }: PasswordStrengthProps) {
    return (
        <Rate className="password-strength" defaultValue={1} disabled character={<MinusOutlined />} value={zxcvbn(password).score + 1} />
    );
}
