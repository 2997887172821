import React, {useEffect} from "react";
import {
  audioMutedState,
  audioTracksState,
  cameraOffState,
  cameraPositionState,
  meetingInfoState,
  remoteStreamsState,
  videoTracksState
} from "../../state/atoms";
import {useRecoilValue} from "recoil";
import {connections, sendInternalMessage} from "../../service/RtcService";
import {InternalMessageType, MeetingStatus} from "../../util/types";
import {fitVideosToFullScreen} from "../../service/MeetingService";

export default function MeetingListener() {
  const localAudioTracks = useRecoilValue(audioTracksState);
  const localVideoTracks = useRecoilValue(videoTracksState);
  const isAudioMuted = useRecoilValue(audioMutedState);
  const isCameraOff = useRecoilValue(cameraOffState);
  const cameraPosition = useRecoilValue(cameraPositionState);
  const meetingInfo = useRecoilValue(meetingInfoState);
  const remoteStreams = useRecoilValue(remoteStreamsState);

  useEffect(() => {
    if (localAudioTracks) {
      localAudioTracks.forEach(track => track.enabled = !isAudioMuted);

      if (isAudioMuted) {
        sendInternalMessage({type: InternalMessageType.MUTE});
      } else {
        sendInternalMessage({type: InternalMessageType.UNMUTE});
      }
    }
  }, [localAudioTracks, isAudioMuted]);

  useEffect(() => {
    if (localVideoTracks) {
      localVideoTracks.forEach(track => track.enabled = !isCameraOff);
    }
    if (isCameraOff) {
      sendInternalMessage({type: InternalMessageType.CAMERA_HIDE});
    } else {
      sendInternalMessage({type: InternalMessageType.CAMERA_SHOW});
    }
  }, [isCameraOff, localVideoTracks]);

  useEffect(() => {
    sendInternalMessage({type: InternalMessageType.CAMERA_POSITION, payload: JSON.stringify(cameraPosition)});
  }, [cameraPosition]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (meetingInfo.status !== MeetingStatus.Progress) return;
      if (document.visibilityState === 'visible') {
        fitVideosToFullScreen(null, null, true);
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange, false);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange, false);
    }
  }, [meetingInfo.status]);

  useEffect(() => {
    if (meetingInfo.status !== MeetingStatus.Progress) return;

    const interval = setInterval(() => {
      if (Object.keys(connections).length !== remoteStreams.length) {
        fitVideosToFullScreen(null, null, true);
      }
    }, 15000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [meetingInfo.status, remoteStreams.length]);

  return <></>
}
