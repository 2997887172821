import * as React from 'react';
import './PracticeSetup.scss';
import {useTranslation} from "react-i18next";
import regLeftImage from '../../img/register-4.svg';
import regRightImage from '../../img/register-5.svg';
import mainLogo from '../../img/noventi-video-logo.png';
import {Button, Input, Form, Rate} from 'antd';
import {useState} from 'react';
import StartPageFooter from '../start/StartPageFooter';
import {useEffect} from "react";
import Storage from "../../util/Storage";
import {CmsAnswerDto, CmsQuestionDto, CmsQuestionDtoTypeEnum, SetupDto} from "../../gen/client";
import {handleServerError} from "../../util/ErrorHandler";
import {getInitialRoute} from "../../util/routes";
import {useHistory} from "react-router";
import PracticeApi from '../../api/PracticeApi';
import {useRecoilState, useRecoilValue} from 'recoil';
import {activePractice, applicationSize, loggedMember} from '../../state/atoms';
import TextArea from 'antd/lib/input/TextArea';
import TenantApi from '../../api/TenantApi';
import {TENANT_ID} from '../../util/constants';
import {getApplicationSizeClass, timestamp} from '../../util/utils';
import {Dimensions} from '../../util/enums';
import moment from 'moment';

export function PracticeSetup() {
  const {t} = useTranslation();
  const history = useHistory();
  const {Item} = Form;

  const [saving, setSaving] = useState(false);
  const [setup] = useState({address: {}} as SetupDto);
  const [questions, setQuestions] = useState([] as CmsQuestionDto[]);
  const [registerQuestions, setRegisterQuestions] = useState([] as CmsQuestionDto[]);
  const [answers] = useState([] as string[]);
  const [registerAnswers] = useState([] as string[]);

  const [practice] = useRecoilState(activePractice);
  const [logged] = useRecoilState(loggedMember);
  const appSize = useRecoilValue<Dimensions>(applicationSize);

  useEffect(() => {
    PracticeApi.getQuestionsByType(practice.id, "QUESTION_AFTER_REGISTER_PROVIDER")
      .then(resp => setQuestions(prev => [...prev, ...resp.data]))
      .catch(handleServerError);

    TenantApi.getQuestionsByType(TENANT_ID, "QUESTION_AFTER_REGISTER")
      .then(resp => setRegisterQuestions(resp.data))
      .catch(handleServerError);
  }, [practice.id]);

  function save() {
    setSaving(true);
    saveAnswers();
    setup.answers = getRegisterAnswers();
    PracticeApi.setup(practice.id, setup).then(() => {
      history.push(getInitialRoute(false, logged.activeRole));
    }).catch(err => {
      handleServerError(err);
      setSaving(false);
    });
  }

  function saveAnswers() {
    answers.forEach((it, idx) => {
      if (!!it) {
        const action = logged.isAdmin ?
          TenantApi.addAnswers(TENANT_ID, 
            [{answer: it, createdDate: timestamp(moment()), memberId: Storage.getMemberId(), questionId: questions[idx].id}]) :
          PracticeApi.addPracticeAnswers(practice.id,
            [{answer: it, createdDate: timestamp(moment()), memberId: Storage.getMemberId(), questionId: questions[idx].id}]);
        action.catch(err => handleServerError(err))
      }
    });
  }

  function getRegisterAnswers() {
    let ans: CmsAnswerDto[] = [];
    registerQuestions?.forEach((it, idx) => { // NOSONAR
      if (registerAnswers[idx]) {
        ans.push({
          answer: registerAnswers[idx],
          createdDate: timestamp(moment()),
          memberId: Storage.getMemberId(),
          questionId: registerQuestions[idx].id
        })
      }
    });

    return ans;
  }

  return <div className={`account-setup ${getApplicationSizeClass(appSize)}`}>
    <img src={regLeftImage} className={'setup-image left'} alt={''}/>
    <div className={'content'}>
      <div className={'data'}>
        <div><img className={'logo'} src={mainLogo} alt={'logo'}/></div>
        <h3>{t(`Willkommen, ${logged.name}`)}</h3>
        <h4>{t(`Before you can use the Noventi Video Call App, let's take a moment and finish setting up your account.`)}</h4>
        <Form onFinish={save} layout="vertical">
          <div className='account-setup-fields'>
            <div className='reg-questions'>
              {
                registerQuestions?.map((it, idx) => (
                  <div key={it.id}>
                    <div>
                      <Item label={t(it.label)}>
                        <Input inputMode={'text'} onChange={ev => registerAnswers[idx] = ev.target.value}/>
                      </Item>
                    </div>
                  </div>
                ))
              }
            </div>
            <div>
              <Item label={t('Wie ist der Name zu deiner Praxis?')} name={'practice'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
                <Input inputMode={'text'} onChange={ev => setup.practice = ev.target.value}/>
              </Item>
            </div>
            <div className={'address-row'}>
              <div>
                <Item label={t('Wie lautet der Straße, Ihre Praxis befindet?')} name={'street'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
                  <Input inputMode={'text'} onChange={ev => setup.address.street = ev.target.value}/>
                </Item>
              </div>
              <div>
                <Item label={t('Haus-Nr.')} name={'number'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
                  <Input inputMode={'text'} onChange={ev => setup.address.number = ev.target.value}/>
                </Item>
              </div>
            </div>
            <div className={'zip-row'}>
              <div>
                <Item label={t('Postleitzahl')} name={'zip'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
                  <Input inputMode={'text'} onChange={ev => setup.address.postalCode = ev.target.value}/>
                </Item>
              </div>
              <div>
                <Item label={t('Ort')} name={'place'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
                  <Input inputMode={'text'} onChange={ev => setup.address.city = ev.target.value}/>
                </Item>
              </div>
            </div>
            <div>
              {questions?.map((question, idx) => (
                <div key={question.id} className='question ant-form-item'>
                  <label>{question.label}</label>
                  {question.type === CmsQuestionDtoTypeEnum.Paragraph && <TextArea onChange={ev => answers[idx] = ev.target.value}/>}
                  {question.type === CmsQuestionDtoTypeEnum.Rating && <Rate className='rate' onChange={value => answers[idx] = value.toString()}/>}
                </div>
              ))}
            </div>
          </div>

          <Item className={'flx ft'}>
            <Button size="large" type="primary" htmlType="submit" loading={saving} disabled={saving}>{t('Speichern')}</Button>
          </Item>
        </Form>
      </div>


      <StartPageFooter/>

    </div>
    <img src={regRightImage} className={'setup-image right'} alt={''}/>
  </div>
}
