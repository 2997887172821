import * as React from 'react';
import './LoginForm.scss';
import {useTranslation} from "react-i18next";
import {Button, Form, Input} from "antd";
import {Store} from 'antd/lib/form/interface';
import {useState} from "react";
import AuthApi from "../../api/AuthApi";
import {handleServerError} from "../../util/ErrorHandler";
import {useRecoilState, useSetRecoilState} from "recoil";
import {loginData, loggedMember, activePractice} from "../../state/atoms";
import {useHistory, NavLink} from 'react-router-dom';
import {errorMessage, initialStorageSetup} from '../../util/utils';
import {getInitialRoute, Routes} from '../../util/routes';
import EmailInput from '../general/EmailInput';
import {getLoggedAtom} from "../../state/logged";
import Storage from '../../util/Storage';

const {Item} = Form;
const {Password} = Input;

export interface LoginFormProps {
  onLoginSuccess: () => void;
}

export default function LoginForm({onLoginSuccess}: LoginFormProps) {
  const {t} = useTranslation();

  const [loginInfo, setLoginInfo] = useRecoilState(loginData);
  const setLogged = useSetRecoilState(loggedMember);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const setPractice = useSetRecoilState(activePractice);

  function login(store: Store) {
    setLoading(true);
    AuthApi.login({
      ...store, 
      device: Storage.getDeviceId(loginInfo?.email ?? '') || ''
    }).then(resp => {
      setLoading(false);

      if (resp.data.member.id) {
        const logged = getLoggedAtom(resp.data);

        initialStorageSetup(resp.data, logged.activeRole);

        setLogged(logged);
        if(resp.data.needsSetup) {
					setLoginInfo({
						email: store.email,
						password: store.password
					});
				} else {
					setLoginInfo({
						email: '',
						password: ''
					});
				}

        setPractice({
          id: resp.data.member.practice.id,
          name: resp.data.member.practice.name
        });

        const initialRoute = getInitialRoute(resp.data.needsSetup, logged.activeRole, resp.data.member.settings?.twoFactorAuthentication != null);

        if (initialRoute === Routes.ACTIVATE_MFA) {
          setLoginInfo({
						email: store.email,
						password: store.password
					});
        }

        history.push(initialRoute);
      } else {
        if (resp.data.codeSent) {
          setLoginInfo({
            email: store.email,
            password: store.password
          });
          onLoginSuccess();
        } else {
          errorMessage(t('Failed to send multi faktor authentication token. Please contact support.'))
        }
      }
    })
      .catch(err => {
        setLoading(false);
        handleServerError(err);
      });
  }

  return (
    <Form className={'log'} onFinish={login} layout="vertical">
      <EmailInput asFormItem={true} required={true}/>

      <Item label={'Passwort'} name={'password'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
        <Password size="large" name="new-password" id="new-password" autoComplete="off"/>
      </Item>

      <div className='row'>
        <Item>
          <NavLink className={'login-forgot'} to={Routes.CONNECTION_TEST}>{t('Verbindungstest starten')}</NavLink>
        </Item>

        <Item>
          <NavLink className={'login-forgot'} to={Routes.PASSWORD_FORGOT}>{t('Passwort vergessen?')}</NavLink>
        </Item>
      </div>

      <Item className={'flx ft'}>
        <Button size="large" type="primary" htmlType="submit" loading={isLoading}>{t('Anmelden')}</Button>
      </Item>
    </Form>
  );
}
