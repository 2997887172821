/**
* This file is generated
*/
import * as React from 'react';
import { CSSProperties } from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path id="Subtraction_4" d="M39.6,42.5H10.4c-1.4,0-2.8-0.6-3.8-1.6c-1-1-1.6-2.3-1.6-3.8V16c0-1.4,0.6-2.8,1.6-3.8 c1-1,2.4-1.6,3.8-1.6h3.9V8.8c0-0.7,0.6-1.3,1.3-1.3S17,8.1,17,8.8v1.8h16V8.8c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3v1.8h3.9 c3,0,5.4,2.4,5.5,5.4v21.1c0,1.4-0.6,2.8-1.6,3.8C42.4,41.9,41,42.5,39.6,42.5z M18.3,24.9c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h7.4 c1.1,0,2-0.9,2-2v-6c0-1.1-0.9-2-2-2H18.3z M32.6,25.9c-0.1,0-0.3,0-0.4,0.1l-3.4,2.1c-0.1,0.1-0.2,0.2-0.2,0.3v3 c0,0.1,0.1,0.2,0.2,0.3l3.4,2.1c0.1,0.1,0.2,0.1,0.4,0.1h0.8c0.2,0,0.3-0.1,0.3-0.3c0,0,0,0,0,0v-7.3c0-0.2-0.2-0.3-0.3-0.3 c0,0,0,0,0,0L32.6,25.9z M10.4,13.3c-1.5,0-2.7,1.2-2.8,2.7v3.4h34.7V16c0-1.5-1.3-2.7-2.8-2.7H10.4z"/></svg>
);

const RegisterVideoIcon = (props: { style?: CSSProperties, className?: string }) => <Icon component={svg} {...props}/>;

export default RegisterVideoIcon;