import * as React from 'react';

export default function MicrophoneSettingsSVG() {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M14.8,10.6V9.5c0,0,0,0,0,0C14.8,9.2,15,9,15.3,9s0.5,0.2,0.5,0.5v1.2c-0.1,0-0.2-0.1-0.4-0.1C15.2,10.6,15,10.6,14.8,10.6z
        M14.3,16.1c0.1-0.1,0.2-0.2,0.3-0.3C14.4,15.9,14.3,16,14.3,16.1z M9.1,15.8c0-0.1,0-0.1,0-0.2c0.1-0.6,0.5-1.1,1.1-1.3
        c-0.3-0.5-0.3-1.2,0.1-1.7c0.3-0.3,0.6-0.6,0.9-0.9l0,0l0,0c0.3-0.2,0.6-0.3,0.9-0.3c0.3,0,0.5,0.1,0.7,0.2c0.1-0.3,0.3-0.6,0.6-0.8
        v-5c0-2.1-1.7-3.9-3.9-3.9C7.5,2,5.8,3.7,5.8,5.9V12C5.8,14,7.2,15.6,9.1,15.8z M11.2,20.7L11.2,20.7L11.2,20.7
        c-0.3-0.2-0.5-0.5-0.7-0.7h-0.3v-0.4c-0.2-0.4-0.2-0.9,0-1.3v0c0,0,0,0,0,0c0,0,0,0,0,0c-0.5-0.1-0.9-0.6-1-1.1
        c-2.6-0.3-4.6-2.4-4.6-5V9.5C4.5,9.2,4.3,9,4,8.9C3.7,9,3.5,9.2,3.5,9.5V12c0,3.2,2.5,5.9,5.7,6.2V20H7.1c-0.3,0-0.5,0.2-0.5,0.5
        c0,0.3,0.2,0.5,0.5,0.5c0,0,0,0,0,0H12C11.7,21,11.4,20.9,11.2,20.7z M19,17.3C19,17.3,19,17.3,19,17.3L19,17.3
        c-0.4,0-0.6,0.2-0.8,0.5c-0.1,0.3,0,0.6,0.2,0.8c0.2,0.2,0.2,0.5,0.1,0.7c-0.2,0.3-0.5,0.5-0.8,0.8c-0.1,0.1-0.2,0.1-0.3,0.1
        c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.2-0.6-0.2c-0.1,0-0.2,0-0.3,0c-0.3,0.1-0.5,0.4-0.5,0.7c0,0.3-0.2,0.5-0.5,0.5
        c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.5,0c-0.3,0-0.5-0.3-0.5-0.5c0-0.3-0.2-0.6-0.4-0.7c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0.1-0.6,0.2
        c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.3-0.2-0.5-0.5-0.8-0.8c-0.2-0.2-0.1-0.5,0.1-0.7c0.2-0.2,0.3-0.5,0.2-0.8
        c-0.1-0.3-0.4-0.5-0.7-0.5h0h0c-0.2,0-0.5-0.2-0.6-0.5c0-0.4,0-0.7,0-1.1c0-0.3,0.2-0.5,0.5-0.5c0,0,0,0,0,0c0,0,0,0,0,0
        c0.3,0,0.6-0.2,0.7-0.4c0.1-0.3,0-0.6-0.2-0.8c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.3,0.5-0.5,0.8-0.8c0.1-0.1,0.2-0.1,0.3-0.1
        c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.2,0.6,0.2c0.1,0,0.2,0,0.3,0c0.3-0.1,0.5-0.4,0.5-0.7c0-0.3,0.2-0.5,0.5-0.5
        c0.2,0,0.3,0,0.5,0c0.2,0,0.4,0,0.6,0c0.3,0,0.5,0.3,0.5,0.5c0,0.3,0.2,0.6,0.4,0.7c0.1,0,0.2,0,0.3,0c0.2,0,0.4-0.1,0.6-0.2
        c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.2,0,0.3,0.1c0.3,0.2,0.5,0.5,0.8,0.8c0.2,0.2,0.1,0.5-0.1,0.7c-0.2,0.2-0.3,0.5-0.2,0.8
        c0.1,0.3,0.4,0.5,0.7,0.5l0,0c0.2,0,0.5,0.2,0.6,0.5c0,0.4,0,0.7,0,1.1C19.5,17.1,19.3,17.3,19,17.3z M16.3,16.3
        c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5C15.6,17.8,16.3,17.1,16.3,16.3z"/>
    </svg>
  )
}
