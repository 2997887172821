import {Button, Card, Modal, Skeleton, Slider, Switch} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import './DSGVO.scss';
import {ParticipantDeletionStatusDto} from '../../gen/client';
import {handleServerError} from '../../util/ErrorHandler';
import PracticeApi from '../../api/PracticeApi';
import {useRecoilState} from 'recoil';
import {activePractice, loggedMember} from '../../state/atoms';
import {downloadFileUsingBlob, successMessage} from '../../util/utils';
import {DEFAULT_PARTICIPANT_DELETION_PERIOD} from '../../util/constants';
import moment from 'moment';
import 'moment/locale/de';

export default function DataProtectionManagement() {
  const {t} = useTranslation();

  const [practice] = useRecoilState(activePractice);
  const [logged] = useRecoilState(loggedMember);

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [participantDeletionStatus, setParticipantDeletionStatus] = useState(null as ParticipantDeletionStatusDto);
  const [deletionPeriod, setDeletionPeriod] = useState(DEFAULT_PARTICIPANT_DELETION_PERIOD);

  const [openDeletionModal, setDeletionModal] = useState(false);
  const [sendDeletionEmail, setDeletionEmail] = useState(false);
  const [downloadDeletionPDF, setDownloadDeletionPDF] = useState(false);

  useEffect(() => {
    setLoading(true);
    PracticeApi.getPracticeSettings(practice.id).then(resp => {
      setDeletionPeriod(resp.data.participantDeletionPeriod || DEFAULT_PARTICIPANT_DELETION_PERIOD);
      setLoading(false);
    }).catch(err => {
      handleServerError(err);
      setLoading(false);
    });
  }, [practice.id]);

  function saveDeletionPeriod() {
    setSaving(true);
    PracticeApi.setParticipantDeletionPeriod(practice.id, deletionPeriod).then(() => {
        successMessage(t('Deletion period successfully saved!'));
        setSaving(false);
      }).catch(err => {
      handleServerError(err);
      setSaving(false);
    });
  }

  function sendParticipantDeletionStatusEmail() {
    PracticeApi.sendParticipantDeletionStatusEmail(practice.id)
      .then(() => successMessage(t('E-mail successfully sent!')))
      .catch(err => handleServerError(err));
  }

  function downloadParticipantDeletionStatusPDF() {
    PracticeApi.getParticipantDeletionStatusPDF(practice.id)
      .then(response => downloadFileUsingBlob(response.request.response, `${practice.id}.pdf`))
      .catch(err => handleServerError(err));
  }

  function checkUserOptionsBeforeClosingDeletionModal() {
    if (sendDeletionEmail) {
      sendParticipantDeletionStatusEmail();
    }
    if (downloadDeletionPDF) {
      downloadParticipantDeletionStatusPDF();
    }
    closeDeletionModal();
  }

  function closeDeletionModal() {
    setDeletionEmail(false);
    setDownloadDeletionPDF(false);
    setDeletionModal(false);
  }

  function onOpenDeletionModal() {
    PracticeApi.getParticipantDeletionStatus(practice.id)
      .then(resp => {
        if (resp.data) {
          setParticipantDeletionStatus(resp.data);
        }
      })
      .catch(err => handleServerError(err));
    setDeletionModal(true);
  }

  function getDeleteDate(period: number) {
    return moment().locale('de').subtract(period, 'years').format('ll');
  }

  return (
    <div className={'dsgvo'}>
      {loading && <Skeleton loading={true}/>}

      {!loading && practice &&
      <div className="content-wrapper">
        <div className='days'>
          <h3>{t('Stammdaten')}</h3>
          <div>{t('Speicherdauer für Patientendaten')}</div>
          <div className='slider-wrapper'>
            <div className='slider'>
              <Slider defaultValue={deletionPeriod} min={1} max={5} tooltipPlacement={'bottom'} onChange={setDeletionPeriod} />
            </div>
            <div className='years-wrapper'>
              <div className='years'>{deletionPeriod} {t('years')}</div>
            </div>
          </div>
          <div className='message'>{t('Alle Patientendaten, die vor dem {{date}} gespeichert wurden, werden innerhalb der nächsten 24 Stunden gelöscht.', {date: getDeleteDate(deletionPeriod)})}</div>
        </div>
        <div className="download">
          <h3>{t('Löschbestätigung anfordern')}</h3>
          <Button className={'download-btn'} onClick={onOpenDeletionModal}>{t('DOWNLOAD')}</Button>
        </div>
        <div>
          <Button type={'primary'} size="large" className='speichern-button' onClick={saveDeletionPeriod} loading={saving}>{t('Speichern')}</Button>
        </div>
      </div>}

      <Modal
        closable={false}
        className={'del-patient-modal'}
        visible={openDeletionModal}
        width={664}
        centered
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        destroyOnClose={true}>
        <div className='modal-body-wrapper'>
          <h1>{t('Patientendaten löschen')}</h1>
          <h3>{t('Möchten Sie wirklich alle Patientendaten ')} {logged.name} {t('für löschen?')}</h3>
          <Card className='del-patient-card'>
            <h3>{t('Das sind die Daten, die gelöscht werden')}:</h3>
            <h4>{participantDeletionStatus?.numberOfDeleted || 0}{t(' Patienten')}</h4>
          </Card>

          <div className='label switch-wrapper'>
            <Switch defaultChecked={sendDeletionEmail} onChange={() => setDeletionEmail(!sendDeletionEmail)} disabled={!participantDeletionStatus}/>
            <span>{t('Loschübersicht per E-Mail versenden')}</span>
          </div>
          <div className='label switch-wrapper'>
            <Switch defaultChecked={downloadDeletionPDF} onChange={() => setDownloadDeletionPDF(!downloadDeletionPDF)} disabled={!participantDeletionStatus}/>
            <span>{t('Loschübersicht als PDF downloaden')}</span>
          </div>
          <div className='btns'>
            <Button type={'primary'} size="large" className='cancel-btn' onClick={closeDeletionModal}>{t('Abbrechen')}</Button>
            <Button type={'primary'} size="large" className='search-btn' onClick={checkUserOptionsBeforeClosingDeletionModal} disabled={!participantDeletionStatus}>{t('Ok')}</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
