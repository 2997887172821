import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Select, Switch} from "antd";
import {SettingsDto} from '../../gen/client';
import {attachSinkId, getStreamForSpecificDevice, isValidDeviceId, stopMeetingStream} from '../../service/DeviceService';
import '../appointment/Meeting.scss';
import MeetingFooter from '../appointment/MeetingFooter';
import {useRecoilState, useRecoilValue} from 'recoil';
import {applicationSize, audioMutedState, cameraOffState, loggedMember} from '../../state/atoms';
import {DeviceType, Dimensions} from '../../util/enums';
import {doNothing} from '../../util/utils';
import {usePrevious} from "../../util/hooks";

interface VideoSettingsProps {
  settings: SettingsDto;
  cameras: MediaDeviceInfo[];
  microphones: MediaDeviceInfo[];
  speakers: MediaDeviceInfo[];
  onMemberSettingsChange: (setting: SettingsDto) => void;
  onSave: () => void;
  onCancel: () => void;
}

export default function VideoSettings({settings, cameras, speakers, microphones, onMemberSettingsChange, onSave, onCancel}: VideoSettingsProps) {
  const {t} = useTranslation();
  const {Option} = Select;
  const [logged, setLogged] = useRecoilState(loggedMember);

  const [localStream, setLocalStream] = useState(null as MediaStream);
  const previousStream = usePrevious(localStream);

  const isAudioMuted = useRecoilValue(audioMutedState);
  const isCameraOff = useRecoilValue(cameraOffState);

  const [selectedCamera, setSelectedCamera] = useState('');
  const [selectedMicrophone, setSelectedMicrophone] = useState('');
  const [selectedSpeaker, setSelectedSpeaker] = useState('');

  const cameraOptions = cameras.map(it => <Option key={it.deviceId} value={it.deviceId}>{it.label}</Option>)
  const microphoneOptions = microphones.map(it => <Option key={it.deviceId} value={it.deviceId}>{it.label}</Option>)
  const speakerOptions = speakers.map(it => <Option key={it.deviceId} value={it.deviceId}>{it.label}</Option>)
  const appSize = useRecoilValue<Dimensions>(applicationSize);
  const [showPreview, setShowPreview] = useState(false);

  const updateStream = useCallback((stream: MediaStream) => {
    setLocalStream(stream);
  }, [setLocalStream]);

  useEffect(() => {
    return () => {
      if (localStream) {
        stopMeetingStream(localStream);
      }
    }
  }, [localStream]);

  useEffect(() => {
    if (localStream) {
      localStream.getVideoTracks().forEach(videoTrack => videoTrack.enabled = !isCameraOff);
    }
  }, [isCameraOff, localStream]);

  useEffect(() => {
    if (localStream) {
      localStream.getAudioTracks().forEach(audioTrack => audioTrack.enabled = !isAudioMuted);
    }
  }, [localStream, isAudioMuted]);

  useEffect(() => {
    if (!cameras || cameras.length === 0) return;
    if (settings && settings.cameraId) {
      const deviceId = cameras.map(it => it.deviceId).indexOf(settings.cameraId) > -1 ? settings.cameraId : cameras[0].deviceId;
      isValidDeviceId(DeviceType.VIDEO_INPUT, deviceId).then(valid => {
        setSelectedCamera(valid ? deviceId : cameras[0].deviceId);
      });
    }
  }, [cameras, settings]);

  useEffect(() => {
    if (!microphones || microphones.length === 0) return;
    if (settings && settings.microphoneId) {
      const deviceId = microphones.map(it => it.deviceId).indexOf(settings.microphoneId) > -1 ? settings.microphoneId : microphones[0].deviceId
      isValidDeviceId(DeviceType.AUDIO_INPUT, deviceId).then(valid => {
        setSelectedMicrophone(valid ? deviceId : microphones[0].deviceId);
      });
    }
  }, [microphones, settings]);

  useEffect(() => {
    if (!speakers || speakers.length === 0) return;
    if (settings && settings.speakerId) {
      const deviceId = speakers.map(it => it.deviceId).indexOf(settings.speakerId) > -1 ?settings.speakerId : speakers[0].deviceId;
      isValidDeviceId(DeviceType.AUDIO_OUTPUT, deviceId).then(valid => {
        setSelectedSpeaker(valid ? deviceId : speakers[0].deviceId);
      });
    }
  }, [speakers, settings]);

  useEffect(() => {
    if (selectedMicrophone && selectedCamera) {
      stopMeetingStream(previousStream);
      getStreamForSpecificDevice({
        audio: {deviceId: {exact: selectedMicrophone}},
        video: {deviceId: {exact: selectedCamera}}
      }).then(updateStream);
    }
  }, [selectedCamera, selectedMicrophone, updateStream, previousStream]);

  useEffect(() => {
    if (selectedSpeaker) {
      attachSinkId(document.getElementById('localVideo'), settings.speakerId || speakers[0]?.deviceId);
    }
  }, [selectedSpeaker, settings.speakerId, speakers]);

  function onCameraChange(cameraId: string) {
    onMemberSettingsChange({cameraId: cameraId});
    setSelectedCamera(cameraId);
  }

  function onMicrophoneChange(microphoneId: string) {
    onMemberSettingsChange({microphoneId: microphoneId});
    setSelectedMicrophone(microphoneId);
  }

  function onSpeakerChange(speakerId: string) {
    onMemberSettingsChange({speakerId: speakerId});
    setSelectedSpeaker(speakerId);
  }

  function onSkipPreviewOptionChange(value: boolean) {
    setLogged({...logged, skipPreview: value});
    onMemberSettingsChange({skipPreview: value});
  }

  const renderLocalVideo = useMemo(() => {
    return <video autoPlay={true} id={'localVideo'} muted className='video local' ref={video => {
      if (video) {
        video.srcObject = localStream
      }
    }}/>
  }, [localStream]);

  return (
    <div className={'video'}>
      <div className={'half-split wrapper'}>
        <div>

          <div className={'fields full-tablet ant-form-item'}>
            <label className={'req'}>{t('Mikrofone')}</label>
            <Select size={'large'} value={selectedMicrophone} style={{width: '100%'}} onChange={onMicrophoneChange}
                    disabled={microphones.length === 0}>{microphoneOptions}</Select>
          </div>
          <div className={'fields full-tablet ant-form-item'}>
            <label className={'req'}>{t('Kamera')}</label>
            <Select size={'large'} value={selectedCamera} style={{width: '100%'}} onChange={onCameraChange}
                    disabled={cameras.length === 0}>{cameraOptions}</Select>
          </div>

          <div className={'fields ant-form-item'}>
            <label className={'req'}>{t('Speakers')}</label>
            <Select size={'large'} value={selectedSpeaker} style={{width: '100%'}} onChange={onSpeakerChange}
                    disabled={speakers.length === 0}>{speakerOptions}</Select>
          </div>

          <div className={'fields'}>
            <div className='label switch-wrapper'>
              <Switch defaultChecked={settings.skipPreview} onChange={value => onSkipPreviewOptionChange(value)}/>
              <span>{t('Überspringen Sie die Videovorschau, bevor Sie den Anruf starten')}</span>
            </div>
          </div>
        </div>

        {appSize === Dimensions.MOBILE && !showPreview &&
        <div className='video-btns'>
            <Button className={'default show-preview'} size="large" onClick={() => setShowPreview(true)}>{t('Vorschau')}</Button>
            <Button style={{visibility: 'hidden'}}/>
        </div>
        }
        {((appSize === Dimensions.MOBILE && showPreview) || appSize !== Dimensions.MOBILE) &&
        <div>
            <header>
                <h3>{t('Test Video / Audio')}</h3>
            </header>
            <div className={'horizontal-split right-settings'}>
                <div className='meeting'>
                    <div className='video-wrapper'>
                        <div className='preview-label'>{t('VORSCHAU')}</div>
                        <div className='local-video'>
                            <div className='video-aspect-ratio'>
                              {renderLocalVideo}
                            </div>
                        </div>
                        <MeetingFooter id={null} meeting={null}
                                       toggleSettingsView={doNothing} toggleDetailsView={doNothing} onEndMeeting={doNothing}
                                       onStartMeeting={doNothing}
                                       newMessagesCount={0} isProvider={true}
                                       participantPreview={false} onParticipantCancel={doNothing}/>
                    </div>
                </div>
            </div>
        </div>
        }
      </div>
      <div className='video-btns'>
        <Button type={'primary'} size="large" onClick={onSave}>{t('Speichern')}</Button>
        <Button className={'default'} size="large" onClick={onCancel}>{t('Abbrechen')}</Button>
      </div>
    </div>
  );
}
