import * as React from 'react';
import './StatisticsChartLegend.scss';

export interface StatisticChartLegendProps {
  icon: any;
  text: string;
  val: string;
}

export default function StatisticsChartLegend({ icon, text, val }: StatisticChartLegendProps) {
  return (
    <div className={'statistic-chart-legend'}>
        <span className={'legend-icon'}>{icon}</span> 
        <span className={'legend-value'}>{val}</span>
        <span className={'legend-text'}>{text}</span>
    </div>
  )
}
