import * as React from 'react';
import { useState } from 'react';
import './IntervalPickerMobile.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { DatePickerInterval } from './IntervalPicker';
import { Button } from 'antd';
import { useSwipeable } from 'react-swipeable';
import { CSSTransition } from 'react-transition-group';
import {ReactComponent as calendarIcon}  from '../../img/icons/calendar-icon.svg';
import Icon from '@ant-design/icons';
export interface DateRangePickerMobileProps {
  open?: boolean
  openChange?: (open: boolean) => void;
  value?: DatePickerInterval;
  valueChange?: (val: DatePickerInterval) => void;
}

export default function IntervalPickerMobile({open, openChange, value, valueChange}: DateRangePickerMobileProps) {

  const [selectedInterval, setSelectedInterval] = useState(value || [moment().startOf('day'), moment().endOf('day')]);
  const [selection, setSelection] = useState([true, true]);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => nextMonth(),
    onSwipedRight: () => previousMonth(),
    onSwipedDown: () => openChange(false)
  });

  const nextMonth = () => {
    setCurrentMonth(moment(currentMonth).add(1, 'month'));
  }
  const previousMonth = () => {
    setCurrentMonth(moment(currentMonth).subtract(1, 'month'));
  }

  const getWeekDays = () => {
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      weekDays.push(moment().startOf('week').add(i, 'day').format('ddd'));
    }
    return weekDays;
  }

  const isToday = (date: Moment): boolean => {
    return date.startOf('day').isSame(moment(), 'day');
  }

  function getSelectionClass(date: Moment): string {
    if (!date) {
      return '';
    }

    let classes = ''

    if (date.isSame(selectedInterval[0], 'day') || date.isSame(selectedInterval[1], 'day')) {
      classes += ' selected';
    }

    if (date.isAfter(selectedInterval[0], 'day')) {
      classes += ' left-neighbour'
    }

    if (date.isBefore(selectedInterval[1], 'day')) {
      classes += ' right-neighbour'
    }

    return classes;
  }

  const getDayMatrix: () => Moment[][] = () => {
    const weeks: Moment[][] = [];
    const start = moment(currentMonth).startOf('month');

    let week = new Array(start.weekday()).fill(null);
    for (let i = 1; i <= moment(currentMonth).endOf('month').date(); i++) {
      if (week.length >= 7) {
        weeks.push(week);
        week = [];
      }
      week.push(moment(start).add(i - 1, 'day'));
    }
    week.push(...new Array(7 - week.length).fill(null));
    weeks.push(week);

    return weeks;
  }

  const selectDate = (date: Moment) => {
    if ((selection[0] && selection[1]) || date.isBefore(selectedInterval[0])) {
      setSelectedInterval([date, date]);
      setSelection([true, false]);
    } else {
      setSelectedInterval([selectedInterval[0], date]);
      setSelection([true, true]);
      valueChange([selectedInterval[0], date]);
      openChange(false)
    }
  }

  return (
    <>
      <Button className='calendar-btn' type={'default'}><Icon component={calendarIcon} alt={''} onClick={() => openChange(true)}/></Button>
      <CSSTransition timeout={300} in={open} mountOnEnter unmountOnExit classNames={'calendar'}>
        <div className={'interval-picker-mobile'}>
          <div className={'interval-picker-backdrop'} onClick={() => openChange(false)}/>
          <div className={'interval-picker-content'} {...swipeHandlers} >
            <div>
              <div className={'header'}>
                <div className={'calendar-nav-button'}>
                  <button className={'calendar-button'} onClick={previousMonth}><LeftOutlined/></button>
                </div>
                <div className={'calendar-title'}><h2> {currentMonth.format('MMM YYYY')} </h2></div>
                <div className={'calendar-nav-button'}>
                  <button className={'calendar-button'} onClick={nextMonth}><RightOutlined/></button>
                </div>
              </div>
              <hr/>
              <div className={'body'}>
                <div className={'weekdays'}>
                  {getWeekDays().map((it, idx) => (<div key={idx}>{it}</div>))}
                </div>
                <div className={'days'}>
                  {getDayMatrix().map((week, id) => (
                    <div className={'week'} key={id}>
                      {week.map((day, index) => (
                        <div className={`day ${getSelectionClass(day)}`} key={index}>{day ? (
                          <button onClick={() => selectDate(day)}
                                  className={`calendar-button ${isToday(day) ? 'today' : ''}`}>{day.format('DD')}</button>
                        ) : ''}</div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}
