import { CustomerCareResourceApi, GeneralStatisticsDto, MeetingsStatisticsDto, MemberCountDto, MemberDto } from '../gen/client';
import { apiConfig, ops } from './ApiConfig';
import { AxiosPromise } from 'axios';

const api = new CustomerCareResourceApi(apiConfig);

const CustomerCareApi = {
	getMembers(filter: 'ALL' | 'ACTIVE' | 'INACTIVE'): AxiosPromise<MemberDto[]> {
		return api.getAllMembers(filter, ops());
	},
  
  countMembers(): AxiosPromise<MemberCountDto[]> {
    return api.countMembers1(ops());
  },

  getMeetingsStatistics(from: number, thru: number): AxiosPromise<MeetingsStatisticsDto> {
    return api.getMeetingsStatistics(from, thru, ops());
  },

  getGeneralStatistics(): AxiosPromise<GeneralStatisticsDto> {
    return api.getGeneralStatistics(ops());
  }
};

export default CustomerCareApi;
