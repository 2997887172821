import * as React from 'react';
import {useEffect, useState} from 'react';
import './Content.scss';
import Container from '../general/Container';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {handleServerError} from '../../util/ErrorHandler';
import {NotificationDto, NotificationDtoTypeEnum} from '../../gen/client';
import {useRecoilState} from 'recoil';
import TextEditor from '../general/TextEditor';
import {Button, Popconfirm, Skeleton} from 'antd';
import PracticeApi from '../../api/PracticeApi';
import {activePractice, loggedMember} from '../../state/atoms';
import Icon from '@ant-design/icons';
import {ReactComponent as backIcon} from '../../img/icons/back-icon.svg';
import {Routes} from '../../util/routes';
import {isAdminOrCustomerCare, isPracticeManager, successMessage} from "../../util/utils";
import AdminApi from "../../api/AdminApi";
import SmsEditor from "../general/SmsEditor";

export default function NotificationEdit() {
  const {t} = useTranslation();
  const {type, name} = useParams();
  const [practice] = useRecoilState(activePractice);
  const [logged] = useRecoilState(loggedMember);

  const [resetting, setResetting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null as NotificationDto);
  const [content, setContent] = useState(null as string);
  const history = useHistory();

  useEffect(() => {
    const promise = isPracticeManager(logged) ?
      PracticeApi.getNotificationByPracticeIdAndTypeAndName(practice.id, type, name) :
      AdminApi.getNotification(type, name);

    setLoading(true);
    promise.then(resp => {
      setLoading(false);
      setNotification(resp.data);
      setContent(resp.data?.content);
    }).catch(err => {
      setLoading(false);
      handleServerError(err);
    })
  }, [logged, practice.id, type, name]);

  function save() {
    const promise = isPracticeManager(logged) ? PracticeApi.updateNotification(practice.id, {...notification, content}) : AdminApi.updateNotification({...notification, content});

    promise.then(resp => {
      setNotification(resp.data);
      setContent(resp.data?.content);
      successMessage(t('Succesfully updated'));
    }).catch(handleServerError);
  }

  function reset() {
    const promise = isAdminOrCustomerCare(logged) ? AdminApi.resetAdminNotification(notification.id) : PracticeApi.resetNotification(practice.id, notification.id);

    setResetting(true);
    promise.then(resp => {
      setNotification(resp.data);
      setContent(resp.data.content);
      setResetting(false);
    }).catch(err => {
      handleServerError(err);
      setResetting(false);
    });
  }

  function goBack() {
    history.push(Routes.CMS);
  }

  return (
    <Container className={'cms-content full'}>
      <header>
        <Button type="link" className='back-btn' onClick={goBack}><Icon component={backIcon}/></Button>
        <h2>{t(notification?.name)}</h2>
      </header>

      {loading && <Skeleton loading={true}/>}

      {!loading && <div className={'content'}>
        {notification?.type === NotificationDtoTypeEnum.Email && <TextEditor onChange={setContent} notification={notification} content={notification?.content}/>}
        {notification?.type === NotificationDtoTypeEnum.Sms && <SmsEditor onChange={setContent} content={content} notification={notification}/>}
        <div className="actions">
          <Popconfirm title={t('Are you sure')} onConfirm={reset} okText={t('Yes')} cancelText={t('No')}>
            <Button size="large" className='reset-btn' loading={resetting}>{t('Reset')}</Button>
          </Popconfirm>
          <Button type={'primary'} size="large" className='save-btn' disabled={!content || content === notification?.content} onClick={save}>{t('Speichern')}</Button>
        </div>
      </div>}
    </Container>
  )
}
