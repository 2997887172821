import * as React from 'react';
import './SortArrows.scss';
import {useTranslation} from "react-i18next";
import { SearchOptionsSortEnum } from '../../../gen/client';
import { SortDirection } from '../../../util/enums';

interface SortArrowsProps {
  sortDirection: SearchOptionsSortEnum;
  onSortChange: (direction: SortDirection) => void;
}

export default function SortArrows({onSortChange, sortDirection}: SortArrowsProps) {
  const {t} = useTranslation();

  return (
      <div className='sort' onClick={() => onSortChange(sortDirection === SearchOptionsSortEnum.Asc ? SortDirection.Desc : SortDirection.Asc)}>
        <span>{t('Sortiere nach')}</span>
        <div className='sort-arrows'>
          {(sortDirection === SearchOptionsSortEnum.Default || sortDirection === SearchOptionsSortEnum.Asc) &&
            <div className='arrow up-arrow'/>}

          {(sortDirection === SearchOptionsSortEnum.Default || sortDirection === SearchOptionsSortEnum.Desc) &&
            <div className='arrow down-arrow' onClick={() => onSortChange(SortDirection.Desc)}/>}
        </div>
      </div>
  )
}
