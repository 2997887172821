import {MeetingInfo, MeetingStatus} from "./types";

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  // NOSONAR

export const SPECIAL_CHARACTERS_PHONE_REGEX = /[`!@#$%^&*_=[\]{};':"|,.<>?~]/;  // NOSONAR

export const LOWER_CASE_REGEX = /[a-zßüöä]/;

export const UPPER_CASE_REGEX = /[A-ZßÜÖÄ]/;

export const MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

export const DEFAULT_NAME = 'Max Mustermann';

export enum ActivityType {
  MEMBER = 'MEMBER',
  PARTICIPANT = 'PARTICIPANT'
}

export const LOGOUT_WINDOW_EVENTS = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"]

export const TENANT_ID = 'main';

export const TIME_REGEX = /^([0-1][0-9]|2[0-3]):?[0-5][0-9]$/;

export const DEFAULT_EMPTY_OK = false;

export const DEFAULT_PHONE_PREFIX = "+49";

export const DEFAULT_PARTICIPANT_DELETION_PERIOD = 3;

export const MIN_PASS_LENGTH = 8;

export const DOWNLOAD_IMAGE_ADDRESS = "https://www.noventi-video.de/static/media/noventi-video-logo.9cdfe200.png";

export const SPECIAL_CHARACTERS_PASSWORD_REGEX = /[`!@#$%^&*_=[\]{};':"|,.<>?~\-()]/;  // NOSONAR

export const INTERNET_SPEED_TEST_INTERVAL = 7000;

export const INTERNET_SPEED_TEST_ITERATIONS = 5;

export const MIN_INTERNET_SPEED_VALUE = 15;

export const EMPTY_MEETING_INFO = {id: '', sessions: [], status: MeetingStatus.Simulate} as MeetingInfo;

export const MEETING_DATE_FORMAT = 'DD.MM.YYYY';
export const MEETING_DATE_FORMAT_SHORT = 'DD.MM.YY';

export const HOUR_FORMAT = 'HH:mm:ss';

export const STATISTICS_DATE_FORMAT = 'YYYY-MM-DD';

export const PASSWORD_CONFIRMED_CODE = 'PASSWORD_CONFIRMED_CODE';

export const MEETING_TYPE_INFO = 'Im Gruppentermin können alle Teilnehmer sprechen und sich gegenseitig sehen. Im Modus Training sehen und hören alle Teilnehmer nur den Moderator.';

export const SUCCESS_MESSAGE_INTERVAL = 4;

export const ERROR_MESSAGE_INTERVAL = 7;

export const MAX_LAYOUT_RECALCULATION_ITERATIONS = 1000;

export const SHARE_SCREEN = 'Bildschirm teilen';

export const CONNECT_USING_LINK = 'Bitte treten Sie über den Meeting-Link bei!';

export const INTERNAL_CAMERA_KEYWORDS = ['nvidia', 'xsplit', 'perfectcam', 'mixcast', 'chromacam', 'built in', 'built-in', 'default', 'back', 'camera'];

export const LARGE_MEETING_THRESHOLD = 5;

export const VideoSettings = {
  COMMON: {
    aspectRatio: 4 / 3
  },

  LOW: {
    width: 320,
    height: 240,
    frameRate: {
      ideal: 15,
      max: 15
    }
  },

  MEDIUM_SINGLE: {
    width: 640,
    height: 480,
    frameRate: {
      ideal: 30,
      max: 30
    }
  },

  MEDIUM_GROUP: {
    width: 640,
    height: 480,
    frameRate: {
      ideal: 20,
      max: 20
    }
  },

  HIGH: {
    width: 1280,
    height: 760,
    frameRate: {
      ideal: 30,
      max: 30
    }
  }
}
