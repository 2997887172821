import * as React from 'react';
import {useEffect, useState} from 'react';
import './AddContactModal.scss';
import {useTranslation} from "react-i18next";
import {Button} from "antd";
import {AccountDto, AppSettingsDto} from "../../gen/client";
import Account from './Account';
import {DEFAULT_PHONE_PREFIX} from '../../util/constants';
import {isValidEmail, isValidNumber} from '../../util/validators';
import {notificationChannelProvided} from '../../util/utils';

interface AddContactModalProps {
  it?: AccountDto;
  defaultSettings: AppSettingsDto;
  onSave: (account: AccountDto) => void;
  onClose: () => void;
}

export default function AddContactModal({it, defaultSettings, onSave, onClose}: AddContactModalProps) {
  const {t} = useTranslation();

  const [nameErr, setNameErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const [account, setAccount] = useState(null as AccountDto);

  useEffect(() => {
    if (!!it) {
      setAccount(it);
    }
  }, [it]);

  useEffect(() => {
    if (!!defaultSettings && !it) {
      setAccount({
        smsNotification: defaultSettings?.smsNotification,
        emailNotification: defaultSettings?.emailNotification
      } as AccountDto);
    }
  }, [defaultSettings, it]);

  function validateAccountName(idx: number, value: string) {
    setNameErr(!value);
  }

  function validateAccountPhone(idx: number, value: string, required: boolean) {
    setPhoneErr(value && value !== DEFAULT_PHONE_PREFIX ? !isValidNumber(value) : required);
  }

  function validateAccountEmail(idx: number, value: string, required: boolean) {
    setEmailErr(value ? !isValidEmail(value) : required);
  }

  function onParticipantChange(updated: AccountDto) {
    const newA = {...account, ...updated};
    setAccount({...account, ...updated});
    setCanSave(notificationChannelProvided(newA) && !!newA.name);
  }

  function save() {
    if (!!account.name && canSave) {
      onSave(account);
    }
  }

  return (
    <div className='add-contact-modal'>
      <Account
        it={account}
        idx={0}
        nameErr={nameErr}
        phoneErr={phoneErr}
        emailErr={emailErr}
        onNameChange={validateAccountName}
        onPhoneChange={validateAccountPhone}
        onEmailChange={validateAccountEmail}
        onParticipantChange={(p) => onParticipantChange(p)} saveErr={false}
        modal={true}/>
      <footer className={'actions'}>
        <Button className={'default'} size="large" onClick={onClose}>{t('Abbrechen')}</Button>
        <Button type={'primary'} size="large" onClick={save} disabled={!canSave}>{t('Speichern')}</Button>
      </footer>
    </div>
  )
}
