import * as React from 'react';
import './ParticipantFeedback.scss';
import Container from "../general/Container";
import {useParams, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Rate, Button, Input, Card} from 'antd';
import {useState, useEffect} from 'react';
import Storage from "../../util/Storage";
import {CmsQuestionDto, CmsQuestionDtoTypeEnum, LightParticipantDto, CmsAnswerDto} from '../../gen/client';
import {handleServerError} from '../../util/ErrorHandler';
import PracticeApi from '../../api/PracticeApi';
import ParticipantApi from '../../api/ParticipantApi';
import Icon from '@ant-design/icons';
import {ReactComponent as checkIcon} from '../../img/icons/check-icon.svg';
import MeetingPreviewHeader from './MeetingPreviewHeader';
import StartPageFooter from '../start/StartPageFooter';
import { Routes } from '../../util/routes';
import { useRecoilValue } from 'recoil';
import { Dimensions } from '../../util/enums';
import { applicationSize } from '../../state/atoms';
import {getApplicationSizeClass, successMessage, timestamp} from '../../util/utils';
import moment from 'moment';

const {TextArea} = Input;

export default function ParticipantFeedback() {
  const {id} = useParams();
  const {t} = useTranslation();
  const history = useHistory();

  const appSize = useRecoilValue<Dimensions>(applicationSize);

  const [participant, setParticipant] = useState(null as LightParticipantDto);
  const [questions, setQuestions] = useState([] as CmsQuestionDto[]);
  const [answers] = useState([] as string[]);

  useEffect(() => {
    ParticipantApi.getByPublicId(Storage.getParticipantId())
      .then((resp) => setParticipant(resp.data))
      .catch(err => handleServerError(err))
  }, [id]);

  useEffect(() => {
    if (!participant) return
    PracticeApi.getParticipantQuestions(participant.practiceId)
    .then(resp => setQuestions(resp.data))
    .catch(handleServerError);
  }, [participant])

  function sendAnswers() {
    let list: CmsAnswerDto[] = [];
    questions.forEach((question, idx) => {
      if (!!answers[idx]){
        list.push({answer: answers[idx], createdDate: timestamp(moment()), questionId: question.id, meetingPublicId: id })
      }
    });
    if (list.length > 0) {
      ParticipantApi.addAnswers(list)
        .then(() => {
          successMessage(t('Ihre Antwort wurde erfolgreich gesendet!'));
          Storage.removeParticipantId();
          history.push(Routes.JOIN);
        })
        .catch(err => handleServerError(err))
    } else {
      Storage.removeParticipantId();
      history.push(Routes.JOIN);
    }
  }


  return (
    <Container className={`participant-feedback ${getApplicationSizeClass(appSize)}`}>
      <MeetingPreviewHeader />
      <div className='content'>
        <div>
          <div><Icon component={checkIcon} alt={''} style={{fontSize: '120px', color: '#00D2A5'}}/></div>
          <h1>{t('Der Termin ist beendet')}</h1>
          <div className='questions'>
            {
              questions?.map((question, idx) => (
                <Card key={question.id} className='card' hoverable={true}>
                  <div>{question.label}</div>
                  {question.type === CmsQuestionDtoTypeEnum.Paragraph &&
                    <div>
                      <TextArea maxLength={255} className='txt' onChange={ev => answers[idx] = ev.target.value} rows={4}/>
                    </div>
                  }
                  {question.type === CmsQuestionDtoTypeEnum.Rating &&
                    <Rate className='rate' onChange={value => answers[idx] = value.toString()}/>}
                </Card>
              ))
            }
          </div>
          <Button className='save-btn' onClick={sendAnswers}>{t('Weiter')}</Button>
        </div>
      </div>
      <StartPageFooter />
    </Container>
  )
}
