import * as React from 'react';
import './JoinPage.scss';
import { useTranslation } from "react-i18next";
import mainLogo from '../../img/noventi-video-logo.png';
import { Button, Checkbox } from 'antd';
import StartPageFooter from './StartPageFooter';
import { useParams, useHistory, NavLink } from 'react-router-dom';
import { Routes, ContentRoutes } from '../../util/routes';
import { useCallback, useEffect, useState } from 'react';
import chairIcon from '../../img/icons/chair.svg';
import MeetingApi from '../../api/MeetingApi';
import { handleServerError } from '../../util/ErrorHandler';
import { LightMeetingDto } from '../../gen/client';
import ParticipantApi from '../../api/ParticipantApi';
import { useRecoilValue } from 'recoil';
import { Dimensions } from '../../util/enums';
import { applicationSize } from '../../state/atoms';
import { getApplicationSizeClass } from '../../util/utils';
import Storage from '../../util/Storage';

export default function JoinPage() {
  const {t} = useTranslation();
  const {id} = useParams();
  const history = useHistory();
  const [acceptance, setAcceptance] = useState(false);
  const [meeting, setMeeting] = useState(null as LightMeetingDto);
  const appSize = useRecoilValue<Dimensions>(applicationSize);
  const [meetingExpired, setMeetingExpired] = useState(false);

  const preFillParticipantData = useCallback(() => {
    const query = new URLSearchParams(history.location.search);
    const participantId = query.get('publicId');
    if (participantId) {
     ParticipantApi.getByPublicId(participantId)
       .then(() => {
         Storage.setParticipantId(participantId);
       }).catch(err => {
         handleServerError(err);
         history.replace(`${Routes.JOIN}/${id}`)
        })
    }
  }, [history, id]);

  useEffect(preFillParticipantData, [preFillParticipantData]);

  useEffect(() => {
    if (id) {
      MeetingApi.getLightMeetingByPublicId(id)
      .then(resp => setMeeting(resp.data))
      .catch(err => {
        setMeeting(null);
        if (err.response?.data?.message && err.response.data.message === "Dieser Termin ist abgelaufen und nicht mehr gültig.") {
          setMeetingExpired(true);
        } else {
          handleServerError(err);
        }
      });
     }
  }, [id]);

  function startMeeting(){
    history.push(`${Routes.MEETING}/${id}/connection`);
  }

  return (
    <div className={`join-page ${getApplicationSizeClass(appSize)} ${!id || meetingExpired ? 'no-id' : ''}`}>
      <div className={'logo-wrapper'}>
        <img className={'logo'} src={mainLogo} alt={'logo'} />
      </div>
      {!id || meetingExpired? 
      <div className="content-wrapper">
        <h1>{t(meetingExpired? 'Dieser Termin ist abgelaufen und nicht mehr gültig.' : 'Sie können dieses Fenster nun schließen.')}</h1>
        <div className='chairs'>
          <img src={chairIcon} className='about-img' alt='star'/>
          <img src={chairIcon} className='about-img' alt='star'/>
          <img src={chairIcon} className='about-img' alt='star'/>
        </div>
      </div>
      : 
      <div className='data'>
        <div className='content'>
          <h2>{t('Nur noch ein Schritt!')}</h2>
          <div className='subtitle'>{t('Sie must die Geschäftsbedingungen akzeptieren')}</div>
          <div className='checkbox'>
            <Checkbox checked={acceptance} onChange={ev => setAcceptance(ev.target.checked)}>
              {t('Ich stimme den')} <NavLink target="_blank" to={ContentRoutes.TERMS}>{t('Nutzungsbedingungen')}</NavLink>
              {t(' zu')}
            </Checkbox>
            <div className='tos'>
              {t('Hier finden Sie unsere die ')} <NavLink target="_blank" to={ContentRoutes.PRIVACY}> {t('Datenschutzerklärung')}</NavLink>{'.'}
            </div>
          </div>
          <div>
            <Button type={'primary'} size="large" className='join-btn' onClick={startMeeting} disabled={!acceptance || !meeting}>{t('JOIN')}</Button>
          </div>
          <div className='chairs'>
            <img src={chairIcon} className='about-img' alt='star'/>
            <img src={chairIcon} className='about-img' alt='star'/>
            <img src={chairIcon} className='about-img' alt='star'/>
          </div>
        </div>
      </div>
      }

      <footer>
        <StartPageFooter/>
      </footer>
    </div>
  );
}
