import React, {Suspense, useEffect} from 'react';
import {Route, Switch} from "react-router-dom";
import Loader from "./components/Loader";
import Dashboard from "./components/dashboard/Dashboard";
import StartPage from "./components/start/StartPage";
import MfaPage from "./components/start/MfaPage";
import {useRecoilState, useSetRecoilState} from 'recoil';
import MemberApi from "./api/MemberApi";
import {activePractice, loggedMember} from './state/atoms';
import {handleServerError} from "./util/ErrorHandler";
import {isAppRoute, Routes} from "./util/routes";
import LoginPage from "./components/start/LoginPage";
import Meeting from "./components/appointment/Meeting";
import {ActivityWebSocket} from './websocket/ActivityWebSocket';
import {PracticeSetup} from "./components/settings/PracticeSetup";
import JoinPage from './components/start/JoinPage';
import ContentView from './components/start/ContentView';
import {ResetMemberInfoType} from './util/enums';
import PasswordForgotPage from './components/start/PasswordForgotPage';
import ResetMemberInfoPage from './components/start/ResetMemberInfoPage';
import Storage from "./util/Storage";
import CheckConnection from './components/appointment/CheckConnection';
import {getLoggedAtomFromStatus, getUnloggedAtom} from "./state/logged";
import adapter from 'webrtc-adapter';
import ActivateMfaPage from './components/start/ActivateMfaPage';
import ParticipantFeedback from './components/appointment/ParticipantFeedback';
import LayoutListener from "./components/general/LayoutListener";
import {logout, shouldLogout} from "./util/utils";
import TokenRefresher from "./components/general/TokenRefresher";
import CheckConnectionTest from './components/start/CheckConnectionTest';

export default function App() {
  const [logged, setLogged] = useRecoilState(loggedMember);
  const setPractice = useSetRecoilState(activePractice);

  useEffect(() => {
    const activityWS = new ActivityWebSocket();

    return () => {
      activityWS.close();
    }
  }, []);

  useEffect(() => {
    try {
      Storage.removeIsRefreshing();
      console.log(`Browser version: ${adapter.browserDetails.version}`);
    } catch (e) {
      console.error('Failed to init adapter');
    }
  }, []);

  useEffect(() => {
    MemberApi.getStatus().then(resp => {
      setLogged(getLoggedAtomFromStatus(resp.data));
      if (resp.data.authenticated) {
        setPractice({
          id: resp.data.member.practice.id,
          name: resp.data.member.practice.name
        });
      }

      if (!resp.data.authenticated && shouldLogout()) {
        logout();
        setLogged(getUnloggedAtom());
        if (isAppRoute(window.location.pathname)) {
          window.location.href = '/';
        }
      }

      if (!Storage.getMachineId()) {
        Storage.setMachineId(resp.data.uuid);
      }

    }).catch(e => {
      handleServerError(e);
      setLogged(getUnloggedAtom());
    });
  }, [setPractice, setLogged]);

  return (
    <Suspense fallback={<Loader/>}>
      <LayoutListener/>
      <TokenRefresher/>
      <Switch>
        <Route exact path={Routes.REGISTER}><StartPage/></Route>
        <Route exact path={Routes.LOGIN}><LoginPage/></Route>
        <Route exact path={Routes.LOGIN_CONFIRM}><MfaPage/></Route>
        <Route exact path={Routes.ACTIVATE_MFA}><ActivateMfaPage/></Route>
        <Route exact path={Routes.CONNECTION_TEST}><CheckConnectionTest/></Route>
        <Route path={`${Routes.ACTIVATE}/:code`}><StartPage/></Route>
        <Route path={`${Routes.MEETING}/:id/connection`}><CheckConnection/></Route>
        <Route path={`${Routes.MEETING}/:id/preview`}><Meeting preview/></Route>
        <Route path={`${Routes.MEETING}/feedback/:id`}><ParticipantFeedback/></Route>
        <Route path={`${Routes.MEETING}/:id`}><Meeting/></Route>
        <Route path={`${Routes.JOIN}/:id?`}><JoinPage/></Route>
        <Route path={`${Routes.CONTENT}/:type`}><ContentView/></Route>
        <Route path={Routes.PASSWORD_FORGOT}><PasswordForgotPage/></Route>
        <Route path={`${Routes.RESET_PASSWORD}/:code`}><ResetMemberInfoPage type={ResetMemberInfoType.PASSWORD}/></Route>
        <Route path={`${Routes.RESET_MFA}/:code`}><ResetMemberInfoPage type={ResetMemberInfoType.MFA_PHONE}/></Route>
        <Route exact path="/"><LoginPage/></Route>

        {logged.logged && <Route path={Routes.APP}><Dashboard/></Route>}
        {logged.logged && <Route path={Routes.SETUP}><PracticeSetup/></Route>}
      </Switch>
    </Suspense>
  );
}
