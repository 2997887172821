import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './ContactsHeader.scss';
import {Checkbox, Button} from "antd";
import {useTranslation} from "react-i18next";
import {ViewMode, SortDirection, Dimensions} from '../../util/enums';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {ReactComponent as gridIcon} from "../../img/icons/grid-icon.svg";
import {ReactComponent as listIcon} from "../../img/icons/list-icon.svg";
import ContactFilters from './ContactFilters';
import {MemberCountDtoKeyEnum, SearchOptionsSortEnum} from '../../gen/client';
import Icon from '@ant-design/icons';
import {isCMSRoute, isContactRoute, isGroupRoute, isMemberRoute, isSearchRoute, isStatisticRoute, Routes} from '../../util/routes';
import {useHistory, useLocation} from 'react-router-dom';
import CloseSVG from '../general/svg/CloseSVG';
import {useRecoilState, useRecoilValue} from "recoil";
import {applicationSize, loggedMember} from "../../state/atoms";
import {isProvider} from "../../util/utils";
import {ReactComponent as iconPlus} from '../../img/icons/icon-plus.svg';
import SortArrows from '../general/sort/SortArrows';

interface ContactsHeaderProps {
  onToggleAll: (ev: CheckboxChangeEvent) => void;
  onChangeViewMode: (viewMode: ViewMode) => void;
  onSortChange: (direction: SortDirection) => void;
  onFilterChange: (filter: MemberCountDtoKeyEnum) => void;
  onClearSearch: () => void;
  shortView: boolean;
  filter: MemberCountDtoKeyEnum;
  isAdmin: boolean;
  emptyContactList: boolean;
  memberView: boolean;
  membersCount: number;
  updated: boolean;
}

export default function ContactsHeader({onToggleAll, onChangeViewMode, onSortChange, onFilterChange, onClearSearch, shortView, filter, isAdmin, emptyContactList, memberView, membersCount, updated}: ContactsHeaderProps) {
  const {t} = useTranslation();
  const history = useHistory();
  const appSize = useRecoilValue(applicationSize);

  const [logged] = useRecoilState(loggedMember);
  const [showViewMode, setShowViewMode] = React.useState(true);
  const [viewMode, setViewMode] = useState(ViewMode.LIST);
  const [searchString, setSearchString] = useState("");
  const [activeSearchRoute, setSearchRoute] = useState(false);

  let location = useLocation();

  const [sortDirection, setSortDirection] = useState(SearchOptionsSortEnum.Default);
  
  useEffect(() => {
    if (isSearchRoute(history.location.pathname)) {
      setSearchRoute(true);
      const query = new URLSearchParams(history.location.search);
      setSearchString(query.get('key'));
    } else {
      setSearchRoute(false);
    }
  }, [history.location.pathname, history.location.search]);


  function changeViewMode(mode: ViewMode) {
    setViewMode(mode);
    onChangeViewMode(mode);
  }

  function clearSearch() {
    history.push(getBackRoute(history.location.pathname));
    onClearSearch();
  }

  function getBackRoute(pathName: string): Routes {
    if (isGroupRoute(pathName)) return Routes.GROUPS;
    if (isContactRoute(pathName)) return Routes.CONTACTS;
    return Routes.MEMBERS;
  }

  function getSearchTitle(): string {
    return `${t('Suche nach')}: ${searchString}`
  }

  const initViewMode = useCallback(() => {
    if (memberView) return;
    setViewMode(window.innerWidth <= 768 ? ViewMode.GRID : ViewMode.LIST);
    onChangeViewMode(window.innerWidth <= 768 ? ViewMode.GRID : ViewMode.LIST);
    window.innerWidth <= 768 ? setShowViewMode(false) : setShowViewMode(true);
  }, [memberView, onChangeViewMode]);

  useEffect(() => {
    initViewMode();
    window.addEventListener('resize', initViewMode);
    return () => {
      window.removeEventListener('resize', initViewMode);
    }
  }, [initViewMode]);

  function getAdminOrCustomerCareTitle(): string{
    return logged.isAdmin ? `${t('Nutzer')} ${t('(Administratoren, Kundenbetreuer, Inhaber und Mitarbeiter)')}` : `${t('Nutzer')} (${t('PRACTICE_MANAGER')})`
  }

  function getTitle()  {
    return isProvider(logged) ? (isGroupRoute(history.location.pathname) ? t('Gruppen') : t('Kontakte')) : getAdminOrCustomerCareTitle();
  }

  const actionButton = useMemo(() => {
    const pathname = location.pathname;
    if (isStatisticRoute(pathname) || isCMSRoute(pathname)) {
      return null;
    }
    if (!isProvider(logged) || isMemberRoute(history.location.pathname)) {
      return <Button className={'add-new'} type={'primary'} size={'large'} onClick={() => history.push(Routes.MEMBERS_NEW)}>
                <Icon component={iconPlus}/>
              </Button>;
    } else if (isContactRoute(history.location.pathname)) {
      return <Button className={'add-new'} type={'primary'} size={'large'} onClick={() => history.push(Routes.CONTACTS_NEW)}>
                <Icon component={iconPlus}/>
              </Button>;
    } else if (isGroupRoute(history.location.pathname)) {
      return <Button className={'add-new'} type={'primary'} size={'large'} onClick={() => history.push(Routes.GROUPS_NEW)}>
                <Icon component={iconPlus}/>
              </Button>;
    }
  }, [location, history, logged]);

  function onSortDirectionChange(direction: SortDirection) {
    setSortDirection(direction === SortDirection.Asc ? SearchOptionsSortEnum.Asc : SearchOptionsSortEnum.Desc);
    onSortChange(direction);
  }

  return (
    <header>
      {appSize === Dimensions.MOBILE && !shortView && actionButton}
      <Checkbox onChange={onToggleAll} disabled={emptyContactList && !activeSearchRoute}/>
      <h2 className={`${activeSearchRoute ? 'no-mg' : ''}`}>
        {(emptyContactList && !activeSearchRoute) ? '0 ' : ''}
        {!activeSearchRoute ? getTitle() : getSearchTitle()}
      </h2>
      {activeSearchRoute &&
      <Button type={'link'} size={'large'} className='close-btn' onClick={clearSearch}>
        <CloseSVG/>
      </Button>
      }
      {(isAdmin && !activeSearchRoute && !isContactRoute(history.location.pathname)) &&
      <ContactFilters onChange={onFilterChange} active={filter} membersCount={membersCount} updated={updated}/>}
      {!shortView &&
      <div className='buttons'>
        <SortArrows sortDirection={sortDirection} onSortChange={onSortDirectionChange}/>
        {showViewMode && !isAdmin ?
          <div className='view-mode-wrapper'>
            <Button size={'large'} className={`icon-only ${viewMode === ViewMode.LIST ? 'active-btn' : ''}`} onClick={() => changeViewMode(ViewMode.LIST)}>
              <Icon component={listIcon} alt={''}/>
            </Button>
            <Button size={'large'} className={`icon-only ${viewMode === ViewMode.GRID ? 'active-btn' : ''}`} onClick={() => changeViewMode(ViewMode.GRID)}>
              <Icon component={gridIcon} alt={''}/>
            </Button>
          </div> : ''
        }
      </div>}
    </header>
  )
}
