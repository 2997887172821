import * as React from 'react';
import './SmsEditor.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {useEffect, useRef, useState} from "react";
import {activePractice, loggedMember} from "../../state/atoms";
import {useRecoilState} from "recoil";
import {getKeyWords, getSmsLengthDiff, getSmsPreview, SmsTag, SmsTags} from "../../service/ContentService";
import {NotificationDto} from "../../gen/client";
import EditorKeyWords from "./EditorKeyWords";

interface SmsEditorProps {
  content: string;
  notification: NotificationDto;
  onChange: (content: string) => void;
}

export default function SmsEditor({content, notification, onChange}: SmsEditorProps) {
  const [practice] = useRecoilState(activePractice);
  const [logged] = useRecoilState(loggedMember);

  const textarea = useRef(null);

  const [tags, setTags] = useState([] as SmsTag[]);
  const [preview, setPreview] = useState('');
  const [length, setLength] = useState(0);
  const [diff, setDiff] = useState(0);
  const [words, setWords] = useState([] as string[]);

  useEffect(() => {
    setTags([...SmsTags, new SmsTag('praxis', practice.name), new SmsTag('mitarbeiter', logged.name)]);
  }, [logged, practice]);

  useEffect(() => {
    const val = getSmsPreview(content, tags);
    setPreview(val);
    setLength(val.length);
    setDiff(getSmsLengthDiff(content, tags));
    setWords(getKeyWords(notification.type, notification.name));
  }, [tags, notification, content]);

  function insertWord(word: string) {
    const pos = textarea.current.selectionStart;
    const final = '[(${' + word + '})]';
    onChange(content.slice(0, pos) + final + content.slice(pos));
    setTimeout(() => {
      const newPos = pos + final.length;
      textarea.current.setSelectionRange(newPos, newPos);
    }, 0);
  }

  return (
    <div className={'sms-editor'}>
      {words.length > 0 && <EditorKeyWords words={words} onClick={insertWord}/>}

      <div className={'data'}>
        <div className={'area'}>
          <textarea ref={textarea} value={content} onChange={ev => onChange(ev.target.value)} maxLength={320 + diff}/>
          <div className={'info'}>
            <span className={'count'}>{length} / 320</span>
          </div>
        </div>
        <div className={'preview'}>{preview}</div>
      </div>
    </div>
  );
}
