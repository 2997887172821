import * as React from 'react';
import {useEffect, useState} from 'react';
import './StatisticsFeedbackList.scss';
import Container from '../general/Container';
import {DatePickerInterval} from '../general/IntervalPicker';
import {useTranslation} from 'react-i18next';
import {Button, Card, Collapse, Skeleton} from 'antd';
import {handleServerError} from '../../util/ErrorHandler';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {statisticsAppointmentList, statisticsDateInterval} from './state/statistics.state';
import {MeetingDto, MemberAnswerGroupDto, ParticipantAnswerGroupDto, SearchOptionsSortEnum} from '../../gen/client';
import {getApplicationSizeClass, getFromDateFromInterval, getToDateFromInterval, groupByMember, isAdminOrCustomerCare, sortAscendingTimestamps, sortDescendingTimestamps} from '../../util/utils';
import {activePractice, applicationSize, loggedMember} from '../../state/atoms';
import StatisticsHeader from './StatisticsHeader';
import PracticeApi from "../../api/PracticeApi";
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {ReactComponent as backIcon} from '../../img/icons/back-icon.svg';
import { Routes } from '../../util/routes';
import ResponseLine from '../cms/ResponseLine';
import { SortDirection } from '../../util/enums';
import TenantApi from '../../api/TenantApi';
import { TENANT_ID } from '../../util/constants';
import MemberAnswersLines from '../cms/MemberAnswersLines';
import SortArrows from '../general/sort/SortArrows';

const { Panel } = Collapse;

export default function StatisticsFeedbackList() {
  const {t} = useTranslation();
  const history = useHistory();

  const appSize = useRecoilValue(applicationSize);
  const [practice] = useRecoilState(activePractice);
  const [logged] = useRecoilState(loggedMember);
  const [loading, setLoading] = useState(false);

  const [interval, setInterval] = useRecoilState<DatePickerInterval>(statisticsDateInterval);
  const setMeetings = useSetRecoilState<MeetingDto[]>(statisticsAppointmentList);

  const [participantFeedbacks, setParticipantFeedbacks] = useState({} as { [key: string]: Array<ParticipantAnswerGroupDto>; });
  const [sortDirection, setSortDirection] = useState(SearchOptionsSortEnum.Default);

  const [memberAnswerGroups, setMemberAnswerGroups] = useState({} as { [key: string]: Array<MemberAnswerGroupDto>; });

  useEffect(() => {
    if (isAdminOrCustomerCare(logged)) return 
    PracticeApi.getMeetingsForProvider(practice.id, logged.id, getFromDateFromInterval(interval), getToDateFromInterval(interval), false)
      .then((res) => setMeetings(res.data))
      .catch(handleServerError);
  }, [logged, practice.id, interval, setMeetings])

  useEffect(() => {
    if (isAdminOrCustomerCare(logged)) return 
    setLoading(true);
    PracticeApi.getParticipantFeedback(practice.id, getFromDateFromInterval(interval), getToDateFromInterval(interval))
      .then(resp => {
        setParticipantFeedbacks(resp.data);
        setLoading(false);
      })
      .catch(err=> {
        handleServerError(err);
        setLoading(false);
      });
  }, [practice.id, interval, logged]);

  useEffect(() => {
    if (!isAdminOrCustomerCare(logged)) return
    setLoading(true);
    TenantApi.getAdminOrCustomerCareFeedbacks(TENANT_ID)
      .then(resp => {
        setMemberAnswerGroups(resp.data);
        setLoading(false);
      })
      .catch(err=> {
        handleServerError(err);
        setLoading(false);
      });
  }, [logged]);

  function goBack() {
    history.push(Routes.STATISTICS);
  }

  function getParticipantFeedbacks() {
    return <div>
      {
        Object.keys(participantFeedbacks)
          .sort((key1, key2) => sortDirection === SearchOptionsSortEnum.Asc ? sortAscendingTimestamps(key1, key2) : sortDescendingTimestamps(key1, key2))
          .map(key => {
            const feedbacks = participantFeedbacks[key];
            return ( <div key={key}>{<div key={key}>{getFeedbacksLines(feedbacks, key)}</div>}</div>)
          }) 
      }
    </div>
  }

  function groupByParticipant(feedbacks: ParticipantAnswerGroupDto[]): { [key: string]: Array<ParticipantAnswerGroupDto>; } {
    return feedbacks
      .reduce((acc: any, feedback: ParticipantAnswerGroupDto, idx: number) => {
        let id = idx
        acc[id] = acc[id] || [];
        acc[id].push(feedback);
        return acc;
      }, []);
  }

  function getFeedbacksLines(list: ParticipantAnswerGroupDto[], date: string) {
    const grouped = groupByParticipant(list);
    return <div>
      {Object.keys(grouped).map((key, idx) => {
        return (
          <Collapse accordion expandIconPosition='right' ghost key={idx}>
            <Panel key={key} header={`${date}`}>
              {grouped[key]?.map(feedback => {
                return (<ResponseLine answer={feedback.answer} question={feedback.question} key={feedback.answer.id}/>)
              })}
            </Panel>
          </Collapse>
        )
      })}
    </div>
  }

  function getMemberAnswerGroupsLines() {
    return <div>
      {
        Object.keys(memberAnswerGroups)
          .sort((key1, key2) => sortDirection === SearchOptionsSortEnum.Asc ? sortAscendingTimestamps(key1, key2) : sortDescendingTimestamps(key1, key2))
          .map(key => {
            const answerGroups = memberAnswerGroups[key];
            return ( <div key={key}>{<div key={key}>{getMemberAnswersLines(answerGroups, key)}</div>}</div>)
          })
      }
    </div>
  }

  function getMemberAnswersLines(list: MemberAnswerGroupDto[], date: string) {
    const grouped = groupByMember(list);
    return <div>
      { Object.keys(grouped).map(key => 
        { return ( <MemberAnswersLines key={key} grouped={grouped} currentKey={key} date={date}/> )}
      )}
    </div>
  }

  function onSortChange(direction: SortDirection) {
    setSortDirection(direction === SortDirection.Asc ? SearchOptionsSortEnum.Asc : SearchOptionsSortEnum.Desc);
  }
  
  return (
    <Container className={`statistics-feedbacks full ${getApplicationSizeClass(appSize)}`}>
      <header className='hdr'>
        <Button type="link" className='back-btn' onClick={goBack}><Icon component={backIcon}/></Button>
        <h2>{t('Feedback Statistik')}</h2>
        {!isAdminOrCustomerCare(logged) &&
          <StatisticsHeader title={t('Feedback Statistik')} interval={interval} setInterval={setInterval}/>
        }
        <SortArrows sortDirection={sortDirection} onSortChange={onSortChange}/>
      </header>
      {loading ? 
        <Skeleton loading={true}/>
        :
        <Card className={(Object.keys(participantFeedbacks).length > 0 || Object.keys(memberAnswerGroups).length > 0) ? 'statistics-feedback-body' : 'statistics-feedback-body empty'}>
          <div className='cms-details'>
          {Object.keys(participantFeedbacks).length > 0  || Object.keys(memberAnswerGroups).length > 0 ?
            <div>
              {
                isAdminOrCustomerCare(logged) ? getMemberAnswerGroupsLines() : getParticipantFeedbacks()
              }
            </div>
            : 
            <h1>{t('Keine Übereinstimmung gefunden.')}</h1>
          }
          </div>
        </Card>
      }
    </Container>
  )
}
