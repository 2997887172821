import React from 'react';
import './Settings.scss';
import {useTranslation} from "react-i18next";
import Container from "../general/Container";
import {Tabs} from "antd";
import DataProtectionManagement from "./DataProtectionManagement";
import PracticeData from "./PracticeData";

const {TabPane} = Tabs;

export default function PracticeSettings() {
  const {t} = useTranslation();

  return (
    <Container className={'settings full'}>
      <div className={'content'}>

        <Tabs defaultActiveKey="1">
          <TabPane tab={t('Praxis Daten')} key="1" className='tab-content'>
            <PracticeData />
          </TabPane>
          <TabPane tab={t('DSGVO')} key="2" className='tab-content'>
            <DataProtectionManagement />
          </TabPane>
        </Tabs>
      </div>
    </Container>
  );
}
