import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Select, Button } from 'antd';
import { useEffect, useState } from 'react';
import { CmsQuestionDtoTypeEnum, CmsQuestionDto, CmsQuestionOptionDto } from '../../gen/client';

interface QuestionFieldsProps {
  it?: CmsQuestionDto;
  onSave: (question: CmsQuestionDto) => void;
  onCancel: () => void;
}

export default function QuestionFields({it, onSave, onCancel}: QuestionFieldsProps) {
  const {t} = useTranslation();
  const [options, setOptions] = useState([{}] as CmsQuestionOptionDto[]);
  const [question, setQuestion] = useState({} as CmsQuestionDto);

  useEffect(() => {
    if (!!it.id) { setQuestion(it) }
  }, [it]);

  function addOption() {
    setOptions([...options, {} as CmsQuestionOptionDto]);
  }

  function save(){
    if (question && question.type === CmsQuestionDtoTypeEnum.MultipleChoice) {
      question.options = options;
    }
    question.userTip = "";
    onSave(question)
  }

  return (
    <div className='question-form'>
      <div className='ant-form-item'>
        <label>{t('Label')}</label>
        <Input size={'large'} name={'label'} type={'text'} value={question?.label} onChange={ev => setQuestion({...question, label: ev.target.value})} key={question?.id}/>
      </div>
      <div className='ant-form-item'>
        <label>{t('Typ')}</label>
        <Select value={question?.type} size={'large'} onChange={value => setQuestion({...question, type: value})} key={question?.id} disabled={question?.cms?.name === "After register questions"}>
          <Select.Option value={CmsQuestionDtoTypeEnum.Paragraph}>{t('Paragraph')}</Select.Option>
          <Select.Option value={CmsQuestionDtoTypeEnum.Rating}>{t('Rating')}</Select.Option>
        </Select>
      </div>
      {question?.type === CmsQuestionDtoTypeEnum.MultipleChoice &&
        <div>
          <div>
          {
            options.map((opt, idx) =>  
              <div key={idx} className='ant-form-item'>
                <label>{t('Option')}{idx + 1}</label>
                <Input defaultValue={opt.option} size={'large'} type={'text'} onChange={ev => opt.option = ev.target.value} />
              </div> )
          }
          </div>
          <Button type={'dashed'} className={'dashed-btn'} size="large" onClick={addOption}>
          {t('andere Frage hinzufügen') }
          </Button></div>
        }
      <div className='btns'>
        <Button type={'primary'} size="large" className='btn save-btn' onClick={save} disabled={!question.label || !question.type}>{t('Speichern')}</Button>
        <Button type={'primary'} size="large" className='btn cancel-btn' onClick={onCancel}>{t('Abbrechen')}</Button>
      </div>
    </div>
  )
}
