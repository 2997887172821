/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountDto
 */
export interface AccountDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    gender?: AccountDtoGenderEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    smsNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    emailNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    notificationAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    notifyBeforeMeeting?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    agreementDate?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    createdDate?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    publicId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    removed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    practiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    providerId?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    lastActivityDate?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountDtoGenderEnum {
    NotSpecified = 'NOT_SPECIFIED',
    Male = 'MALE',
    Female = 'FEMALE'
}

/**
 * 
 * @export
 * @interface AccountGroupDto
 */
export interface AccountGroupDto {
    /**
     * 
     * @type {string}
     * @memberof AccountGroupDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountGroupDto
     */
    name?: string;
    /**
     * 
     * @type {Array<AccountDto>}
     * @memberof AccountGroupDto
     */
    accounts?: Array<AccountDto>;
}
/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    city?: string;
}
/**
 * 
 * @export
 * @interface AppError
 */
export interface AppError {
    /**
     * 
     * @type {string}
     * @memberof AppError
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof AppError
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof AppError
     */
    route?: string;
    /**
     * 
     * @type {string}
     * @memberof AppError
     */
    stackTrace?: string;
    /**
     * 
     * @type {string}
     * @memberof AppError
     */
    userAgent?: string;
    /**
     * 
     * @type {string}
     * @memberof AppError
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof AppError
     */
    appVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof AppError
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface AppErrorDto
 */
export interface AppErrorDto {
    /**
     * 
     * @type {string}
     * @memberof AppErrorDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof AppErrorDto
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof AppErrorDto
     */
    route?: string;
    /**
     * 
     * @type {string}
     * @memberof AppErrorDto
     */
    stackTrace?: string;
    /**
     * 
     * @type {string}
     * @memberof AppErrorDto
     */
    userAgent?: string;
    /**
     * 
     * @type {number}
     * @memberof AppErrorDto
     */
    date?: number;
    /**
     * 
     * @type {string}
     * @memberof AppErrorDto
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface AppSettingsDto
 */
export interface AppSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof AppSettingsDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSettingsDto
     */
    videoQuality?: AppSettingsDtoVideoQualityEnum;
    /**
     * 
     * @type {number}
     * @memberof AppSettingsDto
     */
    defaultDuration?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AppSettingsDto
     */
    emailNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppSettingsDto
     */
    smsNotification?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AppSettingsDto
     */
    participantDeletionPeriod?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AppSettingsDtoVideoQualityEnum {
    High = 'HIGH',
    Low = 'LOW'
}

/**
 * 
 * @export
 * @interface AppointmentDto
 */
export interface AppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    endTime?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentDto
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    information?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    videoAppointmentId?: string;
}
/**
 * 
 * @export
 * @interface AppointmentOraDto
 */
export interface AppointmentOraDto {
    /**
     * 
     * @type {PatientDto}
     * @memberof AppointmentOraDto
     */
    patientDto?: PatientDto;
    /**
     * 
     * @type {AppointmentDto}
     * @memberof AppointmentOraDto
     */
    appointmentDto?: AppointmentDto;
    /**
     * 
     * @type {TreatmentDto}
     * @memberof AppointmentOraDto
     */
    treatmentDto?: TreatmentDto;
    /**
     * 
     * @type {string}
     * @memberof AppointmentOraDto
     */
    memberEmail?: string;
}
/**
 * 
 * @export
 * @interface AppointmentOraInfoDto
 */
export interface AppointmentOraInfoDto {
    /**
     * 
     * @type {string}
     * @memberof AppointmentOraInfoDto
     */
    memberEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentOraInfoDto
     */
    videoAppointmentId?: string;
}
/**
 * 
 * @export
 * @interface AuthResponseDto
 */
export interface AuthResponseDto {
    /**
     * 
     * @type {MemberDto}
     * @memberof AuthResponseDto
     */
    member?: MemberDto;
    /**
     * 
     * @type {string}
     * @memberof AuthResponseDto
     */
    token?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthResponseDto
     */
    codeSent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthResponseDto
     */
    device?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthResponseDto
     */
    needsSetup?: boolean;
}
/**
 * 
 * @export
 * @interface CancelMeetingDto
 */
export interface CancelMeetingDto {
    /**
     * 
     * @type {MeetingDto}
     * @memberof CancelMeetingDto
     */
    meeting?: MeetingDto;
    /**
     * 
     * @type {string}
     * @memberof CancelMeetingDto
     */
    reason?: string;
}
/**
 * 
 * @export
 * @interface CmsAnswerDto
 */
export interface CmsAnswerDto {
    /**
     * 
     * @type {string}
     * @memberof CmsAnswerDto
     */
    answer?: string;
    /**
     * 
     * @type {string}
     * @memberof CmsAnswerDto
     */
    questionId?: string;
    /**
     * 
     * @type {string}
     * @memberof CmsAnswerDto
     */
    memberId?: string;
    /**
     * 
     * @type {number}
     * @memberof CmsAnswerDto
     */
    createdDate?: number;
    /**
     * 
     * @type {string}
     * @memberof CmsAnswerDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CmsAnswerDto
     */
    meetingPublicId?: string;
}
/**
 * 
 * @export
 * @interface CmsDto
 */
export interface CmsDto {
    /**
     * 
     * @type {number}
     * @memberof CmsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CmsDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CmsDto
     */
    access?: CmsDtoAccessEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CmsDtoAccessEnum {
    Public = 'PUBLIC',
    Private = 'PRIVATE'
}

/**
 * 
 * @export
 * @interface CmsQuestionDto
 */
export interface CmsQuestionDto {
    /**
     * 
     * @type {string}
     * @memberof CmsQuestionDto
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof CmsQuestionDto
     */
    type?: CmsQuestionDtoTypeEnum;
    /**
     * 
     * @type {CmsDto}
     * @memberof CmsQuestionDto
     */
    cms?: CmsDto;
    /**
     * 
     * @type {Array<CmsAnswerDto>}
     * @memberof CmsQuestionDto
     */
    answers?: Array<CmsAnswerDto>;
    /**
     * 
     * @type {Array<CmsQuestionOptionDto>}
     * @memberof CmsQuestionDto
     */
    options?: Array<CmsQuestionOptionDto>;
    /**
     * 
     * @type {boolean}
     * @memberof CmsQuestionDto
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CmsQuestionDto
     */
    userTip?: string;
    /**
     * 
     * @type {string}
     * @memberof CmsQuestionDto
     */
    practiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CmsQuestionDto
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof CmsQuestionDto
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CmsQuestionDtoTypeEnum {
    ShortAnswer = 'SHORT_ANSWER',
    Paragraph = 'PARAGRAPH',
    Dropdown = 'DROPDOWN',
    Checkbox = 'CHECKBOX',
    MultipleChoice = 'MULTIPLE_CHOICE',
    Rating = 'RATING'
}

/**
 * 
 * @export
 * @interface CmsQuestionOptionDto
 */
export interface CmsQuestionOptionDto {
    /**
     * 
     * @type {string}
     * @memberof CmsQuestionOptionDto
     */
    option?: string;
    /**
     * 
     * @type {string}
     * @memberof CmsQuestionOptionDto
     */
    questionId?: string;
    /**
     * 
     * @type {string}
     * @memberof CmsQuestionOptionDto
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface ContentDto
 */
export interface ContentDto {
    /**
     * 
     * @type {string}
     * @memberof ContentDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentDto
     */
    type?: ContentDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ContentDto
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentDto
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ContentDtoTypeEnum {
    Homepage = 'HOMEPAGE',
    Register = 'REGISTER',
    Imprint = 'IMPRINT',
    DataPrivacy = 'DATA_PRIVACY',
    Terms = 'TERMS',
    Faq = 'FAQ',
    Contact = 'CONTACT',
    Help = 'HELP',
    DataPrivacyProvider = 'DATA_PRIVACY_PROVIDER'
}

/**
 * 
 * @export
 * @interface CreateMeetingDto
 */
export interface CreateMeetingDto {
    /**
     * 
     * @type {number}
     * @memberof CreateMeetingDto
     */
    startDate?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateMeetingDto
     */
    secure?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateMeetingDto
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateMeetingDto
     */
    information?: string;
    /**
     * 
     * @type {Array<ParticipantDto>}
     * @memberof CreateMeetingDto
     */
    participants?: Array<ParticipantDto>;
    /**
     * 
     * @type {number}
     * @memberof CreateMeetingDto
     */
    startTime?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateMeetingDto
     */
    type?: CreateMeetingDtoTypeEnum;
    /**
     * 
     * @type {Array<ParticipantGroupDto>}
     * @memberof CreateMeetingDto
     */
    groups?: Array<ParticipantGroupDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateMeetingDtoTypeEnum {
    Single = 'SINGLE',
    Multicast = 'MULTICAST',
    Group = 'GROUP'
}

/**
 * 
 * @export
 * @interface GeneralStatisticsDto
 */
export interface GeneralStatisticsDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralStatisticsDto
     */
    totalAccounts?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStatisticsDto
     */
    totalCallsDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStatisticsDto
     */
    totalCallsScheduled?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStatisticsDto
     */
    totalCallsPerformed?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStatisticsDto
     */
    totalAnswersCount?: number;
}
/**
 * 
 * @export
 * @interface Interval
 */
export interface Interval {
    /**
     * 
     * @type {number}
     * @memberof Interval
     */
    from?: number;
    /**
     * 
     * @type {number}
     * @memberof Interval
     */
    thru?: number;
}
/**
 * 
 * @export
 * @interface LicenseCountDto
 */
export interface LicenseCountDto {
    /**
     * 
     * @type {number}
     * @memberof LicenseCountDto
     */
    all?: number;
    /**
     * 
     * @type {number}
     * @memberof LicenseCountDto
     */
    inUse?: number;
}
/**
 * 
 * @export
 * @interface LightCmsAnswerDto
 */
export interface LightCmsAnswerDto {
    /**
     * 
     * @type {string}
     * @memberof LightCmsAnswerDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LightCmsAnswerDto
     */
    answer?: string;
    /**
     * 
     * @type {number}
     * @memberof LightCmsAnswerDto
     */
    createdDate?: number;
    /**
     * 
     * @type {string}
     * @memberof LightCmsAnswerDto
     */
    participantName?: string;
    /**
     * 
     * @type {string}
     * @memberof LightCmsAnswerDto
     */
    memberName?: string;
    /**
     * 
     * @type {string}
     * @memberof LightCmsAnswerDto
     */
    participantPublicId?: string;
    /**
     * 
     * @type {string}
     * @memberof LightCmsAnswerDto
     */
    memberPublicId?: string;
}
/**
 * 
 * @export
 * @interface LightCmsQuestionDto
 */
export interface LightCmsQuestionDto {
    /**
     * 
     * @type {string}
     * @memberof LightCmsQuestionDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LightCmsQuestionDto
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof LightCmsQuestionDto
     */
    type?: LightCmsQuestionDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum LightCmsQuestionDtoTypeEnum {
    ShortAnswer = 'SHORT_ANSWER',
    Paragraph = 'PARAGRAPH',
    Dropdown = 'DROPDOWN',
    Checkbox = 'CHECKBOX',
    MultipleChoice = 'MULTIPLE_CHOICE',
    Rating = 'RATING'
}

/**
 * 
 * @export
 * @interface LightContentDto
 */
export interface LightContentDto {
    /**
     * 
     * @type {string}
     * @memberof LightContentDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LightContentDto
     */
    content?: string;
}
/**
 * 
 * @export
 * @interface LightMeetingDto
 */
export interface LightMeetingDto {
    /**
     * 
     * @type {string}
     * @memberof LightMeetingDto
     */
    publicId?: string;
    /**
     * 
     * @type {number}
     * @memberof LightMeetingDto
     */
    startDate?: number;
    /**
     * 
     * @type {number}
     * @memberof LightMeetingDto
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof LightMeetingDto
     */
    information?: string;
    /**
     * 
     * @type {Array<ParticipantDto>}
     * @memberof LightMeetingDto
     */
    participants?: Array<ParticipantDto>;
    /**
     * 
     * @type {MeetingProviderDto}
     * @memberof LightMeetingDto
     */
    provider?: MeetingProviderDto;
    /**
     * 
     * @type {number}
     * @memberof LightMeetingDto
     */
    startTime?: number;
    /**
     * 
     * @type {string}
     * @memberof LightMeetingDto
     */
    type?: LightMeetingDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LightMeetingDto
     */
    anonymized?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum LightMeetingDtoTypeEnum {
    Single = 'SINGLE',
    Multicast = 'MULTICAST',
    Group = 'GROUP'
}

/**
 * 
 * @export
 * @interface LightMemberDto
 */
export interface LightMemberDto {
    /**
     * 
     * @type {string}
     * @memberof LightMemberDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LightMemberDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof LightMemberDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof LightMemberDto
     */
    practiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof LightMemberDto
     */
    practiceName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LightMemberDto
     */
    roles?: Array<LightMemberDtoRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof LightMemberDto
     */
    publicId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LightMemberDtoRolesEnum {
    Admin = 'ADMIN',
    PracticeManager = 'PRACTICE_MANAGER',
    Provider = 'PROVIDER',
    CustomerCare = 'CUSTOMER_CARE',
    Participant = 'PARTICIPANT'
}

/**
 * 
 * @export
 * @interface LightParticipantDto
 */
export interface LightParticipantDto {
    /**
     * 
     * @type {string}
     * @memberof LightParticipantDto
     */
    publicId?: string;
    /**
     * 
     * @type {string}
     * @memberof LightParticipantDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LightParticipantDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof LightParticipantDto
     */
    practiceId?: string;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    device?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginDto
     */
    rememberDevice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    timezone?: string;
}
/**
 * 
 * @export
 * @interface MeetingCountDto
 */
export interface MeetingCountDto {
    /**
     * 
     * @type {string}
     * @memberof MeetingCountDto
     */
    key?: MeetingCountDtoKeyEnum;
    /**
     * 
     * @type {number}
     * @memberof MeetingCountDto
     */
    count?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MeetingCountDtoKeyEnum {
    All = 'ALL',
    Past = 'PAST',
    Future = 'FUTURE',
    Cancelled = 'CANCELLED',
    CustomDate = 'CUSTOM_DATE'
}

/**
 * 
 * @export
 * @interface MeetingDto
 */
export interface MeetingDto {
    /**
     * 
     * @type {string}
     * @memberof MeetingDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingDto
     */
    publicId?: string;
    /**
     * 
     * @type {number}
     * @memberof MeetingDto
     */
    startDate?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingDto
     */
    secure?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MeetingDto
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof MeetingDto
     */
    information?: string;
    /**
     * 
     * @type {Array<ParticipantDto>}
     * @memberof MeetingDto
     */
    participants?: Array<ParticipantDto>;
    /**
     * 
     * @type {number}
     * @memberof MeetingDto
     */
    createdDate?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingDto
     */
    removed?: boolean;
    /**
     * 
     * @type {Array<MeetingLogDto>}
     * @memberof MeetingDto
     */
    logs?: Array<MeetingLogDto>;
    /**
     * 
     * @type {number}
     * @memberof MeetingDto
     */
    startTime?: number;
    /**
     * 
     * @type {MeetingProviderDto}
     * @memberof MeetingDto
     */
    provider?: MeetingProviderDto;
    /**
     * 
     * @type {string}
     * @memberof MeetingDto
     */
    type?: MeetingDtoTypeEnum;
    /**
     * 
     * @type {Set<string>}
     * @memberof MeetingDto
     */
    permissions?: Set<MeetingDtoPermissionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof MeetingDto
     */
    callDuration?: number;
    /**
     * 
     * @type {Array<ParticipantGroupDto>}
     * @memberof MeetingDto
     */
    groups?: Array<ParticipantGroupDto>;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingDto
     */
    anonymized?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingDto
     */
    fromOra?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum MeetingDtoTypeEnum {
    Single = 'SINGLE',
    Multicast = 'MULTICAST',
    Group = 'GROUP'
}
/**
    * @export
    * @enum {string}
    */
export enum MeetingDtoPermissionsEnum {
    Video = 'VIDEO',
    Audio = 'AUDIO',
    Chat = 'CHAT',
    FileShare = 'FILE_SHARE'
}

/**
 * 
 * @export
 * @interface MeetingLogDto
 */
export interface MeetingLogDto {
    /**
     * 
     * @type {string}
     * @memberof MeetingLogDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof MeetingLogDto
     */
    createdDate?: number;
    /**
     * 
     * @type {string}
     * @memberof MeetingLogDto
     */
    type?: MeetingLogDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MeetingLogDto
     */
    details?: string;
    /**
     * 
     * @type {MemberDto}
     * @memberof MeetingLogDto
     */
    member?: MemberDto;
    /**
     * 
     * @type {LightParticipantDto}
     * @memberof MeetingLogDto
     */
    participant?: LightParticipantDto;
}

/**
    * @export
    * @enum {string}
    */
export enum MeetingLogDtoTypeEnum {
    NotSpecified = 'NOT_SPECIFIED',
    MeetingStarted = 'MEETING_STARTED',
    MeetingEnded = 'MEETING_ENDED',
    MemberJoined = 'MEMBER_JOINED',
    FileShared = 'FILE_SHARED',
    InvitationSent = 'INVITATION_SENT',
    ParticipantJoined = 'PARTICIPANT_JOINED'
}

/**
 * 
 * @export
 * @interface MeetingPageDto
 */
export interface MeetingPageDto {
    /**
     * 
     * @type {Array<MeetingDto>}
     * @memberof MeetingPageDto
     */
    meetings?: Array<MeetingDto>;
    /**
     * 
     * @type {number}
     * @memberof MeetingPageDto
     */
    pageCount?: number;
}
/**
 * 
 * @export
 * @interface MeetingProviderDto
 */
export interface MeetingProviderDto {
    /**
     * 
     * @type {string}
     * @memberof MeetingProviderDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingProviderDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingProviderDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingProviderDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingProviderDto
     */
    phone?: string;
}
/**
 * 
 * @export
 * @interface MeetingsDailyStatisticsDto
 */
export interface MeetingsDailyStatisticsDto {
    /**
     * 
     * @type {number}
     * @memberof MeetingsDailyStatisticsDto
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingsDailyStatisticsDto
     */
    scheduledMeetings?: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingsDailyStatisticsDto
     */
    performedMeetings?: number;
}
/**
 * 
 * @export
 * @interface MeetingsDurationDailyStatisticsDto
 */
export interface MeetingsDurationDailyStatisticsDto {
    /**
     * 
     * @type {number}
     * @memberof MeetingsDurationDailyStatisticsDto
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingsDurationDailyStatisticsDto
     */
    effectiveDuration?: number;
}
/**
 * 
 * @export
 * @interface MeetingsStatisticsDto
 */
export interface MeetingsStatisticsDto {
    /**
     * 
     * @type {number}
     * @memberof MeetingsStatisticsDto
     */
    from?: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingsStatisticsDto
     */
    thru?: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingsStatisticsDto
     */
    totalMeetingsScheduled?: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingsStatisticsDto
     */
    totalMeetingsPerformed?: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingsStatisticsDto
     */
    totalEffectiveDuration?: number;
    /**
     * 
     * @type {Array<MeetingsDailyStatisticsDto>}
     * @memberof MeetingsStatisticsDto
     */
    dailyStatistics?: Array<MeetingsDailyStatisticsDto>;
    /**
     * 
     * @type {Array<MeetingsDurationDailyStatisticsDto>}
     * @memberof MeetingsStatisticsDto
     */
    effectiveDurationDailyStatistics?: Array<MeetingsDurationDailyStatisticsDto>;
}
/**
 * 
 * @export
 * @interface MemberAnswerGroupDto
 */
export interface MemberAnswerGroupDto {
    /**
     * 
     * @type {CmsAnswerDto}
     * @memberof MemberAnswerGroupDto
     */
    answer?: CmsAnswerDto;
    /**
     * 
     * @type {LightCmsQuestionDto}
     * @memberof MemberAnswerGroupDto
     */
    question?: LightCmsQuestionDto;
    /**
     * 
     * @type {LightMemberDto}
     * @memberof MemberAnswerGroupDto
     */
    member?: LightMemberDto;
}
/**
 * 
 * @export
 * @interface MemberCountDto
 */
export interface MemberCountDto {
    /**
     * 
     * @type {string}
     * @memberof MemberCountDto
     */
    key?: MemberCountDtoKeyEnum;
    /**
     * 
     * @type {number}
     * @memberof MemberCountDto
     */
    count?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MemberCountDtoKeyEnum {
    All = 'ALL',
    Active = 'ACTIVE',
    Inactive = 'INACTIVE'
}

/**
 * 
 * @export
 * @interface MemberDto
 */
export interface MemberDto {
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    gender?: MemberDtoGenderEnum;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    lastLogin?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    createdDate?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    state?: MemberDtoStateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof MemberDto
     */
    roles?: Array<MemberDtoRolesEnum>;
    /**
     * 
     * @type {Array<AddressDto>}
     * @memberof MemberDto
     */
    addresses?: Array<AddressDto>;
    /**
     * 
     * @type {SettingsDto}
     * @memberof MemberDto
     */
    settings?: SettingsDto;
    /**
     * 
     * @type {PracticeDto}
     * @memberof MemberDto
     */
    practice?: PracticeDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof MemberDto
     */
    permissions?: Array<MemberDtoPermissionsEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof MemberDto
     */
    needsInitialSetup?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    inactiveDate?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    passwordChangedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    job?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    oldPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    mfaPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    fullAvatar?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    publicId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberDto
     */
    showLoginHint?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum MemberDtoGenderEnum {
    NotSpecified = 'NOT_SPECIFIED',
    Male = 'MALE',
    Female = 'FEMALE'
}
/**
    * @export
    * @enum {string}
    */
export enum MemberDtoStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Deleted = 'DELETED',
    Locked = 'LOCKED'
}
/**
    * @export
    * @enum {string}
    */
export enum MemberDtoRolesEnum {
    Admin = 'ADMIN',
    PracticeManager = 'PRACTICE_MANAGER',
    Provider = 'PROVIDER',
    CustomerCare = 'CUSTOMER_CARE',
    Participant = 'PARTICIPANT'
}
/**
    * @export
    * @enum {string}
    */
export enum MemberDtoPermissionsEnum {
    Sms = 'SMS',
    Statistics = 'STATISTICS',
    Cms = 'CMS'
}

/**
 * 
 * @export
 * @interface MemberPasswordDto
 */
export interface MemberPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof MemberPasswordDto
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface MemberStatusDto
 */
export interface MemberStatusDto {
    /**
     * 
     * @type {boolean}
     * @memberof MemberStatusDto
     */
    authenticated?: boolean;
    /**
     * 
     * @type {MemberDto}
     * @memberof MemberStatusDto
     */
    member?: MemberDto;
    /**
     * 
     * @type {string}
     * @memberof MemberStatusDto
     */
    uuid?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberStatusDto
     */
    millisTillExpire?: number;
}
/**
 * 
 * @export
 * @interface MfaFieldsDto
 */
export interface MfaFieldsDto {
    /**
     * 
     * @type {string}
     * @memberof MfaFieldsDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof MfaFieldsDto
     */
    mfaPhone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MfaFieldsDto
     */
    saveTrustedDevices?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MfaFieldsDto
     */
    twoFactorAuthentication?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MfaFieldsDto
     */
    mfaCode?: string;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    type?: NotificationDtoTypeEnum;
    /**
     * 
     * @type {PracticeDto}
     * @memberof NotificationDto
     */
    practice?: PracticeDto;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    created?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    lastUpdated?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    adminOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationDtoTypeEnum {
    Email = 'EMAIL',
    Sms = 'SMS'
}

/**
 * 
 * @export
 * @interface ParticipantAnswerGroupDto
 */
export interface ParticipantAnswerGroupDto {
    /**
     * 
     * @type {CmsAnswerDto}
     * @memberof ParticipantAnswerGroupDto
     */
    answer?: CmsAnswerDto;
    /**
     * 
     * @type {LightCmsQuestionDto}
     * @memberof ParticipantAnswerGroupDto
     */
    question?: LightCmsQuestionDto;
}
/**
 * 
 * @export
 * @interface ParticipantDeletionStatusDto
 */
export interface ParticipantDeletionStatusDto {
    /**
     * 
     * @type {number}
     * @memberof ParticipantDeletionStatusDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ParticipantDeletionStatusDto
     */
    jobRunDate?: number;
    /**
     * 
     * @type {number}
     * @memberof ParticipantDeletionStatusDto
     */
    numberOfDeleted?: number;
}
/**
 * 
 * @export
 * @interface ParticipantDto
 */
export interface ParticipantDto {
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    gender?: ParticipantDtoGenderEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantDto
     */
    smsNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantDto
     */
    emailNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantDto
     */
    notificationAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantDto
     */
    notifyBeforeMeeting?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParticipantDto
     */
    agreementDate?: number;
    /**
     * 
     * @type {number}
     * @memberof ParticipantDto
     */
    createdDate?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    publicId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantDto
     */
    removed?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ParticipantDtoGenderEnum {
    NotSpecified = 'NOT_SPECIFIED',
    Male = 'MALE',
    Female = 'FEMALE'
}

/**
 * 
 * @export
 * @interface ParticipantGroupDto
 */
export interface ParticipantGroupDto {
    /**
     * 
     * @type {string}
     * @memberof ParticipantGroupDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantGroupDto
     */
    name?: string;
    /**
     * 
     * @type {Array<ParticipantDto>}
     * @memberof ParticipantGroupDto
     */
    participants?: Array<ParticipantDto>;
}
/**
 * 
 * @export
 * @interface PatientDto
 */
export interface PatientDto {
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    gender?: PatientDtoGenderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PatientDtoGenderEnum {
    NotSpecified = 'NOT_SPECIFIED',
    Male = 'MALE',
    Female = 'FEMALE'
}

/**
 * 
 * @export
 * @interface PracticeDto
 */
export interface PracticeDto {
    /**
     * 
     * @type {string}
     * @memberof PracticeDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeDto
     */
    ik?: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeDto
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeDto
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeDto
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeDto
     */
    fax?: string;
    /**
     * 
     * @type {TenantDto}
     * @memberof PracticeDto
     */
    tenant?: TenantDto;
    /**
     * 
     * @type {AppSettingsDto}
     * @memberof PracticeDto
     */
    appSettings?: AppSettingsDto;
    /**
     * 
     * @type {string}
     * @memberof PracticeDto
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface ProviderSearchResultDto
 */
export interface ProviderSearchResultDto {
    /**
     * 
     * @type {Array<AccountDto>}
     * @memberof ProviderSearchResultDto
     */
    participants?: Array<AccountDto>;
    /**
     * 
     * @type {Array<AccountGroupDto>}
     * @memberof ProviderSearchResultDto
     */
    groups?: Array<AccountGroupDto>;
    /**
     * 
     * @type {Array<MeetingDto>}
     * @memberof ProviderSearchResultDto
     */
    meetings?: Array<MeetingDto>;
}
/**
 * 
 * @export
 * @interface PublicMeetingDto
 */
export interface PublicMeetingDto {
    /**
     * 
     * @type {string}
     * @memberof PublicMeetingDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicMeetingDto
     */
    publicId?: string;
    /**
     * 
     * @type {number}
     * @memberof PublicMeetingDto
     */
    startDate?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PublicMeetingDto
     */
    secure?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PublicMeetingDto
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof PublicMeetingDto
     */
    information?: string;
    /**
     * 
     * @type {Array<PublicParticipantDto>}
     * @memberof PublicMeetingDto
     */
    participants?: Array<PublicParticipantDto>;
    /**
     * 
     * @type {number}
     * @memberof PublicMeetingDto
     */
    createdDate?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PublicMeetingDto
     */
    removed?: boolean;
    /**
     * 
     * @type {Array<MeetingLogDto>}
     * @memberof PublicMeetingDto
     */
    logs?: Array<MeetingLogDto>;
    /**
     * 
     * @type {number}
     * @memberof PublicMeetingDto
     */
    startTime?: number;
    /**
     * 
     * @type {PublicMemberDto}
     * @memberof PublicMeetingDto
     */
    provider?: PublicMemberDto;
    /**
     * 
     * @type {string}
     * @memberof PublicMeetingDto
     */
    type?: PublicMeetingDtoTypeEnum;
    /**
     * 
     * @type {Set<string>}
     * @memberof PublicMeetingDto
     */
    permissions?: Set<PublicMeetingDtoPermissionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof PublicMeetingDto
     */
    callDuration?: number;
    /**
     * 
     * @type {Array<PublicParticipantGroupDto>}
     * @memberof PublicMeetingDto
     */
    groups?: Array<PublicParticipantGroupDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PublicMeetingDto
     */
    anonymized?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicMeetingDtoTypeEnum {
    Single = 'SINGLE',
    Multicast = 'MULTICAST',
    Group = 'GROUP'
}
/**
    * @export
    * @enum {string}
    */
export enum PublicMeetingDtoPermissionsEnum {
    Video = 'VIDEO',
    Audio = 'AUDIO',
    Chat = 'CHAT',
    FileShare = 'FILE_SHARE'
}

/**
 * 
 * @export
 * @interface PublicMemberDto
 */
export interface PublicMemberDto {
    /**
     * 
     * @type {string}
     * @memberof PublicMemberDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicMemberDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicMemberDto
     */
    lastName?: string;
}
/**
 * 
 * @export
 * @interface PublicParticipantDto
 */
export interface PublicParticipantDto {
    /**
     * 
     * @type {string}
     * @memberof PublicParticipantDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicParticipantDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicParticipantDto
     */
    publicId?: string;
}
/**
 * 
 * @export
 * @interface PublicParticipantGroupDto
 */
export interface PublicParticipantGroupDto {
    /**
     * 
     * @type {string}
     * @memberof PublicParticipantGroupDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicParticipantGroupDto
     */
    name?: string;
    /**
     * 
     * @type {Array<PublicParticipantDto>}
     * @memberof PublicParticipantGroupDto
     */
    participants?: Array<PublicParticipantDto>;
}
/**
 * 
 * @export
 * @interface RefreshTokenDto
 */
export interface RefreshTokenDto {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenDto
     */
    token?: string;
    /**
     * 
     * @type {number}
     * @memberof RefreshTokenDto
     */
    expiresIn?: number;
}
/**
 * 
 * @export
 * @interface RegisterDto
 */
export interface RegisterDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    license?: string;
}
/**
 * 
 * @export
 * @interface SearchOptions
 */
export interface SearchOptions {
    /**
     * 
     * @type {string}
     * @memberof SearchOptions
     */
    filter?: SearchOptionsFilterEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchOptions
     */
    sort?: SearchOptionsSortEnum;
    /**
     * 
     * @type {Interval}
     * @memberof SearchOptions
     */
    interval?: Interval;
    /**
     * 
     * @type {number}
     * @memberof SearchOptions
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchOptions
     */
    batch?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchOptionsFilterEnum {
    All = 'ALL',
    Past = 'PAST',
    Future = 'FUTURE',
    Cancelled = 'CANCELLED',
    CustomDate = 'CUSTOM_DATE'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchOptionsSortEnum {
    Default = 'DEFAULT',
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface SettingsDto
 */
export interface SettingsDto {
    /**
     * 
     * @type {string}
     * @memberof SettingsDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsDto
     */
    cameraId?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsDto
     */
    microphoneId?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsDto
     */
    speakerId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsDto
     */
    skipPreview?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsDto
     */
    sendEmailSummaryAfterMeeting?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsDto
     */
    sendEmailConfirmationAfterMeetingCreation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsDto
     */
    twoFactorAuthentication?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsDto
     */
    saveTrustedDevices?: boolean;
}
/**
 * 
 * @export
 * @interface SetupDto
 */
export interface SetupDto {
    /**
     * 
     * @type {string}
     * @memberof SetupDto
     */
    practice?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof SetupDto
     */
    address?: AddressDto;
    /**
     * 
     * @type {Array<CmsAnswerDto>}
     * @memberof SetupDto
     */
    answers?: Array<CmsAnswerDto>;
}
/**
 * 
 * @export
 * @interface StartTimeDto
 */
export interface StartTimeDto {
    /**
     * 
     * @type {string}
     * @memberof StartTimeDto
     */
    time?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StartTimeDto
     */
    available?: boolean;
}
/**
 * 
 * @export
 * @interface TenantDto
 */
export interface TenantDto {
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    name?: string;
    /**
     * 
     * @type {AppSettingsDto}
     * @memberof TenantDto
     */
    appSettings?: AppSettingsDto;
}
/**
 * 
 * @export
 * @interface TreatmentDto
 */
export interface TreatmentDto {
    /**
     * 
     * @type {string}
     * @memberof TreatmentDto
     */
    positionNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TreatmentDto
     */
    treatmentFullName?: string;
    /**
     * 
     * @type {number}
     * @memberof TreatmentDto
     */
    mandantId?: number;
}
/**
 * 
 * @export
 * @interface UpdatePasswordDto
 */
export interface UpdatePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordDto
     */
    oldPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordDto
     */
    newPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordDto
     */
    newPasswordConfirm?: string;
}

/**
 * AdminResourceApi - axios parameter creator
 * @export
 */
export const AdminResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countLicenses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/licenses/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMembers2: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/members/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMember: async (memberDto: MemberDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberDto' is not null or undefined
            if (memberDto === null || memberDto === undefined) {
                throw new RequiredError('memberDto','Required parameter memberDto was null or undefined when calling createMember.');
            }
            const localVarPath = `/api/v1/admin/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof memberDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(memberDto !== undefined ? memberDto : {})
                : (memberDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportLicensesToCsv: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/licenses/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {number} patients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDummyData: async (memberId: string, patients: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling generateDummyData.');
            }
            // verify required parameter 'patients' is not null or undefined
            if (patients === null || patients === undefined) {
                throw new RequiredError('patients','Required parameter patients was null or undefined when calling generateDummyData.');
            }
            const localVarPath = `/api/v1/admin/members/{memberId}/generateDummyData`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (patients !== undefined) {
                localVarQueryParameter['patients'] = patients;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateLicenses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/licenses/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EMAIL' | 'SMS'} type 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminNotificationByType: async (type: 'EMAIL' | 'SMS', name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getAdminNotificationByType.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getAdminNotificationByType.');
            }
            const localVarPath = `/api/v1/admin/practices/notifications/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPractice: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/practice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomersLight: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/customers/light`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'ALL' | 'ACTIVE' | 'INACTIVE'} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembersForAdmin: async (filter: 'ALL' | 'ACTIVE' | 'INACTIVE', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            if (filter === null || filter === undefined) {
                throw new RequiredError('filter','Required parameter filter was null or undefined when calling getAllMembersForAdmin.');
            }
            const localVarPath = `/api/v1/admin/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPractices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/practices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralStatistics1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} from 
         * @param {number} thru 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingsStatistics1: async (from: number, thru: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling getMeetingsStatistics1.');
            }
            // verify required parameter 'thru' is not null or undefined
            if (thru === null || thru === undefined) {
                throw new RequiredError('thru','Required parameter thru was null or undefined when calling getMeetingsStatistics1.');
            }
            const localVarPath = `/api/v1/admin/stats/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (thru !== undefined) {
                localVarQueryParameter['thru'] = thru;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeNotifications: async (practiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getPracticeNotifications.');
            }
            const localVarPath = `/api/v1/admin/practices/{practiceId}/notifications`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMember: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling removeMember.');
            }
            const localVarPath = `/api/v1/admin/members/{memberId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAdminNotification: async (notificationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling resetAdminNotification.');
            }
            const localVarPath = `/api/v1/admin/notifications/{notificationId}/reset`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPracticeNotification: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling resetPracticeNotification.');
            }
            const localVarPath = `/api/v1/admin/practices/notifications/{id}/reset`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPractices: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling searchPractices.');
            }
            const localVarPath = `/api/v1/admin/practices/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminNotification: async (notificationDto: NotificationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationDto' is not null or undefined
            if (notificationDto === null || notificationDto === undefined) {
                throw new RequiredError('notificationDto','Required parameter notificationDto was null or undefined when calling updateAdminNotification.');
            }
            const localVarPath = `/api/v1/admin/practices/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof notificationDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(notificationDto !== undefined ? notificationDto : {})
                : (notificationDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppSettingsDto} appSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminPracticeSettings: async (appSettingsDto: AppSettingsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appSettingsDto' is not null or undefined
            if (appSettingsDto === null || appSettingsDto === undefined) {
                throw new RequiredError('appSettingsDto','Required parameter appSettingsDto was null or undefined when calling updateAdminPracticeSettings.');
            }
            const localVarPath = `/api/v1/admin/practice/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof appSettingsDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(appSettingsDto !== undefined ? appSettingsDto : {})
                : (appSettingsDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminResourceApi - functional programming interface
 * @export
 */
export const AdminResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countLicenses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseCountDto>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).countLicenses(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countMembers2(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberCountDto>>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).countMembers2(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMember(memberDto: MemberDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberDto>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).createMember(memberDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportLicensesToCsv(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).exportLicensesToCsv(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {number} patients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateDummyData(memberId: string, patients: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).generateDummyData(memberId, patients, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateLicenses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).generateLicenses(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {'EMAIL' | 'SMS'} type 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminNotificationByType(type: 'EMAIL' | 'SMS', name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).getAdminNotificationByType(type, name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminPractice(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeDto>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).getAdminPractice(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCustomersLight(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LightMemberDto>>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).getAllCustomersLight(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {'ALL' | 'ACTIVE' | 'INACTIVE'} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMembersForAdmin(filter: 'ALL' | 'ACTIVE' | 'INACTIVE', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberDto>>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).getAllMembersForAdmin(filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPractices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PracticeDto>>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).getAllPractices(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralStatistics1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralStatisticsDto>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).getGeneralStatistics1(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} from 
         * @param {number} thru 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingsStatistics1(from: number, thru: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingsStatisticsDto>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).getMeetingsStatistics1(from, thru, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDto>>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).getNotifications1(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeNotifications(practiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDto>>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).getPracticeNotifications(practiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMember(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).removeMember(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetAdminNotification(notificationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).resetAdminNotification(notificationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPracticeNotification(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).resetPracticeNotification(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPractices(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PracticeDto>>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).searchPractices(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminNotification(notificationDto: NotificationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).updateAdminNotification(notificationDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AppSettingsDto} appSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminPracticeSettings(appSettingsDto: AppSettingsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).updateAdminPracticeSettings(appSettingsDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminResourceApi - factory interface
 * @export
 */
export const AdminResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countLicenses(options?: any): AxiosPromise<LicenseCountDto> {
            return AdminResourceApiFp(configuration).countLicenses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMembers2(options?: any): AxiosPromise<Array<MemberCountDto>> {
            return AdminResourceApiFp(configuration).countMembers2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMember(memberDto: MemberDto, options?: any): AxiosPromise<MemberDto> {
            return AdminResourceApiFp(configuration).createMember(memberDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportLicensesToCsv(options?: any): AxiosPromise<void> {
            return AdminResourceApiFp(configuration).exportLicensesToCsv(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {number} patients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDummyData(memberId: string, patients: number, options?: any): AxiosPromise<void> {
            return AdminResourceApiFp(configuration).generateDummyData(memberId, patients, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateLicenses(options?: any): AxiosPromise<void> {
            return AdminResourceApiFp(configuration).generateLicenses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EMAIL' | 'SMS'} type 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminNotificationByType(type: 'EMAIL' | 'SMS', name: string, options?: any): AxiosPromise<NotificationDto> {
            return AdminResourceApiFp(configuration).getAdminNotificationByType(type, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPractice(options?: any): AxiosPromise<PracticeDto> {
            return AdminResourceApiFp(configuration).getAdminPractice(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomersLight(options?: any): AxiosPromise<Array<LightMemberDto>> {
            return AdminResourceApiFp(configuration).getAllCustomersLight(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'ALL' | 'ACTIVE' | 'INACTIVE'} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembersForAdmin(filter: 'ALL' | 'ACTIVE' | 'INACTIVE', options?: any): AxiosPromise<Array<MemberDto>> {
            return AdminResourceApiFp(configuration).getAllMembersForAdmin(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPractices(options?: any): AxiosPromise<Array<PracticeDto>> {
            return AdminResourceApiFp(configuration).getAllPractices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralStatistics1(options?: any): AxiosPromise<GeneralStatisticsDto> {
            return AdminResourceApiFp(configuration).getGeneralStatistics1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} from 
         * @param {number} thru 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingsStatistics1(from: number, thru: number, options?: any): AxiosPromise<MeetingsStatisticsDto> {
            return AdminResourceApiFp(configuration).getMeetingsStatistics1(from, thru, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications1(options?: any): AxiosPromise<Array<NotificationDto>> {
            return AdminResourceApiFp(configuration).getNotifications1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeNotifications(practiceId: string, options?: any): AxiosPromise<Array<NotificationDto>> {
            return AdminResourceApiFp(configuration).getPracticeNotifications(practiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMember(memberId: string, options?: any): AxiosPromise<void> {
            return AdminResourceApiFp(configuration).removeMember(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAdminNotification(notificationId: string, options?: any): AxiosPromise<NotificationDto> {
            return AdminResourceApiFp(configuration).resetAdminNotification(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPracticeNotification(id: string, options?: any): AxiosPromise<void> {
            return AdminResourceApiFp(configuration).resetPracticeNotification(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPractices(key: string, options?: any): AxiosPromise<Array<PracticeDto>> {
            return AdminResourceApiFp(configuration).searchPractices(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminNotification(notificationDto: NotificationDto, options?: any): AxiosPromise<NotificationDto> {
            return AdminResourceApiFp(configuration).updateAdminNotification(notificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppSettingsDto} appSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminPracticeSettings(appSettingsDto: AppSettingsDto, options?: any): AxiosPromise<void> {
            return AdminResourceApiFp(configuration).updateAdminPracticeSettings(appSettingsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminResourceApi - object-oriented interface
 * @export
 * @class AdminResourceApi
 * @extends {BaseAPI}
 */
export class AdminResourceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public countLicenses(options?: any) {
        return AdminResourceApiFp(this.configuration).countLicenses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public countMembers2(options?: any) {
        return AdminResourceApiFp(this.configuration).countMembers2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberDto} memberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public createMember(memberDto: MemberDto, options?: any) {
        return AdminResourceApiFp(this.configuration).createMember(memberDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public exportLicensesToCsv(options?: any) {
        return AdminResourceApiFp(this.configuration).exportLicensesToCsv(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {number} patients 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public generateDummyData(memberId: string, patients: number, options?: any) {
        return AdminResourceApiFp(this.configuration).generateDummyData(memberId, patients, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public generateLicenses(options?: any) {
        return AdminResourceApiFp(this.configuration).generateLicenses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'EMAIL' | 'SMS'} type 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public getAdminNotificationByType(type: 'EMAIL' | 'SMS', name: string, options?: any) {
        return AdminResourceApiFp(this.configuration).getAdminNotificationByType(type, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public getAdminPractice(options?: any) {
        return AdminResourceApiFp(this.configuration).getAdminPractice(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public getAllCustomersLight(options?: any) {
        return AdminResourceApiFp(this.configuration).getAllCustomersLight(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'ALL' | 'ACTIVE' | 'INACTIVE'} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public getAllMembersForAdmin(filter: 'ALL' | 'ACTIVE' | 'INACTIVE', options?: any) {
        return AdminResourceApiFp(this.configuration).getAllMembersForAdmin(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public getAllPractices(options?: any) {
        return AdminResourceApiFp(this.configuration).getAllPractices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public getGeneralStatistics1(options?: any) {
        return AdminResourceApiFp(this.configuration).getGeneralStatistics1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} from 
     * @param {number} thru 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public getMeetingsStatistics1(from: number, thru: number, options?: any) {
        return AdminResourceApiFp(this.configuration).getMeetingsStatistics1(from, thru, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public getNotifications1(options?: any) {
        return AdminResourceApiFp(this.configuration).getNotifications1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public getPracticeNotifications(practiceId: string, options?: any) {
        return AdminResourceApiFp(this.configuration).getPracticeNotifications(practiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public removeMember(memberId: string, options?: any) {
        return AdminResourceApiFp(this.configuration).removeMember(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public resetAdminNotification(notificationId: string, options?: any) {
        return AdminResourceApiFp(this.configuration).resetAdminNotification(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public resetPracticeNotification(id: string, options?: any) {
        return AdminResourceApiFp(this.configuration).resetPracticeNotification(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public searchPractices(key: string, options?: any) {
        return AdminResourceApiFp(this.configuration).searchPractices(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationDto} notificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public updateAdminNotification(notificationDto: NotificationDto, options?: any) {
        return AdminResourceApiFp(this.configuration).updateAdminNotification(notificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppSettingsDto} appSettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public updateAdminPracticeSettings(appSettingsDto: AppSettingsDto, options?: any) {
        return AdminResourceApiFp(this.configuration).updateAdminPracticeSettings(appSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AppResourceApi - axios parameter creator
 * @export
 */
export const AppResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public/app/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppErrorDto} appErrorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportError: async (appErrorDto: AppErrorDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appErrorDto' is not null or undefined
            if (appErrorDto === null || appErrorDto === undefined) {
                throw new RequiredError('appErrorDto','Required parameter appErrorDto was null or undefined when calling reportError.');
            }
            const localVarPath = `/api/v1/public/app/errors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof appErrorDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(appErrorDto !== undefined ? appErrorDto : {})
                : (appErrorDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppResourceApi - functional programming interface
 * @export
 */
export const AppResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await AppResourceApiAxiosParamCreator(configuration).getOne(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AppErrorDto} appErrorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportError(appErrorDto: AppErrorDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppError>> {
            const localVarAxiosArgs = await AppResourceApiAxiosParamCreator(configuration).reportError(appErrorDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AppResourceApi - factory interface
 * @export
 */
export const AppResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(options?: any): AxiosPromise<string> {
            return AppResourceApiFp(configuration).getOne(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppErrorDto} appErrorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportError(appErrorDto: AppErrorDto, options?: any): AxiosPromise<AppError> {
            return AppResourceApiFp(configuration).reportError(appErrorDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppResourceApi - object-oriented interface
 * @export
 * @class AppResourceApi
 * @extends {BaseAPI}
 */
export class AppResourceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppResourceApi
     */
    public getOne(options?: any) {
        return AppResourceApiFp(this.configuration).getOne(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppErrorDto} appErrorDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppResourceApi
     */
    public reportError(appErrorDto: AppErrorDto, options?: any) {
        return AppResourceApiFp(this.configuration).reportError(appErrorDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthResourceApi - axios parameter creator
 * @export
 */
export const AuthResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAccount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling activateAccount.');
            }
            const localVarPath = `/api/v1/public/account/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMfaFieldsNewMember: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMfaFieldsNewMember.');
            }
            const localVarPath = `/api/v1/public/{id}/mfaFields`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalLogout: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling globalLogout.');
            }
            const localVarPath = `/api/v1/public/account/{id}/globalLogout`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginDto: LoginDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            if (loginDto === null || loginDto === undefined) {
                throw new RequiredError('loginDto','Required parameter loginDto was null or undefined when calling login.');
            }
            const localVarPath = `/api/v1/public/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof loginDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(loginDto !== undefined ? loginDto : {})
                : (loginDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public/refreshToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (registerDto: RegisterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerDto' is not null or undefined
            if (registerDto === null || registerDto === undefined) {
                throw new RequiredError('registerDto','Required parameter registerDto was null or undefined when calling register.');
            }
            const localVarPath = `/api/v1/public/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof registerDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(registerDto !== undefined ? registerDto : {})
                : (registerDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (code: string, updatePasswordDto: UpdatePasswordDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling resetPassword.');
            }
            // verify required parameter 'updatePasswordDto' is not null or undefined
            if (updatePasswordDto === null || updatePasswordDto === undefined) {
                throw new RequiredError('updatePasswordDto','Required parameter updatePasswordDto was null or undefined when calling resetPassword.');
            }
            const localVarPath = `/api/v1/public/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updatePasswordDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updatePasswordDto !== undefined ? updatePasswordDto : {})
                : (updatePasswordDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLoginCode: async (loginDto: LoginDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            if (loginDto === null || loginDto === undefined) {
                throw new RequiredError('loginDto','Required parameter loginDto was null or undefined when calling sendLoginCode.');
            }
            const localVarPath = `/api/v1/public/sendLoginCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof loginDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(loginDto !== undefined ? loginDto : {})
                : (loginDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordForgotMail: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling sendPasswordForgotMail.');
            }
            const localVarPath = `/api/v1/public/passwordForgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MfaFieldsDto} mfaFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMfaFieldsNewMember: async (id: string, mfaFieldsDto: MfaFieldsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateMfaFieldsNewMember.');
            }
            // verify required parameter 'mfaFieldsDto' is not null or undefined
            if (mfaFieldsDto === null || mfaFieldsDto === undefined) {
                throw new RequiredError('mfaFieldsDto','Required parameter mfaFieldsDto was null or undefined when calling updateMfaFieldsNewMember.');
            }
            const localVarPath = `/api/v1/public/{id}/mfaFields`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mfaFieldsDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mfaFieldsDto !== undefined ? mfaFieldsDto : {})
                : (mfaFieldsDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthResourceApi - functional programming interface
 * @export
 */
export const AuthResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateAccount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).activateAccount(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMfaFieldsNewMember(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MfaFieldsDto>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).getMfaFieldsNewMember(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalLogout(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).globalLogout(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginDto: LoginDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponseDto>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).login(loginDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).logout(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshTokenDto>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).refreshToken(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(registerDto: RegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponseDto>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).register(registerDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(code: string, updatePasswordDto: UpdatePasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).resetPassword(code, updatePasswordDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendLoginCode(loginDto: LoginDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).sendLoginCode(loginDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPasswordForgotMail(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).sendPasswordForgotMail(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MfaFieldsDto} mfaFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMfaFieldsNewMember(id: string, mfaFieldsDto: MfaFieldsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).updateMfaFieldsNewMember(id, mfaFieldsDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthResourceApi - factory interface
 * @export
 */
export const AuthResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAccount(id: string, options?: any): AxiosPromise<boolean> {
            return AuthResourceApiFp(configuration).activateAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMfaFieldsNewMember(id: string, options?: any): AxiosPromise<MfaFieldsDto> {
            return AuthResourceApiFp(configuration).getMfaFieldsNewMember(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalLogout(id: string, options?: any): AxiosPromise<boolean> {
            return AuthResourceApiFp(configuration).globalLogout(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginDto: LoginDto, options?: any): AxiosPromise<AuthResponseDto> {
            return AuthResourceApiFp(configuration).login(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<void> {
            return AuthResourceApiFp(configuration).logout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(options?: any): AxiosPromise<RefreshTokenDto> {
            return AuthResourceApiFp(configuration).refreshToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerDto: RegisterDto, options?: any): AxiosPromise<AuthResponseDto> {
            return AuthResourceApiFp(configuration).register(registerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(code: string, updatePasswordDto: UpdatePasswordDto, options?: any): AxiosPromise<void> {
            return AuthResourceApiFp(configuration).resetPassword(code, updatePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLoginCode(loginDto: LoginDto, options?: any): AxiosPromise<void> {
            return AuthResourceApiFp(configuration).sendLoginCode(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordForgotMail(email: string, options?: any): AxiosPromise<void> {
            return AuthResourceApiFp(configuration).sendPasswordForgotMail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MfaFieldsDto} mfaFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMfaFieldsNewMember(id: string, mfaFieldsDto: MfaFieldsDto, options?: any): AxiosPromise<void> {
            return AuthResourceApiFp(configuration).updateMfaFieldsNewMember(id, mfaFieldsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthResourceApi - object-oriented interface
 * @export
 * @class AuthResourceApi
 * @extends {BaseAPI}
 */
export class AuthResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public activateAccount(id: string, options?: any) {
        return AuthResourceApiFp(this.configuration).activateAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public getMfaFieldsNewMember(id: string, options?: any) {
        return AuthResourceApiFp(this.configuration).getMfaFieldsNewMember(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public globalLogout(id: string, options?: any) {
        return AuthResourceApiFp(this.configuration).globalLogout(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public login(loginDto: LoginDto, options?: any) {
        return AuthResourceApiFp(this.configuration).login(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public logout(options?: any) {
        return AuthResourceApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public refreshToken(options?: any) {
        return AuthResourceApiFp(this.configuration).refreshToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterDto} registerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public register(registerDto: RegisterDto, options?: any) {
        return AuthResourceApiFp(this.configuration).register(registerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {UpdatePasswordDto} updatePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public resetPassword(code: string, updatePasswordDto: UpdatePasswordDto, options?: any) {
        return AuthResourceApiFp(this.configuration).resetPassword(code, updatePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public sendLoginCode(loginDto: LoginDto, options?: any) {
        return AuthResourceApiFp(this.configuration).sendLoginCode(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public sendPasswordForgotMail(email: string, options?: any) {
        return AuthResourceApiFp(this.configuration).sendPasswordForgotMail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MfaFieldsDto} mfaFieldsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public updateMfaFieldsNewMember(id: string, mfaFieldsDto: MfaFieldsDto, options?: any) {
        return AuthResourceApiFp(this.configuration).updateMfaFieldsNewMember(id, mfaFieldsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CmsResourceApi - axios parameter creator
 * @export
 */
export const CmsResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmsList: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getCmsList.');
            }
            const localVarPath = `/api/v1/cms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CmsResourceApi - functional programming interface
 * @export
 */
export const CmsResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmsList(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CmsDto>>> {
            const localVarAxiosArgs = await CmsResourceApiAxiosParamCreator(configuration).getCmsList(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CmsResourceApi - factory interface
 * @export
 */
export const CmsResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmsList(memberId: string, options?: any): AxiosPromise<Array<CmsDto>> {
            return CmsResourceApiFp(configuration).getCmsList(memberId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CmsResourceApi - object-oriented interface
 * @export
 * @class CmsResourceApi
 * @extends {BaseAPI}
 */
export class CmsResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsResourceApi
     */
    public getCmsList(memberId: string, options?: any) {
        return CmsResourceApiFp(this.configuration).getCmsList(memberId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContentResourceApi - axios parameter creator
 * @export
 */
export const ContentResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentByType: async (type: 'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getContentByType.');
            }
            const localVarPath = `/api/v1/public/content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentList: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getContentList.');
            }
            const localVarPath = `/api/v1/content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentResourceApi - functional programming interface
 * @export
 */
export const ContentResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentByType(type: 'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LightContentDto>> {
            const localVarAxiosArgs = await ContentResourceApiAxiosParamCreator(configuration).getContentByType(type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentList(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentDto>>> {
            const localVarAxiosArgs = await ContentResourceApiAxiosParamCreator(configuration).getContentList(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContentResourceApi - factory interface
 * @export
 */
export const ContentResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentByType(type: 'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER', options?: any): AxiosPromise<LightContentDto> {
            return ContentResourceApiFp(configuration).getContentByType(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentList(memberId: string, options?: any): AxiosPromise<Array<ContentDto>> {
            return ContentResourceApiFp(configuration).getContentList(memberId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentResourceApi - object-oriented interface
 * @export
 * @class ContentResourceApi
 * @extends {BaseAPI}
 */
export class ContentResourceApi extends BaseAPI {
    /**
     * 
     * @param {'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER'} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentResourceApi
     */
    public getContentByType(type: 'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER', options?: any) {
        return ContentResourceApiFp(this.configuration).getContentByType(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentResourceApi
     */
    public getContentList(memberId: string, options?: any) {
        return ContentResourceApiFp(this.configuration).getContentList(memberId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerCareResourceApi - axios parameter creator
 * @export
 */
export const CustomerCareResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMembers1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/care/members/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'ALL' | 'ACTIVE' | 'INACTIVE'} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembers: async (filter: 'ALL' | 'ACTIVE' | 'INACTIVE', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            if (filter === null || filter === undefined) {
                throw new RequiredError('filter','Required parameter filter was null or undefined when calling getAllMembers.');
            }
            const localVarPath = `/api/v1/care/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralStatistics: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/care/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} from 
         * @param {number} thru 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingsStatistics: async (from: number, thru: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling getMeetingsStatistics.');
            }
            // verify required parameter 'thru' is not null or undefined
            if (thru === null || thru === undefined) {
                throw new RequiredError('thru','Required parameter thru was null or undefined when calling getMeetingsStatistics.');
            }
            const localVarPath = `/api/v1/care/stats/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (thru !== undefined) {
                localVarQueryParameter['thru'] = thru;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerCareResourceApi - functional programming interface
 * @export
 */
export const CustomerCareResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countMembers1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberCountDto>>> {
            const localVarAxiosArgs = await CustomerCareResourceApiAxiosParamCreator(configuration).countMembers1(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {'ALL' | 'ACTIVE' | 'INACTIVE'} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMembers(filter: 'ALL' | 'ACTIVE' | 'INACTIVE', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberDto>>> {
            const localVarAxiosArgs = await CustomerCareResourceApiAxiosParamCreator(configuration).getAllMembers(filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralStatistics(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralStatisticsDto>> {
            const localVarAxiosArgs = await CustomerCareResourceApiAxiosParamCreator(configuration).getGeneralStatistics(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} from 
         * @param {number} thru 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingsStatistics(from: number, thru: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingsStatisticsDto>> {
            const localVarAxiosArgs = await CustomerCareResourceApiAxiosParamCreator(configuration).getMeetingsStatistics(from, thru, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerCareResourceApi - factory interface
 * @export
 */
export const CustomerCareResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMembers1(options?: any): AxiosPromise<Array<MemberCountDto>> {
            return CustomerCareResourceApiFp(configuration).countMembers1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'ALL' | 'ACTIVE' | 'INACTIVE'} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMembers(filter: 'ALL' | 'ACTIVE' | 'INACTIVE', options?: any): AxiosPromise<Array<MemberDto>> {
            return CustomerCareResourceApiFp(configuration).getAllMembers(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralStatistics(options?: any): AxiosPromise<GeneralStatisticsDto> {
            return CustomerCareResourceApiFp(configuration).getGeneralStatistics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} from 
         * @param {number} thru 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingsStatistics(from: number, thru: number, options?: any): AxiosPromise<MeetingsStatisticsDto> {
            return CustomerCareResourceApiFp(configuration).getMeetingsStatistics(from, thru, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerCareResourceApi - object-oriented interface
 * @export
 * @class CustomerCareResourceApi
 * @extends {BaseAPI}
 */
export class CustomerCareResourceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCareResourceApi
     */
    public countMembers1(options?: any) {
        return CustomerCareResourceApiFp(this.configuration).countMembers1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'ALL' | 'ACTIVE' | 'INACTIVE'} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCareResourceApi
     */
    public getAllMembers(filter: 'ALL' | 'ACTIVE' | 'INACTIVE', options?: any) {
        return CustomerCareResourceApiFp(this.configuration).getAllMembers(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCareResourceApi
     */
    public getGeneralStatistics(options?: any) {
        return CustomerCareResourceApiFp(this.configuration).getGeneralStatistics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} from 
     * @param {number} thru 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCareResourceApi
     */
    public getMeetingsStatistics(from: number, thru: number, options?: any) {
        return CustomerCareResourceApiFp(this.configuration).getMeetingsStatistics(from, thru, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocaleResourceApi - axios parameter creator
 * @export
 */
export const LocaleResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations: async (lng: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lng' is not null or undefined
            if (lng === null || lng === undefined) {
                throw new RequiredError('lng','Required parameter lng was null or undefined when calling getTranslations.');
            }
            const localVarPath = `/api/public/locales/{lng}.json`
                .replace(`{${"lng"}}`, encodeURIComponent(String(lng)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocaleResourceApi - functional programming interface
 * @export
 */
export const LocaleResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslations(lng: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await LocaleResourceApiAxiosParamCreator(configuration).getTranslations(lng, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LocaleResourceApi - factory interface
 * @export
 */
export const LocaleResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations(lng: string, options?: any): AxiosPromise<string> {
            return LocaleResourceApiFp(configuration).getTranslations(lng, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocaleResourceApi - object-oriented interface
 * @export
 * @class LocaleResourceApi
 * @extends {BaseAPI}
 */
export class LocaleResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} lng 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleResourceApi
     */
    public getTranslations(lng: string, options?: any) {
        return LocaleResourceApiFp(this.configuration).getTranslations(lng, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MeetingResourceApi - axios parameter creator
 * @export
 */
export const MeetingResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<CancelMeetingDto>} cancelMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel: async (cancelMeetingDto: Array<CancelMeetingDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cancelMeetingDto' is not null or undefined
            if (cancelMeetingDto === null || cancelMeetingDto === undefined) {
                throw new RequiredError('cancelMeetingDto','Required parameter cancelMeetingDto was null or undefined when calling cancel.');
            }
            const localVarPath = `/api/v1/meetings/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cancelMeetingDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cancelMeetingDto !== undefined ? cancelMeetingDto : {})
                : (cancelMeetingDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} startDate 
         * @param {number} startTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfIsInThePast: async (startDate: number, startTime: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            if (startDate === null || startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling checkIfIsInThePast.');
            }
            // verify required parameter 'startTime' is not null or undefined
            if (startTime === null || startTime === undefined) {
                throw new RequiredError('startTime','Required parameter startTime was null or undefined when calling checkIfIsInThePast.');
            }
            const localVarPath = `/api/v1/meetings/check/isInThePast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMeetings: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling countMeetings.');
            }
            const localVarPath = `/api/v1/meetings/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMeetingDto} createMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1: async (createMeetingDto: CreateMeetingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMeetingDto' is not null or undefined
            if (createMeetingDto === null || createMeetingDto === undefined) {
                throw new RequiredError('createMeetingDto','Required parameter createMeetingDto was null or undefined when calling create1.');
            }
            const localVarPath = `/api/v1/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createMeetingDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createMeetingDto !== undefined ? createMeetingDto : {})
                : (createMeetingDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {MeetingLogDto} meetingLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetingLog: async (meetingId: string, meetingLogDto: MeetingLogDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingId' is not null or undefined
            if (meetingId === null || meetingId === undefined) {
                throw new RequiredError('meetingId','Required parameter meetingId was null or undefined when calling createMeetingLog.');
            }
            // verify required parameter 'meetingLogDto' is not null or undefined
            if (meetingLogDto === null || meetingLogDto === undefined) {
                throw new RequiredError('meetingLogDto','Required parameter meetingLogDto was null or undefined when calling createMeetingLog.');
            }
            const localVarPath = `/api/v1/meetings/{meetingId}/logs`
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof meetingLogDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(meetingLogDto !== undefined ? meetingLogDto : {})
                : (meetingLogDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {number} date 
         * @param {number} duration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableTimes: async (memberId: string, date: number, duration: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getAvailableTimes.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getAvailableTimes.');
            }
            // verify required parameter 'duration' is not null or undefined
            if (duration === null || duration === undefined) {
                throw new RequiredError('duration','Required parameter duration was null or undefined when calling getAvailableTimes.');
            }
            const localVarPath = `/api/v1/meetings/times`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (duration !== undefined) {
                localVarQueryParameter['duration'] = duration;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (meetingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingId' is not null or undefined
            if (meetingId === null || meetingId === undefined) {
                throw new RequiredError('meetingId','Required parameter meetingId was null or undefined when calling getById.');
            }
            const localVarPath = `/api/v1/meetings/{meetingId}`
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullMeetingByPublicIdForPatient: async (publicId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            if (publicId === null || publicId === undefined) {
                throw new RequiredError('publicId','Required parameter publicId was null or undefined when calling getFullMeetingByPublicIdForPatient.');
            }
            const localVarPath = `/api/v1/public/meetings/full/{publicId}`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullMeetingByPublicIdForProvider: async (publicId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            if (publicId === null || publicId === undefined) {
                throw new RequiredError('publicId','Required parameter publicId was null or undefined when calling getFullMeetingByPublicIdForProvider.');
            }
            const localVarPath = `/api/v1/meetings/full/{publicId}`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLightMeetingByPublicId: async (publicId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            if (publicId === null || publicId === undefined) {
                throw new RequiredError('publicId','Required parameter publicId was null or undefined when calling getLightMeetingByPublicId.');
            }
            const localVarPath = `/api/v1/public/meetings/light/{publicId}`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingActives: async (meetingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingId' is not null or undefined
            if (meetingId === null || meetingId === undefined) {
                throw new RequiredError('meetingId','Required parameter meetingId was null or undefined when calling getMeetingActives.');
            }
            const localVarPath = `/api/v1/public/meetings/{meetingId}/actives`
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingInfoPDF: async (publicId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            if (publicId === null || publicId === undefined) {
                throw new RequiredError('publicId','Required parameter publicId was null or undefined when calling getMeetingInfoPDF.');
            }
            const localVarPath = `/api/v1/meetings/{publicId}/meetingInfo`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingLogs: async (meetingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingId' is not null or undefined
            if (meetingId === null || meetingId === undefined) {
                throw new RequiredError('meetingId','Required parameter meetingId was null or undefined when calling getMeetingLogs.');
            }
            const localVarPath = `/api/v1/meetings/{meetingId}/logs`
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberMeetings: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMemberMeetings.');
            }
            const localVarPath = `/api/v1/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} notify 
         * @param {Array<MeetingDto>} meetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeList: async (notify: boolean, meetingDto: Array<MeetingDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notify' is not null or undefined
            if (notify === null || notify === undefined) {
                throw new RequiredError('notify','Required parameter notify was null or undefined when calling removeList.');
            }
            // verify required parameter 'meetingDto' is not null or undefined
            if (meetingDto === null || meetingDto === undefined) {
                throw new RequiredError('meetingDto','Required parameter meetingDto was null or undefined when calling removeList.');
            }
            const localVarPath = `/api/v1/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (notify !== undefined) {
                localVarQueryParameter['notify'] = notify;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof meetingDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(meetingDto !== undefined ? meetingDto : {})
                : (meetingDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendNotifications: async (meetingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingId' is not null or undefined
            if (meetingId === null || meetingId === undefined) {
                throw new RequiredError('meetingId','Required parameter meetingId was null or undefined when calling resendNotifications.');
            }
            const localVarPath = `/api/v1/meetings/{meetingId}/notification`
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {SearchOptions} searchOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMeetings: async (memberId: string, searchOptions: SearchOptions, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling searchMeetings.');
            }
            // verify required parameter 'searchOptions' is not null or undefined
            if (searchOptions === null || searchOptions === undefined) {
                throw new RequiredError('searchOptions','Required parameter searchOptions was null or undefined when calling searchMeetings.');
            }
            const localVarPath = `/api/v1/meetings/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof searchOptions !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(searchOptions !== undefined ? searchOptions : {})
                : (searchOptions || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {number} duration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCallDuration: async (meetingId: string, duration: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingId' is not null or undefined
            if (meetingId === null || meetingId === undefined) {
                throw new RequiredError('meetingId','Required parameter meetingId was null or undefined when calling updateCallDuration.');
            }
            // verify required parameter 'duration' is not null or undefined
            if (duration === null || duration === undefined) {
                throw new RequiredError('duration','Required parameter duration was null or undefined when calling updateCallDuration.');
            }
            const localVarPath = `/api/v1/meetings/{meetingId}/duration`
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (duration !== undefined) {
                localVarQueryParameter['duration'] = duration;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MeetingDto} meetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeeting: async (meetingDto: MeetingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingDto' is not null or undefined
            if (meetingDto === null || meetingDto === undefined) {
                throw new RequiredError('meetingDto','Required parameter meetingDto was null or undefined when calling updateMeeting.');
            }
            const localVarPath = `/api/v1/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof meetingDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(meetingDto !== undefined ? meetingDto : {})
                : (meetingDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeetingResourceApi - functional programming interface
 * @export
 */
export const MeetingResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<CancelMeetingDto>} cancelMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancel(cancelMeetingDto: Array<CancelMeetingDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeetingDto>>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).cancel(cancelMeetingDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} startDate 
         * @param {number} startTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkIfIsInThePast(startDate: number, startTime: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).checkIfIsInThePast(startDate, startTime, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countMeetings(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeetingCountDto>>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).countMeetings(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateMeetingDto} createMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create1(createMeetingDto: CreateMeetingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).create1(createMeetingDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {MeetingLogDto} meetingLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMeetingLog(meetingId: string, meetingLogDto: MeetingLogDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingLogDto>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).createMeetingLog(meetingId, meetingLogDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {number} date 
         * @param {number} duration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableTimes(memberId: string, date: number, duration: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StartTimeDto>>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).getAvailableTimes(memberId, date, duration, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(meetingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDto>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).getById(meetingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFullMeetingByPublicIdForPatient(publicId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicMeetingDto>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).getFullMeetingByPublicIdForPatient(publicId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFullMeetingByPublicIdForProvider(publicId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDto>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).getFullMeetingByPublicIdForProvider(publicId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLightMeetingByPublicId(publicId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LightMeetingDto>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).getLightMeetingByPublicId(publicId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingActives(meetingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).getMeetingActives(meetingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingInfoPDF(publicId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).getMeetingInfoPDF(publicId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingLogs(meetingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeetingLogDto>>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).getMeetingLogs(meetingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberMeetings(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeetingDto>>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).getMemberMeetings(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} notify 
         * @param {Array<MeetingDto>} meetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeList(notify: boolean, meetingDto: Array<MeetingDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).removeList(notify, meetingDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendNotifications(meetingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).resendNotifications(meetingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {SearchOptions} searchOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchMeetings(memberId: string, searchOptions: SearchOptions, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingPageDto>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).searchMeetings(memberId, searchOptions, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {number} duration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCallDuration(meetingId: string, duration: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).updateCallDuration(meetingId, duration, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {MeetingDto} meetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeeting(meetingDto: MeetingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await MeetingResourceApiAxiosParamCreator(configuration).updateMeeting(meetingDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MeetingResourceApi - factory interface
 * @export
 */
export const MeetingResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {Array<CancelMeetingDto>} cancelMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel(cancelMeetingDto: Array<CancelMeetingDto>, options?: any): AxiosPromise<Array<MeetingDto>> {
            return MeetingResourceApiFp(configuration).cancel(cancelMeetingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} startDate 
         * @param {number} startTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfIsInThePast(startDate: number, startTime: number, options?: any): AxiosPromise<boolean> {
            return MeetingResourceApiFp(configuration).checkIfIsInThePast(startDate, startTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMeetings(memberId: string, options?: any): AxiosPromise<Array<MeetingCountDto>> {
            return MeetingResourceApiFp(configuration).countMeetings(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMeetingDto} createMeetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(createMeetingDto: CreateMeetingDto, options?: any): AxiosPromise<string> {
            return MeetingResourceApiFp(configuration).create1(createMeetingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {MeetingLogDto} meetingLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetingLog(meetingId: string, meetingLogDto: MeetingLogDto, options?: any): AxiosPromise<MeetingLogDto> {
            return MeetingResourceApiFp(configuration).createMeetingLog(meetingId, meetingLogDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {number} date 
         * @param {number} duration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableTimes(memberId: string, date: number, duration: number, options?: any): AxiosPromise<Array<StartTimeDto>> {
            return MeetingResourceApiFp(configuration).getAvailableTimes(memberId, date, duration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(meetingId: string, options?: any): AxiosPromise<MeetingDto> {
            return MeetingResourceApiFp(configuration).getById(meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullMeetingByPublicIdForPatient(publicId: string, options?: any): AxiosPromise<PublicMeetingDto> {
            return MeetingResourceApiFp(configuration).getFullMeetingByPublicIdForPatient(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullMeetingByPublicIdForProvider(publicId: string, options?: any): AxiosPromise<MeetingDto> {
            return MeetingResourceApiFp(configuration).getFullMeetingByPublicIdForProvider(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLightMeetingByPublicId(publicId: string, options?: any): AxiosPromise<LightMeetingDto> {
            return MeetingResourceApiFp(configuration).getLightMeetingByPublicId(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingActives(meetingId: string, options?: any): AxiosPromise<Array<string>> {
            return MeetingResourceApiFp(configuration).getMeetingActives(meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingInfoPDF(publicId: string, options?: any): AxiosPromise<void> {
            return MeetingResourceApiFp(configuration).getMeetingInfoPDF(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingLogs(meetingId: string, options?: any): AxiosPromise<Array<MeetingLogDto>> {
            return MeetingResourceApiFp(configuration).getMeetingLogs(meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberMeetings(memberId: string, options?: any): AxiosPromise<Array<MeetingDto>> {
            return MeetingResourceApiFp(configuration).getMemberMeetings(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} notify 
         * @param {Array<MeetingDto>} meetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeList(notify: boolean, meetingDto: Array<MeetingDto>, options?: any): AxiosPromise<Array<string>> {
            return MeetingResourceApiFp(configuration).removeList(notify, meetingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendNotifications(meetingId: string, options?: any): AxiosPromise<void> {
            return MeetingResourceApiFp(configuration).resendNotifications(meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {SearchOptions} searchOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMeetings(memberId: string, searchOptions: SearchOptions, options?: any): AxiosPromise<MeetingPageDto> {
            return MeetingResourceApiFp(configuration).searchMeetings(memberId, searchOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} meetingId 
         * @param {number} duration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCallDuration(meetingId: string, duration: number, options?: any): AxiosPromise<void> {
            return MeetingResourceApiFp(configuration).updateCallDuration(meetingId, duration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MeetingDto} meetingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeeting(meetingDto: MeetingDto, options?: any): AxiosPromise<string> {
            return MeetingResourceApiFp(configuration).updateMeeting(meetingDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeetingResourceApi - object-oriented interface
 * @export
 * @class MeetingResourceApi
 * @extends {BaseAPI}
 */
export class MeetingResourceApi extends BaseAPI {
    /**
     * 
     * @param {Array<CancelMeetingDto>} cancelMeetingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public cancel(cancelMeetingDto: Array<CancelMeetingDto>, options?: any) {
        return MeetingResourceApiFp(this.configuration).cancel(cancelMeetingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} startDate 
     * @param {number} startTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public checkIfIsInThePast(startDate: number, startTime: number, options?: any) {
        return MeetingResourceApiFp(this.configuration).checkIfIsInThePast(startDate, startTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public countMeetings(memberId: string, options?: any) {
        return MeetingResourceApiFp(this.configuration).countMeetings(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateMeetingDto} createMeetingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public create1(createMeetingDto: CreateMeetingDto, options?: any) {
        return MeetingResourceApiFp(this.configuration).create1(createMeetingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} meetingId 
     * @param {MeetingLogDto} meetingLogDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public createMeetingLog(meetingId: string, meetingLogDto: MeetingLogDto, options?: any) {
        return MeetingResourceApiFp(this.configuration).createMeetingLog(meetingId, meetingLogDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {number} date 
     * @param {number} duration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public getAvailableTimes(memberId: string, date: number, duration: number, options?: any) {
        return MeetingResourceApiFp(this.configuration).getAvailableTimes(memberId, date, duration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public getById(meetingId: string, options?: any) {
        return MeetingResourceApiFp(this.configuration).getById(meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public getFullMeetingByPublicIdForPatient(publicId: string, options?: any) {
        return MeetingResourceApiFp(this.configuration).getFullMeetingByPublicIdForPatient(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public getFullMeetingByPublicIdForProvider(publicId: string, options?: any) {
        return MeetingResourceApiFp(this.configuration).getFullMeetingByPublicIdForProvider(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public getLightMeetingByPublicId(publicId: string, options?: any) {
        return MeetingResourceApiFp(this.configuration).getLightMeetingByPublicId(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public getMeetingActives(meetingId: string, options?: any) {
        return MeetingResourceApiFp(this.configuration).getMeetingActives(meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public getMeetingInfoPDF(publicId: string, options?: any) {
        return MeetingResourceApiFp(this.configuration).getMeetingInfoPDF(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public getMeetingLogs(meetingId: string, options?: any) {
        return MeetingResourceApiFp(this.configuration).getMeetingLogs(meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public getMemberMeetings(memberId: string, options?: any) {
        return MeetingResourceApiFp(this.configuration).getMemberMeetings(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} notify 
     * @param {Array<MeetingDto>} meetingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public removeList(notify: boolean, meetingDto: Array<MeetingDto>, options?: any) {
        return MeetingResourceApiFp(this.configuration).removeList(notify, meetingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public resendNotifications(meetingId: string, options?: any) {
        return MeetingResourceApiFp(this.configuration).resendNotifications(meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {SearchOptions} searchOptions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public searchMeetings(memberId: string, searchOptions: SearchOptions, options?: any) {
        return MeetingResourceApiFp(this.configuration).searchMeetings(memberId, searchOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} meetingId 
     * @param {number} duration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public updateCallDuration(meetingId: string, duration: number, options?: any) {
        return MeetingResourceApiFp(this.configuration).updateCallDuration(meetingId, duration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MeetingDto} meetingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingResourceApi
     */
    public updateMeeting(meetingDto: MeetingDto, options?: any) {
        return MeetingResourceApiFp(this.configuration).updateMeeting(meetingDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MemberResourceApi - axios parameter creator
 * @export
 */
export const MemberResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} memberId 
         * @param {MemberPasswordDto} memberPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPassword: async (memberId: string, memberPasswordDto: MemberPasswordDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling confirmPassword.');
            }
            // verify required parameter 'memberPasswordDto' is not null or undefined
            if (memberPasswordDto === null || memberPasswordDto === undefined) {
                throw new RequiredError('memberPasswordDto','Required parameter memberPasswordDto was null or undefined when calling confirmPassword.');
            }
            const localVarPath = `/api/v1/members/{memberId}/confirmPassword`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof memberPasswordDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(memberPasswordDto !== undefined ? memberPasswordDto : {})
                : (memberPasswordDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {AddressDto} addressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddress: async (memberId: string, addressDto: AddressDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling createAddress.');
            }
            // verify required parameter 'addressDto' is not null or undefined
            if (addressDto === null || addressDto === undefined) {
                throw new RequiredError('addressDto','Required parameter addressDto was null or undefined when calling createAddress.');
            }
            const localVarPath = `/api/v1/members/{memberId}/address`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addressDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addressDto !== undefined ? addressDto : {})
                : (addressDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {SettingsDto} settingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSettings: async (memberId: string, settingsDto: SettingsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling createSettings.');
            }
            // verify required parameter 'settingsDto' is not null or undefined
            if (settingsDto === null || settingsDto === undefined) {
                throw new RequiredError('settingsDto','Required parameter settingsDto was null or undefined when calling createSettings.');
            }
            const localVarPath = `/api/v1/members/{memberId}/settings`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof settingsDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(settingsDto !== undefined ? settingsDto : {})
                : (settingsDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvatar: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling deleteAvatar.');
            }
            const localVarPath = `/api/v1/members/{memberId}/deleteAvatar`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgetDevices: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling forgetDevices.');
            }
            const localVarPath = `/api/v1/members/{memberId}/devices`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fullSearchForProvider: async (memberId: string, key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling fullSearchForProvider.');
            }
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling fullSearchForProvider.');
            }
            const localVarPath = `/api/v1/members/{memberId}/fullSearchForProvider`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling get.');
            }
            const localVarPath = `/api/v1/members/{memberId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddress: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getAddress.');
            }
            const localVarPath = `/api/v1/members/{memberId}/address`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddresses: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getAddresses.');
            }
            const localVarPath = `/api/v1/members/{memberId}/addresses`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} deletedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberDeletionPDF: async (memberId: string, deletedId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMemberDeletionPDF.');
            }
            // verify required parameter 'deletedId' is not null or undefined
            if (deletedId === null || deletedId === undefined) {
                throw new RequiredError('deletedId','Required parameter deletedId was null or undefined when calling getMemberDeletionPDF.');
            }
            const localVarPath = `/api/v1/members/{memberId}/delete/print/{deletedId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)))
                .replace(`{${"deletedId"}}`, encodeURIComponent(String(deletedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMfaFields: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMfaFields.');
            }
            const localVarPath = `/api/v1/members/{memberId}/mfaFields`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings1: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getSettings1.');
            }
            const localVarPath = `/api/v1/members/{memberId}/settings`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public/members/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideLoginHint: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling hideLoginHint.');
            }
            const localVarPath = `/api/v1/members/{memberId}/loginHint`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchForManager: async (memberId: string, key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling searchForManager.');
            }
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling searchForManager.');
            }
            const localVarPath = `/api/v1/members/{memberId}/searchForManager`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchForProvider: async (memberId: string, key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling searchForProvider.');
            }
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling searchForProvider.');
            }
            const localVarPath = `/api/v1/members/{memberId}/searchForProvider`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGroupsForProvider: async (memberId: string, key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling searchGroupsForProvider.');
            }
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling searchGroupsForProvider.');
            }
            const localVarPath = `/api/v1/members/{memberId}/searchGroupsForProvider`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {MfaFieldsDto} mfaFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetMfaPhoneCode: async (memberId: string, mfaFieldsDto: MfaFieldsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling sendResetMfaPhoneCode.');
            }
            // verify required parameter 'mfaFieldsDto' is not null or undefined
            if (mfaFieldsDto === null || mfaFieldsDto === undefined) {
                throw new RequiredError('mfaFieldsDto','Required parameter mfaFieldsDto was null or undefined when calling sendResetMfaPhoneCode.');
            }
            const localVarPath = `/api/v1/members/{memberId}/sendResetMfaPhoneCode`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mfaFieldsDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mfaFieldsDto !== undefined ? mfaFieldsDto : {})
                : (mfaFieldsDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerPasswordReset: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling triggerPasswordReset.');
            }
            const localVarPath = `/api/v1/members/{memberId}/resetPassword`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1: async (memberId: string, memberDto: MemberDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling update1.');
            }
            // verify required parameter 'memberDto' is not null or undefined
            if (memberDto === null || memberDto === undefined) {
                throw new RequiredError('memberDto','Required parameter memberDto was null or undefined when calling update1.');
            }
            const localVarPath = `/api/v1/members/{memberId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof memberDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(memberDto !== undefined ? memberDto : {})
                : (memberDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {MfaFieldsDto} mfaFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMfaFields: async (memberId: string, mfaFieldsDto: MfaFieldsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling updateMfaFields.');
            }
            // verify required parameter 'mfaFieldsDto' is not null or undefined
            if (mfaFieldsDto === null || mfaFieldsDto === undefined) {
                throw new RequiredError('mfaFieldsDto','Required parameter mfaFieldsDto was null or undefined when calling updateMfaFields.');
            }
            const localVarPath = `/api/v1/members/{memberId}/mfaFields`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mfaFieldsDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mfaFieldsDto !== undefined ? mfaFieldsDto : {})
                : (mfaFieldsDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (memberId: string, updatePasswordDto: UpdatePasswordDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling updatePassword.');
            }
            // verify required parameter 'updatePasswordDto' is not null or undefined
            if (updatePasswordDto === null || updatePasswordDto === undefined) {
                throw new RequiredError('updatePasswordDto','Required parameter updatePasswordDto was null or undefined when calling updatePassword.');
            }
            const localVarPath = `/api/v1/members/{memberId}/password`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updatePasswordDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updatePasswordDto !== undefined ? updatePasswordDto : {})
                : (updatePasswordDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {boolean} option 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkipPreviewOption: async (memberId: string, option: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling updateSkipPreviewOption.');
            }
            // verify required parameter 'option' is not null or undefined
            if (option === null || option === undefined) {
                throw new RequiredError('option','Required parameter option was null or undefined when calling updateSkipPreviewOption.');
            }
            const localVarPath = `/api/v1/members/{memberId}/skipPreview`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (option !== undefined) {
                localVarQueryParameter['option'] = option;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAvatar: async (memberId: string, memberDto: MemberDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling uploadAvatar.');
            }
            // verify required parameter 'memberDto' is not null or undefined
            if (memberDto === null || memberDto === undefined) {
                throw new RequiredError('memberDto','Required parameter memberDto was null or undefined when calling uploadAvatar.');
            }
            const localVarPath = `/api/v1/members/{memberId}/uploadAvatar`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof memberDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(memberDto !== undefined ? memberDto : {})
                : (memberDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberResourceApi - functional programming interface
 * @export
 */
export const MemberResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} memberId 
         * @param {MemberPasswordDto} memberPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmPassword(memberId: string, memberPasswordDto: MemberPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).confirmPassword(memberId, memberPasswordDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {AddressDto} addressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAddress(memberId: string, addressDto: AddressDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).createAddress(memberId, addressDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {SettingsDto} settingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSettings(memberId: string, settingsDto: SettingsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).createSettings(memberId, settingsDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAvatar(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).deleteAvatar(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgetDevices(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).forgetDevices(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fullSearchForProvider(memberId: string, key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderSearchResultDto>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).fullSearchForProvider(memberId, key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberDto>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).get(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddress(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressDto>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getAddress(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddresses(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressDto>>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getAddresses(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} deletedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberDeletionPDF(memberId: string, deletedId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getMemberDeletionPDF(memberId, deletedId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMfaFields(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MfaFieldsDto>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getMfaFields(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings1(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsDto>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getSettings1(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberStatusDto>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getStatus(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hideLoginHint(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).hideLoginHint(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchForManager(memberId: string, key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberDto>>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).searchForManager(memberId, key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchForProvider(memberId: string, key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountDto>>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).searchForProvider(memberId, key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchGroupsForProvider(memberId: string, key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountGroupDto>>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).searchGroupsForProvider(memberId, key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {MfaFieldsDto} mfaFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendResetMfaPhoneCode(memberId: string, mfaFieldsDto: MfaFieldsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).sendResetMfaPhoneCode(memberId, mfaFieldsDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerPasswordReset(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).triggerPasswordReset(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update1(memberId: string, memberDto: MemberDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).update1(memberId, memberDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {MfaFieldsDto} mfaFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMfaFields(memberId: string, mfaFieldsDto: MfaFieldsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).updateMfaFields(memberId, mfaFieldsDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(memberId: string, updatePasswordDto: UpdatePasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).updatePassword(memberId, updatePasswordDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {boolean} option 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSkipPreviewOption(memberId: string, option: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).updateSkipPreviewOption(memberId, option, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAvatar(memberId: string, memberDto: MemberDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).uploadAvatar(memberId, memberDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MemberResourceApi - factory interface
 * @export
 */
export const MemberResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} memberId 
         * @param {MemberPasswordDto} memberPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPassword(memberId: string, memberPasswordDto: MemberPasswordDto, options?: any): AxiosPromise<boolean> {
            return MemberResourceApiFp(configuration).confirmPassword(memberId, memberPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {AddressDto} addressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddress(memberId: string, addressDto: AddressDto, options?: any): AxiosPromise<string> {
            return MemberResourceApiFp(configuration).createAddress(memberId, addressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {SettingsDto} settingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSettings(memberId: string, settingsDto: SettingsDto, options?: any): AxiosPromise<string> {
            return MemberResourceApiFp(configuration).createSettings(memberId, settingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvatar(memberId: string, options?: any): AxiosPromise<void> {
            return MemberResourceApiFp(configuration).deleteAvatar(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgetDevices(memberId: string, options?: any): AxiosPromise<void> {
            return MemberResourceApiFp(configuration).forgetDevices(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fullSearchForProvider(memberId: string, key: string, options?: any): AxiosPromise<ProviderSearchResultDto> {
            return MemberResourceApiFp(configuration).fullSearchForProvider(memberId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(memberId: string, options?: any): AxiosPromise<MemberDto> {
            return MemberResourceApiFp(configuration).get(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddress(memberId: string, options?: any): AxiosPromise<AddressDto> {
            return MemberResourceApiFp(configuration).getAddress(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddresses(memberId: string, options?: any): AxiosPromise<Array<AddressDto>> {
            return MemberResourceApiFp(configuration).getAddresses(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} deletedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberDeletionPDF(memberId: string, deletedId: string, options?: any): AxiosPromise<void> {
            return MemberResourceApiFp(configuration).getMemberDeletionPDF(memberId, deletedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMfaFields(memberId: string, options?: any): AxiosPromise<MfaFieldsDto> {
            return MemberResourceApiFp(configuration).getMfaFields(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings1(memberId: string, options?: any): AxiosPromise<SettingsDto> {
            return MemberResourceApiFp(configuration).getSettings1(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(options?: any): AxiosPromise<MemberStatusDto> {
            return MemberResourceApiFp(configuration).getStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideLoginHint(memberId: string, options?: any): AxiosPromise<void> {
            return MemberResourceApiFp(configuration).hideLoginHint(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchForManager(memberId: string, key: string, options?: any): AxiosPromise<Array<MemberDto>> {
            return MemberResourceApiFp(configuration).searchForManager(memberId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchForProvider(memberId: string, key: string, options?: any): AxiosPromise<Array<AccountDto>> {
            return MemberResourceApiFp(configuration).searchForProvider(memberId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGroupsForProvider(memberId: string, key: string, options?: any): AxiosPromise<Array<AccountGroupDto>> {
            return MemberResourceApiFp(configuration).searchGroupsForProvider(memberId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {MfaFieldsDto} mfaFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetMfaPhoneCode(memberId: string, mfaFieldsDto: MfaFieldsDto, options?: any): AxiosPromise<void> {
            return MemberResourceApiFp(configuration).sendResetMfaPhoneCode(memberId, mfaFieldsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerPasswordReset(memberId: string, options?: any): AxiosPromise<void> {
            return MemberResourceApiFp(configuration).triggerPasswordReset(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(memberId: string, memberDto: MemberDto, options?: any): AxiosPromise<void> {
            return MemberResourceApiFp(configuration).update1(memberId, memberDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {MfaFieldsDto} mfaFieldsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMfaFields(memberId: string, mfaFieldsDto: MfaFieldsDto, options?: any): AxiosPromise<void> {
            return MemberResourceApiFp(configuration).updateMfaFields(memberId, mfaFieldsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(memberId: string, updatePasswordDto: UpdatePasswordDto, options?: any): AxiosPromise<void> {
            return MemberResourceApiFp(configuration).updatePassword(memberId, updatePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {boolean} option 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkipPreviewOption(memberId: string, option: boolean, options?: any): AxiosPromise<void> {
            return MemberResourceApiFp(configuration).updateSkipPreviewOption(memberId, option, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAvatar(memberId: string, memberDto: MemberDto, options?: any): AxiosPromise<void> {
            return MemberResourceApiFp(configuration).uploadAvatar(memberId, memberDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MemberResourceApi - object-oriented interface
 * @export
 * @class MemberResourceApi
 * @extends {BaseAPI}
 */
export class MemberResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} memberId 
     * @param {MemberPasswordDto} memberPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public confirmPassword(memberId: string, memberPasswordDto: MemberPasswordDto, options?: any) {
        return MemberResourceApiFp(this.configuration).confirmPassword(memberId, memberPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {AddressDto} addressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public createAddress(memberId: string, addressDto: AddressDto, options?: any) {
        return MemberResourceApiFp(this.configuration).createAddress(memberId, addressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {SettingsDto} settingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public createSettings(memberId: string, settingsDto: SettingsDto, options?: any) {
        return MemberResourceApiFp(this.configuration).createSettings(memberId, settingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public deleteAvatar(memberId: string, options?: any) {
        return MemberResourceApiFp(this.configuration).deleteAvatar(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public forgetDevices(memberId: string, options?: any) {
        return MemberResourceApiFp(this.configuration).forgetDevices(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public fullSearchForProvider(memberId: string, key: string, options?: any) {
        return MemberResourceApiFp(this.configuration).fullSearchForProvider(memberId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public get(memberId: string, options?: any) {
        return MemberResourceApiFp(this.configuration).get(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getAddress(memberId: string, options?: any) {
        return MemberResourceApiFp(this.configuration).getAddress(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getAddresses(memberId: string, options?: any) {
        return MemberResourceApiFp(this.configuration).getAddresses(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {string} deletedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getMemberDeletionPDF(memberId: string, deletedId: string, options?: any) {
        return MemberResourceApiFp(this.configuration).getMemberDeletionPDF(memberId, deletedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getMfaFields(memberId: string, options?: any) {
        return MemberResourceApiFp(this.configuration).getMfaFields(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getSettings1(memberId: string, options?: any) {
        return MemberResourceApiFp(this.configuration).getSettings1(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getStatus(options?: any) {
        return MemberResourceApiFp(this.configuration).getStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public hideLoginHint(memberId: string, options?: any) {
        return MemberResourceApiFp(this.configuration).hideLoginHint(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public searchForManager(memberId: string, key: string, options?: any) {
        return MemberResourceApiFp(this.configuration).searchForManager(memberId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public searchForProvider(memberId: string, key: string, options?: any) {
        return MemberResourceApiFp(this.configuration).searchForProvider(memberId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public searchGroupsForProvider(memberId: string, key: string, options?: any) {
        return MemberResourceApiFp(this.configuration).searchGroupsForProvider(memberId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {MfaFieldsDto} mfaFieldsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public sendResetMfaPhoneCode(memberId: string, mfaFieldsDto: MfaFieldsDto, options?: any) {
        return MemberResourceApiFp(this.configuration).sendResetMfaPhoneCode(memberId, mfaFieldsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public triggerPasswordReset(memberId: string, options?: any) {
        return MemberResourceApiFp(this.configuration).triggerPasswordReset(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {MemberDto} memberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public update1(memberId: string, memberDto: MemberDto, options?: any) {
        return MemberResourceApiFp(this.configuration).update1(memberId, memberDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {MfaFieldsDto} mfaFieldsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public updateMfaFields(memberId: string, mfaFieldsDto: MfaFieldsDto, options?: any) {
        return MemberResourceApiFp(this.configuration).updateMfaFields(memberId, mfaFieldsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {UpdatePasswordDto} updatePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public updatePassword(memberId: string, updatePasswordDto: UpdatePasswordDto, options?: any) {
        return MemberResourceApiFp(this.configuration).updatePassword(memberId, updatePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {boolean} option 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public updateSkipPreviewOption(memberId: string, option: boolean, options?: any) {
        return MemberResourceApiFp(this.configuration).updateSkipPreviewOption(memberId, option, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {MemberDto} memberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public uploadAvatar(memberId: string, memberDto: MemberDto, options?: any) {
        return MemberResourceApiFp(this.configuration).uploadAvatar(memberId, memberDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OraResourceApi - axios parameter creator
 * @export
 */
export const OraResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AppointmentOraInfoDto} appointmentOraInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (appointmentOraInfoDto: AppointmentOraInfoDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentOraInfoDto' is not null or undefined
            if (appointmentOraInfoDto === null || appointmentOraInfoDto === undefined) {
                throw new RequiredError('appointmentOraInfoDto','Required parameter appointmentOraInfoDto was null or undefined when calling _delete.');
            }
            const localVarPath = `/api/v1/public/ora/appointments/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof appointmentOraInfoDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(appointmentOraInfoDto !== undefined ? appointmentOraInfoDto : {})
                : (appointmentOraInfoDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppointmentOraDto} appointmentOraDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (appointmentOraDto: AppointmentOraDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentOraDto' is not null or undefined
            if (appointmentOraDto === null || appointmentOraDto === undefined) {
                throw new RequiredError('appointmentOraDto','Required parameter appointmentOraDto was null or undefined when calling create.');
            }
            const localVarPath = `/api/v1/public/ora/appointments/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof appointmentOraDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(appointmentOraDto !== undefined ? appointmentOraDto : {})
                : (appointmentOraDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppointmentOraInfoDto} appointmentOraInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentInfoPDF: async (appointmentOraInfoDto: AppointmentOraInfoDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentOraInfoDto' is not null or undefined
            if (appointmentOraInfoDto === null || appointmentOraInfoDto === undefined) {
                throw new RequiredError('appointmentOraInfoDto','Required parameter appointmentOraInfoDto was null or undefined when calling getAppointmentInfoPDF.');
            }
            const localVarPath = `/api/v1/public/ora/appointments/appointmentInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof appointmentOraInfoDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(appointmentOraInfoDto !== undefined ? appointmentOraInfoDto : {})
                : (appointmentOraInfoDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppointmentOraDto} appointmentOraDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (appointmentOraDto: AppointmentOraDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentOraDto' is not null or undefined
            if (appointmentOraDto === null || appointmentOraDto === undefined) {
                throw new RequiredError('appointmentOraDto','Required parameter appointmentOraDto was null or undefined when calling update.');
            }
            const localVarPath = `/api/v1/public/ora/appointments/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof appointmentOraDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(appointmentOraDto !== undefined ? appointmentOraDto : {})
                : (appointmentOraDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OraResourceApi - functional programming interface
 * @export
 */
export const OraResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AppointmentOraInfoDto} appointmentOraInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(appointmentOraInfoDto: AppointmentOraInfoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OraResourceApiAxiosParamCreator(configuration)._delete(appointmentOraInfoDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AppointmentOraDto} appointmentOraDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(appointmentOraDto: AppointmentOraDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await OraResourceApiAxiosParamCreator(configuration).create(appointmentOraDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AppointmentOraInfoDto} appointmentOraInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentInfoPDF(appointmentOraInfoDto: AppointmentOraInfoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OraResourceApiAxiosParamCreator(configuration).getAppointmentInfoPDF(appointmentOraInfoDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AppointmentOraDto} appointmentOraDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(appointmentOraDto: AppointmentOraDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await OraResourceApiAxiosParamCreator(configuration).update(appointmentOraDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OraResourceApi - factory interface
 * @export
 */
export const OraResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {AppointmentOraInfoDto} appointmentOraInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(appointmentOraInfoDto: AppointmentOraInfoDto, options?: any): AxiosPromise<void> {
            return OraResourceApiFp(configuration)._delete(appointmentOraInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppointmentOraDto} appointmentOraDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(appointmentOraDto: AppointmentOraDto, options?: any): AxiosPromise<string> {
            return OraResourceApiFp(configuration).create(appointmentOraDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppointmentOraInfoDto} appointmentOraInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentInfoPDF(appointmentOraInfoDto: AppointmentOraInfoDto, options?: any): AxiosPromise<void> {
            return OraResourceApiFp(configuration).getAppointmentInfoPDF(appointmentOraInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppointmentOraDto} appointmentOraDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(appointmentOraDto: AppointmentOraDto, options?: any): AxiosPromise<string> {
            return OraResourceApiFp(configuration).update(appointmentOraDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OraResourceApi - object-oriented interface
 * @export
 * @class OraResourceApi
 * @extends {BaseAPI}
 */
export class OraResourceApi extends BaseAPI {
    /**
     * 
     * @param {AppointmentOraInfoDto} appointmentOraInfoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OraResourceApi
     */
    public _delete(appointmentOraInfoDto: AppointmentOraInfoDto, options?: any) {
        return OraResourceApiFp(this.configuration)._delete(appointmentOraInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppointmentOraDto} appointmentOraDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OraResourceApi
     */
    public create(appointmentOraDto: AppointmentOraDto, options?: any) {
        return OraResourceApiFp(this.configuration).create(appointmentOraDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppointmentOraInfoDto} appointmentOraInfoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OraResourceApi
     */
    public getAppointmentInfoPDF(appointmentOraInfoDto: AppointmentOraInfoDto, options?: any) {
        return OraResourceApiFp(this.configuration).getAppointmentInfoPDF(appointmentOraInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppointmentOraDto} appointmentOraDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OraResourceApi
     */
    public update(appointmentOraDto: AppointmentOraDto, options?: any) {
        return OraResourceApiFp(this.configuration).update(appointmentOraDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParticipantResourceApi - axios parameter creator
 * @export
 */
export const ParticipantResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<CmsAnswerDto>} cmsAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAnswers1: async (cmsAnswerDto: Array<CmsAnswerDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cmsAnswerDto' is not null or undefined
            if (cmsAnswerDto === null || cmsAnswerDto === undefined) {
                throw new RequiredError('cmsAnswerDto','Required parameter cmsAnswerDto was null or undefined when calling addAnswers1.');
            }
            const localVarPath = `/api/v1/public/participants/answers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cmsAnswerDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cmsAnswerDto !== undefined ? cmsAnswerDto : {})
                : (cmsAnswerDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipant: async (publicId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            if (publicId === null || publicId === undefined) {
                throw new RequiredError('publicId','Required parameter publicId was null or undefined when calling getParticipant.');
            }
            const localVarPath = `/api/v1/participants/{publicId}`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantByPublicId: async (publicId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            if (publicId === null || publicId === undefined) {
                throw new RequiredError('publicId','Required parameter publicId was null or undefined when calling getParticipantByPublicId.');
            }
            const localVarPath = `/api/v1/public/participants/{publicId}`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantGroups: async (ids: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling getParticipantGroups.');
            }
            const localVarPath = `/api/v1/participants/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} publicIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipants: async (publicIds: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicIds' is not null or undefined
            if (publicIds === null || publicIds === undefined) {
                throw new RequiredError('publicIds','Required parameter publicIds was null or undefined when calling getParticipants.');
            }
            const localVarPath = `/api/v1/participants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicIds) {
                localVarQueryParameter['publicIds'] = publicIds;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (participantId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            if (participantId === null || participantId === undefined) {
                throw new RequiredError('participantId','Required parameter participantId was null or undefined when calling remove.');
            }
            const localVarPath = `/api/v1/participants/{participantId}`
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAgreement: async (participantId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            if (participantId === null || participantId === undefined) {
                throw new RequiredError('participantId','Required parameter participantId was null or undefined when calling saveAgreement.');
            }
            const localVarPath = `/api/v1/participants/{participantId}/agreement`
                .replace(`{${"participantId"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParticipantResourceApi - functional programming interface
 * @export
 */
export const ParticipantResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<CmsAnswerDto>} cmsAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAnswers1(cmsAnswerDto: Array<CmsAnswerDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ParticipantResourceApiAxiosParamCreator(configuration).addAnswers1(cmsAnswerDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipant(publicId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantDto>> {
            const localVarAxiosArgs = await ParticipantResourceApiAxiosParamCreator(configuration).getParticipant(publicId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipantByPublicId(publicId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LightParticipantDto>> {
            const localVarAxiosArgs = await ParticipantResourceApiAxiosParamCreator(configuration).getParticipantByPublicId(publicId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipantGroups(ids: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParticipantGroupDto>>> {
            const localVarAxiosArgs = await ParticipantResourceApiAxiosParamCreator(configuration).getParticipantGroups(ids, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} publicIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipants(publicIds: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParticipantDto>>> {
            const localVarAxiosArgs = await ParticipantResourceApiAxiosParamCreator(configuration).getParticipants(publicIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(participantId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ParticipantResourceApiAxiosParamCreator(configuration).remove(participantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAgreement(participantId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ParticipantResourceApiAxiosParamCreator(configuration).saveAgreement(participantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ParticipantResourceApi - factory interface
 * @export
 */
export const ParticipantResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {Array<CmsAnswerDto>} cmsAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAnswers1(cmsAnswerDto: Array<CmsAnswerDto>, options?: any): AxiosPromise<void> {
            return ParticipantResourceApiFp(configuration).addAnswers1(cmsAnswerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipant(publicId: string, options?: any): AxiosPromise<ParticipantDto> {
            return ParticipantResourceApiFp(configuration).getParticipant(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantByPublicId(publicId: string, options?: any): AxiosPromise<LightParticipantDto> {
            return ParticipantResourceApiFp(configuration).getParticipantByPublicId(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantGroups(ids: Array<string>, options?: any): AxiosPromise<Array<ParticipantGroupDto>> {
            return ParticipantResourceApiFp(configuration).getParticipantGroups(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} publicIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipants(publicIds: Array<string>, options?: any): AxiosPromise<Array<ParticipantDto>> {
            return ParticipantResourceApiFp(configuration).getParticipants(publicIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(participantId: string, options?: any): AxiosPromise<void> {
            return ParticipantResourceApiFp(configuration).remove(participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAgreement(participantId: string, options?: any): AxiosPromise<void> {
            return ParticipantResourceApiFp(configuration).saveAgreement(participantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParticipantResourceApi - object-oriented interface
 * @export
 * @class ParticipantResourceApi
 * @extends {BaseAPI}
 */
export class ParticipantResourceApi extends BaseAPI {
    /**
     * 
     * @param {Array<CmsAnswerDto>} cmsAnswerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantResourceApi
     */
    public addAnswers1(cmsAnswerDto: Array<CmsAnswerDto>, options?: any) {
        return ParticipantResourceApiFp(this.configuration).addAnswers1(cmsAnswerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantResourceApi
     */
    public getParticipant(publicId: string, options?: any) {
        return ParticipantResourceApiFp(this.configuration).getParticipant(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantResourceApi
     */
    public getParticipantByPublicId(publicId: string, options?: any) {
        return ParticipantResourceApiFp(this.configuration).getParticipantByPublicId(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantResourceApi
     */
    public getParticipantGroups(ids: Array<string>, options?: any) {
        return ParticipantResourceApiFp(this.configuration).getParticipantGroups(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} publicIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantResourceApi
     */
    public getParticipants(publicIds: Array<string>, options?: any) {
        return ParticipantResourceApiFp(this.configuration).getParticipants(publicIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantResourceApi
     */
    public remove(participantId: string, options?: any) {
        return ParticipantResourceApiFp(this.configuration).remove(participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantResourceApi
     */
    public saveAgreement(participantId: string, options?: any) {
        return ParticipantResourceApiFp(this.configuration).saveAgreement(participantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PracticeResourceApi - axios parameter creator
 * @export
 */
export const PracticeResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} practiceId 
         * @param {string} questionId 
         * @param {CmsAnswerDto} cmsAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParticipantAnswer: async (practiceId: string, questionId: string, cmsAnswerDto: CmsAnswerDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling addParticipantAnswer.');
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling addParticipantAnswer.');
            }
            // verify required parameter 'cmsAnswerDto' is not null or undefined
            if (cmsAnswerDto === null || cmsAnswerDto === undefined) {
                throw new RequiredError('cmsAnswerDto','Required parameter cmsAnswerDto was null or undefined when calling addParticipantAnswer.');
            }
            const localVarPath = `/api/v1/public/practices/{practiceId}/cms/answers`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cmsAnswerDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cmsAnswerDto !== undefined ? cmsAnswerDto : {})
                : (cmsAnswerDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {Array<CmsAnswerDto>} cmsAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPracticeAnswers: async (practiceId: string, cmsAnswerDto: Array<CmsAnswerDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling addPracticeAnswers.');
            }
            // verify required parameter 'cmsAnswerDto' is not null or undefined
            if (cmsAnswerDto === null || cmsAnswerDto === undefined) {
                throw new RequiredError('cmsAnswerDto','Required parameter cmsAnswerDto was null or undefined when calling addPracticeAnswers.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/cms/answers`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cmsAnswerDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cmsAnswerDto !== undefined ? cmsAnswerDto : {})
                : (cmsAnswerDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkGroupNameAvailability: async (practiceId: string, memberId: string, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling checkGroupNameAvailability.');
            }
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling checkGroupNameAvailability.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling checkGroupNameAvailability.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/groups/groupName`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMembers: async (practiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling countMembers.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/members/count`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {AccountDto} accountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: async (practiceId: string, accountDto: AccountDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling createAccount.');
            }
            // verify required parameter 'accountDto' is not null or undefined
            if (accountDto === null || accountDto === undefined) {
                throw new RequiredError('accountDto','Required parameter accountDto was null or undefined when calling createAccount.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/accounts`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof accountDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(accountDto !== undefined ? accountDto : {})
                : (accountDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {AccountGroupDto} accountGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountGroup: async (practiceId: string, accountGroupDto: AccountGroupDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling createAccountGroup.');
            }
            // verify required parameter 'accountGroupDto' is not null or undefined
            if (accountGroupDto === null || accountGroupDto === undefined) {
                throw new RequiredError('accountGroupDto','Required parameter accountGroupDto was null or undefined when calling createAccountGroup.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/accounts/groups`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof accountGroupDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(accountGroupDto !== undefined ? accountGroupDto : {})
                : (accountGroupDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProvider: async (practiceId: string, memberDto: MemberDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling createProvider.');
            }
            // verify required parameter 'memberDto' is not null or undefined
            if (memberDto === null || memberDto === undefined) {
                throw new RequiredError('memberDto','Required parameter memberDto was null or undefined when calling createProvider.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/providers`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof memberDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(memberDto !== undefined ? memberDto : {})
                : (memberDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {CmsQuestionDto} cmsQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProviderQuestion: async (practiceId: string, cmsId: number, cmsQuestionDto: CmsQuestionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling createProviderQuestion.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling createProviderQuestion.');
            }
            // verify required parameter 'cmsQuestionDto' is not null or undefined
            if (cmsQuestionDto === null || cmsQuestionDto === undefined) {
                throw new RequiredError('cmsQuestionDto','Required parameter cmsQuestionDto was null or undefined when calling createProviderQuestion.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/cms/questions`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmsId !== undefined) {
                localVarQueryParameter['cmsId'] = cmsId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cmsQuestionDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cmsQuestionDto !== undefined ? cmsQuestionDto : {})
                : (cmsQuestionDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {Array<string>} groupIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountGroups: async (practiceId: string, groupIds: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling deleteAccountGroups.');
            }
            // verify required parameter 'groupIds' is not null or undefined
            if (groupIds === null || groupIds === undefined) {
                throw new RequiredError('groupIds','Required parameter groupIds was null or undefined when calling deleteAccountGroups.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/accounts/groups`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (groupIds) {
                localVarQueryParameter['groupIds'] = groupIds;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {Array<string>} accountIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccounts: async (practiceId: string, accountIds: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling deleteAccounts.');
            }
            // verify required parameter 'accountIds' is not null or undefined
            if (accountIds === null || accountIds === undefined) {
                throw new RequiredError('accountIds','Required parameter accountIds was null or undefined when calling deleteAccounts.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/accounts`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountIds) {
                localVarQueryParameter['accountIds'] = accountIds;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProvider: async (practiceId: string, memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling deleteProvider.');
            }
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling deleteProvider.');
            }
            const localVarPath = `/api/v1/members/{practiceId}/providers/{memberId}`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)))
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestion1: async (practiceId: string, cmsId: number, questionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling deleteQuestion1.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling deleteQuestion1.');
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling deleteQuestion1.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/cms/questions`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmsId !== undefined) {
                localVarQueryParameter['cmsId'] = cmsId;
            }

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCmsToCSV1: async (practiceId: string, cmsId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling exportCmsToCSV1.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling exportCmsToCSV1.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/cms/{cmsId}/export/csv`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)))
                .replace(`{${"cmsId"}}`, encodeURIComponent(String(cmsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportQuestionToCSV1: async (practiceId: string, cmsId: number, questionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling exportQuestionToCSV1.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling exportQuestionToCSV1.');
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling exportQuestionToCSV1.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/cms/{cmsId}/questions/export/csv`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)))
                .replace(`{${"cmsId"}}`, encodeURIComponent(String(cmsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {'ALL' | 'ACTIVE' | 'INACTIVE'} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterMembers: async (practiceId: string, filter: 'ALL' | 'ACTIVE' | 'INACTIVE', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling filterMembers.');
            }
            // verify required parameter 'filter' is not null or undefined
            if (filter === null || filter === undefined) {
                throw new RequiredError('filter','Required parameter filter was null or undefined when calling filterMembers.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/members/filter`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (practiceId: string, accountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getAccount.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getAccount.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/accounts/{accountId}`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountGroup: async (practiceId: string, groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getAccountGroup.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling getAccountGroup.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/accounts/groups/{groupId}`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountGroups: async (practiceId: string, memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getAccountGroups.');
            }
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getAccountGroups.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/accounts/groups`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts: async (practiceId: string, memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getAccounts.');
            }
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getAccounts.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/accounts`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {Array<string>} publicIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsByPublicIds: async (practiceId: string, publicIds: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getAccountsByPublicIds.');
            }
            // verify required parameter 'publicIds' is not null or undefined
            if (publicIds === null || publicIds === undefined) {
                throw new RequiredError('publicIds','Required parameter publicIds was null or undefined when calling getAccountsByPublicIds.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/accounts/search`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicIds) {
                localVarQueryParameter['publicIds'] = publicIds;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLightPracticeAnswersForQuestion: async (practiceId: string, cmsId: number, questionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getLightPracticeAnswersForQuestion.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling getLightPracticeAnswersForQuestion.');
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling getLightPracticeAnswersForQuestion.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/cms/answers/{questionId}`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)))
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmsId !== undefined) {
                localVarQueryParameter['cmsId'] = cmsId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} practiceId 
         * @param {number} from 
         * @param {number} thru 
         * @param {boolean} onlyFinished 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingsForProviderBetween: async (memberId: string, practiceId: string, from: number, thru: number, onlyFinished: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMeetingsForProviderBetween.');
            }
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getMeetingsForProviderBetween.');
            }
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling getMeetingsForProviderBetween.');
            }
            // verify required parameter 'thru' is not null or undefined
            if (thru === null || thru === undefined) {
                throw new RequiredError('thru','Required parameter thru was null or undefined when calling getMeetingsForProviderBetween.');
            }
            // verify required parameter 'onlyFinished' is not null or undefined
            if (onlyFinished === null || onlyFinished === undefined) {
                throw new RequiredError('onlyFinished','Required parameter onlyFinished was null or undefined when calling getMeetingsForProviderBetween.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/provider/{memberId}/meetings`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)))
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (thru !== undefined) {
                localVarQueryParameter['thru'] = thru;
            }

            if (onlyFinished !== undefined) {
                localVarQueryParameter['onlyFinished'] = onlyFinished;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembers: async (practiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getMembers.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/members`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {'EMAIL' | 'SMS'} type 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationByPracticeIdAndTypeAndName: async (practiceId: string, type: 'EMAIL' | 'SMS', name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getNotificationByPracticeIdAndTypeAndName.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNotificationByPracticeIdAndTypeAndName.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getNotificationByPracticeIdAndTypeAndName.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/notifications/{type}`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (practiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getNotifications.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/notifications`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnerPractice: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getOwnerPractice.');
            }
            const localVarPath = `/api/v1/practices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} [questionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantAnswers: async (practiceId: string, cmsId: number, questionId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getParticipantAnswers.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling getParticipantAnswers.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/cms/participantAnswers`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmsId !== undefined) {
                localVarQueryParameter['cmsId'] = cmsId;
            }

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantDeletionStatus: async (practiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getParticipantDeletionStatus.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/participantDeletionStatus`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantDeletionStatusPDF: async (practiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getParticipantDeletionStatusPDF.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/participantDeletionStatus/print`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} from 
         * @param {number} thru 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantFeedback: async (practiceId: string, from: number, thru: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getParticipantFeedback.');
            }
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling getParticipantFeedback.');
            }
            // verify required parameter 'thru' is not null or undefined
            if (thru === null || thru === undefined) {
                throw new RequiredError('thru','Required parameter thru was null or undefined when calling getParticipantFeedback.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/participant/feedback`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (thru !== undefined) {
                localVarQueryParameter['thru'] = thru;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantQuestions: async (practiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getParticipantQuestions.');
            }
            const localVarPath = `/api/v1/public/practices/{practiceId}/cms`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractice: async (practiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getPractice.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} [questionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeAnswers: async (practiceId: string, cmsId: number, questionId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getPracticeAnswers.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling getPracticeAnswers.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/cms/answers`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmsId !== undefined) {
                localVarQueryParameter['cmsId'] = cmsId;
            }

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeQuestions: async (practiceId: string, cmsId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getPracticeQuestions.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling getPracticeQuestions.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/cms/questions`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmsId !== undefined) {
                localVarQueryParameter['cmsId'] = cmsId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionsByType1: async (practiceId: string, type: 'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getQuestionsByType1.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getQuestionsByType1.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/cms`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (practiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getSettings.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/settings`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetNotification: async (practiceId: string, notificationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling resetNotification.');
            }
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling resetNotification.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/notification/{notificationId}/reset`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)))
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {AppSettingsDto} appSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSettings: async (practiceId: string, appSettingsDto: AppSettingsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling saveSettings.');
            }
            // verify required parameter 'appSettingsDto' is not null or undefined
            if (appSettingsDto === null || appSettingsDto === undefined) {
                throw new RequiredError('appSettingsDto','Required parameter appSettingsDto was null or undefined when calling saveSettings.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/settings`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof appSettingsDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(appSettingsDto !== undefined ? appSettingsDto : {})
                : (appSettingsDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendParticipantDeletionStatusEmail: async (practiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling sendParticipantDeletionStatusEmail.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/participantDeletionStatus/email`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} years 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setParticipantDeletionPeriod: async (practiceId: string, years: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling setParticipantDeletionPeriod.');
            }
            // verify required parameter 'years' is not null or undefined
            if (years === null || years === undefined) {
                throw new RequiredError('years','Required parameter years was null or undefined when calling setParticipantDeletionPeriod.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/participantDeletionPeriod`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (years !== undefined) {
                localVarQueryParameter['years'] = years;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {SetupDto} setupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupPractice: async (practiceId: string, setupDto: SetupDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling setupPractice.');
            }
            // verify required parameter 'setupDto' is not null or undefined
            if (setupDto === null || setupDto === undefined) {
                throw new RequiredError('setupDto','Required parameter setupDto was null or undefined when calling setupPractice.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/setup`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof setupDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(setupDto !== undefined ? setupDto : {})
                : (setupDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} groupId 
         * @param {AccountGroupDto} accountGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountGroup: async (practiceId: string, groupId: string, accountGroupDto: AccountGroupDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling updateAccountGroup.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling updateAccountGroup.');
            }
            // verify required parameter 'accountGroupDto' is not null or undefined
            if (accountGroupDto === null || accountGroupDto === undefined) {
                throw new RequiredError('accountGroupDto','Required parameter accountGroupDto was null or undefined when calling updateAccountGroup.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/accounts/groups/{groupId}`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof accountGroupDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(accountGroupDto !== undefined ? accountGroupDto : {})
                : (accountGroupDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMember: async (practiceId: string, memberId: string, memberDto: MemberDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling updateMember.');
            }
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling updateMember.');
            }
            // verify required parameter 'memberDto' is not null or undefined
            if (memberDto === null || memberDto === undefined) {
                throw new RequiredError('memberDto','Required parameter memberDto was null or undefined when calling updateMember.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/members/{memberId}`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)))
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof memberDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(memberDto !== undefined ? memberDto : {})
                : (memberDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification: async (practiceId: string, notificationDto: NotificationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling updateNotification.');
            }
            // verify required parameter 'notificationDto' is not null or undefined
            if (notificationDto === null || notificationDto === undefined) {
                throw new RequiredError('notificationDto','Required parameter notificationDto was null or undefined when calling updateNotification.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/notifications`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof notificationDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(notificationDto !== undefined ? notificationDto : {})
                : (notificationDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {PracticeDto} practiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePractice: async (practiceId: string, practiceDto: PracticeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling updatePractice.');
            }
            // verify required parameter 'practiceDto' is not null or undefined
            if (practiceDto === null || practiceDto === undefined) {
                throw new RequiredError('practiceDto','Required parameter practiceDto was null or undefined when calling updatePractice.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof practiceDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(practiceDto !== undefined ? practiceDto : {})
                : (practiceDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {CmsQuestionDto} cmsQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestion1: async (practiceId: string, cmsId: number, questionId: string, cmsQuestionDto: CmsQuestionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling updateQuestion1.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling updateQuestion1.');
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling updateQuestion1.');
            }
            // verify required parameter 'cmsQuestionDto' is not null or undefined
            if (cmsQuestionDto === null || cmsQuestionDto === undefined) {
                throw new RequiredError('cmsQuestionDto','Required parameter cmsQuestionDto was null or undefined when calling updateQuestion1.');
            }
            const localVarPath = `/api/v1/practices/{practiceId}/cms/questions`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmsId !== undefined) {
                localVarQueryParameter['cmsId'] = cmsId;
            }

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cmsQuestionDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cmsQuestionDto !== undefined ? cmsQuestionDto : {})
                : (cmsQuestionDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PracticeResourceApi - functional programming interface
 * @export
 */
export const PracticeResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} practiceId 
         * @param {string} questionId 
         * @param {CmsAnswerDto} cmsAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addParticipantAnswer(practiceId: string, questionId: string, cmsAnswerDto: CmsAnswerDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmsAnswerDto>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).addParticipantAnswer(practiceId, questionId, cmsAnswerDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {Array<CmsAnswerDto>} cmsAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPracticeAnswers(practiceId: string, cmsAnswerDto: Array<CmsAnswerDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CmsAnswerDto>>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).addPracticeAnswers(practiceId, cmsAnswerDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkGroupNameAvailability(practiceId: string, memberId: string, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).checkGroupNameAvailability(practiceId, memberId, name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countMembers(practiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberCountDto>>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).countMembers(practiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {AccountDto} accountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccount(practiceId: string, accountDto: AccountDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).createAccount(practiceId, accountDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {AccountGroupDto} accountGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccountGroup(practiceId: string, accountGroupDto: AccountGroupDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).createAccountGroup(practiceId, accountGroupDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProvider(practiceId: string, memberDto: MemberDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberDto>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).createProvider(practiceId, memberDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {CmsQuestionDto} cmsQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProviderQuestion(practiceId: string, cmsId: number, cmsQuestionDto: CmsQuestionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).createProviderQuestion(practiceId, cmsId, cmsQuestionDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {Array<string>} groupIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccountGroups(practiceId: string, groupIds: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).deleteAccountGroups(practiceId, groupIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {Array<string>} accountIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccounts(practiceId: string, accountIds: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).deleteAccounts(practiceId, accountIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProvider(practiceId: string, memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).deleteProvider(practiceId, memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQuestion1(practiceId: string, cmsId: number, questionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).deleteQuestion1(practiceId, cmsId, questionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCmsToCSV1(practiceId: string, cmsId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).exportCmsToCSV1(practiceId, cmsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportQuestionToCSV1(practiceId: string, cmsId: number, questionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).exportQuestionToCSV1(practiceId, cmsId, questionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {'ALL' | 'ACTIVE' | 'INACTIVE'} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterMembers(practiceId: string, filter: 'ALL' | 'ACTIVE' | 'INACTIVE', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberDto>>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).filterMembers(practiceId, filter, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(practiceId: string, accountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getAccount(practiceId, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountGroup(practiceId: string, groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountGroupDto>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getAccountGroup(practiceId, groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountGroups(practiceId: string, memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountGroupDto>>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getAccountGroups(practiceId, memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccounts(practiceId: string, memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountDto>>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getAccounts(practiceId, memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {Array<string>} publicIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsByPublicIds(practiceId: string, publicIds: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountDto>>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getAccountsByPublicIds(practiceId, publicIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLightPracticeAnswersForQuestion(practiceId: string, cmsId: number, questionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LightCmsAnswerDto>>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getLightPracticeAnswersForQuestion(practiceId, cmsId, questionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} practiceId 
         * @param {number} from 
         * @param {number} thru 
         * @param {boolean} onlyFinished 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingsForProviderBetween(memberId: string, practiceId: string, from: number, thru: number, onlyFinished: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeetingDto>>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getMeetingsForProviderBetween(memberId, practiceId, from, thru, onlyFinished, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembers(practiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberDto>>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getMembers(practiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {'EMAIL' | 'SMS'} type 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationByPracticeIdAndTypeAndName(practiceId: string, type: 'EMAIL' | 'SMS', name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getNotificationByPracticeIdAndTypeAndName(practiceId, type, name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(practiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDto>>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getNotifications(practiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnerPractice(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeDto>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getOwnerPractice(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} [questionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipantAnswers(practiceId: string, cmsId: number, questionId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<ParticipantAnswerGroupDto>; }>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getParticipantAnswers(practiceId, cmsId, questionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipantDeletionStatus(practiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantDeletionStatusDto>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getParticipantDeletionStatus(practiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipantDeletionStatusPDF(practiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getParticipantDeletionStatusPDF(practiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} from 
         * @param {number} thru 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipantFeedback(practiceId: string, from: number, thru: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<ParticipantAnswerGroupDto>; }>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getParticipantFeedback(practiceId, from, thru, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipantQuestions(practiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CmsQuestionDto>>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getParticipantQuestions(practiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPractice(practiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeDto>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getPractice(practiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} [questionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeAnswers(practiceId: string, cmsId: number, questionId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<MemberAnswerGroupDto>; }>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getPracticeAnswers(practiceId, cmsId, questionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeQuestions(practiceId: string, cmsId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CmsQuestionDto>>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getPracticeQuestions(practiceId, cmsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionsByType1(practiceId: string, type: 'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CmsQuestionDto>>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getQuestionsByType1(practiceId, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(practiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppSettingsDto>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).getSettings(practiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetNotification(practiceId: string, notificationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).resetNotification(practiceId, notificationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {AppSettingsDto} appSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSettings(practiceId: string, appSettingsDto: AppSettingsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeDto>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).saveSettings(practiceId, appSettingsDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendParticipantDeletionStatusEmail(practiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).sendParticipantDeletionStatusEmail(practiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} years 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setParticipantDeletionPeriod(practiceId: string, years: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).setParticipantDeletionPeriod(practiceId, years, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {SetupDto} setupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setupPractice(practiceId: string, setupDto: SetupDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).setupPractice(practiceId, setupDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} groupId 
         * @param {AccountGroupDto} accountGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountGroup(practiceId: string, groupId: string, accountGroupDto: AccountGroupDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).updateAccountGroup(practiceId, groupId, accountGroupDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMember(practiceId: string, memberId: string, memberDto: MemberDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).updateMember(practiceId, memberId, memberDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotification(practiceId: string, notificationDto: NotificationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).updateNotification(practiceId, notificationDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {PracticeDto} practiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePractice(practiceId: string, practiceDto: PracticeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).updatePractice(practiceId, practiceDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {CmsQuestionDto} cmsQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuestion1(practiceId: string, cmsId: number, questionId: string, cmsQuestionDto: CmsQuestionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeResourceApiAxiosParamCreator(configuration).updateQuestion1(practiceId, cmsId, questionId, cmsQuestionDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PracticeResourceApi - factory interface
 * @export
 */
export const PracticeResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} practiceId 
         * @param {string} questionId 
         * @param {CmsAnswerDto} cmsAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParticipantAnswer(practiceId: string, questionId: string, cmsAnswerDto: CmsAnswerDto, options?: any): AxiosPromise<CmsAnswerDto> {
            return PracticeResourceApiFp(configuration).addParticipantAnswer(practiceId, questionId, cmsAnswerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {Array<CmsAnswerDto>} cmsAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPracticeAnswers(practiceId: string, cmsAnswerDto: Array<CmsAnswerDto>, options?: any): AxiosPromise<Array<CmsAnswerDto>> {
            return PracticeResourceApiFp(configuration).addPracticeAnswers(practiceId, cmsAnswerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkGroupNameAvailability(practiceId: string, memberId: string, name: string, options?: any): AxiosPromise<boolean> {
            return PracticeResourceApiFp(configuration).checkGroupNameAvailability(practiceId, memberId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMembers(practiceId: string, options?: any): AxiosPromise<Array<MemberCountDto>> {
            return PracticeResourceApiFp(configuration).countMembers(practiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {AccountDto} accountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(practiceId: string, accountDto: AccountDto, options?: any): AxiosPromise<boolean> {
            return PracticeResourceApiFp(configuration).createAccount(practiceId, accountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {AccountGroupDto} accountGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountGroup(practiceId: string, accountGroupDto: AccountGroupDto, options?: any): AxiosPromise<boolean> {
            return PracticeResourceApiFp(configuration).createAccountGroup(practiceId, accountGroupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProvider(practiceId: string, memberDto: MemberDto, options?: any): AxiosPromise<MemberDto> {
            return PracticeResourceApiFp(configuration).createProvider(practiceId, memberDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {CmsQuestionDto} cmsQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProviderQuestion(practiceId: string, cmsId: number, cmsQuestionDto: CmsQuestionDto, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).createProviderQuestion(practiceId, cmsId, cmsQuestionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {Array<string>} groupIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountGroups(practiceId: string, groupIds: Array<string>, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).deleteAccountGroups(practiceId, groupIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {Array<string>} accountIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccounts(practiceId: string, accountIds: Array<string>, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).deleteAccounts(practiceId, accountIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProvider(practiceId: string, memberId: string, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).deleteProvider(practiceId, memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestion1(practiceId: string, cmsId: number, questionId: string, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).deleteQuestion1(practiceId, cmsId, questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCmsToCSV1(practiceId: string, cmsId: number, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).exportCmsToCSV1(practiceId, cmsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportQuestionToCSV1(practiceId: string, cmsId: number, questionId: string, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).exportQuestionToCSV1(practiceId, cmsId, questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {'ALL' | 'ACTIVE' | 'INACTIVE'} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterMembers(practiceId: string, filter: 'ALL' | 'ACTIVE' | 'INACTIVE', options?: any): AxiosPromise<Array<MemberDto>> {
            return PracticeResourceApiFp(configuration).filterMembers(practiceId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(practiceId: string, accountId: string, options?: any): AxiosPromise<AccountDto> {
            return PracticeResourceApiFp(configuration).getAccount(practiceId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountGroup(practiceId: string, groupId: string, options?: any): AxiosPromise<AccountGroupDto> {
            return PracticeResourceApiFp(configuration).getAccountGroup(practiceId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountGroups(practiceId: string, memberId: string, options?: any): AxiosPromise<Array<AccountGroupDto>> {
            return PracticeResourceApiFp(configuration).getAccountGroups(practiceId, memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts(practiceId: string, memberId: string, options?: any): AxiosPromise<Array<AccountDto>> {
            return PracticeResourceApiFp(configuration).getAccounts(practiceId, memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {Array<string>} publicIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsByPublicIds(practiceId: string, publicIds: Array<string>, options?: any): AxiosPromise<Array<AccountDto>> {
            return PracticeResourceApiFp(configuration).getAccountsByPublicIds(practiceId, publicIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLightPracticeAnswersForQuestion(practiceId: string, cmsId: number, questionId: string, options?: any): AxiosPromise<Array<LightCmsAnswerDto>> {
            return PracticeResourceApiFp(configuration).getLightPracticeAnswersForQuestion(practiceId, cmsId, questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} practiceId 
         * @param {number} from 
         * @param {number} thru 
         * @param {boolean} onlyFinished 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingsForProviderBetween(memberId: string, practiceId: string, from: number, thru: number, onlyFinished: boolean, options?: any): AxiosPromise<Array<MeetingDto>> {
            return PracticeResourceApiFp(configuration).getMeetingsForProviderBetween(memberId, practiceId, from, thru, onlyFinished, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembers(practiceId: string, options?: any): AxiosPromise<Array<MemberDto>> {
            return PracticeResourceApiFp(configuration).getMembers(practiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {'EMAIL' | 'SMS'} type 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationByPracticeIdAndTypeAndName(practiceId: string, type: 'EMAIL' | 'SMS', name: string, options?: any): AxiosPromise<NotificationDto> {
            return PracticeResourceApiFp(configuration).getNotificationByPracticeIdAndTypeAndName(practiceId, type, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(practiceId: string, options?: any): AxiosPromise<Array<NotificationDto>> {
            return PracticeResourceApiFp(configuration).getNotifications(practiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnerPractice(memberId: string, options?: any): AxiosPromise<PracticeDto> {
            return PracticeResourceApiFp(configuration).getOwnerPractice(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} [questionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantAnswers(practiceId: string, cmsId: number, questionId?: string, options?: any): AxiosPromise<{ [key: string]: Array<ParticipantAnswerGroupDto>; }> {
            return PracticeResourceApiFp(configuration).getParticipantAnswers(practiceId, cmsId, questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantDeletionStatus(practiceId: string, options?: any): AxiosPromise<ParticipantDeletionStatusDto> {
            return PracticeResourceApiFp(configuration).getParticipantDeletionStatus(practiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantDeletionStatusPDF(practiceId: string, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).getParticipantDeletionStatusPDF(practiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} from 
         * @param {number} thru 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantFeedback(practiceId: string, from: number, thru: number, options?: any): AxiosPromise<{ [key: string]: Array<ParticipantAnswerGroupDto>; }> {
            return PracticeResourceApiFp(configuration).getParticipantFeedback(practiceId, from, thru, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantQuestions(practiceId: string, options?: any): AxiosPromise<Array<CmsQuestionDto>> {
            return PracticeResourceApiFp(configuration).getParticipantQuestions(practiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPractice(practiceId: string, options?: any): AxiosPromise<PracticeDto> {
            return PracticeResourceApiFp(configuration).getPractice(practiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} [questionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeAnswers(practiceId: string, cmsId: number, questionId?: string, options?: any): AxiosPromise<{ [key: string]: Array<MemberAnswerGroupDto>; }> {
            return PracticeResourceApiFp(configuration).getPracticeAnswers(practiceId, cmsId, questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeQuestions(practiceId: string, cmsId: number, options?: any): AxiosPromise<Array<CmsQuestionDto>> {
            return PracticeResourceApiFp(configuration).getPracticeQuestions(practiceId, cmsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionsByType1(practiceId: string, type: 'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER', options?: any): AxiosPromise<Array<CmsQuestionDto>> {
            return PracticeResourceApiFp(configuration).getQuestionsByType1(practiceId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(practiceId: string, options?: any): AxiosPromise<AppSettingsDto> {
            return PracticeResourceApiFp(configuration).getSettings(practiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetNotification(practiceId: string, notificationId: string, options?: any): AxiosPromise<NotificationDto> {
            return PracticeResourceApiFp(configuration).resetNotification(practiceId, notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {AppSettingsDto} appSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSettings(practiceId: string, appSettingsDto: AppSettingsDto, options?: any): AxiosPromise<PracticeDto> {
            return PracticeResourceApiFp(configuration).saveSettings(practiceId, appSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendParticipantDeletionStatusEmail(practiceId: string, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).sendParticipantDeletionStatusEmail(practiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} years 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setParticipantDeletionPeriod(practiceId: string, years: number, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).setParticipantDeletionPeriod(practiceId, years, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {SetupDto} setupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupPractice(practiceId: string, setupDto: SetupDto, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).setupPractice(practiceId, setupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} groupId 
         * @param {AccountGroupDto} accountGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountGroup(practiceId: string, groupId: string, accountGroupDto: AccountGroupDto, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).updateAccountGroup(practiceId, groupId, accountGroupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} memberId 
         * @param {MemberDto} memberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMember(practiceId: string, memberId: string, memberDto: MemberDto, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).updateMember(practiceId, memberId, memberDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification(practiceId: string, notificationDto: NotificationDto, options?: any): AxiosPromise<NotificationDto> {
            return PracticeResourceApiFp(configuration).updateNotification(practiceId, notificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {PracticeDto} practiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePractice(practiceId: string, practiceDto: PracticeDto, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).updatePractice(practiceId, practiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {CmsQuestionDto} cmsQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestion1(practiceId: string, cmsId: number, questionId: string, cmsQuestionDto: CmsQuestionDto, options?: any): AxiosPromise<void> {
            return PracticeResourceApiFp(configuration).updateQuestion1(practiceId, cmsId, questionId, cmsQuestionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PracticeResourceApi - object-oriented interface
 * @export
 * @class PracticeResourceApi
 * @extends {BaseAPI}
 */
export class PracticeResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} practiceId 
     * @param {string} questionId 
     * @param {CmsAnswerDto} cmsAnswerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public addParticipantAnswer(practiceId: string, questionId: string, cmsAnswerDto: CmsAnswerDto, options?: any) {
        return PracticeResourceApiFp(this.configuration).addParticipantAnswer(practiceId, questionId, cmsAnswerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {Array<CmsAnswerDto>} cmsAnswerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public addPracticeAnswers(practiceId: string, cmsAnswerDto: Array<CmsAnswerDto>, options?: any) {
        return PracticeResourceApiFp(this.configuration).addPracticeAnswers(practiceId, cmsAnswerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {string} memberId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public checkGroupNameAvailability(practiceId: string, memberId: string, name: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).checkGroupNameAvailability(practiceId, memberId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public countMembers(practiceId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).countMembers(practiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {AccountDto} accountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public createAccount(practiceId: string, accountDto: AccountDto, options?: any) {
        return PracticeResourceApiFp(this.configuration).createAccount(practiceId, accountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {AccountGroupDto} accountGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public createAccountGroup(practiceId: string, accountGroupDto: AccountGroupDto, options?: any) {
        return PracticeResourceApiFp(this.configuration).createAccountGroup(practiceId, accountGroupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {MemberDto} memberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public createProvider(practiceId: string, memberDto: MemberDto, options?: any) {
        return PracticeResourceApiFp(this.configuration).createProvider(practiceId, memberDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {number} cmsId 
     * @param {CmsQuestionDto} cmsQuestionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public createProviderQuestion(practiceId: string, cmsId: number, cmsQuestionDto: CmsQuestionDto, options?: any) {
        return PracticeResourceApiFp(this.configuration).createProviderQuestion(practiceId, cmsId, cmsQuestionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {Array<string>} groupIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public deleteAccountGroups(practiceId: string, groupIds: Array<string>, options?: any) {
        return PracticeResourceApiFp(this.configuration).deleteAccountGroups(practiceId, groupIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {Array<string>} accountIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public deleteAccounts(practiceId: string, accountIds: Array<string>, options?: any) {
        return PracticeResourceApiFp(this.configuration).deleteAccounts(practiceId, accountIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public deleteProvider(practiceId: string, memberId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).deleteProvider(practiceId, memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {number} cmsId 
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public deleteQuestion1(practiceId: string, cmsId: number, questionId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).deleteQuestion1(practiceId, cmsId, questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {number} cmsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public exportCmsToCSV1(practiceId: string, cmsId: number, options?: any) {
        return PracticeResourceApiFp(this.configuration).exportCmsToCSV1(practiceId, cmsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {number} cmsId 
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public exportQuestionToCSV1(practiceId: string, cmsId: number, questionId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).exportQuestionToCSV1(practiceId, cmsId, questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {'ALL' | 'ACTIVE' | 'INACTIVE'} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public filterMembers(practiceId: string, filter: 'ALL' | 'ACTIVE' | 'INACTIVE', options?: any) {
        return PracticeResourceApiFp(this.configuration).filterMembers(practiceId, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getAccount(practiceId: string, accountId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getAccount(practiceId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getAccountGroup(practiceId: string, groupId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getAccountGroup(practiceId, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getAccountGroups(practiceId: string, memberId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getAccountGroups(practiceId, memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getAccounts(practiceId: string, memberId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getAccounts(practiceId, memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {Array<string>} publicIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getAccountsByPublicIds(practiceId: string, publicIds: Array<string>, options?: any) {
        return PracticeResourceApiFp(this.configuration).getAccountsByPublicIds(practiceId, publicIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {number} cmsId 
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getLightPracticeAnswersForQuestion(practiceId: string, cmsId: number, questionId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getLightPracticeAnswersForQuestion(practiceId, cmsId, questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {string} practiceId 
     * @param {number} from 
     * @param {number} thru 
     * @param {boolean} onlyFinished 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getMeetingsForProviderBetween(memberId: string, practiceId: string, from: number, thru: number, onlyFinished: boolean, options?: any) {
        return PracticeResourceApiFp(this.configuration).getMeetingsForProviderBetween(memberId, practiceId, from, thru, onlyFinished, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getMembers(practiceId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getMembers(practiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {'EMAIL' | 'SMS'} type 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getNotificationByPracticeIdAndTypeAndName(practiceId: string, type: 'EMAIL' | 'SMS', name: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getNotificationByPracticeIdAndTypeAndName(practiceId, type, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getNotifications(practiceId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getNotifications(practiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getOwnerPractice(memberId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getOwnerPractice(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {number} cmsId 
     * @param {string} [questionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getParticipantAnswers(practiceId: string, cmsId: number, questionId?: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getParticipantAnswers(practiceId, cmsId, questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getParticipantDeletionStatus(practiceId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getParticipantDeletionStatus(practiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getParticipantDeletionStatusPDF(practiceId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getParticipantDeletionStatusPDF(practiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {number} from 
     * @param {number} thru 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getParticipantFeedback(practiceId: string, from: number, thru: number, options?: any) {
        return PracticeResourceApiFp(this.configuration).getParticipantFeedback(practiceId, from, thru, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getParticipantQuestions(practiceId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getParticipantQuestions(practiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getPractice(practiceId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getPractice(practiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {number} cmsId 
     * @param {string} [questionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getPracticeAnswers(practiceId: string, cmsId: number, questionId?: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getPracticeAnswers(practiceId, cmsId, questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {number} cmsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getPracticeQuestions(practiceId: string, cmsId: number, options?: any) {
        return PracticeResourceApiFp(this.configuration).getPracticeQuestions(practiceId, cmsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER'} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getQuestionsByType1(practiceId: string, type: 'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER', options?: any) {
        return PracticeResourceApiFp(this.configuration).getQuestionsByType1(practiceId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public getSettings(practiceId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).getSettings(practiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {string} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public resetNotification(practiceId: string, notificationId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).resetNotification(practiceId, notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {AppSettingsDto} appSettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public saveSettings(practiceId: string, appSettingsDto: AppSettingsDto, options?: any) {
        return PracticeResourceApiFp(this.configuration).saveSettings(practiceId, appSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public sendParticipantDeletionStatusEmail(practiceId: string, options?: any) {
        return PracticeResourceApiFp(this.configuration).sendParticipantDeletionStatusEmail(practiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {number} years 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public setParticipantDeletionPeriod(practiceId: string, years: number, options?: any) {
        return PracticeResourceApiFp(this.configuration).setParticipantDeletionPeriod(practiceId, years, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {SetupDto} setupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public setupPractice(practiceId: string, setupDto: SetupDto, options?: any) {
        return PracticeResourceApiFp(this.configuration).setupPractice(practiceId, setupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {string} groupId 
     * @param {AccountGroupDto} accountGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public updateAccountGroup(practiceId: string, groupId: string, accountGroupDto: AccountGroupDto, options?: any) {
        return PracticeResourceApiFp(this.configuration).updateAccountGroup(practiceId, groupId, accountGroupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {string} memberId 
     * @param {MemberDto} memberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public updateMember(practiceId: string, memberId: string, memberDto: MemberDto, options?: any) {
        return PracticeResourceApiFp(this.configuration).updateMember(practiceId, memberId, memberDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {NotificationDto} notificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public updateNotification(practiceId: string, notificationDto: NotificationDto, options?: any) {
        return PracticeResourceApiFp(this.configuration).updateNotification(practiceId, notificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {PracticeDto} practiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public updatePractice(practiceId: string, practiceDto: PracticeDto, options?: any) {
        return PracticeResourceApiFp(this.configuration).updatePractice(practiceId, practiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {number} cmsId 
     * @param {string} questionId 
     * @param {CmsQuestionDto} cmsQuestionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeResourceApi
     */
    public updateQuestion1(practiceId: string, cmsId: number, questionId: string, cmsQuestionDto: CmsQuestionDto, options?: any) {
        return PracticeResourceApiFp(this.configuration).updateQuestion1(practiceId, cmsId, questionId, cmsQuestionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantResourceApi - axios parameter creator
 * @export
 */
export const TenantResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {Array<CmsAnswerDto>} cmsAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAnswers: async (tenantId: string, cmsAnswerDto: Array<CmsAnswerDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling addAnswers.');
            }
            // verify required parameter 'cmsAnswerDto' is not null or undefined
            if (cmsAnswerDto === null || cmsAnswerDto === undefined) {
                throw new RequiredError('cmsAnswerDto','Required parameter cmsAnswerDto was null or undefined when calling addAnswers.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/cms/answers`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cmsAnswerDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cmsAnswerDto !== undefined ? cmsAnswerDto : {})
                : (cmsAnswerDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {ContentDto} contentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContent: async (tenantId: string, contentDto: ContentDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling createContent.');
            }
            // verify required parameter 'contentDto' is not null or undefined
            if (contentDto === null || contentDto === undefined) {
                throw new RequiredError('contentDto','Required parameter contentDto was null or undefined when calling createContent.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/content`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof contentDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(contentDto !== undefined ? contentDto : {})
                : (contentDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {CmsQuestionDto} cmsQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestion: async (tenantId: string, cmsId: number, cmsQuestionDto: CmsQuestionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling createQuestion.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling createQuestion.');
            }
            // verify required parameter 'cmsQuestionDto' is not null or undefined
            if (cmsQuestionDto === null || cmsQuestionDto === undefined) {
                throw new RequiredError('cmsQuestionDto','Required parameter cmsQuestionDto was null or undefined when calling createQuestion.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/cms/questions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmsId !== undefined) {
                localVarQueryParameter['cmsId'] = cmsId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cmsQuestionDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cmsQuestionDto !== undefined ? cmsQuestionDto : {})
                : (cmsQuestionDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContent: async (tenantId: string, contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling deleteContent.');
            }
            // verify required parameter 'contentId' is not null or undefined
            if (contentId === null || contentId === undefined) {
                throw new RequiredError('contentId','Required parameter contentId was null or undefined when calling deleteContent.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/content`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentId !== undefined) {
                localVarQueryParameter['contentId'] = contentId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestion: async (tenantId: string, cmsId: number, questionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling deleteQuestion.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling deleteQuestion.');
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling deleteQuestion.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/cms/questions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmsId !== undefined) {
                localVarQueryParameter['cmsId'] = cmsId;
            }

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCmsToCSV: async (tenantId: string, cmsId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling exportCmsToCSV.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling exportCmsToCSV.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/cms/{cmsId}/export/csv`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"cmsId"}}`, encodeURIComponent(String(cmsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportQuestionToCSV: async (tenantId: string, cmsId: number, questionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling exportQuestionToCSV.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling exportQuestionToCSV.');
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling exportQuestionToCSV.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/cms/{cmsId}/questions/export/csv`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"cmsId"}}`, encodeURIComponent(String(cmsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminOrCustomerCareFeedbacks: async (tenantId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling getAdminOrCustomerCareFeedbacks.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/cms/feedbacks`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} [questionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnswers: async (tenantId: string, cmsId: number, questionId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling getAnswers.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling getAnswers.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/cms/answers`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmsId !== undefined) {
                localVarQueryParameter['cmsId'] = cmsId;
            }

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLightAnswersForQuestion: async (tenantId: string, cmsId: number, questionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling getLightAnswersForQuestion.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling getLightAnswersForQuestion.');
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling getLightAnswersForQuestion.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/cms/answers/{questionId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmsId !== undefined) {
                localVarQueryParameter['cmsId'] = cmsId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestions: async (tenantId: string, cmsId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling getQuestions.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling getQuestions.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/cms/questions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmsId !== undefined) {
                localVarQueryParameter['cmsId'] = cmsId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionsAndAnswersOverview: async (tenantId: string, cmsId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling getQuestionsAndAnswersOverview.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling getQuestionsAndAnswersOverview.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/cms/{cmsId}/print`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"cmsId"}}`, encodeURIComponent(String(cmsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionsByType: async (tenantId: string, type: 'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling getQuestionsByType.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getQuestionsByType.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/cms`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantContentByType: async (tenantId: string, type: 'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling getTenantContentByType.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getTenantContentByType.');
            }
            const localVarPath = `/api/v1/public/tenant/{tenantId}/content`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {ContentDto} contentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContent: async (tenantId: string, contentDto: ContentDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling updateContent.');
            }
            // verify required parameter 'contentDto' is not null or undefined
            if (contentDto === null || contentDto === undefined) {
                throw new RequiredError('contentDto','Required parameter contentDto was null or undefined when calling updateContent.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/content`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof contentDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(contentDto !== undefined ? contentDto : {})
                : (contentDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {CmsQuestionDto} cmsQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestion: async (tenantId: string, cmsId: number, questionId: string, cmsQuestionDto: CmsQuestionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling updateQuestion.');
            }
            // verify required parameter 'cmsId' is not null or undefined
            if (cmsId === null || cmsId === undefined) {
                throw new RequiredError('cmsId','Required parameter cmsId was null or undefined when calling updateQuestion.');
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling updateQuestion.');
            }
            // verify required parameter 'cmsQuestionDto' is not null or undefined
            if (cmsQuestionDto === null || cmsQuestionDto === undefined) {
                throw new RequiredError('cmsQuestionDto','Required parameter cmsQuestionDto was null or undefined when calling updateQuestion.');
            }
            const localVarPath = `/api/v1/tenant/{tenantId}/cms/questions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmsId !== undefined) {
                localVarQueryParameter['cmsId'] = cmsId;
            }

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cmsQuestionDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cmsQuestionDto !== undefined ? cmsQuestionDto : {})
                : (cmsQuestionDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantResourceApi - functional programming interface
 * @export
 */
export const TenantResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {Array<CmsAnswerDto>} cmsAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAnswers(tenantId: string, cmsAnswerDto: Array<CmsAnswerDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CmsAnswerDto>>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).addAnswers(tenantId, cmsAnswerDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {ContentDto} contentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContent(tenantId: string, contentDto: ContentDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentDto>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).createContent(tenantId, contentDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {CmsQuestionDto} cmsQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQuestion(tenantId: string, cmsId: number, cmsQuestionDto: CmsQuestionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).createQuestion(tenantId, cmsId, cmsQuestionDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContent(tenantId: string, contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).deleteContent(tenantId, contentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQuestion(tenantId: string, cmsId: number, questionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).deleteQuestion(tenantId, cmsId, questionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCmsToCSV(tenantId: string, cmsId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).exportCmsToCSV(tenantId, cmsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportQuestionToCSV(tenantId: string, cmsId: number, questionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).exportQuestionToCSV(tenantId, cmsId, questionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminOrCustomerCareFeedbacks(tenantId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<MemberAnswerGroupDto>; }>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).getAdminOrCustomerCareFeedbacks(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} [questionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnswers(tenantId: string, cmsId: number, questionId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<MemberAnswerGroupDto>; }>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).getAnswers(tenantId, cmsId, questionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLightAnswersForQuestion(tenantId: string, cmsId: number, questionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LightCmsAnswerDto>>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).getLightAnswersForQuestion(tenantId, cmsId, questionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestions(tenantId: string, cmsId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CmsQuestionDto>>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).getQuestions(tenantId, cmsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionsAndAnswersOverview(tenantId: string, cmsId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).getQuestionsAndAnswersOverview(tenantId, cmsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionsByType(tenantId: string, type: 'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CmsQuestionDto>>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).getQuestionsByType(tenantId, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantContentByType(tenantId: string, type: 'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentDto>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).getTenantContentByType(tenantId, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {ContentDto} contentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContent(tenantId: string, contentDto: ContentDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentDto>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).updateContent(tenantId, contentDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {CmsQuestionDto} cmsQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuestion(tenantId: string, cmsId: number, questionId: string, cmsQuestionDto: CmsQuestionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantResourceApiAxiosParamCreator(configuration).updateQuestion(tenantId, cmsId, questionId, cmsQuestionDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TenantResourceApi - factory interface
 * @export
 */
export const TenantResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {Array<CmsAnswerDto>} cmsAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAnswers(tenantId: string, cmsAnswerDto: Array<CmsAnswerDto>, options?: any): AxiosPromise<Array<CmsAnswerDto>> {
            return TenantResourceApiFp(configuration).addAnswers(tenantId, cmsAnswerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {ContentDto} contentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContent(tenantId: string, contentDto: ContentDto, options?: any): AxiosPromise<ContentDto> {
            return TenantResourceApiFp(configuration).createContent(tenantId, contentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {CmsQuestionDto} cmsQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestion(tenantId: string, cmsId: number, cmsQuestionDto: CmsQuestionDto, options?: any): AxiosPromise<void> {
            return TenantResourceApiFp(configuration).createQuestion(tenantId, cmsId, cmsQuestionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContent(tenantId: string, contentId: string, options?: any): AxiosPromise<void> {
            return TenantResourceApiFp(configuration).deleteContent(tenantId, contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestion(tenantId: string, cmsId: number, questionId: string, options?: any): AxiosPromise<void> {
            return TenantResourceApiFp(configuration).deleteQuestion(tenantId, cmsId, questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCmsToCSV(tenantId: string, cmsId: number, options?: any): AxiosPromise<void> {
            return TenantResourceApiFp(configuration).exportCmsToCSV(tenantId, cmsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportQuestionToCSV(tenantId: string, cmsId: number, questionId: string, options?: any): AxiosPromise<void> {
            return TenantResourceApiFp(configuration).exportQuestionToCSV(tenantId, cmsId, questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminOrCustomerCareFeedbacks(tenantId: string, options?: any): AxiosPromise<{ [key: string]: Array<MemberAnswerGroupDto>; }> {
            return TenantResourceApiFp(configuration).getAdminOrCustomerCareFeedbacks(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} [questionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnswers(tenantId: string, cmsId: number, questionId?: string, options?: any): AxiosPromise<{ [key: string]: Array<MemberAnswerGroupDto>; }> {
            return TenantResourceApiFp(configuration).getAnswers(tenantId, cmsId, questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLightAnswersForQuestion(tenantId: string, cmsId: number, questionId: string, options?: any): AxiosPromise<Array<LightCmsAnswerDto>> {
            return TenantResourceApiFp(configuration).getLightAnswersForQuestion(tenantId, cmsId, questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestions(tenantId: string, cmsId: number, options?: any): AxiosPromise<Array<CmsQuestionDto>> {
            return TenantResourceApiFp(configuration).getQuestions(tenantId, cmsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} cmsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionsAndAnswersOverview(tenantId: string, cmsId: string, options?: any): AxiosPromise<void> {
            return TenantResourceApiFp(configuration).getQuestionsAndAnswersOverview(tenantId, cmsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionsByType(tenantId: string, type: 'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER', options?: any): AxiosPromise<Array<CmsQuestionDto>> {
            return TenantResourceApiFp(configuration).getQuestionsByType(tenantId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantContentByType(tenantId: string, type: 'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER', options?: any): AxiosPromise<ContentDto> {
            return TenantResourceApiFp(configuration).getTenantContentByType(tenantId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {ContentDto} contentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContent(tenantId: string, contentDto: ContentDto, options?: any): AxiosPromise<ContentDto> {
            return TenantResourceApiFp(configuration).updateContent(tenantId, contentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {number} cmsId 
         * @param {string} questionId 
         * @param {CmsQuestionDto} cmsQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestion(tenantId: string, cmsId: number, questionId: string, cmsQuestionDto: CmsQuestionDto, options?: any): AxiosPromise<void> {
            return TenantResourceApiFp(configuration).updateQuestion(tenantId, cmsId, questionId, cmsQuestionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantResourceApi - object-oriented interface
 * @export
 * @class TenantResourceApi
 * @extends {BaseAPI}
 */
export class TenantResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {Array<CmsAnswerDto>} cmsAnswerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public addAnswers(tenantId: string, cmsAnswerDto: Array<CmsAnswerDto>, options?: any) {
        return TenantResourceApiFp(this.configuration).addAnswers(tenantId, cmsAnswerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {ContentDto} contentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public createContent(tenantId: string, contentDto: ContentDto, options?: any) {
        return TenantResourceApiFp(this.configuration).createContent(tenantId, contentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {number} cmsId 
     * @param {CmsQuestionDto} cmsQuestionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public createQuestion(tenantId: string, cmsId: number, cmsQuestionDto: CmsQuestionDto, options?: any) {
        return TenantResourceApiFp(this.configuration).createQuestion(tenantId, cmsId, cmsQuestionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public deleteContent(tenantId: string, contentId: string, options?: any) {
        return TenantResourceApiFp(this.configuration).deleteContent(tenantId, contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {number} cmsId 
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public deleteQuestion(tenantId: string, cmsId: number, questionId: string, options?: any) {
        return TenantResourceApiFp(this.configuration).deleteQuestion(tenantId, cmsId, questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {number} cmsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public exportCmsToCSV(tenantId: string, cmsId: number, options?: any) {
        return TenantResourceApiFp(this.configuration).exportCmsToCSV(tenantId, cmsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {number} cmsId 
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public exportQuestionToCSV(tenantId: string, cmsId: number, questionId: string, options?: any) {
        return TenantResourceApiFp(this.configuration).exportQuestionToCSV(tenantId, cmsId, questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public getAdminOrCustomerCareFeedbacks(tenantId: string, options?: any) {
        return TenantResourceApiFp(this.configuration).getAdminOrCustomerCareFeedbacks(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {number} cmsId 
     * @param {string} [questionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public getAnswers(tenantId: string, cmsId: number, questionId?: string, options?: any) {
        return TenantResourceApiFp(this.configuration).getAnswers(tenantId, cmsId, questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {number} cmsId 
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public getLightAnswersForQuestion(tenantId: string, cmsId: number, questionId: string, options?: any) {
        return TenantResourceApiFp(this.configuration).getLightAnswersForQuestion(tenantId, cmsId, questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {number} cmsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public getQuestions(tenantId: string, cmsId: number, options?: any) {
        return TenantResourceApiFp(this.configuration).getQuestions(tenantId, cmsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} cmsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public getQuestionsAndAnswersOverview(tenantId: string, cmsId: string, options?: any) {
        return TenantResourceApiFp(this.configuration).getQuestionsAndAnswersOverview(tenantId, cmsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER'} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public getQuestionsByType(tenantId: string, type: 'QUESTION_AFTER_CALL_PROVIDER' | 'QUESTION_AFTER_CALL_PATIENT' | 'QUESTION_AFTER_CALL_ADMIN' | 'QUESTION_AFTER_REGISTER_PROVIDER' | 'QUESTION_AFTER_REGISTER', options?: any) {
        return TenantResourceApiFp(this.configuration).getQuestionsByType(tenantId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER'} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public getTenantContentByType(tenantId: string, type: 'HOMEPAGE' | 'REGISTER' | 'IMPRINT' | 'DATA_PRIVACY' | 'TERMS' | 'FAQ' | 'CONTACT' | 'HELP' | 'DATA_PRIVACY_PROVIDER', options?: any) {
        return TenantResourceApiFp(this.configuration).getTenantContentByType(tenantId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {ContentDto} contentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public updateContent(tenantId: string, contentDto: ContentDto, options?: any) {
        return TenantResourceApiFp(this.configuration).updateContent(tenantId, contentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {number} cmsId 
     * @param {string} questionId 
     * @param {CmsQuestionDto} cmsQuestionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantResourceApi
     */
    public updateQuestion(tenantId: string, cmsId: number, questionId: string, cmsQuestionDto: CmsQuestionDto, options?: any) {
        return TenantResourceApiFp(this.configuration).updateQuestion(tenantId, cmsId, questionId, cmsQuestionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


