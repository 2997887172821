import * as React from 'react';
import './Participant.scss';
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Input, Modal, Select, Switch} from "antd";
import {AccountDto, ParticipantDto, ParticipantDtoGenderEnum} from "../../gen/client";
import ParticipantApi from '../../api/ParticipantApi';
import {handleServerError} from '../../util/ErrorHandler';
import EmailInput from '../general/EmailInput';
import {getApplicationSizeClass, getParticipant, timestamp} from '../../util/utils';
import PhoneInput from '../general/PhoneInput';
import moment from 'moment';
import { useState } from 'react';
import SearchContactModal from './SearchContactModal';
import { useRecoilValue } from 'recoil';
import { Dimensions } from '../../util/enums';
import { applicationSize } from '../../state/atoms';
import { isContactRoute } from '../../util/routes';
import { useHistory } from 'react-router';

interface ParticipantProps {
  it: ParticipantDto;
  idx: number;
  nameErr: boolean;
  phoneErr?: boolean;
  emailErr?: boolean;
  saveErr: boolean;
  modal: boolean;
  onNameChange: (idx: number, value: string) => void;
  onEmailChange: (idx: number, value: string, required: boolean) => void;
  onPhoneChange: (idx: number, value: string, required: boolean) => void;
  onParticipantChange: (participant: ParticipantDto) => void;
}

export default function Participant({ it, idx, nameErr, saveErr, phoneErr, emailErr, modal,
                                      onNameChange, onEmailChange, onPhoneChange, onParticipantChange}: ParticipantProps) {
  const {t} = useTranslation();
  const history = useHistory();
  const [openSearchParticipant, setOpenSearchParticipant] = useState(false);
  const appSize = useRecoilValue<Dimensions>(applicationSize);

  function handleNameChange(value: string) {
    onParticipantChange({name: value});
    onNameChange(idx, value);
  }

  function handlePhoneChange(value: string) {
    onParticipantChange({phone: value});
    onPhoneChange(idx, value, it.smsNotification);
  }

  function handleEmailChange(value: string) {
    onParticipantChange({email: value});
    onEmailChange(idx, value, it.emailNotification);
  }

  function onNotificationAcceptedChange(value: boolean) {
    if (value && it.id) {
      ParticipantApi.saveAgreement(it.id)
        .then(() => {
        })
        .catch(err => handleServerError(err))
    } else {
      onParticipantChange({agreementDate: timestamp(moment())});
    }
    onParticipantChange({notificationAccepted: value});
  }

  function onNotifyBeforeMeetingChange(value: boolean) {
    onParticipantChange({notifyBeforeMeeting: value});
  }

  function onAccountChosen(account: AccountDto) {
    onParticipantChange(getParticipant(account));
  }

  return (
    <li className={'attendee'}>
      {it?.removed ?
        <header>{it.name}</header>
        :
        <div>
          <header className={'row btm-spacing'}>
            {!isContactRoute(history.location.pathname) && 
              <h3 className={'secondary-header'}>{t('Neuer Teilnehmer')}</h3>}
            {modal && 
              <Button className={'search-contact-btn'} size={'middle'} onClick={() => setOpenSearchParticipant(true)}>{t('Suche in der Kontaktliste')}</Button>}
          </header>
          <div className={'fields'} key={it?.id}>
            <div className='ant-form-item'>
              <label>{t('Geschlecht')}</label>
              <Select value={it?.gender} size={'large'} onChange={value => onParticipantChange({gender: value})}>
                <Select.Option value={ParticipantDtoGenderEnum.NotSpecified}>{t('divers')}</Select.Option>
                <Select.Option value={ParticipantDtoGenderEnum.Male}>{t('männlich')}</Select.Option>
                <Select.Option value={ParticipantDtoGenderEnum.Female}>{t('weiblich')}</Select.Option>
              </Select>
            </div>

            <div className={`ant-form-item ${nameErr ? 'ant-form-item-has-error' : ''}`}>
              <label className={'req'}>{t('Name')}</label>
              <Input size={'large'} name={'name'} type={'text'} value={it?.name} onChange={ev => handleNameChange(ev.target.value)}/>
              {nameErr && <span className='err-msg'>{t('Feld ist erforderlich')}</span>}
            </div>

            <div>
              <PhoneInput asFormItem={false} required={it?.smsNotification} value={it?.phone} onChange={handlePhoneChange} hasError={phoneErr}/>
            </div>

            <div>
              <EmailInput asFormItem={false} required={it?.emailNotification} value={it?.email} onChange={handleEmailChange} hasError={emailErr}/>
            </div>
          </div>

          <div className={'ntf'}>
            <p>{t('Einladung verschicken per')}</p>
            <Checkbox checked={it?.smsNotification} onChange={(e) => onParticipantChange({smsNotification: e.target.checked})}>{t('SMS')}</Checkbox>
            <Checkbox checked={it?.emailNotification} onChange={(e) => onParticipantChange({emailNotification: e.target.checked})}>{t('E-Mail')}</Checkbox>
          </div>

          <div className={`all-ntf hidden ${saveErr ? 'save-err' : ''}`}>
            <div className='label switch-wrapper'>
              <Switch checked={/*it?.notificationAccepted*/ true} onChange={onNotificationAcceptedChange}/>
              <span>{t('Der Nutzer hat eingewilligt, SMS- und E-Mail-Nachrichten von mir zu erhalten')}.</span>
            </div>
          </div>

          <div className={`all-ntf`}>
            <div className='label switch-wrapper'>
              <Switch checked={it?.notifyBeforeMeeting} onChange={onNotifyBeforeMeetingChange}/>
              <span>{t('Der Teilnehmer soll 24 Stunden zuvor eine Erinnerungsnachricht bekommen')}.</span>
            </div>
          </div>
          <Modal
            closable={false}
            className={`search-card ${getApplicationSizeClass(appSize)}`}
            visible={openSearchParticipant}
            width={664}
            centered
            okButtonProps={{style: {display: 'none'}}}
            cancelButtonProps={{style: {display: 'none'}}}
            destroyOnClose={true}>


            <SearchContactModal openSearch={openSearchParticipant} onCloseSearch={() => setOpenSearchParticipant(false)} onContactChosen={onAccountChosen} onContactsChosen={() => {}} showGroups={false} onGroupChosen={() => {}}/>
        </Modal>
        </div>
      }
    </li>
  )
}
