import React, {useMemo} from 'react';
import './DashboardHeader.scss';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router';
import Container from '../general/Container';
import SearchBar from '../general/SearchBar';
import DashboardHeaderMenu from './DashboardHeaderMenu';
import {Button} from 'antd';
import {isAppointmentsRoute, isCMSRoute, isContactRoute, isGroupRoute, isMemberRoute, isStatisticRoute, Routes} from '../../util/routes';
import {useRecoilState, useRecoilValue} from 'recoil';
import {applicationSize, loggedMember} from '../../state/atoms';
import {getApplicationSizeClass, isProvider} from '../../util/utils';
import {Dimensions} from '../../util/enums';
import Icon from '@ant-design/icons';
import {ReactComponent as iconAdd} from '../../img/icons/add-icon.svg';

export interface DashboardHeaderProps {
  menuIconClicked?: () => void;
}

export default function DashboardHeader({menuIconClicked}: DashboardHeaderProps) {
  const {t} = useTranslation();
  const history = useHistory();
  let location = useLocation();

  const [logged] = useRecoilState(loggedMember);
  const appSize = useRecoilValue(applicationSize);


  const actionButton = useMemo(() => {
    const pathname = location.pathname;
    if (isStatisticRoute(pathname) || isCMSRoute(pathname)) {
      return null;
    }

    if (isMemberRoute(pathname) && !isProvider(logged)) {
      return <Button className={'add-new'} type={'primary'} size={'large'} onClick={() => history.push(Routes.MEMBERS_NEW)}>{t('Neuer Nutzer')}</Button>;
    }

    if (isContactRoute(pathname) || isGroupRoute(pathname)) {
      return <div className='btns'>
        <Button className={'add-new add-new-single'} type={'primary'} size={'large'} onClick={() => history.push(Routes.CONTACTS_NEW)}>
          <Icon component={iconAdd}/> {t('Kontakt')}
        </Button>
        <Button className={'add-new add-new-group'} type={'primary'} size={'large'} onClick={() => history.push(Routes.GROUPS_NEW)}>
          <Icon component={iconAdd}/> {t('Gruppe')}
        </Button>
      </div>;
    }

    if (isAppointmentsRoute(pathname)) {
      return <div className='btns'>
        <Button className={'add-new add-new-single'} type={'primary'} size={'large'} onClick={() => history.push(Routes.APPOINTMENTS_NEW_SINGLE)}>
          <Icon component={iconAdd}/>  {t('Video Termin')}
        </Button>
        <Button className={'add-new add-new-group'} type={'primary'} size={'large'} onClick={() => history.push(Routes.APPOINTMENTS_NEW_GROUP)}>
          <Icon component={iconAdd}/> {t('Gruppe Termin')}
        </Button>
      </div>
    }
  }, [location, history, logged, t]);

  return (
    <header className={getApplicationSizeClass(appSize)}>
      <Container className={'full'}>
        {
          appSize !== Dimensions.MOBILE ? <>
            <SearchBar/>
            {actionButton}
            <DashboardHeaderMenu/>
          </> : <>
            <li className={'logo'}>
              <div className={'l'}/>
            </li>
            <SearchBar/>
            <DashboardHeaderMenu/>
          </>
        }
      </Container>
    </header>
  );
}
