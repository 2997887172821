import * as React from 'react';

export default function ShareScreenSVG() {
  return (
    <svg version="1.1" viewBox="0 0 24 24" >
      <path id="Subtraction_20" d="M15.4,21.3H8.6c-0.2,0-0.4-0.2-0.5-0.5v-1.4c0-0.2,0.2-0.4,0.5-0.5h1.6v-2.3H3.4
        c-0.8,0-1.4-0.6-1.4-1.4V4c0-0.8,0.6-1.4,1.4-1.4h17.3C21.4,2.7,22,3.3,22,4v11.4c0,0.8-0.6,1.4-1.4,1.4h-6.8V19h1.6
        c0.2,0,0.4,0.2,0.5,0.5v1.4C15.9,21.1,15.7,21.3,15.4,21.3z M10.3,11L10.3,11l2.8,1.9c0,0.1,0,0.2,0,0.2c0,0.9,0.7,1.7,1.7,1.7
        c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-0.4,0-0.8,0.2-1.1,0.4L10.9,10c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2l2.8-1.9
        c0.3,0.3,0.7,0.4,1.1,0.4c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7c0,0.1,0,0.2,0,0.2l-2.8,1.9
        C10,8.2,9.7,8.1,9.2,8.1c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7C9.6,11.4,10,11.2,10.3,11L10.3,11z M14.8,13.6
        c-0.3,0-0.5-0.2-0.6-0.5c0,0,0,0,0,0c0-0.3,0.2-0.5,0.5-0.6c0,0,0,0,0,0c0.3,0,0.5,0.2,0.6,0.5c0,0,0,0,0,0
        C15.3,13.3,15.1,13.6,14.8,13.6C14.8,13.6,14.8,13.6,14.8,13.6L14.8,13.6z M9.2,10.3c-0.3,0-0.5-0.2-0.6-0.5c0,0,0,0,0,0
        c0-0.3,0.2-0.5,0.5-0.6c0,0,0,0,0,0c0.3,0,0.5,0.2,0.6,0.5c0,0,0,0,0,0C9.8,10,9.6,10.3,9.2,10.3C9.2,10.3,9.2,10.3,9.2,10.3
        L9.2,10.3z M14.8,7c-0.3,0-0.5-0.2-0.6-0.5c0,0,0,0,0,0c0-0.3,0.2-0.6,0.5-0.6c0,0,0,0,0,0c0.3,0,0.5,0.2,0.6,0.5c0,0,0,0,0,0
        C15.3,6.7,15.1,7,14.8,7C14.8,7,14.8,7,14.8,7L14.8,7z"/>
    </svg>
  )
}
