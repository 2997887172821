import moment from 'moment';
import { atom, selector } from 'recoil';
import { DatePickerInterval } from '../../general/IntervalPicker';

export const DEFAULT_INTERVAL: DatePickerInterval = [null, null];
export const EMPTY_INTERVAL: DatePickerInterval = [null, null];
export const STATISTICS_DEFAULT_INTERVAL: DatePickerInterval = [moment().subtract(7, 'days'), moment()];

export const appointmentsDateInterval = atom({
  key: 'appointmentsDateIntervalState',
  default: DEFAULT_INTERVAL as DatePickerInterval
});

export const statisticsDateInterval = atom({
  key: 'statisticsDateIntervalState',
  default: STATISTICS_DEFAULT_INTERVAL as DatePickerInterval
});

export const statisticsAppointmentList = atom({
  key: 'statisticsAppointmentListState',
  default: []
});

export const participantFeedbacksList = atom({
  key: 'participantFeedbacksListState',
  default: []
});

export const summarySelector = selector({
  key: 'statisticsDateIntervalSelector',
  get: ({get}) => {
    const appointments = get(statisticsAppointmentList);
    const participantFeedbacks = get(participantFeedbacksList);
    return {
      total: appointments.length,
      totalDuration: appointments.map(e => e.callDuration).reduce((a, b) => a + b, 0),
      totalEstimatedDuration: appointments.map(e => e.duration).reduce((a, b) => a + b, 0),
      totalFeedbacksCount: participantFeedbacks.length
    }
  }
});

export const feedbackListSelector = selector({
  key: 'statisticsFeedbackListSelector',
  get: ({get}) => get(statisticsAppointmentList).map(it => it.feedbacks).flat()
});
