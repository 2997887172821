import { hasAdminRole, hasCustomerCareRole, hasPracticeManagerRole } from "../util/utils";
import { MemberDto, MemberDtoRolesEnum, MemberDtoStateEnum } from "../gen/client";
import { Store } from "rc-field-form/lib/interface";

export function getSubRoles(roles: MemberDtoRolesEnum[]) {
  const sub = [];
  if (hasAdminRole(roles)) {
    sub.push(MemberDtoRolesEnum.Admin);
		sub.push(MemberDtoRolesEnum.CustomerCare);
		sub.push(MemberDtoRolesEnum.PracticeManager);
  }

  if(hasCustomerCareRole(roles)) {
		sub.push(MemberDtoRolesEnum.PracticeManager);
	}

	if(hasPracticeManagerRole(roles)) {
		sub.push(MemberDtoRolesEnum.Provider);
	}
  return sub;
}

export function getEmptyMember(roles: MemberDtoRolesEnum[]): MemberDto {
  return {
    state: MemberDtoStateEnum.Active,
    roles: hasPracticeManagerRole(roles)? [MemberDtoRolesEnum.Provider]: [MemberDtoRolesEnum.PracticeManager],
    settings: {}
  }
}

export function patchMember(member: MemberDto, store: Store): MemberDto {
  return {
    ...member,
    firstName: store.firstName,
    lastName: store.lastName,
    phone: store.phone,
    email: store.email,
    password: store.password
  }
}
