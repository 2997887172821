import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Form, Input, Skeleton} from "antd";
import {useRecoilState} from "recoil";
import {activePractice} from "../../state/atoms";
import PracticeApi from "../../api/PracticeApi";
import PhoneInput from '../general/PhoneInput';
import './PracticeData.scss';
import {handleServerError} from "../../util/ErrorHandler";
import {PracticeDto} from "../../gen/client";
import {Store} from "rc-field-form/lib/interface";
import {successMessage} from "../../util/utils";

export default function PracticeData() {
  const {t} = useTranslation();
  const {Item} = Form;

  const [active] = useRecoilState(activePractice);

  const [practice, setPractice] = useState(null as PracticeDto);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setLoading(true);
    PracticeApi.getPractice(active.id).then(resp => {
      setPractice(resp.data);
      setLoading(false);
    }).catch(err => {
      handleServerError(err);
      setLoading(false);
    });
  }, [active.id]);

  function save(values: Store) {
    setSaving(true);
    PracticeApi.update(active.id, {...practice, ...values}).then(() => {
      setSaving(false);
      successMessage(t('Erfolgreich gespeichert!'));
    }).catch(err => {
      handleServerError(err);
      setLoading(false);
    });
  }

  return (
    <div className={'practice-data content'}>
      {loading && <Skeleton loading={true} />}

      {!loading && practice &&
      <Form layout="vertical" className='form' onFinish={save} initialValues={{...practice}}>
        <div className='main-data'>
          <h3>{t('Stammdaten')}</h3>
          <div className="practice-name">
            <Item label={t('Praxis Name')} name={'name'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
              <Input size={'large'} type={'text'}/>
            </Item>
          </div>
          <div className="ik-number">
            <Item label={t('IK')} name={'ik'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
              <Input size={'large'} type={'text'} />
            </Item>
          </div>
          <h3>{t('Addrese')}</h3>
          <div className="street">
            <Item label={t('Straße')} name={'street'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
              <Input size={'large'} type={'text'}/>
            </Item>
          </div>
          <div className="street-number">
            <Item label={t('Hausnr')} name={'number'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
              <Input size={'large'} type={'text'}/>
            </Item>
          </div>
          <div className="zip-code">
            <Item label={t('PLZ')} name={'postalCode'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
              <Input size={'large'} type={'number'}/>
            </Item>
          </div>
          <div className="city">
            <Item label={t('Ort')} name={'city'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
              <Input size={'large'} type={'text'}/>
            </Item>
          </div>
        </div>
        <div className='contact-data'>
          <h3>{t('Kontakt')}</h3>
          <div>
            <PhoneInput asFormItem={true} required={true}/>
          </div>
          <div>
            <PhoneInput asFormItem={true} required={false} isFax={true}/>
          </div>
          <div className='website'>
            <Item label={t('Website')} name={'website'}>
              <Input size={'large'} type={'text'}/>
            </Item>
          </div>
        </div>
        <div className='buttons'>
          <Button type={'primary'} size="large" htmlType="submit" loading={saving}>{t('Speichern')}</Button>
          <Button className={'default'} size="large">{t('Abbrechen')}</Button>
        </div>
      </Form>}
    </div>
  );
}
