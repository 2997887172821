import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import './MeetingSettings.scss';
import {useTranslation} from "react-i18next";
import {Button, Select, Tabs} from "antd";
import {attachSinkId, getConnectedDevices, getStreamForSpecificDevice, isValidDeviceId, stopTracks} from '../../service/DeviceService';
import Storage from "../../util/Storage";
import MemberApi from '../../api/MemberApi';
import {handleServerError} from '../../util/ErrorHandler';
import {MemberDto} from '../../gen/client';
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
  audioMutedState,
  audioTracksState,
  cameraBrokenState, cameraOffState,
  camerasState, confirmStartWithoutCameraState,
  errorDeviceTypeState,
  loggedMember,
  meetingOwnerState,
  microphonesState,
  presentationModeState,
  settingsState,
  shareScreenState,
  speakersState,
  userSelectedPresentationModeState, videoSettingsState,
  videoTracksState
} from "../../state/atoms";
import {DeviceType, NetworkConnectionStatus} from '../../util/enums';
import {iOS, successMessage, warnMessage, isOwner} from "../../util/utils";
import {SHARE_SCREEN, VideoSettings} from "../../util/constants";
import {calcLayout, getJoinLinkPath} from "../../service/MeetingService";
import DeviceErrorModal from "./DeviceErrorModal";
import {useHistory} from "react-router-dom";
import {initDefaultVideoSubject, refreshDevicesSubject, updateLocalStreamTracksSubject} from "../../state/subjects";
import {usePrevious} from "../../util/hooks";
import {InternalMessageType} from "../../util/types";
import {reconnect, sendInternalMessage} from "../../service/RtcService";

interface MeetingSettingsProps {
  open: boolean;
  showSettingsView: (close: boolean) => void;
  meetingId: string;
}

export default function MeetingSettings({open, showSettingsView, meetingId}: MeetingSettingsProps) {
  const {t} = useTranslation();
  const {TabPane} = Tabs;
  const {Option} = Select;
  const history = useHistory();

  const [logged] = useRecoilState(loggedMember);

  const [cameras, setCameras] = useRecoilState(camerasState);
  const [microphones, setMicrophones] = useRecoilState(microphonesState);
  const [speakers, setSpeakers] = useRecoilState(speakersState);

  const [isPresentationMode, setPresentationMode] = useRecoilState(presentationModeState);
  const setUserSelectedPresentationMode = useSetRecoilState(userSelectedPresentationModeState);
  const [shareScreen] = useRecoilState(shareScreenState);
  const [isMeetingOwner] = useRecoilState(meetingOwnerState);
  const [localVideoTracks, setLocalVideoTracks] = useRecoilState(videoTracksState);
  const [localAudioTracks, setLocalAudioTracks] = useRecoilState(audioTracksState);
  const previousVideoTracks = usePrevious(localVideoTracks);
  const previousAudioTracks = usePrevious(localAudioTracks);

  const setCameraBroken = useSetRecoilState(cameraBrokenState);
  const setConfirmStartWithoutCamera = useSetRecoilState(confirmStartWithoutCameraState);
  const [errorDeviceType, setErrorDeviceType] = useRecoilState(errorDeviceTypeState);

  const [selectedCamera, setSelectedCamera] = useState('');
  const [selectedMicrophone, setSelectedMicrophone] = useState('');
  const [selectedSpeaker, setSelectedSpeaker] = useState('');

  const [member, setMember] = useState(null as MemberDto);

  const [defaultMicrophone, setDefaultMicrophone] = useState(null as string);
  const [defaultSpeaker, setDefaultSpeaker] = useState(null as string);
  const [defaultCamera, setDefaultCamera] = useState(null as string);

  const videoSettings = useRecoilValue(videoSettingsState);

  const [initialMode, setInitialMode] = useState(null as boolean);

  const [settings, setSettings] = useRecoilState(settingsState);

  const isCameraOff = useRecoilValue(cameraOffState);
  const isAudioMuted = useRecoilValue(audioMutedState);

  // =============================================================================== SETTINGS
  useEffect(() => {
    if (isOwner()) {
      MemberApi.getMember(Storage.getMemberId()).then(resp => {
        setMember(resp.data);
        if (resp.data.settings) {
          setSettings(resp.data.settings);
        }
      }).catch(err => {
        handleServerError(err);
      });
    }
  }, [setSettings]);

  const updateLocalStreamTracks = useCallback((newStream: MediaStream) => {
    if (!newStream) return;

    if (newStream.getVideoTracks().length) {
      setLocalVideoTracks(newStream.getVideoTracks());

      calcLayout();
    }

    if (newStream.getAudioTracks().length) {
      setLocalAudioTracks(newStream.getAudioTracks());
    }
  }, [setLocalAudioTracks, setLocalVideoTracks]);

  // =============================================================================== MICROPHONES
  const initMicrophones = useCallback(() => {
    if (!meetingId || (!settings.id && isOwner())) return;

    getConnectedDevices(DeviceType.AUDIO_INPUT, devices => {
      log('MIC', `Retrieve all devices. Got: ${devices.length} devices.`);
      setMicrophones(devices);

      if (!devices.length) {
        setDefaultMicrophone('');
        setSelectedMicrophone('');
        setErrorDeviceType(DeviceType.AUDIO_INPUT);
        return;
      }

      let deviceId = Storage.getMicrophoneId(meetingId) || devices[0].deviceId;

      if (settings?.microphoneId) {
        if (devices.find(it => it.deviceId === settings.microphoneId)) {
          deviceId = settings.microphoneId;
        }
      }

      isValidDeviceId(DeviceType.AUDIO_INPUT, deviceId).then(valid => {
        const exactDeviceId = valid ? deviceId : devices[0].deviceId;
        setDefaultMicrophone(exactDeviceId);
        setSelectedMicrophone(exactDeviceId);
        Storage.setMicrophoneId(meetingId, exactDeviceId);
      });
    });
  }, [setMicrophones, setErrorDeviceType, meetingId, settings.microphoneId, settings.id]);

  useEffect(initMicrophones, [initMicrophones]);

  useEffect(() => {
    if (!selectedMicrophone) return;

    getStreamForSpecificDevice({
      audio: {deviceId: {exact: selectedMicrophone}}
    }).then(stream => {
      updateLocalStreamTracks(stream);
      log('MIC', `Init ready. Using device id: ${selectedMicrophone}`);
    });
  }, [selectedMicrophone, updateLocalStreamTracks]);

  function handleMicrophoneChange(microphone: string) {
    if (selectedMicrophone !== microphone) {
      closePreviousAudioStream();
      setSelectedMicrophone(microphone);
      Storage.setMicrophoneId(meetingId, microphone);

      log('MIC', `Microphone changed. Using device id: ${microphone}`);
    }
  }

  // =============================================================================== SPEAKERS
  const initSpeakers = useCallback(() => {
    if (!meetingId || (!settings.id && isOwner())) return;

    getConnectedDevices(DeviceType.AUDIO_OUTPUT, devices => {
      log('SPEAKERS', `Retrieve all devices. Got: ${devices.length} devices.`);
      setSpeakers(devices);

      if (!devices.length) {
        setDefaultSpeaker('');
        setSelectedSpeaker('');
        return;
      }

      let deviceId = Storage.getSpeakerId(meetingId) || devices[0].deviceId;
      if (settings?.speakerId) {
        if (devices.find(it => it.deviceId === settings.speakerId)) {
          deviceId = settings.speakerId;
        }
      }

      isValidDeviceId(DeviceType.AUDIO_OUTPUT, deviceId).then(valid => {
        const exactDeviceId = valid ? deviceId : devices[0].deviceId;
        setDefaultSpeaker(exactDeviceId);
        setSelectedSpeaker(exactDeviceId);
        Storage.setSpeakerId(meetingId, exactDeviceId);
        log('SPEAKERS', `Init ready. Using device id: ${exactDeviceId}`);
      });
    });
  }, [setSpeakers, meetingId, settings.id, settings.speakerId]);

  useEffect(initSpeakers, [initSpeakers]);

  useEffect(() => {
    if (!selectedSpeaker) return;

    document.querySelectorAll('video').forEach(it => {
      attachSinkId(it, selectedSpeaker);
    });
  }, [selectedSpeaker]);

  function handleSpeakerChange(speaker: string) {
    if (selectedSpeaker !== speaker) {
      setSelectedSpeaker(speaker);
      Storage.setSpeakerId(meetingId, speaker);

      log('SPEAKERS', `Spearkers changed. Using device id: ${speaker}`);
    }
  }

  // =============================================================================== CAMERA
  const initCameras = useCallback(() => {
    if (!meetingId || (!settings.id && isOwner())) return;

    getConnectedDevices(DeviceType.VIDEO_INPUT, devices => {
      log('CAMERA', `Retrieve all devices. Got: ${devices.length} devices.`);
      setCameras(devices);
      setCameraBroken(false);

      if (!devices.length) {
        setDefaultCamera('');
        setSelectedCamera('');
        setErrorDeviceType(DeviceType.VIDEO_INPUT);
        setCameraBroken(true);
        return;
      }

      let deviceId = Storage.getCameraId(meetingId) || devices[0].deviceId;
      if (settings?.cameraId) {
        if (devices.find(it => it.deviceId === settings.cameraId)) {
          deviceId = settings.cameraId;
        }
      }
      isValidDeviceId(DeviceType.VIDEO_INPUT, deviceId).then(valid => {
        const exactDeviceId = valid ? deviceId : devices[0].deviceId;
        setDefaultCamera(exactDeviceId);
        setSelectedCamera(exactDeviceId);
        Storage.setCameraId(meetingId, exactDeviceId);
      });
    });
  }, [setErrorDeviceType, meetingId, settings.id, settings.cameraId, setCameras, setCameraBroken]);

  useEffect(initCameras, [initCameras]);

  useEffect(() => {
    if (!selectedCamera || selectedCamera === SHARE_SCREEN) return;

    getStreamForSpecificDevice({
      video: {
        ...VideoSettings.COMMON,
        ...videoSettings,
        deviceId: {exact: selectedCamera}
      },
      audio: {
        deviceId: selectedMicrophone ? {exact: selectedMicrophone} : null
      }
    }).then(stream => {
      setCameraBroken(false);
      updateLocalStreamTracks(stream);
      log('CAMERA', `Init ready. Using device id: ${selectedCamera} and microphoneId: ${selectedMicrophone}`);
    }).catch(() => {
      setCameraBroken(true);
      updateLocalStreamTracks(new MediaStream());
      setErrorDeviceType(DeviceType.VIDEO_INPUT);
    });
  }, [selectedCamera, selectedMicrophone, setCameraBroken, updateLocalStreamTracks, setErrorDeviceType, videoSettings]);

  function handleCameraChange(camera: string) {
    if (selectedCamera !== camera) {
      closePreviousVideoStream();
      setSelectedCamera(camera);
      Storage.setCameraId(meetingId, camera);

      log('CAMERA', `Camera changed. Using device id: ${camera}`);
    }
  }

  //===============================================================================

  function onPresentationModeChange(presentationMode: boolean) {
    setPresentationMode(presentationMode);
    setUserSelectedPresentationMode(presentationMode);
  }

  useEffect(() => {
    if (open && initialMode === null) {
      setInitialMode(isPresentationMode);
    } else if (!open) {
      setInitialMode(null);
    }
  }, [open, initialMode, isPresentationMode]);


  function saveSettings() {
    if (selectedCamera) {
      setDefaultCamera(selectedCamera);
    }
    if (selectedMicrophone) {
      setDefaultMicrophone(selectedMicrophone);
    }
    if (selectedSpeaker) {
      setDefaultSpeaker(selectedSpeaker);
    }
    setInitialMode(isPresentationMode);
    showSettingsView(false);
    saveMemberSettings();
  }

  function saveMemberSettings() {
    if (!logged.logged) return;

    member.settings = {...member.settings};

    member.settings.cameraId = selectedCamera;
    member.settings.microphoneId = selectedMicrophone;
    member.settings.speakerId = selectedSpeaker;
    MemberApi.saveMember(Storage.getMemberId(), member)
      .then(() => successMessage(t('Erfolgreich gespeichert!')))
      .catch(err => handleServerError(err));
  }

  function cancelSettings() {
    if (!shareScreen) {
      handleCameraChange(defaultCamera);
    }
    setSelectedSpeaker(defaultSpeaker);
    setSelectedMicrophone(defaultMicrophone);
    onPresentationModeChange(initialMode);
    showSettingsView(false);
  }

  const refreshDevices = useCallback(() => {
    console.log('=> refresh devices');
    setErrorDeviceType(null as DeviceType);
    initCameras();
    initMicrophones();
    initSpeakers();
  }, [setErrorDeviceType, initCameras, initMicrophones, initSpeakers]);

  const stopLocalTracks = useCallback(() => {
    stopTracks(localAudioTracks);
    stopTracks(localVideoTracks);
  }, [localAudioTracks, localVideoTracks]);

  const closePreviousVideoStream = useCallback(() => {
    if (previousVideoTracks) {
      const previousVideoTracksArr = (previousVideoTracks as MediaStreamTrack[]);

      log('VIDEO', `Closing ${previousVideoTracksArr.length} previous streams.`);

      if (previousVideoTracksArr && previousVideoTracksArr.length) {
        previousVideoTracksArr[0].stop();
      }
    }
    if (localVideoTracks.length) {
      stopTracks(localVideoTracks);
    }
  }, [localVideoTracks, previousVideoTracks]);

  const closePreviousAudioStream = useCallback(() => {
    if (previousAudioTracks) {
      const previousAudioTracksArr = (previousAudioTracks as MediaStreamTrack[]);

      log('AUDIO', `Closing ${previousAudioTracksArr.length} previous streams.`);

      if (previousAudioTracksArr && previousAudioTracksArr.length) {
        previousAudioTracksArr[0].stop();
      }
    }
  }, [previousAudioTracks]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (iOS()) {
        if (document.visibilityState === 'hidden') {
          sendInternalMessage({type: InternalMessageType.CAMERA_HIDE});
          sendInternalMessage({type: InternalMessageType.MUTE});

          closePreviousVideoStream();
          setSelectedCamera('');
        } else {
          warnMessage(NetworkConnectionStatus.RECONNECTING);

          closePreviousAudioStream();
          setSelectedMicrophone('');
          stopLocalTracks();

          reconnect(false, true);
          refreshDevices();
        }
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange, false);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange, false);
    }
  }, [refreshDevices, setSelectedMicrophone, setSelectedCamera, closePreviousVideoStream, closePreviousAudioStream, isCameraOff, isAudioMuted, stopLocalTracks]);

  function abortMeeting() {
    history.push(getJoinLinkPath(meetingId, Storage.getParticipantId()));
  }

  function confirmStartMeetingWithoutCamera() {
    setErrorDeviceType(null as DeviceType);
    setCameraBroken(true);
    setConfirmStartWithoutCamera(true);
    setLocalVideoTracks([]);
  }

  useEffect(() => {
    const subR = refreshDevicesSubject.subscribe(() => {
      refreshDevices();
    });

    const subV = initDefaultVideoSubject.subscribe(() => {
      initCameras();
    });

    const subLS = updateLocalStreamTracksSubject.subscribe((v: MediaStream) => {
      setSelectedCamera(SHARE_SCREEN);
      updateLocalStreamTracks(v);
    });

    return () => {
      subR.unsubscribe();
      subV.unsubscribe();
      subLS.unsubscribe();
    }
  }, [updateLocalStreamTracks, refreshDevices, initCameras]);

  function log(type: string, message: string, ...params: any[]) {
    // if (env.debug) {
    console.log(`=> [${type}] | ` + message, params.length > 0 ? params : undefined);
    // }
  }

  return (
    <div className={`meeting-settings ${open ? 'open' : ''}`}>
      <Tabs type="card">
        <TabPane tab={t('Einstellungen')} key="1">
          <div className={'content-wrapper'}>
            <div className={'scroller'}>
              <div>
                <div><label>{t('Kamera')}</label></div>
                <div>
                  {cameras && <Select value={selectedCamera || defaultCamera} style={{width: '100%'}} onChange={handleCameraChange}
                                      disabled={selectedCamera === SHARE_SCREEN}>
                    {cameras.map(it => <Option key={it.deviceId} value={it.deviceId}>{it.label}</Option>)}
                  </Select>}
                </div>
              </div>

              <div className='device'>
                <div><label>{t('Mikrofone')}</label></div>
                <div>
                  {microphones &&
                  <Select disabled={microphones.length === 1} value={selectedMicrophone || defaultMicrophone} style={{width: '100%'}}
                          onChange={handleMicrophoneChange}>
                    {microphones.map(it => <Option key={it.deviceId} value={it.deviceId}>{it.label}</Option>)}
                  </Select>}
                </div>
              </div>

              <div className='device'>
                <div><label>{t('Speakers')}</label></div>
                <div>
                  {speakers && <Select disabled={speakers.length === 1} value={selectedSpeaker || defaultSpeaker} style={{width: '100%'}}
                                       onChange={handleSpeakerChange}>
                    {speakers.map(it => <Option key={it.deviceId} value={it.deviceId}>{it.label}</Option>)}
                  </Select>}
                </div>
              </div>

              <div className='layout'>
                <div><label>{t('Kamera Layout')}</label></div>
                <div className="layouts">
                  <div className={`presentation-layout ${!isPresentationMode ? '' : 'active'}`} onClick={() => onPresentationModeChange(true)}>
                    <div className="grid">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <label>{t('Presentation')}</label>
                  </div>
                  <div className={`meeting-layout ${!isPresentationMode ? 'active' : ''}`} onClick={() => onPresentationModeChange(false)}>
                    <div className="grid">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <label>{t('Meeting')}</label>
                  </div>
                </div>
              </div>
            </div>
            <footer>
              <div className='buttons'>
                <Button type={'primary'} size="large" className='speichern-button' onClick={saveSettings}>{t('Speichern')}</Button>
                <Button type={'primary'} size="large" className='abbrechen-button' onClick={cancelSettings}>{t('Abbrechen')}</Button>
              </div>
            </footer>
          </div>
        </TabPane>
      </Tabs>

      {!isMeetingOwner && errorDeviceType &&
      <DeviceErrorModal deviceType={errorDeviceType} onStart={confirmStartMeetingWithoutCamera} onAbort={abortMeeting} onRefresh={refreshDevices}/>}
    </div>
  );
}
