import React, {useCallback, useEffect, useState} from "react";
import MeetingApi from "../../api/MeetingApi";
import {handleServerError} from "../../util/ErrorHandler";
import {activeParticipantIdsState} from "../../state/atoms";
import {useSetRecoilState} from "recoil";

interface MeetingActivesProps {
  meetingId: string;
}

const CHECK_MEETING_ACTIVES_INTERVAL = 30000;

export default function MeetingActives({meetingId}: MeetingActivesProps) {
  const setActiveParticipantIds = useSetRecoilState(activeParticipantIdsState);
  const [active, setActive] = useState(true);

  const getMeetingStatus = useCallback((publicId: string, errorCallback: () => void) => {
    MeetingApi.getMeetingActives(publicId)
      .then(resp => setActiveParticipantIds(resp.data))
      .catch((e) => {
        handleServerError(e);
        errorCallback();
      });
  }, [setActiveParticipantIds]);

  useEffect(() => {
    if (!active || !meetingId) return;

    const interval = setInterval(() => {
      if (active) {
        getMeetingStatus(meetingId, () => {
          if (interval) {
            setActive(false);
          }
        });
      }
    }, CHECK_MEETING_ACTIVES_INTERVAL);

    return () => {
      clearInterval(interval);
    }
  }, [meetingId, getMeetingStatus, active]);

  return <></>
}
