import * as React from 'react';
import './AppointmentLine.scss';
import {useTranslation} from "react-i18next";
import {MeetingDto} from "../../gen/client";
import {Button, Checkbox, Dropdown, Menu, Modal} from "antd";
import {formatTime, formatDate, fromTimestamp, successMessage, fetchMeetingParticipants, downloadFileUsingBlob} from "../../util/utils";
import MenuItem from "antd/es/menu/MenuItem";
import {ReactComponent as userIcon} from '../../img/icons/user-icon.svg';
import MeetingApi from '../../api/MeetingApi';
import {handleServerError} from '../../util/ErrorHandler';
import {useState} from 'react';
import {Routes} from '../../util/routes';
import {useHistory} from 'react-router-dom';
import Icon from '@ant-design/icons';
import DotsIcon from "../general/icons/DotsIcon";
import AppointmentDeleteCard from './AppointmentDeleteCard';
import {ReactComponent as iconMultiSelectionContacts} from '../../img/icons/icon-multi-selection-contacts.svg';
import CopyUrl from '../general/CopyUrl';
import {getJoinLink, getMeetingInfoFileName, getParticipantsWhoJoined} from '../../service/MeetingService';
import AppointmentOraDeleteModal from './AppointmentOraDeleteCard';

interface AppointmentListProps {
  meeting: MeetingDto;
  selected: boolean;
  past: boolean;
  onSelectToggle: (id: string) => void;
  onClick: (meeting: MeetingDto) => void;
  onDelete: (id: string) => void;
}

export default function AppointmentLine({meeting, selected, past, onSelectToggle, onClick, onDelete}: AppointmentListProps) {
  const {t} = useTranslation();
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const [openWarningDeleteModal, setOpenWarningDeleteModal] = useState(false);
  const [notify, setNotify] = useState(true);
  const [inviting, setInviting] = useState(false);

  const menu = (
    <Menu className={'apt-mr-men'}>
      <MenuItem onClick={(ev) => {
        ev.domEvent.stopPropagation();
        showDeleteConfirm()
      }}><Button type={'link'} disabled={meeting.removed || past}>{t('Löschen')}</Button></MenuItem>
      <MenuItem onClick={(ev) => {
        ev.domEvent.stopPropagation();
        print()
      }}><Button type={'link'} disabled={meeting.anonymized}>{t('Export')}</Button></MenuItem>
      <MenuItem onClick={(ev) => {
        ev.domEvent.stopPropagation();
        goToEdit()
      }}><Button type={'link'} disabled={past}>{t('Bearbeiten')}</Button></MenuItem>
      <MenuItem onClick={resendInvitation}><Button type={'link'} disabled={past}>{t('Einladung erneut senden')}</Button></MenuItem>
    </Menu>
  );

  const participants = meeting.participants.map(it => (
    <li key={it.id}>
      <Icon component={userIcon} alt={''}/>
      <span>{it.name}&nbsp;</span>
    </li>
  ));

  const groups = meeting.groups.map(it => (
    <li key={it.id}>
      <Icon component={iconMultiSelectionContacts} alt={''}/>
      <span>{it.name}&nbsp;</span>
    </li>
  ));

  function goToEdit() {
    history.push((meeting.groups.length === 0 && meeting.participants.length === 1) ? `${Routes.APPOINTMENTS_EDIT_SINGLE}/${meeting.publicId}` : `${Routes.APPOINTMENTS_EDIT_GROUP}/${meeting.publicId}`);
  }

  function showDeleteConfirm() {
    meeting.fromOra ? setOpenWarningDeleteModal(true) : setOpenModal(true);
  }

  function handleDelete() {
    setOpenModal(false);
    MeetingApi.deleteMeetings(notify, [meeting]).then(response => {
      onDelete(response.data[0]);
      successMessage(t(notify ? 'Succesfully deleted and invitations succesfully sent' : 'Succesfully deleted!'));
    }).catch(handleServerError);
  }

  function print() {
    MeetingApi.printMeetingInfo(meeting.publicId).then(response => {
      const participants = getParticipantsWhoJoined(meeting.logs);
      downloadFileUsingBlob(response.request.response, getMeetingInfoFileName(participants));
    }).catch(handleServerError);
  }

  function handleCancelDelete() {
    setOpenModal(false);
  }

  function resendInvitation(ev: any) {
    ev.domEvent.stopPropagation();
    setInviting(true);
    MeetingApi.resendNotifications(meeting.id).then(resp => {
      successMessage(t('Invitation resent'));
      setInviting(false);
    }).catch(err => {
      handleServerError(err)
      setInviting(false);
    });
  }

  const copyUrlEntries = fetchMeetingParticipants(meeting)?.map((p) => {
    return {
      url: getJoinLink(meeting.publicId, p.publicId),
      name: p.name,
      publicId: p.publicId
    }
  });

  const date = fromTimestamp(meeting.startDate);

  return (
    <>
      <div className={"vd-line " + (selected ? 'active' : '')} onClick={() => onClick(meeting)}>
        <Checkbox checked={selected} onChange={() => onSelectToggle(meeting.id)} onClick={ev => ev.stopPropagation()}/>
        <span className={'dtm'}>
          <span>{formatDate(date)}</span>
          <span>{formatTime(date)}</span>
        </span>
        <span className={'dr'}>{meeting.duration} {t('Min')}</span>
        <ul className={'pr'}>{participants} {groups}</ul>
        <div className="actions">
          <div className={'copy-url'}>
            <CopyUrl entries={copyUrlEntries} />
          </div>

          <Dropdown overlay={menu} trigger={['click']} placement={'bottomRight'}>
            <Button type={'link'} size={'large'} onClick={ev => ev.stopPropagation()} loading={inviting}>
              <DotsIcon/>
            </Button>
          </Dropdown>
        </div>
      </div>

      <Modal
        className='delete-card single-delete'
        visible={openModal}
        closable={false}
        onOk={(ev) => {
          ev.stopPropagation();
          handleDelete()
        }}
        onCancel={(ev) => {
          ev.stopPropagation();
          handleCancelDelete()
        }}
        okText={t('Ja')}
        okButtonProps={{className: 'modal-button', size: 'large'}}
        cancelText={t('Nein')}
        cancelButtonProps={{className: 'modal-button', size: 'large'}}>
        <AppointmentDeleteCard meeting={meeting} notify={notify} onNotifyChange={() => setNotify(!notify)}/>
      </Modal>

      <AppointmentOraDeleteModal meeting={meeting} visible={openWarningDeleteModal} onClose={() => setOpenWarningDeleteModal(false)}/>
    </>
  )
}
