import React from 'react';
import './ErrorBoundary.scss';
import ErrorIcon from './icons/ErrorIcon';
import AppErrorAPI from '../../api/AppErrorAPI';
import { handleServerError } from '../../util/ErrorHandler';
import {  } from 'react-router-dom';
import moment from 'moment';
import {timestamp} from '../../util/utils';

export class ErrorBoundary extends React.Component {

  constructor(props: any) {
    super(props);
    this.state = { error: false }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {

    this.setState({
      error: true
    });

    AppErrorAPI.reportRuntimeError({
      type: error.name,
      message: error.message,
      stackTrace: error.stack,
      userAgent: window.navigator.userAgent,
      date: timestamp(moment()),
      route: window.location.pathname
    }).catch(handleServerError);
  }

  render() {
    const { error } = this.state as {
      error: boolean
    };

    if (error) {
      return (
        <div className={'application-error'}>
          <div>
            <ErrorIcon className={'error-icon'}/>
          </div>
          <div>
            <p className={'error-text'}>Ein Fehler ist aufgetreten</p>
          </div>
        </div>
      )
    } else {
      return this.props.children;
    }
  }
}
