import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../util/routes';
import {useEffect, useState} from 'react';
import Storage from "../../util/Storage";
import {ContentDto, ContentDtoTypeEnum} from '../../gen/client';
import {handleServerError} from '../../util/ErrorHandler';
import {useRecoilState} from 'recoil';
import {loggedMember} from '../../state/atoms';
import ContentApi from '../../api/ContentApi';
import ContentLine from './ContentLine';
import {Skeleton} from "antd";

const contentTypesOrdered = [ContentDtoTypeEnum.Homepage, ContentDtoTypeEnum.Register, ContentDtoTypeEnum.Help, ContentDtoTypeEnum.Faq, ContentDtoTypeEnum.Contact, ContentDtoTypeEnum.Imprint,
  ContentDtoTypeEnum.Terms, ContentDtoTypeEnum.DataPrivacy, ContentDtoTypeEnum.DataPrivacyProvider];

function contentSorter(a: ContentDto, b: ContentDto) {
  return contentTypesOrdered.indexOf(a.type) - contentTypesOrdered.indexOf(b.type);
}

export default function ContentList() {
  const history = useHistory();

  const [logged] = useRecoilState(loggedMember);
  const [loading, setLoading] = useState(false);
  const [contentTypes, setContentTypes] = useState([] as ContentDto[]);

  useEffect(() => {
    setLoading(true);
    ContentApi.getAvailableContentTypes(Storage.getMemberId()).then(resp => {
      setContentTypes(Array.from(resp.data).sort(contentSorter));
      setLoading(false);
    }).catch(err => {
      handleServerError(err);
      setLoading(false);
    });
  }, [logged]);


  function editContent(type: ContentDtoTypeEnum) {
    history.push(`${Routes.CMS}/${type.toLowerCase()}/edit`);
  }

  return (
    <div className={'contact-list'}>
      {loading && <Skeleton loading={true} />}
      {!loading && <>{contentTypes.map(it => <ContentLine content={it} onEdit={editContent} key={it.id}/>)}</>}
    </div>
  )
}
