import * as React from 'react';
import './EditorKeyWords.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Button} from "antd";
import {useTranslation} from "react-i18next";

interface EditorKeyWordsProps {
  words: string[];
  onClick: (word: string) => void;
}

export default function EditorKeyWords({words, onClick}: EditorKeyWordsProps) {
  const {t} = useTranslation();

  return (
    <header className={'key-words'}>
      <h6>{t('Key words')}:</h6>
      <ul>
        {words.map(it => <li key={it}><Button size={'small'} type={"dashed"} onMouseDown={ev => ev.preventDefault()} onClick={() => onClick(it)}>{it}</Button></li>)}
      </ul>
    </header>
  );
}
