import * as React from 'react';
import './QuestionFields.scss';
import { useTranslation } from 'react-i18next';
import { CmsQuestionDto } from '../../gen/client';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { activePractice, loggedMember } from '../../state/atoms';
import { handleServerError } from '../../util/ErrorHandler';
import PracticeApi from '../../api/PracticeApi';
import QuestionFields from './QuestionFields';
import { AxiosPromise } from 'axios';
import TenantApi from '../../api/TenantApi';
import { TENANT_ID } from '../../util/constants';
import { isAdminOrCustomerCare } from '../../util/utils';

interface AddQuestionProps {
  onSave: () => void;
  onCancel: () => void;
}

export default function AddQuestion({onSave, onCancel}: AddQuestionProps) {
  const {t} = useTranslation();
  const {id} = useParams();
  const [practice] = useRecoilState(activePractice);
  const [logged] = useRecoilState(loggedMember);

  function save(question: CmsQuestionDto){
    const action: AxiosPromise = isAdminOrCustomerCare(logged) ? TenantApi.createAdminQuestion(id, TENANT_ID, question) :  PracticeApi.createProviderQuestion(id, practice.id, question);
    action
      .then(() => onSave())
      .catch(handleServerError);
  }

  return (
    <div className='question'>
      <h3>{t('Neue Frage')}</h3>
      <QuestionFields it={{}} onSave={save} onCancel={onCancel}/>
    </div>
  )
}
