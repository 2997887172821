import {AxiosPromise} from 'axios';
import {apiConfig, ops} from './ApiConfig';
import {AppError, AppErrorDto, AppResourceApi} from '../gen/client';

const api = new AppResourceApi(apiConfig);

const errorApi = {
  reportRuntimeError(error: AppErrorDto): AxiosPromise<AppError> {
    return api.reportError(error, ops())
  }
}

export default errorApi;