import * as React from 'react';
import './LoginPage.scss';
import { useTranslation } from "react-i18next";
import regImage from '../../img/register-1.svg';
import mainLogo from '../../img/noventi-video-logo.png';
import StartPageFooter from "./StartPageFooter";
import { NavLink, useHistory } from "react-router-dom";
import LoginForm from "./LoginForm";
import {Routes} from "../../util/routes";
import { getApplicationSizeClass } from '../../util/utils';
import { useRecoilValue } from 'recoil';
import { Dimensions } from '../../util/enums';
import { applicationSize } from '../../state/atoms';


export default function LoginPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const appSize = useRecoilValue<Dimensions>(applicationSize);

  function onLoginSuccess() {
		history.push(Routes.LOGIN_CONFIRM);
  }

  return (
    <div className={`login-page ${getApplicationSizeClass(appSize)}`}>
      <div className={'hero'}>
        <div className={'ct'}>
          <img src={regImage} className={'main'} alt={''} />
        </div>
      </div>
      <div className={'content'}>
        <div className={'data'}>
          <img className={'logo'} src={mainLogo} alt={'logo'} />
          <h3>{t('Melde dich in deinem Konto an!')}</h3>
          <h6>
            {t('Sie haben noch keinen Account?')}&nbsp;
            <NavLink to={Routes.REGISTER}>{t('Registrieren')}</NavLink>
          </h6>

          <LoginForm onLoginSuccess={onLoginSuccess} />
        </div>
        {appSize === Dimensions.DESKTOP && <StartPageFooter />}
      </div>
      {appSize !== Dimensions.DESKTOP && <StartPageFooter />}
    </div>
  );
}
