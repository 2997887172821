import React, {useEffect, useLayoutEffect, useState} from 'react';
import Container from '../general/Container';
import {Card, Col, Row} from 'antd';
import StatisticTile from './StatisticTile';
import {useTranslation} from 'react-i18next';
import IntervalPicker, {DatePickerInterval} from '../general/IntervalPicker';
import moment from 'moment';
import {ChartData, Line} from 'react-chartjs-2';
import {handleServerError} from '../../util/ErrorHandler';
import RegisterVideoIcon from '../general/icons/RegisterVideoIcon';
import VideoCallDurationIcon from '../general/icons/VideoCallDurationIcon';
import FeedbackIcon from '../general/icons/FeedbackIcon';
import * as chartjs from 'chart.js';
import {ChartDataSets} from 'chart.js';
import {formatDuration, intervalEnd, intervalStart} from '../../util/utils';
import StatisticsChartLegend from './StatisticsChartLegend';
import './AdminStatistics.scss';
import AdminApi from "../../api/AdminApi";
import {generateMeetingsStatisticsDataSets, generateMeetingsDurationStatisticsDataSets, GENERAL_STATISTICS, MEETINGS_STATISTICS} from "../../service/StatsService";
import { Dimensions } from '../../util/enums';
import IntervalPickerMobile from '../general/IntervalPickerMobile';
import { useRecoilState, useRecoilValue } from 'recoil';
import { applicationSize, loggedMember } from '../../state/atoms';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../util/routes';
import CustomerCareApi from '../../api/CustomerCareApi';
import UserIcon from '../general/icons/UserIcon';
import {MEETING_DATE_FORMAT, MEETING_DATE_FORMAT_SHORT} from "../../util/constants";

export default function AdminStatistics() {
  const {t} = useTranslation();
  const history = useHistory();

  const [interval, setInterval] = useState([moment().subtract(30, 'days'), moment()] as DatePickerInterval);
  const [meetingsChartData, setMeetingsChartData] = useState<ChartData<chartjs.ChartData>>(null);
  const [meetingsDurationChartData, setMeetingsDurationChartData] = useState<ChartData<chartjs.ChartData>>(null);

  const [meetingsDurationChartMax, setMeetingsDurationChartMax] = useState(0);
	const [meetingsChartMax, setMeetingsChartMax] = useState(0);

  const [generalStatistics, setGeneralStatistics] = useState(GENERAL_STATISTICS);
  const [meetingsStatistics, setMeetingsStatistics] = useState(MEETINGS_STATISTICS);

  const [calendarOpen, setCalendarOpen] = useState(false);

  const appSize = useRecoilValue<Dimensions>(applicationSize);
  const [logged] = useRecoilState(loggedMember);
  const [windowSize, setWindowSize] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize(window.innerWidth * window.innerHeight);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    const action = logged.isAdmin ? AdminApi.getGeneralStatistics() : CustomerCareApi.getGeneralStatistics();
    action
    .then(res => setGeneralStatistics(res.data))
    .catch(handleServerError);
  }, [logged.isAdmin]);

  useEffect(() => {
    const action = logged.isAdmin ? AdminApi.getMeetingsStatistics(intervalStart(interval), intervalEnd(interval)) : CustomerCareApi.getMeetingsStatistics(intervalStart(interval), intervalEnd(interval));
    action
    .then((res) => setMeetingsStatistics(res.data))
    .catch(handleServerError);
  }, [interval, logged.isAdmin]);

  useEffect(() => {
    const chartLabels: string[] = [];
    const meetingsDataSets: ChartDataSets[] = [];
    const meetingsDurationDataSets: ChartDataSets[] = [];
    const diff = interval[1].diff(interval[0], 'day');
    const now = moment().startOf('day');

    for (let i = 0; i <= diff; i++) {
      const startDate = moment(interval[0]).startOf('day');
      const currentDate = startDate.add(i, 'day');

      if (currentDate.isBefore(now) || currentDate.isSame(now)) {
        chartLabels.push(currentDate.format(MEETING_DATE_FORMAT_SHORT));
      }
    }
    meetingsDataSets.push(...generateMeetingsStatisticsDataSets(meetingsStatistics.dailyStatistics, moment(interval[0]), moment(interval[1])));
    meetingsDurationDataSets.push(...generateMeetingsDurationStatisticsDataSets(meetingsStatistics.effectiveDurationDailyStatistics, moment(interval[0]), moment(interval[1])));

    setMeetingsChartData({labels: chartLabels, datasets: meetingsDataSets});
    setMeetingsDurationChartData({labels: chartLabels, datasets: meetingsDurationDataSets});

		setMeetingsChartMax(
			Math.max(
				...meetingsStatistics.dailyStatistics.map(duration => duration.scheduledMeetings),
				...meetingsStatistics.dailyStatistics.map(duration => duration.performedMeetings)
			)
		);

    setMeetingsDurationChartMax(
    	Math.max(
				...meetingsStatistics.effectiveDurationDailyStatistics.map(duration => duration.effectiveDuration)
			)
		);

  }, [meetingsStatistics, interval]);

  function goToFeedbacks() {
    history.push(Routes.STATISTICS_FEEDBACK)
  }

  return (
    <Container className={'statistics admin-statistics full'}>
      <header>
        <h2>{t('Statistik')}</h2>
      </header>
      <Col className='content'>
        <div className={'statistics-cards'}>
          <div>
            <StatisticTile text={generalStatistics.totalAccounts.toString()} subtext={t('Accounts')} icon={<UserIcon style={{fill: '#5A4EE6'}}/>}/>
          </div>
          <div>
            <StatisticTile text={generalStatistics.totalCallsScheduled.toString()} subtext={t('Calls scheduled')} icon={<RegisterVideoIcon style={{fill: '#FF5F43'}}/>}/>
          </div>
          <div>
            <StatisticTile text={generalStatistics.totalCallsPerformed.toString()} subtext={t('Calls ended')} icon={<RegisterVideoIcon style={{fill: '#24BF30'}}/>}/>
          </div>
          <div>
            <StatisticTile text={formatDuration(generalStatistics.totalCallsDuration)} subtext={t('Total call duration')} icon={
              <VideoCallDurationIcon style={{fill: '#00D2A5'}}/>
            }/>
          </div>
          <div>
            <StatisticTile text={generalStatistics.totalAnswersCount.toString()} subtext={t('Feedbacks')} click={goToFeedbacks}
                icon={<FeedbackIcon style={{fill: '#FF5059'}}/>} url={{url: '#', urlText: t('alles sehen')}}/>
          </div>
        </div>

        <Row className={'title'} gutter={24} align="middle">
          <Col>
            <header>
              <h2>{t('Historie')}</h2>
            </header>
          </Col>
          {appSize !== Dimensions.MOBILE ? <Col><IntervalPicker value={interval} onChange={setInterval}/></Col> :
            <Col xs={24} className={'picker-wrapper'}>
              <IntervalPickerMobile open={calendarOpen} openChange={setCalendarOpen} value={interval} valueChange={setInterval}/>
              <div>{interval[0].format(MEETING_DATE_FORMAT)} - {interval[1].format(MEETING_DATE_FORMAT)}</div>
            </Col>
          }
        </Row>
        <div className='split' key={`${windowSize}`}>
            <Card>
              <Row gutter={[{xs: 10, sm: 15}, 16]}>
                  <Col>
                    <StatisticsChartLegend
                      icon={<RegisterVideoIcon style={{width: 24, fill: '#FF5F43'}}/>}
                      text={t('Calls scheduled')}
                      val={meetingsStatistics.totalMeetingsScheduled.toString()}/>
                  </Col>
                  <Col >
                    <StatisticsChartLegend
                      icon={<RegisterVideoIcon style={{width: 24, fill: '#24BF30'}}/>}
                      text={t('Calls ended')}
                      val={meetingsStatistics.totalMeetingsPerformed.toString()}/>
                  </Col>
              </Row>
              <Row>
                <Line data={meetingsChartData} height={500}
                      options={
                        {
                          maintainAspectRatio: false,
                          legend: null,
                          scales:
                            {
                              yAxes: [
                                {id: 'count', ticks: {beginAtZero: true, stepSize: 1, suggestedMax: meetingsChartMax, suggestedMin: 0}}
                              ]
                            }
                        }
                      }/>
              </Row>
            </Card>
            <Card>
              <Row gutter={[{xs: 10, sm: 15}, 16]}>
                  <Col>
                    <StatisticsChartLegend
                      icon={<VideoCallDurationIcon style={{width: 24, fill: '#00D2A5'}}/>}
                      text={t('Min Gesprächsdauer')}
                      val={formatDuration(meetingsStatistics.totalEffectiveDuration)}/>
                  </Col>
              </Row>
              <Row>
                <Line data={meetingsDurationChartData} height={500}
                      options={
                        {
                          maintainAspectRatio: false,
                          legend: null,
                          scales:
                            {
                              yAxes: [
                                {id: 'count', ticks: {beginAtZero: true, stepSize: 5, suggestedMax: meetingsDurationChartMax, suggestedMin: 0}}
                              ]
                            }
                        }
                      }/>
              </Row>
            </Card>
        </div>
      </Col>
    </Container>
  )
}
