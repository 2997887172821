import * as React from 'react';
import {useEffect, useState} from 'react';
import './CMSPage.scss';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../util/routes';
import {NotificationDto, NotificationDtoTypeEnum} from '../../gen/client';
import {handleServerError} from '../../util/ErrorHandler';
import {useRecoilState} from 'recoil';
import {activePractice, loggedMember} from '../../state/atoms';
import {isAdmin, isCustomerCare, isPracticeManager} from '../../util/utils';
import PracticeApi from "../../api/PracticeApi";
import NotificationLine from "./NotificationLine";
import AdminApi from "../../api/AdminApi";
import {notificationSorter, sortNotificationByType} from "../../service/ContentService";
import {Skeleton} from "antd";

interface NotificationListProps {
  type: NotificationDtoTypeEnum
}

export default function NotificationList({type}: NotificationListProps) {
  const history = useHistory();
  const [logged] = useRecoilState(loggedMember);
  const [practice] = useRecoilState(activePractice);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([] as NotificationDto[]);

  useEffect(() => {
    if (!isPracticeManager(logged) && !isAdmin(logged) && !isCustomerCare(logged)) return;
    const promise = isPracticeManager(logged) ? PracticeApi.getNotifications(practice.id) : AdminApi.getNotifications();
    setLoading(true);
    promise.then(resp => {
      setNotifications(Array.from(resp.data)
        .filter(it => it.type === type)
        .sort(notificationSorter)
        .sort(sortNotificationByType)
      );
      setLoading(false);
    }).catch(err => {
      handleServerError(err);
      setLoading(false);
    });
  }, [logged, practice.id, type]);

  function onNotificationClick(notification: NotificationDto) {
    history.push(`${Routes.NOTIFICATION}/${notification.type}/${notification.name}/edit`);
  }

  return (
    <div className={'contact-list'}>
      {loading && <Skeleton loading={true}/>}
      {!loading && notifications.map(it => <NotificationLine notification={it} onClick={onNotificationClick} key={it.id}/>)}
    </div>
  )
}
