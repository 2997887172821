import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { AllowStatus, DeviceType, NetworkConnectionStatus } from '../../util/enums';
import { getConnectedDevices } from '../../service/DeviceService';
import { testNetworkSpeed } from '../../service/NetworkService';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../util/routes';
import CheckConnectionTemplate from '../general/CheckConnectionTemplate';

export default function CheckConnectionTest() {
  const { t } = useTranslation();
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(-1);
  const [audioStatus, setAudioStatus] = useState('');
  const [cameraStatus, setCameraStatus] = useState('');
  const [networkStatus, setNetworkStatus] = useState('' as NetworkConnectionStatus);
  const [errorDeviceType, setErrorDeviceType] = useState(null as DeviceType);

  const [finished, setFinished] = useState(false);

  const init = useCallback(() => {
    setTimeout(() => {
      setCurrentStep(0);
      setNetworkStatus(testNetworkSpeed);
    }, 1500);
    getConnectedDevices(DeviceType.AUDIO_INPUT, audio => {
      setTimeout(() => {
        setCurrentStep(1);
        let status = (audio.length > 0) ? AllowStatus.ALLOWED : AllowStatus.NOT_ALLOWED;
        setAudioStatus(status);
      }, 3000);
    });
    getConnectedDevices(DeviceType.VIDEO_INPUT, cameras => {
      setTimeout(() => {
        setCurrentStep(2);
        let status = (cameras.length > 0) ? AllowStatus.ALLOWED : AllowStatus.NOT_ALLOWED;
        setCameraStatus(status);
      }, 4500);
    });
  }, []);

  useEffect(() => {
    init()
  }, [init]);

  useEffect(() => {
    if (currentStep === 2) {
      setFinished(true);
    }
  }, [currentStep]);

  useEffect(() => {
    if (networkStatus === NetworkConnectionStatus.PASSED) {
      if (audioStatus === AllowStatus.NOT_ALLOWED) {
        setTimeout(() => {
          setErrorDeviceType(DeviceType.AUDIO_INPUT);
        }, 500);
      } else if (cameraStatus === AllowStatus.NOT_ALLOWED) {
        setTimeout(() => {
          setErrorDeviceType(DeviceType.VIDEO_INPUT);
        }, 500);
      }
    }
  }, [networkStatus, cameraStatus, audioStatus, t]);

  function refreshDevices() {
    setCurrentStep(-1);
    setAudioStatus('');
    setCameraStatus('');
    setNetworkStatus('' as NetworkConnectionStatus);
    setErrorDeviceType(null as DeviceType);

    init();
  }

  function cancelConnectonTest() {
    history.push(Routes.LOGIN);
  }

  return (
    <CheckConnectionTemplate currentStep={currentStep} errorDeviceType={errorDeviceType} finished={finished}
      audioStatus={audioStatus} cameraStatus={cameraStatus} networkStatus={networkStatus}
      onStart={cancelConnectonTest} onAbort={cancelConnectonTest} onRefresh={refreshDevices} />
  )
}
