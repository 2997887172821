import * as React from 'react';
import './ContactLine.scss';
import {Checkbox, Button, Tooltip} from 'antd';
import {ReactComponent as videoIcon} from '../../img/icons/video-outline-icon.svg';
import {ReactComponent as editIcon} from '../../img/icons/edit-icon.svg';
import {ReactComponent as femaleIcon} from '../../img/icons/gender-female-icon.svg';
import {ReactComponent as maleIcon} from '../../img/icons/gender-male-icon.svg';
import {ReactComponent as diversIcon} from '../../img/icons/gender-divers-icon.svg';
import {
  AccountDto, AccountDtoGenderEnum,
} from "../../gen/client";
import {ReactComponent as deleteIcon} from '../../img/icons/trash-icon.svg';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface ContactLineProps {
  contact: AccountDto;
  selected: boolean;
  reference: any;
  shortView: boolean;
  onSelectToggle: (id: string) => void;
  onClick: (id: string) => void;
  onVideoIconClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onEditIconClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onDeleteIconClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  multipleSelection: boolean;
}

export default function ContactLine({contact, selected, reference, shortView, multipleSelection,
                                    onSelectToggle, onClick, onVideoIconClick, onEditIconClick, onDeleteIconClick}: ContactLineProps) {
	const {t} = useTranslation();

  return shortView ?
    (
      <Tooltip title={selected ? t("If you deselect the checkbox you get back to the Contact list") : undefined}><div className={`vd-line short ${selected ? 'active' : ''}`} ref={reference} onClick={() => onClick(contact.id)}>
        <Checkbox checked={selected} onChange={() => onSelectToggle(contact.id)}/>
        <div className='contact-name'>
          <span>{contact.name}</span>
          <Icon className={`icon-only gender ${contact.gender === AccountDtoGenderEnum.Female ? 'female-icon' : contact.gender === AccountDtoGenderEnum.Male ? 'male-icon' : 'divers-icon'}`}
                component={contact.gender === AccountDtoGenderEnum.Female ? femaleIcon : contact.gender === AccountDtoGenderEnum.Male ? maleIcon : diversIcon} alt={''}/>
        </div>
        <div className='phone'>{contact.phone}</div>
    </div></Tooltip>) :
    
    (<div className={`vd-line ${selected ? 'active' : ''}`} ref={reference} onClick={() => onClick(contact.id)}>
      <div className='col name'>
        <Checkbox checked={selected} onChange={() => onSelectToggle(contact.id)}/>
        <div className='contact-name'>
          <span>{contact.name}</span>
          <Icon className={`icon-only gender ${contact.gender === AccountDtoGenderEnum.Female ? 'female-icon' : contact.gender === AccountDtoGenderEnum.Male ? 'male-icon' : 'divers-icon'}`}
                component={contact.gender === AccountDtoGenderEnum.Female ? femaleIcon : contact.gender === AccountDtoGenderEnum.Male ? maleIcon : diversIcon} alt={''}/>
        </div>
      </div>
      <span className='col phone'>{contact.phone}</span>
      <div className='col email-videos-wrapper'>
        <div className='col email'><Button type={'link'} className='link'>{contact.email}</Button></div>
        <div className='col videos'></div>
      </div>
      <div className="actions">
        <Button type={'link'} className="icon-only" onClick={onVideoIconClick} disabled={multipleSelection}><Icon component={videoIcon} alt={'video'}/></Button>
        <Button type={'link'} className="icon-only" onClick={onEditIconClick} disabled={multipleSelection}><Icon component={editIcon} alt={'edit'}/></Button>
        <Button type={'link'} className="icon-only" onClick={onDeleteIconClick}><Icon component={deleteIcon} alt={'delete'}/></Button>
      </div>
    </div>)
}
