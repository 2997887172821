import * as React from 'react';
import './GroupFields.scss';
import { useTranslation } from 'react-i18next';
import {AppSettingsDto, AccountGroupDto, AccountDto} from '../../gen/client';
import { Button, Divider, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { isValidEmail, isValidNumber } from "../../util/validators";
import { useRecoilState, useRecoilValue } from 'recoil';
import { activePractice, applicationSize, loggedMember } from '../../state/atoms';
import Account from '../appointment/Account';
import { DEFAULT_PHONE_PREFIX } from '../../util/constants';
import SearchContactModal from '../appointment/SearchContactModal';
import { getApplicationSizeClass, notificationChannelProvided } from '../../util/utils';
import { Dimensions } from '../../util/enums';
import PracticeApi from '../../api/PracticeApi';
import { handleServerError } from '../../util/ErrorHandler';
import AddContactModal from '../appointment/AddContactModal';
import OkModal from '../general/OkModal';
import Accounts from "../appointment/Accounts";

interface GroupFieldsProps {
  onSave: (group: AccountGroupDto) => void;
  onCancel: () => void;
  active?: AccountGroupDto;
}

export default function GroupFields({active, onSave, onCancel}: GroupFieldsProps) {
  const { t } = useTranslation();
  const appSize = useRecoilValue<Dimensions>(applicationSize);
  const [practice] = useRecoilState(activePractice);
  const [logged] = useRecoilState(loggedMember);

  const [defaultSettings, setDefaultSettings] = useState(null as AppSettingsDto);
  const [accounts, setAccounts] = useState([] as AccountDto[]);
  const [account, setAccount] = useState(null as AccountDto);

  const [openSearchParticipant, setOpenSearchParticipant] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [openAddParticipant, setOpenAddParticipant] = useState(false);

  const [nameErr, setNameErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState(null as AccountDto);
  const [group, setGroup] = useState(null as AccountGroupDto);

  const [initialGroupName, setInitialGroupName] = useState(null as string);
  const [showNameInUseInfo, setShowNameInUseInfo] = useState(false);

  useEffect(() => {
    if (!!active && !!active.id) {
      setGroup(active);
      setInitialGroupName(active.name);
      setAccounts(active.accounts);
    }
  }, [active]);

  useEffect(() => {
    PracticeApi.getSettings(practice.id)
    .then(resp => {
      setDefaultSettings(resp.data);
      if (!!resp.data) {
        setAccount(getEmptyAccount(resp.data))
      }
    })
    .catch(err => handleServerError(err));
  }, [practice.id]);

  function onDelete(idx: number) {
    const newArr = accounts;
    newArr.splice(idx, 1);
    setAccounts([...newArr]);
  }

  function getEmptyAccount(settings: AppSettingsDto): AccountDto {
    return {
      smsNotification: settings?.smsNotification,
      emailNotification: settings?.emailNotification,
      email: null
    } as AccountDto;
  }

  function cancel() {
    setAccount(!!defaultSettings ? getEmptyAccount(defaultSettings) : {});
  }

  function addContact(contact: AccountDto) {
    setAccounts([...accounts, contact]);
  }

  function addNewContact(contact: AccountDto) {
    addContact(contact);
    setAccount(getEmptyAccount(defaultSettings));
    setCanSave(false);
  }

  function addContacts(contacts: AccountDto[]) {
    setAccounts([...accounts, ...contacts]);
    setAccount(getEmptyAccount(defaultSettings));
  }

  function validateParticipantName(idx: number, value: string) {
    setNameErr(!value);
  }

  function validateParticipantPhone(idx: number, value: string, required: boolean) {
    setPhoneErr(value && value !== DEFAULT_PHONE_PREFIX ? !isValidNumber(value) : required);
  }

  function validateParticipantEmail(idx: number, value: string, required: boolean) {
    setEmailErr(value ? !isValidEmail(value) : required);
  }

  function onParticipantChange(updated: AccountDto) {
		var newA = {...account, ...updated};
		setAccount({...account, ...updated});
		setCanSave(notificationChannelProvided(newA) && !!newA.name);
  }

  function onCloseAddParticipantModal() {
    setOpenAddParticipant(false);
    setSelectedAccount(null);
  }

  function onEditAccount(selected: AccountDto) {
    setSelectedAccount(selected);
    setOpenAddParticipant(true);
  }

  function editParticipant(updated: AccountDto) {
    if (!!selectedAccount) {
      setAccounts([...accounts.filter(it => it.id !== selectedAccount.id), updated])
    } else {
      setAccounts([...accounts, updated]);
    }
    setOpenAddParticipant(false);
  }

  function onGroupChosen(chosen: AccountGroupDto) {
    setAccounts([...accounts, ...chosen.accounts]);
  }

  function saveGroup(group: AccountGroupDto) {
    if (!initialGroupName || (!!initialGroupName && initialGroupName !== group.name)) {
      checkNameAvailabilityBeforeSaving(group);
    } else {
      onSave(group);
    }
  }

  function checkNameAvailabilityBeforeSaving(group: AccountGroupDto) {
    PracticeApi.checkNameAvailability(practice.id, logged.id, group.name)
      .then(resp => { resp.data ? onSave(group) : showNameInUseInfoModal() })
      .catch(err => handleServerError(err))
  }

  function showNameInUseInfoModal() {
    setShowNameInUseInfo(true);
  }

  return (
    <div className='group-fields'>
      <div className='split'>
        <div className=''>
          <div className={'ant-form-item'}>
            <label className={'req'}>{t('Gruppe Name')}</label>
            <Input value={group?.name} size={'large'} name={'groupName'} type={'text'} onChange={ev => setGroup({...group, name: ev.target.value})}/>
          </div>
          <Divider />
          <Account
            it={account}
            idx={0}
            nameErr={nameErr}
            phoneErr={phoneErr}
            emailErr={emailErr}
            onNameChange={validateParticipantName}
            onPhoneChange={validateParticipantPhone}
            onEmailChange={validateParticipantEmail}
            onParticipantChange={(p) => onParticipantChange(p)} saveErr={false}
            modal={false}/>          
          <div className='add-contact-btns'>
            <Button type={'primary'} size="large" onClick={() => addNewContact(account)} disabled={!canSave}>{t('Hinzufügen')}</Button>
            <Button className={'default cancel'} size="large" onClick={cancel}>{t('Abbrechen')}</Button>
          </div>
        </div>
        <Divider type="vertical" />
        <div>
          <Divider type="horizontal" />
          <div className={'header'}>
            <h3>{t('Teilnehmer')}</h3>
            <Button className={'search-contact-btn'} size={'middle'} onClick={() => setOpenSearchParticipant(true)}>{t('Suche in der Kontaktliste')}</Button>
          </div>
          <Accounts accounts={accounts} onEditAccount={onEditAccount} onDeleteAccount={onDelete}
                        groups={[]} onEditGroup={() => {}} onDeleteGroup={() => {}}/>
          <Modal
            closable={false}
            className={`search-card ${getApplicationSizeClass(appSize)}`}
            visible={openSearchParticipant}
            width={664}
            centered
            okButtonProps={{style: {display: 'none'}}}
            cancelButtonProps={{style: {display: 'none'}}}
            destroyOnClose={true}>

            <SearchContactModal openSearch={openSearchParticipant} onCloseSearch={() => setOpenSearchParticipant(false)} onContactChosen={addContact} onContactsChosen={addContacts} showGroups={true} onGroupChosen={onGroupChosen}/>
          </Modal>

          <Modal
            closable={false}
            className={`new-participant-card ${getApplicationSizeClass(appSize)}`}
            visible={openAddParticipant}
            width={664}
            centered
            okButtonProps={{style: {display: 'none'}}}
            cancelButtonProps={{style: {display: 'none'}}}
            destroyOnClose={true}
            footer={null}>

            <AddContactModal it={selectedAccount} defaultSettings={defaultSettings} onSave={editParticipant} onClose={onCloseAddParticipantModal}/>
          </Modal>
          <OkModal visible={showNameInUseInfo} onClose={() => setShowNameInUseInfo(false)}
            content={t('Es ist bereits eine Gruppe mit diesem Namen vorhanden. Bitte wählen Sie einen anderen Namen.')}/>
        </div>
      </div>
      <footer className={'btns'}>
        <Button type={'primary'} size="large" onClick={() => saveGroup({...group, accounts})} disabled={accounts.length < 2 || !group?.name}>{t('Speichern')}</Button>
        <Button className={'default cancel'} size="large" onClick={onCancel}>{t('Abbrechen')}</Button>
      </footer>
    </div>
  )
}
