import * as React from 'react';
import './Avatar.scss';
import {getInitials} from "../../util/utils";
import {useEffect, useState} from 'react';

interface AvatarProps {
  avatar?: string,
  name: string
}

export default function Avatar({avatar, name}: AvatarProps) {
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(avatar ? `data:image/png;base64, ${avatar}` : '');
  }, [avatar]);

  return (
    <div className={'avatar'}>
      {url ? <img src={url} alt={''}/> : (<div className={'pl'}>{getInitials(name)}</div>)}
    </div>
  );
}
