import {MemberDto} from '../../gen/client';
import {useTranslation} from 'react-i18next';
import { Button, Upload, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import Avatar from "../general/Avatar";
import "./AvatarSettings.scss";
import {UploadFile} from 'antd/es/upload/interface';
import ImgCrop from 'antd-img-crop';
import MemberApi from '../../api/MemberApi';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {errorMessage, successMessage} from "../../util/utils";

interface AvatarSettingsProps {
	member: MemberDto
	onUploadAvatar: () => void;
	onChange: (avatar: Blob) => void;
	onDeleteAvatar: () => void;
	onFullAvatarChange: (fullAvatar: Blob) => void;
}

export default function AvatarSettings({member, onUploadAvatar, onChange, onDeleteAvatar, onFullAvatarChange}: AvatarSettingsProps) {
  const {t} = useTranslation();
	const { confirm } = Modal;
  const [fileList, setFileList] = useState([] as UploadFile<File>[]);
	const [isUploading, setIsUploading] = useState(false);
	const [initialAvatar, setInitialAvatar] = useState(member.avatar);

  function onChangeAvatar() {
    setFileList([]);
  }

  function beforeUpload(file: File) {
    onChange(file);

    return false;
  }

  function getMemberName() {
    return member.firstName + ' ' + member.lastName;
  }

  function confirmDeleteAvatar() {
		confirm({
			title: t(`Soll das Profilbild wirklich gelöscht werden?`),
			icon: <ExclamationCircleOutlined />,
			okText: t('Yes'),
			okType: 'danger',
			cancelText: t('No'),
			onOk() {
				onChange(null);
			}
		});
  }

  function beforeCrop(file: File) {
		if (file.type !== 'image/jpeg') {
			errorMessage(t('onlyJpgAccepted'));
			return false;
		}

		onFullAvatarChange(file);

		return true;
	}

	const uploadAvatar = useCallback(() => {
		if (!member.id) return;

		MemberApi.uploadAvatar(member.id, member).then(resp => {
			if (resp.status === 200) {
				onUploadAvatar();
				successMessage(t('uploadCompleted'));
				setIsUploading(false);
			}
		});
	}, [member, t, onUploadAvatar]);

	const deleteAvatar = useCallback(() => {
		MemberApi.deleteAvatar(member.id).then(resp => {
			if (resp.status === 200) {
				onDeleteAvatar();
			}
		});
	}, [member, onDeleteAvatar]);

	useEffect(() => {
		if (initialAvatar !== member.avatar || !member.avatar) {
			if (member.avatar) {
				uploadAvatar();
			} else if (initialAvatar) {
				deleteAvatar();
			}
			setInitialAvatar(member.avatar);
		}
	}, [member, uploadAvatar, deleteAvatar, initialAvatar]);

  return (
    <div>
      <h3>{t('Profilbild')}</h3>
      <div className="avatar-container">
        <div>
          <Avatar avatar={member.avatar} name={getMemberName()}/>
        </div>
        <div className='avatar-buttons'>
						<ImgCrop
							shape={'round'}
							beforeCrop={beforeCrop}
							modalTitle={t('Profilbild hochladen')}
							modalOk={t('Ok')}
							modalCancel={t('Abbrechen')}
						>
							<Upload onChange={onChangeAvatar} beforeUpload={beforeUpload} multiple={false} fileList={fileList} accept="image/jpeg" >
								<Button className={'default'} size="large" loading={isUploading}>{t('Bid hohladen')}</Button>
							</Upload>
						</ImgCrop>
						<Button className={'danger'} size="large" onClick={confirmDeleteAvatar}>{t('Bid löschen')}</Button>
        </div>
      </div>
    </div>
  );
}
