import * as React from 'react';

export default function SettingsIcon() {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M2.9,6.2h2.6C5.9,8,7.7,9,9.5,8.6c1.2-0.3,2.1-1.2,2.4-2.4h9.3c0.5,0,0.8-0.3,0.9-0.8c0-0.5-0.3-0.8-0.8-0.9c0,0,0,0,0,0 h-9.3c-0.4-1.8-2.3-2.8-4-2.4C6.7,2.4,5.8,3.3,5.5,4.5H2.9C2.4,4.5,2,4.9,2,5.3C2,5.8,2.3,6.2,2.9,6.2C2.8,6.2,2.8,6.2,2.9,6.2z M8.7,3.7c0.9,0,1.7,0.7,1.7,1.7C10.3,6.3,9.6,7,8.7,7C7.8,7,7,6.3,7,5.3C7,4.4,7.8,3.7,8.7,3.7L8.7,3.7L8.7,3.7z" />
      <path d="M2.9,12.8h9.3c0.4,1.8,2.3,2.8,4,2.4c1.2-0.3,2.1-1.2,2.4-2.4h2.6c0.5,0,0.8-0.3,0.9-0.8c0-0.5-0.3-0.8-0.8-0.9c0,0,0,0,0,0 h-2.6c-0.4-1.8-2.3-2.8-4-2.4c-1.2,0.3-2.1,1.2-2.4,2.4H2.9C2.4,11.2,2,11.5,2,12C2,12.4,2.3,12.8,2.9,12.8 C2.8,12.8,2.8,12.8,2.9,12.8z M15.3,10.3c0.9,0,1.7,0.7,1.7,1.7s-0.7,1.7-1.7,1.7s-1.7-0.7-1.7-1.7C13.7,11.1,14.4,10.3,15.3,10.3 L15.3,10.3z" />
      <path d="M2.9,19.5h2.6c0.4,1.8,2.3,2.8,4,2.4c1.2-0.3,2.1-1.2,2.4-2.4h9.3c0.5,0,0.8-0.3,0.9-0.8c0-0.5-0.3-0.8-0.8-0.9c0,0,0,0,0,0 h-9.3c-0.4-1.8-2.3-2.8-4-2.4c-1.2,0.3-2.1,1.2-2.4,2.4H2.9c-0.5,0-0.8,0.4-0.8,0.9C2.1,19.1,2.4,19.5,2.9,19.5z M8.7,17 c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7C7,17.7,7.8,17,8.7,17L8.7,17L8.7,17z" />
    </svg>
  )
}
