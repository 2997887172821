import React from 'react';
import DashboardHeaderMenu from '../dashboard/DashboardHeaderMenu';
import './MeetingPreviewHeader.scss';
import {useRecoilState} from "recoil";
import {loggedMember} from "../../state/atoms";

export default function MeetingPreviewHeader() {
  const [logged] = useRecoilState(loggedMember);

    return (
      <div className='prev-hdr'>
        <div className={'logo'}>
          <div className={'l'} />
        </div>
        {logged.logged && <div className='hdr-menu'>
          <DashboardHeaderMenu />
        </div>}
      </div>
    );
}
