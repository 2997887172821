import * as React from 'react';
import './QuestionCard.scss';
import { Button, Card, Rate } from 'antd';
import {ReactComponent as editIcon} from '../../img/icons/edit-icon.svg';
import {ReactComponent as deleteIcon} from '../../img/icons/trash-icon.svg';
import { CmsQuestionDto, CmsQuestionDtoTypeEnum } from '../../gen/client';
import {ReactComponent as chatIcon} from '../../img/icons/chat-icon.svg';
import TextArea from 'antd/lib/input/TextArea';
import Icon from '@ant-design/icons';
import {ReactComponent as exportIcon} from '../../img/icons/export-icon.svg';

interface QuestionCardProps {
  question: CmsQuestionDto;
  selected: boolean;
  editQuestion: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  deleteQuestion: () => void;
  viewResponses: () => void;
  exportQuestion: () => void;
}

export default function QuestionCard({ question, selected, editQuestion, deleteQuestion, exportQuestion, viewResponses }: QuestionCardProps) {

  return (
    <Card key={question.id} className={`card ${selected ? 'selected': ''}`} hoverable={true}>
        <div className='hdr'>
          <div>{question.label}</div>
          <div className='btns'>
            <Button className="icon-only answers" type={'link'}><Icon component={chatIcon} alt={'chat'} onClick={(ev) => {ev.stopPropagation(); viewResponses()}}/><div className={`${selected ? 'selected': ''}`}>{question.answers.length}</div></Button>
            <Button className="icon-only" type={'link'}><Icon component={editIcon} alt={'edit'} onClick={(ev) => {ev.stopPropagation(); editQuestion(ev)}}/></Button>
            <Button className="icon-only" type={'link'}><Icon component={deleteIcon} alt={'delete'} onClick={(ev) => {ev.stopPropagation(); deleteQuestion()}}/></Button>
            <Button className="icon-only" type={'link'}><Icon component={exportIcon} alt={'delete'} onClick={(ev) => {ev.stopPropagation(); exportQuestion()}}/></Button>
          </div>
        </div>

        {question.type === CmsQuestionDtoTypeEnum.Paragraph && <TextArea disabled={true} rows={3}/>}
        {question.type === CmsQuestionDtoTypeEnum.Rating && <Rate className='rate' disabled={true}/>}
      </Card>
  )
}
