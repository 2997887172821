import React, {useCallback, useState} from 'react';
import './Dashboard.scss';
import DashboardAside from "./DashboardAside";
import {Route, Switch} from "react-router";
import Appointments from "../appointment/Appointments";
import DashboardHeader from "./DashboardHeader";
import Appointment from "../appointment/Appointment";
import Settings from "../settings/Settings";
import MeetingOverview from '../appointment/MeetingOverview';
import Members from '../member/Members';
import {Routes} from '../../util/routes';
import CMSPage from '../cms/CMSPage';
import CMSDetails from '../cms/CMSDetails';
import Content from '../cms/Content';
import Statistics from '../statistic/Statistics';
import StatisticsFeedbackList from '../statistic/StatisticsFeedbackList';
import {useRecoilValue} from 'recoil';
import {applicationSize, loggedMember} from '../../state/atoms';
import {getApplicationSizeClass, isAdminOrCustomerCare, isCustomerCare} from '../../util/utils';
import {Dimensions} from '../../util/enums';
import NotificationEdit from '../cms/NotificationEdit';
import AdminStatistics from '../statistic/AdminStatistics';
import AddContact from '../contact/AddContact';
import UserSettings from "../settings/UserSettings";
import PracticeSettings from "../settings/PracticeSettings";
import PracticeList from "../practice/PracticeList";
import ContactTabs from '../contact/ContactTabs';
import AddGroup from '../group/AddGroup';
import AutoLogout from "../general/AutoLogout";

export default function Dashboard() {
  const loggedUser = useRecoilValue(loggedMember);

  const appSize = useRecoilValue<Dimensions>(applicationSize);
  const [path, setPath] = useState('');

  const onRouteChanged = useCallback((route: string) => {
    setPath(route);
  }, []);

  return (
    <div className={`dashboard ${getApplicationSizeClass(appSize)} ${path}`}>
      <AutoLogout/>
      <DashboardAside onRouteChanged={onRouteChanged}/>
      <DashboardHeader/>
      <main>
        <Switch>
          <Route path={`${Routes.APPOINTMENTS_NEW_SINGLE}/:id?`}><Appointment/></Route>
          <Route path={`${Routes.APPOINTMENTS_NEW_GROUP}/:id?`}><Appointment/></Route>
          <Route path={`${Routes.APPOINTMENTS_EDIT_SINGLE}/:id`}><Appointment/></Route>
          <Route path={`${Routes.APPOINTMENTS_EDIT_GROUP}/:id`}><Appointment/></Route>
          <Route path={`${Routes.APPOINTMENTS}/overview/:id`}><MeetingOverview/></Route>
          <Route path={`${Routes.APPOINTMENTS}/search`}><Appointments/></Route>
          <Route path={`${Routes.APPOINTMENTS}/:id?`}><Appointments/></Route>
          <Route path={Routes.USER_SETTINGS}><UserSettings/></Route>

          <Route path={Routes.PRACTICE}><PracticeSettings/></Route>
          {(loggedUser.isAdmin || isCustomerCare(loggedUser)) && <Route path={`${Routes.PRACTICES}/search`}><PracticeList/></Route>}
          {(loggedUser.isAdmin || isCustomerCare(loggedUser)) && <Route path={`${Routes.PRACTICES}/:id?`}><PracticeList/></Route>}

          <Route path={Routes.SETTINGS}><Settings/></Route>
          <Route path={Routes.STATISTICS_FEEDBACK}><StatisticsFeedbackList/></Route>

          <Route path={Routes.STATISTICS}>
            {isAdminOrCustomerCare(loggedUser) ? <AdminStatistics/> : <Statistics/>}
          </Route>

          <Route path={`${Routes.CMS}/:type/edit`}><Content/></Route>
          <Route path={`${Routes.CMS}/:id`}><CMSDetails/></Route>
          <Route path={Routes.CMS}><CMSPage/></Route>
          <Route path={`${Routes.NOTIFICATION}/:type/:name/edit`}><NotificationEdit/></Route>
          <Route path={Routes.CONTACTS_NEW}><AddContact/></Route>
          <Route path={Routes.GROUPS_NEW}><AddGroup/></Route>
          <Route path={`${Routes.CONTACTS}/search`}><ContactTabs onRouteChanged={onRouteChanged}/></Route>
          <Route path={`${Routes.CONTACTS}/:id?`}><ContactTabs onRouteChanged={onRouteChanged}/></Route>
          <Route path={`${Routes.GROUPS}/search`}><ContactTabs onRouteChanged={onRouteChanged}/></Route>
          <Route path={`${Routes.GROUPS}/:id?`}><ContactTabs onRouteChanged={onRouteChanged}/></Route>
          <Route path={`${Routes.MEMBERS}/search`}><Members/></Route>
          <Route path={`${Routes.MEMBERS}/:id?`}><Members/></Route>
          <Route path={`${Routes.MEMBERS}/new`}><Members/></Route>
        </Switch>
      </main>
    </div>
  );
}
