import * as React from 'react';
import {useEffect, useState} from 'react';
import './Appointments.scss';
import Container from "../general/Container";
import {Divider, Modal, Pagination, Skeleton} from "antd";
import {useTranslation} from "react-i18next";
import {MeetingDto, SearchOptions, SearchOptionsFilterEnum, SearchOptionsSortEnum} from "../../gen/client";
import MeetingApi from "../../api/MeetingApi";
import {handleServerError} from "../../util/ErrorHandler";
import AppointmentLine from "./AppointmentLine";
import MeetingInfoDrawer from './MeetingInfoDrawer';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import AppointmentsHeader from "./AppointmentsHeader";
import {splitPastFuture} from "../../service/MeetingService";
import {SortDirection} from '../../util/enums';
import {useHistory, useParams} from 'react-router-dom';
import {isSearchRoute, Routes} from '../../util/routes';
import AppointmentSelectionBar from "./AppointmentSelectionBar";
import {DatePickerInterval} from '../general/IntervalPicker';
import MemberApi from '../../api/MemberApi';
import AppointmentDeleteCard from './AppointmentDeleteCard';
import {successMessage, timestamp} from '../../util/utils';
import {useRecoilState, useSetRecoilState} from "recoil";
import {activeParticipantIdsState, loggedMember} from "../../state/atoms";
import AppointmentOraDeleteModal from './AppointmentOraDeleteCard';

const BATCH = 25;

const DefaultOptions = {
  filter: SearchOptionsFilterEnum.Future,
  sort: SearchOptionsSortEnum.Default,
  page: 0,
  batch: BATCH
} as SearchOptions;

export default function Appointments() {
  const {t} = useTranslation();
  const {id} = useParams();
  const history = useHistory();

  const [logged] = useRecoilState(loggedMember);
  const setActiveParticipantIds = useSetRecoilState(activeParticipantIdsState);

  const [searchOptions, setSearchOptions] = useState(DefaultOptions);
  const [selected, setSelected] = useState([] as string[]);
  const [active, setActive] = useState(null as MeetingDto);
  const [list, setList] = useState([] as MeetingDto[]);
  const [pastList, setPastList] = useState([] as MeetingDto[]);
  const [loading, setLoading] = useState(false);
  const [isDetailsView, setDetailsView] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openWarningDeleteModal, setOpenWarningDeleteModal] = useState(false);
  const [notify, setNotify] = useState(true);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (selected.length === 1) {
      const meeting = (list.concat(pastList)).find(it => it.id === selected[0]);
      if (meeting) {
        MeetingApi.getMeetingActives(meeting.publicId).then(resp => setActiveParticipantIds(resp.data)).catch(err => handleServerError(err))
      }
    }
  }, [selected, list, pastList, setActiveParticipantIds]);

  useEffect(() => {
    reset();
    setLoading(true);

    if (!isSearchRoute(history.location.pathname)) {
      MeetingApi.getMemberMeetingsPages(logged.id, searchOptions).then(resp => {
        if (searchOptions.filter === SearchOptionsFilterEnum.All) {
          const {past, future} = splitPastFuture(resp.data.meetings, searchOptions.sort);
          setList(future);
          setPastList(past);
        } else {
          setList(resp.data.meetings);
          setPastList([]);
        }
        setPageCount(resp.data.pageCount);
        setLoading(false);
      }).catch(err => {
        handleServerError(err);
        setLoading(false);
      });
    } else {
      const query = new URLSearchParams(history.location.search);
      const key = query.get('key');
      MemberApi.fullSearchForProvider(logged.id, key)
        .then(resp => {
          const {past, future} = splitPastFuture(resp.data.meetings, searchOptions.sort);
          setList(future);
          setPastList(past);
          setLoading(false);
        })
        .catch(err => {
          handleServerError(err);
          setLoading(false);
        });
    }
  }, [logged.id, searchOptions, history.location.pathname, history.location.search]);

  useEffect(() => {
    if (!id) return;
    const meeting = (list.concat(pastList)).find(it => it.publicId === id);
    if (meeting) {
      setSelected([meeting.id]);
      setActive(meeting);
      setDetailsView(true);
    }
  }, [id, list, pastList]);

  function reset() {
    setSelected([]);
    setActive(null);
    setDetailsView(false);
  }

  function onIntervalChange(interval: DatePickerInterval) {
    if (interval[0] === null || interval[1] === null) {
      setSearchOptions({
        ...searchOptions,
        interval: { from: null, thru: null }
      })
    } else {
      setSearchOptions({
        ...DefaultOptions,
        filter: SearchOptionsFilterEnum.CustomDate,
        interval: {
          from: timestamp(interval[0]),
          thru: timestamp(interval[1])
        }
      })
    }
  }

  function onSelectToggle(meetingId: string) {
    let meetingIds = [];
    if (selected.indexOf(meetingId) > -1) {
      meetingIds = selected.filter(it => it !== meetingId);
      if (id && meetingId === id) {
        history.push(Routes.APPOINTMENTS);
      }
    } else {
      meetingIds = [...selected, meetingId];
    }

    if (meetingIds.length === 0) {
      setDetailsView(false);
      history.push(Routes.APPOINTMENTS);
    } else if (meetingIds.length === 1) {
      const activeId = meetingIds[0];
      let meeting = (list.concat(pastList)).find(it => it.id === activeId);
      setActive(meeting);
    } else if (meetingIds.length > 1) {
      setActive(null);
    }

    setSelected(meetingIds);
  }

  function onLineClick(meeting: MeetingDto) {
    if (selected.length === 1 && selected[0] === meeting.id) {
      setSelected([]);
      setActive(null);
      setDetailsView(false);
      history.push(Routes.APPOINTMENTS);
    } else {
      setSelected([meeting.id]);
      setActive(meeting);
      setDetailsView(true);
    }
  }

  function onToggleAll(event: CheckboxChangeEvent) {
    if (event.target.checked) {
      setSelected([...list.map(it => it.id), ...pastList.map(it => it.id)]);
      setActive(null);
    } else {
      setSelected([]);
      setDetailsView(false);
    }
  }

  function startMeeting() {
    history.push(`${Routes.MEETING}/${active.publicId}/connection`);
  }

  function onDeleteMeetings(meetingIds: string[]) {
    setList([...list.filter(it => meetingIds.indexOf(it.id) === -1)]);
    setPastList([...pastList.filter(it => meetingIds.indexOf(it.id) === -1)]);
    reset();
  }

  function onDeleteMeeting(meetingId: string) {
    setList([...list.filter(it => it.id !== meetingId)]);
    setPastList([...pastList.filter(it => it.id !== meetingId)]);
    reset();
  }

  function onDeletePastMeeting(meetingId: string) {
    setPastList([...pastList.filter(it => it.id !== meetingId)]);
  }

  function onSortChange(direction: SortDirection) {
    setSearchOptions({
      ...searchOptions,
      sort: direction === SortDirection.Asc ? SearchOptionsSortEnum.Asc : SearchOptionsSortEnum.Desc
    });
  }

  function onFilterChange(filter: SearchOptionsFilterEnum) {
    setSearchOptions({
      ...searchOptions,
      page: 0,
      sort: SearchOptionsSortEnum.Default,
      filter,
      interval: {from: null, thru: null}
    });
  }

  function goToEdit() {
    history.push((active.groups.length === 0 && active.participants.length === 1) ? `${Routes.APPOINTMENTS_EDIT_SINGLE}/${active.publicId}` : `${Routes.APPOINTMENTS_EDIT_GROUP}/${active.publicId}`);
  }

  function toggleDetailsView() {
    setDetailsView(false);
    setSelected([]);
  }

  function showDeleteConfirmation() {
    active.fromOra ? setOpenWarningDeleteModal(true) : setOpenModal(true);
  }

  function handleDelete() {
    setOpenModal(false);
    MeetingApi.deleteMeetings(notify, [active]).then(response => {
      onDeleteMeeting(response.data[0]);
      setActive(null);
      successMessage(t(notify ? 'Succesfully deleted and invitations succesfully sent' : 'Succesfully deleted!'));
    }).catch(handleServerError);
  }

  function handleCancelDelete() {
    setOpenModal(false);
  }

  function onPageChange(page: number) {
    setSearchOptions({
      ...searchOptions,
      page: page - 1
    });
  }

  return (
    <Container className={`appointments vd-list full ${isDetailsView ? 'w-det' : ''}`}>
      <AppointmentsHeader filter={searchOptions.filter} sortDirection={searchOptions.sort} onToggleAll={onToggleAll} setFilter={onFilterChange} meetings={list} pastMeetings={pastList}
                          onSortChange={onSortChange} onIntervalChange={onIntervalChange}/>
      <div className='content'>
        {loading && <Skeleton loading={true}/>}

        {!loading && <>
          {(searchOptions.filter === SearchOptionsFilterEnum.All && list.length > 0 && pastList.length > 0 && searchOptions.sort === SearchOptionsSortEnum.Asc) ? 
            <>
              {(searchOptions.filter === SearchOptionsFilterEnum.All && pastList.length > 0) && <Divider orientation="left">{t('Vergangene termine')}</Divider>}
              
              <div className={'list past last'}>
                {pastList.map(it =>
                  <AppointmentLine meeting={it} selected={selected.indexOf(it.id) > -1} key={it.id} onSelectToggle={onSelectToggle} onClick={onLineClick} onDelete={onDeletePastMeeting} past={true}/>
                )}
              </div>

              <div className={'list'}>
                {list.map(it => (
                  <AppointmentLine meeting={it} selected={selected.indexOf(it.id) > -1} key={it.id} onSelectToggle={onSelectToggle} onClick={onLineClick} onDelete={onDeleteMeeting}
                                  past={searchOptions.filter === SearchOptionsFilterEnum.Past || searchOptions.filter === SearchOptionsFilterEnum.Cancelled}/>))}
              </div>
            </>
            :
            <>
              <div className={'list'}>
                {list.map(it => (
                  <AppointmentLine meeting={it} selected={selected.indexOf(it.id) > -1} key={it.id} onSelectToggle={onSelectToggle} onClick={onLineClick} onDelete={onDeleteMeeting}
                                  past={searchOptions.filter === SearchOptionsFilterEnum.Past || searchOptions.filter === SearchOptionsFilterEnum.Cancelled}/>))}
              </div>

              {(searchOptions.filter === SearchOptionsFilterEnum.All && pastList.length > 0) && <Divider orientation="left">{t('Vergangene termine')}</Divider>}

              <div className={'list past'}>
                {pastList.map(it =>
                  <AppointmentLine meeting={it} selected={selected.indexOf(it.id) > -1} key={it.id} onSelectToggle={onSelectToggle} onClick={onLineClick} onDelete={onDeletePastMeeting} past={true}/>
                )}
              </div>
            </>
          }
        </>}
        {pageCount > 1 && <Pagination current={searchOptions.page + 1} defaultPageSize={BATCH} total={pageCount * BATCH} onChange={onPageChange} showSizeChanger={false} />}

        {selected.length > 1 && <AppointmentSelectionBar list={list} pastList={pastList} selected={selected} onSelectedChange={setSelected} onDeleteMeetings={onDeleteMeetings}/>}
      </div>

      <MeetingInfoDrawer active={active} multiSelect={selected.length > 1} open={isDetailsView} onMeetingStart={startMeeting} messages={[]} onSendFile={null}
                         onSendMessage={null} onEditMeeting={goToEdit} onToggleDetailsView={toggleDetailsView} onDeleteMeeting={showDeleteConfirmation} 
                         meeting={null} onOpenChat={() => {}}/>

      <Modal
        className='delete-card single-delete'
        visible={openModal}
        closable={false}
        onOk={(ev) => {
          ev.stopPropagation();
          handleDelete()
        }}
        okButtonProps={{className: 'modal-button', size: 'large'}}
        onCancel={(ev) => {
          ev.stopPropagation();
          handleCancelDelete()
        }}
        okText={t('Ja')}
        cancelText={t('Nein')}
        cancelButtonProps={{className: 'modal-button', size: 'large'}}>
        <AppointmentDeleteCard meeting={active} notify={notify} onNotifyChange={() => setNotify(!notify)}/>
      </Modal>

      <AppointmentOraDeleteModal meeting={active} visible={openWarningDeleteModal} onClose={() => setOpenWarningDeleteModal(false)}/>
    </Container>
  )
}
