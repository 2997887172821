import {AdminResourceApi, AppSettingsDto, GeneralStatisticsDto, LicenseCountDto, LightMemberDto, MeetingsStatisticsDto, MemberCountDto, MemberDto, NotificationDto, PracticeDto} from '../gen/client';
import {apiConfig, ops} from './ApiConfig';
import {AxiosPromise} from 'axios';

const api = new AdminResourceApi(apiConfig);

const AdminApi = {

  getGeneralStatistics(): AxiosPromise<GeneralStatisticsDto> {
    return api.getGeneralStatistics1(ops());
  },

  getMembers(filter: 'ALL' | 'ACTIVE' | 'INACTIVE'): AxiosPromise<MemberDto[]> {
    return api.getAllMembersForAdmin(filter, ops());
  },

  getPractices(): AxiosPromise<PracticeDto[]> {
    return api.getAllPractices(ops());
  },

  getPracticeNotifications(id: string): AxiosPromise<PracticeDto[]> {
    return api.getPracticeNotifications(id, ops());
  },

  resetPracticeNotification(id: string): AxiosPromise<void> {
    return api.resetPracticeNotification(id, ops());
  },

  getPractice(): AxiosPromise<PracticeDto> {
    return api.getAdminPractice(ops())
  },

  savePracticeSettings(settings: AppSettingsDto): AxiosPromise<void> {
    return api.updateAdminPracticeSettings(settings, ops())
  },

  getLightCustomers(): AxiosPromise<LightMemberDto[]> {
    return api.getAllCustomersLight(ops());
  },

  getMeetingsStatistics(from: number, thru: number): AxiosPromise<MeetingsStatisticsDto> {
    return api.getMeetingsStatistics1(from, thru, ops());
  },

  deleteMember(memberId: string): AxiosPromise<void> {
    return api.removeMember(memberId, ops())
  },

  createMember(dto: MemberDto): AxiosPromise<MemberDto> {
    return api.createMember(dto, ops())
  },

  getNotifications(): AxiosPromise<NotificationDto[]> {
    return api.getNotifications1(ops());
  },

  resetAdminNotification(notificationId: string): AxiosPromise<NotificationDto> {
    return api.resetAdminNotification(notificationId, ops());
  },

  getNotification(type: 'EMAIL' | 'SMS', name: string): AxiosPromise<NotificationDto> {
    return api.getAdminNotificationByType(type, name, ops());
  },

  updateNotification(dto: NotificationDto): AxiosPromise<NotificationDto> {
    return api.updateAdminNotification(dto, ops());
  },

  searchPractices(key: string): AxiosPromise<PracticeDto[]> {
    return api.searchPractices(key, ops());
  },

  generateDummyData(memberId: string, patients: number): AxiosPromise<void> {
    return api.generateDummyData(memberId, patients, ops())
  },

  generateLicenses(): AxiosPromise<void> {
    return api.generateLicenses(ops())
  },

  countLicenses(): AxiosPromise<LicenseCountDto> {
    return api.countLicenses(ops())
  },

  exportLicensesToCsv(): AxiosPromise<void> {
    return api.exportLicensesToCsv(ops())
  },

  countMembers(): AxiosPromise<MemberCountDto[]> {
    return api.countMembers2(ops());
  }
};

export default AdminApi;
