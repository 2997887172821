import {ReactComponent as linkIcon} from '../../../img/icons/link-icon.svg';
import * as React from "react";
import Icon from '@ant-design/icons';

interface CopyIconProps {
  className: string;
  onClick: (ev: React.MouseEvent) => void;
}

export default function CopyIcon({className, onClick}: CopyIconProps) {
  return <Icon component={linkIcon} onClick={onClick} className={className}/>
}
