import {AxiosError} from "axios";
import {errorMessage} from "./utils";
import {NetworkConnectionStatus} from "./enums";

const UNAUTHORIZED_CODE = 401;

export function handleServerError(err: AxiosError) {
  if (err.response?.status === UNAUTHORIZED_CODE) {
    return;
  }

  if (!err.response && err.message === 'Network Error') {
    errorMessage(NetworkConnectionStatus.NONE);
    return;
  }

  let msg = '';
  if (err.response) {
    msg = !!err.response ? err.response.data.message : err.response.data;
  } else {
    msg = 'Unerwarteter Fehler, bitte wiederholen Sie Ihre Aktion.'
  }

  errorMessage(msg);
}
