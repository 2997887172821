import React, {useCallback, useEffect, useState} from "react";
import {getDownloadSpeed, successMessage, warnMessage} from "../../util/utils";
import {
  DOWNLOAD_IMAGE_ADDRESS,
  INTERNET_SPEED_TEST_INTERVAL,
  INTERNET_SPEED_TEST_ITERATIONS,
  MIN_INTERNET_SPEED_VALUE
} from "../../util/constants";
import {isMeetingRoute} from "../../util/routes";
import {useHistory} from "react-router-dom";
import {NetworkConnectionStatus} from "../../util/enums";

const DOWNLOAD_SIZE = 609 * 101 * 32; // width * height * bit dept

export default function NetworkSpeed() {
  const [speeds, setSpeeds] = useState([] as number[]);
  const history = useHistory();

  const checkInitialSpeed = useCallback(() => {
    if (speeds.reduce((a, b) => a + b, 0) / speeds.length < MIN_INTERNET_SPEED_VALUE) {
      warnMessage(NetworkConnectionStatus.WEAK);
    }
  }, [speeds]);

  const showInternetDownloadSpeedTestResults = useCallback((endTime: number, startTime: number) => {
    let speedMbps: number = getDownloadSpeed(endTime, startTime, DOWNLOAD_SIZE);
    if (speeds.length < INTERNET_SPEED_TEST_ITERATIONS) {
      setSpeeds(s => [...s, speedMbps]);
      if (speeds.length === INTERNET_SPEED_TEST_ITERATIONS) {
        checkInitialSpeed()
      }
    } else if (speeds.length === INTERNET_SPEED_TEST_ITERATIONS) {
      const lastSpeed = speeds.reduce((a, b) => a + b, 0) / speeds.length;
      speeds.shift();
      setSpeeds(s => [...speeds, speedMbps]);
      const currentSpeed = speeds.reduce((a, b) => a + b, 0) / speeds.length;
      if (lastSpeed > MIN_INTERNET_SPEED_VALUE && currentSpeed < MIN_INTERNET_SPEED_VALUE) {
        warnMessage(NetworkConnectionStatus.WEAK);
      }
      if (lastSpeed < MIN_INTERNET_SPEED_VALUE && currentSpeed > MIN_INTERNET_SPEED_VALUE) {
        successMessage('Verbindung wiederhergestellt');
      }
    }
  }, [checkInitialSpeed, speeds]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isMeetingRoute(history.location.pathname)) {
        const download = new Image();
        const startTime = new Date().getTime();
        const cacheBuster = "?nnn=" + startTime;
        download.src = DOWNLOAD_IMAGE_ADDRESS + cacheBuster;
        download.id = cacheBuster;
        download.onload = () => {
          const endTime = new Date().getTime();

          showInternetDownloadSpeedTestResults(endTime, startTime);
        };
      } else {
        if (interval) {
          clearInterval(interval);
        }
      }
    }, INTERNET_SPEED_TEST_INTERVAL);

    return () => {
      clearInterval(interval);
    }
  }, [history.location.pathname, showInternetDownloadSpeedTestResults]);


  return <></>;
}
