import * as React from 'react';
import '../contact/Contacts.scss';
import {Button, Spin} from "antd";
import {useTranslation} from "react-i18next";
import {ReactComponent as trashIcon} from '../../img/icons/trash-icon.svg';
import {ReactComponent as videoIcon} from '../../img/icons/video-icon-no-fill.svg';
import { LoadingOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';

interface SelectionBarProps {
  selected: string[],
  groups: boolean,
  deleteInProgress: boolean,
  onDelete: () => void;
  onCreateNewAppointment: () => void;
}

export default function ContactSelectionBar({ selected, groups, deleteInProgress, onDelete, onCreateNewAppointment }: SelectionBarProps) {
  const {t} = useTranslation();

  function getCountMessage(): string {
    return groups ? t(' Gruppen ausgewählt') : t(' Kontakte ausgewählt');
  }

  return (
    <>
      <div className={`count-bar ${deleteInProgress ? 'del' : ''}`}>
        {deleteInProgress ?
          <div className='count-bar-content'>
            <span className='count-message'>{selected.length + getCountMessage()}&nbsp;</span>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24}} spin />}/>
          </div>
          :
          <div className='count-bar-content'>
            <span className='count-message'>{selected.length + getCountMessage()}&nbsp;</span>
            <Button type={'primary'} size="large" onClick={onDelete}><Icon component={trashIcon} alt={''} />&nbsp;{t('Kontakte Löschen')}</Button>
            <Button type={'primary'} size="large" onClick={onCreateNewAppointment} className={'video-icon'}><Icon component={videoIcon} alt={''} />&nbsp;{t('Video Termin hinzufügen')}</Button>
          </div>
        }
      </div>
    </>
  )
}
