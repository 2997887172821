/**
* This file is generated
*/
import * as React from 'react';
import { CSSProperties } from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24" xmlSpace="preserve"><path d="M11.9,3.2c-1,0-2.1,0.4-2.6,0.9C8.7,4.9,8.7,5.8,8.7,6.4c0.1,0.8,0.4,1.7,1,2.3c0.3,0.4,1,1,2,1c0,0,0.1,0,0.1,0
	c0.7,0,1.5-0.4,2.1-1.1c0.4-0.4,1.1-1.2,1.1-2.3c0-1-0.2-1.7-0.6-2.2C14,3.6,13.1,3.3,11.9,3.2 M12,13.7L12,13.7
	C11.9,13.7,11.9,13.7,12,13.7c-1.6,0-3.1,0.6-4.5,1.8c-1.1,1-1.9,2-2,2.3c-0.2,0.3-0.3,0.7-0.3,0.9c0,0.4,0.2,0.9,0.8,1.1
	c2,0.6,4,0.9,5.9,0.9c2.1,0,4.3-0.4,6.3-1.2c0.4-0.2,0.7-0.4,0.7-0.6c0-0.1,0-0.1,0-0.2l0,0v0c0-0.1,0-0.3-0.1-0.5
	c-0.1-0.2-0.7-1.3-1.8-2.3C15.4,14.5,13.8,13.7,12,13.7 M11.9,2c1.7,0,2.8,0.5,3.5,1.3c0.7,0.8,0.9,1.9,0.9,3c0,1.3-0.6,2.4-1.5,3.2
	c-0.8,0.8-1.9,1.4-3,1.4c-0.1,0-0.1,0-0.2,0c-1.2,0-2.2-0.6-2.9-1.4C8,8.7,7.6,7.6,7.5,6.5C7.4,5.3,7.6,4.2,8.3,3.4h0
	C9.1,2.5,10.4,2,11.9,2z M12,12.5c5.2,0,7.9,5.3,7.9,5.3c0.1,0.3,0.2,0.6,0.2,1c0,0.3-0.1,0.6-0.2,0.8c-0.2,0.4-0.8,0.9-1.4,1.1
	c-2.2,0.8-4.5,1.3-6.8,1.3c-2,0-4.1-0.3-6.3-1c-1-0.3-1.6-1.3-1.6-2.3c0-0.5,0.2-1,0.5-1.5c0.5-0.7,3.2-4.7,7.5-4.7
	C11.9,12.5,11.9,12.5,12,12.5z"/></svg>
);

const UserIcon = (props: { style?: CSSProperties, className?: string }) => <Icon component={svg} {...props}/>;

export default UserIcon;