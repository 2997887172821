import * as React from 'react';
import {useEffect, useState} from 'react';
import './AppointmentsHeader.scss';
import {Button, Checkbox, Dropdown, Menu} from "antd";
import {useTranslation} from "react-i18next";
import AppointmentFilters from "./AppointmentFilters";
import {MeetingDto, SearchOptionsFilterEnum, SearchOptionsSortEnum} from "../../gen/client";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import IntervalPicker, {DatePickerInterval} from '../general/IntervalPicker';
import {useRecoilState, useRecoilValue} from 'recoil';
import {appointmentsDateInterval, EMPTY_INTERVAL} from '../statistic/state/statistics.state';
import {Dimensions, SortDirection} from '../../util/enums';
import {applicationSize} from '../../state/atoms';
import {isSearchRoute, Routes} from '../../util/routes';
import {useHistory} from 'react-router';
import CloseSVG from '../general/svg/CloseSVG';
import Icon from '@ant-design/icons';
import {ReactComponent as iconPlus} from '../../img/icons/icon-plus.svg';
import MenuItem from 'antd/lib/menu/MenuItem';
import SortArrows from '../general/sort/SortArrows';


interface AppointmentsHeaderProps {
  filter: SearchOptionsFilterEnum;
  sortDirection: SearchOptionsSortEnum;
  meetings: MeetingDto[];
  pastMeetings: MeetingDto[];
  onToggleAll: (ev: CheckboxChangeEvent) => void;
  setFilter: (filter: SearchOptionsFilterEnum) => void;
  onSortChange: (direction: SortDirection) => void;
  onIntervalChange: (value: DatePickerInterval) => void;
}

export default function AppointmentsHeader({filter, meetings, pastMeetings, onToggleAll, setFilter, onSortChange, onIntervalChange, sortDirection}: AppointmentsHeaderProps) {
  const {t} = useTranslation();
  const [interval, setInterval] = useRecoilState<DatePickerInterval>(appointmentsDateInterval);
  const [hasData, setHasData] = useState(false);
  const appSize = useRecoilValue(applicationSize);
  const history = useHistory();
  const [searchString, setSearchString] = useState("");
  const [activeSearchRoute, setSearchRoute] = useState(false);

  const menu = (
    <Menu className={'apt-mr-men'}>
      <MenuItem><Button type={'link'} onClick={() => history.push(Routes.APPOINTMENTS_NEW_SINGLE)}>{t('Single')}</Button></MenuItem>
      <MenuItem><Button type={'link'} onClick={() => history.push(Routes.APPOINTMENTS_NEW_GROUP)}>{t('Group')}</Button></MenuItem>
    </Menu>
  );

  useEffect(() => {
    setHasData(meetings.length + pastMeetings.length > 0);
    if (isSearchRoute(history.location.pathname)) {
      setSearchRoute(true);
      const query = new URLSearchParams(history.location.search);
      setSearchString(query.get('key'));
    } else {
      setSearchRoute(false);
    }
  }, [meetings, pastMeetings, history.location.pathname, history.location.search]);

  useEffect(() => {
    if (filter !== SearchOptionsFilterEnum.CustomDate) {
      setInterval(EMPTY_INTERVAL);
    }
  }, [filter, setInterval]);

  function onDateIntervalChange(value: DatePickerInterval) {
    if (value !== EMPTY_INTERVAL) {
      setInterval(value);
    }
    onIntervalChange(value)
  }

  function goToMeetings() {
    history.push(Routes.APPOINTMENTS);
  }

  function getSearchTitle(): string {
    return `${t('Suche nach')}: ${searchString}`
  }

  return (
    <header>
      <Checkbox onChange={onToggleAll} disabled={!hasData}/>
      <h2 className={`${activeSearchRoute ? 'no-mg' : ''}`}>{!hasData ? '0 ' : ''}{!activeSearchRoute ? t('Termine') : getSearchTitle()}</h2>
      {activeSearchRoute &&
      <Button type={'link'} size={'large'} className='close-btn' onClick={goToMeetings}>
        <CloseSVG/>
      </Button>
      }

      {appSize === Dimensions.MOBILE &&
        <Dropdown overlay={menu} trigger={['click']} placement={'topCenter'}>
          <Button className={'add-new'} type={'primary'} size={'large'} onClick={ev => ev.stopPropagation()}>
            <Icon component={iconPlus}/>
          </Button>
        </Dropdown>
      }

      {!activeSearchRoute &&
        <div className='sort-wrapper'>
        <AppointmentFilters active={filter} onChange={setFilter} meetings={meetings} pastMeetings={pastMeetings}/>
        <IntervalPicker value={interval} onChange={onDateIntervalChange}/>
        <SortArrows sortDirection={sortDirection} onSortChange={onSortChange}/>
      </div>}
    </header>
  )
}
