import React, { useCallback, useEffect } from 'react';
import {Input, Form} from "antd";
import { isEmpty, isValidNumber } from "../../util/validators";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import './PhoneInput.scss'
import {Rule} from 'antd/lib/form';
import {DEFAULT_PHONE_PREFIX} from '../../util/constants';

const {Item} = Form;

interface PhoneInputProps {
  value?: string;
  onChange?: (value: string) => void;
  asFormItem: boolean;
  required: boolean;
  isFax?: boolean;
  isMfa?: boolean;
  customLabel?: string;
  hasError?: boolean;
  hint?: string;
  disabled?: boolean
	defaultValue?: string;
}

export default function PhoneInput({value, onChange, asFormItem, required, isFax, isMfa, customLabel, hasError, hint, disabled, defaultValue}: PhoneInputProps) {
	const isValid = useCallback((val: string)=> {
		if(required) {
			return val !== DEFAULT_PHONE_PREFIX && isValidNumber(val);
		} else {
			return val === DEFAULT_PHONE_PREFIX || isEmpty(val) || isValidNumber(val);
		}
	}, [required])

	const {t} = useTranslation();
  const [valid, setValid] = useState(!value? true : isValid(value));
  const [empty, setEmpty] = useState(!value? false : isEmpty(value));

  useEffect(() => {
		isValid(value);
	}, [isValid, value]);

  const phoneValidator = (rule: Rule, val: string) => {
    if (isValid(val)) {
      return Promise.resolve();
    }
    return isFax ? Promise.reject('Fax ist falsch') : Promise.reject('Mobilnummer ist falsch');
  };

  function onPhoneChange(newValue: string) {
    setValid(newValue === DEFAULT_PHONE_PREFIX ? true : isValid(newValue));
    setEmpty(newValue === DEFAULT_PHONE_PREFIX ? true : isEmpty(newValue));
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  }

  return (
    <>
      {asFormItem ?
        <Item label={t(customLabel ? customLabel : (isFax ? 'Fax' : 'Mobilnummer'))} name={isFax ? 'fax' : isMfa ? undefined : 'phone'} initialValue={value}
              rules={[{required, message: t('Feld ist erforderlich')}, {validator: phoneValidator}]}>
          <Input size={'large'} addonBefore={DEFAULT_PHONE_PREFIX} inputMode={'tel'} type={'tel'} disabled={disabled} value={value} defaultValue={defaultValue} onChange={ev => onPhoneChange(DEFAULT_PHONE_PREFIX + ev.target.value)}/>
        </Item>
        :
        <div className={`${isFax ? 'fax-input' : 'phone-input'} ${!valid || (hasError || (required && empty)) ? 'ant-form-item-has-error' : ''}`}>
          <label className={required ? 'req' : ''}>{t(customLabel ? customLabel : (isFax ? 'Fax' : 'Mobilnummer'))}</label>
          <Input size={'large'} addonBefore={DEFAULT_PHONE_PREFIX} className={!valid || (hasError || (required && empty)) ? 'error' : ''} inputMode={'tel'} type={'tel'} 
            disabled={disabled} onChange={ev => onPhoneChange(DEFAULT_PHONE_PREFIX + ev.target.value)}
            value={value?.startsWith(DEFAULT_PHONE_PREFIX) ? value?.replace(DEFAULT_PHONE_PREFIX, "") : value}
					 	defaultValue={defaultValue}/>
					{hint && <span>{hint}</span>}
				 	{(hasError || !valid) && <span className='err-msg'>{t(isFax ? 'Fax ist falsch' : 'Mobilnummer ist falsch')}</span>}
          {required && (hasError || empty) && <span className='err-msg'>{t('Feld ist erforderlich')}</span>}
        </div>
      }
    </>
  );
}
