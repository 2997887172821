import * as React from 'react';
import './CMSDetails.scss';
import {Collapse} from 'antd';
import ResponseLine from './ResponseLine';
import {MemberAnswerGroupDto} from '../../gen/client';

const { Panel } = Collapse;

interface MemberAnswersLinesProps {
  grouped: { [key: string]: Array<MemberAnswerGroupDto>; };
  currentKey: string;
  date: string;
}

export default function MemberAnswersLines({grouped, currentKey, date}: MemberAnswersLinesProps) {
  
  return (
    <Collapse accordion expandIconPosition='right' ghost key={currentKey}>
      <Panel key={currentKey} header={`${grouped[currentKey][0].member.publicId} - ${date}`}>
        {grouped[currentKey]?.map(feedback => {
          return (<ResponseLine answer={feedback.answer} question={feedback.question} key={feedback.answer.id}/>)
        })}
      </Panel>
    </Collapse>
  )
}