import {AxiosPromise} from "axios";
import {apiConfig, ops} from "./ApiConfig";
import {
  CmsAnswerDto,
  ParticipantDto, AccountGroupDto,
  LightParticipantDto,
  ParticipantResourceApi
} from "../gen/client";

const api = new ParticipantResourceApi(apiConfig);

const ParticipantApi = {
  deleteParticipant(participantId: string): AxiosPromise<void> {
    return api.remove(participantId, ops())
  },
  getByPublicId(participantId: string): AxiosPromise<LightParticipantDto> {
    return api.getParticipantByPublicId(participantId, ops());
  },
  getParticipant(participantId: string): AxiosPromise<ParticipantDto> {
    return api.getParticipant(participantId, ops());
  },
  saveAgreement(participantId: string): AxiosPromise<void> {
    return api.saveAgreement(participantId, ops());
  },
  addAnswers(answers: CmsAnswerDto[]): AxiosPromise<void> {
    return api.addAnswers1(answers, ops());
  },
  getParticipants(participantIds: string[]): AxiosPromise<ParticipantDto[]> {
    return api.getParticipants(participantIds, ops());
  },
  getGroups(groupIds: string[]): AxiosPromise<AccountGroupDto[]> {
    return api.getParticipantGroups(groupIds, ops());
  }
};

export default ParticipantApi;
