import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import './Meeting.scss';
import {Badge, Button, Checkbox, Tooltip} from 'antd';
import settingsIcon from "../../img/icons/cog-icon.svg";
import chatIcon from "../../img/icons/chat-icon.svg";
import checkIcon from '../../img/icons/check-icon.svg';
import {useTranslation} from 'react-i18next';
import MeetingApi from "../../api/MeetingApi";
import {MeetingDto, MeetingLogDtoTypeEnum, PublicMeetingDto} from "../../gen/client";
import {handleServerError} from "../../util/ErrorHandler";
import Storage from '../../util/Storage';
import ShareScreenSVG from '../general/svg/ShareScreenSVG';
import {displayTime, fetchMeetingParticipants, getHours, getMinutes, getParticipantActiveStatus, isDesktop, timestamp} from '../../util/utils';
import moment from "moment";
import {InternalMessageType, MeetingStatus} from '../../util/types';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {
  applicationSize,
  audioMutedState,
  cameraBrokenState,
  cameraOffState,
  loggedMember,
  meetingInfoState,
  meetingOwnerState,
  presentationModeState,
  shareScreenState, userSelectedCameraOffState,
  userSelectedPresentationModeState
} from '../../state/atoms';
import MemberApi from '../../api/MemberApi';
import {Dimensions} from '../../util/enums';
import Icon from '@ant-design/icons';
import {ReactComponent as microphoneIcon} from '../../img/icons/microphone-icon.svg';
import {ReactComponent as microphoneIconMuted} from '../../img/icons/microphone-mute-icon.svg';
import {sendInternalMessage} from "../../service/RtcService";
import {initDefaultVideoSubject, updateLocalStreamTracksSubject} from "../../state/subjects";
import {getShareScreenStream} from "../../service/DeviceService";
import VideoSVG from "../general/svg/VideoSVG";
import XButtonSVG from "../general/svg/XButtonSVG";

interface MeetingFooterProps {
  id: string;
  meeting: MeetingDto | PublicMeetingDto;
  isProvider?: boolean;
  toggleDetailsView: () => void;
  toggleSettingsView: () => void;
  onEndMeeting: () => void;
  onStartMeeting: () => void;
  newMessagesCount: number;
  preview?: boolean;
  participantPreview: boolean;
  onParticipantCancel: () => void;
}

export default function MeetingFooter({
                                        id,
                                        isProvider,
                                        toggleDetailsView,
                                        toggleSettingsView,
                                        meeting,
                                        onEndMeeting,
                                        onStartMeeting,
                                        newMessagesCount,
                                        preview,
                                        participantPreview,
                                        onParticipantCancel
                                      }: MeetingFooterProps
) {
  const {t} = useTranslation();
  const [logged, setLogged] = useRecoilState(loggedMember);
  const [minutes, setMinutes] = useState(0);
  const [isPC] = useState(isDesktop());
  const [skipPreview, setSkipPreview] = useState(false);
  const appSize = useRecoilValue<Dimensions>(applicationSize);
  const [isMeetingOwner] = useRecoilState(meetingOwnerState);
  const [isAudioMuted, setAudioMuted] = useRecoilState(audioMutedState);
  const [isCameraOff, setCameraOff] = useRecoilState(cameraOffState);
  const [isUserSelectedCameraOff, setUserSelectedCameraOff] = useRecoilState(userSelectedCameraOffState);
  const isCameraBroken = useRecoilValue(cameraBrokenState);
  const [shareScreen, setShareScreen] = useRecoilState(shareScreenState);
  const setPresentationMode = useSetRecoilState(presentationModeState);
  const userSelectedPresentationMode = useRecoilValue(userSelectedPresentationModeState);
  const meetingInfo = useRecoilValue(meetingInfoState);

  const tick = useCallback(() => {
    if (meetingInfo.started) {
      const durationSinceStarted = moment.duration(moment().diff(moment.unix(meetingInfo.started / 1000)));
      setMinutes(durationSinceStarted.asMinutes());
    }
  }, [meetingInfo.started]);

  function toggleMicrophone() {
    setAudioMuted(!isAudioMuted);
  }

  function toggleCamera() {
    setCameraOff(!isCameraOff);
    setUserSelectedCameraOff(!isCameraOff);
  }

  useEffect(() => {
    let timerID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerID);
    };
  }, [tick]);

  function startMeeting() {
    if (meetingInfo.status === MeetingStatus.Simulate) return;

    checkSkipPreviewOption();
    MeetingApi.createMeetingLog({createdDate: timestamp(moment()), type: MeetingLogDtoTypeEnum.MeetingStarted}, id)
      .then()
      .catch(err => handleServerError(err));

    onStartMeeting();
  }

  function endMeeting() {
    Storage.removeMessages();

    if (isMeetingOwner) {
      createMeetingEndedLog();
      updateCallDuration();
    }

    onEndMeeting();
  }

  function createMeetingEndedLog() {
    MeetingApi.createMeetingLog({createdDate: timestamp(moment()), type: MeetingLogDtoTypeEnum.MeetingEnded}, id)
      .then(() => {
      })
      .catch(err => handleServerError(err));
  }

  function updateCallDuration() {
    const minutesSinceStarted = moment.duration(moment().diff(moment.unix(meetingInfo.started / 1000))).asMinutes();
    MeetingApi.updateCallDuration(id, minutesSinceStarted)
      .then(() => {
      })
      .catch(err => handleServerError(err));
  }

  function checkSkipPreviewOption() {
    if (logged.skipPreview !== skipPreview) {
      setLogged({...logged, skipPreview: skipPreview});
      MemberApi.updateSkipPreviewOption(logged.id, skipPreview)
        .then(() => {
        })
        .catch(handleServerError);
    }
  }

  function onShareScreen() {
    const onShareScreenStart = (stream: MediaStream) => {
      setShareScreen(true);
      setPresentationMode(true);
      sendInternalMessage({type: InternalMessageType.SWITCH_TO_SHARE_SCREEN});
      setCameraOff(false);

      updateLocalStreamTracksSubject.next(stream);

      stream.getTracks().forEach(s => s.addEventListener('ended', () => {
        onShareScreenStop();
      }));
    }

    const onShareScreenStop = () => {
      setShareScreen(false);
      setPresentationMode(userSelectedPresentationMode);
      sendInternalMessage({type: InternalMessageType.SWITCH_TO_DEVICE});
      setCameraOff(isUserSelectedCameraOff);

      initDefaultVideoSubject.next(new Date().getTime());
    };


    if (shareScreen) {
      onShareScreenStop();
    } else {
      getShareScreenStream(stream => {
        onShareScreenStart(stream);
      });
    }
  }

  return (
    <footer className={`btm ${!preview ? 'live' : ''}`}>
      <Tooltip placement="top" title={t('Einstellungen')}>
        <Button type={'link'} size={'large'} className='settings-button' onClick={toggleSettingsView}>
          <img src={settingsIcon} alt={''}/>
        </Button>
      </Tooltip>

      {appSize !== Dimensions.MOBILE && <div className="left-message-wrapper">
        {preview && isProvider && meeting ? fetchMeetingParticipants(meeting).map(p => <span id={p.id} key={p.id}
                                                                                             className="left-message">{p.name} {getParticipantActiveStatus(meetingInfo, p.publicId) ? t('ist online') : t('ist noch nicht online')}</span>) :
          <div/>
        }
      </div>}

      <Tooltip placement="top" title={isAudioMuted ? t('Schalten Sie das Mikrofon ein') : t('Schalten Sie das Mikrofon aus')}>
        <Button type={'link'} size={'large'} className={`microphone-button ${!isAudioMuted ? 'active' : ''}`} onClick={toggleMicrophone}>
          {!isAudioMuted && <Icon component={microphoneIcon}/>}
          {isAudioMuted && <Icon component={microphoneIconMuted}/>}
        </Button>
      </Tooltip>

      {meetingInfo.status === MeetingStatus.WarmUp && isProvider && meeting &&
      <Tooltip placement="top" title={t('Bist du bereit zu starten?')} visible={true}
               getPopupContainer={() => document.getElementById('tooltip-container')}>
          <Button type={'link'} size={'large'} className="meeting-btn" onClick={startMeeting} id='tooltip-container'>
              <img src={checkIcon} alt={''}/>
          </Button>
      </Tooltip>}

      {participantPreview && meetingInfo.status !== MeetingStatus.Progress ?
        <Tooltip placement="top" title={t('Abbrechen')}>
          <Button type={'link'} size={'large'} className="meeting-btn end" onClick={onParticipantCancel}>
            <XButtonSVG/>
          </Button>
        </Tooltip>
        :
        ((!isProvider && meetingInfo.status !== MeetingStatus.Progress) || (isProvider && meetingInfo.status === MeetingStatus.Simulate))
        && <div/>
      }

      {meetingInfo.status === MeetingStatus.Progress &&
      <Tooltip placement="top" title={t('Termine Beenden')}>
          <Button type={'link'} size={'large'} className="meeting-btn end" onClick={endMeeting}>
              <XButtonSVG/>
          </Button>
      </Tooltip>}

      <Tooltip placement="top" title={(isCameraOff || isCameraBroken) ? t('Schalten Sie das Kamera ein') : t('Schalten Sie das Kamera aus')}>
        <Button type={'link'} size={'large'} className={`camera-button ${!isCameraOff && !isCameraBroken ? 'active' : ''}`} disabled={shareScreen} onClick={toggleCamera}>
          <VideoSVG/>
        </Button>
      </Tooltip>

      {appSize !== Dimensions.MOBILE &&
      <div className={`right-message-wrapper ${isPC ? 'desktop' : ''}`}>
          <div className="right-message">
            {preview && isProvider &&
            <div className="no-preview">
                <Checkbox defaultChecked={logged.skipPreview || false} onChange={ev => setSkipPreview(ev.target.checked)}/>
                <span>{t("Vorschau beim nächste Mal überspringen")}</span>
            </div>
            }

            {!preview && meetingInfo.status !== MeetingStatus.Simulate &&
            <div className='duration'>
              {meetingInfo.status === MeetingStatus.Progress &&
              <h3 className='temp'>
                {moment.utc().startOf('day').add(minutes, 'minutes').format('HH:mm')} / {displayTime(getHours(meeting?.duration), getMinutes(meeting?.duration))}
              </h3>}
            </div>}
          </div>
      </div>}

      {appSize === Dimensions.MOBILE && <div className={'messages'}>
          <div className="left-message-wrapper">
            {preview && isProvider && meeting ? fetchMeetingParticipants(meeting).map(p => <span id={p.id} key={p.id}
                                                                                                 className="left-message">{p.name} {getParticipantActiveStatus(meetingInfo, p.publicId) ? t('ist online') : t('ist noch nicht online')}</span>) :
              <div/>
            }
          </div>
          <div className="right-message-wrapper">
              <div className="right-message">
                {preview && isProvider &&
                <div className="no-preview">
                    <Checkbox defaultChecked={logged.skipPreview || false} onChange={ev => setSkipPreview(ev.target.checked)}/>
                    <span>{t("Vorschau beim nächste Mal überspringen")}</span>
                </div>
                }

                {!preview && meetingInfo.status !== MeetingStatus.Simulate &&
                <div className='duration'>
                  {meetingInfo.status === MeetingStatus.Progress &&
                  <h3 className='temp'>
                    {moment.utc().startOf('day').add(minutes, 'minutes').format('HH:mm')} / {displayTime(getHours(meeting?.duration), getMinutes(meeting?.duration))}
                  </h3>}
                </div>}
              </div>
          </div>
      </div>}

      {isPC &&
      <div className='share-button right'>
          <Tooltip placement="top" title={t('Bildschirm teilen')}>
              <Button type={'link'} size={'large'} className={`details-button ${shareScreen ? 'active' : ''}`} onClick={onShareScreen}>
                  <ShareScreenSVG/>
              </Button>
          </Tooltip>
      </div>
      }

      <div className='chat-button right'>
        <Badge count={newMessagesCount}>
          <Tooltip placement="top" title={t('Chat')}>
            <Button type={'link'} size={'large'} className='details-button' onClick={toggleDetailsView}>
              <img src={chatIcon} alt={''}/>
            </Button>
          </Tooltip>
        </Badge>
      </div>

    </footer>
  )
}
