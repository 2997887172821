import * as React from 'react';
import './ResponseLine.scss';
import { Rate } from 'antd';
import { CmsAnswerDto, LightCmsQuestionDto, LightCmsQuestionDtoTypeEnum } from '../../gen/client';

interface ResponseLineProps {
 answer: CmsAnswerDto,
 question: LightCmsQuestionDto
}

export default function ResponseLine({answer, question}: ResponseLineProps) {

  return (
    <div className='vd-line'>
      <div className='col-1'>
        {question?.label}
      </div>
      <div className='col-2'>
        {(question?.type === LightCmsQuestionDtoTypeEnum.Paragraph || question?.type === LightCmsQuestionDtoTypeEnum.ShortAnswer) &&
           <div>{answer.answer}</div>}
        {question?.type === LightCmsQuestionDtoTypeEnum.Rating &&
           <div><Rate defaultValue={parseInt(answer.answer)} disabled/></div>}
      </div>
    </div>
  )
}
