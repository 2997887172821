import * as React from 'react';
import './MemberLine.scss';
import {Button, Checkbox, Switch} from 'antd';
import {ReactComponent as editIcon} from '../../img/icons/edit-icon.svg';
import {ReactComponent as checkIcon} from '../../img/icons/check-icon.svg';
import {MemberDto, MemberDtoPermissionsEnum, MemberDtoRolesEnum, MemberDtoStateEnum} from "../../gen/client";
import {formatDate, fromTimestamp, getHighestRole} from '../../util/utils';
import {useTranslation} from 'react-i18next';
import {ReactComponent as deleteIcon} from '../../img/icons/trash-icon.svg';
import RoleLabel from '../general/RoleLabel';
import Icon from '@ant-design/icons';

interface MemberLineProps {
  member: MemberDto;
  selected: boolean;
  shortView: boolean;
  onSelectToggle: (id: string) => void;
  onClick: (id: string) => void;
  onDelete: (event: React.MouseEvent<HTMLElement, MouseEvent>, member: MemberDto) => void;
}

export default function MemberLine({member, selected, shortView, onSelectToggle, onClick, onDelete}: MemberLineProps) {
  const {t} = useTranslation();

  return shortView ? (
      <div className={`vd-line short-ad ${selected ? 'active' : ''}`} onClick={() => onClick(member.id)}>

        <Checkbox checked={selected} onChange={() => onSelectToggle(member.id)}/>
        <span className='name'>{member.lastName && member.firstName ? member.lastName + ', ' + member.firstName: member.lastName + member.firstName}</span>
        {member.roles.length > 0 &&
        <RoleLabel role={getHighestRole(member.roles)}/>}
        <div className='short-btns'>
          <Button type={'link'} className='icon-only'><Icon component={editIcon}/></Button>
          <Button type={'link'} className={`icon-only ${member.roles.includes(MemberDtoRolesEnum.Admin) ? 'invisible' :''}`} onClick={(ev) => onDelete(ev, member)}><Icon component={deleteIcon}/></Button>
        </div>
      </div>
    ) :
    (<div className={`vd-line ${selected ? 'active' : ''} ${member.roles.length === 0 ? 'no-role' : ''}`} onClick={() => onClick(member.id)}>
        <div className='col name-col'>
          <Checkbox checked={selected} onChange={() => onSelectToggle(member.id)}/>
          <span className='name'>{member.lastName && member.firstName ? member.lastName + ', ' + member.firstName: member.lastName + member.firstName}</span>
          <span className={'all-roles'}>{member.roles.map(it => <RoleLabel key={it} role={it}/>)}</span>
          <span className={'highest-role'}><RoleLabel role={getHighestRole(member.roles)}/></span>
        </div>
        <div className='col create-date'>
          {formatDate(fromTimestamp(member.createdDate))}
        </div>
        <div className='col email-col'>{member.email}</div>
        <div className='col hidden-lt-lg permissions'>
          {Object.values(MemberDtoPermissionsEnum).map((it, idx) =>
            member.permissions.indexOf(it) > -1 ?
              <div className='permission' key={idx}>
                <Icon component={checkIcon}/> {it}
              </div>
              : ""
          )}
        </div>
        <div className='col akt'>
          <div className='label'>
            <Switch checked={member.state === MemberDtoStateEnum.Active} disabled/>
            <span>{t('Aktiv')}</span>
          </div>
        </div>
        <div className='actions'>
          <Button type={'link'} className='icon-only'><Icon component={editIcon}/></Button>
          <Button type={'link'} className={`icon-only ${member.roles.includes(MemberDtoRolesEnum.Admin) ? 'invisible' :''}`} onClick={(ev) => onDelete(ev, member)}><Icon component={deleteIcon}/></Button>
        </div>
      </div>
    )
}
