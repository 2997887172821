import {
  UpdatePasswordDto,
  MfaFieldsDto,
  ProviderSearchResultDto,
  AccountGroupDto,
  AccountDto,
  MemberPasswordDto
} from '../gen/client';
import {AxiosPromise} from "axios";
import {apiConfig, ops} from "./ApiConfig";
import {MemberResourceApi, MemberStatusDto, MemberDto} from "../gen/client";

const api = new MemberResourceApi(apiConfig);

const MemberApi = {
  getStatus(): AxiosPromise<MemberStatusDto> {
    return api.getStatus(ops());
  },

  getMember(memberId: string): AxiosPromise<MemberDto> {
    return api.get(memberId, ops());
  },

  saveMember(memberId: string, member: MemberDto): AxiosPromise<void> {
    return api.update1(memberId, member, ops())
  },

  triggerPasswordReset(memberId: string): AxiosPromise<void>  {
    return api.triggerPasswordReset(memberId, ops())
  },

  sendResetMfaPhoneCode(memberId: string, dto: MfaFieldsDto): AxiosPromise<void>  {
    return api.sendResetMfaPhoneCode(memberId, dto, ops())
  },

  fullSearchForProvider(memberId: string, key: string): AxiosPromise<ProviderSearchResultDto> {
    return api.fullSearchForProvider(memberId, key, ops());
  },

  searchForProvider(memberId: string, key: string): AxiosPromise<AccountDto[]> {
    return api.searchForProvider(memberId, key, ops());
  },

  searchForManager(memberId: string, key: string) {
    return api.searchForManager(memberId, key, ops());
  },

  printMemberDeletion(memberId: string, deletedId: string): AxiosPromise<void> {
    return api.getMemberDeletionPDF(memberId, deletedId, {...ops(), responseType: 'blob'})
  },

  forgetDevices(memberId: string) {
    return api.forgetDevices(memberId, ops());
  },

  updatePassword(memberId: string, dto: UpdatePasswordDto) {
    return api.updatePassword(memberId, dto, ops());
  },

  getMfaFields(memberId: string) {
    return api.getMfaFields(memberId, ops());
  },

  updateMfaFields(memberId: string, dto: MfaFieldsDto) {
    return api.updateMfaFields(memberId, dto, ops());
  },

  updateSkipPreviewOption(memberId: string, option: boolean): AxiosPromise<void> {
    return api.updateSkipPreviewOption(memberId, option, ops());
  },

  confirmPassword(memberId: string, password: MemberPasswordDto): AxiosPromise<boolean> {
    return api.confirmPassword(memberId, password, ops());
  },

	uploadAvatar(memberId: string, memberDto: MemberDto): AxiosPromise<void> {
  	return api.uploadAvatar(memberId, memberDto, ops());
	},

	deleteAvatar(memberId: string): AxiosPromise<void> {
		return api.deleteAvatar(memberId, ops());
	},

  searchGroupsForProvider(memberId: string, key: string): AxiosPromise<AccountGroupDto[]> {
    return api.searchGroupsForProvider(memberId, key, ops());
  },

  hideLoginHint(memberId: string): AxiosPromise<void> {
		return api.hideLoginHint(memberId, ops());
	}
};

export default MemberApi;
