import * as React from 'react';
import {useEffect, useState} from 'react';
import './CMSPage.scss';
import {useTranslation} from 'react-i18next';
import CMSLine from './CMSLine';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../util/routes';
import Storage from "../../util/Storage";
import CmsApi from '../../api/CmsApi';
import {CmsDto, CmsDtoAccessEnum} from '../../gen/client';
import {handleServerError} from '../../util/ErrorHandler';
import {useRecoilState} from 'recoil';
import {activePractice, loggedMember} from '../../state/atoms';
import TenantApi from '../../api/TenantApi';
import {TENANT_ID} from '../../util/constants';
import {downloadFile, downloadFileUsingBlob, isAdmin, isCustomerCare} from '../../util/utils';
import { AxiosPromise } from 'axios';
import PracticeApi from '../../api/PracticeApi';

export default function QuestionList() {
  const {t} = useTranslation();
  const history = useHistory();

  const [logged] = useRecoilState(loggedMember);
  const [practice] = useRecoilState(activePractice);

  const [questions, setQuestions] = useState([] as CmsDto[]);
  const [registerQuestions, setRegisterQuestions] = useState([] as CmsDto[]);
  const [isAdminOrCustomerCare, setAdminOrCustomerCare] = useState(false);

  useEffect(() => {
    CmsApi.getAvailableCmsTypes(Storage.getMemberId()).then(resp => {
      setQuestions(resp.data.filter(it => it.name !== "After register questions"));
    }).catch(handleServerError);

    const adminOrCare = isAdmin(logged) || isCustomerCare(logged);
    setAdminOrCustomerCare(adminOrCare);
    if (adminOrCare) {
      CmsApi.getAvailableCmsTypes(Storage.getMemberId()).then(resp => {
        setRegisterQuestions(resp.data.filter(it => it.name === "After register questions"));
      }).catch(handleServerError);
    }
  }, [logged]);


  function onClick(id: number) {
    history.push(`${Routes.CMS}/${id}`)
  }

  function printQuestionsWithAnswers(cms: CmsDto) {
    TenantApi.generateQuestionsAndAnswersOverview(TENANT_ID, cms.id.toString())
      .then(response => {
        downloadFileUsingBlob(response.request.response, `${t(cms.name)}.pdf`)
      }).catch(handleServerError);
  }

  function exportCms(cms: CmsDto){
    const action: AxiosPromise = isAdminOrCustomerCare ? TenantApi.exportCmsToCSV(TENANT_ID, cms.id) : PracticeApi.exportCmsToCSV(practice.id, cms.id);
    action.then(response => {
      downloadFile(response.request.responseText, 'answers.csv')
    }).catch(handleServerError);
  }

  return (
    <div className={'contact-list cms-question-list'}>
      {questions.map(it => <CMSLine cms={it} onClick={onClick} key={it.id} 
      onPrint={() => printQuestionsWithAnswers(it)} access={CmsDtoAccessEnum.Public} onExport={() => exportCms(it)}/>)}
      {isAdminOrCustomerCare && registerQuestions
        .filter(it => it.name === "After register questions")
        .map(it => <CMSLine cms={it} onClick={onClick} key={it.id} onPrint={() => printQuestionsWithAnswers(it)} onExport={() => exportCms(it)}/>)
      }
    </div>
  )
}
