import * as React from 'react';
import {useEffect, useState} from 'react';
import {Prompt, useHistory} from 'react-router-dom';
import {Location} from 'history';
import useExitPrompt from '../../util/hooks';
import LeaveMeetingModal from './LeaveMeetingModal';

interface RouteLeaveGuardProps {
  enabled: boolean;
  message: string;
  okText?: string;
  cancelText?: string;
  onConfirm: () => void;
}

export default function RouteLeaveGuard({enabled, message, okText, cancelText, onConfirm}: RouteLeaveGuardProps) {
  const history = useHistory();

  const [modalVisible, setModalVisible] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [, setShowExitPrompt] = useExitPrompt(enabled);

  useEffect(() => {
    setShowExitPrompt(enabled);
  }, [enabled, setShowExitPrompt]);

  useEffect(() => {
    return () => {
      setShowExitPrompt(false)
    }
  }, [setShowExitPrompt]);

  function handleBlockedNavigation(nextLocation: Location<unknown>): boolean {
    if (!confirmedNavigation && enabled) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  }

  function handleConfirmNavigation() {
    setModalVisible(false);
    setConfirmedNavigation(true);
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      onConfirm();
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history, onConfirm]);

  return (
    <>
      <Prompt
        when={enabled}
        message={handleBlockedNavigation}
      />

      <LeaveMeetingModal message={message} visible={modalVisible} okText={okText} cancelText={cancelText} onCancel={() => setModalVisible(false)} onOk={handleConfirmNavigation}/>
    </>);
}
