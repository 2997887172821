import * as React from 'react';
import '../contact/Contacts.scss';
import {Card, Checkbox, Button} from 'antd';
import {ReactComponent as cameraIcon} from '../../img/icons/video-camera-outline-icon.svg';
import {ReactComponent as videoIcon} from '../../img/icons/video-outline-icon.svg';
import {ReactComponent as editIcon} from '../../img/icons/edit-icon.svg';
import {ReactComponent as deleteIcon} from '../../img/icons/trash-icon.svg';
import {AccountGroupDto} from "../../gen/client";
import Icon from '@ant-design/icons';
import {ReactComponent as userIcon} from '../../img/icons/user-icon.svg';

interface ReferenceProp {
  [key: string]: any;
}

interface GroupCardProps {
  group: AccountGroupDto;
  selected: string[];
  references: ReferenceProp[];
  onSelectToggle: (id: string) => void;
  onVideoIconClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, group: AccountGroupDto) => void;
  onEditIconClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, group: AccountGroupDto) => void;
  onDeleteIconClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, group: AccountGroupDto) => void;
}

export default function GroupCard({group, selected, references, onSelectToggle, onVideoIconClick, onEditIconClick, onDeleteIconClick}: GroupCardProps) {

  return (
    <Card.Grid className={`gridStyle groups ${selected.indexOf(group.id) > -1 ? 'active' : ''}`} hoverable={false}>
      <div className={`row`} ref={(references as ReferenceProp)[group.id]}>
        <Checkbox className='checkbox' checked={selected.indexOf(group.id) > -1} onChange={() => onSelectToggle(group.id)}/>
        <div className='contact-name'>{group.name}</div>
        <div className='phone-camera-wrapper'>
          <div className='col-1 phone'><Icon className="icon-only" component={userIcon} alt={''}/><span>{group.accounts.length}</span></div>
          <div className='col-1 camera'><Icon className="icon-only" component={cameraIcon} alt={''}/><span>{group.accounts.length}</span></div>
        </div>

        <div className="actions">
          <Button type={'link'} className="icon-only" onClick={(e) => onVideoIconClick(e, group)} disabled={selected.length > 1}><Icon component={videoIcon} alt={'video'}/></Button>
          <Button type={'link'} className="icon-only" onClick={(e) => onEditIconClick(e, group)} disabled={selected.length > 1}><Icon component={editIcon} alt={'edit'}/></Button>
          <Button type={'link'} className="icon-only" onClick={(e) => onDeleteIconClick(e, group)}><Icon component={deleteIcon} alt={'delete'}/></Button>
        </div>
      </div>
    </Card.Grid>
  )
}
