import * as React from 'react';
import './Appointments.scss';
import { useTranslation } from "react-i18next";
import { MeetingDto } from "../../gen/client";
import { formatDate, formatTime, fromTimestamp } from '../../util/utils';
import Icon from '@ant-design/icons';
import {ReactComponent as userIcon}  from '../../img/icons/user-icon.svg';
import { Modal } from 'antd';

interface AppointmentOraDeleteModalProps {
  meeting: MeetingDto;
  visible: boolean;
  onClose: () => void;
}

export default function AppointmentOraDeleteModal({meeting, visible, onClose}: AppointmentOraDeleteModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      className='delete-card single-delete'
      visible={visible}
      closable={false}
      onOk={(ev) => {
        ev.stopPropagation();
        onClose();
      }}
      okText={t('Ok')}
      okButtonProps={{className: 'modal-button', size: 'large'}}
      cancelButtonProps={{style: {display: 'none'}}}>
      <div className='all-ntf'>
        <h3 className='title'>{t('Stornierung Termin')}</h3>
        <div className={'cards'}>
          <div className='gridStyle' key={meeting?.id}>
            <div className={'dtm'}>{formatDate(fromTimestamp(meeting?.startDate))} {formatTime(fromTimestamp(meeting?.startDate))}</div>
            <div>{
              meeting?.participants.map(it => (
                <div key={it.id}>
                  <Icon component={userIcon} alt={''} />&nbsp;<span>{it.name}&nbsp;</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>{t('Der Termin wurde in Noventi ORA erstellt und kann nur dort storniert werden.')}</div>
      </div>
    </Modal>
  )
}
