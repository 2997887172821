import * as React from 'react';

export function StatsIcon() {
  return (
    <svg viewBox="0 0 24 24">
      <g transform="translate(-26 -107)">
        <g transform="translate(26 107)">
          <rect x="2" y="15.9" width="2.4" height="6.1"/>
          <rect x="10.8" y="12.8" width="2.4" height="9.2"/>
          <rect x="6.4" y="17.5" width="2.4" height="4.5"/>
          <rect x="19.6" y="12.8" width="2.4" height="9.2"/>
          <rect x="15.2" y="10.6" width="2.4" height="11.4"/>
          <path d="M7,7.1V2C4.1,2.2,1.9,4.7,2,7.6c0.1,1.2,0.5,2.3,1.3,3.2L7,7.1z"/>
          <path d="M12.5,6.9c-0.1-1.2-0.5-2.3-1.3-3.2L8,6.9H12.5z"/>
          <path d="M7.6,2v4.5l3.2-3.2C9.9,2.5,8.8,2.1,7.6,2z"/>
          <path d="M12.5,7.6H7.4l-3.6,3.6c2.2,1.9,5.5,1.8,7.4-0.4C12,9.9,12.5,8.8,12.5,7.6z"/>
        </g>
      </g>
    </svg>
  )
}
