import { AuthResponseDto, MemberDto, MemberDtoPermissionsEnum, MemberDtoRolesEnum, MemberStatusDto } from "../gen/client";
import Storage, {STORAGE_ROLE} from "../util/Storage";
import {hasAdminRole, hasCustomerCareRole, hasPracticeManagerRole} from "../util/utils";
import { UNLOGGED_MEMBER } from "./atoms";

export interface MemberAtom {
  id: string;
  name: string;
  email: string;
  avatar: string;
  roles: MemberDtoRolesEnum[],
  activeRole: MemberDtoRolesEnum,
  isAdmin: boolean,
  permissions: MemberDtoPermissionsEnum[]
  skipPreview: boolean,
  showLoginHint: boolean
}

export interface LoggedAtom extends MemberAtom{
  logged: boolean;
  millisTillTokenExpire: number;
}

export function getLoggedAtom(resp: AuthResponseDto): LoggedAtom {
  return {
    logged: true,
    millisTillTokenExpire: -1,
    ...getMemberPartial(resp.member)
  }
}

export function getLoggedAtomFromStatus(resp: MemberStatusDto): LoggedAtom {
  return {
    logged: resp.authenticated,
    millisTillTokenExpire: resp.millisTillExpire,
    ...getMemberPartial(resp.member)
  }
}

export function getUnloggedAtom(): LoggedAtom {
  return UNLOGGED_MEMBER;
}

function getActiveRole(member: MemberDto): MemberDtoRolesEnum {
  if (hasAdminRole(member.roles)) return MemberDtoRolesEnum.Admin;
  if (hasCustomerCareRole(member.roles)) return MemberDtoRolesEnum.CustomerCare;
  if (member.needsInitialSetup) {
    if (hasPracticeManagerRole(member.roles)) {
      Storage.setValue(STORAGE_ROLE, MemberDtoRolesEnum.PracticeManager);
      return MemberDtoRolesEnum.PracticeManager;
    }
  }
  let existing = Storage.getValue(STORAGE_ROLE) || member.roles[0];
  if (!member.roles.find(it => it === existing)) {
    existing = member.roles[0];
  }

  return existing;
}

function getMemberPartial(member: MemberDto): { permissions: Array<MemberDtoPermissionsEnum>; roles: Array<MemberDtoRolesEnum>; name: string; needsInitialSetup: boolean; id: string; avatar: string; isAdmin: boolean; skipPreview: boolean; email: string; activeRole: MemberDtoRolesEnum; showLoginHint: boolean } {
  return {
    id: member.id,
    name: `${member.firstName} ${member.lastName}`,
    email: member.email,
    avatar: member.avatar,
    roles: member.roles,
    activeRole: getActiveRole(member),
    isAdmin : member.roles.length && !!member.roles.find(it => it === MemberDtoRolesEnum.Admin),
    permissions: member.permissions,
    skipPreview: member.settings?.skipPreview || false,
		needsInitialSetup: member.needsInitialSetup,
    showLoginHint: member.showLoginHint || false
  }
}
