import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Form, Input, message, Modal, Select, Skeleton, Switch} from "antd";
import {MemberDto, MemberDtoRolesEnum, MemberDtoStateEnum} from '../../gen/client';
import {activePractice, loggedMember} from '../../state/atoms';
import {useRecoilState} from 'recoil';
import moment from 'moment';
import EmailInput from '../general/EmailInput';
import PhoneInput from '../general/PhoneInput';
import MemberApi from "../../api/MemberApi";
import {handleServerError} from "../../util/ErrorHandler";
import {getEmptyMember, getSubRoles, patchMember} from "../../service/MemberService";
import './UserGeneralSettings.scss';
import {arrayBufferToBase64, formatDate, fromTimestamp, hasPracticeManagerRole, hasProviderRole, isCustomerCare, removePhonePrefix, timestamp, formatTime} from '../../util/utils';
import {Store} from "rc-field-form/lib/interface";
import PracticeApi from "../../api/PracticeApi";
import {useRecoilValue} from "recoil";
import Password from "antd/lib/input/Password";
import AdminApi from "../../api/AdminApi";
import {AxiosError} from "axios";
import Icon from '@ant-design/icons';
import {ReactComponent as backIcon} from '../../img/icons/back-icon.svg';
import {passwordValidator} from '../../util/validators';
import AvatarSettings from './AvatarSettings';
import {env} from '../../env/env';

const {Item} = Form;

interface MemberSettingsProps {
  memberId: string;
  isAdmin: boolean;
  isSelf: boolean;
  onSaveSuccess?: (member: MemberDto) => void;
  onCancel: () => void;
  onMemberStateChange: () => void;
}

export default function UserGeneralSettings({memberId, isAdmin, isSelf, onCancel, onSaveSuccess, onMemberStateChange}: MemberSettingsProps) {
  const {t} = useTranslation();
  const [logged] = useRecoilState(loggedMember);
  const practice = useRecoilValue(activePractice);

  const [member, setMember] = useState(null as MemberDto);
  const [roles, setRoles] = useState([] as MemberDtoRolesEnum[]);

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [canBeProvider, setCanBeProvider] = useState(false);
  const [isAlsoProvider, setIsAlsoProvider] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [hasMemberStatusChanged, setMemberStatusChanged] = useState(false);

  const [dummyDataModal, setDummyDataModal] = useState(false);
  const [dummyDataEntries, setDummyDataEntries] = useState(50);
  const [dummyLoading, setDummyLoading] = useState(false);

  useEffect(() => {
    if (memberId === null) {
      setMember(getEmptyMember(logged.roles));
      setLoading(false);
      setIsNew(true);
      return;
    }

    setLoading(true);
    MemberApi.getMember(memberId).then(resp => {
      setMember({
        ...resp.data,
        addresses: resp.data.addresses.length > 0 ? resp.data.addresses : [{}],
        settings: resp.data.settings || {},
        roles: resp.data.roles || []
      });
      setCanBeProvider(hasPracticeManagerRole(resp.data.roles));
      setIsAlsoProvider(hasProviderRole(resp.data.roles));
      setLoading(false);
    }).catch(err => {
      handleServerError(err);
      setLoading(false);
    });
  }, [logged, memberId]);

  useEffect(() => {
    setRoles(getSubRoles(logged.roles));
  }, [logged]);

  function save(store: Store) {
    setSaving(true);
    isNew ? create(store) : update(store);
  }

  function create(store: Store) {
    const patched = patchMember(member, store);

    if (isAdmin || isCustomerCare(logged)) {
      AdminApi.createMember(patched)
        .then(resp => onSuccessfullySave(resp.data))
        .catch(onErrorSave);
    } else {
      PracticeApi.createProvider(practice.id, patched)
        .then(resp => onSuccessfullySave(resp.data))
        .catch(onErrorSave);
    }
  }

  function update(store: Store) {
    const patched = patchMember(member, store);
    MemberApi.saveMember(memberId, patched)
      .then(() => onSuccessfullySave(member))
      .catch(err => {
        setSaving(false);
        handleServerError(err);
      });
  }

  function onSuccessfullySave(value: MemberDto) {
    setSaving(false);
    if (onSaveSuccess) {
      onSaveSuccess(value);
      checkMemberStatusUpdate();
    }
  }

  function onErrorSave(err: AxiosError) {
    setSaving(false);
    handleServerError(err);
  }

  function checkMemberStatusUpdate() {
    if (hasMemberStatusChanged) {
      onMemberStateChange();
    }
  }

  function getDefaultInactiveDate(): string {
    if (member && !!member.inactiveDate) {
      return formatDate(fromTimestamp(member.inactiveDate))
    }
    return formatDate(moment())
  }

  function onChange(patch: Partial<MemberDto>) {
    setMember({...member, ...patch});
  }

  function onAvatarChange(avatar: Blob) {
    if (avatar) {
      avatar.arrayBuffer().then(resp => {
        setMember({...member, avatar: arrayBufferToBase64(resp)});
      });
    } else {
      setMember({...member, avatar: null});
    }
  }

  function onFullAvatarChange(fullAvatar: Blob) {
    if (fullAvatar) {
      fullAvatar.arrayBuffer().then(resp => {
        setMember({...member, fullAvatar: arrayBufferToBase64(resp)});
      });
    } else {
      setMember({...member, fullAvatar: null});
    }
  }

  const onUploadAvatar = useCallback(() => {
    if (logged.id === memberId) {
      onSuccessfullySave(member);
    }
  }, [member, onSuccessfullySave]);

  const onDeleteAvatar = useCallback(() => {
    if (logged.id === memberId) {
      onSuccessfullySave(member);
    }
  }, [member, onSuccessfullySave]);

  function toggleState(value: boolean) {

    onChange({state: value ? MemberDtoStateEnum.Active : MemberDtoStateEnum.Inactive, inactiveDate: !value ? timestamp(moment()) : null});
    setMemberStatusChanged(true);
  }

  function toggleProviderRole(value: boolean) {
    const newRoles = value ? [...member.roles, MemberDtoRolesEnum.Provider] : member.roles.filter(it => it !== MemberDtoRolesEnum.Provider);
    setMember({...member, roles: newRoles});
  }

  function sendPasswordReset() {
    MemberApi.triggerPasswordReset(memberId).then(() => {
      message.success(t('Passwort zurückgesetzt E-Mail erfolgreich gesendet!'));
    }).catch(handleServerError);
  }

  function sendMfaPhoneReset() {
    // MemberApi.sendResetMfaPhoneCode(memberId).then(() => {
    //   message.success(t('MFA Nummer zurückgesetzt E-Mail erfolgreich gesendet!'));
    // }).catch(handleServerError);
  }

  function toggleMfa(value: boolean) {
    setMember({
      ...member,
      settings: {
        ...member.settings,
        twoFactorAuthentication: value
      }
    });
  }

  function generateDummyData() {
    setDummyLoading(true);
    AdminApi.generateDummyData(member.id, dummyDataEntries).then(() => {
      setDummyDataModal(false);
      message.info(t('Generated!'));
    }).catch(() => {
      setDummyDataModal(false);
      message.info(t('Failed to generate.'));
    })
  }

  return (
    <>
      {loading && <Skeleton loading={true}/>}

      {!loading && member &&
      <div className={`content user-general-settings ${isSelf ? '' : 'expanded'}`}>
        <Form layout="vertical" className={'form'} onFinish={save} initialValues={{
          ...member,
          phone: removePhonePrefix(member?.phone)
        }}>
          <div className={'member-info'}>
            <Button type="link" className='back-btn' onClick={() => onCancel()}><Icon component={backIcon}/></Button>
            <h3 className='user-settings-title'>{t('Konto-Einstellungen')}</h3>
          </div>

          <div className={`label switch-wrapper ${isAdmin && member.state === MemberDtoStateEnum.Inactive ? '' : 'hide-inactive-from'}`}>
            <Switch defaultChecked={member.state === MemberDtoStateEnum.Active} onChange={toggleState} disabled={member.state === MemberDtoStateEnum.Locked}/>
            <span>{t('Benutzer Aktiv')}</span>
          </div>

          {isAdmin && member.state === MemberDtoStateEnum.Inactive &&
          <Item label={t('Inactive from')} className='inactivity-form-item'>
            <div>{getDefaultInactiveDate()}</div>
          </Item>}

          <div className='user-type'>
            <label>{t('Typ')}</label>
            <Select className={'full-width'} defaultValue={isSelf ? t(logged.activeRole) : t(member.roles[0])} size={'large'} onChange={value => onChange({roles: [value as MemberDtoRolesEnum]})}
                    disabled={!isAdmin}>
              {roles.map(it => <Select.Option value={it} key={it}>{t(it)}</Select.Option>)}
            </Select>
          </div>

          {!isNew &&
          <div className={'label align-end switch-wrapper'}>
            {canBeProvider && <>
              <Switch defaultChecked={isAlsoProvider} onChange={toggleProviderRole}/>
              <span>{t('Care Provider Konto')}</span>
            </>}
          </div>}

          {isNew &&
          <Item label={t('Passwort')} name={'password'} rules={[{required: true, message: t('Feld ist erforderlich')}, {validator: passwordValidator}]}>
            <Password size="large" name="new-password" id="new-password" autoComplete="off"/>
          </Item>}

          <div className={'fields'}>
            <Item label={t('Nachname')} name={'lastName'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
              <Input size={'large'} type={'text'}/>
            </Item>
          </div>

          <div className={'fields'}>
            <Item label={t('Vorname')} name={'firstName'} rules={[{required: true, message: t('Feld ist erforderlich')}]}>
              <Input size={'large'} type={'text'}/>
            </Item>
          </div>

          <div className={'fields'}>
            <EmailInput asFormItem={true} required={true} disabled={!isNew}/>
          </div>

          <div className={'fields phone'}>
            <div>
              <PhoneInput asFormItem={true} required={true}/>
            </div>
          </div>

          {memberId &&
          <div className="avatar-settings">
            <AvatarSettings member={member} onChange={onAvatarChange} onUploadAvatar={onUploadAvatar} onDeleteAvatar={onDeleteAvatar} onFullAvatarChange={onFullAvatarChange}/>
          </div>}

          <div className="notifications-settings">
            {/*<NotificationSettings settings={member.settings} onMemberSettingsChange={onSettingsChange}/>*/}
          </div>

          {!isSelf &&
          <div className={`security ${!isNew ? 'not-new' : ''}`}>
            {!isNew &&
            <div className={'reset'}>
              <Button className={'forget-devices'} onClick={sendPasswordReset}>{t('Passwort zurücksetzen')}</Button>
              <p>{`${t('zuletzt geändert am ') +
              formatDate(fromTimestamp(member.passwordChangedAt ? member.passwordChangedAt : member.createdDate)) + ' ' +
              formatTime(fromTimestamp(member.passwordChangedAt ? member.passwordChangedAt : member.createdDate)) + ' ' + t('Uhr')}`}</p>
            </div>}

            <div className='label switch-wrapper'>
              <Switch defaultChecked={isNew || member.settings.twoFactorAuthentication} onChange={toggleMfa} disabled={isNew}/>
              <span>{t('Zwei-Faktor-Authentifizierung')}</span>
            </div>

            {!isNew &&
            <Button onClick={sendMfaPhoneReset}>{t('mfa telefonnummer zurücksetzen')}</Button>}

            {env.generateDummyData &&
            <Button onClick={() => setDummyDataModal(true)}>{t('Generate dummy data')}</Button>}
          </div>}

          <div className='buttons'>
            <Button type={'primary'} size="large" htmlType={'submit'} loading={saving}>{t('Speichern')}</Button>
            <Button className={'default'} size="large" onClick={onCancel}>{t('Abbrechen')}</Button>
          </div>
        </Form>

        {env.generateDummyData && <Modal
          confirmLoading={dummyLoading}
          className={'dummy-data-modal'}
          visible={dummyDataModal}
          onOk={generateDummyData}
          okButtonProps={{className: 'modal-button', size: 'large'}}
          onCancel={() => setDummyDataModal(false)}
          okText={t('Ja')}
          cancelText={t('Nein')}
          cancelButtonProps={{className: 'modal-button', size: 'large'}}>
          <Input value={dummyDataEntries} type={'number'} onChange={(ev) => setDummyDataEntries(parseInt(ev.target.value))}/>
        </Modal>}
      </div>}
    </>
  );
}
