import {Button, Checkbox, Modal} from 'antd';
import './ShowLoginHintModal.scss';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import { useState } from 'react';

interface ShowLoginHintModalProps {
  visible: boolean;
  onClose: (hideHint: boolean) => void;
}

export default function ShowLoginHintModal({visible, onClose}: ShowLoginHintModalProps) {
  const {t} = useTranslation();
  const [hideHint, setHideHint] = useState(false);

  return (
    <Modal className={'hint-modal'} visible={visible}>
      <h3>
        <div>{t('Sie sind in der Rolle „Inhaber“ angemeldet.')}</div>
        <div>{t('In dieser Rolle können Sie Ihre Praxiseinstellungen ändern')}</div>
        <div>{t('oder Mitarbeiter anlegen.')}</div>
      </h3>
      <h3>
        <div>{t('Wenn Sie Videosprechstunden führen möchten,')}</div>
        <div>{t('wechseln Sie bitte oben rechts in die Mitarbeiteransicht.')}</div>
      </h3>
      <div className={'check'}>
        <Checkbox onChange={ev => setHideHint(ev.target.checked)}/>
        <h3>{t('Diese Meldung nicht mehr anzeigen.')}</h3>
      </div>
      <Button type={'primary'} size={'middle'} className={'ok-btn'} onClick={() => onClose(hideHint)}>{t('Ok')}</Button>
    </Modal>
  );
}


