import React from 'react';
import './DashboardHeaderMenu.scss';
import {useTranslation} from "react-i18next";
import {useRecoilState} from "recoil";
import {loggedMember} from "../../state/atoms";
import {Button, Dropdown} from "antd";
import {hasProviderRole, logout as doLogout} from "../../util/utils";
import {useHistory} from "react-router";
import {MemberDtoRolesEnum} from "../../gen/client";
import {ReactComponent as caretIcon} from '../../img/icons/caret-down-icon.svg';
import Avatar from "../general/Avatar";
import Storage, {STORAGE_ROLE} from "../../util/Storage";
import {getDefaultRoute, Routes} from "../../util/routes";
import {NavLink} from "react-router-dom";
import Icon from '@ant-design/icons';
import {getUnloggedAtom} from '../../state/logged';
import AuthApi from "../../api/AuthApi";

export default function DashboardHeaderMenu() {
  const {t} = useTranslation();
  const history = useHistory();

  const [logged, setLogged] = useRecoilState(loggedMember);

  function logout() {
    doLogout();
    AuthApi.logout();
    setLogged(getUnloggedAtom());
    history.push('/');
  }

  function changeRole() {
    const alternative = logged.roles.find(it => it !== MemberDtoRolesEnum.Provider);
    const role = logged.activeRole === MemberDtoRolesEnum.Provider ? alternative : MemberDtoRolesEnum.Provider;
    Storage.setValue(STORAGE_ROLE, role);
    setLogged({...logged, activeRole: role});
    history.push(getDefaultRoute(role));
  }

  const menu = (
    <div style={{ transform: "translateY(-5px)" }}>
    <ul className={'hdr-mn'}>
      {(logged.activeRole === MemberDtoRolesEnum.Provider || logged.activeRole === MemberDtoRolesEnum.PracticeManager) &&
      <li>
        <NavLink to={Routes.USER_SETTINGS} className={'ant-btn ant-btn-link'}>{t('Benutzer-Einstellungen')}</NavLink>
      </li>}

      <li><Button type={'link'} onClick={logout}>{t('Abmelden')}</Button></li>

      {logged.roles.length > 1 && hasProviderRole(logged.roles) &&
      <li>
        <Button className={'rl'} type={'primary'} onClick={changeRole}>
          {logged.activeRole !== MemberDtoRolesEnum.Provider && <span>{t('Care Provider Area')}</span>}
          {logged.activeRole === MemberDtoRolesEnum.Provider && <span>{t('Management Area')}</span>}
        </Button>
      </li>}
    </ul>
    </div>
  );

  return (
    <div className={'db-mn'}>
      <Dropdown className={'ntf-drop'} overlay={menu} trigger={['click']} placement={'bottomRight'}>
        <Button type={'ghost'} size={'large'}>
          <Avatar avatar={logged.avatar} name={logged.name}/>
          <div className={'txt'}>
            <span className={'nm'}>{logged.name}</span>
            {(logged.activeRole !== MemberDtoRolesEnum.Provider || logged.roles.length > 1) &&
            <span className={`role ${logged.activeRole === MemberDtoRolesEnum.Provider ? 'pv' : ''}`}>{t(logged.activeRole)}</span>}
          </div>
          <Icon className={'crt'} component={caretIcon} alt={''}/>
        </Button>
      </Dropdown>
    </div>
  );
}
