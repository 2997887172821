import * as React from 'react';
import './CMSDetails.scss';
import Container from '../general/Container';
import {useTranslation} from 'react-i18next';
import {Tabs, Button, Modal, Collapse} from 'antd';
import {useState, useEffect, useCallback} from 'react';
import QuestionResponses from './QuestionResponses';
import EditQuestion from './EditQuestion';
import ResponseLine from './ResponseLine';
import {CmsQuestionDto, CmsDto, MemberAnswerGroupDto, ParticipantAnswerGroupDto, LightCmsAnswerDto, SearchOptionsSortEnum} from '../../gen/client';
import {useHistory, useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {activePractice, loggedMember} from '../../state/atoms';
import {handleServerError} from '../../util/ErrorHandler';
import PracticeApi from '../../api/PracticeApi';
import Icon, {ExclamationCircleOutlined} from '@ant-design/icons';
import {downloadFile, groupByMember, isAdminOrCustomerCare, sortAscendingTimestamps, sortDescendingTimestamps} from '../../util/utils';
import AddQuestion from './AddQuestion';
import QuestionCard from './QuestionCard';
import CmsApi from '../../api/CmsApi';
import {AxiosPromise} from 'axios';
import TenantApi from '../../api/TenantApi';
import {TENANT_ID} from '../../util/constants';
import {ReactComponent as backIcon} from '../../img/icons/back-icon.svg';
import {Routes} from '../../util/routes';
import MemberAnswersLines from './MemberAnswersLines';
import SortArrows from '../general/sort/SortArrows';
import { ActiveTab, SortDirection } from '../../util/enums';

const { Panel } = Collapse;
const {confirm} = Modal;

export default function CMSDetails() {
  const {t} = useTranslation();
  const {TabPane} = Tabs;
  const {id} = useParams();
  const history = useHistory();
  const [practice] = useRecoilState(activePractice);

  const [openAddQuestionTab, setOpenAddQuestionTab] = useState(false);
  const [openEditQuestionTab, setOpenEditQuestionTab] = useState(false);
  const [openAnswersTab, setOpenAnswersTab] = useState(false);
  const [selected, setSelected] = useState({} as CmsQuestionDto);
  const [questions, setQuestions] = useState([] as CmsQuestionDto[]);

  const [participantAnswerGroups, setParticipantAnswerGroups] = useState({} as { [key: string]: Array<ParticipantAnswerGroupDto>; });
  const [memberAnswerGroups, setMemberAnswerGroups] = useState({} as { [key: string]: Array<MemberAnswerGroupDto>; });

  const [questionAnswers, setQuestionAnswers] = useState([] as LightCmsAnswerDto[]);

  const [cms, setCms] = useState(null as CmsDto);
  const [logged] = useRecoilState(loggedMember);

  const [sortDirection, setSortDirection] = useState(SearchOptionsSortEnum.Default);
  const [activeTab, setActiveTab] = useState(ActiveTab.FIRST);

  const getQuestionList = useCallback(() => {
    const action = isAdminOrCustomerCare(logged) ? TenantApi.getQuestions(id, TENANT_ID) : PracticeApi.getQuestions(id, practice.id);
    action.then(resp => setQuestions(resp.data)).catch(handleServerError);
  }, [id, practice.id, logged]);


  const getAnswerList = useCallback((value: CmsDto) => {
    if (value && value.name.toUpperCase() === "PATIENT AFTER CALL QUESTIONS") {
      PracticeApi.getParticipantAnswers(id, practice.id)
        .then(resp => setParticipantAnswerGroups(resp.data)).catch(handleServerError);
    } else {
      const action = isAdminOrCustomerCare(logged) ? TenantApi.getAnswers(id, TENANT_ID) : PracticeApi.getAnswers(id, practice.id);
      action.then(resp => setMemberAnswerGroups(resp.data)).catch(handleServerError);
    }
  }, [id, practice.id, logged]);

  useEffect(() => {
    CmsApi.getAvailableCmsTypes(logged.id)
      .then(resp => {
        var response = resp.data.find(it => it.id.toString() === id);
        setCms(response);
        getQuestionList();
        getAnswerList(response);
      })
      .catch(handleServerError);
  }, [id, practice.id, getQuestionList, getAnswerList, logged.id]);

  useEffect(() => {
    if (selected.id && (openEditQuestionTab || openAnswersTab)) {
      history.push(`${Routes.CMS}/${id}/${selected.id}`);
    } else {
      history.push(`${Routes.CMS}/${id}`);
      setSelected({} as CmsQuestionDto);
    }
  }, [openEditQuestionTab, openAnswersTab, selected.id, id, history]);

  function isPatientCms() {
    return cms && cms.name.toUpperCase() === "PATIENT AFTER CALL QUESTIONS";
  }

  function onSave() {
    setOpenAddQuestionTab(false);
    getQuestionList();
  }

  function onEdit() {
    setOpenEditQuestionTab(false);
    getQuestionList();
  }

  function showDeleteConfirm(questionId: string) {
    confirm({
      title: `${t('Möchten Sie die Frage wirklich löschen?')}?`,
      icon: <ExclamationCircleOutlined/>,
      okText: t('Ja'),
      okType: 'danger',
      cancelText: t('Nein'),
      onOk() {
        deleteQuestion(questionId);
      }
    });
  }

  function deleteQuestion(questionId: string) {
    const deleteAction = isAdminOrCustomerCare(logged) ? TenantApi.deleteQuestion(id, TENANT_ID, questionId) : PracticeApi.deleteQuestion(id, practice.id, questionId);
    deleteAction.then(() => {
      setQuestions([...questions.filter(it => it.id !== questionId)])
    }).catch(handleServerError);
  }

  function closeTabs() {
    setQuestionAnswers([]);
    if (openAddQuestionTab) {
      setOpenAddQuestionTab(false);
    }
    if (openEditQuestionTab) {
      setOpenEditQuestionTab(false);
    }
    if (openAnswersTab) {
      setOpenAnswersTab(false);
    }
  }

  function addQuestion() {
    closeTabs();
    setOpenAddQuestionTab(true);
  }

  function editQuestion(event: React.MouseEvent<HTMLElement, MouseEvent>, question: CmsQuestionDto) {
    event.stopPropagation();
    closeTabs();
    setOpenEditQuestionTab(true);
    setSelected(question);
  }

  function viewResponses(question: CmsQuestionDto) {
    closeTabs();
    if (question.id !== selected.id) {
      getAnswersForQuestion(question);
      setOpenAnswersTab(true);
      setSelected(question);
    } else {
      setOpenAnswersTab(false);
      setSelected({} as CmsQuestionDto);
    }
  }

  function getAnswersForQuestion(question: CmsQuestionDto) {
    const getAnswersAction: AxiosPromise = isAdminOrCustomerCare(logged) ? TenantApi.getLightAnswersForQuestion(id, TENANT_ID, question.id) : PracticeApi.getLightPracticeAnswersForQuestion(id, practice.id, question.id);
    getAnswersAction
      .then(resp => setQuestionAnswers(resp.data))
      .catch(handleServerError);
  }

  function getMemberAnswerGroupsLines() {
    return <div>
      {
        Object.keys(memberAnswerGroups).length > 0 ? 
        <div>
          {
            Object.keys(memberAnswerGroups)
              .sort((key1, key2) => sortDirection === SearchOptionsSortEnum.Asc ? sortAscendingTimestamps(key1, key2) : sortDescendingTimestamps(key1, key2))
              .map(key => {
                const answerGroups = memberAnswerGroups[key];
                return ( <div key={key}>{<div key={key}>{getMemberAnswersLines(answerGroups, key)}</div>}</div>)
              })
          }
        </div> 
        : <h1>{t('Keine Übereinstimmung gefunden.')}</h1>
      }
    </div>
  }

  function getParticipantAnswerGroupsLines() {
    return <div>
      {
        Object.keys(participantAnswerGroups).length > 0 ? 
        <div>
          {
            Object.keys(participantAnswerGroups)
              .sort((key1, key2) => sortDirection === SearchOptionsSortEnum.Asc ? sortAscendingTimestamps(key1, key2) : sortDescendingTimestamps(key1, key2))
              .map(key => {
                const answerGroups = participantAnswerGroups[key];
                return ( <div key={key}>{<div key={key}>{getParticipantAnswersLines(answerGroups, key)}</div>}</div>)
              }) 
          }
          </div> 
        : <h1>{t('Keine Übereinstimmung gefunden.')}</h1>
      }
    </div>
  }

function groupByParticipant(answerGroups: ParticipantAnswerGroupDto[]): { [key: string]: Array<ParticipantAnswerGroupDto>; } {
  return answerGroups
    .reduce((acc: any, answerGroup: ParticipantAnswerGroupDto, idx) => {
      let participantId = idx
      acc[participantId] = acc[participantId] || [];
      acc[participantId].push(answerGroup);
      return acc;
  }, []);
}

function getMemberAnswersLines(list: MemberAnswerGroupDto[], date: string) {
  const grouped = groupByMember(list);
  return <div>
    { Object.keys(grouped).map(key => 
      { return ( <MemberAnswersLines key={key} grouped={grouped} currentKey={key} date={date}/> )}
    )}
  </div>
}

function getParticipantAnswersLines(list: ParticipantAnswerGroupDto[], date: string) {
  const grouped = groupByParticipant(list);
  return <div>
    {Object.keys(grouped).map((key, idx) => {
      return (
        <Collapse accordion expandIconPosition='right' ghost key={key}>
          <Panel key={key} header={`${grouped[key][0]}${idx} - ${date}`}>
            {grouped[key]?.map(feedback => {
              return (<ResponseLine answer={feedback.answer} question={feedback.question} key={feedback.answer.id}/>)
            })}
          </Panel>
        </Collapse>
      )
    })}
  </div>
}

  function goBack() {
    history.push(Routes.CMS);
  }

  function exportQuestion(question: CmsQuestionDto) {
    const action: AxiosPromise = isAdminOrCustomerCare(logged) ? TenantApi.exportQuestionToCSV(TENANT_ID, cms.id, question.id) : PracticeApi.exportQuestionToCSV(practice.id, cms.id, question.id);
    action.then(response => {
      downloadFile(response.request.responseText, 'answers.csv')
    }).catch(handleServerError);
  }
 
  const questionCards = questions?.map(question => (
    <div className='question-card' key={question.id} onClick={(ev) => {ev.stopPropagation(); viewResponses(question)}}>
      <QuestionCard question={question} selected={question.id === selected.id} editQuestion={(ev) => editQuestion(ev, question)}
                  deleteQuestion={() => showDeleteConfirm(question.id)} viewResponses={() => viewResponses(question)} key={question.id}
                  exportQuestion={() => exportQuestion(question)}/>
    </div>
  ));

  function onSortChange(direction: SortDirection) {
    setSortDirection(direction === SortDirection.Asc ? SearchOptionsSortEnum.Asc : SearchOptionsSortEnum.Desc);
  }

  return (
    <Container className={'cms-details full vd-list'}>
      <header>
        <Button type="link" className='back-btn' onClick={goBack}><Icon component={backIcon}/></Button>
        <h2>{t(cms?.name)}</h2>
        {activeTab === ActiveTab.SECOND &&
          <SortArrows sortDirection={sortDirection} onSortChange={onSortChange}/>}
      </header>
      <Tabs onChange={tab => setActiveTab(tab as ActiveTab)}>
        <TabPane tab={t('Questions')} key="1">
          <div className='first-tab'>
            <div className={`questions ${openAddQuestionTab ? 'add-new' : ''} ${selected?.id ? 'selected' : ''}`}>
              <div>{questionCards}</div>
              {cms?.name !== "After register questions" && !openAddQuestionTab &&
              <Button type={'dashed'} className={'dashed-btn'} size="large" onClick={addQuestion}>
                {t('andere Frage hinzufügen')}
              </Button>}
            </div>

            <div className={`question-details ${(openAddQuestionTab || openEditQuestionTab || openAnswersTab) ? 'has-content' : ''}`}>
              {openAddQuestionTab &&
              <AddQuestion onSave={onSave} onCancel={() => setOpenAddQuestionTab(false)}/>}
              {openEditQuestionTab &&
              <EditQuestion question={selected} onEdit={onEdit} onCancel={() => setOpenEditQuestionTab(false)}/>}
              {openAnswersTab &&
              <QuestionResponses answers={questionAnswers} question={questions.filter(it => it.id === selected.id)[0]} onCancel={() => setOpenAnswersTab(false)}/>}
            </div>
          </div>
        </TabPane>
        <TabPane tab={t('Responses')} key="2">
          <div className='second-tab'>
            <div className='answers content'>{isPatientCms() ? getParticipantAnswerGroupsLines() : getMemberAnswerGroupsLines()}</div>
          </div>
        </TabPane>
      </Tabs>
    </Container>
  )
}
