import * as React from 'react';
import './MfaPage.scss';
import {useTranslation} from "react-i18next";
import regImage from '../../img/register-1.svg';
import mainLogo from '../../img/noventi-video-logo.png';
import StartPageFooter from "./StartPageFooter";
import MfaInputBox from "../general/MfaInputBox";
import {loginData, loggedMember, activePractice, applicationSize} from "../../state/atoms";
import {useHistory} from "react-router-dom";
import {Switch, Button, Skeleton, Tooltip} from 'antd';
import {useState, useEffect, useCallback} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import AuthApi from '../../api/AuthApi';
import Storage from "../../util/Storage";
import {handleServerError} from "../../util/ErrorHandler";
import {errorMessage, getApplicationSizeClass, initialStorageSetup, successMessage} from '../../util/utils';
import {Dimensions} from '../../util/enums';
import {getLoggedAtom} from "../../state/logged";
import { getInitialRoute } from "../../util/routes";
import { env } from '../../env/env';

export default function MfaPage() {
  const {t} = useTranslation();
  const history = useHistory();

  const [loginInfo, setLoginInfo] = useRecoilState(loginData);
  const setLogged = useSetRecoilState(loggedMember);
  const setPractice = useSetRecoilState(activePractice);
  const appSize = useRecoilValue<Dimensions>(applicationSize);

  const [isLoading, setLoading] = useState(false);
  const [isLoginLoading, setLoginLoading] = useState(false);
  const [loginCode, setLoginCode] = useState('');
  const [rememberDevice, setRememberDevice] = useState(false);
	const [enableResendButton, setEnableResendButton] = useState(false);

  const [disableMfaInput, setDisableMfaInput] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  useEffect(() => enableResendButtonAfterMfaPeriodExpired(), []);

  function onMfaValueChange(value: string) {
    setLoginCode(value);
    setDisableSaveButton(false);
  }

  const login = useCallback((autoLogin: boolean = false) => {
    if (autoLogin) {
      setLoading(true);
    } else {
      setLoginLoading(true);
    }
    if (loginInfo.email && loginInfo.password) {
      AuthApi.login(Object.assign({}, loginInfo, {
        code: loginCode,
        device: Storage.getDeviceId(loginInfo.email) || '',
        rememberDevice
      })).then(resp => {
        if (autoLogin) {
          setLoading(false);
        } else {
          setLoginLoading(false);
        }

        const member = resp.data.member;
        if (member.id) {
          const logged = getLoggedAtom(resp.data);
          setLogged(logged);
          initialStorageSetup(resp.data, logged.activeRole);

          setLoginInfo({email: '', password: ''});
          setPractice({
            id: member.practice.id,
            name: member.practice.name
          });
          history.push(getInitialRoute(resp.data.needsSetup, logged.activeRole));
        } else {
          if (autoLogin) {
            Storage.removeDeviceId(loginInfo.email);
          } else {
            errorMessage(t('Invalid MFA code'));
          }
          setLoginCode('');
        }
      }).catch(err => {
        setLoginCode('');
        if (autoLogin) {
          setLoading(false);
        } else {
          setLoginLoading(false);
        }
        handleServerError(err);
        if (err.response.data.message && (err.response.data.message === t("member.auth.expired") || err.response.data.message === t("member.auth.wrong.code"))) {
          setDisableMfaInput(true);
        }
      });
    }
  }, [history, loginCode, loginInfo, rememberDevice, setLogged, setLoginInfo, t, setPractice]);

  useEffect(() => {
    if ((!loginInfo.email || !loginInfo.password) && !Storage.getMemberId()) {
      history.push('/login');
    } else if (Storage.getDeviceId(loginInfo.email)) {
      login();
    }
  }, [history, login, loginInfo]);

  function resendCode() {
		setEnableResendButton(false);
    setDisableMfaInput(false);
    AuthApi.sendLoginCode(loginInfo).then(() => {
      successMessage(t('New MFA code sent'))
    }).catch(err => {
			err.response.data.message = (
    		<span dangerouslySetInnerHTML={{__html: t(err.response.data.message, {interpolation:{escapeValue: false}})}}>
				</span>
			)
			handleServerError(err);
		});

		enableResendButtonAfterMfaPeriodExpired();
  }

  function enableResendButtonAfterMfaPeriodExpired() {
    setTimeout(() => {
			setEnableResendButton(true);
		}, env.resendMfaCodeTimeout);
  }

  return (
    <div className={`login-page mfa-page ${getApplicationSizeClass(appSize)}`}>
      <div className={'hero'}>
        <div className={'ct'}>
          <img src={regImage} className={'main'} alt={''}/>
        </div>
      </div>
      <div className={'content'}>
        {isLoginLoading ? <Skeleton loading={true}/> : (
          <>
            <div className={'data'}>
              <img className={'logo'} src={mainLogo} alt={'logo'}/>
              <h2>{t('Geben Sie den Code ein, den wir an Ihre Handynummer gesendet haben.')}</h2>

              <MfaInputBox onChange={onMfaValueChange} disabled={disableMfaInput} onInvalidValue={() => setDisableSaveButton(true)}/>
              
                <div className={`line code ${!enableResendButton ? 'disabled' : ''}`}>
                  {t('Sie haben den Code nicht erhalten?')} <br/>
                  <Tooltip title={!enableResendButton ? t('Sie müssen 30 Sekunden warten, bevor Sie einen neuen Code anfordern können.') : undefined}>
                    <Button type={'link'} className={'resend-code'} onClick={resendCode} disabled={!enableResendButton}> {t('Senden Sie den Code erneut')}</Button>
                  </Tooltip>
                </div>
              
              
              <div className={'line remember'}>
                <div className='label switch-wrapper'>
                  <Switch onChange={ev => setRememberDevice(ev)}/>
                  <span>{t('Auf diesem Gerät zukünftig nicht mehr nachfragen.')}</span>
                </div>
              </div>

              <div className={'line'}>
                <Button size="large" type="primary" htmlType="submit" loading={isLoading} onClick={() => login()} disabled={!loginCode || isLoading || disableMfaInput || disableSaveButton}>{t('Anmelden')}</Button>
              </div>
            </div>

            {appSize === Dimensions.DESKTOP && <StartPageFooter />}
          </>
        )}
      </div>
      {appSize !== Dimensions.DESKTOP && <StartPageFooter />}
    </div>
  );
}
