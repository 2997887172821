import {isFirefox} from '../util/utils';
import {DeviceType} from './../util/enums';
import {INTERNAL_CAMERA_KEYWORDS} from "../util/constants";

export function getConnectedDevices(type: DeviceType, callback: (devices: MediaDeviceInfo[], stream?: MediaStream) => void) {
  navigator.mediaDevices.getUserMedia(getConstraints(type))
    .then((stream) => {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          let filtered = devices.filter(device => device.kind === type);
          filtered = sortDevices(filtered);

          callback(filtered, stream);
        });
      
      if (!isFirefox()) {
        stream.getTracks().forEach(track => {
          track.stop();
        });
      }
    })
    .catch((e) => {
      console.error(e);
      callback([])
    });
}

function sortDevices(devices: MediaDeviceInfo[]): MediaDeviceInfo[] {
  return devices.sort((a, b) => {
    const arrayLength = INTERNAL_CAMERA_KEYWORDS.length;
    for (let i = 0; i < arrayLength; i++) {
      const k = INTERNAL_CAMERA_KEYWORDS[i];
      if (!a.label || a.label.toLowerCase().indexOf(k) > -1) {
        return 1;
      }
      if (!b.label || b.label.toLowerCase().indexOf(k) > -1) {
        return -1;
      }
    }

    try {
      return a.label.localeCompare(b.label);
    } catch (e) {
      return 0;
    }
  });
}

function getConstraints(type: DeviceType): MediaStreamConstraints {
  switch (type) {
    case DeviceType.VIDEO_INPUT: return {audio: false, video: true};
    case DeviceType.AUDIO_INPUT: return {audio: true, video: false};
    default: return {audio: true, video: true};
  }
}

export function enumerateDevices(type: DeviceType): Promise<MediaDeviceInfo[]> {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      resolve(devices.filter(device => device.kind === type));
    }).catch(reject);
  });
}

export function getStreamForSpecificDevice(constraints: MediaStreamConstraints): Promise<MediaStream> {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => resolve(stream))
      .catch(e => {
        console.error(e);
        console.log(constraints);
        reject(e);
      });
  });
}

export function getShareScreenStream(callback: (stream: MediaStream) => void) {
  const mediaDevices = navigator.mediaDevices as any;
  mediaDevices.getDisplayMedia({video: true})
    .then(((stream: MediaStream) => callback(stream)))
    .catch(() => {
    });
}

export function attachSinkId(element: any, sinkId: string) {
  if (element && typeof element.sinkId !== 'undefined') {
    if (sinkId) {
      element.setSinkId(sinkId)
        .then(() => {
        })
        .catch((error: any) => {
          let isLocalVideo = false;
          try {
            isLocalVideo = element.id.includes('local');
          } catch (e) {}
          if (!isLocalVideo) {
            console.error(`Cannot attach sinkId for element because`, element, error);
          }
        });
      } else {
        console.error('Tried to attach an empty sinkId');
      }
  }
}

export function stopMeetingStream(stream: MediaStream) {
  stopTracks(stream?.getTracks());
}

export function stopTracks(tracks: MediaStreamTrack[]) {
  tracks?.forEach(track => track.stop()); // NOSONAR
}

export function isValidDeviceId(type: DeviceType, deviceId: string): Promise<boolean> {
  return new Promise((resolve) => {
    enumerateDevices(type).then(d => {
      if (deviceId && d.map(it => it.deviceId).includes(deviceId)) {
        resolve(true);
      } else {
        resolve(false);
      }
    }).catch(() => resolve(false));
  });
}
