import * as React from 'react';
import { useTranslation } from "react-i18next";
import regImage from '../../img/register-1.svg';
import mainLogo from '../../img/noventi-video-logo.png';
import StartPageFooter from "./StartPageFooter";
import { applicationSize } from "../../state/atoms";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getApplicationSizeClass } from '../../util/utils';
import { Dimensions, ResetMemberInfoType } from '../../util/enums';

import UserPassword from '../settings/UserPassword';
import UserMfa from '../settings/UserMfa';

export interface ResetMemberInfoPageProps {
    type: ResetMemberInfoType
}

export default function ResetMemberInfoPage({ type }: ResetMemberInfoPageProps) {
    const { t } = useTranslation();

    const { code } = useParams();

    const appSize = useRecoilValue<Dimensions>(applicationSize);

    return (
        <div className={`login-page reset-password-page ${getApplicationSizeClass(appSize)}`}>
            <div className={'hero'}>
                <div className={'ct'}>
                    <img src={regImage} className={'main'} alt={''} />
                </div>
            </div>
            <div className={'content'}>
                <div className={'data'}>
                    <img className={'logo'} src={mainLogo} alt={'logo'} />
                    {type === ResetMemberInfoType.PASSWORD &&
                        <>
                            <h3>{t('Zurücksetzen Ihres Passworts')}</h3>
                            <h6>{t('Geben Sie bitte Ihre Neue Passwort ein')}</h6>

                            <UserPassword code={code} />
                        </>
                    }
                    {type === ResetMemberInfoType.MFA_PHONE &&
                        <>
                            <h3>{t('Zurücksetzen Ihres MFA Nummer')}</h3>
                            <h6>{t('Geben Sie bitte Ihre Neue MFA Nummer ein')}</h6>

                            <UserMfa code={code} />
                        </>
                    }
                </div>

                {appSize === Dimensions.DESKTOP && <StartPageFooter />}
            </div>
            {appSize !== Dimensions.DESKTOP && <StartPageFooter />}
        </div>
    );
}
