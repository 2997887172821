/**
* This file is generated
*/
import * as React from 'react';
import { CSSProperties } from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24" xmlSpace="preserve"><path d="M17.4,12.8c-2,0-3.7-1.1-4.7-2.7H3.3V8.5c0-0.7,0.6-1.3,1.3-1.3l7.2,0c0,0,0,0,0,0c0-0.4,0.1-0.9,0.2-1.3H7.8V5
	c0-0.4-0.3-0.7-0.6-0.7C6.8,4.4,6.5,4.7,6.5,5v0.9H4.6c-0.7,0-1.4,0.3-1.8,0.8C2.3,7.2,2,7.8,2,8.5v10.4c0,0.7,0.3,1.4,0.8,1.8
	c0.5,0.5,1.2,0.8,1.8,0.8h14.1c0.7,0,1.4-0.3,1.8-0.8c0.5-0.5,0.8-1.1,0.8-1.8v-7.7C20.3,12.2,18.9,12.8,17.4,12.8z M13,16.8
	c0,0.5-0.4,1-1,1H8.4c-0.5,0-1-0.4-1-1v-2.9c0-0.5,0.4-1,1-1H12c0.5,0,1,0.4,1,1V16.8z M15.9,17.1c0,0.1-0.1,0.2-0.2,0.2h-0.4
	c-0.1,0-0.1,0-0.2-0.1l-1.6-1c0,0-0.1-0.1-0.1-0.1v-1.5c0-0.1,0-0.1,0.1-0.1l1.6-1c0.1,0,0.1-0.1,0.2-0.1l0.4,0
	c0.1,0,0.2,0.1,0.2,0.2V17.1z M17.4,11.8c-2.5,0-4.6-2.1-4.6-4.7s2.1-4.7,4.6-4.7c2.5,0,4.6,2.1,4.6,4.7S19.9,11.8,17.4,11.8z
	 M17.4,3.6c-2,0-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6S21,9.2,21,7.2S19.4,3.6,17.4,3.6z M20,7.2c0,0.3-0.2,0.5-0.5,0.5h-2.1
	c-0.3,0-0.5-0.2-0.5-0.5V4.9c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v1.8h1.5C19.7,6.6,20,6.9,20,7.2z"/></svg>
);

const VideoCallDurationIcon = (props: { style?: CSSProperties, className?: string }) => <Icon component={svg} {...props}/>;

export default VideoCallDurationIcon;