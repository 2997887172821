import {Message} from "../components/appointment/Chat";
import {MeetingDtoPermissionsEnum, MeetingDtoTypeEnum} from "../gen/client";
import {NetworkConnectionStatus} from "./enums";

export enum StreamEvent {
  Join = 'join',
  Confirm = 'confirm',
  Infirm = 'infirm',
  Connect = 'connect',
  Start = 'start',
  End = 'end',
  Status = 'status',
  Logout = 'logout',
  Joined = 'joined',
  RequestRestartIce = 'restartice',
  RequestRestart = 'reqrest',
  Restart = 'restart',
  Ping = 'ping',
  Pong = 'pong',

  Offer = 'offer',
  Answer = 'answer',
  Ice = 'ice'
}

export interface JoinParams {
  memberOrParticipantId: string;
  meetingId: string;
  owner: boolean;
}

export interface ConfirmParams {
  meetingId: string;
  peerId: string;
}

export interface StartParams {
  meetingId: string;
  type: MeetingDtoTypeEnum,
  permissions: Set<MeetingDtoPermissionsEnum>;
}

export interface EndParams {
  meetingId: string;
}

export enum MeetingStatus {
  Simulate = 'SIMULATE',
  WarmUp = 'WARM_UP',
  Progress = 'IN_PROGRESS',
  Ended = 'ENDED'
}

export interface Connection {
  pc: RTCPeerConnection, 
  dc: RTCDataChannel, 
  ms: MediaStream, 
  offerer: boolean, 
  makingOffer: boolean,
  initDone: boolean,
  expectingAnswer: boolean;
  createdDate: number;
  lastIceRestart: number;
  lastConnectionRestart: number;
  hasReceivedNullIceCandidate: boolean;
  bandwidth: Bandwidth;
  restartCount: number;
  restartIce: () => void;
  restartConnection: () => void;
  increaseBandwidth: () => void;
  decreaseBandwidth: () => void;
  refreshBandwidth: () => void;
}

export interface AttachmentMetadata {
  chunkSize: number,
  name: string,
  size: number,
  type: string,
  finished: boolean;
}

export interface MeetingSession {
  id: string,
  memberOrParticipantId: string,
  owner: boolean;
}

export interface MeetingInfo {
  id: string,
  sessions: MeetingSession[],
  status: MeetingStatus,
  started?: number,
  ended?: number;
}

export interface ConnectionFeedback {
  uuid: string;
  speed: NetworkConnectionStatus
}

export interface ConnectParams {
  peers: string[], 
  ownerPeers: string[], 
  permissions: MeetingDtoPermissionsEnum[];
}

export type OnConnectedCallback = (uuid: string) => void;
export type OnStreamCallback = (uuid: string, stream: MediaStream) => void;
export type OnMessageCallback = (message: Message) => void;
export type OnInternalMessageCallback = (message: InternalMessage) => void;
export type OnParticipantJoinedCallback = (meetingSession: MeetingSession) => void;
export type OnMeetingInfoCallback = (info: MeetingInfo) => void;
export type OnConnectionFeedbackCallback = (feedback: ConnectionFeedback) => void;

export enum Bandwidth {
  UNLIMITED = 'unlimited',
  _2000 = '2000',
  _1000 = '1000',
  _750 = '750',
  _500 = '500',
  _200 = '200',
  _100 = '100'
}

export enum AspectRatio {
  RATIO_3_4 = '3/4',
  RATIO_4_3 = '4/3',
  
  RATIO_16_9 = '16/9',
  RATIO_9_16 = '9/16',
  
  RATIO_5_3 = '5/3',
  RATIO_3_5 = '3/5',

  RATIO_5_4 = '5/4',
  RATIO_4_5 = '4/5'
}

export enum MemberAuthTokenStatus {
  VALID = 'VALID', 
  INVALID = 'INVALID', 
  EXPIRED = 'EXPIRED',
  MAX_ATTEMPTS = 'MAX_ATTEMPTS'
}

export interface InternalMessage {
  type: InternalMessageType,
  from?: string,
  payload?: string
}

export enum InternalMessageType {
  SWITCH_TO_SHARE_SCREEN = 'SWITCH_TO_SHARE_SCREEN',
  SWITCH_TO_DEVICE = 'SWITCH_TO_DEVICE',
  MUTE = 'MUTE',
  UNMUTE = 'UNMUTE',
  CAMERA_POSITION = 'CAMERA_POSITION',
  CAMERA_SHOW = 'CAMERA_SHOW',
  CAMERA_HIDE = 'CAMERA_HIDE'
}

export interface RemoteStream {
  id: string, 
  stream: MediaStream, 
  screenSharing?: boolean,
  audioMuted?: boolean,
  cameraPosition?: CameraPosition;
  cameraOff?: boolean;
}

export enum MfaInputBoxState {
  INITIAL = 'INITIAL',
  INVALID = 'INVALID'
}

export enum CameraPosition {
  USER, ENVIRONMENT
}

export interface CopyUrlEntry {
  url: string;
  name: string;
  publicId: string;
}

export interface CalcLayoutProps {
  drawerVisible: boolean;
}

export type TimelineTag = 'getUserMedia' | 'peer' | 'connection' | 'track' | 'datachannel' | 'stats'

export interface TimelineEvent {
  event: string
  tag: TimelineTag
  timestamp?: Date
  data?: any
  peerId?: string
  error?: any
  rawStats?: RTCStatsReport
  statsObject?: any
  filteredStats?: any
}

export interface CalcLayoutParams {
  screenWidth: number;
  screenHeight: number;
  videoCount: number;
}
