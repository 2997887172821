import * as React from 'react';
import './RegisterForm.scss';
import { Button, Checkbox, Form, Input, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Store } from 'antd/lib/form/interface';
import AuthApi from "../../api/AuthApi";
import { handleServerError } from "../../util/ErrorHandler";
import { useSetRecoilState } from "recoil";
import { loggedMember } from "../../state/atoms";
import { NavLink } from "react-router-dom";
import PasswordStrength from '../general/PasswordStrength';
import EmailInput from '../general/EmailInput';
import PhoneInput from '../general/PhoneInput';
import {getLoggedAtom} from "../../state/logged";
import Icon from '@ant-design/icons';
import {ReactComponent as infoIcon}  from '../../img/icons/info-icon.svg';
import { passwordValidator } from '../../util/validators';
import { ContentRoutes } from '../../util/routes';

const { Item } = Form;
const { Password } = Input;

export interface RegisterFormProps {
  onRegisterSuccess: () => void;
}

export default function RegisterForm({ onRegisterSuccess }: RegisterFormProps) {
  const { t } = useTranslation();

  const setLogged = useSetRecoilState(loggedMember);
  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState('' as string);

  function register(values: Store) {
    setLoading(true);
    AuthApi.register(values).then(resp => {
      setLoading(false);
      setLogged(getLoggedAtom(resp.data));
      onRegisterSuccess();
    }).catch(err => {
      setLoading(false);
      handleServerError(err);
    });
  }


  function onValuesChange(changedValues: any, values: Store) {
    setPassword(values.password || '')
  }

  return (
    <Form className={'reg'} onFinish={register} onValuesChange={onValuesChange}>
      <div className={'split'}>
        <Item label={'Vorname'} name={'firstName'} rules={[{ required: true, message: t('Feld ist erforderlich') }]}>
          <Input inputMode={'text'} size="large" />
        </Item>

        <Item label={'Nachname'} name={'lastName'} rules={[{ required: true, message: t('Feld ist erforderlich') }]}>
          <Input inputMode={'text'} size="large" name="new-password" id="new-password" autoComplete="off"/>
        </Item>
      </div>

      <div className={'split mobile'}>
        <EmailInput asFormItem={true} required={true} />

        <PhoneInput asFormItem={true} required={true} />
      </div>

      <div className='pass-div'>
        <Item className='password'>
          <Item label={'Passwort'} name={'password'} className={'password'} rules={[{ required: true, message: t('Feld ist erforderlich') }, { validator: passwordValidator }]}>
            <Password size="large" name="new-password" id="new-password" autoComplete="off"/>
          </Item>
          <PasswordStrength password={password} />
        </Item>
        <div className='pass-icon'>
          <Tooltip title={
            <div className='pass-rules'>
              <h3>{t('Passwortanforderungen')}</h3>
              <ul>
                <li>{t('acht Zeichen lang')}</li>
                <li>{t('eine Zahl')}</li>
                <li>{t('einen Buchstaben')}</li>
                <li>{t('Groß- und Kleinschreibung')}</li>
                <li>{t('ein Sonderzeichen (!@#$%^&-_()*)')}</li>
              </ul>
            </div>
          }>
            <Icon component={infoIcon} />
          </Tooltip>
        </div>
      </div>

      <Item label={t('License')} name={'license'} rules={[{ required: true, message: t('Feld ist erforderlich') }]}>
        <Input inputMode={'text'} size="large" />
      </Item>
    
      <Form.Item
        name={'terms'}
        valuePropName="checked"
        rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject('Akzeptieren Sie bitte unsere Geschäftsbedingungen') }]}>
        <div>
        <Checkbox>
          {t('Ich stimme den')} <NavLink target="_blank" to={ContentRoutes.TERMS}>{t('Nutzungsbedingungen')}</NavLink>
          {t(' zu')}
        </Checkbox>
        <div className='tos'>
          {t('Hier finden Sie unsere die ')} <NavLink target="_blank" to={ContentRoutes.PRIVACY}> {t('Datenschutzerklärung')}</NavLink>{'.'}
        </div>
        </div>
      </Form.Item>

      <Item className={'flx ft'}>
        <Button size="large" type="primary" htmlType="submit" loading={isLoading}>{t('Register')}</Button>
      </Item>
    </Form>
  );
}
