import * as React from 'react';
import {useTranslation} from "react-i18next";
import {AccountGroupDto, ParticipantDto} from "../../gen/client";
import ParticipantShortView from './ParticipantShortView';
import iconMultiSelectionContacts from '../../img/icons/icon-multi-selection-contacts.svg';
import './Participants.scss';
import ParticipantGroupShortView from "../group/ParticipantGroupShortView";

interface ParticipantsProps {
  participants: ParticipantDto[];
  groups: AccountGroupDto[];
  onEditParticipant: (participant: ParticipantDto) => void;
  onDeleteParticipant: (idx: number) => void;
  onDeleteGroup: (idx: number) => void;
}

export default function Participants({
                                       participants,
                                       groups,
                                       onEditParticipant,
                                       onDeleteParticipant,
                                       onDeleteGroup
                                     }: ParticipantsProps) {
  const {t} = useTranslation();

  return (
    <div className={'participants'}>
      {participants.length > 0 || groups.length > 0 ?
        <div>
          {
            participants.map((it, idx) =>
              <div key={idx} className='participant-view'>
                <ParticipantShortView participant={it} onDelete={() => onDeleteParticipant(idx)}
                                      onEdit={() => onEditParticipant(it)}/>
              </div>)
          }
          {
            groups.map((it, idx) =>
              <div key={idx} className='participant-view group-view'>
                <ParticipantGroupShortView group={it} onDelete={() => onDeleteGroup(idx)}/>
              </div>)
          }
        </div>
        :
        <div className={'no-participants'}>
          <div className="multi-selection-app-icon">
            <img src={iconMultiSelectionContacts} alt={''}/>
          </div>
          <h3>{t('KEINE TEILNEHMER')}</h3>
        </div>
      }
    </div>
  )
}
