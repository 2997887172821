import * as React from 'react';
import './Appointment.scss';
import {AccountDto, ParticipantDto} from "../../gen/client";
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import {ReactComponent as editIcon} from '../../img/icons/edit-icon.svg';
import {ReactComponent as deleteIcon} from '../../img/icons/trash-icon.svg';

interface ParticipantShortViewProps {
  participant: ParticipantDto | AccountDto;
  onEdit: () => void;
  onDelete: () => void;
}

export default function ParticipantShortView({ participant, onEdit, onDelete }: ParticipantShortViewProps) {
 
  return (
    <div>
      <div className='row'>
        <h3>{participant.name}</h3>
        <div className='group-actions'>
          {!participant.id && <Button type={'link'} className="icon-only"><Icon component={editIcon} alt={'edit'} onClick={onEdit}/></Button>}
          <Button type={'link'} className="icon-only del-btn"><Icon component={deleteIcon} alt={'delete'} onClick={onDelete} /></Button>
        </div>
      </div>
      <div className='contact-row'>
        {!!participant.phone && <div>{participant.phone}</div>}
        <div className={`${!!participant.phone ? 'left-spacing' : ''}`}>{participant.email}</div>
      </div>
    </div>
  )
}
