import * as React from 'react';
import './StartPageFooter.scss';
import {useTranslation} from "react-i18next";
import {NavLink, useHistory} from "react-router-dom";
import { ContentRoutes, isJoinRoute, isLoginRoute, isRegisterRoute } from '../../util/routes';
import { loggedMember } from '../../state/atoms';
import { useRecoilState } from 'recoil';

export default function StartPageFooter() {
  const { t } = useTranslation();
  const history = useHistory();
  const [logged] = useRecoilState(loggedMember);

  function getPrivacyLink(): ContentRoutes{
    return (isLoginRoute(history.location.pathname) || isRegisterRoute(history.location.pathname) || history.location.pathname === '/' || logged.logged) 
          ? ContentRoutes.DATA_PRIVACY_PROVIDER : ContentRoutes.PRIVACY
  }

  return (
    <footer className={'stp'}>
      <nav>
        <ul>
          <li><NavLink target="_blank" to={ContentRoutes.TERMS}> {t('Nutzungsbedingungen')}</NavLink></li>
          <li><NavLink target="_blank" to={getPrivacyLink()}> {t('Datenschutz')}</NavLink></li>
          <li><NavLink target="_blank" to={ContentRoutes.COPY}> {t('Impressum')}</NavLink></li>
          <li><NavLink target="_blank" to={ContentRoutes.FAQ}> {t('FSA')}</NavLink></li>
          {!isJoinRoute(history.location.pathname) &&
            <li><NavLink target="_blank" to={ContentRoutes.CONTACT}> {t('Contact')}</NavLink></li>}
        </ul>
      </nav>
    </footer>
  );
}
