import * as React from 'react';

export default function VideoSettingsSVG() {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M11.3,16.1h-7c-1.3,0-2.3-1-2.3-2.3V7c0-1.3,1-2.3,2.3-2.3h8.5c1.3,0,2.3,1,2.3,2.3v1.7c-0.3,0.2-0.5,0.5-0.5,0.8
        c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.7,0.1-0.9,0.3l0,0l0,0c-0.4,0.3-0.7,0.6-1,1c-0.4,0.5-0.5,1.2-0.1,1.8c-0.6,0.2-1.1,0.7-1.2,1.3
        c-0.1,0.4-0.1,0.9,0,1.4C10.6,15.5,10.9,15.9,11.3,16.1z M17.2,8.4c0.6,0.1,1.2,0.5,1.3,1.1c0.2-0.1,0.5-0.2,0.8-0.2
        c0.4,0,0.7,0.1,1,0.3c0.4,0.3,0.7,0.6,1,1c0.4,0.5,0.4,1.2,0.1,1.7c0.3,0.1,0.5,0.2,0.7,0.4V6.2c0-0.2-0.2-0.4-0.4-0.4h-0.9
        c-0.1,0-0.3,0-0.4,0.1l-3.8,2.4C16.7,8.3,17,8.3,17.2,8.4z M20.9,15.4C20.9,15.4,20.9,15.4,20.9,15.4L20.9,15.4
        c-0.4,0-0.7,0.2-0.8,0.5c-0.1,0.3,0,0.6,0.2,0.8c0.2,0.2,0.2,0.5,0.1,0.7c-0.2,0.3-0.5,0.6-0.8,0.8c-0.1,0.1-0.2,0.1-0.3,0.1
        c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.4-0.2-0.6-0.2c-0.1,0-0.2,0-0.3,0c-0.3,0.1-0.5,0.4-0.5,0.7c0,0.3-0.2,0.5-0.5,0.6
        c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0c-0.3,0-0.5-0.3-0.5-0.6c0-0.3-0.2-0.6-0.5-0.8c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0.1-0.6,0.2
        c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.3-0.2-0.6-0.5-0.8-0.8c-0.2-0.2-0.1-0.5,0.1-0.7c0.2-0.2,0.3-0.6,0.2-0.9
        c-0.1-0.3-0.4-0.5-0.7-0.5h0h0c-0.3,0-0.6-0.2-0.6-0.5c0-0.4,0-0.8,0-1.1c0-0.3,0.3-0.5,0.5-0.5c0,0,0,0,0,0c0,0,0,0,0,0
        c0.3,0,0.6-0.2,0.7-0.5c0.1-0.3,0-0.6-0.2-0.9c-0.2-0.2-0.2-0.5-0.1-0.7c0.2-0.3,0.5-0.6,0.8-0.8c0.1-0.1,0.2-0.1,0.3-0.1
        c0.2,0,0.3,0.1,0.4,0.2c0.2,0.2,0.4,0.2,0.6,0.2c0.1,0,0.2,0,0.3,0c0.3-0.1,0.5-0.4,0.5-0.7c0-0.3,0.2-0.5,0.5-0.6
        c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.6,0c0.3,0,0.5,0.3,0.5,0.6c0,0.3,0.2,0.6,0.5,0.8c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.6-0.3
        c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.2,0,0.3,0.1c0.3,0.2,0.6,0.5,0.8,0.8c0.2,0.2,0.1,0.5-0.1,0.7c-0.2,0.2-0.3,0.6-0.2,0.9
        c0.1,0.3,0.4,0.5,0.7,0.5l0,0c0.3,0,0.5,0.2,0.6,0.5c0,0.4,0,0.8,0,1.1C21.4,15.2,21.2,15.4,20.9,15.4z M18.1,14.3
        c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6C17.4,15.9,18.1,15.2,18.1,14.3z"/>
    </svg>
  )
}
