import {useEffect, useRef, useState} from "react";

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function useExitPrompt(active: boolean) {
  const [showExitPrompt, setShowExitPrompt] = useState(active);

  window.onload = function() {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt] as const;
}


const initBeforeUnLoad = (active: boolean) => {
  window.onbeforeunload = (event: BeforeUnloadEvent) => {
    if (active) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};

