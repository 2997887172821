import * as React from 'react';
import {useTranslation} from "react-i18next";
import {AccountGroupDto, AccountDto} from "../../gen/client";
import ParticipantShortView from './ParticipantShortView';
import iconMultiSelectionContacts from '../../img/icons/icon-multi-selection-contacts.svg';
import './Participants.scss';
import GroupShortView from '../group/GroupShortView';

interface AccountsProps {
  accounts: AccountDto[];
  groups: AccountGroupDto[];
  onEditAccount: (account: AccountDto) => void;
  onDeleteAccount: (idx: number) => void;
  onEditGroup: (group: AccountGroupDto) => void;
  onDeleteGroup: (idx: number) => void;
}

export default function Accounts({accounts, groups, onEditAccount, onDeleteAccount, onEditGroup, onDeleteGroup}: AccountsProps) {
  const {t} = useTranslation();

  return (
    <div className={'participants'}>
      {accounts.length > 0 || groups.length > 0 ?
        <div>
            {
              accounts.map((it, idx) =>
              <div key={idx} className='participant-view'>
                <ParticipantShortView participant={it} onDelete={() => onDeleteAccount(idx)} onEdit={() => onEditAccount(it)}/>
              </div>)
            }
            {
              groups.map((it, idx) =>
              <div key={idx} className='participant-view group-view'>
                <GroupShortView group={it} onDelete={() => onDeleteGroup(idx)} onEdit={() => onEditGroup(it)}/>
              </div>)
            }
        </div>
        :
        <div className={'no-participants'}>
          <div className="multi-selection-app-icon">
            <img src={iconMultiSelectionContacts} alt={''}/>
          </div>
          <h3>{t('KEINE TEILNEHMER')}</h3>
        </div>
      }
    </div>
  )
}
