import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import {env} from "./env/env";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
	lng: 'de',
    fallbackLng: 'de',
    debug: env.debug,
    backend: {
      loadPath: `${env.apiPath}/api/public/locales/{{lng}}.json`
    },
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    nsSeparator: false,
  }).then(() => {});

export default i18n;
