import * as React from 'react';
import './StartPage.scss';
import {useTranslation} from "react-i18next";
import {useState} from "react";
import regImage from '../../img/register-1.svg';
import mailImage from '../../img/register-2.svg';
import mailImageMobile from '../../img/register-mobile.svg';
import mainLogo from '../../img/noventi-video-logo.png';
import StartPageFooter from "./StartPageFooter";
import RegisterForm from "./RegisterForm";
import RegisterBenefits from "./RegisterBenefits";
import {NavLink} from "react-router-dom";
import {getApplicationSizeClass} from '../../util/utils';
import {useRecoilValue} from 'recoil';
import {Dimensions} from '../../util/enums';
import {applicationSize} from '../../state/atoms';
import { env } from '../../env/env';

const VIEW_REGISTER = 'reg';
const VIEW_REGISTER_SUCCESSFULLY = 'reg_succ';

export default function StartPage() {
  const {t} = useTranslation();

  const [view, setView] = useState(VIEW_REGISTER);
  const appSize = useRecoilValue<Dimensions>(applicationSize);

  function onRegisterSuccess() {
    setView(VIEW_REGISTER_SUCCESSFULLY);
  }

  return (
    <div className={`start-page ${getApplicationSizeClass(appSize)}`}>
      <div className={'main'}>
        <div className={'content'}>
          <div className={'data'}>
            <img className={'logo'} src={mainLogo} alt={'logo'}/>

            {view === VIEW_REGISTER && <>
              <h3>{t('Registriere dein Konto')}</h3>
              <h6>
                {t('Sie haben bereits ein Konto')}?&nbsp;
                <NavLink to={'/login'}>{t('Anmelden')}</NavLink>
              </h6>
              <RegisterForm onRegisterSuccess={onRegisterSuccess}/>
            </>}

            {view !== VIEW_REGISTER && <>
              <h3 className='title'>{t('Vielen Dank für Ihre Anmeldung bei NOVENTI Videosprechstunde')}</h3>
              <p>{t('Sie erhalten in Kürze eine Nachricht mit weiteren Informationen an die angegebene Mail-Adresse.')}</p>
              <p>{t('Zur Anmeldung gehen Sie bitte auf die Login-Seite')} <a href={env.basePath}>{env.basePath}</a></p>
              <p>{t('Sollten Sie keine E-Mail erhalten haben, dann schauen Sie zur Sicherheit bitte auch in Ihrem SPAM-Ordner nach.')}</p>

              <div>{t('Mit freundlichen Grüßen')}</div>
              <p>{t('Ihr NOVENTI HealthCare Team')}</p>
            </>}
          </div>
          {appSize === Dimensions.DESKTOP && <StartPageFooter/>}
        </div>
        {appSize !== Dimensions.DESKTOP && <StartPageFooter/>}
      </div>
      <div className={'hero'}>
        <div className={'ct'}>

          {view === VIEW_REGISTER && <>
            <img src={regImage} alt={''}/>
            <RegisterBenefits/>
          </>}

          {view !== VIEW_REGISTER &&
           <img src={appSize === Dimensions.DESKTOP ? mailImage : mailImageMobile} className={'mail'} alt={''}/>}
        </div>
      </div>
    </div>
  );
}
