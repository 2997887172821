import * as React from 'react';
import './Contacts.scss';
import {useTranslation} from "react-i18next";
import Container from '../general/Container';
import {Tabs} from 'antd';
import Contacts from './Contacts';
import Groups from '../group/Groups';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isGroupRoute, Routes } from '../../util/routes';
import { ActiveTab } from '../../util/enums';

const {TabPane} = Tabs;
interface ContactTabsProps {
  onRouteChanged: (route: string) => void;
}

export default function ContactTabs({onRouteChanged}: ContactTabsProps) {
  const {t} = useTranslation();
  const history = useHistory();

  const [activeKey, setActiveKey] = useState(ActiveTab.FIRST);

  useEffect(() => {
    setActiveKey(isGroupRoute(history.location.pathname) ? ActiveTab.SECOND : ActiveTab.FIRST);
  }, [history.location.pathname]);

  function routeChanged(key: ActiveTab) {
    setActiveKey(key);
    history.push(key === ActiveTab.FIRST ? Routes.CONTACTS : Routes.GROUPS);
    onRouteChanged(key === ActiveTab.FIRST ? 'p-kon' : 'p-gro');
  }
  
  return (
    <Container className={'settings full contact-tabs-container'}>
      <div className={'content'}>
        <Tabs activeKey={activeKey} onChange={key => routeChanged(key as ActiveTab)}>
          <TabPane tab={t('Kontakte')} key={ActiveTab.FIRST} className='tab-content'>
            <Contacts/>
          </TabPane>
          <TabPane tab={t('Gruppen')} key={ActiveTab.SECOND} className='tab-content'>
            <Groups/>
          </TabPane>
        </Tabs>
      </div>
    </Container>
  )
}
