import * as React from 'react';
import { MouseEventHandler } from 'react';
import './StatisticTile.scss';
import { Card } from 'antd';
import { hex2rgba } from '../../util/utils';

export interface StatisticTileProps {
  text?: string;
  subtext?: string;
  icon?: any;
  click?: MouseEventHandler;
  url?: {
    url: string,
    urlText?: string
  };
}

export default function StatisticTile({text, subtext, icon, click, url}: StatisticTileProps) {

  return (
    <Card size={'small'} className={click ? 'statistic-tile clickable' : 'statistic-tile'} onClick={click}>
      <div>
        <h1>{text}</h1>
        <div>{subtext} {url ? <span><span>{url.urlText}</span>
          <svg className={'triangle'} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24"
               enableBackground="new 0 0 24 24">
              <path id="Polygon_25" d="M15,12l-6,4V8L15,12z"/>
            </svg></span> : null}</div>
      </div>
      <div className={'icon-container'}>
        <div className={'icon'} style={{backgroundColor: hex2rgba(icon.props?.style.fill || '#FFFFFF', 0.2)}}>
          {icon}
        </div>
      </div>

    </Card>
  )

}
