import * as React from 'react';
import './RegisterBenefits.scss';
import {useTranslation} from "react-i18next";
import { getApplicationSizeClass } from '../../util/utils';
import { useRecoilValue } from 'recoil';
import { Dimensions } from '../../util/enums';
import { applicationSize } from '../../state/atoms';

export default function RegisterBenefits() {
  const {t} = useTranslation();
  const appSize = useRecoilValue<Dimensions>(applicationSize);

  return (
    <div className={`reg-ben ${getApplicationSizeClass(appSize)}`}>
      <h2>{t('Das Komplettpaket für Ihre Praxis')}</h2>
      <ul className={'review'}>
        <li>
          <svg viewBox="0 0 50 50">
            <path id="Subtraction_4" d="M39.6,42.5H10.4c-1.4,0-2.8-0.6-3.8-1.6c-1-1-1.6-2.3-1.6-3.8V16c0-1.4,0.6-2.8,1.6-3.8 c1-1,2.4-1.6,3.8-1.6h3.9V8.8c0-0.7,0.6-1.3,1.3-1.3S17,8.1,17,8.8v1.8h16V8.8c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3v1.8h3.9 c3,0,5.4,2.4,5.5,5.4v21.1c0,1.4-0.6,2.8-1.6,3.8C42.4,41.9,41,42.5,39.6,42.5z M18.3,24.9c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h7.4 c1.1,0,2-0.9,2-2v-6c0-1.1-0.9-2-2-2H18.3z M32.6,25.9c-0.1,0-0.3,0-0.4,0.1l-3.4,2.1c-0.1,0.1-0.2,0.2-0.2,0.3v3 c0,0.1,0.1,0.2,0.2,0.3l3.4,2.1c0.1,0.1,0.2,0.1,0.4,0.1h0.8c0.2,0,0.3-0.1,0.3-0.3c0,0,0,0,0,0v-7.3c0-0.2-0.2-0.3-0.3-0.3 c0,0,0,0,0,0L32.6,25.9z M10.4,13.3c-1.5,0-2.7,1.2-2.8,2.7v3.4h34.7V16c0-1.5-1.3-2.7-2.8-2.7H10.4z"/>
          </svg>
          <p>{t('Zertifizierte Videosprechstunde')}</p>
        </li>
        <li>
          <svg viewBox="0 0 50 50">
            <path id="Path_345" d="M48.1,21h-6.6c-1.1,0-2,0.9-1.9,2v14.9c0,1.1,0.9,2,1.9,2h6.6c1.1,0,2-0.9,1.9-2V23.1 C50,22,49.2,21.1,48.1,21C48.1,21,48.1,21,48.1,21z M45.6,39.1h-1.9c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5c0,0,0,0,0.1,0 h1.9c0.3,0,0.5,0.2,0.5,0.5C46.1,38.8,45.9,39.1,45.6,39.1C45.6,39.1,45.6,39.1,45.6,39.1z M48.5,37.2H41V23.8h7.6L48.5,37.2 L48.5,37.2z M41.8,14.1C41.5,14,41.3,14,41,14H27.4c-0.3,0-0.5,0.1-0.8,0.2c-0.7,0.3-1.2,1.1-1.2,1.9v21.8c0,1.1,0.9,2.1,2,2.1 c0,0,0,0,0,0h11.2c-0.4-0.6-0.6-1.3-0.6-2v-1.2H27.3V16.8h13.8v2.7H43v-3.1l0,0V16C43,15.2,42.5,14.5,41.8,14.1z M32.8,38 c0-0.1,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.4-0.2h1.9c0.2,0,0.4,0.1,0.5,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3 c-0.1,0.2-0.3,0.3-0.5,0.3h-1.9c-0.3,0-0.5-0.2-0.5-0.5C32.8,38.2,32.8,38.1,32.8,38z M31.8,12.4v0.2H2.5v17.6H24v5h-4.5 c0,0.5,0.3,2.6,3.4,3h-0.2c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9c0,0,0,0-0.1,0H9.1c-0.5,0-0.9-0.4-0.9-0.9 c0-0.5,0.4-0.9,0.9-0.9c0,0,0,0,0.1,0H9c3.1-0.4,3.4-2.5,3.4-3H2.1c-1.1,0-2-0.9-2.1-2l0,0V12.4C0,11.1,1,10,2.3,10h27.2 C30.8,10,31.8,11,31.8,12.4C31.8,12.3,31.8,12.3,31.8,12.4z"/>
          </svg>
          <p>{t('Nutzbar auf allen Endgeräten')}</p>
        </li>
        <li>
          <svg viewBox="0 0 50 50">
            <path d="M19.7,20.8l-0.3-0.1l-4,2.5l1.2-3.5l-0.5-0.3c-2.2-1.2-3.6-3.5-3.7-6.1c0-4.3,4.4-7.7,9.7-7.7c3.3-0.1,6.5,1.4,8.5,4 c0.7,0,1.3,0.1,2,0.2C30.9,6.4,26.8,4,22.1,4c-6.2,0-11.3,4.2-11.3,9.3c0.1,2.9,1.5,5.5,3.9,7.1L12.4,27l7.3-4.5 c0.8,0.1,1.6,0.2,2.3,0.2c0.2,0,0.5,0,0.7,0c-0.5-0.5-0.9-1-1.2-1.6C21,21,20.3,20.9,19.7,20.8z M30.3,11c-4.5,0-8.1,3-8.1,6.7 s3.6,6.7,8.1,6.7c0.8,0,1.6-0.1,2.4-0.3l6.3,2.8l-2.6-4.8c1.2-1.1,1.9-2.7,2-4.4C38.5,14.1,34.8,11,30.3,11z M35.4,23.6l-2.6-1.1 l-0.3,0.1c-0.7,0.2-1.5,0.3-2.2,0.3c-3.6,0-6.5-2.3-6.5-5.1s2.9-5.1,6.5-5.1s6.5,2.3,6.5,5.1c0,1.4-0.7,2.7-1.9,3.6l-0.5,0.4 L35.4,23.6z M26.5,17.1c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9C25.6,17.5,26,17.1,26.5,17.1z M30.3,17.1c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9C29.5,17.5,29.8,17.1,30.3,17.1z M34.1,17.1 c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9C33.3,17.5,33.7,17.1,34.1,17.1z M19.8,44.9c0-0.1,0-0.3,0-0.4 c0-3.5-3.6-6.4-7.9-6.4S4,41,4,44.5c0,0.1,0,0.3,0,0.4L4.1,46h15.6L19.8,44.9z M6.3,43.8c0.5-1.9,2.8-3.3,5.6-3.3s5.1,1.4,5.6,3.3 H6.3z M30.2,44.5c0,0.1,0,0.3,0,0.4l0.1,1.1h15.6l0.1-1.1c0-0.1,0-0.3,0-0.4c0-3.5-3.6-6.4-7.9-6.4S30.2,41,30.2,44.5z M38.1,40.4 c2.8,0,5.1,1.4,5.6,3.3H32.5C33,41.9,35.3,40.4,38.1,40.4z M41.7,32.5c-0.3-2.2-2.2-3.8-4.5-3.6c-2.2,0.3-3.8,2.2-3.5,4.5 c0.2,2,2,3.6,4,3.6C40.1,36.9,41.9,34.9,41.7,32.5z M36,32.5c0.2-1,1.2-1.6,2.2-1.3c1,0.2,1.6,1.2,1.3,2.2c-0.2,0.8-0.9,1.4-1.8,1.4 C36.6,34.6,35.9,33.6,36,32.5z M16.7,32.5c-0.3-2.2-2.2-3.8-4.5-3.6c-2.2,0.3-3.8,2.2-3.5,4.5c0.2,2,2,3.6,4,3.6 C15.1,36.9,16.9,34.9,16.7,32.5z M11,32.5c0.2-1,1.2-1.6,2.2-1.3c1,0.2,1.6,1.2,1.3,2.2c-0.2,0.8-0.9,1.4-1.8,1.4 C11.6,34.6,10.9,33.6,11,32.5z"/>
          </svg>
          <p>{t('Persönlicher Support bei Fragen')}</p>
        </li>
        <li>
          <svg viewBox="0 0 50 50">
            <path d="M33.5,12.5c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1C32.4,13,32.9,12.5,33.5,12.5z M33.5,26.1 c0.6,0,1.1-0.5,1.1-1.1v0v-6.8c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1V25C32.4,25.6,32.9,26.1,33.5,26.1L33.5,26.1L33.5,26.1 z M11.4,13.6h8c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1h-8c-0.6,0-1.1,0.5-1.1,1.1C10.2,13.1,10.7,13.6,11.4,13.6z M11.4,18.2 h8c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1h-8c-0.6,0-1.1,0.5-1.1,1.1C10.2,17.7,10.7,18.2,11.4,18.2z M11.4,22.7h8 c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1h-8c-0.6,0-1.1,0.5-1.1,1.1C10.2,22.2,10.7,22.7,11.4,22.7z M11.4,27.3h8 c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1h-8c-0.6,0-1.1,0.5-1.1,1.1C10.2,26.8,10.7,27.3,11.4,27.3z M48.9,9.4h-3.4V6.1 c0-0.6-0.5-1.1-1.1-1.1c0,0,0,0,0,0H29.5c-1.8,0-3.5,0.8-4.5,2.3C23.9,5.8,22.3,5,20.5,5H5.7C5.1,5,4.5,5.5,4.5,6.1v3.3H1.1 C0.5,9.4,0,9.9,0,10.6c0,0,0,0,0,0v26.7c0,0.6,0.5,1.1,1.1,1.1c0,0,0,0,0,0h11.4c6.2,0,8.2,1.8,11.6,6.2c0.4,0.5,1.1,0.6,1.6,0.2 c0.1-0.1,0.2-0.1,0.2-0.2c3.5-4.6,5.5-6.2,9.3-6.2h13.6c0.6,0,1.1-0.5,1.1-1.1v0V10.6C50,9.9,49.5,9.4,48.9,9.4L48.9,9.4L48.9,9.4z M29.5,7.2h13.6v24.4H30.7c-1.7,0.1-3.4,0.8-4.5,2.1V10.6C26.1,8.7,27.7,7.2,29.5,7.2z M6.8,7.2h13.6c1.9,0,3.4,1.5,3.4,3.3v23.2 c-1.2-1.3-2.8-2-4.5-2.1H6.8L6.8,7.2z M47.7,36.1H35.2c-4.8,0-7.3,2.3-10.2,5.9c-3-3.7-5.8-5.9-12.5-5.9H2.3V11.7h2.3v21.1 c0,0.6,0.5,1.1,1.1,1.1h0h13.6c1.8,0,3.7,2,4.7,3.8c0.2,0.4,0.6,0.6,1,0.6c0.1,0,0.2,0,0.3,0c0.3-0.1,0.6-0.3,0.7-0.6l0,0 c0.9-1.9,2.7-3.8,4.7-3.8h13.6c0.6,0,1.1-0.5,1.1-1.1v0V11.7h2.3V36.1z"/>
          </svg>
          <p>{t('Schritt für Schritt-Anleitung für Ihre Patienten')}</p>
        </li>
      </ul>
    </div>
  );
}
