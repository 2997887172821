import * as React from 'react';
import './GroupLine.scss';
import {AccountDto, AccountDtoGenderEnum} from "../../gen/client";
import Icon from '@ant-design/icons';
import {ReactComponent as femaleIcon} from '../../img/icons/gender-female-icon.svg';
import {ReactComponent as maleIcon} from '../../img/icons/gender-male-icon.svg';
import {ReactComponent as diversIcon} from '../../img/icons/gender-divers-icon.svg';

interface GroupContactLineProps {
  contact: AccountDto;
}

export default function GroupContactLine({contact}: GroupContactLineProps) {

  return (
    <div className={'vd-line'}>
      <div className='col name'>
        <div className='contact-name'>
          <span>{contact.name}</span>
          <Icon className={`icon-only gender ${contact.gender === AccountDtoGenderEnum.Female ? 'female-icon' : contact.gender === AccountDtoGenderEnum.Male ? 'male-icon' : 'divers-icon'}`}
                component={contact.gender === AccountDtoGenderEnum.Female ? femaleIcon : contact.gender === AccountDtoGenderEnum.Male ? maleIcon : diversIcon} alt={''}/>
        </div>
      </div>
      <span className='col phone'>{contact.phone}</span>
      <span className='col email'>{contact.email}</span>
    </div>
  )
}
