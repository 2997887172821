/**
* This file is generated
*/
import * as React from 'react';
import { CSSProperties } from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24" xmlSpace="preserve"><path d="M12,22C6.5,22,2,17.5,2,12C2,6.5,6.5,2,12,2c5.5,0,10,4.5,10,10C22,17.5,17.5,22,12,22z M12,3.1c-4.9,0-8.9,4-8.9,8.9
	s4,8.9,8.9,8.9s8.9-4,8.9-8.9S16.9,3.1,12,3.1z M12.8,11.7L15.5,9c0.2-0.2,0.2-0.6,0-0.8c-0.2-0.2-0.6-0.2-0.8,0l-2.6,2.6L9.4,8.3
	c-0.2-0.2-0.6-0.2-0.8,0C8.5,8.5,8.5,8.8,8.7,9l2.6,2.6l-2.6,2.6c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2
	l2.6-2.6l2.6,2.6c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.6,0-0.8L12.8,11.7z"/></svg>
);

const ErrorIcon = (props: { style?: CSSProperties, className?: string }) => <Icon component={svg} {...props}/>;

export default ErrorIcon;