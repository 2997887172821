import { GeneralStatisticsDto, MeetingsDailyStatisticsDto, MeetingsDurationDailyStatisticsDto, MeetingsStatisticsDto } from "../gen/client";
import {ChartDataSets} from "chart.js";
import moment, {Moment} from "moment";
import {fromTimestamp} from "../util/utils";

export function generateMeetingsStatisticsDataSets(meetingStatistics: MeetingsDailyStatisticsDto[], from: Moment, thru: Moment): ChartDataSets[] {
  return [
    generateDailyScheduledMeetingsDataset(meetingStatistics, from, thru),
    generateDailyPerformedMeetingsDataset(meetingStatistics, from, thru)
  ];
}

function generateDailyScheduledMeetingsDataset(statistics: MeetingsDailyStatisticsDto[], from: Moment, thru: Moment): ChartDataSets {
  const data = [];
  const diff = thru.diff(from, 'day');
  const now = moment().startOf('day');

  for (let i = 0; i <= diff; i++) {
    const dayAsMoment = moment(from).add(i, 'day').startOf('day');
    if (dayAsMoment.isBefore(now) || dayAsMoment.isSame(now)) {
      const entry = statistics.find(it => fromTimestamp(it.date).startOf('day').isSame(dayAsMoment));

      if (!!entry) {
        data.push(entry.scheduledMeetings);
      } else {
        data.push(0);
      }
    }
  }

  return {
    label: 'Erstellte Termine',
    lineTension: 0.01,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderColor: '#FF5F43',
    data,
    yAxisID: 'count'
  }
}

function generateDailyPerformedMeetingsDataset(statistics: MeetingsDailyStatisticsDto[], from: Moment, thru: Moment): ChartDataSets {
  const data = [];
  const diff = thru.diff(from, 'day');
  const now = moment().startOf('day');

  for (let i = 0; i <= diff; i++) {
    const dayAsMoment = moment(from).add(i, 'day').startOf('day');
    if (dayAsMoment.isBefore(now) || dayAsMoment.isSame(now)) {
      const entry = statistics.find(it => fromTimestamp(it.date).startOf('day').isSame(dayAsMoment));

      if (!!entry) {
        data.push(entry.performedMeetings);
      } else {
        data.push(0);
      }
    }
  }

  return {
    label: 'Durchgeführte Termine',
    lineTension: 0.01,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderColor: '#24BF30',
    data,
    yAxisID: 'count'
  }
}

export function generateMeetingsDurationStatisticsDataSets(meetingStatistics: MeetingsDurationDailyStatisticsDto[], from: Moment, thru: Moment): ChartDataSets[] {
  const data = [];
  const diff = thru.diff(from, 'day');
  const now = moment().startOf('day');
  for (let i = 0; i <= diff; i++) {
    const dayAsMoment = moment(from).add(i, 'day').startOf('day');
    if (dayAsMoment.isBefore(now) || dayAsMoment.isSame(now)) {
      const entry = meetingStatistics.find(it => fromTimestamp(it.date).startOf('day').isSame(dayAsMoment));

      if (!!entry) {
        data.push(Math.round(entry.effectiveDuration * 100) / 100);
      } else {
        data.push(0);
      }
    }
  }

  return [{
    label: 'Min Gesprächsdauer',
    lineTension: 0.01,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderColor: '#24BF30',
    data,
    yAxisID: 'count'
  }];
}

export const GENERAL_STATISTICS: GeneralStatisticsDto = {
  totalAccounts: 0,
  totalCallsDuration: 0,
  totalCallsScheduled: 0,
  totalCallsPerformed: 0,
  totalAnswersCount: 0
};

export const MEETINGS_STATISTICS: MeetingsStatisticsDto = {
  totalMeetingsScheduled: 0,
  totalMeetingsPerformed: 0,
  totalEffectiveDuration: 0,
  dailyStatistics: [],
  effectiveDurationDailyStatistics: [],
  from: 0,
  thru: 0
};
