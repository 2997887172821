import * as React from 'react';
import './QuestionResponses.scss';
import { Rate } from 'antd';
import { CmsQuestionDtoTypeEnum, CmsQuestionDto, LightCmsAnswerDto } from '../../gen/client';
import { Button } from 'antd';
import Icon from '@ant-design/icons/';
import {ReactComponent as backIcon} from '../../img/icons/back-icon.svg';
import { formatDate, fromTimestamp } from '../../util/utils';
interface QuestionResponsesProps {
  question: CmsQuestionDto;
  answers: LightCmsAnswerDto[];
  onCancel: () => void;
}

export default function QuestionResponses({question, answers, onCancel}: QuestionResponsesProps) {

  function getAnswerLine(answer: LightCmsAnswerDto){
    return (
      <div className='vd-line rating-line' key={answer.id}>
        <div className='col-1'>{answer.participantPublicId || answer.memberPublicId}</div>
        <div className='date-column'>{formatDate(fromTimestamp(answer.createdDate))}</div>
        {question?.type === CmsQuestionDtoTypeEnum.Rating &&
          <div className='col-2'><Rate defaultValue={parseInt(answer.answer)} disabled/></div>
        }
        {(question?.type === CmsQuestionDtoTypeEnum.Paragraph || question?.type === CmsQuestionDtoTypeEnum.ShortAnswer) &&
          <div className='col-2'>{answer.answer}</div>
        }
      </div>
    )
  }

  return (
    <div className='raiting-resp'>
      <div className={'info-header'}>
        <Button type="link" className='back-btn' onClick={onCancel}><Icon component={backIcon}/></Button>
        <h3>{question?.label}</h3>
      </div>
      <div className='responses-list'>
        {
          answers?.map(it => getAnswerLine(it))
        }
      </div>
    </div>
  )
}
