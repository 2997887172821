import * as React from 'react';
import './Chat.scss';
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import {Button, Input, message as msg, Modal} from 'antd';
import {ReactComponent as closeThickIcon} from "../../img/icons/close-thick-icon.svg"
import sendIcon from "../../img/icons/send-message-icon.svg";
import {downloadFileUsingBlob, getNameByMeetingWSId, prettyFileSize} from '../../util/utils';
import attachmentIcon from '../../img/icons/attachment-icon.svg';
import Icon from '@ant-design/icons';
import {MeetingInfo} from '../../util/types';
import {MeetingDto, PublicMeetingDto} from '../../gen/client';
import {ReactComponent as FileIcon} from '../../img/icons/file-icon.svg';
import moment from 'moment';

export interface Message {
  yours: boolean;
  value?: string;
  attachmentName?: string;
  attachmentSize?: number;
  attachment?: Blob,
  from?: string,
  senderName?: string,
  time?: number
}

interface ChatProps {
  messages: Message[];
  meetingInfo: MeetingInfo;
  meeting: MeetingDto | PublicMeetingDto;
  onSendMessage: (message: Message) => void;
  onSendFile: (file: File) => void;
  toggleDetailsView: () => void;
  onReadMessages: () => void;
}

const {confirm} = Modal;

export default function Chat({messages, meetingInfo, meeting, onSendMessage, onSendFile, toggleDetailsView, onReadMessages}: ChatProps) {
  const {t} = useTranslation();

  const container = useRef(null);
  const [message, setMessage] = useState(null as string);

  useEffect(() => {
    //container.current.scrollTop = container.current.scrollHeight;
    let chatWrapper = document.getElementById('chat-wrapper');
    chatWrapper.scrollTo(0, chatWrapper.scrollHeight);
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [message]);

  function scrollToBottom() {
    if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);
    }
  }

  function sendMessage() {
    if (message) {
      onSendMessage({yours: true, value: message, time: moment().unix()});
      setMessage(null);
    }
  }

  function onFileChange(files: FileList) {
    const newFile = files[0];
    if (!newFile) {
      msg.warn('No file chosen');
    } else {
      onSendFile(newFile);
    }
  }

  function triggerFileInput() {
    document.getElementById('send-file').click();
  }

  function downloadAttachment(blob: Blob, name: string) {
    if (blob.size) {
      downloadFileUsingBlob(blob, name);
    }
  }

  function getMessageSender(mess: Message) {
    if (!mess.senderName) {
      mess.senderName = getNameByMeetingWSId(meeting, meetingInfo, mess.from);
    }
    return mess.senderName;
  }

  function onDownloadAttachment(mess: Message) {
    if (mess.attachment.size && !mess.yours) {
      confirm({
        title: t('DOWNLOAD_ATTACHMENT_POPUP_TITLE', {name: getMessageSender(mess)}),
        content: <>
					<div>
						{t('DOWNLOAD_ATTACHMENT_POPUP_CONTENT')}
					</div>
					<div className={'attachment'}>
						<Icon component={FileIcon} alt=""/>
						<div className={'file-info'}>
							<div className={'file-name'}>{mess.attachmentName}</div>
							<div className={'file-size'}>({mess.attachment.size ? prettyFileSize(mess.attachmentSize) : t('Removed')})</div>
						</div>
					</div>
        </>,
        okText: t('Ja'),
        cancelText: t('Nein'),
        onOk() {
          downloadAttachment(mess.attachment, mess.attachmentName);
        }
      });
    }
  }

  function getMessageElement(mess: Message, idx: number): JSX.Element {
    if (mess.value) {
      return <div className={`message-item ${mess.yours ? 'sent' : 'received'}`} key={idx}>
        <span className={'message-sender'}>{!mess.yours && `${getMessageSender(mess)}`}</span>
        <span className={'message-text'}>{mess.value}</span>
        <span className={'message-time'}>{moment.unix(mess.time).format("HH:mm")}</span>
      </div>
    }
    if (mess.attachment) {
      return <div className={`message-item ${mess.yours ? 'sent' : 'received'} attachment`} key={idx} onClick={
        () => onDownloadAttachment(mess)
      }>
        <span className={'message-sender'}>{!mess.yours && `${getMessageSender(mess)}: `}</span>
        <span className={'attachment'}>
          <Icon component={FileIcon} alt=""/>
          <div className={'file-info'}>
            <div className={'file-name'}>{mess.attachmentName}</div>
            <div className={'file-size'}>({mess.attachment.size ? prettyFileSize(mess.attachmentSize) : t('Removed')})</div>
          </div>
        </span>
        <span className={'message-time'}>{moment.unix(mess.time).format("HH:mm")}</span>
      </div>
    }
    return null;
  }

  return (
    <>
      <div className="chat" id="chat-wrapper">
        <div className="message-list" ref={container}>
          {messages?.map((it, idx) => getMessageElement(it, idx))}
        </div>
      </div>
      <div className='new-message-wrapper'>
      <div className='new-message-container' onClick={onReadMessages}>
        <Input onChange={ev => setMessage(ev.target.value)} onPressEnter={() => sendMessage()}
               value={message}
               placeholder={t('Nachricht eingeben')}
               suffix={
                 <div>
                   <Button type={'link'} size={'large'} className='more' onClick={() => sendMessage()}>
                    <img src={sendIcon} alt={''}/>
                   </Button>
                   <Button type={'link'} size={'large'} className='more' onClick={() => triggerFileInput()}>
                    <img src={attachmentIcon} alt={''}/>
                   </Button>
                   <input type="file" name="file" id="send-file" hidden onChange={(e) => onFileChange(e.target.files)}/>
                 </div>
               }
        />
      </div>
      <Button type={'link'} size={'large'} className='close-chat-button' onClick={toggleDetailsView}>
        <Icon component={closeThickIcon} alt={''}/>
      </Button>
      </div>
    </>
  )
}
