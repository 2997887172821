import * as React from 'react';
import './Appointments.scss';
import { Switch } from "antd";
import { useTranslation } from "react-i18next";
import { MeetingDto } from "../../gen/client";
import { formatDate, formatTime, fromTimestamp } from '../../util/utils';
import Icon from '@ant-design/icons';
import {ReactComponent as userIcon}  from '../../img/icons/user-icon.svg';
import {ReactComponent as iconMultiSelectionContacts} from '../../img/icons/icon-multi-selection-contacts.svg';

interface AppointmentDeleteCardProps {
  meeting: MeetingDto;
  notify: boolean;
  onNotifyChange: () => void;
}

export default function AppointmentDeleteCard({meeting, notify, onNotifyChange}: AppointmentDeleteCardProps) {
  const { t } = useTranslation();

  return (
    <div className='all-ntf'>
      <h3 className='title'>{t('Möchten Sie die termin wirklich löschen?')}</h3>
      <div className={'cards'}>
          <div className='gridStyle' key={meeting?.id}>
            <div className={'dtm'}>{formatDate(fromTimestamp(meeting?.startDate))} {formatTime(fromTimestamp(meeting?.startDate))}</div>
            <div>{
              meeting?.participants.map(it => (
                <div key={it.id}>
                  <Icon component={userIcon} alt={''} />&nbsp;<span>{it.name}&nbsp;</span>
                </div>
              ))}
            </div>
            <div>{
              meeting?.groups.map(it => (
                <div key={it.id}>
                  <Icon component={iconMultiSelectionContacts} alt={''} />&nbsp;<span>{it.name}&nbsp;</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='label switch-wrapper'>
          <Switch defaultChecked={notify} onChange={onNotifyChange} />
          <span>{t('Absage-Meldung an die Teilnehmer senden')}</span>
        </div>
    </div>
  )
}
