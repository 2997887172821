import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button} from "antd";
import {useTranslation} from "react-i18next";
import {CameraPosition, MeetingInfo, MeetingSession, RemoteStream} from "../../util/types";
import Icon from "@ant-design/icons";
import {ReactComponent as microphoneIcon} from "../../img/icons/microphone-icon.svg";
import {ReactComponent as microphoneIconMuted} from "../../img/icons/microphone-mute-icon.svg";
import Avatar from "../general/Avatar";
import {getNameByMeetingWSId, getOwnName} from "../../util/utils";
import Storage from "../../util/Storage";
import {attachSinkId} from "../../service/DeviceService";
import {MeetingDto, MeetingDtoTypeEnum, PublicMeetingDto} from "../../gen/client";
import {
  audioMutedState,
  cameraBrokenState,
  cameraOffState,
  cameraPositionState,
  meetingInfoState,
  meetingOwnerState,
  meetingTypeState,
  presentationModeState,
  remoteStreamsState,
  shareScreenState,
  videoTracksState
} from "../../state/atoms";
import {useRecoilState, useRecoilValue} from "recoil";
import {fitVideosToFullScreen} from "../../service/MeetingService";

interface MeetingStreamsProps {
  preview: boolean;
  meetingSessions: MeetingSession[];
  participantJoined: boolean;
  participantPreview: boolean;
  meeting: MeetingDto | PublicMeetingDto;
  onVideoMetadataLoaded: () => void;
  onCancel: () => void;
}

export default function MeetingStreams({
                                         preview,
                                         meetingSessions,
                                         participantJoined,
                                         participantPreview,
                                         meeting,
                                         onVideoMetadataLoaded,
                                         onCancel
                                       }: MeetingStreamsProps) {
  const {t} = useTranslation();

  const [cameraOff] = useRecoilState(cameraOffState);
  const [cameraBroken] = useRecoilState(cameraBrokenState);
  const [meetingOwner] = useRecoilState(meetingOwnerState);
  const [shareScreen] = useRecoilState(shareScreenState);
  const [audioMuted] = useRecoilState(audioMutedState);
  const [presentationMode] = useRecoilState(presentationModeState);
  const [cameraPosition] = useRecoilState(cameraPositionState);
  const [localVideoTracks] = useRecoilState(videoTracksState);
  const [meetingInfo] = useRecoilState(meetingInfoState);
  const [remoteStreams] = useRecoilState(remoteStreamsState);
  const meetingType = useRecoilValue(meetingTypeState);
  const [isOneOnOneRemoteSharingScreen, setOneOnOneRemoteSharingScreen] = useState(false);

  const onLocalVideoRef = useCallback((video: HTMLVideoElement) => {
    if (video && !!localVideoTracks.length && (video.srcObject === null || (video.srcObject as MediaStream).getVideoTracks()[0].id !== localVideoTracks[0].id)) {
      video.srcObject = new MediaStream(localVideoTracks);
    }
  }, [localVideoTracks]);

  const onRemoteVideoRef = useCallback((video: HTMLVideoElement, r: RemoteStream) => {
    if (video && (video.srcObject === null || (video.srcObject as MediaStream).id !== r.stream.id)) {
      video.srcObject = r.stream;

      if (!!Storage.getSpeakerId(meeting.publicId)) {
        attachSinkId(video, Storage.getSpeakerId(meeting.publicId));
      }
    }
  }, [meeting.publicId]);

  const patientLeftTheMeeting = useMemo(() => {
    return !preview && meetingSessions.length === 1 && participantJoined;
  }, [preview, participantJoined, meetingSessions.length]);

  const meetingNotStarted = useMemo(() => {
    return (!preview && meetingSessions.length === 1 && !participantJoined) || participantPreview;
  }, [preview, participantJoined, participantPreview, meetingSessions.length]);

  const selfHasCameraOff = useMemo(() => {
    return cameraOff || cameraBroken || (localVideoTracks.length === 0) || (localVideoTracks.length === 1 && localVideoTracks[0].muted);
  }, [cameraOff, cameraBroken, localVideoTracks]);

  const isOneOnOneView = useMemo(() => {
    return meetingType === MeetingDtoTypeEnum.Single || (meetingType === MeetingDtoTypeEnum.Multicast && !meetingOwner && !presentationMode)
  }, [meetingType, meetingOwner, presentationMode]);

  const localVideoElemClass = useMemo(() => {
    return `video remote ${!shareScreen ? 'mirror' : ''} ${shareScreen || isOneOnOneRemoteSharingScreen ? 'screen-sharing' : ''} ${cameraPosition !== CameraPosition.USER ? 'environment-facing' : ''}`;
  }, [shareScreen, cameraPosition, isOneOnOneRemoteSharingScreen]);

  const remoteVideoElemClass = useCallback((r: RemoteStream) => {
    return `video remote ${r.screenSharing ? 'screen-sharing' : ''} ${r.cameraPosition !== CameraPosition.USER ? 'environment-facing' : ''}`;
  }, []);

  const ownName = useMemo(() => {
    return getOwnName(meeting, meetingSessions);
  }, [meetingSessions, meeting]);

  useEffect(() => {
    setOneOnOneRemoteSharingScreen(isOneOnOneView && remoteStreams && remoteStreams.length && remoteStreams[0].screenSharing);
  }, [isOneOnOneView, remoteStreams]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fitVideosToFullScreen(null, null, true);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    }
  }, [isOneOnOneRemoteSharingScreen]);

  const localVideo = useMemo(() => (
    <div className={`local-video-wrapper ${selfHasCameraOff ? 'camera-off' : ''}`} id={'localVideo-wrapper'} key={'local'}>
      <div className="name">
        <span className='full-name'>{t('You')}</span>
        {!audioMuted && <Icon component={microphoneIcon}/>}
        {audioMuted && <Icon component={microphoneIconMuted} className={'muted'}/>}
      </div>

      <video playsInline={true} autoPlay={true} id={'localVideo'} muted className={localVideoElemClass} onLoadedMetadata={onVideoMetadataLoaded}
             ref={onLocalVideoRef}/>

      {selfHasCameraOff && <Avatar name={ownName}/>}
    </div>
  ), [audioMuted, t, ownName, selfHasCameraOff, onVideoMetadataLoaded, localVideoElemClass, onLocalVideoRef]);

  const remoteVideo = useMemo(() => (
    sortStreams(meeting, remoteStreams, meetingInfo).map(it =>
      <div className={`local-video-wrapper ${remoteHasCameraOff(it) ? 'camera-off' : ''}`} id={`remoteVideo-${it.id}-wrapper`} key={`${it.id}`}>
        <div className="name">
          <span className='full-name'>{getNameByMeetingWSId(meeting, meetingInfo, it.id)}</span>
          {!it.audioMuted && <Icon component={microphoneIcon}/>}
          {it.audioMuted && <Icon component={microphoneIconMuted} className={'muted'}/>}
        </div>

        <video playsInline={true} autoPlay={true} id={`remoteVideo-${it.id}`}
               className={remoteVideoElemClass(it)}
               onLoadedMetadata={onVideoMetadataLoaded} ref={(v) => onRemoteVideoRef(v, it)}/>

        {remoteHasCameraOff(it) && <Avatar name={getNameByMeetingWSId(meeting, meetingInfo, it.id)}/>}
      </div>
    )
  ), [remoteStreams, meeting, meetingInfo, onVideoMetadataLoaded, remoteVideoElemClass, onRemoteVideoRef]);

  const allVideoStreams = useMemo(() => {
    const nobodyElseIsSharingScreen = !remoteStreams.filter(it => it.screenSharing).length;

    return ((meetingOwner || shareScreen) && nobodyElseIsSharingScreen) ? [localVideo, ...remoteVideo] : [...remoteVideo, localVideo];
  }, [remoteStreams, meetingOwner, shareScreen, localVideo, remoteVideo]);

  const showLocalVideo = useMemo(() => {
    return !preview && !participantPreview && isOneOnOneView && !presentationMode;
  }, [preview, participantPreview, isOneOnOneView, presentationMode]);

  function remoteHasCameraOff(it: RemoteStream) {
    return it.cameraOff || !it.stream.getVideoTracks().length || it.stream.getVideoTracks()[0].muted
  }

  function sortStreams(meeting: MeetingDto | PublicMeetingDto, streams: RemoteStream[], info: MeetingInfo): RemoteStream[] {
    function isOwner(stream: RemoteStream) {
      const candidate = info.sessions.find(s => s.id === stream.id);
      return candidate && candidate.owner;
    }

    function isSharingScreen(stream: RemoteStream) {
      return stream.screenSharing
    }

    return [...streams]
      .filter(it => !!getNameByMeetingWSId(meeting, info, it.id))
      .sort((a, b) => {
      if (isSharingScreen(a)) return -1;
      if (isSharingScreen(b)) return 1;

      if (isOwner(a)) return -1;
      if (isOwner(b)) return 1;

      return a.id.localeCompare(b.id);
    });
  }

  return <>
    {meetingNotStarted &&
    <div className={'participant-info'}>
        <h2>{t('Sie sind dem Termin beigetreten.')}</h2>
        <h3>{t('Der Termin beginnt, sobald ein weiterer Gesprächsteilnehmer verfügbar ist.')}</h3>
    </div>}

    {patientLeftTheMeeting &&
    <div className={'participant-info'}>
        <h2>{t('Der Patient hat den Termin verlassen.')}</h2>
        <h3>{t('Beenden auch Sie den Termin.')}</h3>
    </div>}

    {preview && <div className='preview-label'>{t('VORSCHAU')}</div>}

    {preview && <Button className={`default abh-btn ${preview ? '' : 'left'}`} size="large" onClick={onCancel}>{t('Abbrechen')}</Button>}

    <div className={`local-video`} id='video-wrapper'>
      <div id='videos'>
        {preview || participantPreview ? localVideo : (isOneOnOneView && !presentationMode) ? remoteVideo : allVideoStreams}
      </div>
    </div>

    {showLocalVideo && <div className='remote-video'>{localVideo}</div>}
  </>
}
