import * as React from 'react';
import './AddGroup.scss';
import { useTranslation } from 'react-i18next';
import { AccountGroupDto } from '../../gen/client';
import { Card } from 'antd';
import Container from '../general/Container';
import { useRecoilState } from 'recoil';
import { activePractice } from '../../state/atoms';
import PracticeApi from '../../api/PracticeApi';
import { handleServerError } from '../../util/ErrorHandler';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../util/routes';
import GroupFields from './GroupFields';
import {successMessage} from "../../util/utils";

export default function AddGroup() {
  const { t } = useTranslation();
  const history = useHistory();
  const [practice] = useRecoilState(activePractice);

  function saveGroup(group: AccountGroupDto) {
    PracticeApi.createAccountGroup(practice.id, group)
      .then(() => {
        successMessage(t('Erfolgreich gespeichert!'));
        history.push(Routes.GROUPS);
      })
      .catch(err => handleServerError(err))
  }

  function goToGroupList() {
    history.push(Routes.GROUPS);
  }

  return (
    <Container className={'add-group'}>
      <header>
        <h2>{t('Neue Gruppe')}</h2>
      </header>
      <div className='content'>
        <Card>
          <GroupFields onSave={saveGroup} onCancel={goToGroupList}/>
        </Card>
      </div>
  </Container>
  )
}
