import * as React from 'react';
import './Appointment.scss';
import Container from "../general/Container";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import {useState, useEffect, useCallback} from "react";
import DurationSlider from "./DurationSlider";
import InfoReview from "./InfoReview";
import {
  errorMessage,
  formatTime,
  fromTimestamp,
  getApplicationSizeClass,
  getParticipant,
  notificationChannelProvided,
  parseTime,
  successMessage,
  timestamp,
  tinyDate, getParticipantGroup, getAccount
} from "../../util/utils";
import {isValidEmail, isValidNumber} from "../../util/validators";
import {Card, Button, Input, Checkbox, Modal, Tooltip} from "antd";
import moment, {Moment} from "moment";
import DateTime from "./DateTime";
import MeetingApi from "../../api/MeetingApi";
import {handleServerError} from "../../util/ErrorHandler";
import {isSingleAppointmentRoute, Routes} from '../../util/routes';
import {
  AppSettingsDto,
  CreateMeetingDto,
  CreateMeetingDtoTypeEnum,
  ParticipantDto,
  MeetingDto,
  MeetingDtoTypeEnum,
  AccountGroupDto, AccountDto, ParticipantGroupDto
} from "../../gen/client";
import PracticeApi from '../../api/PracticeApi';
import {useRecoilState, useRecoilValue} from "recoil";
import {activePractice, applicationSize} from "../../state/atoms";
import {DEFAULT_PHONE_PREFIX, MEETING_TYPE_INFO, TIME_REGEX} from '../../util/constants';
import Storage from "../../util/Storage";
import {Dimensions} from '../../util/enums';
import SearchContactModal from './SearchContactModal';
import Participants from './Participants';
import Icon from '@ant-design/icons';
import {ReactComponent as infoIcon} from '../../img/icons/info-icon.svg';
import Participant from "./Participant";
import AddContactModal from "./AddContactModal";

const {TextArea} = Input;

export default function Appointment() {
  const {t} = useTranslation();
  const {id} = useParams();
  const history = useHistory();
  const appSize = useRecoilValue<Dimensions>(applicationSize);

  const [participants, setParticipants] = useState([] as ParticipantDto[]);
  const [selected, setSelected] = useState(null as ParticipantDto);

  const [practice] = useRecoilState(activePractice);
  const [duration, setDuration] = useState(20);
  const [startTime, setStartTime] = useState('');
  const [startTimeErr, setStartTimeErr] = useState(false);
  const [date, setDate] = useState(moment(new Date()));
  const [dateErr, setDateErr] = useState(false);
  const [information, setInformation] = useState('');
  const [defaultSettings, setDefaultSettings] = useState(null as AppSettingsDto);
  const [meeting, setMeeting] = useState(null as MeetingDto);

  const [nameErr] = useState([] as boolean[]);
  const [phoneErr] = useState([] as boolean[]);
  const [emailErr] = useState([] as boolean[]);

  const [isSaving, setSaving] = useState(false);
  const [canSave, setCanSave] = useState(true);
  const [saveErrors] = useState([] as boolean[]);

  const [isInThePast, setIsInThePast] = useState(true);
  const [openSearchParticipant, setOpenSearchParticipant] = useState(false);

  const [type, setType] = useState(MeetingDtoTypeEnum.Group);
  const [preFillContacts, setPreFillContacts] = useState(false);

  const [isSingleAppointment, setIsSingleAppointment] = useState(true);
  const [openAddParticipant, setOpenAddParticipant] = useState(false);

  const [participantGroups, setParticipantGroups] = useState([] as ParticipantGroupDto[]);

  useEffect(() => {
    setIsSingleAppointment(isSingleAppointmentRoute(history.location.pathname));
  }, [history.location.pathname]);

  useEffect(() => {
    if (!isSingleAppointmentRoute(history.location.pathname) && !!Storage.getParticipantsIds()) {
      setPreFillContacts(true);
      let ids: string[] = Array.from(Storage.getParticipantsIds().split(','));

      PracticeApi.getAccountsByPublicIds(practice.id, ids)
        .then(resp => setParticipants(resp.data.map(it => getParticipant(it))))
        .catch(err => handleServerError(err));

      Storage.removeParticipantsIds();
    }
  }, [history.location.pathname]);

  useEffect(() => {
    PracticeApi.getSettings(practice.id).then(resp => {
      setDefaultSettings(resp.data);
      setDuration(resp.data.defaultDuration);
    }).catch(err => {
      handleServerError(err);
    });
  }, [practice.id]);

  useEffect(() => {
    if (meeting) {
      setParticipants(Array.from(meeting.participants.values()));
      setParticipantGroups(meeting.groups);
      setDuration(meeting.duration);
      setDate(fromTimestamp(meeting.startDate));
      setStartTime(formatTime(fromTimestamp(meeting.startDate)));
      setInformation(meeting.information);
      setType(meeting.type.toString() as MeetingDtoTypeEnum);
      setIsInThePast(false);
      return;
    }

    const query = new URLSearchParams(history.location.search);
    const contactId = query.get('publicId');
    const groupId = query.get('id');
    if (!!contactId) {
      PracticeApi.getAccount(practice.id, contactId)
        .then(resp => {
          let contact = getParticipant(resp.data);
          if (!contact.emailNotification && !contact.smsNotification) {
            contact.emailNotification = true;
          }
          setParticipants([contact]);
        })
        .catch(err => handleServerError(err));
      return;
    }

    if (!!groupId) {
      PracticeApi.getAccountGroup(practice.id, groupId)
        .then(resp => setParticipantGroups([getParticipantGroup(resp.data)]))
        .catch(err => handleServerError(err));
    }

    if (isSingleAppointmentRoute(history.location.pathname)) {
      if (!preFillContacts) {
        setParticipants([{
          emailNotification: defaultSettings?.emailNotification,
          smsNotification: defaultSettings?.smsNotification
        }]);
      }
    } else {
      setParticipants([]);
    }
  }, [history.location.search, history.location.pathname, meeting, defaultSettings, preFillContacts, practice.id]);

  useEffect(() => {
    if (id) {
      MeetingApi.getFullMeetingByPublicIdForProvider(id).then(resp => {
        setMeeting(resp.data);
      }).catch(err => {
        handleServerError(err);
      });
    }
  }, [id]);

  const checkSave = useCallback(() => {
    let notificationsAccepted = true;
    participants.forEach(it => {
      if (!notificationChannelProvided(it)) {
        notificationsAccepted = false;
        return
      }
    });
    setCanSave(notificationsAccepted)
  }, [participants]);
  
  useEffect(() => {
    checkSave();
  }, [checkSave, participants]);
  
  function goToAppointments() {
    history.push(Routes.APPOINTMENTS);
  }

  function validate(): boolean {
    setDateErr(!date);
    setStartTimeErr(!startTime);

    let valid = true;
    participants.forEach((it, idx) => {
      validateParticipantName(idx, it.name);
      validateParticipantPhone(idx, it.phone, it.smsNotification);
      validateParticipantEmail(idx, it.email, it.emailNotification);
      if (nameErr[idx] || phoneErr[idx] || emailErr[idx]) {
        errorMessage('Invalid Teilnehmer ' + (idx + 1));
        valid = false;
      }
    });

    return valid && !!date && !!startTime;
  }

  function validateParticipantName(idx: number, value: string) {
    nameErr[idx] = !value;
  }

  function validateParticipantPhone(idx: number, value: string, required: boolean) {
    phoneErr[idx] = value && value !== DEFAULT_PHONE_PREFIX ? !isValidNumber(value) : required;
  }

  function validateParticipantEmail(idx: number, value: string, required: boolean) {
    emailErr[idx] = value ? !isValidEmail(value) : required;
  }

  function onDateChange(value: Moment) {
    setDate(value);
    setDateErr(!value);
    setStartTimeErr(!startTime);
    checkSave();
    if (value && startTime) {
      checkIfAppointmentIsInThePast(timestamp(value), timestamp(parseTime(startTime)));
    }
  }

  function onTimeChange(value: string) {
    setStartTime(value);
    setDateErr(!date);
    setStartTimeErr(!value || !TIME_REGEX.test(value));
    checkSave();
    if (value && TIME_REGEX.test(value) && date) {
      checkIfAppointmentIsInThePast(timestamp(date), timestamp(parseTime(value)));
    }
  }

  function checkIfAppointmentIsInThePast(startDate: number, time: number) {
    MeetingApi.checkIfIsInThePast(startDate, time)
      .then(resp => setIsInThePast(resp.data))
      .catch(err => handleServerError(err));
  }

  function onDurationChange(value: number) {
    setDuration(value);
    checkSave();
  }

  function onTypeChange(newType: MeetingDtoTypeEnum) {
    setType(newType);
    checkSave();
  }

  function onInformationChange(value: string) {
    setInformation(value);
    checkSave();
  }

  function checkParticipantsAcceptance() {
    participants.forEach((it, idx) => {
      if (!it.notificationAccepted) {
        setCanSave(false);
        saveErrors[idx] = true;
      }
    })
  }

  function validateParticipants() {
    let valid = true;
    participants.forEach(participant => {
      if (participant.emailNotification && !participant.email) {
        valid = false;
        return
      }
      if (participant.smsNotification && !participant.phone) {
        valid = false;
        return
      }
    });

    if (!valid) {
      errorMessage(t('Die Einladung kann nicht gesendet werden. Überprüfen Sie das Feld SMS / E-Mail'))
    }

    return valid
  }

  function save() {
    checkParticipantsAcceptance();
    if (!validate() || !validateParticipants()) return;

    setSaving(true);

    if (!!id) {
      MeetingApi.update({...meeting, ...getMeetingData()}).then(() => {
        successMessage(t('Appointment updated and Invitations succesfully sent'));
        goToAppointments();
        setSaving(false);
      }).catch(err => {
        setSaving(false);
        handleServerError(err);
      });
    } else {
      MeetingApi.create(getNewMeetingData()).then(() => {
        successMessage(t('Appointment saved and Invitations succesfully sent'));
        goToAppointments();
        setSaving(false);
      }).catch(err => {
        setSaving(false);
        handleServerError(err);
      });
    }
  }

  function getNewMeetingData(): CreateMeetingDto {
    return {
      duration,
      startDate: timestamp(date),
      startTime: timestamp(parseTime(startTime)),
      information,
      participants,
      type: isSingleAppointmentRoute(history.location.pathname) ? CreateMeetingDtoTypeEnum.Single : type.toString() as CreateMeetingDtoTypeEnum,
      groups: participantGroups
    }
  }

  function getMeetingData(): MeetingDto {
    return {
      duration,
      startDate: timestamp(date),
      startTime: timestamp(parseTime(startTime)),
      information,
      participants,
      type: type.toString() as MeetingDtoTypeEnum,
      groups: participantGroups
    }
  }

  function onParticipantChange(participant: ParticipantDto, idx: number) {
    let newArr = [...participants];
    newArr[idx] = Object.assign(newArr[idx], participant);
    setParticipants(newArr);
    saveErrors[idx] = !participant.notificationAccepted;
    let allowSave = true;
    newArr.forEach(it => {
      if (!(notificationChannelProvided(it) && !!it.name)) {
        allowSave = false;
        return
      }
    });
    setCanSave(allowSave);
  }

  function addContact(account: AccountDto) {
    const participant = getParticipant(account);

    if (isSingleAppointment) {
      setParticipants([participant]);
    } else {
      if (!!selected) {
        setParticipants([...participants.filter(it => it.id !== selected.id), participant])
      } else {
        setParticipants([...participants, participant]);
      }
    }
    setOpenAddParticipant(false);
  }

  function addContacts(accountList: AccountDto[]) {
    const list = accountList.map(it => getParticipant(it));
    if (!!selected) {
      setParticipants([...participants.filter(it => it.id !== selected.id), ...list])
    } else {
      setParticipants([...participants, ...list]);
    }
    setOpenAddParticipant(false);
  }

  function addGroup(accountGroup: AccountGroupDto) {
    const group = getParticipantGroup(accountGroup);

    setParticipantGroups([...participantGroups, group]);
  }

  function onEditParticipant(participant: ParticipantDto) {
    setSelected(participant);
    setOpenAddParticipant(true);
  }

  function onDeleteParticipant(idx: number) {
    var newArr = participants;
    newArr.splice(idx, 1);
    setParticipants([...newArr]);
  }

  function onDeleteGroup(idx: number) {
    const newArr = participantGroups;
    newArr.splice(idx, 1);
    setParticipantGroups([...newArr]);
  }

  function onCloseAddParticipantModal() {
    setOpenAddParticipant(false);
    setSelected(null);
  }

  function onCloseSearchParticipantModal() {
    setOpenSearchParticipant(false);
    setSelected(null);
  }

  return (
    <Container className={'apt'}>
      <header>
        {meeting ?
          <h2>{t('Video Termin')}</h2> :
					<h2>{isSingleAppointment? t('Neuer Video Termin') : t('Neuer Gruppen Termin')}</h2>
        }
      </header>

      <div className={'split content appointment-wrapper'}>
        <div>
          <Card className={'info'}>
            <header>
              <h3 className={'main-header'}>{t('Termin Info')}</h3>
            </header>

            {!isSingleAppointment &&
            <div className='typ-row'>
              <label>{t('MEETING_TYPE')} <Tooltip placement="right" id='meeting-type-info' title={t(MEETING_TYPE_INFO)}><Icon component={infoIcon}/></Tooltip></label>
              <div>
                <Checkbox checked={type === MeetingDtoTypeEnum.Group} onChange={() => onTypeChange(MeetingDtoTypeEnum.Group)}> {t('MEETING_TYPE_GROUP')}</Checkbox>
                <Checkbox checked={type === MeetingDtoTypeEnum.Multicast} onChange={() => onTypeChange(MeetingDtoTypeEnum.Multicast)}> {t('MEETING_TYPE_MULTICAST')}</Checkbox>
              </div>
            </div>}

            <DurationSlider value={duration} onChange={onDurationChange}/>

            <DateTime date={date} duration={duration} onDateChange={onDateChange} onTimeChange={onTimeChange} dateErr={dateErr} timeErr={startTimeErr} startTime={startTime}/>

            <InfoReview date={tinyDate(date)} startTime={startTime} duration={duration} isInThePast={isInThePast} isFromOra={meeting?.fromOra}/>

            <label>{t('Interne Bemerkungen / Grund')}</label>
            <TextArea value={information} rows={3} onChange={ev => onInformationChange(ev.target.value)}/>
          </Card>
        </div>

        <div className='attendees-wrapper'>
          <Card className={'attendees'}>
            <header className={`${appSize === Dimensions.MOBILE ? 'mobile-hdr' : ''}`}>
              <h3 className={'main-header'}>{t('Teilnehmer')}</h3>
              <div className={'act'}>
                {!isSingleAppointment && <Button className={'new-contact'} type={'primary'} size={'middle'} onClick={(ev) => {ev.stopPropagation(); setOpenAddParticipant(true)}}>{t('Neuer Teilnehmer')}</Button>}
                <Button className={'contacts-btn'} size={'middle'} onClick={() => setOpenSearchParticipant(true)}>{t('Suche in der Kontaktliste')}</Button>
              </div>
            </header>

            {isSingleAppointment &&
            <ul>
              {participants.map((it, idx) =>
                <Participant key={idx} it={it} idx={idx} modal={false}
                             nameErr={nameErr[idx]} phoneErr={phoneErr[idx]} emailErr={emailErr[idx]}
                             onNameChange={validateParticipantName}
                             onPhoneChange={validateParticipantPhone}
                             onEmailChange={validateParticipantEmail}
                             onParticipantChange={(p) => onParticipantChange(p, idx)} saveErr={saveErrors[idx]}/>)}
            </ul>}

            {!isSingleAppointment && 
              <Participants participants={participants} onEditParticipant={onEditParticipant} onDeleteParticipant={onDeleteParticipant}
                            groups={participantGroups} onDeleteGroup={onDeleteGroup}/>}
          </Card>
        </div>
        <footer className={'actions'}>
          <Button type={'primary'} size="large" onClick={save} loading={isSaving} 
              disabled={!canSave || isInThePast || isSaving || (participants.length === 0 && participantGroups.length === 0) || meeting?.fromOra}>{t('Termin speichern')}</Button>
          <Button className={'default'} size="large" onClick={goToAppointments}>{t('Abbrechen')}</Button>
        </footer>
      </div>

      <Modal
        closable={false}
        className={`search-card ${getApplicationSizeClass(appSize)}`}
        visible={openSearchParticipant}
        width={664}
        centered
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        destroyOnClose={true}>

        <SearchContactModal openSearch={openSearchParticipant} onCloseSearch={onCloseSearchParticipantModal} onContactChosen={addContact} onContactsChosen={addContacts} showGroups={!isSingleAppointment} onGroupChosen={addGroup}/>
      </Modal>

      <Modal
        closable={false}
        className={`new-participant-card ${getApplicationSizeClass(appSize)}`}
        visible={openAddParticipant}
        width={664}
        centered
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        destroyOnClose={true}
        footer={null}>

        <AddContactModal it={getAccount(selected)} defaultSettings={defaultSettings} onSave={addContact} onClose={onCloseAddParticipantModal}/>
      </Modal>
    </Container>
  )
}
