import React, {useCallback, useEffect, useState, createRef} from 'react';
import {useTranslation} from "react-i18next";
import Container from "../general/Container";
import AdminApi from "../../api/AdminApi";
import {handleServerError} from "../../util/ErrorHandler";
import {Button, Popconfirm, Skeleton, Divider} from "antd";
import {NotificationDto, PracticeDto} from "../../gen/client";
import './PracticeList.scss';
import { isSearchRoute, Routes } from '../../util/routes';
import { useHistory } from 'react-router';
import Icon from '@ant-design/icons';
import {ReactComponent as backIcon} from '../../img/icons/back-icon.svg';
import CloseSVG from '../general/svg/CloseSVG';
import { useParams } from 'react-router-dom';
import Avatar from '../general/Avatar';
import {successMessage} from "../../util/utils";
import { applicationSize } from '../../state/atoms';
import { Dimensions } from '../../util/enums';
import { useRecoilValue } from 'recoil';

interface ReferenceProp {
  [key: string]: any;
}

export default function PracticeList() {
  const {t} = useTranslation();
  const history = useHistory();
  const {id} = useParams();

  const appSize = useRecoilValue<Dimensions>(applicationSize);

  const [isLoading, setLoading] = useState(false);
  const [isPracticeLoading, setPracticeLoading] = useState(false);
  const [notifications, setNotifications] = useState([] as NotificationDto[]);
  const [selectedId, setSelectedId] = useState(null);
  const [list, setList] = useState([] as PracticeDto[]);
  const [references, setReferences] = useState([] as ReferenceProp[]);

  const [searchString, setSearchString] = useState("");

  const scrollToItem = useCallback((itemId: string) => {
    if (references && (references as ReferenceProp)[itemId]) {
      (references as ReferenceProp)[itemId].current?.scrollIntoView({ // NOSONAR
        behavior: 'smooth',
        block: "center",
        inline: "nearest"
      });
    }
  }, [references]);

  useEffect(() => {
    if (!id) return;
    getPracticeNotifications(id);
    scrollToItem(id);
  }, [id, scrollToItem]);

  useEffect(() => { 
    if (!isSearchRoute(history.location.pathname)) fetchPractices() }, []);

  useEffect(() => {
    setReferences(list.reduce((acc: any, value: PracticeDto) => {
      acc[value.id] = createRef();
      return acc;
    }, {}));
  }, [list]);

  useEffect(() => {
    if (isSearchRoute(history.location.pathname)) {
      setLoading(true);
      const query = new URLSearchParams(history.location.search);
      const key = query.get('key');
      setSearchString(key);
      AdminApi.searchPractices(key)
      .then(resp => {
        setLoading(false);
        setList(resp.data);
      }).catch((err) => {
        handleServerError(err);
        setLoading(false);
      })
    }
  }, [history.location]);

  function fetchPractices() {
    setLoading(true);
    AdminApi.getPractices().then(resp => {
      setLoading(false);
      setList(resp.data);
    }).catch((err) => {
      handleServerError(err);
      setLoading(false);
    })
  }

  function onPracticeClick(practice: PracticeDto) {
    if (isSearchRoute(history.location.pathname)){
      getPracticeNotifications(practice.id);
    } else {
        if (!!id && id === practice.id) {
          history.push(Routes.PRACTICES);
          setSelectedId(null);
        } else {
          history.push(`${Routes.PRACTICES}/${practice.id}`);
        }
    }
  }

  function getPracticeNotifications(practiceId: string) {
    setSelectedId(practiceId);
    setPracticeLoading(true);
    AdminApi.getPracticeNotifications(practiceId).then(resp => {
      setPracticeLoading(false);
      setNotifications(resp.data);
    }).catch((err) => {
      handleServerError(err);
      setPracticeLoading(false);
    })
  }

  function reset(notification: NotificationDto) {
    AdminApi.resetPracticeNotification(notification.id).then(() => {
      successMessage(t('Succesfully updated'));
    }).catch(handleServerError);
  }

  function goBack() {
    setSelectedId(null);
    history.push(Routes.PRACTICES);
  }

  function getTitle(): string {
    return !isSearchRoute(history.location.pathname) ? t('Praxis Einstellungen') : `${t('Suche nach')}: ${searchString}`;
  }

  function clearSearch() {
    history.push(Routes.PRACTICES);
    fetchPractices();
  }

  return (
    <Container className={'practice-list full'}>
      <div>
        <header>
            <h2>{getTitle()}</h2>
            {isSearchRoute(history.location.pathname) &&
              <Button type={'link'} size={'large'} className='close-btn' onClick={clearSearch}>
                <CloseSVG/>
              </Button>
            }
        </header>
        {!selectedId &&
          <div className="title-row">
            <h3 className='name-title'>{t('Praxis Name')}</h3>
            <h3 className='ik-title'>{appSize !== Dimensions.MOBILE ? t('IK-Nummer') : t('IK-Nr.')}</h3>
            <h3 className='address-title'>{t('Anschrift')}</h3>
          </div>
        }
      </div>
        
      {isLoading && <Skeleton active/>}

      {!isLoading && <div className={`content ${selectedId !== null ? 'details' : ''}`}>
        <ul className={'vd-list'}>
          {list.map(it =>
            <li key={it.id} className={`vd-line ${it.id === selectedId ? 'active' : ''}`} onClick={() => onPracticeClick(it)} ref={(references as ReferenceProp)[it.id]}>
              <div className='col name-col'>
                {it.name}
              </div>

              <div className='col ik-col'>
                {it.ik}
              </div>

              <div className='col address-col'>
                {it.street} {it.number}, {it.city}, {it.postalCode}
              </div>
            </li>)}
        </ul>

        {selectedId !== null && <div className={'info'}>
          <div className={'info-header'}>
            <Button type="link" className='back-btn' onClick={goBack}><Icon component={backIcon}/></Button>
            {list.filter(it => selectedId === it.id).map(it => 
              <>
              <Avatar name={it?.name} />
              <h3 key={it.id}>
                <div className='name-col'>
                    {it.name}
                </div>
                <div className='address-col'>
                  {it.street} {it.number}, {it.city}, {it.postalCode}
                </div>
              </h3>
              <Button className={'default'} size="large">{t('Reset all')}</Button>
              </>
            )}
          </div>
          <div className='divider'><Divider /></div>
          <h3>{t('Cms')}</h3>
          {isPracticeLoading && <Skeleton active/>}
          <ul>
            {notifications.map(it => <li key={it.id}>
              <span className={'name'}>{t(it.name)}</span>
              <span className={'type'}>{t(it.type)}</span>
              <span className={'action'}>
                  <Popconfirm title={t('Are you sure')} onConfirm={() => reset(it)} okText={t('Yes')} cancelText={t('No')}>
                    <Button type={'link'} size={'small'}>{t('Reset')}</Button>
                  </Popconfirm>
                </span>
            </li>)}
          </ul>
        </div>}
      </div>}
    </Container>
  );
}
