import * as React from 'react';
import './TextEditor.scss';
import {useState, useEffect} from 'react';
import {EditorState, convertToRaw, ContentState, Modifier} from "draft-js";
import {Editor} from "react-draft-wysiwyg"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import EditorKeyWords from "./EditorKeyWords";
import {NotificationDto} from "../../gen/client";
import {getKeyWords} from "../../service/ContentService";

interface TextEditorProps {
  content: string;
  notification?: NotificationDto;
  onChange: (content: string) => void;
}

export default function TextEditor({content, onChange, notification}: TextEditorProps) {

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [words, setWords] = useState([] as string[]);

  useEffect(() => {
    if (content) {
      const blocksFromHtml = htmlToDraft(content);
      const {contentBlocks, entityMap} = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [content]);

  useEffect(() => {
    if (!notification) return;
    setWords(getKeyWords(notification.type, notification.name));
  }, [notification]);

  function insertWord(word: string) {
    let contentState = editorState.getCurrentContent();
    let targetRange = editorState.getSelection();
    let newContentState = Modifier.insertText(contentState, targetRange, '[(${' + word + '})]');

    let result = EditorState.push(editorState, newContentState, "insert-characters");
    setEditorState(result);

    const raw = draftToHtml(convertToRaw(result.getCurrentContent()));
    onChange(raw);
  }

  return (
    <div className={'text-editor'}>
      {words.length > 0 && <EditorKeyWords words={words} onClick={insertWord}/>}

      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        onChange={() => onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))}
        toolbar={{
          inline: {inDropdown: true},
          list: {inDropdown: true},
          textAlign: {inDropdown: true},
          link: {inDropdown: true},
          history: {inDropdown: true}
        }}
        localization={{
          locale: 'de',
        }}
      />
    </div>
  );
}
