/**
* This file is generated
*/
import * as React from 'react';
import { CSSProperties } from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24" xmlSpace="preserve"><path d="M15,2.5H9c-3.9,0-7,3.1-7,7c0,2.8,1.6,5.3,4.1,6.4v5c0,0.3,0.2,0.5,0.4,0.6c0.1,0,0.2,0,0.3,0c0.2,0,0.4-0.1,0.5-0.2
	l4.4-4.8H15c3.9,0,7-3.1,7-7S18.9,2.5,15,2.5L15,2.5z M15,15.4h-3.7l-4,4.4v-4.6L6.9,15c-3-1.2-4.6-4.6-3.4-7.6
	C4.4,5.1,6.6,3.6,9,3.6h6c3.2,0,5.9,2.6,5.9,5.9S18.3,15.4,15,15.4L15,15.4z M16.3,8.5c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1
	c0.6,0,1-0.4,1-1c0,0,0,0,0,0C17.3,8.9,16.9,8.5,16.3,8.5z M12,8.5c-0.6,0-1,0.5-1,1s0.5,1,1,1c0.6,0,1-0.5,1-1S12.6,8.5,12,8.5
	L12,8.5z M7.7,8.5c-0.6,0-1,0.5-1,1s0.5,1,1,1c0.6,0,1-0.5,1-1S8.2,8.5,7.7,8.5C7.7,8.5,7.7,8.5,7.7,8.5z"/></svg>
);

const ChatIcon = (props: { style?: CSSProperties, className?: string }) => <Icon component={svg} {...props}/>;

export default ChatIcon;