import * as React from 'react';
import {useEffect} from 'react';
import './Statistics.scss';
import Container from '../general/Container';
import {useTranslation} from 'react-i18next';
import StatisticTile from './StatisticTile';
import {useHistory} from 'react-router';
import {Routes} from '../../util/routes';
import {DatePickerInterval} from '../general/IntervalPicker';
import {CmsAnswerDto, MeetingDto, ParticipantAnswerGroupDto} from '../../gen/client';
import StatisticsAppointmentTable from './StatisticsAppointmentTable';
import {handleServerError} from '../../util/ErrorHandler';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {participantFeedbacksList, statisticsAppointmentList, statisticsDateInterval, summarySelector} from './state/statistics.state';
import {activePractice, applicationSize, loggedMember} from '../../state/atoms';
import {Dimensions} from '../../util/enums';
import StatisticsHeader from './StatisticsHeader';
import RegisterVideoIcon from '../general/icons/RegisterVideoIcon';
import VideoCallDurationIcon from '../general/icons/VideoCallDurationIcon';
import FeedbackIcon from '../general/icons/FeedbackIcon';
import {formatDuration, getFromDateFromInterval, getToDateFromInterval, isProvider} from '../../util/utils';
import PracticeApi from "../../api/PracticeApi";

export interface MeetingListSummary {
  total: number;
  totalDuration: number;
  totalEstimatedDuration: number;
  totalFeedbacksCount: number;
}

export default function Statistics() {
  const {t} = useTranslation();
  const history = useHistory();

  const appSize = useRecoilValue(applicationSize);
  const [practice] = useRecoilState(activePractice);
  const [logged] = useRecoilState(loggedMember);

  const [interval, setInterval] = useRecoilState<DatePickerInterval>(statisticsDateInterval);
  const [meetings, setMeetings] = useRecoilState<MeetingDto[]>(statisticsAppointmentList);
  const setParticipantFeedbacks = useSetRecoilState<CmsAnswerDto[]>(participantFeedbacksList);
  const summary = useRecoilValue<MeetingListSummary>(summarySelector);

  useEffect(() => {
    if (isProvider(logged) && !!interval[0] && !!interval[1]) {
      PracticeApi.getMeetingsForProvider(practice.id, logged.id, getFromDateFromInterval(interval), getToDateFromInterval(interval), false).then((res) => {
        setMeetings(res.data);
      }).catch(handleServerError);
    }
  }, [practice, interval, setMeetings, logged]);

  useEffect(() => {
    if (isProvider(logged) && !!interval[0] && !!interval[1]) {
      PracticeApi.getParticipantFeedback(practice.id, getFromDateFromInterval(interval), getToDateFromInterval(interval))
      .then(resp => getAnswers(resp.data))
      .catch(handleServerError);
    }
  }, [practice.id, interval]);

  function getAnswers(groups: { [key: string]: Array<ParticipantAnswerGroupDto> }) {
    let feedbacks: CmsAnswerDto[] = [];
    Object.values(groups).forEach((it: ParticipantAnswerGroupDto[]) => {
      it.forEach(group => feedbacks.push(group.answer))
    })
    setParticipantFeedbacks(feedbacks);
  }

  function goToFeedback() {
    history.push(Routes.STATISTICS_FEEDBACK)
  }

  function getTotalFeedbackCount(): string {
    return summary.totalFeedbacksCount.toString();
  }

  return (
    <Container className={'statistics full'}>
      <header className='hdr'>
        <h2>{t('Statistik')}</h2>
        <StatisticsHeader title={t('Statistik')} interval={interval} setInterval={setInterval}/>
      </header>
      <div className='content'>
        <div className={'statistics-cards'}>
          <div>
            <StatisticTile text={summary.total.toString()} subtext={t('Video Termine')} icon={<RegisterVideoIcon style={{fill: '#FF5F43'}}/>}/>
          </div>
          <div>
            <StatisticTile text={formatDuration(summary.totalDuration)}
                           subtext={t('Total Call Duration')} icon={<VideoCallDurationIcon style={{fill: '#00D2A5'}}/>}/>
          </div>
          <div>
            <StatisticTile text={getTotalFeedbackCount()} subtext={t('Feedbacks')} click={goToFeedback}
                           icon={<FeedbackIcon style={{fill: '#FF5059'}}/>} url={{url: '#', urlText: t('alles sehen')}}/>
          </div>
        </div>
        
        {(appSize !== Dimensions.MOBILE || meetings.length > 0) && <StatisticsAppointmentTable meetings={meetings} summary={summary}/>}
      </div>
    </Container>
  )
}
